<template>
  <div
    id="single-page-main"
    class="main main-singlepage">
    <div
      id="single-page"
      class="single-page page-singlepage">
      <div id="single-page-right">
        <transition name="page-toggle">
          <router-view :key="$route.fullPath"></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/libs/util.js';
import LayoutMixin from '@/mixin/layout.js';
export default {
  components: {},
  mixins: [LayoutMixin],
  data() {
    return {
      shrink: false,
      userName: '',
      isFullScreen: false
    };
  },
  computed: {
    pageTagsList() {
      return this.$store.state.app.pageOpenedList; // 打开的页面的页面对象
    },
    currentPath() {
      return this.$store.state.app.currentPath; // 当前面包屑数组
    },
    avatorPath() {
      return localStorage.avatorImgPath;
    },
    cachePage() {
      return this.$store.state.app.cachePage;
    },
    lang() {
      return this.$store.state.app.lang;
    },
    openedSubmenuArr() {
      return this.$store.state.app.openedSubmenuArr;
    },
    menuTheme() {
      return this.$store.state.app.menuTheme;
    },
    mesCount() {
      return this.$store.state.app.messageCount;
    }
  },
  watch: {
    $route(to) {
      // this.$store.commit('setCurrentPageName', to.name);
      // let pathObj = util.setCurrentPath(this, to.name);
      // this.$store.commit('addOpenSubmenu', pathObj.name);
      // this.checkTag(to.name);
      // localStorage.currentPageName = to.name;
    },
    lang() {
      util.setCurrentPath(this, this.$route.name); // 在切换语言时用于刷新面包屑
    }
  },
  mounted() {
    let lastChildCssLink = document.getElementsByTagName('Link');
    if (lastChildCssLink['bpm-flow-web']) {
      let headDomNode = lastChildCssLink['bpm-flow-web'].parentNode;
      headDomNode.insertBefore(lastChildCssLink['bpm-flow-web'], headDomNode.childNodes[0]);
    }
    this.init();
  },
  created() {
    this.$store.dispatch('getUeConfig');
    // if (this.$route.query.isAgentSide) {
    //   sessionStorage.isAgentSide = true;
    // } else {
    //   sessionStorage.isAgentSide = false;
    // }
    // 显示打开的页面的列表
    // this.$store.commit('setOpenedList');
    // this.$store.commit('changeMainTheme', 'default');
  },
  methods: {
    init() {

      // let pathObj = util.setCurrentPath(this, this.$route.name);
      // this.$store.commit('addOpenSubmenu', pathObj.name);
      // this.userName = Cookies.get('user');
      // let messageCount = 3;
      // this.messageCount = messageCount.toString();
      // this.checkTag(this.$route.name);
      // this.$store.commit('setMessageCount', 3);
    },
    toggleClick() {
      this.shrink = !this.shrink;
    },
    handleClickUserDropdown(name) {
      if (name === 'ownSpace') {
        util.openNewPage(this, 'ownspace_index');
        this.$router.push({
          name: 'ownspace_index'
        });
      } else if (name === 'loginout') {
        // 退出登录
        this.$store.commit('logout', this);
        this.$store.commit('clearOpenedSubmenu');
        this.$router.push({
          name: 'login'
        });
      }
    },
    checkTag(name) {
      let openpageHasTag = this.pageTagsList.some((item) => {
        if (item.name === name) {
          return true;
        }
      });
      if (!openpageHasTag) {
        //  解决关闭当前标签后再点击回退按钮会退到当前页时没有标签的问题
        util.openNewPage(this, name, this.$route.params || {}, this.$route.query || {});
      }
    },
    handleSubmenuChange(name) {
      let willpush = true;
      if (this.beforePush !== undefined) {
        if (!this.beforePush(name)) {
          willpush = false;
        }
      }
      if (willpush) {
        const menuItem = this.getMenuItemByName(name);
        if (menuItem && menuItem.bVue) {
          const query = util.param2Obj(menuItem.url);
          this.$router.push({
            name,
            query
          });
        } else {
          let url = menuItem && menuItem.url;
          let splieChar = '&';
          if (url.indexOf('?') === -1) {
            splieChar = '?';
          }
          url = url + splieChar + 'menuid=' + menuItem.sysCode;
          if (menuItem.route) {
            url += '#' + menuItem.route;
          }
          if (menuItem.isNewWindow) {
            this.$open(url);
          } else {
            window.location.href = url;
          }
        }
      }
    },
    beforePush(name) {
      // if (name === 'accesstest_index') {
      //     return false;
      // } else {
      //     return true;
      // }
      return true;
    },
    fullscreenChange(isFullScreen) {
      // console.log(isFullScreen);
    }
  }
};
</script>
