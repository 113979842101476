const entirePromotionDetail = {
  path: 'promotionGroup/entirePromotionDetail',
  name: 'entirePromotionDetail',
  component: () => import('@/views/agent/promotionGroup/entirePromotionDetail'),
  meta: {
    menuCode: 'agentPromotionGroup'
  }
};

export default entirePromotionDetail;
