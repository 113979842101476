import Cookies from 'js-cookie';
import util from '@/libs/util.js';
// 该类主要用于帮助u8c的打印功能设置yht_access_token
const printCookie = {
  getSubDomain: function() {
    if (!location.hostname) return;
    return location.hostname.indexOf('localhost') > -1 ? location.hostname : location.hostname.match(/[\w-]+[.]\w+$/)[0];
  },
  getToken: function(url) {
    return url.match(/token=(.*)[&]?/)[1];
  },
  setPrintToken(url, path = '/u8cprint', tokenname = 'yht_access_token', tokenvalue) {
    let domain = this.getSubDomain();
    if (!domain) return;
    const exp = new Date();
    exp.setTime(exp.getTime() + 168 * 60 * 60 * 1000);
    let params = { domain, path, expires: exp };
    let token = util.getUrlParam('token', url) || tokenvalue;
    Cookies.set(tokenname, token, params);
  },
  clearToken(path = '/', tokenname = 'yht_access_token') {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);
    let domain = this.getSubDomain();
    if (!domain) return;
    let params = { domain, path, expires: exp };
    Cookies.set(tokenname, null, params);
    Cookies.set('yht_udh_access_token', null, params);
  }
};

export default printCookie;
