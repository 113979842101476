import { checkInventory, confirmDelivery, backConfirmDvery, delDelivery, exportDelivery, takeDelivery, getDafaultStock, getAllStock } from '@/api/delivery';
import { getMerchantIdApi } from '@/api/salereturn';
import { getStockByOrg } from '@/api/refer-select';
const mixin = {
  data() {
    return {
    };
  },
  mounted() {},
  computed: {},

  methods: {
    mixin_exportDelivery(query) {
      const type = this.mixin_isPlatForm();
      const other = {
        'name': 'isOtherFranchiser',
        'v1': true,
        'valueType': 'BOOLEAN',
        'conditionType': 'eq'
      };
      if (type && !query.querySchema.conditions.other) {
        query.querySchema.conditions.push(other);
      }
      return exportDelivery(query);
    },
    mixin_confirmDelivery(params, vm) {
      const confirmDv = () => {
        let confirmArray = [];
        confirmArray.push(params);
        let postData = {
          cDeliveryNos: confirmArray,
          datatype: 'json'
        };
        confirmDelivery(postData).then((data) => {
          vm.$Message.success(vm.mlang('confirmDeliverySuccess'));
          vm.dispatch('VoucherList', 'reload');
        });
      };

      checkInventory(params).then((data) => {
        if (data.isExceed) {
          vm.$Modal.confirm({
            content: data.msg,
            onOk: () => {
              confirmDv();
            }
          });
        } else {
          vm.$Modal.confirm({
            content: vm.mlang('confirmDeliveryContent'),
            onOk: () => {
              confirmDv();
            }
          });
        }
      });
    },
    mixin_backConfirmDvery(params, vm) {
      if (params.row.cOutSysKey) {
        vm.$Message.warning(vm.mlang('backConfirmDveryWarning'));
      } else {
        vm.$Modal.confirm({
          content: vm.mlang('backConfirmDveryContentA') + params.row.cDeliveryNo + vm.mlang('backConfirmDveryContentB'),
          onOk: () => {
            const postData = {
              cDeliveryNo: params.row.cDeliveryNo
            };
            backConfirmDvery(postData).then((data) => {
              vm.$Message.success(vm.mlang('backConfirmDverySuccess'));
              vm.dispatch('VoucherList', 'reload');
            });
          }
        });
      }
    },
    mixin_delDelivery(params, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('delDeliveryContentA') + params.row.cDeliveryNo + vm.mlang('delDeliveryContentB'),
        onOk: () => {
          const postData = {
            cDeliveryNo: params.row.cDeliveryNo
          };
          delDelivery(postData).then(() => {
            vm.$Message.success(vm.mlang('delDeliverySuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_batchSure: (batchSureArr, vm) => {
      // console.log(batchSureArr);
      let nos = [];
      batchSureArr.map((v) => {
        nos.push(v.cDeliveryNo);
      });
      vm.$Modal.confirm({
        content: vm.mlang('batchSureContentA') + batchSureArr.length + vm.mlang('batchSureContentB'),
        onOk: () => {
          const postData = {
            cDeliveryNos: nos,
            datatype: 'json'
          };
          confirmDelivery(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.broadcast('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_sureReceivingGood: (delivery, vm) => {
      vm.returnConfirm(delivery, vm);
    },
    getMerchantId(delivery, vm) {
      getMerchantIdApi(delivery.oAgent.id).then((data) => {
        let isOpenStock = vm.$getBO('OPENSTOCK');
        if (isOpenStock) {
          delivery.merchantId = data.id;
          this.getStock(delivery, vm); // 获取库存类表
        } else {
          this.returnConfirm(delivery, vm);
        }
      });
    },
    getStock(delivery, vm) {
      let postData = {
        bizId: delivery.merchantId
        //  saleOrgId: saleReturn.salesOrgId
      };
      getStockByOrg(postData).then((res) => {
        if (res && res.length) {
          vm.stockList = res;
        }
        if (res.length === 0) {
          vm.$Modal.warning({
            content: vm.mlang('pleaseSetStockFiles')
          });
        } else if (res.length === 1) {
          delivery.deliveryStockId = res[0].id;
          this.returnConfirm(delivery, vm);
        } else {
          this.selectStock(delivery, res);
        }
      });
    },
    selectStock(delivery, res) {
      const _this = this;
      _this.control.editRows[0].enumArray = [];
      res.map((v, index) => {
        _this.control.editRows[0].enumArray.push({
          'key': v.id,
          'text': v.name
        });
      });
      _this.isShowModal = true;
      _this.rowData = delivery;
    },
    async returnConfirm(delivery, vm) {
      const currentUser = this.$store.getters.currentUser;
      const merchants_2Bor2C = currentUser.docType && (currentUser.docType.includes('3') || currentUser.docType.includes('5'));
      if (merchants_2Bor2C) {
        const DafaultStock = await getDafaultStock(delivery?.receiveId, delivery?.deliveryShop);
        const allStock = await getAllStock(delivery?.iAgentId);
        this.$store.commit('setPickStockModalShow', true);
        this.$store.commit('setAllStock', allStock);
        this.$store.commit('setDefaultStockId', DafaultStock?.id);
        this.$store.commit('setTemporaryDeliveryId', delivery?.id);
      } else {
        vm.$Modal.confirm({
          content: vm.mlang('pleaseGetGoodBeforeGet'),
          onOk: () => {
            // let postData = {
            //   deliveryStockId: delivery.deliveryStockId,
            //   deliveryNo: delivery.cDeliveryNo,
            //   loading: true
            // };
            takeDelivery(delivery.cDeliveryNo).then((data) => {
              if (data.code === 999 && data.message) {
                vm.$Modal.error({
                  content: data.message
                });
              } else if (data.code !== 999) {
                if (data.message) {
                  vm.$Modal.success({
                    content: data.message,
                    onOk: () => {
                      window.location.reload();
                    }
                  });
                } else {
                  window.location.reload();
                }
              }
            }).catch(res => {
              if (res.code === 986) {
                vm.$router.go(0);
              }
            });
          }
        });
      }
    }
  }
};
export default mixin;
