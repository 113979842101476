<template>
  <div v-if="column.key !== 'fixWidthColumn' && column.key !== 'nowidth'" ref="cell" :class="classes" :style="styles" :fieldid="column.fieldName">
    <template v-if="renderType === 'index'">
      <span v-if="!row._isSum" class="table-index">{{ showIndex }}</span>
    </template>
    <template v-if="renderType === 'selection'">
      <Checkbox
        v-if="!row._isSum"
        class="table-checkBox"
        :value="checked"
        fieldid="table-checkBox"
        :disabled="disabled"
        @click.native.stop="handleClick"
        @on-change="toggleSelect"></Checkbox>
      <span v-if="row._isPageSum" class="table-index">{{ mlang('littleSum') }}</span>
      <span v-if="row._isAllSum" class="table-index">{{ mlang('totalSum') }}</span>
    </template>

    <template v-if="renderType === 'radio'">
      <Radio
        class="table-radio"
        :value="selected"
        :disabled="disabled"
        :index="index"
        @click.native.stop="handleClick"
        @on-change="toggleRadio">
        <span></span>
      </Radio>
    </template>
    <template v-if="renderType === 'html'">
      <span v-html="row[column.key]"></span>
    </template>
    <template v-if="renderType === 'normal'">
      <!-- <Tooltip :content="formatValue(row,column)" :delay="1000"  max-width="200" theme="light"> -->
      <span
        v-if="!fixed"
        :title="formatValue(row,column)"
        class="two-line-overflow-hide">{{ formatValue(row,column) }}</span>
      <!-- </Tooltip> -->
    </template>
    <template v-if="renderType === 'expand' && !row._disableExpand">
      <div :class="expandCls" @click="toggleExpand">
        <Icon type="ios-arrow-right"></Icon>
      </div>
    </template>
    <template v-if="renderType === 'tree' && !row._disableExpand">
      <div :class="expandCls" @click="toggleExpandTree(row)">
        <template v-if="hasChildren(row)">
          <Icon v-if="!expandedTree" type="md-add"></Icon>
          <Icon v-if="expandedTree" type="md-remove"></Icon>
        </template>
        <span>{{ row[column.key] }}</span>
      </div>
    </template>
    <Cell
      v-if="renderType === 'render'"
      :row="row"
      :column="column"
      :index="renderIndex"
      :table-index="index"
      :render="column.render"></Cell>
    <table-slot v-if="renderType === 'slot'" :row="row" :column="column" :index="index"></table-slot>
    <table-edit v-if="renderType === 'edit'" :row="row" :column="column" :index="index"></table-edit>
  </div>
</template>
<script>
import Cell from './expand';
import TableSlot from './slot';
import TableEdit from './table-edit';
// import Icon from '../icon';
// import Checkbox from '../checkbox';
import _, { split } from 'lodash';
export default {
  name: 'TableCell',
  components: { TableSlot, Cell, TableEdit },
  props: {
    prefixCls: String,
    row: Object,
    column: Object,
    naturalIndex: Number, // index of rebuildData
    index: Number, // _index of data
    selected: Boolean,
    checked: Boolean,
    disabled: Boolean,
    expanded: Boolean,
    expandedTree: Boolean,
    fixed: {
      type: [Boolean, String],
      default: false
    },
    rowHeight: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {
      renderType: '',
      uid: -1,
      context: this.$parent.$parent.$parent.currentContext
    };
  },
  computed: {
    showIndex() {
      return this.column.tableIndexKey && !this.column._renderTr ? this.row[this.column.tableIndexKey] + 1 : this.row['_index'] + 1;
    },
    renderIndex() {
      return _.isUndefined(this.row.__scIndex) ? this.index : this.row.__scIndex;
    },
    classes() {
      return [
        `${this.prefixCls}-cell mh54`,
        {
          [`${this.prefixCls}-hidden`]: !this.fixed && this.column.fixed && (this.column.fixed === 'left' || this.column.fixed === 'right' || this.column.fixed === 'suspend'),
          [`${this.prefixCls}-cell-ellipsis`]: this.column.ellipsis || false,
          [`${this.prefixCls}-cell-index`]: this.renderType === 'index',
          [`${this.prefixCls}-cell-with-expand`]: this.renderType === 'expand',
          [`${this.prefixCls}-cell-edit`]: this.renderType === 'edit',
          'cell-edit': this.renderType === 'edit',
          [`${this.prefixCls}-cell-h${this.rowHeight}`]: this.column.fixed !== 'suspend'
        }
      ];
    },
    styles() {
      return {
        paddingLeft: this.renderType === 'tree' && this.column.type === 'tree' && `${(this.row.iDepth || this.row.level || 1) * 18}px`,
        maxHeight: this.column.maxHeight
      };
    },
    expandCls() {
      return [
        `${this.prefixCls}-cell-expand`,
        {
          [`${this.prefixCls}-cell-expand-expanded`]: this.expanded
        }
      ];
    }
  },
  created() {
    if (this.column.type === 'index') {
      this.renderType = 'index';
    } else if (this.column.type === 'selection') {
      this.renderType = 'selection';
    } else if (this.column.type === 'radio') {
      this.renderType = 'radio';
    } else if (this.column.type === 'html') {
      this.renderType = 'html';
    } else if (this.column.type === 'expand') {
      this.renderType = 'expand';
    } else if (this.column.type === 'tree') {
      this.renderType = 'tree';
    } else if (this.column.type === 'edit') {
      this.renderType = 'edit';
    } else if (this.column.render) {
      this.renderType = 'render';
    } else if (this.column.slot) {
      this.renderType = 'slot';
    } else {
      this.renderType = 'normal';
    }
  },
  methods: {
    hasChildren(row) {
      return row.children && row.children.length > 0;
    },
    toggleSelect() {
      this.$parent.$parent.$parent.toggleSelect(this.index);
    },
    toggleRadio() {
      this.$parent.$parent.$parent.handleCurrentRow('highlight', this.index);
    },
    toggleExpand() {
      this.$parent.$parent.$parent.toggleExpand(this.index);
    },
    toggleExpandTree(row) {
      if (!this.hasChildren(row)) {
        return;
      }
      this.$parent.$parent.$parent.toggleExpandTree(this.index);
    },
    handleClick() {
      // 放置 Checkbox 冒泡
    },
    formatValue(row, column) {
      const spiltArr = split(column.key, '.');
      if (spiltArr && spiltArr.length > 1) {
        const keys = split(column.key, '.');
        let formatVal = row;
        keys.forEach((key) => {
          formatVal = formatVal || {};
          formatVal = formatVal[key] ?? '';
        });
        return formatVal;
      }
      return row[column.key];
    }
  }
};
</script>
