import home from './home';
import order from './order';
import history from './history';
import products from './products';
import customer from './customer';
import auth from './auth';
import basebill from './basebill';
import channelcenter from './channelcenter';
import integrationextension from './integrationextension';
import settle from './settle';
import platform from './platform';
import organization from './organization';
import settings from './settings';
import priceCenter from './priceCenter';
import promotionZTE from './promotionZTE';
import stockCenter from './stockcenter';
import other from './other';
import creditManagement from './creditManagement';
import rebateCenter from './rebateCenter';
import productNotice from './productNotice';
import operator from './operator';
import tenantManagement from './tenantManagement';
import selfinfo from '../agent/selfInfo';
import bankConfig from './bankConfig';
import billItemConfig from './billItemConfig';
import decorate from './decorate';

export const corpRoute = [
  ...other.children,
  ...customer,
  ...operator,
  ...tenantManagement,
  ...home,
  selfinfo,
  ...order.children,
  ...products.children,
  ...auth.children,
  ...basebill.children,
  ...organization.children,
  ...settings,
  ...channelcenter.children,
  ...integrationextension.children,
  ...settle.children,
  ...platform.children,
  ...priceCenter.children,
  ...promotionZTE.children,
  ...stockCenter,
  ...productNotice,
  ...rebateCenter,
  ...creditManagement,
  ...bankConfig,
  ...history.children,
  ...billItemConfig,
  ...decorate
];
