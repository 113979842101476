import corpParentView from '@/views/corp/home/home.vue';

const message = {
  path: 'message',
  title: { i18n: 'message' },
  name: 'message',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'index',
      title: { i18n: 'title.settlementMethodList' },
      name: 'index',
      component: () => import('@/components/message/message')
    },
    {
      path: 'detail',
      title: { i18n: 'title.settlementMethodList' },
      name: 'messageDetail',
      component: () => import('@/components/message/message-detail')
    }
  ]
};

export default message;
