import { yhtUserInfo } from '@/api/user.js';
/* eslint-disable camelcase */
/**
 *易观api方法
 * https://docs.analysys.cn/ark/integration/sdk/js/js
 * @export
 * @returns
 */
const PRODUCTID = 'Uorder';
const PRODUCTNAME = 'U订货'; // notranslate

const getProperty = (property = {}) => {
  const { tenant_id, company, user_id, user_name, terminal } = property;
  const prop = {
    tenant_id: (tenant_id && tenant_id.toString()) || '',
    company: company || '',
    user_id: (user_id && user_id.toString()) || '',
    user_name: user_name || '',
    product_id: PRODUCTID,
    product_name: PRODUCTNAME,
    terminal
  };
  return prop;
};
const doLogin = (user, cb) => {
  yhtUserInfo(user.userId)
    .then((res) => {
      if (res && res.code === 200) {
        const data = res.data || {};
        try {
          api.init({
            tenant_id: data.tenantId,
            company: data.corpInfo && data.corpInfo.name,
            user_id: user.userId,
            user_name: data.userInfo && data.userInfo.userName,
            terminal: user.isAgent ? 'order_0' : 'manage_1'
          });
          api.track('login_in');
          // eslint-disable-next-line no-empty
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
      cb();
    })
    .catch((e) => {
      cb();
    });
};
const api = {
  installed: typeof window.AnalysysAgent !== 'undefined',
  init(property) {
    try {
      const prop = getProperty(property);
      window.AnalysysAgent.alias(prop.user_id);
      window.AnalysysAgent.profileSet(prop);
      window.AnalysysAgent.registerSuperProperties(prop);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  track(eventName, properties) {
    if (window.AnalysysAgent) {
      window.AnalysysAgent.track(eventName, properties);
    }
  },
  login(user = {}, callback) {
    if (api.installed) {
      doLogin(user, callback);
    } else {
      callback();
    }
  }
};
export default api;
