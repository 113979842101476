export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000082","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000083","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000084","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000085","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000086","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000087","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000088","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000089","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500008A","停用") /* "停用" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500008B","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500008C","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500008D","查看执行情况") /* "查看执行情况" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500008E","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  handleBatchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500008F","没有权限操作该平台的促销") /* "没有权限操作该平台的促销" */
};
