import { getCustomerGroups } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const customerGroupsRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'value',
      refName: 'value',
      // 传递过去的参数
      passedparams: [
        {
          name: 'agentGroupId',
          v1: 0, // 该值会变，不同的值获取不同的内容
          isSkip: false,
          isOuterField: false,
          valueType: 'Long',
          conditionType: 'eq'
        }
      ],
      queryschema: [
        {
          name: 'agent.name',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('agentName'),
          conditionType: 'like'
        },
        {
          name: 'agent.code',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('agentCode'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          fieldName: 'agentCode'
        },
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'agentName'
        },
        {
          showCaption: vm.mlang('bizName'),
          isShowIt: true,
          fieldName: 'bizName'
        },
        {
          showCaption: vm.mlang('org'),
          isShowIt: vm.$store && vm.$store.getters.currentUser.__showOrg,
          fieldName: 'orgName'
        },
        {
          showCaption: vm.mlang('operate'),
          fieldName: 'action',
          isShowIt: true,
          width: 200,
          align: 'center',
          fixed: 'suspend',
          render: (h, params) => {
            return !ctrl.agentDimension ? '' : h('span',
              {
                class: 'action-btn',
                style: {
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    ctrl.deleteCustomer(ctrl.passedparams[0].v1, params.row.agentId);
                  }
                }
              },
              vm.mlang('delete')
            );
          }
        }
      ],
      actions: { queryList: getCustomerGroups },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default customerGroupsRefer;
