import $ from 'jquery';
export const initWidth = (id, styleObject) => {
  if (id.split('_').includes('undefined')) return;
  var JPos = {};
  (function($) {
    $.getAbsPos = function(pTarget) {
      var x_ = 0; var y_ = 0;
      if (pTarget.style.position !== 'absolute') {
        while (pTarget.offsetParent) {
          x_ += pTarget.offsetLeft;
          y_ += pTarget.offsetTop;
          pTarget = pTarget.offsetParent;
        }
      }
      x_ += pTarget.offsetLeft;
      y_ += pTarget.offsetTop;
      return { x: x_, y: y_ };
    };

    $.getEventPos = function(evt) {
      var _x, _y;
      evt = JEvent.getEvent(evt);
      if (evt.pageX || evt.pageY) {
        _x = evt.pageX;
        _y = evt.pageY;
      } else if (evt.clientX || evt.clientY) {
        _x =
          evt.clientX +
          (document.body.scrollLeft ||
            document.documentElement.scrollLeft) -
          (document.body.clientLeft || document.documentElement.clientLeft);
        _y =
          evt.clientY +
          (document.body.scrollTop || document.documentElement.scrollTop) -
          (document.body.clientTop || document.documentElement.clientTop);
      } else {
        return $.getAbsPos(evt.target);
      }
      return { x: _x, y: _y };
    };
  })(JPos);
  var JEvent = {};
  (function($) {
    $.getEvent = function(evt) {
      evt = window.event || evt;
      if (!evt) {
        var fun = JEvent.getEvent.caller;
        while (fun !== null) {
          evt = fun.arguments[0];
          if (evt && evt.constructor === Event) break;
          fun = fun.caller;
        }
      }
      return evt;
    };

    $.doEvent = function(fun) {
      var args = arguments;
      return function() {
        return fun(args);
      };
    };
  })(JEvent);

  var colIndex;
  var dataTable = document.getElementById(id);
  var cols = dataTable && dataTable.getElementsByTagName('COL');
  var dragMask = document.createElement('DIV');
  var mAWidth = 20; var mAHeight = 20;
  document.body.insertBefore(dragMask, document.body.lastChild);
  dragMask.style.cssText =
    'width:' +
    mAWidth +
    'px;height:' +
    mAHeight +
    'px;position:absolute;background-color:#fff;opacity: 0.1;display:none;z-index:999999999;display:none;cursor: col-resize;';

  var maskMousemove = function(evt) {
    if (document.selection) {
      if (document.selection.empty) document.selection.empty();
      else {
        document.selection = null;
      }
    } else if (window.getSelection) {
      window.getSelection().removeAllRanges();
    }

    var oPos = JPos.getAbsPos(this);
    var mPos = JPos.getEventPos(evt);
    var x = mPos.x - oPos.x - mAWidth / 2;
    var tmpX = parseInt(cols[colIndex].style.width) + x;
    dragMask.style.left = mPos.x - mAWidth / 2 + 'px';
    cols[colIndex].style.width = tmpX >= 40 ? tmpX + 'px' : '40px'; // 宽度最小40px
    var dataTableWithHeadCol = $(`#${id}_HeadCol`).html();
    localStorage.setItem(`${location.host}_${id}`, dataTableWithHeadCol);
  };

  var maskMouseup = function() {
    dragMask.style.display = 'none';

    var colll = localStorage.getItem(`${location.host}_${id}`);
    $(`#${id}_BodyCol`).html(colll);
  };

  dragMask.onmousemove = maskMousemove;
  dragMask.onmouseup = maskMouseup;

  var cellMousedown = function() {
    colIndex = this.colIndex;
    var mPos = JPos.getEventPos();
    dragMask.style.left = mPos.x - mAWidth / 2 + 'px';
    dragMask.style.top = mPos.y - mAHeight / 2 + 'px';
    dragMask.style.display = '';
  };
  var i, o;
  let headCol = localStorage.getItem(`${location.host}_${id}`);
  if (headCol) {
    $(`#${id}_HeadCol`).html(headCol);
    $(`#${id}_BodyCol`).html(headCol);
  }
  for (i = 0; (o = dataTable.rows && dataTable.rows[0].cells[i]); i++) {
    o.colIndex = i;
    o.onmousedown = cellMousedown;
  }
};
