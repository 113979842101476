<template>
  <div>
    <Form
      ref="form-meta"
      :model="currentVM"
      :label-width="100"
      :rules="meta.validateRules"
      label-position="right">
      <FormItem
        v-for="row in cloneEditRows"
        :key="row.model"
        :label="row.label"
        :prop="row.model">
        <Select
          v-if="row.controlType=='select'"
          v-model="currentVM[row.model]"
          :placeholder="row.placeholder"
          :disabled="row.readOnly">
          <Option
            v-for="option in row.enumArray"
            :key="option.key"
            :label="option.text"
            :value="option.key"></Option>
        </Select>
        <keep-alive>
          <SelectTab
            v-if="row.controlType=='selecttab'"
            v-model="currentVM[row.model]"
            :name-key="row.nameKey"
            :sort-key="row.sortKey"
            :element-id="row.model"
            :default-array="row.defaultArray"
            :more-array="row.moreArray"
            :placeholder="row.placeholder"
            @on-select="handleSelectTabSelect">
          </SelectTab>
        </keep-alive>
        <span v-if="row.controlType=='text'">{{ currentVM[row.model] }}</span>
        <Input
          v-if="row.controlType=='input'"
          v-model="currentVM[row.model]"
          :placeholder="row.placeholder"
          :maxlength="row.maxlength"
          :read-only="row.readOnly"></Input>
        <Checkbox
          v-if="row.controlType=='checkbox'"
          v-model="currentVM[row.model]"></Checkbox>
        <ImgUpload
          v-if="row.controlType=='upload'"
          v-model="currentVM[row.model]"
          :control="row"></ImgUpload>
      </FormItem>
    </Form>
  </div>
</template>
<script>
import { deepCopy } from '@/u-components/utils/assist';
import ImgUpload from './img-upload-one';
export default {
  name: 'FormMeta',
  components: {
    ImgUpload
  },
  mixins: [],
  props: {
    meta: {
      required: true,
      type: Object
    }
  },
  data() {
    const editRows = this.meta.editRows;
    const currentVM = {};
    editRows.forEach((row) => {
      const key = row.model;
      currentVM[key] = '';
    });
    return {
      currentVM
    };
  },
  computed: {
    cloneEditRows() {
      return deepCopy(this.meta.editRows).filter((v) => {
        return v.bEnable !== false;
      });
    }
  },
  watch: {
    currentVM: {
      handler: function(val, oldVal) {
        this.$emit('on-form-change', val, oldVal);
      },
      deep: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    setVm(data) {
      this.currentVM = Object.assign({}, this.currentVM, data);
    },
    handleSelectTabSelect(val, fieldName) {
      // setTimeout(() => {
      //   this.$refs['form-meta'].validateField(fieldName)
      // }, 0);
    }
  }
};
</script>
