<template>
  <div>
    <Poptip v-if="showProductApportions && showProductApportions.length && orderProductType !== 'GIFT'" trigger="click" placement="bottom-start" popper-class="poptip poptip-setting">
      <span class="shopping-table-group-comtitle-icon comtitle-icon-4 proGroup_btn"> {{ mlang('promotionActivity') }}<Icon type="ios-arrow-down"></Icon> </span>
      <div v-if="showProductApportions && showProductApportions.length && orderProductType !== 'GIFT'" slot="content" class="proGroup_box">
        <div v-for="(proGroup, pgIndex) in showProductApportions" :key="pgIndex" class="proGroup_wrapper">
          <div v-if="proGroup.cApportionType !== 'REBATE'">
            <p class="proGroup_title">
              <span class="shopping-table-group-comtitle-icon" :class="[`comtitle-icon-${proGroup.promotionType || 4}`, `comtitle-icon-${!!proGroup.entireGiveawayPromotionId}`]">
                <i>{{ proGroupIcon(proGroup) }}</i>
              </span>
              {{ proGroup.cApportionName }}
            </p>
            <p class="proGroup_content">
              <span v-if="proGroup.cApportionAttr">{{ proGroup.cApportionAttr }}</span>
              <span>
                {{ mlang('hadDiscount') }}
                <span>{{ '' | upCoinOperater | upHideOperater('Amount') }}</span>
                <span>{{ proGroup.fApportionMoney || 0 | upCoinDigit | upHideString('Amount') }}</span>
              </span>
            </p>
            <p class="proGroup_time">
              {{ proGroup.startDate }}-{{ proGroup.endDate }}
            </p>
          </div>
        </div>
      </div>
    </Poptip>
  </div>
</template>

<script>
export default {
  name: 'PromotionPoptip',
  props: {
    productApportions: {
      type: Array,
      default: []
    },
    orderProductType: String
  },
  data() {
    return {
      promotionMethod: [this.mlang('discount'), this.mlang('reducePrice'), this.mlang('giveProduct'), this.mlang('onePrice')],
      orderDetailGroups: this.$store.getters.GET_ORDERDETAILGROUPS
    };
  },
  computed: {
    showProductApportions() {
      return this.productApportions.filter(item => item.cApportionType !== 'REBATE');
    }
  },
  watch: {
    showProductApportions: {
      handler(val) {
        if (!val) return;
        this.orderDetailGroups = this.$store.getters.GET_ORDERDETAILGROUPS;
        this.handlePromotionData();
      },
      deep: true
    }
  },
  created() {
    this.handlePromotionData();
  },
  methods: {
    proGroupIcon(group) {
      let title = '';
      if (group.combinationGroupId) {
        switch (group.promotionType) {
          case 1:
            title = this.mlang('comDiscount');
            break;
          case 2:
            title = this.mlang('comPriceBreak');
            break;
          case 4:
            title = this.mlang('onePrice');
            break;
          default:
            break;
        }
      } else if (['SINGLEPRODUCT', 'COMBINATIONPRODUCT', 'SINGLECLASS', 'PRODUCTSKUTAG', 'BRAND','DISCOUNTPROMOTION'].includes(group.strPromotionType)) {
        switch (group.promotionType) {
          case 1:
            title = this.mlang('productDiscount');
            break;
          case 2:
            title = this.mlang('productPriceBreak');
            break;
          case 3:
            title = this.mlang('productGift');
            break;
          case 4:
            title = this.mlang('onePrice');
            break;
          default:
            break;
        }
      } else if (group.isEntireGroup) {
        if (group.promotionType === 1) {
          title = this.mlang('wholeOrderdiscount');
        } else if (group.promotionType === 2) {
          title = this.mlang('wholeOrderPriceBreak');
        } else if (group.promotionType === 3) {
          title = this.mlang('wholeOrderGift');
        } else {
          title = '';
        }
      } else if (group.strPromotionType === 'ACCUMULATE') {
        title = this.mlang('leijiGift');
      } else if (group.strPromotionType === 'SELECTFREEPROMOTION') {
        title = this.mlang('package');
      }
      return title;
    },
    handlePromotionData() {
      // 给商品行促销活动赋值需显示内容
      if (this.showProductApportions && this.showProductApportions.length > 0) {
        this.showProductApportions.forEach((productApportion) => {
          let promotionActivity = {};
          if (this.orderDetailGroups && this.orderDetailGroups.length > 0) {
            promotionActivity = this.orderDetailGroups.find((item) => item.iPreId === productApportion.iPreId);
            productApportion.promotionType = promotionActivity?.iPreType;
            productApportion.startDate = promotionActivity?.pStartDate;
            productApportion.endDate = promotionActivity?.pEndDate;
            productApportion.combinationGroupId = promotionActivity?.iComPromotionGroupId;
            productApportion.strPromotionType = promotionActivity?.cPromotionType;
            productApportion.isEntireGroup = promotionActivity?.bEntireGroup;
            productApportion.entireGiveawayPromotionId = promotionActivity?.iEntityGiveawayPreId;
          }
        });
      }
    }
  }
};
</script>
<style scoped lang="less">
/* .sales-promotion {
  color: #ff5735;
} */

.proGroup_box{
  width: 372px;
  padding: 0 14px;
  max-height: 220px;
}

.proGroup_wrapper{
  padding-bottom: 12px;
  p{
    padding: 0 !important;
  }
  .proGroup_title{
    line-height: 20px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 14px;
    color: #111111;
    margin-bottom: 3px;
    .shopping-table-group-comtitle-icon{
      display: inline-block;
      vertical-align: middle;
      // width:40px;
      height:18px;
      line-height: 18px;
      border-radius:4px 4px 8px 0px;
      color: #fff;
      text-align: center;
      padding: 0;
      i{
        display:block;
        font-style: normal;
        font-size : 12px;
        -webkit-transform : scale(0.84,0.84) ;
        *font-size:10px;
      }
    }
    .comtitle-icon-1{//折扣
      background:rgba(230,80,80,1);
    }
    .comtitle-icon-2{//减价
      background:rgba(68,200,156,1);
    }
    .comtitle-icon-3, .comtitle-icon-true{//赠品
      background:rgba(90,141,232,1) !important;
    }
    .comtitle-icon-4{//一口价
      background:#E98533;
    }
  }
  .proGroup_content{
    color: #555555;
    font-size: 12px;
    font-weight: 400;
    margin-left: 40px;
    span{
      padding: 0;
    }
  }
  .proGroup_time{
    color: #888888;
    font-size: 12px;
    font-weight: 400;
    margin-left: 40px;
  }
}
.comtitle-icon-1 {
  //折扣
  background: rgba(230, 80, 80, 1);
}
.comtitle-icon-2 {
  //减价
  background: rgba(68, 200, 156, 1);
}
.comtitle-icon-3,
.comtitle-icon-true {
  //赠品
  background: rgba(90, 141, 232, 1) !important;
}
.comtitle-icon-4 {
  //一口价
  background: #e98533;
}
.proGroup_btn {
  i {
    display: inline-block !important;
    margin-left: 2px;
  }
  background: #e98533;
  border-radius: 4px 4px 8px 0px;
  padding: 0 3px;
  font-size: 10px;
  color: #fff;
  text-align: center;
}
.promotion-tip {
  display: table-cell;
  vertical-align: top;
}
.promotion-content {
  display: table-cell;
  vertical-align: top;
  padding-left: 5px;
}
.offer-method {
  width: 38px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  background: #fffaeb;
  border: 1px solid #ffe9a7;
  border-radius: 2px;
  font-size: 10px;
  color: #ff9500;
}
</style>
