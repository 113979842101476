export default {
  createOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003A5","创建组织") /* "创建组织" */,
  activityName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003A6","活动名称") /* "活动名称" */,
  activeTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003A7","活动时间") /* "活动时间" */,
  creator: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003A8","制单人") /* "制单人" */,
  marketName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003A9","营销方案名称") /* "营销方案名称" */,
  themeName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003AA","营销活动名称") /* "营销活动名称" */,
  activeState: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003AB","状态") /* "状态" */,
  promotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003AC","促销方式") /* "促销方式" */,
  strPromotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003AD","促销类型") /* "促销类型" */,
  stepCalcRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003AE","促销条件") /* "促销条件" */,
  isCalcEntireProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003AF","活动门槛计算依据") /* "活动门槛计算依据" */,
  giveawayCalcRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B0","赠送计算规则") /* "赠送计算规则" */,
  isMutexClassPre: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B1","与分类促关系") /* "与分类促关系" */,
  giveawaySelectRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B2","订单赠品选择") /* "订单赠品选择" */,
  isCalcWithDisCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B3","赠品数量计算") /* "赠品数量计算" */,
  Proportional: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B4","满足条件后完全按比例赠送") /* "满足条件后完全按比例赠送" */,
  isGiveawayShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B5","赠品金额处理") /* "赠品金额处理" */,
  mutexActivities: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B6","互斥条件") /* "互斥条件" */,
  isEnableStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B7","数乘阶梯") /* "数乘阶梯" */,
  openStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B8","启用(全额累进)") /* "启用(全额累进)" */,
  isEnableMaxPreferential: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003B9","最大优惠数量") /* "最大优惠数量" */,
  openMaxPre: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003BA","启用(按照计价数量单位计算)") /* "启用(按照计价数量单位计算)" */,
  isEnableMaxCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003BB","最大优惠次数") /* "最大优惠次数" */,
  open: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003BC","启用") /* "启用" */,
  close: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003BD","停用") /* "停用" */,
  maxPreCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003BE","每客户享受本促销最多") /* "每客户享受本促销最多" */,
  ci: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003BF","次") /* "次" */,
  demension: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C0","促销维度") /* "促销维度" */,
  batchDel: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C1","批量删除") /* "批量删除" */,
  addProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C2","新增商品") /* "新增商品" */,
  addRow: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C3","增行") /* "增行" */,
  product: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C4","商品") /* "商品" */,
  strPromotionType1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C5","单品促销") /* "单品促销" */,
  strPromotionType2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C6","商品组合促销") /* "商品组合促销" */,
  strPromotionType3: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C7","商品分类促销") /* "商品分类促销" */,
  strPromotionType4: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C8","商品分组促销") /* "商品分组促销" */,
  promotionType1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003C9","打折") /* "打折" */,
  promotionType2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003CA","减价") /* "减价" */,
  promotionType3: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003CB","一口价") /* "一口价" */,
  promotionType4: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003CC","赠商品") /* "赠商品" */,
  stepCalcRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003CD","满件") /* "满件" */,
  stepCalcRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003CE","满额") /* "满额" */,
  isCalcEntireProduct1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003CF","活动商品") /* "活动商品" */,
  isCalcEntireProduct2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D0","整单") /* "整单" */,
  isMutexClassPre1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D1","以单品促为准") /* "以单品促为准" */,
  isMutexClassPre2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D2","优先单品促") /* "优先单品促" */,
  giveawaySelectRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D3","赠品不可选，各梯度由高到低自动匹配") /* "赠品不可选，各梯度由高到低自动匹配" */,
  giveawaySelectRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D4","自由选择各梯度可赠商品数量") /* "自由选择各梯度可赠商品数量" */,
  mutexActivitie1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D5","整单促销(针对金额促销)") /* "整单促销(针对金额促销)" */,
  mutexActivitie2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D6","整单促销(针对买赠促销)") /* "整单促销(针对买赠促销)" */,
  isGiveaway1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D7","赠品金额强制为0") /* "赠品金额强制为0" */,
  isGiveaway2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D8","赠品参与分摊") /* "赠品参与分摊" */,
  giveawayCalcRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003D9","赠一定数量的赠品") /* "赠一定数量的赠品" */,
  giveawayCalcRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003DA","赠一定金额的赠品") /* "赠一定金额的赠品" */,
  productProperty: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003DB","商品属性") /* "商品属性" */,
  salePrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003DC","原价") /* "原价" */,
  ifstepCalcRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003DD","条件（批发计价单位）") /* "条件（批发计价单位）" */,
  ifstepCalcRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003DE","条件（满额）") /* "条件（满额）" */,
  disratelv: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003DF","折扣率%") /* "折扣率%" */,
  notDisrate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E0","倒扣率%") /* "倒扣率%" */,
  notUsedPreferentialNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E1","已使用数量") /* "已使用数量" */,
  del: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E2","删除") /* "删除" */,
  fireMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E3","单价减") /* "单价减" */,
  giveways: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E4","赠品") /* "赠品" */,
  giveawayMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E5","赠品金额") /* "赠品金额" */,
  giveawayNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E6","赠品数量") /* "赠品数量" */,
  isCapping: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E7","限制") /* "限制" */,
  addGroupStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E8","增加阶梯") /* "增加阶梯" */,
  sbfd: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E9","上不封顶") /* "上不封顶" */,
  productClassProes: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003EA","商品分类") /* "商品分类" */,
  productGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003EB","商品分组") /* "商品分组" */,
  pleaseSelectOrgFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003EC","请先选择组织！") /* "请先选择组织！" */,
  pleaseSelectCurrencyFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003ED","请先选择币种!") /* "请先选择币种!" */,
  xuanze: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003EE","选择") /* "选择" */,
  selectClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003EF","选择分类") /* "选择分类" */,
  pleaseSelectProductClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F0","请选择商品分类") /* "请选择商品分类" */,
  selectGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F1","选择分组") /* "选择分组" */,
  addCombine: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F2","新增组合") /* "新增组合" */,
  zuhetaocan: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F3","组合套餐") /* "组合套餐" */,
  goUp: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F4","上移") /* "上移" */,
  goDown: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F5","下移") /* "下移" */,
  stopSale: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F6","停售") /* "停售" */,
  startSale: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F7","开售") /* "开售" */,
  peijianzumingcheng: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F8","名称") /* "名称" */,
  koulvWith: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003F9","扣率%") /* "扣率%" */,
  danjiajian: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E3","单价减") /* "单价减" */,
  danjiayikoujia: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003FA","单价一口价") /* "单价一口价" */,
  shuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003FB","数量") /* "数量" */,
  taocan: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003FC","套餐") /* "套餐" */,
  zhekou: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003FD","扣率") /* "扣率" */,
  jian: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003FE","减") /* "减" */,
  danjiajin: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003E3","单价减") /* "单价减" */,
  qidingliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050003FF","起订量") /* "起订量" */,
  bLimitPriority: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000400","相同组合显示设置") /* "相同组合显示设置" */,
  bLimitPriority1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000401","显示所有组合") /* "显示所有组合" */,
  bLimitPriority2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000402","仅显示最高优先级组合") /* "仅显示最高优先级组合" */,
  pleaseSetCombine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000403","请设置组合！") /* "请设置组合！" */,
  pleaseSetProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000404","请设置商品") /* "请设置商品" */,
  pleaseSetDimenssion: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000405","请正确设置维度") /* "请正确设置维度" */,
  titleNotCanNull: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000406","名称不能为空！") /* "名称不能为空！" */,
  repeatProducthasNO: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000407","重复商品已被忽略") /* "重复商品已被忽略" */,
  zuidayouhuiliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000408","最大优惠量") /* "最大优惠量" */,
  zuidayouhuijineContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000409","主商品最大优惠金额") /* "主商品最大优惠金额" */,
  youhuizongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500040A","优惠总金额") /* "优惠总金额" */,
  yishiyong: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500040B","已使用") /* "已使用" */,
  meikehu: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500040C","每客户") /* "每客户" */,
  zongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500040D","总金额") /* "总金额" */,
  maxtenProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500040E","组合中最多可添加10个商品") /* "组合中最多可添加10个商品" */,
  bumen: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500040F","部门") /* "部门" */,
  shengyukeyongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000410","剩余可用金额") /* "剩余可用金额" */,
  zuidayouhuijine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000411","最大优惠金额") /* "最大优惠金额" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000412","请正确填写!") /* "请正确填写!" */,
  opposeReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000413","驳回原因") /* "驳回原因" */
};
