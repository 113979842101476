
import discountRebates from './discountRebates';
import goodsRebates from './goodsRebates';
import refundRules from './refundRules';

export default {
  refundRules,
  discountRebates,
  goodsRebates,
  discountRebate: discountRebates
};
