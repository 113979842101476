export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000518","确定执行整单生效?") /* "确定执行整单生效?" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000519","请选择至少一条记录！") /* "请选择至少一条记录！" */,
  currentContents: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500051A","是要对当前的") /* "是要对当前的" */,
  OperateContents: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500051B","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500051C","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500051D","条") /* "条" */,
  deleListInfo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500051E","删除") /* "删除" */,
  editContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500051F","编辑") /* "编辑" */,
  effectContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000520","生效") /* "生效" */,
  disEffectContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000521","失效") /* "失效" */,
  handleBatchOperationEnableWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000522","请选择至少一条未生效记录！") /* "请选择至少一条未生效记录！" */
};
