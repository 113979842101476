const payment = {
  state: {
    summaryParams: {}
  },
  getters: {
    GET_SUMMARYPARAMS: (state, getters, rootState) => state.summaryParams
  },
  mutations: {
    SET_SUMMARYPARAMS: (state, data) => {
      state.summaryParams = data;
    }
  },
  actions: {
  }
};

export default payment;
