import { stockSkuInventories } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const ncSaleOrgRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: false,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'iStockId',
      refName: 'stockName',
      queryschema: [
        {
          name: 'value',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('stockName'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('stockName'),
          isShowIt: true,
          fieldName: 'stockName',
          minWidth: 120
        },
        {
          showCaption: vm.mlang('stockOrgName'),
          isShowIt: vm.$store.getters.currentUser.__isMutiOrg,
          minWidth: 100,
          fieldName: 'stockOrgName'
        },
        {
          showCaption: vm.mlang('productCode'),
          isShowIt: true,
          minWidth: 100,
          fieldName: 'cProductCode'
        },
        {
          showCaption: vm.mlang('skuCode'),
          isShowIt: true,
          minWidth: 100,
          fieldName: 'cSkuCode'
        },
        {
          showCaption: vm.mlang('totalInventory'),
          isShowIt: vm.$getBO('INVENTORYDISPLAY') !== 'NONE',
          minWidth: 100,
          fieldName: 'inventoryDisplay'
        },
        {
          showCaption: vm.mlang('availableInventory'),
          isShowIt: vm.$getBO('INVENTORYDISPLAY') !== 'NONE',
          minWidth: 100,
          fieldName: 'notusedInventoryDisplay'
        }
      ],
      actions: { queryList: stockSkuInventories },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default ncSaleOrgRefer;
