import accountParentView from '@/views/agent/home/components/accountHome.vue';

const data = {
  path: 'mutiAgentDataTabs',
  name: 'mutiAgentDataTabs',
  component: accountParentView,
  redirect: { name: 'mutiAgentAuthMngDataOfSwitch' },
  meta: { module: 'mutiAgentDataTabs' },
  children: [
    {
      path: 'mutiAgentAuthMngDataOfSwitch',
      name: 'mutiAgentAuthMngDataOfSwitch',
      meta: { module: 'mutiAgentDataTabs' },
      component: () => import('@/views/agent/accounts/components/data/dataOfSwitch.vue')
    },
    {
      path: 'mutiAgentAuthMngDataOfRole',
      name: 'mutiAgentAuthMngDataOfRole',
      meta: { module: 'mutiAgentDataTabs' },
      component: () => import('@/views/agent/accounts/components/data/dataOfRole.vue')
    },
    {
      path: 'mutiAgentAuthMngDataOfUser',
      name: 'mutiAgentAuthMngDataOfUser',
      meta: { module: 'mutiAgentDataTabs' },
      component: () => import('@/views/agent/accounts/components/data/authMngDataOfUser.vue')
    },
    {
      path: 'mutiAgentOrdersDataOfUser',
      name: 'mutiAgentOrdersDataOfUser',
      meta: { module: 'mutiAgentDataTabs' },
      component: () => import('@/views/agent/accounts/components/data/ordersDataOfUser.vue')
    },

  ]
};
export default data;
