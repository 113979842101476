<template>
  <span>
    <Button
      type="primary"
      icon="plus"
      @click="buttonClick">
      {{ title }}
    </Button>
    <Refer
      :id="control.name"
      ref="refer-combination-promotion"
      v-model="value"
      :biz-meta="bizMeta"
      :is-queryschema="false"
      :control="control"
      @on-ok="onOk">
    </Refer>
  </span>
</template>

<script>
import { combinationPromotionRefer } from '@/meta/refer-common';
import Refer from '_c/common/refer/refer';

const control = (vm) => {
  const itemMeta = {
    key: 'combinationPromotionRefer',
    multiple: vm.multiple,
    action: 'action',
    caption: '组合销售',
    showCaption: '组合销售',
    isAgent: false
  };
  const conf = combinationPromotionRefer(itemMeta, vm);
  return conf;
};
export default {
  name: 'SelectCombinationPromotion',
  components: {
    Refer
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    title: String,

    beforeQuery: Function
  },
  data() {
    return {
      bizMeta: {
        actions: { action: this.action }
      },
      modal: false,
      value: null,
      control: control(this)
    };
  },
  methods: {
    action(filterMeta) {
      return this.control;
    },
    buttonClick() {
      this.control = control(this);
      if (this.beforeQuery && this.beforeQuery()) {
        this.showRefModal();
      } else if (!this.beforeQuery) {
        this.showRefModal();
      }
    },
    showRefModal() {
      this.$nextTick(() => {
        this.$refs['refer-combination-promotion'].showModal();
      });
    },
    onOk(rows) {
      this.$emit('selected', rows);
    }
  }
};
</script>
