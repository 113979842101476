import ajax from '@/libs/ajax';

export const fetchCurrentUserBo = (code) => {
  const setFirstBeDefault = (data) => {
    let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
    let bizId = user.bizId;
    if (Array.isArray(data) && data.length > 1) {
      let idx = data.findIndex(v => v.bizId === bizId);
      if (idx > 0) {
        let temp = data[idx];
        data[idx] = data[0];
        data[0] = temp;
      }
    }
  };

  const agentId = JSON.parse(localStorage.getItem('defaultUserInfo')).agentId;
  let data = {
    conditions: [
      { name: 'agentId', v1: agentId, valueType: 'LONG', conditionType: 'eq' },
      {
        conditionType: 'eq',
        isOuterField: false,
        isSkip: false,
        name: 'isContainBiz',
        v1: true,
        valueType: 'BOOLEAN'
      },
      {
        conditionType: 'eq',
        isOuterField: false,
        isSkip: false,
        name: 'isContainOrg',
        v1: true,
        valueType: 'BOOLEAN'
      },
      { name: 'activate', v1: true, valueType: 'BOOLEAN', conditionType: 'eq' }
    ],
    pager: {
      pageIndex: 1,
      pageSize: 300
    }
  };

  return ajax.request({
    url: '/archives/option/keyvalues/bizsOfOrg/0',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  }).then((res) => {
    setFirstBeDefault(res);
    return res;
  });
};
// 门户参数mdd改造适配——闪订
const newFetchCurrentFlashUserBo = () => {
  let orgId = window.localStorage.getItem('upOrgId');
  return ajax.request({
    url: '/marketingbill/flash/getOptionDatasByOptionId',
    method: 'post',
    data: { datatype: 'json', isMarket: 1, data: { optionId: 'flash_option', orgId } }
  }).then((res) => {
    return res;
  });
};
// 门户参数mdd改造适配
export const newFetchCurrentUserBo = () => {
  let orgId = window.localStorage.getItem('upOrgId');
  return ajax.request({
    url: '/marketingbill/flash/getOptionDatasByOptionId',
    method: 'post',
    data: { datatype: 'json', isMarket: 1, data: { optionId: 'portal_option', orgId } }
  }).then(async(res) => {
    const flashRes = await newFetchCurrentFlashUserBo();
    res = [...res, ...flashRes];
    return res;
  });
};

// 根据具体Code查询对应参数值
export const getOptionValueByCode = (code, orgId = localStorage.getItem('upOrgId')) => {
  return ajax.request({
    url: '/marketing/option/getOptionValue',
    method: 'get',
    data: {
      isMarket: 1,
      code,
      orgId
    }
  });
};

export const getSHOWSALERETURNNONESOURCEBo = () => {
  let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
  let relationId = localStorage.upAgentRelationId;
  let relation = user.relations.find((v) => {
    return v.id + '' === relationId;
  });
  return ajax.request({
    url: `/voucher/salereturn/salereturn/show/nonesource?salesOrgId=${relation.orgId}&bizId=${relation.bizId}&isArchive=1`,
    method: 'get'
  });
};
export const getFunctionOptionByCode = (code) => {
  return ajax.request({
    url: `/archives/option/${code}/value`,
    method: 'get',
    data: {
      cCodes: code,
      isArchive: 1
    }
  });
};

// 隐藏价格设置
export const fetchUserSettingPrice = () => {
  return ajax.request({
    url: '/user/infrastructure/getUserSettingForHidePrice?isArchive=1',
    method: 'get'
  });
};

/**
 * i18n资源
 */
export const fetchI18nRes = () => {
  return ajax.request({
    url: '/pc/Langs/getResourcesByPackage?packageName=JS',
    method: 'get'
  });
};

/**
 * 获取i18n
 */
export const getI18n = () => {
  return ajax.request({
    url: '/passport/lang',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
