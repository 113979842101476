import ajax from '@/libs/ajax';

// 获取企业端信息
export const getCorpInfo = (data) => {
  return ajax.request({
    url: '/passport/tenant',
    method: 'get',
    data: { isArchive: 1 }
  });
};
// 获取商家端信息
export const getMerchantsInfo = (data) => {
  return ajax.request({
    url: '/orginazation/merchant',
    method: 'get',
    data: { isArchive: 1 }
  });
};
// 帮助中心保存热线电话等上面内容
export const saveCorpInfo = (data) => {
  return ajax.request({
    url: '/passport/tenant/savehelpcenter',
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data
    }
  });
};
// 企业端保存
export const saveCropbaseDateEnterprise = (data) => {
  return ajax.request({
    url: '/passport/tenant',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 商家端保存
export const saveCropbaseDateBusiness = (data) => {
  return ajax.request({
    url: '/orginazation/merchant',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 获取企业端客服信息列表
export const getcontactWayList = (data) => {
  return ajax.request({
    url: '/archives/contactway/list',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const saveContactData = (data) => {
  return ajax.request({
    url: '/archives/contactway/save',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 获取订购信息
export const getCorpLicense = () => {
  return ajax.request({
    url: '/passport/corplicense/info',
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 激活许可
export const activeAction = (data) => {
  return ajax.request({
    url: `/thirdparty/corp/${data.devicedUUid}/activeAction/${data.activationCode}`,
    method: 'post',
    data: {
      data: data.checkCode,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 注册邀请
export const getInvitation = () => {
  return ajax.request({
    url: '/passport/invitationcodeurl',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const getCurrentTheme = () => {
  return ajax.request({
    url: '/passport/tenant/themes?isArchive=1',
    method: 'get',
    data: {}
  });
};

export const getCorpration = () => {
  return ajax.request({
    url: '/passport/tenant/getCorpration?isArchive=1',
    method: 'get',
    data: {}
  });
};

export const getHelpCategory = () => {
  return ajax.request({
    url: '/archives/help/article/list',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const getHelpDetail = (id) => {
  return ajax.request({
    url: '/archives/help/article/' + id,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const saveCorpLogo = (data) => {
  return ajax.request({
    url: '/passport/tenant/logo',
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data: data.url
    }
  });
};

export const saveTheme = (data) => {
  return ajax.request({
    url: '/passport/tenant/theme',
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data: data
    }
  });
};

export const saveHelpCate = (data) => {
  return ajax.request({
    url: '/archives/help/article',
    method: 'post',
    data: {
      datatype: 'json',
      data: data,
      isArchive: 1
    }
  });
};

export const delHelpCate = (data) => {
  return ajax.request({
    url: `/archives/help/article/${data.id}/delete`,
    method: 'post',
    data: {
      data: {},
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 查询租户列表
export const getCorprationList = (data) => {
  return ajax.request({
    url: '/passport/corpration',
    method: 'post',
    data: {
      data: data.querySchema,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 编辑租户列表
export const getManagementDetail = (corpId) => {
  return ajax.request({
    url: `/passport/corpration/${corpId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 查询操作列表
export const getCorplicenserecord = (data) => {
  return ajax.request({
    url: '/passport/corplicenserecord/page',
    method: 'post',
    data: {
      data: data.querySchema,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 保存操作记录
export const saveCorplicenserecord = (data) => {
  return ajax.request({
    url: '/passport/corplicenserecord',
    method: 'post',
    data: {
      data: { ...data },
      isArchive: 1,
      datatype: 'json'
    }
  });
};

export const saveCorpPhone = (data) => {
  return ajax.request({
    url: '/pc/corp/CorprationSrv/saveCorpPhone',
    method: 'post',
    data: {
      phone: data
    }
  });
};

export const saveWeiXin = (data) => {
  return ajax.request({
    url: '/pc/corp/CorprationSrv/saveWeiXin',
    method: 'post',
    data: data
  });
};

// 获取客服信息
export const getServiceType = () => {
  return ajax.request({
    url: 'archives/contactway/list/contactWays',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 获取组织邀请码
export const getInvitationcode = (orgId) => {
  return ajax.request({
    url: `/passport/invitationcodeurl?orgId=${orgId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
