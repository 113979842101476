export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000451","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000452","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000453","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000454","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000455","条") /* "条" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000456","删除") /* "删除" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000457","编辑") /* "编辑" */,
  effectContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000458","生效") /* "生效" */,
  disEffectContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000459","失效") /* "失效" */
};
