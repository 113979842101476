<template>
  <div>
    <div v-if="flattenQueryschemaArr.length">
      <QuerySchema
        ref="queryschema"
        :b-refer="true"
        :biz-meta="cloneMeta"
        :filters="flattenQueryschemaArr"
        @on-queryschema-search="queryschemaSearch"
        @on-queryschema-loaded="queryschemaLoaded"></QuerySchema>
    </div>
    <Row>
      <div class="pull-left product-ref-center" style="width:500px">
        <Table
          ref="mainTable"
          :width="mainTableWidth"
          :columns="mainColumns"
          :data="mainTableData"
          :loading="mainTableLoading"
          :height="height"
          :highlight-row="highlightRow">
          <div slot="footer">
            <div style="float: right;">
              <Page
                :total="pager.totalCount"
                :current.sync="pager.currentPage"
                simple
                @on-change="changePage"></Page>
            </div>
          </div>
        </Table>
      </div>
    </Row>
  </div>
</template>

<script>
import { oneOf, deepCopy, findComponentUpward } from '@/u-components/utils/assist';
import CommonMixin from '@/mixin/common.js';
import PropsyncMixin from '@/mixin/propsync.js';
import Mixin from '@/mixin/archiveList.js';
import _ from 'lodash';
import { getProductsList } from '@/api/refer-modal';
import { getDiscountPriceModal } from '@/api/agentSaleReturn/agentSaleReturn';
import { mapGetters } from 'vuex';
import * as filters from '@/filters'; // global filters
const prefixCls = 'ivu-select';

export default {
  name: 'ProductRefTable',
  mixins: [CommonMixin, PropsyncMixin, Mixin],
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    referType: {
      type: String,
      default: 'Table',
      validator(value) {
        return oneOf(value, ['Table', 'TreeTable']);
      }
    },
    isQueryschema: {
      type: [Boolean],
      default: true
    },
    billMeta: {
      default: {},
      type: Object
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    showInput: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      flattenQueryschemaArr: [],
      queryschema: null,
      classid: null,
      cloneMeta: null,
      mainTableLoading: false,
      queryschemaObj: {},
      mainColumns: [],
      mainTableData: [],
      prefixCls: prefixCls,
      showValue: '',
      height: 0,
      modal: false,
      modalLoading: false,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      pageType: 0,
      selectedProd: []
    };
  },
  computed: {
    mainTableWidth() {
      return 900;
    },
    highlightRow() {
      return this.cloneMeta && !this.cloneMeta.multiple;
    },
    ...mapGetters(['currentUser'])
  },
  watch: {
    billMeta: {
      handler() {
        this.init();
      },
      deep: true
    }
  },
  created() {
    this.$on('on-tree-selected', ({ selectNodes }) => {
      if (selectNodes && selectNodes.length) {
        const classid = selectNodes[0].id;
        this.classid = classid;
        this.pager.pageIndex = 1;
        this.loadProductData();
      }
    });
    this.init();
    this.setPager();
  },
  methods: {
    init() {
      // this.loadProductData();
      this.makeMeta();
      this.makeQueryschema();
      this.makeMainColumns();
    },
    makeMeta() {
      /**
        isSingleSku -- 是否只选择一个SKU
        isMustSelectSku --是否必须选择一个SKU
        isAllowSelectSku  --选择商品,不能选择SKU
        isAllowSelectProduct --可以选择商品,不选择SKU
      */
      this.cloneMeta = deepCopy(this.billMeta);
    },
    queryschemaSearch(queryschema) {
      this.queryschema = queryschema;
      this.pager.pageIndex = 1;
      this.pager.currentPage = 1;
      this.loadProductData();
    },
    loadProductData(classfilyID) {
      let status = -1;
      let keyword = '';
      if (this.queryschema && this.queryschema.conditions.length) {
        const cond = this.queryschema.conditions.find((v) => {
          return v.name === 'value';
        });
        if (cond) {
          keyword = cond.v1;
        }
        const statusControl = this.queryschema.conditions.find((v) => {
          return v.name === 'status';
        });
        if (statusControl) {
          status = statusControl.v1;
        }
      }
      if (status === '') {
        status = -1;
      }
      let postData = {
        pageIndex: this.pager.pageIndex,
        pageSize: this.pager.pageSize,
        status,
        keyword
      };
      if (!this.$util.isNoValue(classfilyID)) {
        postData.classid = classfilyID;
      }
      if (!this.cloneMeta.isAgent) {
        postData.status = status;
      }
      if (this.cloneMeta.selectSkuBizId) {
        postData.bizId = this.cloneMeta.selectSkuBizId;
      }
      if (this.classid) {
        postData.classid = this.classid;
      }
      if (this.cloneMeta.classId) {
        postData.classid = this.cloneMeta.classId;
      }
      if (this.billMeta.agentId) {
        postData.iAgentId = this.billMeta.agentId;
      }
      if (this.billMeta.orgId) {
        postData.orgId = this.billMeta.orgId;
      }
      postData.pagerType = this.pageType === 1 ? 'sku' : 'product';
      if (this.billMeta.searchProductArchives !== undefined) {
        // 适用范围true平台，false商家
        postData.searchProductArchives = this.billMeta.searchProductArchives;
      }
      if (this.cloneMeta.beforeQuery) {
        const result = this.cloneMeta.beforeQuery(postData);
        if (result === false) {
          const refer = findComponentUpward(this, 'Refer');
          refer && refer.hideModal();
          return;
        } else {
          postData = result;
        }
      }
      this.mainTableLoading = true;
      getProductsList(postData).then((res) => {
        res.data &&
            res.data.map((val) => {
              val.productName = val.cName;
              val.__uuid = `${val.id}__${val.bizProductId}`;
              val.salesOrgId = val.saleOrgId;
              val.__auxEnable = this.$getBO('RETURNUNIT') !== 'Unit';
              val.__onlyAuxEnable = this.$getBO('RETURNUNIT') === 'SaleUnit';
              var oProduct = deepCopy(val);
              val.lsProductSkus &&
                val.lsProductSkus.map((v, index) => {
                  delete oProduct.lsProductSkus;
                  v.oProduct = oProduct;
                  v.__uuid = `${v.id}__${v.bizProductId}`;
                  v.bizId = val.bizId;
                  v.saleOrgId = val.saleOrgId;
                  v.salesOrgId = val.saleOrgId;
                  v.productName = val.cName;
                  v.fSalePayMoney = 0;
                  v.iQuantity = 0;
                  v.iAuxQuantity = 0;
                  v.modelDescription = val.modelDescription;
                  v.model = val.model;
                  v.productLineName = val.productLineName;
                  const exist = this.selectedProd.find((k) => v.__uuid === k.__uuid);
                  if (exist) {
                    v.iQuantity = exist.iQuantity;
                    v.iAuxQuantity = exist.iAuxQuantity;
                    v.fSalePrice = exist.fSalePrice;
                    v.fSalePayMoney = exist.fSalePayMoney;
                  }
                  v.__onlyAuxEnable = this.$getBO('RETURNUNIT') === 'SaleUnit';
                  v.__auxEnable = this.$getBO('RETURNUNIT') !== 'Unit';
                });
            });

        let proArr = [];
        res.data && res.data.map((v) => {
          if (v.lsProductSkus && v.lsProductSkus.length > 1) {
            proArr.push(v);
            v.lsProductSkus.map((k) => {
              k.manySku = true;
              proArr.push(k);
            });
          } else if (v.lsProductSkus && v.lsProductSkus.length === 1) {
            v.lsProductSkus[0].manySku = false;
            proArr.push(v.lsProductSkus[0]);
          }
        });
        this.mainTableData = proArr;
        this.pager.totalCount = res.totalCount;
        this.mainTableLoading = false;
      });
    },
    getSelectRow() {
      if (this.selectedProd.length === 0) {
        this.$Message.warning({ content: this.mlang('pleaseSelectProduct') });
        return false;
      }
      return this.selectedProd;
    },
    makeMainColumns() {
      const noWidthColumn = {
        key: 'nowidth',
        title: ''
      };
      const modelDescription = {
        showCaption: this.mlang('productDescription'),
        isShowIt: false,
        width: 100,
        fieldName: 'modelDescription'
      };
      const model = {
        showCaption: this.mlang('productModel'),
        isShowIt: true,
        width: 100,
        fieldName: 'model'
      };

      this.cloneMeta.columns = [...this.cloneMeta.columns, modelDescription, model, noWidthColumn];
      this.cloneMeta.columns.forEach((v) => {
        if (v.fieldName === 'cCode') {
          // 商品编码 sku不显示，商品的显示cCode
          v.render = (h, params) => {
            let title = params.row.lsProductSkus ? params.row.cCode : params.row.oProduct && params.row.oProduct.cCode;
            return params.row.manySku === undefined || !params.row.manySku
              ? h('div', [
                h('span', { class: ['product-ref-name one-line-overflow-hide'], attrs: { title } }, title)])
              : h('div', '');
          };
        } else if (v.fieldName === 'name') {
          // 商品名称 sku的显示cSpecDescription，商品的显示cName
          v.render = (h, params) => {
            if (params.row.manySku === true) {
              return h('div', { class: ['product-ref-name two-line-overflow-hide'], attrs: { title: params.row.cSpecDescription } }, params.row.cSpecDescription);
            } else {
              let name = params.row.cName ? params.row.cName : params.row.oProduct.cName;
              return h('div', { class: ['product-ref-name two-line-overflow-hide'], attrs: { title: name } }, name && name.length > 18 ? name.substring(0, 18) + '...' : name);
            }
          };
        } else if (v.fieldName === 'orgName') {
          v.render = (h, params) => {
            if (params.row.orgName) {
              return h('div', { class: ['product-ref-name one-line-overflow-hide'], attrs: { title: params.row.orgName } }, params.row.orgName);
            } else {
              return h('div', { class: ['product-ref-name one-line-overflow-hide'], attrs: { title: params.row.oProduct.orgName } }, params.row.oProduct.orgName);
            }
          };
        } else if (v.fieldName === 'fSalePrice') {
          v.render = (h, params) => {
            if (params.row.manySku !== undefined) {
              return h('div', [
                h('div', { class: ['product-ref-name one-line-overflow-hide'] }, `${filters.upHideString(filters.priceFree(params.row.fSalePrice), 'fSalePrice')}`)
              ]);
            } else {
              return h('div', '');
            }
          };
        } else if (v.fieldName === 'iQuantity') {
          v.render = (h, params) => {
            let unit = params.row.oProduct ? this.getProductUnit(params.row.oProduct) : this.getProductUnit(params.row);
            let precision = unit.unitPrecision;
            let roundType = unit.unitRoundType;

            if (params.row.manySku === undefined) {
              return h('div', '');
            } else if (!params.row.__onlyAuxEnable || !params.row.oProductAuxUnit) {
              return h('div', { class: 'productEditQuantity' }, [
                h('InputNumber', {
                  props: {
                    min: 0,
                    disabled:this.$getBO('RETURNUNIT') === 'SaleUnit',
                    value: params.row.iQuantity || 0,
                    params,
                    precision,
                    roundType,
                    column: params.column
                  },
                  on: {
                    'input': (val) => {
                      params.row.iQuantity = val;
                      if (params.row.changeFormAuxCount) {
                        params.row.changeFormAuxCount = false;
                      } else {
                        if (params.row.oProductAuxUnit) {
                          params.row.iAuxQuantity = this.$util.getIAuxUnitQuantity(params.row.iQuantity, params.row, null, null, false);
                        }
                        params.row.changeFormCount = true;
                      }
                      this.reCalulateRow(params.row);
                    }
                  }
                }),
                h('span', { class: 'unit' }, (params.row.oUnit && params.row.oUnit.cName) || (params.row.oProduct.oUnit && params.row.oProduct.oUnit.cName))
              ]);
            } else if (params.row.__onlyAuxEnable && params.row.oProductAuxUnit) {
              return <span>
                <span>{filters.upExactNumber(params.row.iQuantity, {unitPrecision: precision, priceRoundType: roundType})}</span>
                <span class="unit">
                  {(params.row.oUnit && params.row.oUnit.cName) || (params.row.oProduct.oUnit && params.row.oProduct.oUnit.cName)}
                </span>
              </span>;
            }
          };
        } else if (v.fieldName === 'iAuxQuantity') {
          let isOpenNauxUnit = this.$getBO('OPENAUXUNIT');
          v.render = (h, params) => {
            let unit = params.row.oProductAuxUnit ? this.getProductUnit(params.row.oProductAuxUnit) : this.getProductUnit(params.row);
            let precision = unit.unitPrecision;
            let roundType = unit.unitRoundType;
            if (params.row.manySku === undefined) {
              return h('div', '');
            } else {
              if (params.row.oProductAuxUnit && isOpenNauxUnit && this.$getBO('RETURNUNIT') !== 'Unit') {
                return h('div', { class: 'productEditQuantity' }, [
                  h('InputNumber', {
                    props: {
                      min: 0,
                      value: params.row.iAuxQuantity || 0,
                      params,
                      disabled: this.$getBO('RETURNUNIT') === 'Unit',
                      precision,
                      roundType,
                      column: params.column
                    },
                    on: {
                      'input': (val) => {
                        params.row.iAuxQuantity = val;
                        if (params.row.changeFormCount) {
                        // 如果是因为 count 的更改导致关联更改,则不再会写 count (再回写会因为小数位舍入导致计算误差)
                          params.row.changeFormCount = false;
                        } else {
                          params.row.iQuantity = this.$util.getIQuantity(params.row.iAuxQuantity, params.row, null, null, false);
                          params.row.changeFormAuxCount = true;
                        }

                        this.reCalulateRow(params.row);
                      }
                    }
                  }),
                  h('span', { class: 'unit' }, params.row.oProductAuxUnit.oUnit.cName)
                ]);
              } else if (isOpenNauxUnit) {
                return <span>
                  <span>
                    {filters.upExactNumber(params.row.iAuxQuantity, {unitPrecision: precision, priceRoundType: roundType})}
                  </span>
                  <span class="unit">
                    {params.row.oProductAuxUnit && params.row.oProductAuxUnit.oUnit && params.row.oProductAuxUnit.oUnit.cName}
                  </span>
                </span>;
              }
            }
          };
        } else if (v.fieldName === 'fSalePayMoney') {
          v.render = (h, params) => {
            return params.row.manySku !== undefined
              ? h('div', { class: ['product-ref-name one-line-overflow-hide'] }, `${filters.upHideString(filters.upCoinDigit(params.row.fSalePayMoney, 'money'), 'fSalePrice')}`)
              : h('div', '');
          };
        } else if (v.fieldName === 'modelDescription') {
          v.render = (h, params) => {
            return (<div style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;">{params?.row?.modelDescription}</div>);
          };
        } else if (v.fieldName === 'model') {
          v.render = (h, params) => {
            return (<div style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;">{params?.row?.model}</div>);
          };
        };
      });
      this.mainColumns = this.cloneMeta.columns.filter((item, index) => {
        if (item.fieldName === 'cCode' && !this.$getBO('QUICKORDERISSHOWPRODUCTCODEPC')) {
          return false;
        }
        if (item.fieldName === 'modelDescription' && !this.$getBO('PRODUCTSPECIFICATION')) {
          return false;
        }
        if (item.fieldName === 'model' && !this.$getBO('PACKAGESPECIFICATIONS')) {
          return false;
        }
        return item;
      });
    },
    getProductUnit(row) {
      let unit = {
        unitPrecision: 0,
        unitRoundType: 0,
        iConversionRate: 0
      };
      if (row.oUnit) {
        unit = row.oUnit;
      }
      return unit;
    },
    changes(sku, modify) {
      // 修改商品金额
      if (modify === 'fSalePrice') {
        if (sku.fSalePrice < 0) {
          this.$Message.warning('价格填写不正确，请重新填写!');
          return false;
        }
        getDiscountPriceModal(sku, sku.saleOrgId).then((data) => {
          sku.fSalePrice = data;
          sku.changeFormAuxCount = false;
          sku.changeFormCount = false;
          this.reCalulateRow(sku);
        });
      }
    },
    reCalulateRow(sku) {
      if (sku.iQuantity) {
        const existIndex = this.selectedProd.findIndex((v) => v.__uuid === sku.__uuid);
        if (existIndex > -1) {
          this.selectedProd[existIndex] = sku;
        } else {
          this.selectedProd.push(sku);
        }
      } else {
        _.remove(this.selectedProd, (v) => {
          return v.__uuid === sku.__uuid;
        });
      }
      if (sku.changeFormAuxCount || sku.changeFormCount) {
        this.changes(sku, 'fSalePrice');
      } else {
        sku.fSalePayMoney = _.multiply(sku.fSalePrice, sku.iQuantity || 0);
      }
    },
    setColumns() {
      if (this.cloneMeta.multiple) {
        this.cloneMeta.columns = [this.radioColumn, ...this.cloneMeta.columns];
      } else {
        this.cloneMeta.columns = [this.selectionColumn, ...this.cloneMeta.columns];
      }
      this.columns = this.cloneMeta.columns;
    },
    makeQueryschema() {
      let queryschemas = deepCopy(this.billMeta.queryschema);
      this.flattenQueryschemaArr = queryschemas.filter((v) => {
        return v.isHidden !== true;
      });
    },

    getQueryschemaData() {
      const schema = this.$refs.queryschema.getQueryschemaRealData();
      schema.pager = this.pager;
      return schema;
    },
    queryschemaLoaded() {
      this.height = 434;
      const querySchema = this.getQueryschemaData();
      this.queryschema = querySchema;
      this.loadProductData();
    },
    changePage(page) {
      this.pager.pageIndex = page;
      this.loadProductData();
    },
    setPager() {
      if (this.cloneMeta.pagerSizeOpts) {
        this.pager.pageSizeOpts = this.cloneMeta.pagerSizeOpts;
      }
      this.pager.pageSize = this.pager.pageSizeOpts[0];
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ivu-input-number{
  float: left !important;
  width:70px !important;
  margin-right: 5px;
}
/deep/.productEditQuantity .unit{
  line-height: 32px;
}
.product-ref-center /deep/ .mh54 {
  max-height: initial;
}
</style>
