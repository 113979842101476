import { getProductGroups } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const productGroupsRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'value',
      refName: 'value',
      // 传递过去的参数
      passedparams: [
        {
          name: 'productGroupId',
          v1: 0, // 该值会变，不同的值获取不同的内容
          isSkip: false,
          isOuterField: false,
          valueType: 'Long',
          conditionType: 'eq'
        }
      ],
      queryschema: [
        {
          name: 'value',
          controlType: 'String',
          valueType: 'STRING',
          groupType: 2,
          isShowIt: true,
          placeholder: vm.mlang('nameOrCode'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          fieldName: 'code'
        },
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'name'
        }
        // {
        //   showCaption: vm.mlang('bizName'),
        //   isShowIt: vm.$store.getters.currentUser.__showBiz,
        //   fieldName: 'bizName',
        //   width: 120
        // },
        // {
        //   showCaption: vm.mlang('org'),
        //   isShowIt: vm.$store.getters.currentUser.showOrg,
        //   fieldName: 'orgName',
        //   width: 120
        // }
      ],
      actions: { queryList: getProductGroups },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default productGroupsRefer;
