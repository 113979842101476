import errorHandler from '@/libs/errorHandler';
const mixin = {
  data() {
    return {
      imgNames: ['jpg', 'jpeg', 'png', 'bmp'],
      fileExts: ['.jpg', '.jpeg', '.png', '.bmp', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.txt'],
      fileNames: ['jpg', 'jpeg', 'png', 'bmp', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt']
    };
  },
  mounted() {},
  computed: {},

  methods: {
    uploadBtnText(control) {
      const uploadTxt = this.mlang('upload');
      const ext = control.type === 'image' ? this.mlang('image') : this.mlang('file');
      return uploadTxt + ext;
    },
    uploadDesc(control) {
      return `（${this.mlang('geshi')}：${this.uploadFormat(control).join('、')}, ${this.mlang('wenjiandaxiaobuchaoguo')}<span class="text-danger">${control.maxSize}M</span> ,${this.mlang('zongwenjianbuchaoguo')}<span class="text-danger">${control.maxQuantity}${this.mlang('fen')}</span>）`;
    },
    uploadFormat(control) {
      return control.type === 'image' ? this.imgNames : this.fileNames;
    },
    handleUploadMaxSize(control) {
      const fn = (file) => {
        this.$Message.warning({
          content: this.mlang('overFileLimitSize'),
          desc: `${this.mlang('file')}  ${file.name} ${this.mlang('tooBig')}, ${this.mlang('cantover')} ${control.maxSize}M.`,
          duration: 5
        });
      };
      return fn;
    },
    handleBeforeUpload(control) {
      const fn = (file) => {
        const ctrl = this.$refs.upload[0] || this.$refs.upload;
        const check = ctrl.fileList.length < control.maxQuantity;
        if (!check) {
          this.$Message.warning({
            content: `${this.mlang('maxUpload')}${control.maxQuantity}${this.mlang('ge')}`,
            duration: 5
          });
        }
        return check;
      };
      return fn;
    },
    handleFormatError(control) {
      const fn = (file) => {
        this.$Message.warning({
          content: this.mlang('fileFormatError'),
          desc: `${this.mlang('file')}${file.name}${this.mlang('errorFormatqingchongxuan')}`
        });
      };
      return fn;
    },
    handleUploadProgress(control) {
      const fn = (event, file, fileList) => {
        if (file.percentage === 0) {
          this.$Message.info({
            content: this.mlang('uploading'),
            duration: 2
          });
        }
        this.$Loading.update(file.percentage);
      };
      return fn;
    },
    handleUploadSuccess(control) {
      const self = this;
      const fn = (res, file, fileList) => {
        const data = errorHandler(res);
        self.$util.uploadDataFormat(data);
        data.actionStatus = 'C';

        fileList.map((v, index) => {
          if (v.uid === file.uid) {
            delete v.response;
            fileList[index] = Object.assign({}, v, data);
          }
        });
        const notFinish = fileList.find((v) => {
          return v.status !== 'finished';
        });
        if (!notFinish) {
          this.$Message.success({
            content: this.mlang('uploadSuccess'),
            duration: 2
          });
          this.$Loading.finish();
          this.handleUploadChange(fileList, control);
        }
      };
      return fn;
    },
    uploadAccept(control) {
      if (control.type === 'image') {
        return 'image/*';
      } else {
        return '*/*';
      }
    }
  }
};

export default mixin;
