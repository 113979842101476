export default {
  user: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000527","人员") /* "人员" */,
  role: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000528","角色") /* "角色" */,
  pricePlate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000529","价盘") /* "价盘" */,
  refunds: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052A","退款管理") /* "退款管理" */,
  refund: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052B","退款详情") /* "退款详情" */,
  payments: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052C","收款管理") /* "收款管理" */,
  withdrawalmngs: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052D","提现管理") /* "提现管理" */,
  refundRules: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052E","费用使用规则") /* "费用使用规则" */,
  pricePlates: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052F","价盘管理") /* "价盘管理" */,
  orders: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000530","销售订单") /* "销售订单" */,
  presaleOrders: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000531","预售订单") /* "预售订单" */,
  deliverys: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000532","发货单") /* "发货单" */,
  salereturns: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000533","退货单") /* "退货单" */,
  managementList: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000534","租户") /* "租户" */,
  managementDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000534","租户") /* "租户" */,
  operator: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000535","操作记录") /* "操作记录" */,
  expenses: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000536","费用管理") /* "费用管理" */,
  corp_home_index: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000537","首页") /* "首页" */,
  settlementMethods: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800046","结算方式") /* "结算方式" */,
  deliveryMethods: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000538","发运方式") /* "发运方式" */,
  expenseOrderTypes: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000539","费用类型") /* "费用类型" */,
  channelTransactions: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053A","渠道交易关系") /* "渠道交易关系" */,
  channelagents: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053B","渠道客户管理") /* "渠道客户管理" */,
  customerGroups: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053C","客户分组") /* "客户分组" */,
  logistics: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800045","物流公司") /* "物流公司" */,
  stores: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053D","门店等级") /* "门店等级" */,
  authSwitch: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053E","权限开关") /* "权限开关" */,
  defines: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053F","自定义项设置") /* "自定义项设置" */,
  systemLog: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000540","系统日志") /* "系统日志" */,
  shareRules: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000541","分摊规则") /* "分摊规则" */,
  shareRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000541","分摊规则") /* "分摊规则" */,
  roles: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000528","角色") /* "角色" */,
  users: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000542","人员设置") /* "人员设置" */,
  promotionAd: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000543","APP促销广告") /* "APP促销广告" */,
  themeSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000544","主题风格") /* "主题风格" */,
  licence: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000545","订购信息") /* "订购信息" */,
  invite: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000546","邀请注册") /* "邀请注册" */,
  contacters: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000547","业务员") /* "业务员" */,
  contacter: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000547","业务员") /* "业务员" */,
  base: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000548","账户管理") /* "账户管理" */,
  usersafe: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000548","账户管理") /* "账户管理" */,
  logisticsCompanys: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800045","物流公司") /* "物流公司" */,
  permissionSwitch: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053E","权限开关") /* "权限开关" */,
  productGroups: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000549","商品分组") /* "商品分组" */,
  productGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000549","商品分组") /* "商品分组" */,
  orgs: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500054A","组织") /* "组织" */,
  salesRelationships: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500054B","业务委托关系") /* "业务委托关系" */,
  salesRelationship: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500054C","销售业务委托关系") /* "销售业务委托关系" */,
  departments: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500054D","部门") /* "部门" */,
  extApis: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500054E","拓展开发配置") /* "拓展开发配置" */,
  extApi: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500054E","拓展开发配置") /* "拓展开发配置" */,
  errorLogs: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500054F","集成日志") /* "集成日志" */,
  msgQueueLogs: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000550","消息列队日志") /* "消息列队日志" */,
  merchants: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000551","商家列表") /* "商家列表" */,
  'platform-order': lang.templateByUuid("UID:P_UDHWN_17DD50DE05000552","商家订单管理") /* "商家订单管理" */,
  'platform-salereturns': lang.templateByUuid("UID:P_UDHWN_17DD50DE05000553","商家退货管理") /* "商家退货管理" */,
  printSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000554","打印模板") /* "打印模板" */,
  shippingChoices: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000538","发运方式") /* "发运方式" */,
  deliveryList: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000555","发货列表") /* "发货列表" */,
  rebateRuleList: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052E","费用使用规则") /* "费用使用规则" */,
  orderShortageList: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000556","欠货明细表") /* "欠货明细表" */,
  productclass: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000557","商品分类") /* "商品分类" */,
  noticeclasses: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000558","公告分类") /* "公告分类" */,
  noticeclass: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000559","公告分类详情") /* "公告分类详情" */,
  stockInventorys: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055A","库存量管理") /* "库存量管理" */,
  stockInventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055A","库存量管理") /* "库存量管理" */,
  stockinventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055B","库存量详情") /* "库存量详情" */,
  customerAreas: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055C","客户区域") /* "客户区域" */,
  customerCategorys: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055D","客户分类") /* "客户分类" */,
  customerLevels: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055E","客户级别") /* "客户级别" */,
  industrySettings: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055F","客户行业") /* "客户行业" */,
  customerList: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  helpCenterSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000561","帮助中心") /* "帮助中心" */,
  potentialAgents: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000562","潜在客户") /* "潜在客户" */,
  noticeSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000563","公告") /* "公告" */,
  noticeClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000558","公告分类") /* "公告分类" */,
  functionOptions: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000564","业务选项") /* "业务选项" */,
  orderAuditRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000565","校验规则设定") /* "校验规则设定" */,
  transactionTypes: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000566","交易类型") /* "交易类型" */,
  transactionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000566","交易类型") /* "交易类型" */,
  agreement: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000567","服务协议") /* "服务协议" */,
  corprations: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000568","企业信息设置") /* "企业信息设置" */,
  stocks: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000569","仓库设置") /* "仓库设置" */,
  stock: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056A","仓库") /* "仓库" */,
  corpbase: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056B","企业基本信息") /* "企业基本信息" */,
  contactway: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056C","客服信息") /* "客服信息" */,
  agentPriceSettingShowes: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056D","客户价格设置") /* "客户价格设置" */,
  agentPriceSettingShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056D","客户价格设置") /* "客户价格设置" */,
  adpicturesettings: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800047","广告图片设置") /* "广告图片设置" */,
  adpicturesetting: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800048","广告图片") /* "广告图片" */,
  productPromotion: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056E","商品促销") /* "商品促销" */,
  productSalesListShowOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056E","商品促销") /* "商品促销" */,
  agentIndustry: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055F","客户行业") /* "客户行业" */,

  // FLH Begin
  productPriceSettingShows: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056F","商品价格设置") /* "商品价格设置" */, // FLH
  productPriceSettingShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056F","商品价格设置") /* "商品价格设置" */,
  productPriceShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000570","商品价格表") /* "商品价格表" */,
  agnPriceShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000571","客户价格表") /* "客户价格表" */,
  agentSalesShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000572","客户折扣(商品分类)") /* "客户折扣(商品分类)" */,
  agentSalesShow2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000573","客户折扣设置列表(商品)") /* "客户折扣设置列表(商品)" */,
  productLimit: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000574","商品限购列表") /* "商品限购列表" */,
  productLimitDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000575","商品限购") /* "商品限购" */,
  exeStatusInfo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000576","限购执行情况") /* "限购执行情况" */,
  rebateShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000577","现金折扣类费用") /* "现金折扣类费用" */,
  rebateShow2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000578","商品类费用") /* "商品类费用" */,
  combine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000579","组合") /* "组合" */,
  combinationSales: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057A","组合销售") /* "组合销售" */,
  bizCombinationSales: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057A","组合销售") /* "组合销售" */,
  combineSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057A","组合销售") /* "组合销售" */,
  combineSettingOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057A","组合销售") /* "组合销售" */,
  singleDiscountListShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057B","整单优惠列表") /* "整单优惠列表" */,
  bizSingleDiscountListShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057B","整单优惠列表") /* "整单优惠列表" */,
  bizAccumulationPromotions: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057C","累计量促销列表") /* "累计量促销列表" */,
  productSalesListShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057D","商品促销列表") /* "商品促销列表" */,
  bizProductSalesListShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057D","商品促销列表") /* "商品促销列表" */,
  singlediscountshow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057E","整单优惠") /* "整单优惠" */,
  singleDiscountDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057E","整单优惠") /* "整单优惠" */,
  singleDiscountOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057E","整单优惠") /* "整单优惠" */,
  showStockTipSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057F","库存提示设置列表") /* "库存提示设置列表" */,
  accessorieSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000580","推荐配件设置列表") /* "推荐配件设置列表" */,
  productpromotion: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500056E","商品促销") /* "商品促销" */,

  merchantsNoticeSettings: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000581","商家公告管理") /* "商家公告管理" */,
  merchantsNoticeSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000582","商家公告") /* "商家公告" */,
  merchantsPromotionAds: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000583","商家app促销广告") /* "商家app促销广告" */,
  merchantsPromotionAd: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000583","商家app促销广告") /* "商家app促销广告" */,
  shortageDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000556","欠货明细表") /* "欠货明细表" */,
  fundAccountList: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000584","客户资金账户列表") /* "客户资金账户列表" */,
  showStatistics: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000585","客户往来对账") /* "客户往来对账" */,
  voucherClear: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000586","单据清理") /* "单据清理" */,
  settlementMethod: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800046","结算方式") /* "结算方式" */,
  menuSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000587","菜单管理") /* "菜单管理" */,
  rebateSourceList: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000588","费用来源") /* "费用来源" */,
  rebateSourceCard: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000588","费用来源") /* "费用来源" */,
  // FLH End
  fundaccount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000589","线上支付") /* "线上支付" */,
  bankDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058A","线下支付") /* "线下支付" */,
  expense: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000536","费用管理") /* "费用管理" */,
  refundRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500052E","费用使用规则") /* "费用使用规则" */,
  discountRebate: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058B","金额费用") /* "金额费用" */,
  goodsRebate: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058C","商品费用") /* "商品费用" */,
  agentbase: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  potentialAgent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000562","潜在客户") /* "潜在客户" */,
  logisticsCompany: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800045","物流公司") /* "物流公司" */,
  channelagent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053B","渠道客户管理") /* "渠道客户管理" */,
  productline: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058D","产品线") /* "产品线" */,
  channelTransaction: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053A","渠道交易关系") /* "渠道交易关系" */,
  wxMoneyRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058E","微信红包规则") /* "微信红包规则" */,
  customerLevel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500055E","客户级别") /* "客户级别" */,
  brand: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058F","品牌") /* "品牌" */,
  customerDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  expenseOrderType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000539","费用类型") /* "费用类型" */,
  freightTemplates: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000590","运费模板") /* "运费模板" */,
  freightTemplate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000590","运费模板") /* "运费模板" */,
  customerGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500053C","客户分组") /* "客户分组" */,
  shippingChoice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000538","发运方式") /* "发运方式" */,
  exceptAgentEditArchive: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000591","例外客户") /* "例外客户" */,
  inventorylimit: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000592","例外商品") /* "例外商品" */,
  inventoryCheckSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000593","规则设置") /* "规则设置" */,
  orderConfirmationStrategy: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000594","订单自动确认条件") /* "订单自动确认条件" */,
  autoSeparateBillRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000595","分单规则") /* "分单规则" */,
  message: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000596","消息列表") /* "消息列表" */,
  payment: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000597","付款单") /* "付款单" */,
  temporaryCredits: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000598","客户临时信用") /* "客户临时信用" */,
  temporaryCredit: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000598","客户临时信用") /* "客户临时信用" */,
  agentCreditsArchives: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000599","对象信用档案") /* "对象信用档案" */,
  signInRecords: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059A","签收记录") /* "签收记录" */,
  signIn: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059A","签收记录") /* "签收记录" */,
  agentcontacts: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentaddress: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentbank: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentinvoice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentpoint: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentcoupon: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentcontactsdetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentcontactslist: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentaddressdetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentaddresslist: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentbankdetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentbanklist: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentinvoicedetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  agentinvoicelist: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000560","客户") /* "客户" */,
  customerDiscProd: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059B","客户折扣(商品)") /* "客户折扣(商品)" */,
  customerDiscProdCate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000572","客户折扣(商品分类)") /* "客户折扣(商品分类)" */,
  onlinePaySetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000589","线上支付") /* "线上支付" */,
  offlinePaySettings: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058A","线下支付") /* "线下支付" */,
  offlinePaySetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500058A","线下支付") /* "线下支付" */,
  commission: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059C","佣金") /* "佣金" */,
  creditCalculation: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059D","信用计算") /* "信用计算" */,
  creditConsumption: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059E","信用耗用") /* "信用耗用" */,
  creditConsumptionBill: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059E","信用耗用") /* "信用耗用" */,
  objectCredits: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059F","信用对象") /* "信用对象" */,
  objectCredit: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500059F","信用对象") /* "信用对象" */,
  creditRules: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A0","信用检查规则") /* "信用检查规则" */,
  creditRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A0","信用检查规则") /* "信用检查规则" */,
  singleDiscountShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500057E","整单优惠") /* "整单优惠" */,
  unit: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A1","单位") /* "单位" */,
  userBase: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A2","个人信息") /* "个人信息" */,
  corpErpIntegrationOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A3","ERP集成") /* "ERP集成" */,
  quickPays: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A4","快捷支付") /* "快捷支付" */,
  quickPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A4","快捷支付") /* "快捷支付" */,
  corpERPIntegrationOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A3","ERP集成") /* "ERP集成" */,
  customerFundAccount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A5","客户资金账户") /* "客户资金账户" */,
  customerFundAccountRebate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A6","客户资金账户费用") /* "客户资金账户费用" */,
  customerFundAccountPayMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A7","客户资金账户预付款") /* "客户资金账户预付款" */,
  bankConfig: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A8","银行配置") /* "银行配置" */,
  mainBankConfig: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A8","银行配置") /* "银行配置" */,
  subBankConfig: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A8","银行配置") /* "银行配置" */,
  promotionManager: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005A9","适用组织") /* "适用组织" */,
  showStockTipSettingDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005AA","库存提示设置") /* "库存提示设置" */,
  ProductListTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005AB","商品列表") /* "商品列表" */,
  decorates: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005AC","页面装修") /* "页面装修" */,
  orderHistory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000530","销售订单") /* "销售订单" */,
  deliveryHistory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000532","发货单") /* "发货单" */,
  saleReturnHistory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000533","退货单") /* "退货单" */,
  paymentHistory: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005AD","收款单") /* "收款单" */,
  billItemConfig: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005AE","元数据配置") /* "元数据配置" */
};
