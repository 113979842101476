export default {
  customer: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500084A","客户") /* "客户" */,
  area: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500084B","区域") /* "区域" */,
  classification: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500084C","分类") /* "分类" */,
  level: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500084D","级别") /* "级别" */,
  effect: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500084E","整单生效") /* "整单生效" */,
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500084F","确定执行整单生效?") /* "确定执行整单生效?" */,
  delContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000850","确定删除该项吗?") /* "确定删除该项吗?" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000851","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000852","条") /* "条" */,
  channelLevel: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000853","渠道等级") /* "渠道等级" */,
  endTimeBeGreaterThanStartTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000854","结束时间必须大于开始时间") /* "结束时间必须大于开始时间" */,
  endTimeBeGreaterThanCurrentTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000855","结束时间必须大于当前时间") /* "结束时间必须大于当前时间" */,
  switchDimensionTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000856","切换价格维度将会重置列表中的数据,确定切换吗?") /* "切换价格维度将会重置列表中的数据,确定切换吗?" */,
  selectProductFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000857","请先选择商品！") /* "请先选择商品！" */
};
