<template>
  <div class="textarea-warp">
    <Input
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      :autofocus="autofocus"
      :rows="rows"
      type="textarea"
      @input="emitChange">
    </Input>
    <span
      v-if="maxlength && !currentUser.__isAgentSide"
      class="textarea-word-tips">{{ mlang('haikeyishuru') }}<span class="textarea-warp-span">{{ remnant }}</span>{{ mlang('gezi') }}</span>
    <span
      v-if="maxlength && currentUser.__isAgentSide"
      class="textarea-word-tips"><span class="textarea-warp-span">{{ remnant }}</span>/{{ maxlength }}</span>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    maxlength: {
      default: 255,
      type: Number
    },
    value: {
      type: [String, Number],
      default: ''
    },
    rows: {
      type: Number,
      default: 6
    }
  },
  data() {
    return {
      remnant: 255
    };
  },
  computed: {
    currentUser() {
      return window.__app.mainV.$store.getters.currentUser;
    }
  },
  watch: {
    value: {
      handler(val) {
        this.setRemnant(val);
      }
    }
  },
  methods: {
    setRemnant(value) {
      if (value) {
        var len = this.maxlength - value.length;
        this.remnant = len > 0 ? len : 0;
      } else {
        this.remnant = this.maxlength;
      }
    },
    emitChange(val) {
      this.$emit('input', val); // 实现组件的数据双向绑定
      this.$emit('on-change', val);
      this.setRemnant(val);
    }
  }
};
</script>
