import corpParentView from '@/views/corp/home/home.vue';

const platform = {
  path: 'platform',
  title: { i18n: 'platform' },
  name: 'platform',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'merchantsOrders',
      name: 'merchantsOrders',
      meta: {
        module: 'merchantsOrders'
      },
      component: () => import('@/views/corp/platform/components/merchantsOrders/merchantsOrders.vue')
    },
    {
      path: 'merchantsSaleReturns',
      name: 'merchantsSaleReturns',
      meta: {
        module: 'merchantsSaleReturns'
      },
      component: () => import('@/views/corp/platform/components/merchantsSaleReturns/merchantsSaleReturns.vue')
    },
    {
      path: 'merchantsDeliverys',
      name: 'merchantsDeliverys',
      meta: {
        module: 'merchantsDeliverys'
      },
      component: () => import('@/views/corp/platform/components/merchantsDeliverys/merchantsDeliverys.vue')
    },
    {
      path: 'merchants',
      title: { i18n: 'title.merchants' },
      name: 'merchants',
      component: () => import('@/views/corp/platform/components/merchants.vue')
    },
    {
      path: 'merchantsNoticeSettings',
      name: 'merchantsNoticeSettings',
      title: { i18n: 'title.merchantsNoticeSettings' },
      meta: {
        module: 'merchantsNoticeSettings'
      },
      component: () => import('@/views/corp/platform/components/merchantsNoticeSettings/merchantsNoticeSettings.vue')
    },
    {
      path: 'merchantsNoticeSetting',
      name: 'merchantsNoticeSetting',
      title: { i18n: 'title.merchantsNoticeSettings' },
      meta: {
        module: 'merchantsNoticeSettings',
        notCache:true
      },
      component: () => import('@/views/corp/platform/components/merchantsNoticeSettings/merchantsNoticeSetting.vue')
    },
    {
      path: 'merchantsPromotionAds',
      name: 'merchantsPromotionAds',
      title: { i18n: 'title.merchantsPromotionAds' },
      meta: {
        module: 'merchantsPromotionAds'
      },
      component: () => import('@/views/corp/platform/components/merchantsPromotionAds/merchantsPromotionAds.vue')
    },
    {
      path: 'merchantsPromotionAd',
      name: 'merchantsPromotionAd',
      title: { i18n: 'title.merchantsPromotionAds' },
      meta: {
        module: 'merchantsPromotionAds',
        notCache:true
      },
      component: () => import('@/views/corp/platform/components/merchantsPromotionAds/merchantsPromotionAd.vue')
    }
  ]
};
export default platform;
