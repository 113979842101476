import ajax from '@/libs/ajax';

// 商品促销
export const editProductPromotion = (data) => {
  return ajax.request({
    url: `/goods/promotion/product/${data.id}?isCopy=${data.isCopy}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const saveProductPromotion = (data) => {
  return ajax.request({
    url: '/goods/promotion/product/',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 组合促销查看执行情况
export const getProductPromotionExeStatusList = (query) => {
  return ajax.request({
    url: '/goods/promotion/getProductPreferentialRecord',
    method: 'POST',
    data: {
      data: query.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const submitProductDetailData = (data) => {
  let res = ajax.request({
    url: '/bill/submit',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    }
  });
  return res;
};
export const unSubmitProductDetailData = (data) => {
  return ajax.request({
    url: '/bill/unsubmit',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    }
  });
};
