<template>
  <div>
    <slot name="extend"></slot>
    <ImportExcel
      :biz-meta="bizMeta"
      :btn-type="btnType"
      @on-uploading="handleOnUploading"
      @on-upload-success="handleOnUploadSuccess"
      @on-upload-success-ys="handleOnUploadSuccessYs"
      @on-upload-success-new="handleOnUploadSuccessNew"></ImportExcel>
    <ExportExcel
      :btn-type="btnType"
      :biz-meta="bizMeta"
      :export-batch="exportBatch"
      @on-click="handleExportClick"></ExportExcel>
    <!--弹窗相关的部分-->
    <ExImportResultModal
      ref="exImportResultModal"
      :biz-meta="bizMeta"
      :import-result="importResult"></ExImportResultModal>
      <!---->
  </div>
</template>

<script>
import ImportExcel from './import-excel';
import ExportExcel from './export-excel';
import ExImportResult from './im-or-export-result';
import ExImportResultModal from './ex-or-import-result-modal/ex-or-import-result-modal.vue'; // 弹窗相关的部分
import { getImportExportStatus, exportExcel, getExportStatusYS } from '@/api/import';
import { getYHTtoken } from '@/api/order';
export default {
  name: 'ImportExport',
  components: {
    ImportExcel,
    ExportExcel,
    ExImportResult,
    ExImportResultModal // 弹窗相关的部分
  },
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    getQueryschema: {
      type: Function
    },
    getSelectRowsIds: {
      type: Function
    },
    btnType: {
      type: String,
      default: 'default'
    },
    exportBatch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      timer: null,
      uploadingNum: 0,
      importErrorCount: 0,
      showImportProcess: false,
      showExport: false,
      showImportResult: false,
      exporting: false,
      importList: [],
      exportList: [],
      importResult: {} // 弹窗相关的部分
    };
  },
  methods: {
    handleOnUploadSuccess(result, file) {
      this.showImportProcess = false;
      this.uploadingNum = 0;
      this.showImportResult = true;
      this.importErrorCount = (result && result.errorCount) || 0;
      const uploadFile = this.importList.find((v) => {
        return v.uid === file.uid;
      });
      if (uploadFile) {
        uploadFile.num = 0;
        uploadFile.errorFile = result.errorFile;
        uploadFile.errorCount = this.importErrorCount;
      } else {
        this.importList.push({
          num: 0,
          errorFile: result.errorFile,
          errorCount: result.errorCount,
          ...file
        });
      }
      this.$emit('import-success');
      // 弹窗相关的部分
      this.importResult = result;
      this.showImportTip(this.showImportResult, this.importErrorCount);
      //
    },
    handleOnUploadSuccessNew(result, file) {
      this.showImportProcess = false;
      this.uploadingNum = 0;
      this.showImportResult = true;
      this.importErrorCount = result && result.errorCount;
      const uploadFile = this.importList.find((v) => {
        return v.uid === file.uid;
      });
      if (uploadFile) {
        uploadFile.num = 0;
        uploadFile.errorFile = result.filePath;
        uploadFile.errorCount = this.importErrorCount;
      } else {
        this.importList.push({
          num: 0,
          errorFile: result.filePath,
          errorCount: result.errorCount,
          ...file
        });
      }
      this.$emit('import-success');
      // 弹窗相关的部分
      this.importResult = result || {};
      this.importResult.errorFile = result.filePath;
      this.showImportTip(this.showImportResult, this.importErrorCount);
      //
    },
    handleOnUploadSuccessYs(result, file = {}) {
      this.showImportResult = true;
      this.importErrorCount = result && result.failCount;
      // 弹窗相关的部分
      this.importResult = result || {};
      this.showImportTip(this.showImportResult, this.importErrorCount);
      //
    },
    showImportTip(showImportResult, importErrorCount) {
      let isShowErrorModal = showImportResult && importErrorCount;
      if (isShowErrorModal) {
        this.$refs['exImportResultModal'].isShowModal();
      } else {
        // this.$Modal.warning({
        //   content: this.mlang('importSuccess')
        // });
        this.$Message.success(this.mlang('importSuccess'));
      }
    },
    handleOnUploading(num, file) {
      this.showImportProcess = true;
      this.showImportResult = false;
      this.showExport = false;
      this.uploadingNum = num;
      const uploadFile = this.importList.find((v) => {
        return v.uid === file.uid;
      });
      if (uploadFile) {
        uploadFile.num = num;
      } else {
        file.num = num;
        this.importList.push(file);
      }
    },
    exportExcel(queryschema) {
      this.showImportProcess = false;
      this.showImportResult = false;

      if (!this.bizMeta.export.action && !this.bizMeta.export.enumName && !this.bizMeta.export.isSaleExport) {
        console.error('Meta.export.action or enumName is must not null '); //eslint-disable-line
        return;
      }
      if (!this.bizMeta.export.isSaleExport) {
        queryschema && (queryschema.datatype = 'json');
        if (queryschema && queryschema.querySchema) {
          delete queryschema.querySchema.pager;
        }
      }
      const exportFn = (query) => {
        const file = {
          fileName: this.mlang('daoruwenjian'),
          filePath: null,
          exporting: true
        };
        const queryStatus = (data) => {
          getImportExportStatus(data.jobName).then((res) => {
            if (res.status === 'COMPLETED') { // 导出完成
              if (this.timer) clearTimeout(this.timer);
              this.exporting = false;
              file.fileName = res.fileName;
              file.filePath = res.filePath;
              file.exporting = false;
              this.$download(file.filePath && file.filePath);
              // 弹窗相关的部分
              // this.$Modal.warning({
              //   content: this.mlang('exportSuccess')
              // });
              this.$Message.success(this.mlang('exportSuccess'));
              //
            } else if (res.status === 'FAILED' || res.status === 'STOPPED') { // 导出失败
            // 弹窗相关的部分
              this.$Modal.warning({
                content: (res.message || this.mlang('exportFail'))
              });
              //
            } else {
              this.timer = setTimeout(() => {
                queryStatus(data);
              }, 1000);
            }
          });
        };
        // 调用销售服务的导出事件查询导出状态
        const querySaleExportStatus = (data) => {
          getExportStatusYS(data, this.bizMeta.domainKey || 'udinghuo').then(async(res) => {
            let result = JSON.parse(res);
            const percentage = parseFloat(result.percentage || '0');
            switch (true) {
              case (result.flag === 0):
                result.status = 'FAILED';
                break;
              case (result.flag === 1 && percentage >= 100):
                result.status = 'COMPLETED';
                break;
            }
            if (result.status === 'COMPLETED') { // 导出完成
              if (this.timer) clearTimeout(this.timer);
              const { domainKey } = this.bizMeta;
              // let serviceUrl = domainKey === 'ustock'
              //   ? window.__app.context.HTTP_EXPORT_SCM_STOCK
              //   : domainKey === 'marketingbill'
              //     ? window.__app.context.HTTP_MARKET_BASEURL
              //     : window.__app.context.HTTP_EXPORT_SCM_SCMSA;
              let domainKeyObj = {
                ustock: 'yonbip-scm-stock',
                marketingbill: 'yonbip-mkt-mkc2b',
                udinghuo: 'yonbip-scm-scmsa'
              };
              let serviceUrl = domainKeyObj[domainKey || 'udinghuo'];
              let token = (await getYHTtoken()).token;
              let path = `${window.__app.context.publicDomain}/${serviceUrl}/billtemp/downloadFileStream?asyncKey=${data}&domainKey=${domainKey || 'udinghuo'}&yht_access_token=${token}`;
              this.exporting = false;
              file.filePath = path;
              file.fileName = this.bizMeta.export.fileName;
              this.$downloadFileWithPath(file.filePath);
              this.$Message.success(this.mlang('exportSuccess'));
            } else if (result.status === 'FAILED' || result.status === 'STOPPED') { // 导出失败
              this.$Modal.warning({
                content: (result.message || this.mlang('exportFail'))
              });
            } else {
              this.timer = setTimeout(() => {
                querySaleExportStatus(data);
              }, 1000);
            }
          });
        };

        const queryFn = () => {
          if (this.bizMeta.export.action) { // 旧的
            query && (query.exportExcel = 1); // 导入导出特殊标记
            this.bizMeta.export.action(query).then((data) => {
              if (data[1]) {
                if (this.timer) clearTimeout(this.timer);
                this.exporting = false;
                file.fileName = data[0].fileName;
                file.filePath = data[0].filePath;
                file.exporting = false;
                this.$download(file.filePath && file.filePath);
                // 弹窗相关的部分
                // this.$Modal.warning({
                //   content: this.mlang('exportSuccess')
                // });
                this.$Message.success(this.mlang('exportSuccess'));
                //
              } else {
                this.timer = setTimeout(() => {
                  queryFn();
                }, 1000);
              }
            });
          } else if (this.bizMeta.export.enumName) {
            exportExcel(query, this.bizMeta.export.enumName).then(data => {
              queryStatus(data);
            });
          } else if (this.bizMeta.export.isSaleExport) { // 销售服务的导出
            if (this.bizMeta.export.saleService) {
              this.bizMeta.export.saleService(query).then((data) => {
                if (!data) {
                  this.$Message.error('没有相应权限请先授权');
                }
                if (data && data.infos && data.infos[0] && data.infos[0].asyncKey) {
                  querySaleExportStatus(data.infos[0].asyncKey);
                }
              });
            }
          }
        };
        this.showExport = true;
        this.exporting = true;
        this.exportList.push(file);
        queryFn();
      };
      if (this.bizMeta.export.beforeExport) {
        const query = this.bizMeta.export.beforeExport(queryschema, exportFn);
        if (query !== false) {
          exportFn(query);
        }
      } else {
        exportFn(queryschema);
      }
    },
    exportAll() {
      const query = {
        querySchema: {
          conditions: []
        }
      };
      this.exportExcel(query);
    },
    exportByQuery() {
      const query = this.getQueryschema();
      this.exportExcel(query);
    },
    exportById() {
      const ids = this.getSelectRowsIds();
      if (!ids || !ids.length) {
        this.$Modal.error({
          content: this.mlang('pleaseSelectExportData')
        });
        return;
      }
      // 如果换成销售服务导出
      if (this.bizMeta.export.isSaleExport) {
        this.exportExcel({ 'ids': ids.join(',') });
      } else {
        const query = this.getQueryschema();
        const idConditon = {
          conditionType: 'in',
          name: 'id',
          v1: ids.join(','),
          valueType: 'LONG'
        };
        query.querySchema.conditions.push(idConditon);
        this.exportExcel(query);
      }
    },
    handleExportClick(type) {
      if (type === 'all') {
        this.exportAll();
      } else if (type === 'condition') {
        this.exportByQuery();
      } else if (type === 'id') {
        this.exportById();
      }
    }
  }
};
</script>
