import ajax from '@/libs/ajax';

export const getList = (data) => {
  return ajax
    .request({
      url: '/archives/image/advertisementimage/queryschema',
      method: 'post',
      data: {
        data: data.querySchema,
        datatype: 'json',
        isArchive: 1
      }
    });
};
export const del = (id) => {
  return ajax
    .request({
      url: `/archives/image/advertisementimage/${id}`,
      method: 'DELETE',
      data: {
        isArchive: 1
      }
    });
};

// 促销广告详情
export const detail = (id) => {
  return ajax
    .request({
      url: `/archives/image/advertisementimage/${id}`,
      method: 'get',
      data: {
        isArchive: 1
      }
    });
};

// 促销广告详情保存
export const save = (data) => {
  return ajax
    .request({
      url: '/archives/image/advertisementimage',
      method: 'post',
      data: {
        data,
        isArchive: 1,
        datatype: 'json'
      }
    });
};

// 促销广告启用停用
export const statusChangeApi = (data, valid) => {
  return ajax
    .request({
      url: `/archives/image/advertisementimage/${data.id}/${valid}`,
      method: 'post',
      data: {
        isArchive: 1,
        datatype: 'json'
      }
    });
};

// 根据图片id查询促销广告id
export const getPromotionIdWithPictureId = (id) => {
  return ajax
    .request({
      url: `/archives/image/carouselimage/${id}/promotion`,
      method: 'get',
      data: {
        isArchive: 1
      }
    });
};
