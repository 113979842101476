export default {
  i: {
    locale: 'zh-CN',
    select: {
      placeholder: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002DD","请选择") /* "请选择" */,
      noMatch: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002DE","无匹配数据") /* "无匹配数据" */,
      loading: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002DF","加载中") /* "加载中" */
    },
    table: {
      noDataText: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E0","暂无数据") /* "暂无数据" */,
      noFilteredDataText: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E1","暂无筛选结果") /* "暂无筛选结果" */,
      confirmFilter: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E2","筛选") /* "筛选" */,
      resetFilter: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580000C","重置") /* "重置" */,
      clearFilter: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E3","全部") /* "全部" */
    },
    datepicker: {
      selectDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E4","选择日期") /* "选择日期" */,
      selectTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E5","选择时间") /* "选择时间" */,
      startTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E6","开始时间") /* "开始时间" */,
      endTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E7","结束时间") /* "结束时间" */,
      clear: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E8","清空") /* "清空" */,
      ok: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E9","确定") /* "确定" */,
      datePanelLabel: '[yyyy] [mm]',
      month: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002EA","月") /* "月" */,
      month1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002EB","1 月") /* "1 月" */,
      month2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002EC","2 月") /* "2 月" */,
      month3: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002ED","3 月") /* "3 月" */,
      month4: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002EE","4 月") /* "4 月" */,
      month5: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002EF","5 月") /* "5 月" */,
      month6: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F0","6 月") /* "6 月" */,
      month7: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F1","7 月") /* "7 月" */,
      month8: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F2","8 月") /* "8 月" */,
      month9: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F3","9 月") /* "9 月" */,
      month10: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F4","10 月") /* "10 月" */,
      month11: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F5","11 月") /* "11 月" */,
      month12: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F6","12 月") /* "12 月" */,
      year: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F7","年") /* "年" */,
      weekStartDay: '0',
      weeks: {
        sun: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F8","日") /* "日" */,
        mon: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002F9","一") /* "一" */,
        tue: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002FA","二") /* "二" */,
        wed: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002FB","三") /* "三" */,
        thu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002FC","四") /* "四" */,
        fri: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002FD","五") /* "五" */,
        sat: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002FE","六") /* "六" */
      },
      months: {
        m1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002FF","1月") /* "1月" */,
        m2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000300","2月") /* "2月" */,
        m3: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000301","3月") /* "3月" */,
        m4: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000302","4月") /* "4月" */,
        m5: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000303","5月") /* "5月" */,
        m6: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000304","6月") /* "6月" */,
        m7: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000305","7月") /* "7月" */,
        m8: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000306","8月") /* "8月" */,
        m9: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000307","9月") /* "9月" */,
        m10: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000308","10月") /* "10月" */,
        m11: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000309","11月") /* "11月" */,
        m12: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500030A","12月") /* "12月" */
      }
    },
    transfer: {
      titles: {
        source: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500030B","源列表") /* "源列表" */,
        target: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500030C","目的列表") /* "目的列表" */
      },
      filterPlaceholder: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500030D","请输入搜索内容") /* "请输入搜索内容" */,
      notFoundText: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500030E","列表为空") /* "列表为空" */
    },
    modal: {
      okText: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E9","确定") /* "确定" */,
      cancelText: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500030F","取消") /* "取消" */
    },
    poptip: {
      okText: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E9","确定") /* "确定" */,
      cancelText: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500030F","取消") /* "取消" */
    },
    page: {
      prev: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000310","上一页") /* "上一页" */,
      next: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000311","下一页") /* "下一页" */,
      total: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000312","共") /* "共" */,
      item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000313","条") /* "条" */,
      items: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000313","条") /* "条" */,
      prev5: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000314","向前 5 页") /* "向前 5 页" */,
      next5: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000315","向后 5 页") /* "向后 5 页" */,
      page: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000316","条/页") /* "条/页" */,
      goto: lang.templateByUuid("UID:P_UDHWN_1A10FB3005C0000B","转至页") /* "转至页" */,
      p: ''
    },
    rate: {
      star: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000319","星") /* "星" */,
      stars: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000319","星") /* "星" */
    },
    time: {
      before: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500031A","前") /* "前" */,
      after: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500031B","后") /* "后" */,
      just: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500031C","刚刚") /* "刚刚" */,
      seconds: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500031D","秒") /* "秒" */,
      minutes: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500031E","分钟") /* "分钟" */,
      hours: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500031F","小时") /* "小时" */,
      days: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000320","天") /* "天" */
    },
    tree: {
      emptyText: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002E0","暂无数据") /* "暂无数据" */
    }
  }
};
