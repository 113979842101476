<template>
  <div class="bodyFour topvalue-20">
    <Breadcrumb>
      <BreadcrumbItem to="/agent/home">{{ mlang('home') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ currentName }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="bodyFour-router">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      name: 'Orders'
    };
  },
  computed: {
    fourList() {
      let arr = [];
      if (this.$hasAuth('agentOrderShow')) {
        arr.push({ title: this.mlang('orderList'), area: 'v#/agent/aorders/orders', name: 'Orders' });
      }
      if (this.$hasAuth('agentDeliveryList')) {
        arr.push({ title: this.mlang('receivingNote'), area: 'v#/agent/aorders/deliverys', name: 'Delivery' });
      }
      if (this.$hasAuth('agentExpenseOrderList')) {
        arr.push({ title: this.mlang('expenseNote'), area: 'v#/agent/aorders/expenses', name: 'Expenses' });
      }
      if (this.$hasAuth('agentRetunListShow')) {
        arr.push({ title: this.mlang('returnNotes'), area: 'v#/agent/aorders/returnBills', name: 'ReturnBill' });
      }
      arr.push({ title: this.mlang('signNotes'), area: 'v#/agent/aorders/orderSign', name: 'OrderSign' });
      return arr;
    },
    currentName() {
      switch (this.$route.name) {
        case 'Orders':
          return this.mlang('dingdan'); // 订单
        case 'Delivery':
          return this.mlang('receivingNote'); // 收货单
        case 'ReturnBill':
          return this.mlang('returnNotes'); // 退货单
        default:
          return '';
      }
    }
  },
  created() {
    this.name = this.$route.name || 'Orders';
  },
  methods: {
    toList(name) {
      if (name === this.name) {
        return false;
      }
      this.name = name;
      this.$router.push({
        name
      });
    }
  }
};
</script>

<style lang="less" scoped>
span {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
.bodyFour {
  background: #fff;
  /deep/.ivu-tabs-bar{
    margin: 0;
  }
  /deep/.ivu-tabs-nav .ivu-tabs-tab{
    padding: 0 0 8px 0!important;
    width: 110px;
    text-align: center;
  }
  /deep/.ivu-tabs-nav .ivu-tabs-ink-bar{
    width: 14px !important;
    margin: 0 48px;
  }
  // .bodyFour-router{
  //   margin-top: -50px;
  // }
  .ivu-breadcrumb {
    padding-top: 10px;
    padding-left: 10px;
  }
}
.list {
  float: left;
}
.list:last-child {
  border: none;
}
.list > span {
  font-size: 14px;
  margin-right: 2px;
  height: 44px;
  border-radius: 4px 4px 0 0;
  padding: 10px 0;
  margin: 0 20px;
  color: #666;
  position: relative;
}
.list span:after{
      position: absolute;
      bottom:0;
      height:0;
      left:50%;
      transform: translateX(-50%);
      content: '';
      width:14px;
      border-bottom: solid 2px transparent;
}
/*.list .activeList:after{
  border-bottom-color: #505766;
}*/
.orderList_tab .list > a:hover:after {
  border-bottom-color: #505766;
}

.orderList_tab {
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  display: block;
  padding-bottom: 7px;
}
.orderList_tab .activeList {
  border-bottom-color: #505766 !important;
}
.pull-right {
  float: right !important;
}

.btn-warning {
  color: #fff;
  background-color: #f89d3b;
  border-color: #f79122;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 2px 14px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
