export default {
  IsActivation: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000686","您的账号还未激活，请您") /* "您的账号还未激活，请您" */,
  Activation: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000687","立即激活") /* "立即激活" */,
  SwitchingAccount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000688","切换账户") /* "切换账户" */,
  AgentCenter: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000689","订货端") /* "订货端" */,
  BizCenter: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500068A","商家端") /* "商家端" */,
  CorpCenter: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500068B","企业端") /* "企业端" */,
  Verification: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500068C","验证") /* "验证" */,
  mailbox: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500068D","邮箱") /* "邮箱" */,
  VerificationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500068E","验证码") /* "验证码" */,
  VerificationCodePrompt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500068F","请输入验证码") /* "请输入验证码" */,
  ValidationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000690","校证码") /* "校证码" */,
  ValidationCodePrompt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000691","请输入校证码") /* "请输入校证码" */,
  ObtainVerificationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000692","获取校证码") /* "获取校证码" */,
  ImgPrompt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000693","点击更换验证码") /* "点击更换验证码" */,
  Prompt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000694","请先输入验证码") /* "请先输入验证码" */,

  showMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000695","查看全部") /* "查看全部" */,
  cue: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000696","正在发送...") /* "正在发送..." */,
  cueTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000697","秒后重新获取") /* "秒后重新获取" */,
  bizLogo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000698","商") /* "商" */,
  corpLogo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000699","企") /* "企" */,
  agentLogo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500069A","订") /* "订" */
};
