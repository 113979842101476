<template>
  <table :id="'billId_'+metaSetting.billId" :fieldid="'table_billId_'+metaSetting.billId" :style="styleObject" cellspacing="0" cellpadding="0" border="0" :class="tableClass">
    <colgroup :id="'billId_'+metaSetting.billId+'_BodyCol'">
      <col v-for="(column, index) in columns" :key="index" :style="`width: ${colWidth(column, index, false)}px`" />
    </colgroup>
    <tbody :class="[prefixCls + '-tbody']">
      <template v-for="(row, index) in visibleData">
        <Expand
          v-if="hasRenderTr(row._index)"
          :key="row._rowKey"
          :row="row"
          :columns="columns"
          :render="renderTr"
          :index="row.__scIndex === undefined ? row._index :row.__scIndex"
          class="subHeader"></Expand>
        <table-tr
          v-else
          v-show="row.actionStatus !== 'D'"
          :key="row._rowKey"
          :row="row"
          :index="row.__scIndex"
          :suspendkey="suspendkey"
          :prefix-cls="prefixCls"
          @mouseenter.native.stop="handleMouseIn(row._index,suspendkey,index)"
          @mouseleave.native.stop="handleMouseOut(row._index,suspendkey,index)"
          @click.native="clickCurrentRow(row._index)"
          @dblclick.native.stop="dblclickCurrentRow(row._index)">
          <template v-for="(column,colIndex) in columns">
            <td
              v-if="cellMergeInit(row._index,column.key,row,fixed)"
              :key="colIndex"
              :colSpan="setColRowSpan(row._index,column.key,row).colSpan"
              :rowSpan="setColRowSpan(row._index,column.key,row).rowSpan"
              :class="alignCls(column, row)">
              <template v-if="showTd(column)">
                <!--存在列合并-->
                <div v-if="isCellMergeRender(row._index,column.key,row)" class="ivu-table-cell">
                  <span v-if="cellMergeContentType(row._index,column.key,row).isComponent">
                    <component
                      :is="cellMerge(row._index,row,column.key).componentName"
                      :row-data="row"
                      :field="column.key ? column.key : ''"
                      :index="row._index"
                      @on-custom-comp="customCompFunc"></component>
                  </span>
                  <Expand
                    v-else-if="cellMerge(row._index,row,column.key).hasColumnRender"
                    :row="row"
                    :column="column"
                    :index="index"
                    :render="column.render"></Expand>
                  <span v-else v-html="cellMerge(row._index,row,column.key).content"></span>
                </div>
                <Cell
                  v-else
                  :key="column._columnKey"
                  :fixed="fixed"
                  :prefix-cls="prefixCls"
                  :row="row"
                  :column="column"
                  :natural-index="index"
                  :index="row._index"
                  :selected="rowHighlighted(row._index)"
                  :checked="rowChecked(row._index)"
                  :disabled="rowDisabled(row._index)"
                  :expanded="rowExpanded(row._index)"
                  :expanded-tree="rowExpandedTree(row._index)"
                  :row-height="rowHeight"></Cell>
              </template>
            </td>
          </template>
        </table-tr>
        <tr v-if="rowExpandedTree(row._index)" :key="row._rowKey" :class="{[prefixCls + '-expanded-hidden']: fixed}">
          <td :colspan="columns.length" :class="prefixCls + '-expanded-tree-cell'">
            <Table :columns="cloneColumns" :data="row.children" :show-header="false"></Table>
          </td>
        </tr>
        <tr v-if="rowExpanded(row._index)" :key="row._rowKey" :class="{[prefixCls + '-expanded-hidden']: fixed}">
          <td :colspan="columns.length" :class="prefixCls + '-expanded-cell'">
            <Expand :key="row._rowKey" :row="row" :render="expandRender" :index="row._index"></Expand>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>
<script>
// todo :key="row"
import TableTr from './table-tr.vue';
import Cell from './cell.vue';
import Expand from './expand.js';
import Mixin from './mixin';
import bodyCellMergeMixin from './body-cell-merge-mixin.js';
import { deepCopy, findComponentUpward } from '../../utils/assist';
export default {
  name: 'TableBody',
  components: { Cell, Expand, TableTr },
  mixins: [Mixin, bodyCellMergeMixin],
  props: {
    prefixCls: String,
    styleObject: Object,
    columns: Array,
    data: Array, // rebuildData
    objData: [Object, Array],
    columnsWidth: Object,
    fixed: {
      type: [Boolean, String],
      default: false
    },
    subKey: String,
    renderTr: {
      type: Function,
      default() {
        return '';
      }
    },
    // 单元格合并
    cellMerge: Function,
    suspendkey: {
      type: String
    },
    rowHeight: {
      type: Number,
      default: 40
    },
    metaSetting: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    tableClass() {
      let align = 'main';
      if (this.fixed) {
        align = this.fixed;
      }
      return [`scroll-${align}-table`];
    },
    parent() {
      const table = findComponentUpward(this, 'Table');
      return table;
    },
    visibleData() {
      const dt = this.data.filter((v) => {
        return v.actionStatus !== 'D';
      });
      return dt;
    },
    cloneColumns() {
      return deepCopy(this.columns);
    },
    expandRender() {
      let render = function() {
        return '';
      };
      for (let i = 0; i < this.columns.length; i++) {
        const column = this.columns[i];
        if (column.type && column.type === 'expand') {
          if (column.render) render = column.render;
        }
      }
      return render;
    },
    // 获取非固定列的字段集合
    getNoFrozenColumnsFields() {
      return this.columns
        .filter((x) => !x.fixed)
        .map((item) => {
          return item.key;
        });
    },
    // 获取固定列的字段集合
    getFrozenColumnsFields() {
      return this.columns
        .filter((x) => x.fixed)
        .map((item) => {
          return item.key;
        });
    }
  },
  watch: {
    // 行变化初始化隐藏列
    visibleData: {
      handler(val, oldVal) {
        if (val.length !== oldVal.length) {
          this.clearSkipRenderCells();
          // 用于解决，删除子表中的行，但子表上没有悬浮按钮的问题
          let suspendTable = this.$parent.$refs.fixedBodySuspend;
          if (suspendTable) {
            suspendTable.setAttribute('style', 'width:0;z-index:4;');
          }

          // if(suspendTable && suspendTable.length){
          //   for(let i = 0,len =suspendTable.length; i<len ; i++ ) {
          //      suspendTable[i].setAttribute('style',  'width:0;z-index:4;');
          //   }
          // }
        }
      },
      deep: true
    }
  },
  methods: {
    colWidth(column, index, boll) {
      const sysWidht = this.setCellWidth(column, index, boll);
      if (this.fixed) {
        return sysWidht;
      } else {
        const leftColums = this.columns.filter((v) => {
          return v.fixed === 'left';
        });
        const firstRightColumn = this.columns.find((v) => {
          return v.fixed === 'right';
        });
        const firstSuspendColumn = this.columns.find((v) => {
          return v.fixed === 'suspend';
        });
        if (leftColums && leftColums.length) {
          const lastLeftColumn = leftColums[leftColums.length - 1];
          if (column.__id === lastLeftColumn.__id) {
            const fixWidth = sysWidht - 2;
            return fixWidth;
          }
        }
        if (firstRightColumn) {
          if (column.__id === firstRightColumn.__id) {
            const fixWidth = sysWidht - 2;
            return fixWidth;
          }
        }
        if (firstSuspendColumn) {
          if (column.__id === firstSuspendColumn.__id) {
            const fixWidth = sysWidht - 2;
            return fixWidth;
          }
        }
      }
      return sysWidht;
    },
    rowHighlighted(_index) {
      return this.objData[_index] && this.objData[_index]._isHighlight;
    },
    rowChecked(_index) {
      return this.objData[_index] && this.objData[_index]._isChecked;
    },
    rowDisabled(_index) {
      return this.objData[_index] && this.objData[_index]._isDisabled;
    },
    rowExpanded(_index) {
      return this.objData[_index] && this.objData[_index]._isExpanded;
    },
    rowExpandedTree(_index) {
      return this.objData[_index] && this.objData[_index]._isExpandedTree;
    },
    hasRenderTr(_index) {
      return this.objData[_index] && this.objData[_index].hasRenderTr;
    },
    handleMouseIn(_index, suspendkey, index) {
      this.parent.handleMouseIn(_index, suspendkey, index);
    },
    handleMouseOut(_index, suspendkey, index) {
      this.parent.handleMouseOut(_index, suspendkey, index);
    },
    clickCurrentRow(_index) {
      this.parent.clickCurrentRow(_index);
    },
    dblclickCurrentRow(_index) {
      this.parent.dblclickCurrentRow(_index);
    },
    customCompFunc(params) {
      this.$emit('on-custom-comp', params);
    },
    showTd(column) {
      let show = true;
      let fixBody = !!this.fixed;
      let fixColumn = !!column.isFixed;
      if (fixBody) {
        if (fixColumn) {
          show = true;
        } else {
          show = false;
        }
      } else {
        if (fixColumn) {
          show = false;
        } else {
          show = true;
        }
      }

      return show;
    }
  }
};
</script>
