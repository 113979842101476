<template>
  <transition name="fade">
    <div
      :class="classes"
      :style="wraperStyles"
      @click.stop="check">
      <span
        v-if="showDot"
        :class="dotClasses"
        :style="bgColorStyle"></span>
      <Poptip v-if="showPopTip" placement="bottom" trigger="hover">
        <span
          :class="textClasses"
          :style="textColorStyle"><slot></slot></span>
        <div slot="content">
          <slot name="childrenContent"></slot>
        </div>
      </Poptip>
      <span
        v-else
        :class="textClasses"
        :style="textColorStyle"><slot></slot></span>
      <Icon
        v-if="closable"
        :class="iconClass"
        :color="lineColor"
        type="ios-close-empty"
        @click.native.stop="close"></Icon>
    </div>
  </transition>
</template>
<script>
// import Icon from '../icon';
import { oneOf } from '../../utils/assist';
import PopTipMixin from '@/mixin/poptip.js';
const prefixCls = 'ivu-tag';
const initColorList = ['blue', 'green', 'red', 'yellow', 'default'];
export default {
  name: 'TagButton',
  mixins: [PopTipMixin],
  // components: { Icon },
  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: false
    },
    closable: {
      type: Boolean,
      default: false
    },
    checkable: {
      type: Boolean,
      default: true
    },
    checked: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      default: 'border',
      validator(value) {
        return oneOf(value, ['border', 'dot']);
      }
    },
    name: {
      required: true,
      type: [String, Number, Boolean]
    },
    showPopTip: {
      type: Boolean,
      default: false
    },
    isChildrenChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: false
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-${this.color}`]: !!this.color && oneOf(this.color, initColorList),
          [`${prefixCls}-${this.type}`]: !!this.type,
          [`${prefixCls}-closable`]: this.closable,
          [`${prefixCls}-checked`]: this.isChecked || this.isChildrenChecked
        }
      ];
    },
    wraperStyles() {
      return oneOf(this.color, initColorList)
        ? {}
        : { background: this.isChecked ? this.defaultTypeColor : 'transparent', borderWidth: '1px', borderStyle: 'solid', borderColor: this.type !== 'dot' && this.type !== 'border' && this.isChecked ? this.borderColor : this.lineColor, color: this.lineColor };
    },
    textClasses() {
      return [`${prefixCls}-text`, this.type === 'border' ? (oneOf(this.color, initColorList) ? `${prefixCls}-color-${this.color}` : '') : '', this.type !== 'dot' && this.type !== 'border' && this.color !== 'default' ? (this.isChecked ? `${prefixCls}-color-white` : '') : ''];
    },
    dotClasses() {
      return `${prefixCls}-dot-inner`;
    },
    iconClass() {
      if (this.type === 'dot') {
        return '';
      } else if (this.type === 'border') {
        return oneOf(this.color, initColorList) ? `${prefixCls}-color-${this.color}` : '';
      } else {
        return this.color !== undefined ? (this.color === 'default' ? '' : 'rgb(255, 255, 255)') : '';
      }
    },
    showDot() {
      return !!this.type && this.type === 'dot';
    },
    lineColor() {
      if (this.type === 'dot') {
        return '';
      } else if (this.type === 'border') {
        return this.color !== undefined ? (oneOf(this.color, initColorList) ? '' : this.color) : '';
      } else {
        return this.color !== undefined ? (this.color === 'default' ? '' : 'rgb(255, 255, 255)') : '';
      }
    },
    borderColor() {
      return this.color !== undefined ? (this.color === 'default' ? '' : this.color) : '';
    },
    dotColor() {
      return this.color !== undefined ? (oneOf(this.color, initColorList) ? '' : this.color) : '';
    },
    textColorStyle() {
      return oneOf(this.color, initColorList) ? {} : this.type !== 'dot' && this.type !== 'border' ? (this.isChecked ? { color: this.lineColor } : {}) : { color: this.lineColor };
    },
    bgColorStyle() {
      return oneOf(this.color, initColorList) ? {} : { background: this.dotColor };
    },
    defaultTypeColor() {
      return this.type !== 'dot' && this.type !== 'border' ? (this.color !== undefined ? (oneOf(this.color, initColorList) ? '' : this.color) : '') : '';
    }
  },
  watch: {
    value(val) {
      this.updateModel();
    }
  },
  mounted() {
    this.updateModel();
  },
  methods: {
    updateModel() {
      if (Array.isArray(this.value) && this.value.includes(this.name)) {
        this.isChecked = true;
      } else {
        this.isChecked = this.value === this.name;
      }
    },
    close(event) {
      if (this.name === undefined) {
        this.$emit('on-close', event);
      } else {
        this.$emit('on-close', event, this.name);
      }
    },
    check() {
      if (!this.checkable || this.disabled) return;
      if (!Array.isArray(this.value)) {
        if (this.isChecked) return;
        const checked = !this.isChecked;
        this.isChecked = checked;
      }
      this.$emit('input', this.name);
      this.$emit('on-change', this.isChecked, this.name);
    }
  }
};
</script>
