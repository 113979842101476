<template>
  <div
    v-if="tagNavList && tagNavList.length"
    class="tags-nav">
    <div class="close-con">
      <Poptip
        :transfer="false"
        content="content"
        trigger="hover"
        placement="bottom">
        <span class="span-cursor">
          <Icon
            class="icon-reload"
            type="refresh"
            size="18" />
        </span>
        <div slot="content">
          <p
            name="refresh"
            class="tags-nav-refresh"
            @click="handleTagsOption('refresh')">
            {{ mlang('refreshCurrentPage') }}
          </p>
          <p
            name="close-others"
            class="tags-nav-refresh"
            @click="handleTagsOption('close-others')">
            {{ mlang('closeOtherPage') }}
          </p>
          <p
            name="close-all"
            class="tags-nav-refresh"
            @click="handleTagsOption('close-all')">
            {{ mlang('closeAllPage') }}
          </p>
        </div>
      </poptip>
    </div>
    <Tabs
      ref="tabs"
      key="menu-tabs"
      v-model="currentValue"
      class="header-tabs"
      type="card"
      @on-tab-remove="handleClose"
      @on-click="handleClick">
      <TabPane
        v-for="(item,index) in tagNavList"
        v-if="item.meta.isHidden !== true"
        ref="tagsPageOpened"
        :key="item.meta.uuid"
        :index="index"
        :data-route-item="item"
        :closable="item.name !== $config.homeName"
        :name="tagName(item)"
        :label="showTitleInside(item) ? showTitleInside(item) : mlang('noTitle')"></TabPane>
    </Tabs>
  </div>
</template>

<script>
import util from '@/libs/util.js';
import beforeClose from '@/router/before-close';
import { mapGetters } from 'vuex';
export default {
  name: 'TagsNav',
  props: {
    value: Object
  },
  data() {
    return {
      currentValue: this.getCurrentValue(this.$route),
      tagBodyLeft: 0,
      rightOffset: 40,
      outerPadding: 4,
      contextMenuLeft: 0,
      contextMenuTop: 0,
      visible: false
    };
  },
  computed: {
    currentRouteObj() {
      const route = this.tagNavList.find(v => {
        const key = this.$getBO('MANYTAB') ? v.meta.hash : v.name;
        return key === this.currentValue;
      });
      return route;
    },
    // list() {
    //   return this.$store.state.app.tagNavList;
    // },
    ...mapGetters(['menuList', 'tagNavList', 'flattenMenuList'])
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.setCurrentValue(newVal);
        // this.currentValue = location.hash;
        // console.log(this.currentValue);
        this.getTagElementByName(newVal);
      },
      deep: true
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.getTagElementByName(this.$route);
    // }, 200);
  },
  methods: {
    tagName(item) {
      let name = this.$getBO('MANYTAB') ? item.meta && item.meta.hash : item.name;
      return name;
    },
    tagKey(item) {
      return item.meta && item.meta.hash;
    },
    getCurrentValue(route) {
      const value = this.$getBO('MANYTAB') ? this.$util.getCurrentRouteHash(route) : route.name;
      return value;
    },
    setCurrentValue(route) {
      const value = this.getCurrentValue(route);
      this.currentValue = value;
    },
    getKey(item) {
      // let key = '';
      // const module = item.meta && item.meta.module;
      // if (module) {
      //   key = module;
      // } else {
      //   key = name;
      // }
      return item.name;
    },
    // handlescroll(e) {
    //   var type = e.type;
    //   let delta = 0;
    //   if (type === 'DOMMouseScroll' || type === 'mousewheel') {
    //     delta = e.wheelDelta ? e.wheelDelta : -(e.detail || 0) * 40;
    //   }
    //   this.handleScroll(delta);
    // },
    // handleScroll(offset) {
    //   const outerWidth = this.$refs.scrollOuter.offsetWidth;
    //   const bodyWidth = this.$refs.scrollBody.offsetWidth;
    //   if (offset > 0) {
    //     this.tagBodyLeft = Math.min(0, this.tagBodyLeft + offset);
    //   } else {
    //     if (outerWidth < bodyWidth) {
    //       if (this.tagBodyLeft < -(bodyWidth - outerWidth)) {
    //         this.tagBodyLeft = this.tagBodyLeft;
    //       } else {
    //         this.tagBodyLeft = Math.max(this.tagBodyLeft + offset, outerWidth - bodyWidth);
    //       }
    //     } else {
    //       this.tagBodyLeft = 0;
    //     }
    //   }
    // },
    handleTagsOption(type) {
      if (type.includes('all')) {
        // 关闭所有，除了home
        let res = this.tagNavList.filter((item) => item.name === this.$config.homeName);
        this.$emit('on-close', res, 'all');
      } else if (type.includes('others')) {
        // 关闭除当前页和home页的其他页
        let res = this.tagNavList.filter((item) => util.routeEqual(this.currentRouteObj, item) || item.name === this.$config.homeName);
        this.$emit('on-close', res, 'others', this.currentRouteObj);
      } else if (type.includes('refresh')) {
        let res = this.tagNavList;
        const key = this.$getBO('MANYTAB') ? this.currentRouteObj.meta.hash : this.currentRouteObj.name;
        this.$emit('refresh', res, this.currentRouteObj, this.getItem(key));
      }
    },
    getItem(value) {
      return this.tagNavList.find((v) => {
        if (this.$getBO('MANYTAB')) {
          return v.meta.hash === value;
        } else {
          return v.name === value;
        }
      });
    },
    handleClose(name) {
      const current = this.getItem(name);
      if (current.meta && current.meta.beforeCloseName && current.meta.beforeCloseName in beforeClose) {
        new Promise(beforeClose[current.meta.beforeCloseName]).then((close) => {
          if (close) {
            this.close(current);
          }
        });
      } else {
        this.close(current);
      }
    },
    close(route) {
      let eqMethod = util.routeEqualNew;
      if (this.$getBO('MANYTAB')) {
        eqMethod = util.routeEqual;
      }
      let res = this.tagNavList.filter((item) => !eqMethod(route, item));
      this.$emit('on-close', res, undefined, route);
    },
    handleClick(key) {
      this.$emit('input', this.getItem(key));
    },
    showTitleInside(item) {
      item.meta = item.meta || {};
      let menu;
      if (this.flattenMenuList && this.flattenMenuList.length) {
        menu = this.flattenMenuList.find(v => v.node && v.node.sysCode === item.name);
      };
      if (menu) {
        item.meta.title = menu.node.caption;
      }
      return util.showTitle(item, this);
    },
    isCurrentTag(item) {
      return util.routeEqual(this.currentRouteObj, item);
    },
    moveToView(tag) {
      this.$refs.tabs && this.$refs.tabs.scrollToActiveTab();
      // const outerWidth = this.$refs.scrollOuter.offsetWidth;
      // const bodyWidth = this.$refs.scrollBody.offsetWidth;
      // 引入jquery
      // const outerWidth = document.getElementsByClassName('ivu-tabs-nav-scroll')[0].offsetWidth;
      // const bodyWidth = document.getElementsByClassName('ivu-tabs-nav')[0].offsetWidth;
      // if (bodyWidth <= outerWidth) {
      //   return false;
      // } else {
      //   if (tag.offsetLeft < 0) {
      //     document.getElementsByClassName('ivu-tabs-nav')[0].style['transform:translateX'] = 0;
      //   } else if (tag.offsetLeft > 0 && tag.offsetLeft + tag.offsetWidth < outerWidth) {
      //     return false;
      //   } else {
      //     // right = 0;
      //   }
      // }
      // if (bodyWidth < outerWidth) {
      //   this.tagBodyLeft = 0;
      // } else if (tag.offsetLeft < -this.tagBodyLeft) {
      //   // 标签在可视区域左侧
      //   this.tagBodyLeft = -tag.offsetLeft + this.outerPadding;
      // } else if (tag.offsetLeft > -this.tagBodyLeft && tag.offsetLeft + tag.offsetWidth < -this.tagBodyLeft + outerWidth) {
      //   // 标签在可视区域
      //   this.tagBodyLeft = Math.min(0, outerWidth - tag.offsetWidth - tag.offsetLeft - this.outerPadding);
      // } else {
      //   // 标签在可视区域右侧
      //   this.tagBodyLeft = -(tag.offsetLeft - (outerWidth - this.outerPadding - tag.offsetWidth));
      // }
    },
    getTagElementByName(route) {
      this.$nextTick(() => {
        this.moveToView();
        this.refsTag = this.$refs.tagsPageOpened;
        // this.refsTag.forEach((item, index) => {
        //   if (util.routeEqual(route, item.$attrs['data-route-item'])) {
        //     let tag = this.refsTag[index].$el;
        //     this.moveToView(tag);
        //   }
        // });
      });
    },
    contextMenu(item, e) {
      if (item.name === this.$config.homeName) {
        return;
      }
      this.visible = true;
      const offsetLeft = this.$el.getBoundingClientRect().left;
      this.contextMenuLeft = e.clientX - offsetLeft + 10;
      this.contextMenuTop = e.clientY - 64;
    },
    closeMenu() {
      this.visible = false;
    }
  }
};
</script>

<style lang="less">
@import './tags-nav.less';
</style>
