<template>
  <a
    v-if="params.row[column.fieldName+'To']"
    :class="classes"
    :title="content"
    @click.prevent="linkClick">{{ content }}</a>
  <a
    v-else-if="params.row[column.fieldName.split('.')[0]] && params.row[column.fieldName.split('.')[0]]['nameTo']"
    :class="classes"
    :title="content"
    @click.prevent="linkClick">{{ content }}</a>
  <a
    v-else-if="params.row[column.fieldName+'Href']"
    :class="classes"
    :title="content"
    @click.prevent="linkHref">{{ content }}</a>
  <a
    v-else-if="params.row[column.fieldName+'CorpTo']"
    :class="classes"
    :title="content"
    @click.prevent="corplinkClick">{{ content }}</a>
  <span v-else>{{ content }}</span>
</template>

<script>
import CellMixin from '@/mixin/cellControl.js';
import util from '@/libs/util.js';
export default {
  name: 'Link',
  mixins: [CellMixin],
  props: {
    main: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {
    content() {
      let val;
      let _fieldName = this.column.fieldName;
      if (_fieldName.indexOf('.') > -1) {
        let a = _fieldName.split('.')[0];
        let b = _fieldName.split('.')[1];
        val = this.params.row[a] && this.params.row[a][b];
      } else {
        val = this.params.row[_fieldName];
      }
      if (this.column.isPrice) {
        const params = {
          column: this.column,
          data: this.params.row,
          value: val
        };
        let v = this.$util.getNumberFormatDataValue(params);
        return v;
      } else {
        return val;
      }
    },
    classes() {
      return [
        {
          'a-normal': !this.main,
          'two-line-overflow-hide': true
        }
      ];
    }
  },
  methods: {
    linkHref() {
      const href = this.params.row[this.column.fieldName + 'Href'];
      const title = this.params.row[this.column.fieldName + 'Title'];
      if (this.params.row.isIframe) {
        this.$open(href);
      } else {
        util.menuClickOldPage(href, this, { title });
      }
    },
    linkClick(e) {
      let to;
      let _fieldName = this.column.fieldName;
      if (_fieldName.indexOf('.') > -1) {
        let a = _fieldName.split('.')[0];
        let b = _fieldName.split('.')[1];
        to = this.params.row[a][b + 'To'];
      } else {
        to = this.params.row[_fieldName + 'To'];
      }
      // const to = this.params.row[this.column.fieldName + 'To'];
      const agentLink = this.params.row[this.column.fieldName + 'AgentLink'];
      if (this.params.row.isIframe) {
        if (window !== top) {
          top.location.href = to;
        } else {
          window.open(to);
        }
      } else if (agentLink) {
        if (agentLink.query) {
          agentLink.query.titleType = 'detail';
        } else {
          agentLink.query = {
            titleType: 'detail'
          };
        }
        this.agentLink(agentLink);
      } else {
        // 添加链接标志titleType,add:新增 detail:详情 editor编辑
        if (to) {
          if (to.query) {
            to.query.titleType = 'detail';
            if (to.query.receiveNoteNo) { // 临时兼容签收单传id
              to.query.id = this.params.row.id;
            }
          } else {
            to.query = {
              titleType: 'detail'
            };
          }
        }
        if (this.params.row.openNewBlank) {
          let routeUrl = this.$router.resolve(to);
          window.open(routeUrl.href, '_blank');
        } else {
          this.$router.push(to);
        }
      }
    },
    corplinkClick() {
      if (this.params.row[this.column.fieldName + 'CorpTo']) {
        this.params.row[this.column.fieldName + 'CorpTo']();
      }
    }
  }
};
</script>
