export default {
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D5","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D6","停用") /* "停用" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D7","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D8","删除") /* "删除" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D9","条") /* "条" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002DA","操作成功") /* "操作成功" */,
  setDefault: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800040","设为默认") /* "设为默认" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002DB","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  deleteOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002DC","请选择至少一条状态为停用的信息") /* "请选择至少一条状态为停用的信息" */
};
