<template>
  <Poptip
    trigger="hover"
    content="content"
    popper-class="customer-service-wrapper"
    :placement="placement">
    <p class="service-title">
      <span>{{ mlang('serviceCenter') }}</span>
      <Icon class="iconfont icon-xiangxia-copy" />
    </p>
    <div
      v-if="noData"
      slot="content"
      class="content-nodata">
      <div>
        <SvgIcon
          icon-class="nodata"
          class-name="no-data-icon" />
        <p>{{ mlang('noResults') }}</p>
      </div>
    </div>
    <div
      v-else
      slot="content"
      class="content-wrapper">
      <div
        v-if="onlineService.length"
        class="content-top"
        style="margin:0 12px">
        <ul
          :class="['service-name', serviceColumns]">
          <li
            v-for="item in onlineService"
            :key="item.id">
            <span class="service-name-icon">
              <SvgIcon :class="item.type" :icon-class="item.type" />
            </span>
            <div class="service-name-info">
              <p>{{ item.nickname }}</p>
              <p class="service-info-contact">
                {{ item.contactNum }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div
        v-if="phoneService.length"
        class="content-bottom">
        <ul
          :class="['service-info', phoneColumns]">
          <li
            v-for="item in phoneService"
            :key="item.id"
            class="dianhuahaoma">
            <span class="service-phone">
              <i class="iconfont rexiandianhua"></i>
            </span>
            <div>
              <p class="telephoneconsultation">
                {{ item.nickname }}
              </p>
              <p class="phonenumber">
                {{ item.contactNum }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Poptip>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCustomerService } from '@/api/user.js';
export default {
  name: 'CustomerService',
  props: {
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      phoneService: [],
      onlineService: [],
      serviceColumns: 'serviceOneColumns', // 客服显示列数及样式
      phoneColumns: 'phoneOneColumns' // 热线显示列数及样式
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    noData() {
      if (!this.phoneService.length && !this.onlineService.length) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 获取客服信息
      getCustomerService().then((res = []) => {
        if (res.length) {
          for (let key in res) {
            // if (res[key].isPlatform) {
              if (res[key].type === 'phone') {
                this.phoneService.push(res[key]);
              } else {
                this.onlineService.push(res[key]);
              }
            // }
          }
        }
        // 客服中心数据排列方式
        const onlineLen = this.onlineService.length;
        if (onlineLen) {
          if (onlineLen <= 3) {
            this.serviceColumns = 'serviceOneStyle';
          } else if (onlineLen >= 4 && onlineLen <= 6) {
            this.serviceColumns = 'serviceTwoStyle';
          } else {
            this.serviceColumns = 'serviceThreeStyle';
          }
        }
        // 热线
        const phoneLen = this.phoneService.length;
        if (phoneLen) {
          if (phoneLen <= 3) {
            this.phoneColumns = 'phoneOneStyle';
          } else if (phoneLen >= 4 && phoneLen <= 6) {
            this.phoneColumns = 'phoneTwoStyle';
          } else {
            this.phoneColumns = 'phoneThreeStyle';
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
  .ivu-poptip-body .ivu-poptip-body-content p{
    &:hover{
      background:none;
    }
    padding: 2px 0;
  }
  .content-nodata{
      width: 220px;
      div{
        margin: 0 auto;
        text-align: center;
      }
    }
  .content-wrapper{
    overflow-y: hidden;
    background:rgba(255,255,255,1);
    box-shadow:0px -2px 10px 0px rgba(0,0,0,0.1);
    .content-bottom{
      margin:0 12px;
      display: flex;
      align-items: flex-start;
    }
    .service-name{
      display: flex;
      flex-wrap: wrap;
      li{
        display: flex;
        align-items: flex-start;
        width: auto;
        padding:0 12px 6px 0;
        cursor: pointer;
        &:hover .service-info-contact{
          color: #588CE9;
        }
        .service-name-info {
          width: auto;
          p{
            font-size:12px;
            font-weight:400;
            color: #999999;
            line-height:17px;
            text-align: left;
            padding:0;
            width:110px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .service-info-contact{
            color: #333333;
            height:20px;
            font-size:14px;
            line-height:20px;
          }
          .service-info-contact:hover{
            color: #588CE9;
          }
        }
      }
    }
    .service-name-icon,.service-phone{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      color: #ff7937;
      i{
        font-size: 18px;
      }
      svg.svg-icon{
        width: 18px;
        height: 18px;
      }
    }
    .service-phone{
      margin-top:8px;
    }
    .serviceThreeStyle{
      flex-direction: row;
      width: 300px;
      li{
        width:50%;
      }
    }
    .serviceTwoStyle{
      flex-direction: row;
      /*height: 188px;*/
      width: 300px;
      border-bottom: 1px solid #eee;
      margin: 0 auto;
      li{
        width:50%;
      }
    }
    .serviceOneStyle{
      flex-direction: column;
      max-height: 188px;
    }
    .phoneOneStyle{
      flex-direction: column;
      max-height: 130px;
    }
    .phoneTwoStyle{
      flex-direction: column;
      height: 130px;
      width: 300px;
    }
    .phoneThreeStyle{
      flex-direction: row;
      width: 300px;
    }
    .service-info{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding-top:8px;
      justify-content: space-between;
      li{
        display: flex;
        align-items: flex-start;
        width: auto;
        padding:0 12px 6px 0;
        cursor: pointer;
        &:hover .service-info-contact{
          color: #588CE9;
        }
        .service-name-info {
          width: auto;
          p{
            font-size:12px;
            font-weight:400;
            color: #999999;
            line-height:17px;
            text-align: left;
            padding:0;
            width:110px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .service-info-contact{
            color: #333333;
            height:20px;
            font-size:14px;
            line-height:20px;
          }
          .service-info-contact:hover{
            color: #588CE9;
          }
        }
      }
      .dianhuahaoma{
        width: 50%;
      }
      .telephoneconsultation{
        color: #999999;
        width: 110px;
        height:17px;
        font-size:12px;
        line-height:17px;
        cursor: pointer;
        padding:0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .phonenumber{
        width:auto;
        color: #333333;
        height:20px;
        font-size:14px;
        line-height:20px;
        cursor: pointer;
        &:hover{
          color: #588CE9;
        }
      }
    }
  }
</style>
