<template>
  <Poptip
    ref="meta-setting-poptip"
    :offset="offset"
    :options="popTipProps"
    trigger="click"
    placement="bottom-end"
    popper-class="poptip poptip-setting"
    @on-popper-show="onpoperShow">
    <Button
      v-if="!icon"
      class="btn-setting">
      {{ mlang('setting') }}
    </Button>
    <Icon
      v-if="icon"
      custom="iconfont icon-gengduo2"></Icon>
    <div
      slot="content"
      class="meta-setting-poptip-content">
      <div class="filter-btn-fixed">
        <div class="filter-txt">
          <div class="meta-setting-checkboxgruop">
            <template>
              <Row
                v-for="(column, index) in columnsList"
                :key="index"
                class-name="meta-setting-row">
                <Col
                  v-if="!column.isHidden"
                  span="18"
                  class-name="meta-setting-left-col">
                <Checkbox
                  v-model="column.showIt"
                  :disabled="column.isMustSelect"
                  @on-change="checkChange(column,...arguments)">
                  <span>{{ column.showCaption || column.caption }}</span>
                </Checkbox>
                </Col>
                <!-- 排序暂时隐藏 -->
                <!-- <Col
                  span="6"
                  class-name="meta-setting-right-col">
                <Row
                  type="flex"
                  justify="end"
                  align="middle"
                  class-name="meta-setting-icons">
                  <Icon
                    v-if="showUp(index,column)"
                    class-name="meta-setting-down"
                    custom="iconfont icon-shangyi"
                    @click="up(index,column)"></Icon>
                  <Icon
                    v-if="showDown(index,column)"
                    class-name="meta-setting-up"
                    custom="iconfont icon-xiayi"
                    @click="down(index,column)"></Icon>
                </Row>
                </Col> -->
              </Row>
            </template>
          </div>
        </div>
        <div class="filter-btn-1">
          <Button
            class="cancel-btn"
            @click="cancel">
            {{ mlang('cancel') }}
          </Button>
          <Button
            type="text"
            class="queryschema-setting-ok btn-black"
            @click="save">
            {{ mlang('save') }}
          </Button>
        </div>
      </div>
    </div>
  </Poptip>
</template>

<script>
import { deepCopy, oneOf } from '@/u-components/utils/assist';
import Emitter from '@/u-components/mixins/emitter';
import { saveFiltersItems, saveUserBillMetaSave, userBillMetaGet } from '@/api/meta';
import PoptipMixin from '@/mixin/poptip.js';
export default {
  name: 'MetaSetting',
  mixins: [Emitter, PoptipMixin],
  props: {
    billId: {
      type: [Number],
      required: true
    },
    type: {
      type: String,
      default: 'column',
      validator(value) {
        return oneOf(value, ['queryschema', 'column']);
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    allMetaColumns: {
      type: Array,
      default() {
        return [];
      }
    },
    hiddenFields: {
      type: Array,
      default() {
        return [];
      }
    },
    beforeSave: {
      type: Function
    },
    icon: {
      default: false,
      type: Boolean
    },
    className: {
      default: '',
      type: String
    },
    source: {
      type: String,
      default: ''
    }

  },
  data: function() {
    return {
      cloneColumns: [],
      social: [],
      idxMap: new Map(),
      columnsList: []
    };
  },
  computed: {
    offset() {
      return this.type === 'queryschema' ? 0 : 14;
    },
    // actionIdx() {
    //   return this.cloneColumns.findIndex((v) => v.fieldName === 'action');
    // },
    visibleColumns() {
      let visibleIdx = 0;
      let items = [];
      this.cloneColumns.forEach((v, idx) => {
        if (v.fieldName !== 'action' && !(v.isShowIt === false && v.isMustSelect === true) && !this.hiddenFields.includes(v.fieldName)) {
          this.idxMap.set(visibleIdx++, idx);
          items.push(v);
        }
      });
      return items;
    }

  },
  created() {},
  methods: {
    showUp(index, current) {
      // 当action位于第一行, 下一个属性不显示
      // if (this.actionIdx !== -1 && this.actionIdx === 0 && index === this.actionIdx + 1) return false;
      const currentGruopType = current.groupType;
      const prev = this.cloneColumns[this.idxMap.get(index) - 1];
      if (prev && prev.groupType !== currentGruopType) {
        return false;
      }
      return (index > 0);
      // if (index > 0) {
      //   if (this.cloneColumns[index].isMustSelect) {
      //     // this.cloneColumns[index].isCanMove = false;
      //   }
      //   if (this.cloneColumns[index - 1] && this.cloneColumns[index - 1].isCanMove) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
    },
    showDown(index, current) {
      // 当action位于第末行, 上一个属性不显示
      //  if (this.actionIdx !== -1 && this.actionIdx === this.cloneColumns.length - 1 && index + 1 === this.actionIdx) return false;
      const currentGruopType = current.groupType;
      const next = this.cloneColumns[this.idxMap.get(index) + 1];
      if (next && next.groupType !== currentGruopType) {
        return false;
      }
      return (index < this.visibleColumns.length - 1);
      // if (index <= this.cloneColumns.length - 1) {
      //   if (this.cloneColumns[index].isMustSelect) {
      //     // this.cloneColumns[index].isCanMove = false;
      //   }
      //   if (index + 1 !== this.cloneColumns.length && this.cloneColumns[index + 1].isCanMove) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
    },
    checkCanModify(column) {
      if (column.isCanMove !== true) {
        this.$Message.warning('该字段不允许修改');
        return false;
      }
      return true;
    },
    checkChange(column, value) {
      if (!value) {
        this.checkLastOne(column, value);
      }
    },
    checkLastOne(column, value) {
      const checked = this.columnsList.filter((v) => v.showIt);
      if (checked.length === 0) {
        this.$nextTick(() => {
          column.showIt = !column.showIt;
        });
      }
    },
    onpoperShow() {
      // this.cloneColumns = deepCopy(this.columns);
      // this.dealRepeat();
      userBillMetaGet(this.billId, localStorage.getItem('upOrgId')).then(res => {
        // 此处原来并没有isHidden控制显示隐藏，因此接口返回并无isHidden，想要通过该字段控制显示隐藏，手动赋值isHidden属性
        if (Array.isArray(res) && res.length > 0) {
          res.forEach(item => {
            item.isHidden = false;
          })
        }
        if (Array.isArray(res) && res.length > 0 && this.source === 'collectibleGoods') {
          res.forEach(item => {
            switch(item.fieldName) {
              case 'productLineName':
                item.isHidden = true;
                break;
              case 'productBrandName':
                item.isHidden = true;
                break;
            }
          })
        }
        res.forEach(v => {
          this.allMetaColumns.map(item => {
            if (item.sourceTplGroupId === v.groupId && v.fieldName === item.fieldName) {
              v.showCaption = item.showCaption;
            }
          });
        });
        this.columnsList = res;
      });
    },
    dealRepeat() {
      let hash = {};
      // 去除name相同的元素
      this.cloneColumns = this.cloneColumns.reduce(function(item, next) {
        if (!hash[next.name]) {
          hash[next.name] = true;
          item.push(next);
        }
        return item;
      }, []);
    },
    cancel() {
      this.$refs['meta-setting-poptip'].handleClose();
    },
    save() {
      if (typeof this.beforeSave === 'function') {
        this.beforeSave(this.cloneColumns);
      }
      const fn = this.type === 'queryschema' ? saveFiltersItems : saveUserBillMetaSave;
      fn(this.billId, this.columnsList).then((res) => {
        this.$Message.success(this.mlang('operateSuccess'));
        this.dispatch('VoucherList', this.type === 'queryschema' ? 'reload-all' : 'reload-table');
        this.$emit('save-ok', this.cloneColumns);// 传的是this.cloneColumns ，不然旧数据中好多属性会丢
        this.cancel();
      });
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    up($index, column) {
      // if ($index === 0) {
      //   return;
      // }
      // if (!this.checkCanModify(column)) {
      //   return;
      // }
      // if (this.cloneColumns[$index - 1].isCanMove !== true) {
      //   return;
      // }
      this.cloneColumns = this.swapItems(this.cloneColumns, this.idxMap.get($index), this.idxMap.get($index - 1));
    },
    down($index, column) {
      // if ($index === this.cloneColumns.length - 1) {
      //   return;
      // }
      // if (!this.checkCanModify(column)) {
      //   return;
      // }
      // if (this.cloneColumns[$index + 1].isCanMove !== true) {
      //   return;
      // }
      this.cloneColumns = this.swapItems(this.cloneColumns, this.idxMap.get($index), this.idxMap.get($index + 1));
    }
  }
};
</script>
