import { getDefineRefs } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const agentsRefer = (ctrl, vm) => {
  let control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'value',
      refName: 'value',
      queryschema: [{
        name: 'value',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        isShowIt: true,
        placeholder: vm.mlang('name'),
        conditionType: 'like'
      },
      {
        name: 'code',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        isShowIt: true,
        placeholder: vm.mlang('code'),
        conditionType: 'like'
      }],
      passedparams: [{
        name: 'defineId',
        v1: ctrl.defineId,
        valueType: 'LONG',
        conditionType: 'eq'
      },
      {
        name: 'fieldType',
        v1: ctrl.fieldType,
        valueType: 'LONG',
        conditionType: 'eq'
      }],
      columns: [
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          fieldName: 'code'
        },
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'value'
        }
      ],
      actions: { queryList: getDefineRefs },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  if (control.fieldType === 20) {
    control.refReturnValue = 'id';
  }
  if (control.fieldType === 21) {
    control.refName = ctrl.echoName || 'name';
    control.refReturnValue = 'id';
  }
  return deepCopy(control);
};
export default agentsRefer;
