<template>
  <Row class="col-float">
    <Col style="float: left;margin-right:10px;line-height:26px" class="datapicker-tag">
    <TagButton
      v-for="tag in enumArray"
      :key="tag.value"
      v-model="p_value['tag']"
      :class="{'datepickerButtonDisabled':disabled}"
      :name="tag.value"
      :fieldid="'datapicker-tag_'+control['name']+'_'+tag.name"
      :disabled="disabled"
      @on-change="tagChange(...arguments,tag)">
      {{ tag.title }}
    </TagButton>
    </Col>
    <div style="float:left" class="predicate-datepicker">
      <!-- <DatePicker
        ref="picker"
        :value="pickerValue"
        :editable="false"
        :clearable="false"
        :options="control.options"
        :auto-time="true"
        type="datetimerange"
        style="width:308px"
        split-panels
        placement="bottom-start"
        class="u-datepicker-between22"
        @on-clear="onClear"
        @on-open-change="onOpenChange"
        @on-ok="onOk"
        @on-change="setDatePickerValue"></DatePicker> -->
      <Row type="flex">
        <Col>
        <div class="queryschema-between-left">
          <DatePicker
            :value="pickerValue[0]"
            :options="dateOption"
            type="datetime"
            clearable
            placement="bottom-start"
            class="u-datepicker-between"
            @on-change="setDatePickerValue(...arguments,0)"></DatePicker>
        </div>
        </Col>
        <Col style="width:19px">
        <div class="queryschema-between-center sp-range-txt" style="font-size:16px">
          -
        </div>
        </Col>
        <Col>
        <div class="queryschema-between-right">
          <DatePicker
            :value="pickerValue[1]"
            :options="dateOption"
            type="datetime"
            clearable
            placement="bottom-start"
            class="u-datepicker-between"
            @on-change="setDatePickerValue(...arguments,1)"></DatePicker>
        </div>
        </Col>
      </Row>
    </div>
  </Row>
</template>

<script>
import _ from 'lodash';
import PropsyncMixin from '@/mixin/propsync.js';
import dayjs from 'dayjs';
const acFormat = require('ac-format');
export default {
  name: 'RedicateDatepicker',
  components: {},
  mixins: [PropsyncMixin],
  props: {
    control: {
      required: true,
      propsync: false,
      type: Object
    },
    value: {
      required: true,
      type: Object
    },
    bizMeta: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      oldPickerValue: [],
      pickerValue: [],
      v1: '',
      v2: '',
      disabled: false,
      dateOption: {},
      enumArray: [
        { title: this.mlang('nearly1weeks'), value: '-7', name: 'day' },
        { title: this.mlang('nearly1months'), value: '-1', name: 'month' },
        // { title: this.mlang('nearly2months'), value: '-2', name: 'month' },
        { title: this.mlang('nearly3months'), value: '-3', name: 'month' },
        { title: this.mlang('nearly6months'), value: '-6', name: 'month' },
        { title: this.mlang('nearly1year'), value: '-365', name: 'day' }]
    };
  },
  computed: {
    buttonStyle() {
      return {};
    }
  },
  watch: {
    value: {
      handler(v, ov) {
        if (!_.isEqual(v, ov)) {
          this.setDate();
        }
        // this.onOk();
      }
    },
    bizMeta: {
      handler(val) {
        if (_.isObject(val.onlyOneYear)) {
          this.setOldYear(val.onlyOneYear);
        } else if (val.onlyOneYear === -1) {
          this.disabled = false;
          this.p_value['tag'] = (this.bizMeta[this.control.name] && this.bizMeta[this.control.name].defaultValue) || this.control.enumArray[0].value;
          this.setDate();
        }

        if (val.resetPredicate) {
          if (!this.pickerValue[0] && !this.pickerValue[1]) {
            this.p_value['tag'] = '-6';
            this.setDate();
          }
        }
        if (val.resetSingleDateTime) {
          if (this.pickerValue[0] && !this.pickerValue[1]) {
            let es = dayjs(this.pickerValue[0]).add(6, 'month').format('YYYY-MM-DD');
            let s = `${es} 23:59:59`;
            let diff = dayjs().diff(dayjs(s), 'month');
            if (diff < 0) {
              s = `${dayjs().format('YYYY-MM-DD')} 23:59:59`;
            }
            const s1 = acFormat.getGlobalizationDateFormatString(s, null, null, 'datetime', 'YYYY-MM-DD HH:mm:ss').value;
            const s2 = this.$util.formatToUtc8DateTime(s1);
            this.pickerValue.splice(1, 1, s2);
          } else if (!this.pickerValue[0] && this.pickerValue[1]) {
            let es = dayjs(this.pickerValue[1]).subtract(6, 'month').format('YYYY-MM-DD');
            let s = `${es} 00:00:00`;
            const s1 = acFormat.getGlobalizationDateFormatString(s, null, null, 'datetime', 'YYYY-MM-DD HH:mm:ss').value;
            const s2 = this.$util.formatToUtc8DateTime(s1);
            this.pickerValue.splice(0, 1, s2);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.setDate();
    // this.hideClearBtn();
  },
  methods: {
    setValue(tagValue, dateValue) {
      this.$emit('input', { tag: tagValue, v: dateValue });
    },
    setDate(reload) {
      const tagValue = this.value['tag'];
      const tag = this.enumArray.find((v) => {
        return v.value === tagValue;
      });
      const name = tag && tag.name; // dayjsjs
      if (name) {
        let v1 = dayjs()
          .subtract(tagValue * -1, name)
          .format('YYYY-MM-DD');
        let v2 = dayjs()
          .subtract(0, name)
          .format('YYYY-MM-DD');
        let s = `${v1} 00:00:00`;
        let e = `${v2} 23:59:59`;
        // 2020-02-07 00:00:00 已经是上下文的了只需要格式化一下
        const s1 = acFormat.getGlobalizationDateFormatString(s, null, null, 'datetime', 'YYYY-MM-DD HH:mm:ss').value;
        const e1 = acFormat.getGlobalizationDateFormatString(e, null, null, 'datetime', 'YYYY-MM-DD HH:mm:ss').value;
        // // 再格式化成 utc8 时间
        const s2 = this.$util.formatToUtc8DateTime(s1);
        const e2 = this.$util.formatToUtc8DateTime(e1);
        this.pickerValue = [s2, e2];
      }

      // if (!reload) {
      this.setValue(tagValue, this.pickerValue);
      // }
    },
    onOpenChange(visible) {
      if (!visible) {
        if (this.oldPickerValue !== this.pickerValue) {
          this.pickerValue = this.oldPickerValue;
        }
      } else {
        this.oldPickerValue = this.pickerValue;
      }
    },
    onOk() {
      this.oldPickerValue = this.pickerValue;
      this.p_value['v'] = this.pickerValue;
      this.p_value['tag'] = null;
      this.setValue(null, this.pickerValue);
      this.$emit('on-tag-click');
    },
    onClear() {
      this.pickerValue = ['', ''];
      this.oldPickerValue = this.pickerValue;
      this.p_value['v'] = this.pickerValue;
      this.p_value['tag'] = null;
      this.setValue(null, this.pickerValue);
      this.$emit('on-tag-click');
    },
    setDatePickerValue(date, selectKey, index) {
      // if (selectKey === 'date') {
      //   date[1] = dayjs(date[1])
      //     .subtract(1, 'seconds')
      //     .add(1, 'days')
      //     .format('YYYY-MM-DD HH:mm:ss');
      // }
      this.oldPickerValue = this.pickerValue;
      this.pickerValue[index] = date;
      this.pickerValue.splice(index, 1, date);
      // YS，第一个选择器改变第二个选择器清空，第二个选择器改变就直接发起搜索
      this.p_value['tag'] = null;
      if (index === 0) {
        this.pickerValue.splice(1, 1);
      }
      if (index === 1) {
        if (this.pickerValue[0] && this.pickerValue[1]) {
          this.$emit('on-tag-click');
        }
      }
      // this[selectKey] = date;
      // this.p_value[selectKey] = date;
      // this.p_value['tag'] = null;
      // this.$emit('on-tag-click');
    },
    tagChange(eee, value, tag) {
      this.setDate();
      this.$emit('on-tag-click', tag, value);
    },
    hideClearBtn() {
      this.$refs.picker.$data.prefixCls = 'prediacte-picker ivu-date-picker';
    },
    setOldYear(val) {
      this.disabled = true;
      let s = dayjs(val.maxDateTime).subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss');
      let e = val.maxDateTime;

      this.dateOption.disabledDate = (date) => {
        return date && (date.valueOf() < new Date(val.minDateTime) || date.valueOf() > new Date(e));
      };
      const s1 = acFormat.getGlobalizationDateFormatString(s, null, null, 'datetime', 'YYYY-MM-DD HH:mm:ss').value;
      const e1 = acFormat.getGlobalizationDateFormatString(e, null, null, 'datetime', 'YYYY-MM-DD HH:mm:ss').value;
      // // 再格式化成 utc8 时间
      const s2 = this.$util.formatToUtc8DateTime(s1);
      const e2 = this.$util.formatToUtc8DateTime(e1);
      this.pickerValue = [s2, e2];
      this.setValue(null, this.pickerValue);
      this.$emit('on-tag-click');
    }
  }
};
</script>
<style lang="less" scoped>
.datapicker-tag{
  .datepickerButtonDisabled{
    background:#f7f7f7 !important;
  }
}

</style>
