<template>
  <Select
    ref="select"
    :label="label"
    :disabled="disabled"
    :placeholder="placeholder"
    :placement="placement"
    :transfer="true"
    class="ivu-auto-complete"
    auto-complete
    @on-change="handleChange">
    <slot name="input">
      <Input
        ref="input"
        slot="input"
        v-model="textValue"
        :element-id="elementId"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        class="select-tab-input"
        icon="ios-arrow-down"
        readonly
        @on-click="handleClear"
        @on-focus="handleFocus"></Input>
    </slot>
    <slot>
      <div class="select-tab ivu-auto-complete width100">
        <Tabs type="card">
          <TabPane
            :label="mlang('common')"
            name="default">
            <div class="normalOptions ivu-select-dropdown-list">
              <Option
                v-for="item in defaultArray"
                :key="item.id"
                :class="[item.id === currentValue? 'ivu-select-item-selected':'']"
                :value="item.id">
                {{ item[nameKey] }}
              </Option>
            </div>
          </TabPane>
          <TabPane
            :label="mlang('more')"
            name="more">
            <div class="word">
              <a
                v-for="(word,index) in wordsShow"
                :key="index"
                :class="{'active-option-group' : activeOptionGroup === word }"
                @click.stop="fastCharClick(word)">{{ word }}</a>
            </div>
            <div
              id="moreGroup"
              class="moreOptions">
              <OptionGroup
                v-for="(wordarea,index) in moreArrayShow"
                :key="index"
                :ref="'optionGruop' + wordarea.wordkey"
                :label="wordarea.wordkey">
                <Option
                  v-for="item in wordarea.items"
                  :key="item.id"
                  :class="[item.id === currentValue? 'ivu-select-item-selected':'']"
                  :value="item.id">
                  {{ item[nameKey] }}
                </Option>
              </OptionGroup>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </slot>
  </Select>
</template>

<script>
import { oneOf, deepCopy } from '../../utils/assist';
import Emitter from '../../mixins/emitter';
export default {
  name: 'SelectTab',
  mixins: [Emitter],
  props: {
    defaultArray: {
      type: Array,
      default: () => []
    },
    moreArray: {
      type: Array,
      default: () => []
    },
    nameKey: {
      type: String,
      default: 'cName'
    },
    sortKey: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: [String, Number],
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      default: '',
      type: String
    },
    filterMethod: {
      type: [Function, Boolean],
      default: false
    },
    placement: {
      type: String,
      default: 'bottom',
      validator(value) {
        return oneOf(value, ['top', 'bottom']);
      }
    },
    transfer: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    elementId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      words: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      wordsShow: [],
      moreArrayShow: [],
      textValue: '',
      currentValue: this.value,
      disableEmitChange: false, // for Form reset
      activeOptionGroup: null
    };
  },
  computed: {
    allData() {
      return deepCopy(this.defaultArray.concat(this.moreArray));
    },
    inputIcon() {
      let icon = '';
      if (this.clearable && this.currentValue) {
        icon = 'ios-close';
      } else if (this.icon) {
        icon = this.icon;
      }
      return icon;
    },
    filteredData() {
      if (this.filterMethod) {
        return this.data.filter((item) => this.filterMethod(this.currentValue, item));
      } else {
        return this.data;
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        this.disableEmitChange = true;
        this.currentValue = val;
      },
      immediate: true
    },
    currentValue: {
      handler(val) {
        // this.$refs.select.query = val;
        this.$emit('input', val);
        this.updateModel();

        let model = this.getModel(val);
        this.$emit('on-change', val, model);

        if (this.disableEmitChange) {
          this.disableEmitChange = false;
          return;
        }
        this.dispatch('FormItem', 'on-form-change', val);
      },
      immediate: true
    },
    moreArray: {
      handler(val, oldVal) {
        if (val && oldVal && val.length === oldVal.length) {
          return;
        }
        this.wordsShow = [];
        this.moreArrayShow = [];
        if (val && val.length) {
          let other = [];
          for (let i = 0; i < val.length; i++) {
            let curr = val[i];
            let isFind = false;
            for (let k = 0; k < this.words.length; k++) {
              let word = this.words[k];
              let sortVal = curr[this.sortKey];
              if (sortVal && word === sortVal.substr(0, 1).toUpperCase()) {
                isFind = true;
                if (this.wordsShow.indexOf(word) > -1) {
                  // 已存在该字母
                  this.moreArrayShow
                    .find((v) => {
                      return v.wordkey === word;
                    })
                    .items.push(curr);
                } else {
                  this.wordsShow.push(word);
                  this.moreArrayShow.push({
                    wordkey: word,
                    items: [curr]
                  });
                }
                break;
              }
            }

            if (!isFind) {
              // 在已有字母中没找到
              other.push(curr);
            }
          }
          this.wordsShow.sort();
          this.moreArrayShow.sort((a, b) => {
            return a.wordkey < b.wordkey ? -1 : 1;
          });
          if (other.length) {
            this.wordsShow.push('-');
            this.moreArrayShow.push({
              wordkey: '-',
              items: other
            });
          }
        }
      },
      immediate: true
    }

  },
  methods: {
    fastCharClick(work) {
      this.activeOptionGroup = work;
      const optionGroup = this.$refs[`optionGruop${work}`];
      optionGroup && optionGroup[0] && optionGroup[0].$children[0] && optionGroup[0].$children[0].$el.scrollIntoViewIfNeeded({ block: 'end', inline: 'nearest' });
    },
    updateModel() {
      const currentValue = this.currentValue;
      let model = { id: null };
      model = this.allData.find((v) => {
        return v.id === currentValue;
      });
      if (!model) {
        model = this.allData.find((v) => {
          return v[this.nameKey] === this.name;
        });
      }
      this.textValue = model ? model[this.nameKey] || '' : '';
    },
    getModel(val) {
      let model = {
        id: null
      };
      model = this.allData.find((v) => {
        return v.id === val;
      });
      if (model) return model;
      model = this.allData.find((v) => {
        return v[this.nameKey] === this.name;
      });
      return model;
    },
    remoteMethod(query) {
      this.$emit('on-search', query);
    },
    handleChange(val) {
      this.currentValue = val;
      this.$refs.select.model = val;
      this.$refs.input.blur();
      this.$emit('on-select', val);
    },
    handleFocus() {
      // this.$refs.select.visible = true;
    },
    handleBlur() {
      // this.$refs.select.visible = false;
    },
    handleClear() {
      if (!this.clearable) return;
      this.currentValue = '';
      this.$refs.select.model = '';
    }
  }
};
</script>
