<template>
  <Poptip
    :options="popTipProps"
    trigger="hover"
    placement="bottom"
    popper-class="poptip poptip-setting">
    <slot></slot>
    <div
      slot="content"
      class="im-or-export-result-content">
      <Tabs
        :value="tabValue"
        size="small">
        <TabPane
          :label="mlang('import')"
          name="import"
          class="import">
          <ul class="export-list">
            <li
              v-for="item in importList"
              :key="item.uid">
              <div class="title two-line-overflow-hide">
                {{ item.name }}
              </div>
              <div
                v-if="item.num"
                class="total">
                {{ mlang('beingImport') }}
                <template v-if="item.num">
                  {{ mlang('count') }}
                  <span>{{ item.num }}</span>
                  {{ mlang('bar') }}
                </template>
              </div>
              <div
                v-if="item.errorCount"
                class="total fail"
                @click="downErrorFile(item)">
                {{ mlang('importWarning') }}
              </div>
              <div
                v-if="!item.errorCount && !item.num"
                class="total success import-success">
                {{ mlang('importSuccess') }}
              </div>
            </li>
          </ul>
        </TabPane>
        <TabPane
          :label="mlang('export')"
          name="export"
          class="export">
          <ul class="export-list">
            <li
              v-for="item in exportList"
              :key="item.filePath">
              <div class="title two-line-overflow-hide">
                {{ item.fileName }}
              </div>
              <div
                v-if="item.exporting"
                class="total">
                {{ mlang('beingExport') }}
              </div>
              <div
                v-if="!item.exporting"
                class="total success"
                @click="download(item)">
                {{ mlang('exportSuccess') }}
              </div>
            </li>
          </ul>
        </TabPane>
      </Tabs>
    </div>
  </Poptip>
</template>

<script>
import PoptipMixin from '@/mixin/poptip.js';
export default {
  name: 'ExImportResult',
  mixins: [PoptipMixin],
  props: {
    bizMeta: Object,
    importList: {
      type: Array,
      required: true
    },
    exportList: {
      type: Array,
      required: true
    },
    tabValue: {
      type: String,
      default: 'import'
    }
  },
  data() {
    return {};
  },
  methods: {
    download(item) {
      if (item && item.filePath) {
        this.$open(item.filePath);
      }
    },
    downErrorFile(item) {
      if (item && item.errorFile && this.bizMeta.import.url) {
        this.$download(item.errorFile);
      } else if (item && item.errorFile && this.bizMeta.import.enumName) {
        this.$Modal.error({
          content: item.errorFile
        });
      }
    }
  }
};
</script>
