export default {
  effect: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500087B","整单生效") /* "整单生效" */,
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500087C","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500087D","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500087E","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500087F","操作成功") /* "操作成功" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000880","删除") /* "删除" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000881","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000882","编辑") /* "编辑" */,
  effectting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000883","生效中") /* "生效中" */
};
