// 消息中心相关的url
import ajax from '@/libs/ajax';
// 用于获取消息列表
export const getBulletinsForNewService = (data) => {
  data['isArchive'] = 1;
  return ajax.request({
    url: '/messagecenter/bulletin/pagelist',
    method: 'get',
    data
  });
};

// 用于删除选中的消息
export const delMessage = (bulletinids) => {
  return ajax.request({
    url: '/messagecenter/bulletin/user/delete',
    method: 'post',
    data: {
      datatype: 'json',
      data: bulletinids, // 该参数为数组类型,包含要删除的消息的id
      isArchive: 1
    }
  });
};

// 用于标记选中的消息为已读

export const markMessagesAsRead = (bulletinids) => {
  return ajax.request({
    url: '/messagecenter/bulletin/user/reade',
    method: 'post',
    data: {
      datatype: 'json',
      data: bulletinids, // 该参数为数组类型,包含bulletinuser对象id
      isArchive: 1
    }
  });
};
