<template>
  <div>
    <div
      v-if="!currentUser.__isAgentSide"
      class="action">
      <Row
        class-name="btn"
        type="flex"
        justify="center">
        <span
          v-if="params.row.isShowConfirmBtn && deliveryAuditing"
          @click="confirmDelivery(params.row.cDeliveryNo)">
          {{ mlang('toExamine') }}
        </span>
        <!-- <span
          v-if="params.row.isShowPayBtn && orderPay"
          @click="pay(params)">
          {{ mlang('receivePay') }}
        </span> -->
        <span
          v-if="params.row.isShowUnConfirmBtn && deliveryBackConfirm"
          @click="backConfirmDvery(params)">
          {{ mlang('discardedTrial') }}
        </span>
        <span
          v-if="params.row.isShowModifyBtn && deliveryOrderEdit"
          @click="deliveryEdit(params)">
          {{ mlang('modify') }}
        </span>
        <span
          v-if="params.row.isShowPayBtn && invoicePay"
          class="payBtn hollowBtn"
          @click="pay(params)">
          {{ mlang('receivePay') }}
        </span>
        <span
          v-if="$hasAuth('deliveryOpenInvoice') && params.row.isShowOpenInvoiceBtn"
          @click="invoice(params)">
          {{ mlang('invoice') }}
        </span>
        <span
          v-if="params.row.invoiceMoney && params.row.invoiceMoney > 0 && $getBO('ELEINVOICE')"
          @click="watchInvoice(params)">
          {{ mlang('watchInvoice') }}
        </span>
        <span
          v-if="params.row.isShowDeletedBtn && invoiceDel"
          class="deliveryBtn"
          @click="delDelivery(params)">
          {{ mlang('delete') }}
        </span>
      </Row>
    </div>
    <div
      v-else
      class="action">
      <!-- 付款 -->
      <span
        v-if="params.row.isShowPayBtn && agentOrderPay && !currentUser.__isRepresent"
        class="deliveryBtn"
        @click="agentPay(params)">
        {{ mlang('pay') }}
      </span>
      <!-- 查发票 -->
      <span
        v-if="params.row.invoiceMoney && params.row.invoiceMoney > 0 && $getBO('ELEINVOICE')"
        class="deliveryBtn"
        @click="watchInvoice(params)">
        {{ mlang('watchInvoice') }}
      </span>
      <!-- 收货确认 -->
      <span
        v-if="params.row.isShowTakeDeliveryBtn && agentOrderGathering && !$getBO('RECEIVINGNOTE')"
        class="deliveryBtn"
        @click="agentReceive(params)">
        {{ mlang('receiveAndConfirm') }}
      </span>
      <!-- 签收 -->
      <span
        v-if="params.row.isShowReceiveBtn && saveReceivingNote && currentUser.__isRepresent && $getBO('RECEIVINGNOTE')"
        @click="signIn(params.row)">
        {{ mlang('signIn') }}
      </span>
      <span
        v-if="params.row.isShowReceiveBtn && !currentUser.__isRepresent && $getBO('RECEIVINGNOTE')"
        @click="signIn(params.row)">
        {{ mlang('signIn') }}
      </span>
    </div>
  </div>
</template>

<script>
import Emitter from '@/u-components/mixins/emitter';
import PopTipMixin from '@/mixin/poptip.js';
import agentDelivery from '@/mixin/agentDelivery.js';
import CellMixin from '@/mixin/cellControl.js';
import DeliveryMixin from '@/mixin/delivery.js';
import ArchiveListMixin from '@/mixin/archive-list.js';
import { getInvoice, watchInvoiceApi } from '@/api/delivery';
import util from '@/libs/util.js';
export default {
  name: 'OrderAction',
  components: {},
  mixins: [Emitter, PopTipMixin, CellMixin, DeliveryMixin, ArchiveListMixin,agentDelivery],
  data: function() {
    return {
      currentUser: this.$store.getters.currentUser,
      isOpenInvoiceBtn: false,
      deliveryAuditing: this.$hasAuth('deliveryAuditing'),
      orderPay: this.$hasAuth('orderPay'),
      invoicePay: this.$hasAuth('invoicePay'),
      deliveryOrderEdit: this.$hasAuth('deliveryOrderEdit'),
      invoiceDel: this.$hasAuth('invoiceDel'),
      deliveryBackConfirm: this.$hasAuth('deliveryBackConfirm'),
      agentOrderPay: this.$hasAuth('agentOrderPay'),
      agentOrderGathering: this.$hasAuth('agentOrderGathering'),
      saveReceivingNote: this.$hasAuth('saveReceivingNote')
    };
  },

  methods: {
    // 审核
    confirmDelivery(params) {
      this.mixin_confirmDelivery(params, this);
    },
    // 弃审
    backConfirmDvery(params) {
      this.mixin_backConfirmDvery(params, this);
    },
    // 删除
    delDelivery(params) {
      this.mixin_delDelivery(params, this);
    },
    // 修改
    deliveryEdit(params) {
      util.menuClickOldPage('/corppage/Deliverys/newDelivery?menuid=deliveryOrderList&cDeliveryNo=' + params.row.cDeliveryNo, this, { title: this.mlang('deliveryDetail') });
    },
    // 付款
    pay(params) {
      util.menuClickOldPage('/corppage/Payments/addPayment?fromDelivery=true&orderno=' + params.row.cDeliveryNo, this, { title: this.mlang('paymentDetail') });
    },
    // 开票
    invoice(params) {
      getInvoice(params.row.cOrderNo).then((res) => {
        let invoiceObj = {
          type: 'business',
          deliveryNo: params.row.cDeliveryNo,
          bankName: res.cBankName ? res.cBankName : '',
          subBankName: res.cSubBankName ? res.cSubBankName : '',
          bankAccount: res.cBankAccount ? res.cBankAccount : '',
          cInvoiceTitle: res.cInvoiceTitle ? res.cInvoiceTitle : '',
          iInvoiceMoney: params.row.fPayMoney ? params.row.fPayMoney : '',
          cInvoiceTelephone: '',
          buyerTaxNo: res.cTaxNum ? res.cTaxNum : '',
          cInvoiceContent: res.cInvoiceContent ? res.cInvoiceContent : '',
          email: params.row.agentEmail ? params.row.agentEmail : '',
          cInvoiceType: res.cInvoiceType ? res.cInvoiceType : '',
          invoiceAddress: res.cInvoiceAddress ? res.cInvoiceAddress : '',
          invoiceTelephone: res.cInvoiceTelephone ? res.cInvoiceTelephone : ''

        };
        const reg = /^1(3|4|5|7|8)\d{9}$/;
        if (res.cInvoiceTelephone) {
          if (reg.test(res.cInvoiceTelephone)) {
            invoiceObj.cInvoiceTelephone = res.cInvoiceTelephone;
          }
        }
        this.dispatch('Deliverys', 'on-show-invoice-modal', invoiceObj);
      });
    },
    watchInvoice(params) {
      watchInvoiceApi(params.row.invoiceSerialNo).then((res) => {
        if (res && res[params.row.invoiceSerialNo] && res[params.row.invoiceSerialNo].shareUrl) {
          this.$open(res[params.row.invoiceSerialNo].shareUrl);
        } else {
          this.$Message(this.mlang('notFindInvoice'));
        }
      });
    },
    signIn(order) {
      this.$router.push({
        name: 'signIns',
        query: {
          deliveryNo: order.cDeliveryNo,
          id: order.id
        }
      });
    },
    agentReceive(delivery) {
      this.mixin_sureReceivingGood(delivery.row,this)
    },
    agentPay(delivery) {
      this.agentLink({ type: 'payDetail', query: { orderno: delivery.row.cDeliveryNo } });
    }
  }
};
</script>
