<template>
  <div>
    <iframe
      ref="iframe"
      :src="url"
      frameborder="0"
      class="noBorder"
      :scrolling="true"></iframe>
  </div>
</template>
<script>
import qs from 'qs';
import { getMenuUrlByCode } from '@/api/system/menu';
import Bus from './bus.js';
export default {
  name: 'SummaryIframe',
  data() {
    return {
      url: ''
    };
  },
  async mounted() {
    const { name } = this.$route;
    let url = '';
    const code = name === 'amountCount' ? 'amountRebateList' : 'productRebateList';
    await getMenuUrlByCode(code).then((res) => {
      url = res.url && res.url.replace(/^https?\:/i, '');
    });
    Bus.$on('surplusParmas', surplusParmas => {
      const _vm = this;
      _vm.url = surplusParmas && `${url}&${qs.stringify(surplusParmas)}`;
      surplusParmas && _vm.sendMessage(surplusParmas);
    });
  },
  methods: {
    // data传递的数据
    sendMessage(data) {
      const mapFrame = this.$refs['iframe'];
      if (mapFrame) {
        const iframeWin = mapFrame.contentWindow;
        iframeWin.postMessage(data, '*');
      }
    }
  }
};
</script>
<style lang="less" scoped>
.noBorder{
  width: 1200px;
  height: 600px;
  border-top: 1px solid #000;
}
</style>
