import { deepCopy } from '@/u-components/utils/assist';
import { getUpProductClassTree } from '@/api/refer-tree';
// import util from '@/libs/util';

const productEditRefer = (ctrl, vm) => {
  const columns = [
    {
      showCaption: vm.mlang('code'),
      isShowIt: true,
      fieldName: 'cCode',
      width: 130
    },
    {
      showCaption: vm.mlang('product'),
      isShowIt: true,
      fieldName: 'name',
      width: 270
    },
    {
      showCaption: vm.mlang('fSalePrice'),
      isShowIt: true,
      fieldName: 'fSalePrice',
      width: 100
    },
    {
      showCaption: vm.mlang('valuationQuantity'),
      isShowIt: true,
      fieldName: 'iQuantity',
      width: 150
    },
    {
      showCaption: vm.mlang('auxUnit'),
      isShowIt: true,
      fieldName: 'iAuxQuantity',
      width: 150
    },
    {
      showCaption: `${vm.mlang('littleSum')}(${vm.mlang('yuan')})`,
      isShowIt: true,
      fieldName: 'fSalePayMoney',
      width: 120
    }
  ];

  const control = Object.assign(
    {},
    {
      key: 'productEditRef', // 只有为这个才会显示特殊商品参照
      noMetaSetting: true,
      referType: 'TreeTable',
      pagerSizeOpts: [10, 20],
      showTitle: true,
      closeable: true,
      page: true,
      bRefer: true,
      controlType: 'TreeRefer',
      refReturnValue: 'id',
      refName: 'productName',
      queryschema: [
        {
          name: 'value',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('productNameOrCode'),
          conditionType: 'like'
        }
      ],
      columns,
      actions: { queryTree: getUpProductClassTree }
    },
    ctrl
  );
  const exisStatus = control.queryschema.find((v) => v.name === 'status');
  if (!control.isAgent && !exisStatus) {
    const status = {
      name: 'status',
      controlType: 'Select',
      groupType: 2,
      valueType: 'LONG',
      isShowIt: true,
      placehoder: vm.mlang('status'),
      isEnum: true,
      width: 220,
      enumString: `[{"title":"${vm.mlang('shelves')}","value":"1","name":"1"},{"title":"${vm.mlang('notShelves')}","value":"0","name":"0"}]`,
      conditionType: 'eq'
    };
    control.queryschema.push(status);
  }
  return deepCopy(control);
};
export default productEditRefer;
