<template>
  <div>
    <Upload
      ref="upload"
      :accept="accept"
      :format="format"
      :show-upload-list="false"
      :default-file-list="defaultList"
      :on-success="handleSuccess"
      :max-size="maxSize"
      :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize"
      :before-upload="handleBeforeUpload"
      :multiple="control.multiple"
      :data="control.data"
      :action="control.action"
      type="drag"
      style="display: inline-block;width:58px;">
      <div style="width: 58px;height:58px;line-height: 58px;">
        <div
          v-for="item in uploadList"
          :key="item.uid"
          class="demo-upload-list">
          <template v-if="item.status === 'finished'">
            <img :src="item.url">
            <div class="demo-upload-list-cover">
              <Icon
                type="camera"
                size="20"></Icon>
            </div>
          </template>
          <template v-else>
            <Progress
              v-if="item.showProgress"
              :percent="item.percentage"
              hide-info></Progress>
          </template>
        </div>
        <Icon
          type="camera"
          size="20"></Icon>
      </div>
    </Upload>
  </div>
</template>

<script>
export default {
  name: 'FromUpload',
  props: {
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    control: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      imgName: '',
      visible: false,
      defaultList: [],
      uploadList: []
    };
  },
  computed: {
    format() {
      const defaultAccept = ['jpg', 'jpeg', 'png'];
      return defaultAccept;
    },
    accept() {
      return 'image/*';
    },
    maxSize() {
      return this.control.maxSize || 10240;
    },
    maxLength() {
      return this.control.maxLength || 1;
    }
  },
  watch: {
    value(val) {
      this.setValue(val);
    }
  },
  mounted() {
    this.uploadList = this.$refs.upload.fileList;
  },
  methods: {
    setValue(v) {
      this.$refs.upload.fileList = [];
      this.defaultList = [];
      if (v) {
        this.defaultList.push({ name: 'tmp', url: v });
      }
      setTimeout(() => {
        this.uploadList = this.$refs.upload.fileList;
      }, 0);
    },
    handleView(name) {
      this.imgName = name;
      this.visible = true;
    },
    handleRemove(file) {
      const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
    },
    handleSuccess(res, file, fileList) {
      fileList = [];
      if (res.code === 200) {
        file.url = res.data.cUrl;
        file.name = res.data.cFileName;
        this.$emit('input', file.url);
      }
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: 'The file format is incorrect',
        desc: 'File format of ' + file.name + ' is incorrect, please select jpg or png.'
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: 'Exceeding file size limit',
        desc: 'File  ' + file.name + ' is too large, no more than 2M.'
      });
    },
    handleBeforeUpload() {
      const check = this.uploadList.length < 5;
      if (!check) {
        this.$Notice.warning({
          title: 'Up to five pictures can be uploaded.'
        });
      }
      return check;
    }
  }
};
</script>

<style scoped>
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.demo-upload-list img {
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}
.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
