<template>
  <div class="contractRef">
    <Row>
      <!-- 左侧合同列表 -->
      <div class="pull-left product-ref-center" style="width:200px">
        <div class="src-bill-refer">
          <Refer
            ref="src-bill-ref"
            :control="srcBillControl"
            :biz-meta="srcBillBizMeta"
            @on-ok="srcBillRefSelected"
            :showText="selectContractName"
            :placeholder="placeholder"
            >
          </Refer>
        </div>

        <div class="leftPanel modal-tree-panel">
          <ReferTree
            ref="referTreeRef"
            :data="treeData"
            :is-filter="true"
            @on-tree-selected="handleTreeSelected"
            >
          </ReferTree>
        </div>
      </div>
      <!-- 右侧某一合同明细 -->
      <div class="pull-right product-ref-right" style="width:600px">
        <!-- 456 -->
        <QuerySchema
          ref="queryschema2"
          :b-refer="true"
          :biz-meta="cloneMeta"
          class="queryschema2"
          :filters="flattenQueryschemaArr2"
          @on-queryschema-search="queryschemaSearch2"></QuerySchema>
          <Table
          :meta-setting="bizMeta"
          :columns="subTableColumn"
          :loading="subTableLoading"
          :highlight-row="subHighlightRow"
          :height="434"
          :data="subTableData"
          :row-class-name="rowClassName"
          @on-selection-change="subTableSectionChange">
          <div slot="footer">
            <div style="float: right;">
              <Page
                :total="pager2.totalCount"
                :current.sync="pager2.currentPage"
                simple
                @on-change="changePage2"></Page>
            </div>
          </div>
        </Table>
      </div>
    </Row>
  </div>
</template>
<script>
import CommonMixin from '@/mixin/common.js';
import Refer from '_c/common/refer/refer';
import ReferTree from '_c/common/refer/refer-tree';
import { srcBillRefer } from '@/meta/refer-common';
import { getSrcProducts, getProducts, getSalesContractObjectByLevel, getSalesContractObjectByLevelBySacoId } from '@/api/refer-modal';
import { getProductClassTreeYS } from '@/api/refer-tree';
import { deepCopy } from '@/u-components/utils/assist';
import { upHideString } from '@/filters/index';
import { getDiscountPrice } from '@/api/product';
export default {
  name: 'SrcBillRefTable',
  mixins: [CommonMixin],
  components: { ReferTree },
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    isQueryschema: {
      type: [Boolean],
      default: true
    },
    billMeta: {
      default: {},
      type: Object
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    showInput: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    const control = (vm) => {
      return {
        action: "srcBillIdReferAction",
        caption: `${this.mlang('saleContract')}`,
      };
    };
    return {
      srcBillControl: control(this),
      srcBillBizMeta: {
        actions: { srcBillIdReferAction: this.referAction }
      },
      treeData: [],
      cloneMeta: null,
      queryschema: null,
      pager2: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      subTableColumn: [], // 销售合同的表头
      subTableLoading: false,
      subTableData: [], // 销售合同的数据,
      selectContract: {}, //选择的合同
      specReferParams: '', // 规格/商品参照点击的参数
      rowno: 0 ,// 临时合同明细标识
      tempContractDetailsArr: [], // 临时合同明细
      clickRowIndex: 0, // 被点击的合同的数组下标
      selectedRows: [],
      skuTableData: [], // 选择规格数据
      tempNoSpecsProductArr: [], // 临时单规格商品
      tempSpecsProductArr: [], // 临时多规格商品
      cacheContractDetails: [], // 缓存的合同明细
      cacheSactId: 0, // 记录已经在下单好的合同id
      mainTableLoading: false,
      mainTableData: [], // 销售合同列表的数据
      selectNodesId: null,
      subTableCheckedArr: [],
      selectedMainRow: null,
      showExpand: {},
      tempTableObj: {}, //加减号记录
      searchTempTableObj: {}, //搜索后数据临记录
      tempSubTableDataArr: [], // 临时的存储的每一页合同明细
      selectContractName: '', //上次选中的合同名称
      selectContractProducts: null, //上次选中的合同商品
      subHighlightRow: true,
      placeholder: this.mlang('pleaseSelectSaleBillOrder'),
      dataFlag: null, //用于区分是搜索还是直接点击+号的暂存数据的标识
      contractId: null,  //合同id
    };
  },
  watch: {
    billMeta: {
      handler() {
        this.init();
      },
      deep: true
    },
    contractId: {
      handler(newValue, oldValue) {
        const selectContract = this.$store.getters.GET_CONTRACT;
        if (selectContract && selectContract.contract && selectContract.contract.id && !oldValue) {
          oldValue = selectContract.contract.id
        }
        if (newValue !== oldValue) {
          this.subTableCheckedArr = [];
          this.selectContractProducts = null;
          this.$store.commit('SET_CONTRACT_TEMPSTOREPRODUCTS', null);
        }
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.commit('SET_CONTRACT_TEMPSTOREPRODUCTS', null);
  },

  methods: {
    init() {
      this.getTreeData();
      this.makeMeta();
      this.makeQueryschema();
      // this.makeMainColumns();
      this.setSubColumns();
      this.loadSelectContract();
    },
    loadSelectContract() {
      const storeContractData = window.localStorage.getItem('storeContractData')
      const fromStoreOrder = this.$store.getters.GET_CONTRACT_FROMSTOREORDER;// 暂存订单的标识
      if (storeContractData && fromStoreOrder) {
        this.placeholder = null;
        this.$store.commit('SET_CONTRACT', JSON.parse(storeContractData));
      }
      const selectContract = this.$store.getters.GET_CONTRACT;
      if (!selectContract) {
        return ;
      }else {
        this.placeholder = null;
      }
      this.selectContractName = selectContract.contract && selectContract.contract.name;
      this.selectContractProducts = selectContract.selectContractProducts;
      this.srcBillRefSelected(selectContract.contract)
    },
    makeMeta() {
      this.cloneMeta = deepCopy(this.billMeta);
    },
    makeQueryschema() {
      let queryschemas = deepCopy(this.billMeta.queryschema);
      let queryschemas2 = deepCopy(this.billMeta.queryschema2);
      this.flattenQueryschemaArr = queryschemas.filter((v) => {
        return v.isHidden !== true;
      });
      this.flattenQueryschemaArr2 = queryschemas2.filter((v) => {
        return v.isHidden !== true;
      });
      this.flattenQueryschemaArr2.forEach(item => {
        if (item.name === "contractDetailKeyword") {
          item.placeholder = this.mlang('newSearchPlaceholdre')
        }
      })
    },
    referAction(filterMeta) {
      let _currentUser = this.$store.getters.currentUser;
      filterMeta.beforeQuery = (query) => {
        query.conditions.push(
          {
            name: 'agentId',
            controlType: 'String',
            valueType: 'STRING',
            groupType: 2,
            conditionType: 'eq',
            v1: _currentUser.agentId
          },
          {
            name: 'contractStatus',
            controlType: 'String',
            valueType: 'STRING',
            groupType: 2,
            conditionType: 'in',
            v1: '5'
          }
        );
        query.conditions.push(
          {
            name: 'saleOrgId',
            controlType: 'String',
            groupType: 2,
            valueType: 'STRING',
            conditionType: 'eq',
            v1: this.$getBO('ORDERSHOWWAY') ? (this.cloneMeta?.order?.oAgent?.orgId || this.cloneMeta.orgId) : localStorage.getItem('upOrgId')
          });
        return query;
      };
      return srcBillRefer(filterMeta, this);
    },
    srcBillRefSelected(selectRows) {
      this.selectedMainRow = selectRows;
      this.pager2.pageIndex = 1;
      this.pager2.currentPage = 1;
      Object.assign(this.selectContract, { id: selectRows.id, salesOrgId: selectRows.salesOrgId });
      this.contractId = selectRows.id;
      // 切换合同清除临时的存储的每一页合同明细
      this.tempSubTableDataArr = [];
      this.getSrcDetails();
      this.getTreeData()
      this.clearChildAutoComplete();
      this.$refs.queryschema2 && this.$refs.queryschema2.init(); // 调用子组件的清空方法
      this.clearTempStoreProducts()
    },
    clearTempStoreProducts() { //当重新选择合同或者分类的时候 清空vuex中关于当前勾选的商品存储
      this.$store.commit('SET_CONTRACT_TEMPSTOREPRODUCTS', null);
    },
    handleTreeSelected(selectNodes) {
      this.selectNodesId = selectNodes[0] && selectNodes[0].id;
      if (!this.selectContract.id) {
        this.$Modal.error({
          content: this.mlang('pleaseSelectTheContractFirst')
        });
        return;
      }
      this.pager2.pageIndex = 1;
      this.pager2.currentPage = 1;
      this.getSrcDetails(this.selectNodesId)
      // 如果没有关键字了
      if (!this.specReferParams.keyword) {
        this.tempTableObj = {}
      }
      this.$refs.queryschema2 && this.$refs.queryschema2.init(); // 调用子组件的清空方法
      this.clearTempStoreProducts()
    },
    getTreeData() {
      getProductClassTreeYS({}).then((res) => {
        this.treeData = res;
      });
    },
    queryschemaSearch2(queryschema) {
      if (!this.selectContract.id) {
        this.$Modal.error({
          content: this.mlang('pleaseSelectTheContractFirst')
        });
        return;
      }
      this.getTreeData()
      this.clearChildAutoComplete();
      this.queryschema = queryschema;
      this.pager2.pageIndex = 1;
      this.pager2.currentPage = 1;
      this.getSrcDetails(null, true);
    },
    mergeProduct(subTableCheckedArr, selectContract) {
      let mergedArray = {};
      subTableCheckedArr.forEach(obj => {
          mergedArray[obj.skuId] = obj;
      });
      selectContract.forEach(obj => {
          if (!mergedArray[obj.skuId]) {
              mergedArray[obj.skuId] = obj;
          }
      });
      let resultArray = Object.values(mergedArray);
      return resultArray;
    },
    getSrcDetails(selectNodesId, fromQuery) {
      const selectContract = this.$store.getters.GET_CONTRACT_TEMPSTOREPRODUCTS || this.selectContractProducts; //上一次选择的商品
      if (this.subTableCheckedArr) { //当前选择的商品
        if (selectContract && selectContract.length) {
          this.subTableCheckedArr = this.mergeProduct(this.subTableCheckedArr, selectContract) //合并当前选的商品和上一次选的
        }
        this.subTableCheckedArr.forEach(item => item.tempStoreProduct = true) // 用来和下次选的做区分
        this.$store.commit('SET_CONTRACT_TEMPSTOREPRODUCTS', this.subTableCheckedArr);
      }
      let postData = {
        conditions: [],
        pager: {
          pageIndex: this.pager2.pageIndex,
          pageSize: this.pager2.pageSize
        }
      };
      if (this.queryschema && this.queryschema.conditions && this.queryschema.conditions.length) {
        this.queryschema.conditions.forEach((v) => {
          if(v.name === "contractDetailKeyword" && fromQuery){
            postData.conditions.push({
            name: 'keyWord',
            controlType: 'String',
            groupType: 2,
            valueType: 'STRING',
            conditionType: 'eq',
            v1: v.v1,
          });
          }
        });
      }
      const salesContractConditionOBj = {
        name: 'salesContractId',
        controlType: 'Long',
        valueType: 'LONG',
        groupType: 2,
        conditionType: 'eq',
        v1: this.selectContract.id || ''
      };
      const orgIdConditionOBj = {
        name: 'orgId',
        controlType: 'Long',
        valueType: 'LONG',
        groupType: 2,
        conditionType: 'eq',
        v1: this.selectContract.salesOrgId || ''
      };
      const classIdOBj = {
        name: 'porductClassId',
        controlType: 'Long',
        valueType: 'LONG',
        groupType: 2,
        conditionType: 'eq',
        v1: selectNodesId || ''
      };
      if (!postData.conditions.find(item => item.name === 'keyWord')) {
        postData.conditions.push(salesContractConditionOBj, orgIdConditionOBj, classIdOBj);
      }else {
        postData.conditions.push(salesContractConditionOBj, orgIdConditionOBj);
      }

      // 如果临时的存储的合同明细存在直接用临时存储的数据就行返回
      const flag = this.tempSubTableDataArr.find(item => item.page == this.pager2.pageIndex && item.selectNodesId === selectNodesId);
      if (flag && !fromQuery && !this.selectContractProducts) {
        this.subTableData = flag.subTableData;
        return
      }
      this.subTableLoading = true;
      getSalesContractObjectByLevel(postData).then((res) => {
        const tempStoreProducts = this.$store.getters.GET_CONTRACT_TEMPSTOREPRODUCTS;
        if (tempStoreProducts && tempStoreProducts.length) {
          this.subTableCheckedArr = tempStoreProducts;
        };
        if (this.cacheContractDetails && this.cacheContractDetails.length > 0) {
          const processedData = this.assemblyData(this. cacheContractDetails, res.content);
          this.subTableData = processedData;
          let selectedArr = this.subTableData.filter(item => item._checked)
          this.subTableSectionChange(selectedArr, _, true); // 手动触发合同明细的被选中事件
        } else {
          res.content.forEach((item, index) => {
            if (this.subTableCheckedArr && this.subTableCheckedArr.length > 0) { //合并商品之后，当再查询的话，把之前选择的直接覆盖到当前商品
              this.selectContractProducts = this.subTableCheckedArr;
            }
            if (item.productDetails) {
              return;
            }
            if (this.selectContractProducts) {
              this.selectContractProducts.forEach(selectProduct => {
                if (selectProduct.id === item.id && selectProduct.skuId === item.skuId) { //如果之前选择的商品不是加号里面的商品，则把当前subQty赋值过来
                  item.subQty = selectProduct.subQty;
                  item.priceQty = selectProduct.priceQty;
                  item._checked = true;
                }else if (!item.skuId && selectProduct.id === item.id) { //之前选择的商品是加号里面的商品，则在加号下面把这个商品加出来（和加号并列）
                  selectProduct.tempAddProduct = true;
                  selectProduct._checked = true;
                }
              })
            }
          })
          if (this.selectContractProducts) {
            let newContent = res.content.slice();
              newContent.forEach((item, index) => {
                if (item.productDetails) {
                  return;
                }
              this.selectContractProducts.forEach(selectProduct => {
                if (!item.skuId && selectProduct.id === item.id && !res.content.slice(index).find(contentItem => contentItem.skuId === selectProduct.skuId)) { //之前选择的商品是加号里面的商品，则在加号下面把这个商品加出来（和加号并列）
                    res.content.splice(res.content.findIndex((item1)=> item1.id === item.id) + 1, 0, selectProduct);
                  }
                })
            })
          }
          let copyResContent = deepCopy(res.content);
          res.content.forEach((item, index) => {
            item.page = this.pager2.pageIndex;
            //搜索的逻辑 如果有productDetails 那么减号，数据挪下面来
            if(item.productDetails) {
                this.showExpand[item.id] = {
                show: false
              }
              item.productDetails.forEach(item => item.rowColor = true)
              this.tempTableObj[item.id] = {}
              this.tempTableObj[item.id].len = item.productDetails.length;
              this.tempTableObj[item.id].data = item.productDetails;
              this.dataFlag = false;
              copyResContent.splice(copyResContent.findIndex((item1)=> item1.id === item.id) + 1, 0, ...item.productDetails);
              copyResContent.forEach(item => {
                this.selectContractProducts && this.selectContractProducts.forEach(selectProduct => {
                  if (selectProduct.id === item.id && selectProduct.skuId === item.skuId) { //如果之前选择的商品不是加号里面的商品，则把当前subQty赋值过来
                    item.subQty = selectProduct.subQty;
                    item.priceQty = selectProduct.priceQty;
                    item._checked = true;
                  }
                })
              })
            }else if ((item.productId && !item.skuId) || !item.productId) {
              this.showExpand[item.id] = {
              show: true
            };
            }
          })
          this.subTableData = copyResContent;
          let selectedArr = this.subTableData.filter(item => item._checked)
          if (selectedArr.length) {
            this.subTableSectionChange(selectedArr, _, true, 'autoCheck'); // 手动触发合同明细的被选中事件
          }

        }
        this.subTableData.forEach((item, index) => {
          if ( !item.productId || !item.skuId ) {
            item._disabled = true;
          }
        })
        this.pager2.totalCount = res.totalElements;
        this.subTableLoading = false;
      }).catch(() => {
        this.subTableLoading = false;
      });
    },
    // 右侧销售合同被选中事件
    subTableSectionChange(selection, row, status, autoCheck) {
      selection.forEach(item => {
        item.page = this.pager2.pageIndex
      })
      this.subTableCheckedArr = this.subTableCheckedArr.filter(item => (item.tempStoreProduct || item.page != this.pager2.pageIndex));
      this.subTableCheckedArr.push(...selection);
      // 遍历 this.subTableCheckedArr 数组去删除那些重复添加的

      let uniqueItems = {};
      let filteredArray = [];

      this.subTableCheckedArr.forEach(item => {
          const key = `${item.id}-${item.skuId}`;
          if (!uniqueItems[key]) {
              uniqueItems[key] = item;
          } else {
              uniqueItems[key] = item;
          }
      });
      filteredArray = Object.values(uniqueItems);
      this.subTableCheckedArr = filteredArray;

      // 如果取消勾选的商品是之前暂存过的则取消暂存这个商品
      let selectContract = this.$store.getters.GET_CONTRACT_TEMPSTOREPRODUCTS; //上一次选择的商品
      if (!status && selectContract && selectContract.length > 0) {
        row.forEach(rowItem => {
          if (rowItem.skuId) {
            const skuIdToRemove = rowItem.skuId;
            const indexToRemove = selectContract.findIndex(item => item.skuId === skuIdToRemove);
            if (indexToRemove !== -1) {
              selectContract.splice(indexToRemove, 1);
            }
          }
        })
        this.$store.commit('SET_CONTRACT_TEMPSTOREPRODUCTS', selectContract);
      }
      // 以分页数据进行升序排列
      this.subTableCheckedArr = _.sortBy(this.subTableCheckedArr, (item) => {
        return item.page
      })
      if (!autoCheck && status) { //主动勾选的话，销售数量值取业务选项 "订货端默认展示的订购数量"
        selection.forEach(selectionItem => {
          row.forEach(rowItem => {
            if (selectionItem.skuId === rowItem.skuId) {
              let SHOWORDERCOUNT = this.$getBO('SHOWORDERCOUNT');
              let _value;
              switch (SHOWORDERCOUNT) {
                case 'NONE':
                  _value = 0;
                  break;
                case 'ONE':
                  _value = 1;
                  break;
                default:
                  _value = rowItem.iSaleMinOrderQuantity;
              };

              if (rowItem.oriTaxUnitPrice === undefined || rowItem.oriUnitPrice === undefined) {
                const data = {
                  isArchive: 1,
                  skuId: rowItem.skuId,
                  count: Number(_value),
                  bizId: rowItem.bizId,
                  saleOrgId: rowItem.salesOrgId,
                  bizProductId: rowItem.bizProductId,
                  productId: rowItem.productId
                }
                this.subTableLoading = true;
                getDiscountPrice(data).then(res => {
                  const oriTaxUnitPrice = res ? res/Number(_value) : 0;
                  // 监测对象变化，使其触发页面视图更新
                  this.$set(this.subTableData, index, {...this.subTableData[index], oriTaxUnitPrice})
                  this.subTableLoading = false;
                }).catch(err => {
                  _value = rowItem.subQty;
                  this.subTableLoading = false;
                })
              }
              this.subTableData = this.subTableData.map((item, index) => {
                if (item.id === rowItem.id && item.skuId == rowItem.skuId && item.rowno == rowItem.rowno) {
                  if (_value >= 0) {
                    item.subQty = Number(_value);
                    item.priceQty = this.$util.toFixed(this.$util.numDiv(this.$util.numMulti(_value, item.invExchRate), item.invPriceExchRate), item.cqtPrecision, item.cqtUnitRoundType);
                    item._checked = true;
                  } else {
                    item.subQty = 0;
                    item.priceQty = 0;
                    this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
                  }
                }
                return item;
              });
              this.subTableCheckedArr = this.subTableCheckedArr.map((item, index) => {
                if (item.id === rowItem.id && item.skuId == rowItem.skuId && item.rowno == rowItem.rowno) {
                  if (_value >= 0) {
                    item.subQty = Number(_value);
                    item.priceQty = this.$util.toFixed(this.$util.numDiv(this.$util.numMulti(_value, item.invExchRate), item.invPriceExchRate), item.cqtPrecision, item.cqtUnitRoundType);
                    item._checked = true;
                  } else {
                    item.subQty = 0;
                    item.priceQty = 0;
                  }
                }
                return item;
              });
            }
          })
        })

      }
      this.selectedSubTableData(this.subTableCheckedArr);
    },
    // 对销售合同明细从被选中的明细中进行查询判断其是否勾选
    selectedSubTableData(selectedArr) {
      this.subTableData.forEach(item => {
        // 查询当前销售合同明细是否在临时的被选中的skuId数组中
        let flag = selectedArr.find(subitem => item.skuId == subitem.skuId && item.rowno == subitem.rowno);
        if (flag) {
          item._checked = true;
        } else {
          item._checked = false;
        }
      });
    },
    setSubColumns() {
      const vm = this;
      const subColumns = [];
      let column = [
      {
          showCaption: '',
          isShowIt: true,
          width: 50,
          render(_, params) {
            return (
              ( vm.showExpand[params.row.id] && !params.row.skuId ?   (
                vm.showExpand[params.row.id]['show']  ?  <button class="ivu-tree-arrow" onClick={() => vm.expandData(params)}>+</button> : <button class="ivu-tree-arrow" onClick={() => vm.shrinkData(params)}>-</button>
              ) : '' )
            )

          }
        },
        {
          showCaption: vm.mlang('orders.productClassName'),
          isShowIt: true,
          width: 100,
          fieldName: 'productClassName',
          render(_, params) {
            return (
              <div>{ params.row.productClassName || '_' }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.productName'),
          isShowIt: true,
          width: 100,
          fieldName: 'productName',
          render(_, params) {
            return (
              params.row.productId ? (
                <div title={params.row.productName} class="two-line-overflow-hide">
                  { params.row.productName }
                </div>
              ) : <div>_</div>
            )
          }
        },
        {
          showCaption: vm.mlang('guige'),
          isShowIt: true,
          width: 100,
          fieldName: 'specNames',
          render(_, params) {
            return (
              params.row.productId ? (
                params.row.skuId ? (
                  <div>{ params.row.specNames || '_' }</div>
                ) : (
                  <div>_</div>
                )
              ) : ''
            )
          }
        },
        {
          showCaption: vm.mlang('orders.subQty'),
          isShowIt: true,
          width: 80,
          fieldName: 'subQty',
          render(_, params){
            return (
              params.row._disabled ? (
                <div style="padding-left: 7px">
                  {params.row.subQty}
                </div>
              ) : (
                <input
                  class="ivu-input-number-input"
                  value={params.row.subQty}
                  onChange={(e) => vm.changeSubQtyValue(e, params)}
                  onFocus={(e) => vm.changeSubQtyFocus(e, params)}
                  type="number"
                  spellcheck="false"
                  autocomplete="off"
                />
              )
            )
          }
        },
        {
          showCaption: vm.mlang('referenceMargin'), //参考余量
          isShowIt: true,
          width: 100,
          fieldName: 'orderSurplusNum'
        },
        {
          showCaption: vm.mlang('referenceBalance'),
          isShowIt: true,
          width: 100,
          fieldName: 'orderSurplusMoney',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.orderSurplusMoney, 'fSalePrice') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('productModel'),
          isShowIt: true,
          width: 100,
          fieldName: 'model'
        },
        {
          showCaption: vm.mlang('productDescription'),
          isShowIt: false,
          width: 100,
          fieldName: 'modelDescription'
        },
        {
          showCaption: vm.mlang('orders.salesOrgName'),
          isShowIt: true,
          width: 150,
          fieldName: 'salesOrgName'
        },
        {
          showCaption: vm.mlang('orders.skuName'),
          isShowIt: true,
          width: 150,
          fieldName: 'skuName'
        },
        {
          showCaption: vm.mlang('orders.cqtUnitName'),
          isShowIt: true,
          width: 150,
          fieldName: 'cqtUnitName'
        },
        {
          showCaption: vm.mlang('orders.priceQty'),
          isShowIt: true,
          width: 100,
          fieldName: 'priceQty'
        },
        {
          showCaption: vm.mlang('orders.saleunitName'),
          isShowIt: true,
          width: 100,
          fieldName: 'saleunitName'
        },
        {
          showCaption: vm.mlang('orders.oriTaxUnitPrice'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriTaxUnitPrice',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriTaxUnitPrice, 'fSalePrice') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.oriUnitPrice'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriUnitPrice',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriUnitPrice, 'salePriceOrigTaxfree') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.oriSum'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriSum',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriSum, 'fSaleCost') }</div>
            )
          }
        },
        {
          showCaption: vm.mlang('orders.oriMoney'),
          isShowIt: true,
          width: 150,
          fieldName: 'oriMoney',
          render(_, params) {
            return (
              <div>{ upHideString(params.row.oriMoney, 'salePayMoneyOrigTaxfree') }</div>
            )
          }
        }
      ];
      if (this.cloneMeta.isSingleSku) {
        subColumns.push(this.c_radioColumn, ...column);
      } else {
        subColumns.push(this.c_selectionColumn, ...column);
      }
      // 销售合同明细支持企业端闪定页面设置中商品模板业务选项控制UDH-60504
      const filterSubColumns = subColumns.filter((item, index) => {
        if (item.fieldName === 'modelDescription' && !this.$getBO('PRODUCTSPECIFICATION')) {
          return false
        }
        if (item.fieldName === 'model' && !this.$getBO('PACKAGESPECIFICATIONS')) {
          return false
        }
        return item;
      })
      this.subTableColumn = filterSubColumns;
    },
    changePage2(page) {
      // 存储每一页销售合同明细数据
      const flag = this.tempSubTableDataArr && this.tempSubTableDataArr.find(item => item.page == this.pager2.pageIndex);
      if (!flag) {
        this.tempSubTableDataArr.push({page: this.pager2.pageIndex, subTableData: this.subTableData, selectNodesId: this.selectNodesId })
      }
      this.pager2.pageIndex = page;
      let queryschmaShow = this.queryschema && this.queryschema.conditions && this.queryschema.conditions.length > 0
      this.getSrcDetails(this.selectNodesId, queryschmaShow);
    },
    // 是否有合同明细缓存
    getCacheOrder() {
      this.cacheContractDetails = [];
      let order = this.cloneMeta.order;
      order && order.oOrderDetails.map((v) => {
        delete (v.id);
        if (v.actionStatus !== 'D') {
          this.cacheContractDetails.push(v);
        }
      });
      if (this.cacheContractDetails.length > 0) {
        this.cacheSactId = this.cacheContractDetails[0].sactId;
      }
    },
    // 提交合同时的校验
    getSelectRow() {
      if (!this.selectContract.id) {
        this.$Message.warning({ content: this.mlang('pleaseSelectSaleBillOrder') });
        return false;
      }
      if (!this.subTableCheckedArr.length) {
        this.$Message.warning({ content: this.mlang('pleaseCheckProduct') });
        return false;
      }
      // 检查被选中的销售合同是否没有选择规格/商品
      const ifHasProdductOrSku = this.subTableCheckedArr.some(item => {
        return item.productId === undefined || item.skuId === undefined
      })
      if (ifHasProdductOrSku) {
        this.$Message.warning({ content: `${this.mlang('pleaseSelectProduct')}或${this.mlang('pleaseSelectSku')}` });
        return false;
      }

      // 是否没有填写销售数量
      const ifFillSalesQuantity = this.subTableCheckedArr.some(item => {
        return item.subQty == 0 || item.priceQty == 0
      })

      if (ifFillSalesQuantity) {
        this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
        return false;
      }

      if (this.cloneMeta.multiple) {
        return this.selectedRows;
      } else {
        return this.getSingleSelectRow();
      }
    },
    getSingleSelectRow() {
      const srcBillOrder = this.selectedMainRow;
      Object.assign(srcBillOrder, { bizId: this.billMeta.bizId });
      const srcBillDetails = this.subTableCheckedArr;
      srcBillOrder.orderDetails = srcBillDetails;
      return [srcBillOrder];
    },
    // 加号点击事件
    async expandData(params) {
      // 点击加号逻辑
      try {
        let arr;
        // 查询的时候存了一份id, data，根据id展开， 根据data填充数据
        if ((this.dataFlag && this.tempTableObj[params.row.id] && this.tempTableObj[params.row.id].dataFlag) || (this.queryschema && this.queryschema.conditions && this.queryschema.conditions.length && this.tempTableObj[params.row.id] && this.tempTableObj[params.row.id].data)) {
          arr = this.tempTableObj[params.row.id].data;
          this.showExpand[params.row.id]['show'] = false;
        }else {
          this.subTableLoading = true;
          this.showExpand[params.row.id]['show'] = false;
          const res = await getSalesContractObjectByLevelBySacoId(params.row.id);
          res.forEach(item => {
            item.rowColor = true;
            this.subTableData = this.subTableData.filter(item2 => {
              if (item2.tempAddProduct && item.skuId === item2.skuId && item.id === item2.id) {
                item.subQty = item2.subQty;
                item._checked = true;
                return false; // 过滤掉符合条件的元素
              }
              return true; // 保留不符合条件的元素
            });
          });
          arr = res;
          this.tempTableObj[params.row.id] = {}
          this.tempTableObj[params.row.id].len = arr.length;
        }
        let index = this.subTableData.findIndex((item1)=> item1.id === params.row.id) + 1
        this.subTableData.splice(index, 0, ...arr)
        this.subTableLoading = false;
      } catch (error) {
        console.log("error: ", error);
        this.showExpand[params.row.id]['show'] = true;
        this.subTableData = [...this.subTableData]; // 创建一个新数组
        this.subTableLoading = false;
      }
    },
    // 减号点击事件
    shrinkData(params) {
      // 点击减号逻辑
      let index = this.subTableData.findIndex((item1)=> item1.id === params.row.id) + 1
      if (this.tempTableObj[params.row.id]) {
        let deleteData = this.subTableData.splice(index, this.tempTableObj[params.row.id].len);
        this.dataFlag = true;
        this.tempTableObj[params.row.id].dataFlag = true;
        this.tempTableObj[params.row.id].data = deleteData;
      }
      this.showExpand[params.row.id]['show'] = true;
      this.subTableData = [...this.subTableData];
    },
    // 手动修改销售数量并通过主辅计算率对计价数量进行赋值
    changeSubQtyValue(e, params) {
      const { row, index } = params;
      const { target } = e;
      if (!Number(target.value) || target.value < 0) {
        target.value = 0;
        this.subTableData.forEach((item, index) => {
          if (params.index === index) {
              item.subQty = target.value;
              item.priceQty = target.value;
          }
        });
        this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
        return false;
      }
      if (row.oriTaxUnitPrice === undefined || row.oriUnitPrice === undefined) {
        const data = {
          isArchive: 1,
          skuId: row.skuId,
          count: Number(target.value),
          bizId: row.bizId,
          saleOrgId: row.salesOrgId,
          bizProductId: row.bizProductId,
          productId: row.productId
        }
        this.subTableLoading = true;
        getDiscountPrice(data).then(res => {
          const oriTaxUnitPrice = res ? res/Number(target.value) : 0;
          // 监测对象变化，使其触发页面视图更新
          this.$set(this.subTableData, index, {...this.subTableData[index], oriTaxUnitPrice})
          this.subTableLoading = false;
        }).catch(err => {
          target.value = row.subQty;
          this.subTableLoading = false;
        })
      }
      this.subTableData = this.subTableData.map((item, index) => {
        if (params.index === index) {
          if (e.target.value >= 0) {
            item.subQty = Number(e.target.value);
            item.priceQty = this.$util.toFixed(this.$util.numDiv(this.$util.numMulti(e.target.value, item.invExchRate), item.invPriceExchRate), item.cqtPrecision, item.cqtUnitRoundType);
            item._checked = true;
          } else {
            item.subQty = 0;
            item.priceQty = 0;
            this.$Message.warning({ content: this.mlang('PleaseFillSalesQuantity') });
          }
        }
        return item;
      });
      // this.selectedSubTableData(this.subTableCheckedArr);
      let selected = this.subTableData.filter(item => item._checked);
      this.subTableSectionChange(selected, _, true, 'autoCheck'); // 手动触发合同明细的被选中事件
    },
    changeSubQtyFocus(e, params) {
      //注册一个鼠标事件监听鼠标移动，只要鼠标移动出销售数量那个输入框的范围，去触发失去焦点事件，并删除事件监听
      const { target } = e;
      const eventFunc = (event) => {
        if (event.target !== target) {
          target.blur();
          document.removeEventListener('mouseover', eventFunc);
        }
      }
      document.addEventListener('mouseover', eventFunc)
    },
    rowClassName(row, index) {
      if (row.rowColor) {
        return 'table-row-color'
      }
    },
    clearChildAutoComplete() {
      this.$refs.referTreeRef && this.$refs.referTreeRef.clearAutocomplete(); // 调用子组件的清空方法
    }
  }
};
</script>
<style lang="less" scoped>
.contractRef {
  .product-ref-center {
    border-right: transparent !important;
  }
  /deep/.ivu-row {
  display: flex;
  }
  /deep/.product-ref-right {
    flex: 1
  }
  /deep/.queryschema {
    width: 100%;
  }
  /deep/.ivu-table.ivu-table-with-fixed-top {
    overflow-x: scroll;
  }
  /deep/ .ivu-table-row-highlight td {
     background: #D5E2F5 !important;
  }
  /deep/ .ivu-table-row-hover td {
    background: #D5E2F5 !important;
  }
  .orderSelected-modal {
    /deep/ .ivu-modal-content {
      .ivu-modal-body {
        height: 514px;
      }
    }
  }
  /deep/ .ivu-table {
    overflow-x: scroll;
    .table-row-color td {
      background-color: #EDF1F7;
    }
  }
}
.src-bill-refer {
  width: 80%;
  margin: auto;
  margin-top: 10px;
}
.symbols {
  width: 14px !important;
  height: 14px !important;
  text-align: center;
  line-height: 12px !important;
  border: 1px solid #ccc !important;
  border-radius: 2px !important;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  color: #333;

}
.table-row-color {
   font-size: 222px;
  td {
  background: #EDF1F7;
}
}


</style>
