const combinationPromotionDetail = {
  path: 'promotionGroup/combinationPromotionDetail',
  name: 'combinationPromotionDetail',
  component: () => import('@/views/agent/promotionGroup/combinationPromotionDetail'),
  meta: {
    menuCode: 'agentPromotionGroup'
  }
};

export default combinationPromotionDetail;
