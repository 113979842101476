<template>
  <div class="agent-bottom">
    <ul class="agent-bottom-lists noticeList">
      <li class="agent-bottom-list agent-bottom-list-top">
        <span class="proclamation">{{ mlang('notice') }}</span>
        <a class="more" href="v#/agent/notices/notices" fieldid="fieldid_noticeList_more">{{ mlang('more') }}</a>
      </li>
      <ul class="noticeClasses">
        <li v-if="noticeClasses.length">
          <span class="classes">{{ mlang('fenlei') }}:</span>
        </li>
        <li v-for="category in noticeClasses" :key="category.id" class="category" @click="chooseCategory(category)">
          <span :class="{ categoryChecked: category.checked }">{{ category.name }}</span>
        </li>
      </ul>
      <li v-if="!noticeList.length" class="noNotice">
        <span>{{ mlang('noNotice') }}</span>
      </li>
      <li v-for="notice in noticeList" :key="notice.id" class="agent-bottom-list agent-bottom-list-h">
        <a href="javacript:void(0)" class="left" @click="toNotice(notice)">
          {{ ((notice.noticeCategoryName ? '[' + notice.noticeCategoryName + ']' : '') + notice.title) | substr(20) }}
        </a>
        <span class="right">{{ notice.createdDate | upDate('MM-DD') }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { getNoticeSettings, noticeTypeAction } from '@/api/setting/notice-setting.js';

import QuerySchemaLabel from '../../../../components/common/queryschema/queryschema-label.vue';
export default {
  name: 'NoticeList',
  components: {
    QuerySchemaLabel
  },
  data() {
    return {
      noticeList: [],
      noticeClasses: [
        {
          name: this.mlang('all'),
          id: '0',
          checked: true
        }
      ]
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const postData = { querySchema: { conditions: [], pager: { pageIndex: 1, pageSize: 4 } } };
      const orgId = this.$getBO('ORDERSHOWWAY') ? '666666' : localStorage.getItem('upOrgId');
      getNoticeSettings(postData).then((res) => {
        this.noticeList = res.content;
      });
      noticeTypeAction({ orgId }).then((res) => {
        this.noticeClasses.push(...res);
      });
    },
    toNotice(item) {
      if (item.noticeType === 'URL') {
        this.$open(item.url);
      } else {
        this.$router.push({
          name: 'notice',
          query: {
            id: item.id,
            createdDate: item.createdDate
          }
        });
      }
    },
    chooseCategory(category) {
      if (category.checked) {
        return;
      }
      const postData = { querySchema: { conditions: [], pager: { pageIndex: 1, pageSize: 4 } } };
      const conditions = category.id === '0' ? [] : [
        {
          conditionType: 'eq',
          isDefine: false,
          isOuterField: false,
          name: 'noticeCategoryId',
          v1: category?.id,
          valueType: 'LONG'
        }
      ];
      postData.querySchema.conditions = conditions;
      this.noticeClasses.forEach((item) => {
        if (item.id !== category.id) {
          this.$set(item, 'checked', false);
        } else {
          getNoticeSettings(postData).then((res) => {
            this.noticeList = res?.content;
          });
          this.$set(item, 'checked', true);
        }
      });
    }
  }
};
</script>
<style lang="less">
.noticeList {
  .queryschema {
    margin-bottom: 0px;
    .queryschema-left {
      max-width: 184px;
      overflow-x: scroll;
      min-width: auto;
      .queryschema-row {
        margin-bottom: 0 !important;
      }
      .label-control {
        padding-left: 0;
        min-width: auto;
        width: 50px;
      }
      .queryschema-control {
        flex: 1;
        white-space: nowrap;
      }
    }
    .queryschema-right {
      display: none;
    }
  }
}

.classes {
  color: #666;
  text-align: left;
  margin-right: 5px;
}

.noticeClasses {
  display: flex;
  width: 184px;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 3px;
}

.category {
  margin-right: 4px;
  cursor: pointer;
  span {
    border-radius: 4px;
    padding: 0 6px;
    border: 1px solid transparent;
  }
  span:hover {
    border: 1px solid #dddee1;
  }
}

.categoryChecked {
  border: 1px solid #505766 !important;
}
</style>
