import bankConfigParentView from '@/views/corp/bankConfig/bankConfig.vue';

const bankConfig = [
  {
    path: 'bankConfig',
    name: 'bankConfig',
    title: { i18n: 'title.bankConfig' },
    meta: { module: 'bankConfig', notCache: true },
    component: bankConfigParentView,
    children: [
      {
        path: 'mainBankConfig',
        name: 'mainBankConfig',
        meta: { module: 'bankConfig', notCache: true },
        component: () => import('@/views/corp/bankConfig/mainBankConfig.vue')
      },
      {
        path: 'subBankConfig',
        name: 'subBankConfig',
        meta: { module: 'bankConfig', notCache: true },
        component: () => import('@/views/corp/bankConfig/subBankConfig.vue')
      }
    ]
  }
];

export default bankConfig;
