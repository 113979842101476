export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500070D","确定执行整单生效?") /* "确定执行整单生效?" */,
  OperateCurrent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500070E","是要对当前的") /* "是要对当前的" */,
  OperateContents: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500070F","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000710","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000711","条") /* "条" */,
  delContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000712","删除") /* "删除" */,
  editContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000713","编辑") /* "编辑" */,
  effectContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000714","生效") /* "生效" */,
  disEffectContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000715","失效") /* "失效" */,
  customerLevel: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000716","客户等级") /* "客户等级" */,
  channelLevel: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000717","渠道等级") /* "渠道等级" */,
  confirmDel: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000718","确定删除么？") /* "确定删除么？" */
};
