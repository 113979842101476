<template>
  <div class="header-inline-center caretHover">
    <Poptip
      :transfer="false"
      content="content"
      trigger="hover"
      placement="bottom">
      <p :title="clientValue">
        <span
          v-if="!currentUser.__isAgentSide"
          class="header-user-type">{{ clientKey }}</span>
        {{ currentUser.fullName }}（{{ currentUser.agentName }}）
        <span>
          <i
            v-if="!currentUser.__isAgentSide"
            class="ivu-icon ivu-icon-arrow-down-b ivu-select-arrow"></i>
          <i
            v-else
            class="iconfont icon-xiangxia-copy"></i>
        </span>
      </p>
      <div slot="content">
        <div class="switch-account js-switch-account">
          <div class="list-block media-list">
            <ul class="new-switch-account">
              <template v-if="switchAccountData && switchAccountData.length">
                <li
                  v-for="user in switchAccountData"
                  :key="user.tenantId"
                  @click="switchTetant(user)">
                  <div class="headerName">
                    <p :title="user.tenantName">
                      <SvgIcon icon-class="switchTenant"></SvgIcon><em :class="{'checked': user.checked}">{{ user.tenantName }}</em>
                    </p>
                    <ul v-if="user.identities" style="margin-top:5px">
                      <li v-for="identity in user.identities" :key="identity.id" :class="{'checked': identity.checked}" @click.stop="switchIdentity(identity)">
                        <SvgIcon icon-class="switchIdentify"></SvgIcon>{{ identity.name }}
                      </li>
                    </ul>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </Poptip>
  </div>
</template>
<script>
import { getTants, switchUser } from '@/api/auth';
import { getYHTtoken, clearYHTtoken } from '@/api/order';
import { mapGetters, mapActions } from 'vuex';
import util from '@/libs/util.js';
import printCookie from '@/components/print-ctrl/print-cookie.js';
import Cookies from 'js-cookie';
export default {
  name: 'ChangeAccount',
  data() {
    return {
      switchAccountData: [],
      clientKey: null,
      clientValue: null,
      postData: {
        captcha: null,
        contactWay: null,
        phone: null
      },
      onOkData: {
        validCode: null,
        iUserId: null,
        email: null,
        phone: null,
        key: null
      },
      okUrl: '',
      isModel: false,
      placeholder: null

    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    urlArr() {
      return window.__app.context.ysDownFileUrl.indexOf('daily') > -1 ? ['yyuap.com', 'yonyoucloud.com'] : ['diwork.com', 'yonyoucloud.com'];
    },
    urlMapping() {
      return window.__app.context.ysDownFileUrl.indexOf('daily') > -1 ? {
        'dinghuo-daily.yyuap.com': 'https://dinghuo-bip-daily.yonyoucloud.com/',
        'dinghuo-bip-daily-dedicated.yyuap.com': 'https://dinghuo-bip-daily-dedicated.yonyoucloud.com/'
      } : {
        'dinghuo-yonbip.diwork.com': 'https://dinghuo-c2.yonyoucloud.com/',
        'dinghuo.diwork.com': 'https://dinghuo-c2.yonyoucloud.com/',
        'dinghuo-yonsuite.diwork.com': 'https://dinghuo-c2.yonyoucloud.com/',
        'dinghuo-yonbip-core1.diwork.com': 'https://dinghuo-c1.yonyoucloud.com/',
        'dinghuo-yonbip-core3.diwork.com': 'https://dinghuo-c3.yonyoucloud.com/',
        'dinghuo-yonbip-core4.diwork.com': 'https://dinghuo-c4.yonyoucloud.com/',
        'dinghuo-dbox.diwork.com': 'https://dinghuo-dbox.yonyoucloud.com/',
        'dinghuo-globalbip-new.yonyou.com': 'https://dinghuo-global.yonyoucloud.com/'
      };
    }
  },
  created() {
    this.switchAccount();
    if (this.currentUser.isBiz) {
      // 商家端
      this.clientKey = this.mlang('bizLogo');
      this.clientValue = this.currentUser.bizName;
    } else if (this.currentUser.isAgent) {
      // 订货端
      this.clientKey = this.mlang('agentLogo');
      this.clientValue = `${this.currentUser.fullName}（${this.currentUser.agentName}）`;
    } else {
      // 企业端
      this.clientKey = this.mlang('corpLogo');
      this.clientValue = this.currentUser.bizName;
    }
  },
  methods: {
    ...mapActions(['setUserInfo']),
    async switchTetant(tetant) {
      let self = this;
      if (tetant.tenantId === this.currentUser.yhtTenantId) {
        return false;
      }
      // 如果要切换租户，就先判断是否是当前的数据中心，先做跳转再调用switchUser，传租户 id 就可以了
      if ((tetant.dataCenter && tetant.dataCenter.workbenchUrl.indexOf(window.__app.context.ysDownFileUrl) > -1) || !tetant.dataCenter) {
        switchUser({ 'tenantId': tetant.tenantId }).then((res) => {
          if (window.localStorage.getItem('storeOrder')) {
            window.localStorage.removeItem('storeOrder');
          }
          localStorage.removeItem('upAgentRelationId');
          localStorage.removeItem('storageRelationId');
          localStorage.removeItem('defaultUserInfo');
          if (res.token) {
            printCookie.setPrintToken(window.location.href, '/', 'yht_access_token', res.token);
          }
          Cookies.set('udhTenantId', tetant.tenantId);
          Cookies.remove('userId');
          window.location = '/v#/agent/home';
          window.location.reload();
        });
      } else {
        let token = (await getYHTtoken()).token;
        // 不是当前数据中心
        if (tetant.dataCenter && tetant.dataCenter.workbenchUrl) {
          if (window.location.href.indexOf(this.urlArr[0]) > -1 && tetant.dataCenter.workbenchUrl.indexOf(this.urlArr[1]) > -1) {
            // 外层是旧域名，要切换的数据中心是新域名
            this.$Modal.error({
              content: this.mlang('urlReplace') + this.urlMapping[window.location.host] + '，' + this.mlang('favoriteUrl'),
              onOk: async function() {
                // 清除原token
                await clearYHTtoken();
                printCookie.clearToken();
                window.location = self.urlMapping[window.location.host];
              }
            });
            return false;
          }
          let dn = tetant.dataCenter.workbenchUrl.slice(0, 8) + 'dinghuo-' + tetant.dataCenter.workbenchUrl.slice(8);
          if (window.location.href.indexOf(this.urlArr[1]) > -1 && dn.indexOf(this.urlArr[0]) > -1) {
            dn = this.urlMapping[dn.slice(8, dn.length - 1)];
          }
          let url = dn + `v#/login?accessToken=${token}&tenantId=${tetant.tenantId}`;
          if (window.top === window.self) {
            localStorage.setItem('dataCenterUrl', url);
            // 清除原token
            await clearYHTtoken();
            printCookie.clearToken();
            if (window.localStorage.getItem('storeOrder')) {
              window.localStorage.removeItem('storeOrder');
            }
            localStorage.removeItem('upAgentRelationId');
            localStorage.removeItem('storageRelationId');
            localStorage.removeItem('defaultUserInfo');
            Cookies.remove('udhTenantId');
            Cookies.remove('userId');
            // 切换租户外层租户名称没变
            window.document.title = tetant.tenantName;
            window.location = util.getAgentUrl();
          } else {
            window.location = url;
            window.parent.postMessage({ key: 'udhTitleModify', tenantName: tetant.tenantName }, '*');
          }
        }
      }
    },
    switchIdentity(identity) {
      if (identity.id === this.currentUser.userId) {
        return false;
      }
      // 如果只切换身份，那就调用switchUser接口传两个参数
      switchUser({ 'tenantId': identity.tenantId, 'identityId': identity.id }).then((res) => {
        if (window.localStorage.getItem('storeOrder')) {
          window.localStorage.removeItem('storeOrder');
        }
        if (res.token) {
          printCookie.setPrintToken(window.location.href, '/', 'yht_access_token', res.token);
        }
        Cookies.set('userId', identity.id);
        Cookies.set('udhTenantId', identity.tenantId);
        localStorage.removeItem('upAgentRelationId');
        localStorage.removeItem('storageRelationId');
        window.location = '/v#/agent/home';
        window.location.reload();
      });
    },
    switchAccount() {
      let _this = this;
      getTants().then((res) => {
        if (res.tenantsAndIdentities && res.tenantsAndIdentities.length) {
          res.tenantsAndIdentities.forEach((v) => {
            v.checked = v.tenantId === this.currentUser.yhtTenantId;
            if (v.identities && v.identities.length) {
              v.identities.forEach((j) => {
                j.checked = j.id.toString() === this.currentUser.userId;
              });
            }
          });
          _this.switchAccountData = res.tenantsAndIdentities;
        } else {
          _this.$Message.warning(this.mlang('noAuth'));
        }
      });
    },
    type(user) {
      let type = '';
      if (user.isBiz) {
        type = this.mlang('bizLogo');
      } else if (user.isAgent) {
        type = this.mlang('agentLogo');
      } else {
        type = this.mlang('corpLogo');
      }
      return type;
    }

  }
};
</script>

<style lang="less" scoped>
.header-inline-center{
  padding-top: 0px !important;
  margin-top: 20px;
  float: right;
  padding: 0;
  margin-left: 30px;
  font-size: 14px;
  cursor: pointer;
  .header-user-type{
    margin-right: 3px;
    &.enterprise{
      width:36px;
      height:19px;
      font-size:12px;
      padding: 0 3px;
      font-family:PingFangSC-Regular;
      font-weight:400;
      color:rgba(255,166,0,1);
      line-height:19px;
      background:rgba(255,250,235,1);
      border-radius:2px;
      border:1px solid rgba(255,233,167,1);
    }
    &.merchants{
      width:36px;
      height:17px;
      font-size:12px;
      color:rgba(102,151,239,1);
      line-height:17px;
      background:rgba(88,140,233,0.1);
      border-radius:2px;
      border:1px solid rgba(88,140,233,0.2);
    }
    &.client{
      width:36px;
      height:17px;
      font-size:12px;
      font-family:PingFangSC-Regular;
      font-weight:400;
      color:#25C08B;
      line-height:17px;
      background:#E2F2ED;
      border-radius:2px;
      border:1px solid #BDE9DB;
    }
  }
  .new-switch-account{
    svg{
      width: 20px;
      height: 20px;
      display: inline;
    }
    .current-user-icon{
      position: absolute;
      top: 8px;
      left: 12px;
      color: #EE2223;
      &:before{
        font-size: 14px
      }
    }
    .enterprise{
      span{
        display: inline-block;
        padding: 0 1px;
        font-size:12px;
        color:rgba(255,166,0,1);
        line-height:14px;
        height:16px;
        background:rgba(255,250,235,1);
        border-radius:2px;
        border:1px solid rgba(255,233,167,1);
        vertical-align: middle;
      }
    }
    .headerName{
      position: relative;
      p{
        padding: 0px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        em{
          display: inline-block;
          vertical-align: middle;
          font-style: normal;
          margin-right:5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: #000;
          max-width:200px;
        }
      }
      .checked{
        color: #ef2527;
      }
    }
    .merchants{
      span{
        display: inline-block;
        padding: 0 1px;
        font-size: 12px;
        color: #6697ef;
        line-height: 14px;
        height: 16px;
        background: rgba(88, 140, 233, 0.1);
        border-radius: 2px;
        border: 1px solid rgba(88, 140, 233, 0.2);
        vertical-align: middle;
      }
      .headerName{
        position: relative;
        p{
          line-height: 20px;
          color:#333;
        }
        .client{
          display: block;
          font-size:12px;
          color:#999;
        }
      }
    }
    .client{
      span{
        display: inline-block;
        box-sizing: border-box;
        padding: 0 1px;
        font-size: 12px;
        color:rgba(37,192,139,1);
        line-height: 14px;
        height: 16px;
        background:rgba(24,182,129,0.1);
        border-radius:2px;
        border:1px solid rgba(189,233,219,1);
        vertical-align: middle;
      }
      .headerName{
        position: relative;
        p{
          color:#333;
          line-height:20px;
        }
        .client{
          display: block;
          font-size:12px;
          color:#999;
        }
      }
    }
    .oneHeaderName{
      padding-left:14px;
    }
    .account-manager{
      position: absolute;
      font-size:12px;
      font-family:PingFangSC-Regular;
      font-weight:400;
      color:rgba(88,140,233,1);
      line-height:17px;
      top:20px;
      right:14px;
    }
    li.dhd {
      dt {
        color: #999999;
      }
    }
    li {
      height: auto;
    }
    li:after {
      width: calc(~'100% - 16px');
      left: 16px;
    }
    dt {
      width: 38px;
      height: 18px;
      line-height: 16px;
      text-align: center;
      font-size: 10px;
      color: #999999;
    }
    dt + dd {
      padding-top: 0;
    }
    dd {
      padding-bottom: 0;
      padding-top: 5px;
    }
    dl {
      padding: 5px 14px 10px;
      dt {
        float: left;
        height: 16px !important;
        text-align: left !important;
        margin-top: 5px;
      }
      dd {
        line-height: 16px !important;
        padding-left: 46px !important;
        margin-top: 5px;
      }
    }
  }
  .ivu-poptip {
    .list-block ul li {
      border: none !important;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-sizing: border-box;
    }
    .list-block ul li:hover {
      background: #f3f5f9;
    }
    .list-block ul li:first-child:after {
      height: 0;
      content: none;
    }
    .list-block ul dt {
      width: 46px;
      height: 46px;
      border-radius: 100px;
      overflow: hidden;
      .cLogo {
        height: 46px;
      }
    }
    .list-block ul dt + dd {
      padding-top: 4px;
    }
    .list-block ul dd {
      padding-left: 56px;
      font-size: 12px;
      color: #333;
      padding-bottom: 6px;
    }
    /deep/ .ivu-poptip-body-content {
      max-height: 320px;
      min-height: auto;
    }
  }
  a {
    color: #333;
  }
  a:hover {
    color: #333;
  }
  .ivu-select-arrow {
    right: -15px;
  }
  .ivu-icon-arrow-down-b {
    font-size: 12px;
    margin-top: -3px;
    transform: scale(0.5);
    margin-left: -4px;
    &:before {
      content: '\e762';
    }
  }
  .message-con {
    margin-top: 4px;
  }
}
</style>
