<template>
  <div>
    <div v-if="!currentUser.__isAgentSide">
      <!-- 审批 -->
      <div>
        <div class="action">
          <Row
            class-name="btn"
            type="flex"
            justify="center">
            <span
              v-if="params.row.isShowOpposeBtn && returnedPurchaseReject"
              class="opposeBtn"
              @click="confirmDialog(params)">
              {{ mlang('oppose') }}
            </span>
            <span
              v-if="params.row.isShowOrderUnlockBtn && saleReturnUnLock"
              @click="deblock(params.row)">
              {{ mlang('deblock') }}
            </span>
          </Row>
          <Row
            class-name="btn"
            type="flex"
            justify="center">
            <span
              v-if="params.row.isShowConfirmBtn && saleReturnApply"
              class="sureBtn solidBtn"
              @click="approveSaleReturn(params)">
              {{ mlang('sure') }}
            </span>
          </Row>
        </div>
      </div>
      <!-- 待退货 -->
      <div>
        <div class="action">
          <Row
            class-name="btn"
            type="flex"
            justify="center">
            <span
              v-if="params.row.isShowRollBackBtn && $hasAuth('salereturnConfirmBack')"
              class="rollBackBtn"
              @click="saleReturnConfirmBack(params)">
              {{ mlang('return') }}
            </span>
          </Row>
        </div>
      </div>
      <!-- 收货 -->
      <div>
        <div class="action">
          <Row
            class-name="btn"
            type="flex"
            justify="center">
            <span
              v-if="params.row.isShowSaleReturnConfirmBtn && returnedPurchaseConfirm"
              style="width: 60px;"
              class="rollBackBtn solidBtn"
              @click="confirmSaleReturn(params)">
              {{ mlang('returnGoodsConfirm') }}
            </span>
          </Row>
        </div>
      </div>
      <!-- 完成 -->
      <div>
        <div class="action">
          <Row
            class-name="btn"
            type="flex"
            justify="center">
            <span
              v-if="params.row.isShowRefundsBtn && addRefund"
              class="rollBackBtn"
              @click="goReturnFund(params)">
              {{ mlang('refund') }}
            </span>
          </Row>
        </div>
      </div>
    </div>

    <div v-else>
      <!-- 退货申请  -->
      <div v-if="params.row.cSaleReturnStatus=='SUBMITSALERETURN'" class="action">
        <Row class-name="btn" type="flex" justify="center">
          <!-- 修改 -->
          <span v-if="agentOrderReturnEdit && params.row.isShowModifyBtn" class="rollBackBtn" @click="agentEditSaleReturn(params)">
            {{ mlang('edit') }}
          </span>
          <!-- 删除 -->
          <span v-if="agentOrderReturnDel && params.row.isShowDeletedBtn" class="rollBackBtn" @click="agentDelSaleReturn(params)">
            {{ mlang('delete') }}
          </span>
        </Row>
      </div>

      <!-- 退货确认  -->
      <div v-if="params.row.cSaleReturnStatus=='CONFIRMSALERETURNORDER'" class="action">
        <Row class-name="btn" type="flex" justify="center">
          <!-- 退货 -->
          <span v-if="agentOrderReturnExeute && params.row.isShowSaleReturnBtn" class="rollBackBtn" @click="agentSaleReturnExeute(params)">
            {{ mlang('saleOrderReturn') }}
          </span>
        </Row>
      </div>

      <FormModal
        ref="FormModal"
        :meta="control"
        :form-modal-flag="isShowModal"
        @on-save-click="handleformModalSave"
        @on-form-change="handleformChange"
        @on-cancel="handleCancel"></FormModal>
    </div>
  </div>
</template>

<script>
import Emitter from '@/u-components/mixins/emitter';
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import SalereturnMixin from '@/mixin/salereturn.js';
import agentSaleReturnMixin from '@/mixin/agentSaleReturn.js';
import util from '@/libs/util.js';
import FormModal from '@/views/agent/components/order/components/form-modal.vue';

export default {
  name: 'ReturnAction',
  components: { FormModal },
  mixins: [agentSaleReturnMixin, Emitter, PopTipMixin, CellMixin, SalereturnMixin],
  data: function() {
    return {
      currentUser: this.$store.getters.currentUser,
      returnedPurchaseConfirm: this.$hasAuth('returnedPurchaseConfirm'),
      saleReturnApply: this.$hasAuth('saleReturnApply'),
      returnedPurchaseReject: this.$hasAuth('returnedPurchaseReject'),
      salereturnConfirmBack: this.$hasAuth('salereturnConfirmBack'),
      addRefund: this.$hasAuth('addRefund'),
      agentOrderReturnEdit: this.$hasAuth('agentOrderReturnEdit'),
      agentOrderReturnDel: this.$hasAuth('agentOrderReturnDel'),
      agentOrderReturnExeute: this.$hasAuth('agentOrderReturnExeute'),
      saleReturnUnLock: this.$hasAuth('saleReturnUnLock'), // 解锁的权限码
      stockList: [],
      isShowModal: false,
      control: {
        editModalTitle: this.mlang('setreturnStock'),
        editRows: [{
          controlType: 'select',
          label: this.mlang('returnStock'),
          enumArray: [],
          model: 'selectId'
        }]
      },
      returnStock: '',
      rowData: {}
    };
  },
  created() {
    // console.log(this.currentUser);
    // console.log(this.$Modal);
  },
  methods: {
    // ERP解锁
    deblock(params) {
      this.mixin_saleReturndDeblockOrder(params, this);
    },
    handleformChange(newVal, oldVal) {
      this.returnStock = newVal;
    },
    handleformModalSave() {
      if (!this.returnStock['selectId']) {
        this.$Modal.warning({
          content: this.mlang('noSelectReturnStock')
        });
      } else {
        this.params.row.deliveryStockId = this.returnStock['selectId'];
        this.isShowModal = false;
        this.returnConfirm(this.rowData, this);
      }
    },
    handleCancel() {
      this.isShowModal = false;
      this.returnStock['selectId'] = '';
    },
    // 确认
    approveSaleReturn(params) {
      this.mixin_approveSaleReturn(params, this);
    },
    // 驳回
    confirmDialog(params) {
      this.mixin_confirmDialog(params, this);
    },
    // 回退
    saleReturnConfirmBack(params) {
      this.mixin_saleReturnConfirmBack(params, this);
    },
    // 退货确认
    confirmSaleReturn(params) {
      this.mixin_confirmSaleReturn(params, this);
    },
    // 退款
    goReturnFund(params) {
      var iAmount = params.row.iAmount || params.row.fPayMoney - params.row.fRebateToCashMoney - params.row.fRefundAmount;
      let url = `/corppage/Refunds/edit?menuid=repaymng&iAgentId=${params.row.agent}&iBizId=${params.row.bizId}&salesOrgId=${params.row.salesOrgId}&iAmount=${iAmount}&fromSaleReturns=true&saleReturnNos=${params.row.cSaleReturnNo}&isBatch=${params.row.len > 1 ? 1 : 0}`;
      util.menuClickOldPage(url, this, { title: this.mlang('refundDetail') });
    },
    agentToSaleReturnDetail(params) {
      this.$open(`/agentpage/SaleReturns/newSaleReturnIndex?cSaleReturnNo=${params.row.cSaleReturnNo}&notEdit=true`);
    },
    agentEditSaleReturn(params) {
      this.agentLink({
        type: 'saleReturnDetail',
        query: {
          titleType: 'editor',
          cSaleReturnNo: params.row.cSaleReturnNo,
          salereturnId: params.row.id,
          salesOrgId: params.row.salesOrgId
        }
      });
      // this.$open(`/agentpage/SaleReturns/newSaleReturnIndex?cSaleReturnNo=${params.row.cSaleReturnNo}`);
    },
    agentDelSaleReturn(params) {
      this.mixin_agentDelSaleReturn(params, this);
    },
    agentSaleReturnExeute(params) {
      this.currentUser.__isU8C ? this.mixin_ysAgentSaleReturnExeute(params, this) : this.mixin_agentSaleReturnExeute(params, this);
    }
  }
};
</script>
