import corpParentView from '@/views/corp/home/home.vue';

const order = {
  path: 'order',
  title: { i18n: 'order' },
  name: 'order',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'rebateRuleList',
      title: { i18n: 'title.rebateRuleList' },
      name: 'rebateRuleList',
      meta: { module: 'rebateRuleList' },
      component: () => import('@/views/corp/order/components/rebate-rule-list.vue')
    },
    {
      path: 'rebateRule',
      title: { i18n: 'title.rebateRuleList' },
      name: 'rebateRule',
      component: () => import('@/views/corp/order/components/rebate-rule-list.vue')
    },
    {
      path: 'orders',
      title: { i18n: 'title.orders' },
      name: 'orders',
      meta: { module: 'orders' },
      component: () => import('@/views/corp/order/components/orders.vue')
    },
    {
      path: 'presaleOrders',
      title: { i18n: 'title.presaleOrders' },
      name: 'presaleOrders',
      meta: { module: 'presaleOrders' },
      component: () => import('@/views/corp/order/components/presaleOrders.vue')
    },
    {
      path: 'deliverys',
      title: { i18n: 'title.deliverys' },
      name: 'deliverys',
      meta: { module: 'deliverys' },
      component: () => import('@/views/corp/order/components/deliverys.vue')
    },
    {
      path: 'salereturns',
      title: { i18n: 'title.salereturns' },
      name: 'salereturns',
      meta: { module: 'salereturns' },
      component: () => import('@/views/corp/order/components/salereturns.vue')
    },
    {
      path: 'orderShortageList',
      title: { i18n: 'title.orderShortageList' },
      name: 'orderShortageList',
      component: () => import('@/views/corp/order/components/order-shortage-list.vue')
    },
    {
      path: 'expenses',
      title: { i18n: 'title.expenses' },
      name: 'expenses',
      meta: { module: 'expenses' },
      component: () => import('@/views/corp/order/components/expenses.vue')
    },
    {
      path: 'expense',
      title: { i18n: 'title.expense' },
      name: 'expense',
      meta: { module: 'expenses' },
      component: () => import('@/views/corp/order/components/expense.vue')
    },
    {
      path: 'expenseedit',
      title: { i18n: 'title.expense' },
      name: 'expense',
      component: () => import('@/views/corp/order/components/expense-edit.vue')
    },
    {
      path: 'signInRecords',
      title: { i18n: 'title.signInRecords' },
      meta: { module: 'signInRecords' },
      name: 'signInRecords',
      component: () => import('@/views/corp/order/components/signIn/signInRecords.vue')
    },
    {
      path: 'signIn',
      title: { i18n: 'title.signIn' },
      name: 'signIn',
      meta: { module: 'signIn', notCache: true },
      component: () => import('@/views/corp/order/components/signIn/signIn.vue')
    },
    {
      path: 'signIns',
      title: { i18n: 'title.signIns' },
      name: 'signIns',
      meta: { module: 'signIn' },
      component: () => import('@/views/corp/order/components/signIn/signIns.vue')
    }
  ]
};
export default order;
