<template>
  <div
    v-if="isMultiLang">
    <Dropdown trigger="hover" @on-click="selectLang">
      <i class="iconfont duoyuqiehuan"></i>
      <span href="javascript:void(0)">
        {{ title }}
        <Icon size="11" type="arrow-down-b" />
      </span>
      <DropdownMenu slot="list">
        <DropdownItem v-for="lang in langs" :key="lang.code" :name="lang.code">
          {{ lang.name }}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { switchLanguage } from '@/api/meta';
import { getTants, switchUser, getA01 } from '@/api/auth';
import printCookie from '@/components/print-ctrl/print-cookie.js';
export default {
  name: 'Language',
  props: {
    from: String
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isMultiLang', 'langs', 'currentUser']),
    title() {
      const l = this.langs.find((v) => v.code === this.lang) || {};
      return l.name;
    },
    lang() {
      return this.$store.state.app.lang;
    }
  },
  watch: {
    lang(lang) {
      // this.$i18n.locale = lang;
    }
  },
  methods: {
    ...mapMutations(['setLocal']),
    selectLang(name) {
      localStorage.removeItem('md5');
      localStorage.removeItem('AllLocaleResource');
      sessionStorage.removeItem('AllLocaleResource');
      const l = this.langs.find((v) => v.code === name) || {};
      localStorage.setItem('ybLangCode', l.ysCode);
      this.setLocal(name);
      if (this.from !== 'login') { // 登陆页不调用此接口
        if (localStorage.isRepresentToOrder !== 'true') {
          // 调用getA01接口
          switchUser({
            'tenantId': this.currentUser.yhtTenantId,
            'identityId': this.currentUser.userId,
            'locale': name
          }).then(res => {
            printCookie.setPrintToken(window.location.href, '/', 'yht_access_token', res.token);
            location.reload();
          });
        } else {
          if (name === 'zh-cn') {
            name = 'zh_CN';
          } else if (name === 'zh-tw') {
            name = 'zh_TW';
          } else if (name === 'en-us') {
            name = 'en_US';
          }
          getA01(name).then(res => {
            if(window.location.href.indexOf('isRepresent=true') > -1){
              window.location = '/v#/agent/home'
            }
            location.reload();
          });
        }
      } else {
        location.reload();
      };
    }
  }
};
</script>
