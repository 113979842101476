import { getAgentGroup } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const agentGroupWithAuth = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'name',
      // 传递过去的参数
      passedparams: [
        {
          name: 'isContainAuth',
          v1: true, // 该值会变，不同的值获取不同的内容
          isSkip: false,
          isOuterField: false,
          valueType: 'Boolean',
          conditionType: 'eq'
        }
      ],
      queryschema: [
        {
          name: 'name',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('name'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          fieldName: 'code'
        },
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'name'
        }

      ],
      actions: { queryList: getAgentGroup },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default agentGroupWithAuth;
