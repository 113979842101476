import ajax from '@/libs/ajax';

export const fetchAllBusiEntitysWithSwitchStatus = () => {
  return ajax.request({
    url: '/pc/auth/AuthSrv/getAllBusiEntitysWithSwitchStatus',
    method: 'get'
  });
};

export const getAuth = (auth) => {
  return ajax.request({
    url: '/authority/function/checkAuth?isArchive=1',
    method: 'get',
    data: {
      auth
    }
  });
};

export const updateUsedBusiEntity = (data) => {
  return ajax.request({
    url: '/pc/auth/AuthSrv/updateUsedBusiEntity',
    method: 'post',
    data
  });
};

export const getBulletinsForCommon = (data) => {
  return ajax.request({
    url: '/pc/BulletinSrv/getBulletinsForCommon',
    method: 'post',
    data
  });
};

export const getTants = (data) => {
  return ajax.request({
    url: '/gscm/user/tenants',
    method: 'get',
    data: {
      isMarket: 1,
      ...data
    }
  });
};
export const switchUser = (data) => {
  return ajax.request({
    url: '/gscm/user/switchUser',
    method: 'post',
    data: {
      isMarket: 1,
      data,
      datatype: 'json'
    }
  });
};
export const getA01 = (locale) => {
  return ajax.request({
    url: '/gscm/user/getA01',
    method: 'post',
    data: {
      isMarket: 1,
      data:{
        functionType: 4,
        locale,
      },
      datatype: 'json'
    }
  });
};

export const switchAccount = () => {
  return ajax.request({
    url: '/passport/users/associated',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 权限开关页面新接口

export const fetchAllSwitchStatus = () => {
  return ajax.request({
    url: '/authority/data/getSwitchStatus',
    data: {
      isArchive: 1
    },
    method: 'get'
  });
};

export const authorityAllocation = (data) => {
  return ajax.request({
    url: '/authority/data/permissionsswitch',
    method: 'post',
    data: {
      isArchive: 1,
      data: data,
      datatype: 'json'
    }
  });
};

// 客户权限 树 控件
export const getCustomerAuthTree = (data) => {
  return ajax.request({
    url: '',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
