<template>
  <div
    class="sku-table-cell">
    <div
      v-if="specDescription"
      :title="skuCode"
      class="sku-No one-line-overflow-hide">
      {{ skuCode }}
    </div>
    <div v-if="specDescription" :class="[{'sku-spec-description': isCanModify}]" @mouseenter.stop="switchEditState" @mouseleave.stop="switchEditState">
      <div
        :title="specDescription"
        :class="`sku-attribute ${overflow}`">
        {{ specDescription }}
      </div>
      <div v-show="isEdit && isCanModify" class="sku-btn ivu-btn-primary" @click.stop="onModifySku">
        {{ mlang('modify') }}
      </div>
    </div>
    <SelectProductSku
      v-if="specDescription"
      ref="selectProductSku"
      :is-refer-sale-product="isReferSaleProduct"
      @on-selected="onSelectedProductSku">
    </SelectProductSku>
  </div>
</template>
<script>
import SelectProductSku from '_c/select-product-sku/select-product-sku/';
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'SKUTableCell',
  components: {
    SelectProductSku
  },
  mixins: [CellMixin],
  props: {
    isCanModify: {
      type: Boolean,
      default: false
    },
    isReferSaleProduct: {
      type: Boolean,
      default: false
    },
    bizMeta: {
      type: Object
    }
  },
  data() {
    return {
      isEdit: false
    };
  },
  computed: {
    overflow() {
      return this.bizMeta && this.bizMeta.rowHeight === 60 ? 'two-line-overflow-hide' : 'one-line-overflow-hide';
    },
    row() {
      return this.params.row || {};
    },
    sku() {
      return (this.row.oSKU) || (this.row.sku) || (this.row) || {};
    },
    productId() {
      return this.sku.iProductId || this.sku.productId;
    },
    bizProductId() {
      return this.sku.bizProductId;
    },
    skuCode() {
      return this.sku.code || this.sku.cCode || this.sku.skuCode || this.sku.cSkuCode || '';
    },
    specDescription() {
      return this.sku.specDescription || this.sku.cSpecDescription || '';
    }
  },
  methods: {
    switchEditState() {
      if (!this.isCanModify) return; // 用于控制可否编辑
      this.isEdit = !this.isEdit;
    },
    onModifySku() {
      this.$refs.selectProductSku.setProductId(this.productId);
      this.$refs.selectProductSku.setBizProductId(this.bizProductId);
      this.$refs.selectProductSku.showRefModal(this.specDescription);
    },
    onSelectedProductSku(sku) {
      if (this.sku.id === sku.id) {
        return;
      }
      // this.row.oSKU = sku;
      // this.row.sku = sku;
      // this.row.iSKUId = sku.id;
      // this.row.cSkuCode = sku.cCode || sku.code || '';
      // this.row.cSpecDescription = sku.specDescription || this.sku.cSpecDescription || '';
      this.$emit('on-modify-sku', sku);
    }
  }

};
</script>
<style lang="less" scoped>
  .ivu-table .sku-No{
    color: #999999;
    height: 17px;
    font-size: 12px;
    line-height: 17px;
  }
  .sku-spec-description {
    border: 1px dashed transparent;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    &:hover{
      border: 1px dashed;
      .sku-btn{
        display: inline-block;
      }
      .sku-attribute{
        margin-left: 5px;
      }
    }
    .sku-attribute {
      height: 20px;
      line-height: 20px;
    }
    .sku-btn {
      min-width: 30px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      display: none;
    }
  }
</style>
