import ajax from '@/libs/ajax';

export const getCustomerRefund = (data) => {
  return ajax.request({
    url: '/voucher/refund/queryschema',
    data: {
      data: data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
export const getCustomerRefundDetail = (id) => {
  return ajax.request({
    url: `/voucher/refund/detail/${id}`,
    data: {
      isArchive: 1
    },
    method: 'get'
  });
};
