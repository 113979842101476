const order = (menu) => {
  let code = menu.code;
  let sysCode = menu.sysCode;
  if (code === 'orderManager') {
    menu.sysCode = 'order';
  }
  if (code === 'deliveryOrderList') {
    menu.sysCode = 'deliverys';
    menu.bVue = true;
  }
  if (code === 'orderList') {
    menu.sysCode = 'orders';
    menu.bVue = true;
  }
  if (code === 'presaleOrders') {
    menu.sysCode = 'presaleOrders';
    menu.bVue = true;
  }
  if (code === 'returnedPurchaseList') {
    menu.sysCode = 'salereturns';
    menu.bVue = true;
  }
  if (code === 'expenseOrderList') {
    menu.sysCode = 'expenses';
    menu.bVue = true;
  }
  menu.name = sysCode || code;
};
const history = (menu) => {
  let code = menu.code;
  if (code === 'ORDERHISTORY') {
    menu.oriName = 'orders';
    menu.sysCode = 'ordersHistory';
    menu.bVue = true;
  }
  if (code === 'PAYMENTHISTORY') {
    menu.oriName = 'payments';
    menu.sysCode = 'paymentsHistory';
    menu.bVue = true;
  }
  if (code === 'SALERETURNHISTORY') {
    menu.oriName = 'salereturns';
    menu.sysCode = 'salereturnsHistory';
    menu.bVue = true;
  }
  if (code === 'REFUNDHISTORY') {
    menu.oriName = 'refunds';
    menu.sysCode = 'refundsHistory';
    menu.bVue = true;
  }
  if (code === 'SHIPPINGHISTORY') {
    menu.oriName = 'deliverys';
    menu.sysCode = 'deliverysHistory';
    menu.bVue = true;
  }
  if (code === 'DISCOUNTREBATEHISTORY') {
    menu.oriName = 'discountRebates';
    menu.sysCode = 'discountRebatesHistory';
    menu.bVue = true;
  }
  if (code === 'GOODREBATEHISTORY') {
    menu.oriName = 'goodsRebates';
    menu.sysCode = 'goodsRebatesHistory';
    menu.bVue = true;
  }

  menu.name = menu.sysCode || menu.code;
};
const customer = (v) => {
  let { code } = v;
  if (code === 'customerManager') {
    v.sysCode = 'customer';
  }
  if (code === 'corpAgentGroup') {
    v.bVue = true;
    v.sysCode = 'customerGroups';
  }
  if (code === 'agentLevel') {
    v.bVue = true;
    v.sysCode = 'customerLevels';
  }
  if (code === 'agentIndustry') {
    v.bVue = true;
    v.sysCode = 'industrySettings';
  }
  if (code === 'potentialAgent') {
    v.bVue = true;
    v.sysCode = 'potentialAgents';
  }
  if (code === 'agentList') {
    v.bVue = true;
    v.sysCode = 'customerList';
  }
  if (code === 'agentCategroy') { // 客户分类
    v.bVue = true;
    v.sysCode = 'customerCategorys';
  }
  if (code === 'agentArea') {
    v.bVue = true;
    v.sysCode = 'customerAreas';
  }
  if (code === 'showStatistics') { // 往来对账
    v.bVue = true;
    v.sysCode = 'showStatistics';
  }
  if (code === 'fundAccountList') { // 资金账户
    v.bVue = true;
    v.sysCode = 'customerFundAccount';
  }
  v.name = v.sysCode || v.code;
};
const platform = (v) => {
  let { code } = v;
  if (code === 'platformsupervision') {
    v.sysCode = 'platform';
  }
  if (code === 'merchantretrungoods') {
    v.sysCode = 'merchantsSaleReturns';
    v.bVue = true;
  }
  if (code === 'merchantorder') {
    v.sysCode = 'merchantsOrders';
    v.bVue = true;
  }
  if (code === 'supplierlist') {
    v.bVue = true;
    v.sysCode = 'merchants';
  }
  if (code === 'merchantsDeliverys') {
    v.sysCode = 'merchantsDeliverys';
    v.bVue = true;
  }
  if (code === 'merchantsPromotionAds') {
    v.bVue = true;
  }
  if (code === 'merchantsNoticeSettings') {
    v.bVue = true;
  }
  v.name = v.sysCode || v.code;
};
const channelcenter = (v) => {
  let { code } = v;
  if (code === 'channelcenter') {
    v.sysCode = 'channelcenter';
  }
  if (code === 'channelagent') {
    v.bVue = true;
    v.sysCode = 'channelagents';
  }
  if (code === 'channeltransaction') {
    v.bVue = true;
    v.sysCode = 'channelTransactions';
  }
  if (code === 'channelship') {
    v.bVue = true;
    v.sysCode = 'channelship';
  }
  v.name = v.sysCode || v.code;
};
const product = (v) => {
  let { code } = v;
  if (code === 'pruductManager') {
    v.sysCode = 'products';
  }
  if (code === 'brand') {
    v.bVue = true;
    v.sysCode = 'brands';
  }
  if (code === 'productline') {
    v.bVue = true;
    v.sysCode = 'productlines';
  }
  v.name = v.sysCode || v.code;
};
const organization = (v) => {
  let { code } = v;
  if (code === 'orgs') {
    v.sysCode = 'organization';
  }
  if (code === 'organization') {
    v.sysCode = 'orgs';
    v.bVue = true;
  }
  if (code === 'departments') {
    v.sysCode = 'departments';
    v.bVue = true;
  }
  if (code === 'salesship') {
    v.sysCode = 'salesRelationships';
    v.bVue = true;
  }
  v.name = v.sysCode || v.code;
};
const settings = (v) => {
  let { code } = v;
  if (code === 'settingManager') {
    v.sysCode = 'settings';
  }
  if (code === 'rolesetting') {
    v.sysCode = 'roles';
    v.bVue = true;
  }
  if (code === 'noticeClass') {
    v.sysCode = 'noticeClasses';
    v.bVue = true;
  }
  if (code === 'userSetting') {
    v.bVue = true;
    v.sysCode = 'users';
  }
  if (code === 'noticeSetting') {
    v.sysCode = 'noticeSettings';
    v.bVue = true;
  }
  if (code === 'adPictrueSetting') {
    v.bVue = true;
    v.sysCode = 'adpicturesettings';
  }
  if (code === 'appPromotionAds') {
    v.bVue = true;
    v.sysCode = 'promotionAds';
  }
  if (code === 'mobileFlashConfig') {
    v.bVue = true;
    v.sysCode = 'mobileFlashConfig';
  }
  if (code === 'contactersetting') {
    v.bVue = true;
    v.sysCode = 'contacters';
  }
  if (code === 'printTemplate') {
    v.bVue = true;
    v.sysCode = 'printSetting';
  }
  if (code === 'einvoice') {
    v.bVue = true;
    v.sysCode = 'invoiceSetting';
  }
  if (code === 'businessOption') {
    v.sysCode = 'functionOptions';
    v.bVue = true;
  }
  if (code === 'offlinePaySettings') {
    v.bVue = true;
    v.sysCode = 'offlinePaySettings';
  }
  if (code === 'onlinePaySetting') {
    v.bVue = true;
    v.sysCode = 'onlinePaySetting';
  }
  if (code === 'helpCenterSetting') {
    v.bVue = true;
    v.sysCode = 'helpCenterSetting';
  }
  // 公司基本信息
  if (code === 'industrySetting') {
    v.sysCode = 'enterpriseInfoSetting';
    v.bVue = true;
  }
  // ERP集成
  if (code === 'corpERPIntegration') {
    v.sysCode = 'corpERPIntegration';
    v.bVue = true;
  }
  // 接口密钥
  if (code === 'interfaceKey') {
    v.sysCode = 'interfaceKey';
    v.bVue = true;
  }
  // 订购信息
  if (code === 'orderInfo') {
    v.sysCode = 'orderInfo';
    v.bVue = true;
  }
  // 注册邀请
  if (code === 'registeredInvite') {
    v.sysCode = 'registeredInvite';
    v.bVue = true;
  }
  // 主题风格
  if (code === 'themeSetting') {
    v.sysCode = 'themeSetting';
    v.bVue = true;
  }
  // 客服信息
  if (code === 'serviceInformation') {
    v.sysCode = 'serviceInformation';
    v.bVue = true;
  }
  v.name = v.sysCode || v.code;
};
const settle = (v) => {
  let { code } = v;
  if (code === 'settlementcenter') {
    v.sysCode = 'settle';
  }
  if (code === 'receivablesDetail') {
    v.sysCode = 'payments';
    v.bVue = true;
  }
  if (code === 'repaymng') {
    v.sysCode = 'refunds';
    v.bVue = true;
  }
  if (code === 'shareRule') {
    v.sysCode = 'shareRules';
    v.bVue = true;
  }
  if (code === 'RefundRuleList') {
    v.sysCode = 'refundRules';
    v.bVue = true;
  }
  if (code === 'withdrawalmng') {
    v.sysCode = 'withdrawalmngs';
    v.bVue = true;
  }
  v.name = v.sysCode || v.code;
};
const extension = (v) => {
  let { code } = v;
  if (code === 'integrationextension') {
    v.sysCode = 'integrationextension';
  }
  if (code === 'receivablesDetail') {
    v.sysCode = 'payments';
    v.bVue = true;
  }
  if (code === 'operatorlog') {
    v.sysCode = 'systemLog';
    v.bVue = true;
  }
  if (code === 'defines') {
    v.sysCode = 'defines';
    v.bVue = true;
  }
  if (code === 'apiSetting') {
    v.sysCode = 'extApis';
    v.bVue = true;
  }
  if (code === 'msgQueueLogs') {
    v.bVue = true;
  }
  if (code === 'integrationlog') {
    v.sysCode = 'errorLogs';
    v.bVue = true;
  }
  if (code === 'clearOrder') {
    v.sysCode = 'clearOrder';
    v.bVue = true;
  }
  v.name = v.sysCode || v.code;
};
const basebill = (v) => {
  let { code } = v;
  if (code === 'basebill') {
    v.sysCode = 'basebill';
  }
  if (code === 'deliveryMethod') {
    v.bVue = true;
    v.sysCode = 'shippingChoices';
  }
  if (code === 'expenseOrderTypeList') {
    v.bVue = true;
    v.sysCode = 'expenseOrderTypes';
  }
  if (code === 'logisticsCompany') {
    v.bVue = true;
    v.sysCode = 'logisticsCompanys';
  }
  if (code === 'settlementMethod') {
    v.bVue = true;
    v.sysCode = 'settlementMethod';
  }
  if (code === 'transportExpensesTemplate') {
    v.bVue = true;
    v.sysCode = 'freightTemplates';
  }

  v.name = v.sysCode || v.code;
};

const auth = (v) => {
  let { code } = v;
  if (code === 'dataAuthSwitch') {
    v.sysCode = 'permissionSwitch';
    v.bVue = true;
  }
  if (code === 'authorityAssignment1') {
    v.bVue = true;
    v.sysCode = 'customerAuth';
  }
  if (code === 'authorityAssignment2') {
    v.bVue = true;
    v.sysCode = 'userFunctionAuth';
  }
  if (['productGroups', 'userCustomerAuth', 'userProductAuth', 'userStockAuth', 'userCustomerGroupAuth'].includes(code)) {
    v.bVue = true;
  }

  v.name = v.sysCode || v.code;
};

const stockcenter = (v) => {
  let { code } = v;
  if (code === 'stockcenter') {
    v.sysCode = 'stockcenter';
  }
  if (code === 'stockSetting') {
    v.bVue = true;
    v.sysCode = 'stocks';
  }
  if (code === 'inventoryShow') { // 库存量管理
    v.bVue = true;
    v.sysCode = 'stockInventorys';
  }
  if (code === 'showStoctTipSetting') { // 库存提示设置
    v.bVue = true;
    v.sysCode = 'showStockTipSetting';
  }
  v.name = v.sysCode || v.code;
};

const pricecenter = (v) => {
  let { code } = v;
  if (code === 'priceManager') {
    v.sysCode = 'priceCenter';
  }
  if (code === 'agnPriceShow') {
    v.bVue = true;
    v.sysCode = 'agnPriceShow';// 客户价格
  }
  if (code === 'productPriceShow') {
    v.bVue = true;
    v.sysCode = 'productPriceShow';// 商品价格
  }
  if (code === 'customerDiscProdCates') {
    v.bVue = true;
    v.sysCode = 'customerDiscProdCates';// 客户折扣(商品分类)
  }
  if (code === 'customerDiscProds') {
    v.bVue = true;
    v.sysCode = 'customerDiscProds';// 客户折扣(商品)
  }
  if (code === 'pricePlates') {
    v.bVue = true;
    v.sysCode = 'pricePlates';
  }
  if (code === 'menuSetting') {
    v.bVue = true;
    v.sysCode = 'menuSetting'; // 菜单管理
  }
  if (code === 'agentPriceSettingShow') {
    v.bVue = true;
    v.sysCode = 'agentPriceSettingShowes'; // 客户价格设置
  }
  if (code === 'productPriceSettingShow') {
    v.bVue = true;
    v.sysCode = 'productPriceSettingShows'; // 商品价格设置
  }
  if (code === 'mobileUiDecorate') {
    v.bVue = true;
    v.sysCode = 'decorates';
  }
  v.name = v.sysCode || v.code;
};

const rebateCenter = (v) => {
  let { code } = v;
  if (code === 'rebateCenter') {
    v.sysCode = 'rebateCenter';
  }
  if (code === 'rebateMoney') { // 金额费用
    v.bVue = true;
    v.sysCode = 'discountRebates';
  }
  if (code === 'rebateProduct') {
    v.bVue = true;
    v.sysCode = 'goodsRebates'; // 商品费用
  }
  if (code === 'refundRules') {
    v.bVue = true;
    v.sysCode = 'refundRules'; // 费用使用规则
  }
};
// 促销中心
const promotionZTE = (v) => {
  let { code } = v;
  if (code === 'promotionManager') {
    v.sysCode = 'promotionZTE';
  }
  if (code === 'combinationSales') {
    v.bVue = true;
    v.sysCode = 'combinationSales'; // 组合销售列表
  }
  if (code === 'productSalesListShow') {
    v.bVue = true;
    v.sysCode = 'productSalesListShow'; // 商品促销列表
  }
  if (code === 'singleDiscountListShow') {
    v.bVue = true;
    v.sysCode = 'singleDiscountListShow'; // 整单优惠列表
  }
  if (code === 'accessorieSetting') {
    v.bVue = true;
    v.sysCode = 'accessorieSetting';
  }
  if (code === 'productLimit') {
    v.bVue = true;
    v.sysCode = 'productLimit'; // 商品限购列表
  }

  if (code === 'productPresales') {
    v.bVue = true;
    v.sysCode = 'productPresales';
  }
  if (code === 'productGroupBuy') {
    v.bVue = true;
    v.sysCode = 'productGroupBuys';
  }
  v.name = v.sysCode || v.code;
};
const productNotice = (v) => {
  let { code } = v;
  if (code === 'productNotices') {
    v.bVue = true;
    v.sysCode = 'productNotices';
  }
  if (code === 'bankConfig') {
    v.bVue = true;
    v.sysCode = 'bankConfig';
  }
  if (code === 'metaConfig') {
    v.bVue = true;
    v.sysCode = 'billItemConfig';
  }

  v.name = v.sysCode || v.code;
};
// 租户
const tenantManagement = v => {
  let { code } = v;
  if (code === 'corpManagementList') {
    v.bVue = true;
    v.sysCode = 'managementList';
  }
  v.name = v.sysCode || v.code;
};
// 操作记录
const operator = v => {
  let { code } = v;
  if (code === 'corpOperatior') {
    v.bVue = true;
    v.sysCode = 'operator';
  }
  v.name = v.sysCode || v.code;
};

const objectCredit = (v) => {
  let { code } = v;
  if (code === 'temporaryCredits') {
    v.bVue = true;
    v.sysCode = 'temporaryCredits';
  }
  if (code === 'agentCreditsArchives') {
    v.bVue = true;
    v.sysCode = 'agentCreditsArchives';
  }
  if (code === 'creditRules') {
    v.bVue = true;
    v.sysCode = 'creditRules';
  }
  if (code === 'objectCredits') {
    v.bVue = true;
  }
  if (code === 'creditConsumption') {
    v.bVue = true;
  }
  if (code === 'creditCalculation') {
    v.bVue = true;
  }
  v.name = v.sysCode || v.code;
};

const menuRegister = {
  order,
  customer,
  tenantManagement,
  operator,
  platform,
  channelcenter,
  organization,
  product,
  settings,
  settle,
  extension,
  basebill,
  auth,
  stockcenter,
  pricecenter,
  rebateCenter,
  promotionZTE,
  productNotice,
  objectCredit,
  history
};

export default menuRegister;
