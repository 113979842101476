<template>
  <span
    class="sort-button-wrapper"
    @click="handleSort">
    <i
      :class="[currentActiveColSort && currentSortType === 'up' ? 'sort-button-item-up-active' : '']"
      data-sort-btn="up"
      class="sort-button-item sort-button-item-up"></i>
    <i
      :class="[currentActiveColSort && currentSortType === 'down' ? 'sort-button-item-down-active' : '']"
      data-sort-btn="down"
      class="sort-button-item sort-button-item-down"></i>
  </span>
</template>
<script>
export default {
  name: 'SortButton',
  props: {
    colIndex: Number,
    currentSortColIndex: Number,
    currentSortType: String
  },
  data() {
    return {
      sortingType: ''
    };
  },
  computed: {
    currentActiveColSort() {
      return this.colIndex === this.currentSortColIndex;
    }
  },
  watch: {
    currentSortType(type) {
      if (this.currentSortColIndex === this.colIndex) this.sortingType = type;
    }
  },
  methods: {
    handleSort(e) {
      const sortType = e.target.getAttribute('data-sort-btn');
      if (this.sortingType === sortType) {
        this.sortingType = '';
        this.$emit('on-cancel-sort');
      } else {
        this.sortingType = sortType;
        this.$emit('on-sort', this.colIndex, sortType);
      }
    }
  }
};
</script>
