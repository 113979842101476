export default {
  topContent1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A3","1、最多可添加5个一级分类") /* "1、最多可添加5个一级分类" */,
  topContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A4","2、每个分类最多支持两级") /* "2、每个分类最多支持两级" */,
  hotTelephone: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A5","热线电话") /* "热线电话" */,
  pleaseWriteHotTelephone: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A6","请输入热线电话") /* "请输入热线电话" */,
  weixinCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A7","微信二维码") /* "微信二维码" */,
  clickUpload: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A8","点击上传") /* "点击上传" */,
  uploadTipMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A9","仅支持jpg、jpeg、png、bmp格式，文件小于500KB(尺寸120*120px)") /* "仅支持jpg、jpeg、png、bmp格式，文件小于500KB(尺寸120*120px)" */,
  contentCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002AA","描述二维码") /* "描述二维码" */,
  pleaseWriteContentCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002AB","请输入二维码描述") /* "请输入二维码描述" */,
  helpCenterTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002AC","帮助中心") /* "帮助中心" */,
  mingcheng: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002AD","名称") /* "名称" */,
  sortNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002AE","排序号") /* "排序号" */,
  neirong: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002AF","内容") /* "内容" */,
  fujian: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B0","附件") /* "附件" */,
  shili: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B1","示例") /* "示例" */,
  pleaseWriteMenu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B2","请输入菜单名称") /* "请输入菜单名称" */,
  addSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B3","添加成功") /* "添加成功" */,
  pleaseSelectDelNode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B4","请选择要删除的节点") /* "请选择要删除的节点" */,
  operateSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B3","添加成功") /* "添加成功" */,
  workTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B5","工作时间") /* "工作时间" */,
  topContent3: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B6","最多添加5个二级分类") /* "最多添加5个二级分类" */
};
