<template>
  <Modal
    v-model="modal"
    :mask-closable="false"
    :width="500"
    :title="meta.editModalTitle"
    class-name="form-modal return-form-modal">
    <FormMeta
      ref="form-modal"
      :meta="meta"
      @on-form-change="formChange"></FormMeta>
    <div slot="footer">
      <Button
        type="text"
        @click="cancelEdit">
        {{ mlang('cancel') }}
      </Button>
      <Button
        :loading="modalLoading"
        type="primary"
        @click="saveEdit">
        {{ mlang('save') }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import FormMeta from '@/components/common/archive-list/form-meta';
export default {
  name: 'FormModal',
  components: {
    FormMeta
  },
  mixins: [],
  props: {
    meta: {
      required: true,
      type: Object
    },
    formModalFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false,
      modalLoading: false
    };
  },
  watch: {
    formModalFlag: {
      handler(val, oldVal) {
        this.modal = val;
      },
      immediate: true
    },
    modal: {
      handler(val, oldVal) {
        if (val !== this.formModalFlag) {
          this.$emit('on-cancel');
        }
      },
      immediate: true
    }
  },
  methods: {
    formChange(newVal, oldVal) {
      this.$emit('on-form-change', newVal, oldVal);
    },
    saveEdit() {
      this.$emit('on-save-click');
    },
    setLoading(loading) {
      this.modalLoading = loading;
    },
    cancelEdit() {
      this.modal = false;
      this.$emit('on-cancel');
      this.modalLoading = false;
    },
    visibleChange(visible) {
      if (this.modal !== visible) {
        this.modal = visible;
        const emitKey = visible ? 'on-modal-show' : 'on-modal-hide';
        this.$emit(emitKey);
      }
    }
  }
};
</script>
