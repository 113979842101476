import ajax from '@/libs/ajax';

// 创建新的工单
export const createWorkOrder = () => {
  return ajax
    .request({
      url: '/thirdparty/workOrder/common/create',
      method: 'get',
      data: {
        isArchive: 1
      }
    });
};

// 查看我的工单
export const queryWorkOrders = () => {
  return ajax
    .request({
      url: '/thirdparty/workOrder/common/query',
      method: 'get',
      data: {
        isArchive: 1
      }
    });
};
