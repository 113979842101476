<template>
  <div class="zone-home-wrapper">
    <div class="top-main">
      <div class="left-btns-wrapper">
        <div class="order-btns" :style="orderBtnsImgStyle">
          <div v-for="(item, index) in orderBtnList" :key="index" class="order-btn" @click="openNewPage(item)">
            <img class="order-btn-icon" :src="require(`@/assets/images/zoneOrderBtn${index + 1}.png`)" />
            <div class="text-name">
              {{ item.name }}
            </div>
            <Icon type="ios-arrow-forward" />
          </div>
        </div>
        <div class="other-btns">
          <div v-for="(item, index) in otherBtnList" :key="index" class="other-btn" :style="otherBtnsImgStyle(index + 1)" @click="openNewPage(item)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="center-carousel-wrapper">
        <!-- 轮播 -->
        <Carouse />
      </div>
      <div class="right-order-info-wrapper">
        <TradingData :style="orderSummaryImgStyle" />
        <NoticeList :style="noticeImgStyle" />
      </div>
    </div>
    <div class="bottom-img-list">
      <div
        v-for="(item, index) in promotionalImgList"
        :key="index"
        class="promotional-img"
        :style="{ width: promotionalImgWidth + 'px' }">
        <template v-if="item.compType === 1">
          <img class="picture" :src="item.url" @error="handleError" />
        </template>
        <template v-else-if="item.type === 2">
          <video
            ref="videoPlayer"
            class="video"
            :src="item.url"
            :width="promotionalImgWidth"
            height="244"
            muted
            poster
            @play="onPlay"
            @pause="onPause"
            @input="handlePlayBarDrag" />
          <div v-if="!isPlaying" class="player-btn" @click="playVideo">
            <div class="icon-wrapper">
              <img src="../../../../assets/images/play.png" alt="">
            </div>
          </div>
        </template>
        <template v-else>
          <div class="carouse">
            <Carousel v-model="carouselIndex" loop>
              <CarouselItem v-for="carouseItem in item.list" :key="carouseItem.id">
                <img :src="carouseItem.url">
              </CarouselItem>
            </Carousel>
          </div>
        </template>
        <!-- (!isPlaying && item.type === 'video') || item.type !== 'video' -->
        <div v-if="(!isPlaying && item.compType === 2) || item.compType !== 2" ref="operationPromptRef" class="operation-prompt">
          <div class="promotional-name item-name">
            {{ item.name }}
          </div>
          <div class="click-prompt item-name" @click="openNewPage(item)">
            <span>了解更多</span>
            <Icon type="ios-arrow-forward" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carouse from './carouse.vue'; // 轮播
import TradingData from './tradingData.vue';
import NoticeList from './noticeList.vue';
import orderSummaryImg from '@/assets/images/orderSummary.png';
import noticeImg from '@/assets/images/notice.png';
import orderBtnsImg from '@/assets/images/orderBtns.png';
import otherBtnsImg1 from '@/assets/images/zoneOtherBtn1.png';
import otherBtnsImg2 from '@/assets/images/zoneOtherBtn2.png';
import otherBtnsImg3 from '@/assets/images/zoneOtherBtn3.png';
import otherBtnsImg4 from '@/assets/images/zoneOtherBtn4.png';
import DefaultImg from '@/assets/images/ProductDefaultImg.png';
import { getZoneHomeData } from '@/api/agent-home/agent-home.js';

const imgs = [
  {
    imgUrl: 'https://img1.baidu.com/it/u=1765424967,137821940&fm=253&fmt=auto&app=120&f=JPEG?w=1422&h=800',
    url: 'https://www.baidu.com/',
    name: '活动名称1',
    id: '111',
    type: 'picture'
  },
  {
    imgUrl: 'https://v1.kwaicdn.com/ksc2/tWUjnI252W2_KvAyeqtzL9idGmf0x5jkkvSSoA_BUB2Labmko5TcPfYtDX1p_1gj2srY_-rgSTSwfHj-TOSmfT_LGLKPdn9gM-hsvR_012lX56nOj5XHxsSio7TfxS5geB38iUO6_ZXTwjViIfRbxf8-U5mVCOkwJOqxeX7hzkKXSw69p6B4EALPfCbRgC6g.mp4?pkey=AAV3UyiGjE3x04XnkzJaVdgidMo4pyiehGwrJRPx60r1TZQFXkwS4p5WVq9jyz0y066IqyXvPlB7HICNWANeDwgwET5J_8d16yhI5MgiSqcOFaCLSxaeMmos_JZhVWu3R3I&tag=1-1709713503-unknown-0-xol3rgudnm-752913aa1b6d3827&clientCacheKey=3x8fz8capcdzvq2_hd15.mp4&di=7d2305f7&bp=14944&tt=hd15&ss=vp',
    url: 'https://music.163.com/',
    name: '活动名称2',
    id: '222',
    type: 'video'
  },
  {
    imgUrl: 'https://img0.baidu.com/it/u=4138232271,2230352829&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
    url: 'https://github.com/',
    imgList: [
      {
        imgUrl: 'https://img1.baidu.com/it/u=4049906368,438977941&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500',
        url: 'www.baidu.com',
        name: '活动名称1',
        id: '111',
        type: 'picture'
      },
      {
        imgUrl: 'https://img0.baidu.com/it/u=4120070778,1326534427&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500',
        url: 'www.baidu.com',
        name: '活动名称2',
        id: '222',
        type: 'picture'
      },
      {
        imgUrl: 'https://img2.baidu.com/it/u=835739443,3981852722&fm=253&fmt=auto&app=138&f=JPEG?w=400&h=225',
        url: 'www.baidu.com',
        name: '活动名称3',
        id: '333',
        type: 'picture'
      }
    ],
    name: '活动名称3',
    id: '333',
    type: 'carouse'
  }
];

export default {
  name: 'Home',
  components: {
    Carouse,
    TradingData,
    NoticeList
  },
  data() {
    return {
      orderSummaryImg,
      noticeImg,
      orderBtnsImg,
      otherBtnsImg: otherBtnsImg1,
      promotionalImgList: imgs,
      carouselIndex: 0,
      isPlaying: false,
      isPlayBarDrag: false,
      orderBtnList: [],
      otherBtnList: []
    };
  },
  computed: {
    orderSummaryImgStyle() {
      return {
        'background-image': `url(${this.orderSummaryImg})`
      };
    },
    noticeImgStyle() {
      return {
        'background-image': `url(${this.noticeImg})`
      };
    },
    orderBtnsImgStyle() {
      return {
        'background-image': `url(${this.orderBtnsImg})`
      };
    },
    promotionalImgWidth() {
      if (this.promotionalImgList.length === 1) {
        return 1200;
      } else if (this.promotionalImgList.length === 2) {
        return 590;
      } else if (this.promotionalImgList.length === 3) {
        return 386;
      } else {
        return 0;
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getZoneHomeData().then((res) => {
        const orderBtnList = res.find(item => item.componentType === 1);
        const otherBtnList = res.find(item => item.componentType === 2);
        const promotionalList = res.find(item => item.componentType === 3);
        this.promotionalImgList = promotionalList?.list || [];
        this.otherBtnList = otherBtnList?.list.map(item => {
          return {
            ...item,
            targetUrl: item.url
          };
        }) || [];
        this.orderBtnList = orderBtnList?.list.map(item => {
          return {
            ...item,
            targetUrl: item.url
          };
        }) || [];
      });
    },
    openNewPage(item) {
      window.open(item.targetUrl, '_blank');
    },
    onPlay() {
      this.isPlaying = true;
      this.$refs.videoPlayer[0].setAttribute('controls', 'true');
      this.isPlayBarDrag = false;
    },
    onPause() {
      // 拖拽或点击底部视频进度条继续播放视频
      if (this.isPlayBarDrag) {
        this.$refs.videoPlayer[0].play();
        return;
      }
      this.isPlaying = false;
      this.$refs.videoPlayer[0].removeAttribute('controls');
    },
    playVideo() {
      this.$refs.videoPlayer[0].play();
      this.isPlayBarDrag = false;
    },
    handlePlayBarDrag() {
      this.isPlayBarDrag = true;
    },
    otherBtnsImgStyle(index) {
      let targetObj = null;
      switch (index) {
        case 1:
          targetObj = { 'background-image': `url(${otherBtnsImg1})` };
          break;
        case 2:
          targetObj = { 'background-image': `url(${otherBtnsImg2})` };
          break;
        case 3:
          targetObj = { 'background-image': `url(${otherBtnsImg3})` };
          break;
        case 4:
          targetObj = { 'background-image': `url(${otherBtnsImg4})` };
          break;
      }
      return targetObj;
    },
    // 图片加在异常
    handleError(e) {
      e.target.src = DefaultImg;
    }
  }
};
</script>

<style lang="less" scoped>
.zone-home-wrapper {
  display: flex;
  flex-direction: column;

  .top-main {
    height: 330px;
    width: 1200px;
    margin-bottom: 20px;
    display: flex;

    .left-btns-wrapper {
      width: 224px;
      height: 100%;

      .order-btns {
        width: 224px;
        height: 186px;
        margin-bottom: 8px;
        padding: 10px 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .order-btn {
          width: 208px;
          height: 48px;
          border-radius: 6px;
          padding-left: 18px;
          padding-right: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-image: linear-gradient(-62deg, #FFEDD1 0%, #FCF8EE 78%);

            .ivu-icon-ios-arrow-forward {
              font-weight: 700;
              color: #333;
            }
          }

          .text-name {
            height: 20px;
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            flex: 1;
            margin-left: 16px;
          }

          .ivu-icon-ios-arrow-forward {
            font-weight: 700;
            color: #959595;
          }
        }
      }

      .other-btns {
        width: 224px;
        height: 136px;
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 8px;

        .other-btn {
          width: 100px;
          height: 56px;
          border-radius: 4px;
          padding-top: 14px;
          padding-left: 12px;
          font-weight: 600;
          font-size: 14px;
          color: #9E7638;
          line-height: 18px;
          border: 1px solid #fff;
          cursor: pointer;

          &:hover {
            border: 1px solid #D3BD9B;
          }
        }
      }
    }

    .center-carousel-wrapper {
      margin: 21px 20px 0;
      flex: 1;

      .agent-content-banner {
        width: 712px;
        height: 310px;
        border-radius: 4px;
        background-color: #d6d6d6;
        overflow: hidden;
        position: relative;
      }

      img {
        border-radius: 4px;
      }
    }

    .right-order-info-wrapper {
      width: 224px;
      height: 100%;

      .trading-data-wrapper {
        height: 141px;
        margin-bottom: 8px;
        padding: 0 12px;
        border-radius: 4px;

        .agent-num {
          display: flex !important;
          .left {
            width: 112px;
          }
        }
      }

      .agent-bottom {
        width: 224px;
        height: 181px;
        border-radius: 4px;
        padding: 8px 12px;
      }
    }
  }

  .bottom-img-list {
    width: 1200px;
    height: 244px;
    display: flex;
    justify-content: space-between;

    .promotional-img {
      height: 100%;
      border-radius: 4px;
      position: relative;

      &:hover {
        .operation-prompt {
          display: flex;
        }
      }

      .operation-prompt {
        display: none;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        background: #0000005c;
        position: absolute;
        bottom: 0;
        padding: 10px 30px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

        .item-name {
          display: flex;
          align-items: center;
          height: 20px;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
        }

        .click-prompt {
          cursor: pointer;
        }
      }

      .picture {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }

      .video {
        border-radius: 4px;
        height: 100%;
        object-fit: cover;
      }

      .player-btn {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-wrapper {
          width: 30px;
          height: 30px;
          background: #FFFFFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 35%;
            height: 35%;
            margin-left: 3px;
          }
        }
      }

      .carouse {
        .ivu-carousel {
          height: 244px;
        }
        .ivu-carousel-list {
          height: 244px;

          .ivu-carousel-item {
            height: 244px !important;
            border-radius: 4px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

</style>
