export default {
  ruleName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A2","规则名称") /* "规则名称" */,
  pleaseWrite: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A3","请输入") /* "请输入" */,
  openStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A4","启用状态") /* "启用状态" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A5","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A6","停用") /* "停用" */,
  fentanguize: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A7","分摊规则") /* "分摊规则" */,
  moneyApportion: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A8","按金额比例加权平均分摊") /* "按金额比例加权平均分摊" */,
  apportionRange: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A9","分摊商品范围") /* "分摊商品范围" */,
  onlyActivityProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000AA","仅活动商品") /* "仅活动商品" */,
  minDiscount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000AB","分摊商品最低折扣率") /* "分摊商品最低折扣率" */,
  doControl: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000AC","控制") /* "控制" */,
  notControl: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000AD","不控制") /* "不控制" */,
  validateMinDiscount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000AE","请输入正确的折扣") /* "请输入正确的折扣" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000AF","操作成功") /* "操作成功" */,
  changeStateWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000B0","不能关闭全部规则") /* "不能关闭全部规则" */,
  delRuleContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000B1","确定删除该规则吗？") /* "确定删除该规则吗？" */,
  delRuleSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000B2","删除成功!") /* "删除成功!" */,

  changeRuleStateContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000B3","确定要开启这个规则吗？") /* "确定要开启这个规则吗？" */,
  batchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000B4","请选择至少一条状态为停用的规则") /* "请选择至少一条状态为停用的规则" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000B5","请选择规则") /* "请选择规则" */,
  deleteRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000B6","删除") /* "删除" */
};
