const agentMid = {
  path: 'home',
  name: 'home',
  meta: {
    menuCode: 'agentIndex' // 新页面之间跳转，跳转页面时选中对应菜单，值从config.js里找需要选中的菜单的code
  },
  component: () => import('@/views/agent/home/home.vue')
};

export default agentMid;
