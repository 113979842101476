<template>
  <div class="proportion-table-cell">
    <div class="proportion-quantity">
      {{ proportion.iSendQuantity + '/' + proportion.iQuantity +'('+proportion.cProductUnitName+')' }}
    </div>
  </div>
</template>
<script>
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'ProportionTableCell',
  mixins: [CellMixin],
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {};
  },
  computed: {
    proportion() {
      if (this.params.row) {
        return this.params.row;
      }
      return {};
    }
  }
};
</script>
<style scoped>
.proportion-table-cell {
  color: #333333;
}
</style>
