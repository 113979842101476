const mixin = {
  data() {
    return {
      popTipProps: {
        modifiers: {
          computeStyle: {
            gpuAcceleration: false
          },
          preventOverflow: {
            boundariesElement: 'viewport'
          }
        }
      }
    };
  }
};

export default mixin;
