import { noticeName } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const noticeNameRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'title',
      refName: 'title',
      // 传递过去的参数
      passedparams: [
      ],
      queryschema: [
        {
          name: 'keyword',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('name'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'title'
        },
        {
          showCaption: vm.mlang('startDateTime'),
          isShowIt: true,
          fieldName: 'createdDate'
        },
        {
          showCaption: vm.mlang('endDateTime'),
          isShowIt: true,
          fieldName: 'endDate'
        }
      ],
      actions: { queryList: noticeName },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default noticeNameRefer;
