import authSwitch from './authSwitch';
import productGroups from './productGroups';
import userCustomerAuth from './userCustomerAuth';
export default {
  clearAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000094","清空权限") /* "清空权限" */,
  addHasAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000095","自动有权") /* "自动有权" */,
  authLoadTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000096","权限状态正在更新，请稍后") /* "权限状态正在更新，请稍后" */,
  pleaseSelectExportObj: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000097","请选择授权对象") /* "请选择授权对象" */,
  pleaseUpdate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000098","此功能已更新，请升级租户") /* "此功能已更新，请升级租户" */,
  onlyShowHaveAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000099","仅显示有权") /* "仅显示有权" */,
  authTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500009A","权限") /* "权限" */,
  hasAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500009B","有权") /* "有权" */,
  noAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500009C","无权") /* "无权" */,
  pleaseEnterNameOrCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500009D","请输入名称/编码") /* "请输入名称/编码" */,
  brandClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500009E","品牌") /* "品牌" */,
  dealAll: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500009F","批量操作") /* "批量操作" */,
  saveSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A0","保存成功") /* "保存成功" */,
  selectSKU: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000A1","选择SKU") /* "选择SKU" */,
  authSwitch,
  productGroups,
  userCustomerAuth
};
