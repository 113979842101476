export default {
  batchEnableWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001AD","请选择至少一条状态为停用的客户信息") /* "请选择至少一条状态为停用的客户信息" */,
  batchDisableWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001AE","请选择至少一条状态为启用的客户信息") /* "请选择至少一条状态为启用的客户信息" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001AF","是否要对选中的") /* "是否要对选中的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B0","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B1","操作成功") /* "操作成功" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B2","请选择客户") /* "请选择客户" */,
  merchantAgentImportWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B3","原有已认证客户的公共信息不会通过导入修改!") /* "原有已认证客户的公共信息不会通过导入修改!" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B4","条") /* "条" */
};
