import orderList from '@/views/agent/components/order/components/orders.vue';
import corpParentView from '@/views/agent/components/order/orderListTab.vue';

const order = {
  path: 'aorders',
  title: { i18n: 'orders' },
  name: 'Aorders',
  component: corpParentView,
  children: [
    {
      path: 'expenses',
      title: { i18n: 'title.settlementMethodList' },
      name: 'Expenses',
      component: () => import('@/views/agent/components/order/components/expenses.vue'),
      meta: { menuCode: 'agentOrderShow' }
    },
    {
      path: 'orders',
      title: { i18n: 'title.settlementMethodList' },
      name: 'Orders',
      component: orderList,
      meta: { menuCode: 'agentOrderShow' }
    },
    {
      path: 'deliverys',
      title: { i18n: 'title.settlementMethodList' },
      name: 'Delivery',
      component: () => import('@/views/agent/components/order/components/deliverys.vue'),
      meta: { menuCode: 'agentOrderShow' }
    },
    {
      path: 'returnBills',
      title: { i18n: 'title.settlementMethodList' },
      name: 'ReturnBill',
      component: () => import('@/views/agent/components/order/components/returnBills.vue'),
      meta: { menuCode: 'agentOrderShow' }
    },
    {
      path: 'orderSign',
      title: { i18n: 'title.settlementMethodList' },
      name: 'OrderSign',
      component: () => import('@/views/corp/order/components/signIn/signInRecords.vue'),
      meta: { menuCode: 'agentOrderShow' }
    },
    {
      path: 'signIn',
      name: 'signIn',
      meta: { module: 'signIn', notCache: true },
      component: () => import('@/views/corp/order/components/signIn/signIn.vue')
    }
  ]
};

export default order;
