<template>
  <CheckboxGroup
    v-model="model"

    @on-change="groupChange">
    <template v-for="item in meta.enumArray">
      <Checkbox
        :disabled="meta.disabled"
        :label="item.value">
        {{ item.title }}
      </Checkbox>
    </template>
  </CheckboxGroup>
</template>

<script>
import _ from 'lodash';
import { deepCopy } from '@/u-components/utils/assist';
export default {
  name: 'CheckGroup',
  props: {
    value: {
      type: Array,
      default: []
    },
    meta: {
      type: Object
    }
  },
  data() {
    return {
      model: [],
      data2Field: {},
      field2Data: {},
      oriValue: []
    };
  },
  computed: {
    dataKey() {
      return this.meta['refRetId'];
    }
  },
  watch: {
    value() {
      this.setValue();
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // this.setRefMap();
      this.setValue();
    },
    setValue() {
      let value = [];
      let oriValue = [];
      let key = this.dataKey;
      this.value.map((item) => {
        if (item.actionStatus !== 'D') {
          value.push(item[key]);
          oriValue.push(item[key]);
        }
      });
      this.model = value;
      this.oriValue = oriValue;
    },
    setRefMap() {
      this.meta.refRetId = '{"orgFunc":"value"}';
      try {
        var obj = JSON.parse(this.meta.refRetId);
        for (var billKey in obj) {
          var referKey = obj[billKey];
          this.data2Field[billKey] = referKey;
          this.field2Data[referKey] = billKey;
        }
      } catch (error) {
        console.error(error); //eslint-disable-line
      }
    },
    groupChange() {
      const xor = _.xor(this.oriValue, this.model);
      const addArr = [];
      const oldValue = deepCopy(this.value);
      if (xor.length) {
        xor.map((item) => {
          const add = this.oriValue.indexOf(item) === -1;
          const del = this.model.indexOf(item) === -1;
          if (add) {
            const obj = {
              actionStatus: 'C'
            };
            obj[this.dataKey] = item;
            addArr.push(obj);
          }
          if (del) {
            const delItem = oldValue.find((v) => {
              return v[this.dataKey] === item;
            });
            delItem.actionStatus = 'D';
          }
        });
        let emitValue = [...oldValue, ...addArr];
        emitValue = emitValue.filter((v) => {
          let flag = true;
          if (v.id) {
            flag = true;
          } else if (v.actionStatus === 'D') {
            flag = false;
          }

          return flag;
        });
        this.$emit('change', this.meta.fieldName, emitValue);
      }
    }
  }
};
</script>
