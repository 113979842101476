import ajax from '@/libs/ajax';
export const getImportStatus = (actionStr) => {
  return ajax.request({
    url: '/pc/base/getImportStatus',
    method: 'get',
    data: {
      actionStr
    }
  });
};

// 新导入导出查询状态
export const getImportExportStatus = (jobName) => {
  return ajax.request({
    url: `/udinghuo/batch/status/excelexecution/${jobName}`,
    method: 'post',
    data: {
      datatype: 'json',
      isExport: 1
    }
  });
};

export const getImportExportStatusYS = (asyncKey, token, domainKey = 'udinghuo') => {
  return ajax.request({
    url: '/mdf-node/uniform/billtemp/getImportProcess',
    method: 'get',
    data: {
      asyncKey,
      token,
      isURLToken: true,
      domainKey,
      isArchive: 1,
      isU8C: 1
    }
  });
};
export const getExportStatusYS = (asyncKey, domainKey = 'udinghuo') => {
  return ajax.request({
    url: '/mdf-node/uniform/billtemp/getExportProcess',
    method: 'get',
    data: {
      asyncKey,
      domainKey,
      isArchive: 1,
      isU8C: 1
    }
  });
};

// 新导出
export const exportExcel = (data, enumName) => {
  return ajax.request({
    url: `/udinghuo/batch/export/${enumName}`,
    method: 'post',
    data: {
      datatype: 'json',
      conditions: data.querySchema.conditions,
      isExport: 1
    }
  });
};

export const getImportTmpl = (enumName) => {
  return ajax.request({
    url: `/udinghuo/batch/template/${enumName}`,
    method: 'get',
    data: {
      datatype: 'json',
      isExport: 1
    }
  });
};
// 用于测试
export const getImportTmplYS = () => {
  return ajax.request({
    url: '/mdf-node/files/billtemp/export',
    method: 'get',
    responseType: 'blob',
    data: {
      nodeal: true,
      datatype: 'json',
      isExport: 1
    }
  });
};

export const getSystemToken = () => {
  return ajax.request({
    url: '/passport/yht/systemtoken?noCache=true',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
