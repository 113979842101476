<template>
  <Poptip
    :options="popTipProps"
    :trigger="trigger"
    placement="bottom"
    popper-class="poptip"
    @on-popper-show="onPopShow"
    @on-popper-hide="onPopHide">
    <div
      class="order-status-msg">
      <Icon
        custom="iconfont icon-wuliuxinxi"></Icon>
      {{ mlang('logisticInfo') }}
      <Icon type="arrow-down-b"></Icon>
    </div>
    <div
      slot="content"
      class="order-status-poptip-content logistics infor">
      <div
      v-if="!datas.length && isLoading"
        class="ivu-spin ivu-spin-large ivu-spin-fix">
        <div class="ivu-spin-main">
          <span class="ivu-spin-dot"></span> <div class="ivu-spin-text"></div>
        </div>
      </div>
      <div
        v-if="!datas.length && !isLoading"
        class="informatio no-data">
        <p>
          {{ mlang('noLogistic') }}
        </p>
      </div>
      <ul v-if="datas.length">
        <li  v-for="(data,index) in datas">
          <h2>{{ title }}：{{ data.cDeliveryNo }}</h2>
          <div
            class="informatio">
            <p v-if="(data.oCorpLogistics && data.oCorpLogistics.cFullName) || data.cLogisticsBillNo">
              {{ (data.oCorpLogistics && data.oCorpLogistics.cFullName) || '' }} {{ data.oCorpLogistics && data.oCorpLogistics.cFullName && data.cLogisticsBillNo && ':' }}{{ data.cLogisticsBillNo }}
            </p>
            <p
              v-if="data.cLogisticsUserName || data.cLogisticsUserPhone || data.cLogisticsCarNum"
              style="margin-bottom:20px">
              <span
                v-if="data.cLogisticsUserName"
                style="float:left;margin-right:5px;">
                {{ data.cLogisticsUserName }}
              </span>
              <span
                v-if="data.cLogisticsUserPhone"
                style="float:left">
                {{ data.cLogisticsUserPhone }}
              </span>
              <span
                v-if="data.cLogisticsCarNum"
                style="float:right">
                {{ data.cLogisticsCarNum }}
              </span>
            </p>
            <div v-if="data.logisticsMsg">
              <template v-for="(detail,index2) in data.logisticsMsg">
                <template v-if="index2 < 1 || data.showAll">
                  <p
                    :key="detail.id"
                    :class="{'current':index2 ===0 }">
                    {{ detail.context }}
                  </p>
                  <p
                    :key="index2"
                    class="time">
                    {{ detail.time }}
                  </p>
                </template>
              </template>
              <p
                v-if="data.logisticsMsg && data.logisticsMsg.length >1"
                class="see-All"
                @click="showAllItem(data)">
                {{ data.showAll === true ? mlang('shouqi'):mlang('showALl') }}
              </p>
            </div>
            <div v-else-if="data.oCorpLogistics && data.oCorpLogistics.cFullName && data.oCorpLogistics.cFullName.indexOf('顺丰') > -1">
              <p>
                <a
                  :href="`http://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/${data.cLogisticsBillNo}`"
                  class="primary-color"
                  target="_blank">由于顺丰接口限制，请点此链接去顺丰官网查询</a>
              </p>
            </div>
            <no-data v-else-if="!data.oCorpLogistics">
              {{ mlang('noLogistic') }}
            </no-data>
          </div>
          <i class="round"><b></b></i>
        </li>
      </ul>
    </div>
  </poptip>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
export default {
  name: 'OrderLogistic',
  mixins: [PopTipMixin],
  props: {
    datas: {
      type: Array,
      required: true
    },
    hasFetch: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      trigger: 'hover'
    };
  },
  methods: {
    showAllItem(item) {
      this.trigger = 'click';
      this.$set(item, 'showAll', !item.showAll);
    },
    onPopShow() {
      this.$emit('on-popper-show');
    },
    onPopHide() {
      this.trigger = 'hover';
    }
  }
};
</script>
