import util from '@/libs/util.js';
import _ from 'lodash';
import { deepCopy, oneOf } from '@/u-components/utils/assist';
import { getSaleReturnList, getSaleReturnListBtn, approveSaleReturn, opposeSaleReturn, saleReturnConfirmBack, confirmSaleReturn, deblockSaleReturn } from '@/api/salereturn';
const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {},

  methods: {
    mixin_approveSaleReturn(params, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('approveSaleReturnContentA') + params.row.cSaleReturnNo + vm.mlang('approveSaleReturnContentB'),
        onOk: () => {
          approveSaleReturn(params.row.cSaleReturnNo).then((data) => {
            vm.$Message.success(vm.mlang('approveSaleReturnSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_confirmDialog(params, vm) {
      vm.$Modal.prompt({
        content: vm.mlang('opposeMsg'),
        okText: vm.mlang('oppose'),
        cancelText: vm.mlang('cancel'),
        title: vm.mlang('opposeTitle'),
        tip: vm.mlang('returnOpposeMsg'),
        onOk: (res, instance) => {
          const postData = {
            cSaleReturnNo: params.row.cSaleReturnNo,
            opposeMemo: res
          };
          if (!res) {
            vm.$Message.error(vm.mlang('confirmDialogError'));
            return false;
          }
          opposeSaleReturn(postData).then((data) => {
            vm.$Message.success(vm.mlang('confirmDialogSuccess'));
            vm.dispatch('VoucherList', 'reload');
            instance.remove();
          });
        }
      });
    },
    mixin_saleReturnConfirmBack(params, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('saleReturnConfirmBackContentA') + params.row.cSaleReturnNo + vm.mlang('saleReturnConfirmBackContentB'),
        onOk: () => {
          saleReturnConfirmBack(params.row.cSaleReturnNo).then((data) => {
            vm.$Message.success(vm.mlang('saleReturnConfirmBackSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_confirmSaleReturn(params, vm) {
      if (vm.currentUser.__isU8C) {
        vm.$Modal.confirm({
          content: vm.mlang('returnConfirmTip'),
          onOk: () => {
            const postData = {
              cSaleReturnNo: params.row.cSaleReturnNo
            };
            confirmSaleReturn(postData).then((data) => {
              vm.$Message.success(vm.mlang('confirmSaleReturnSuccess'));
              vm.dispatch('VoucherList', 'reload');
            });
          }
        });
      } else {
        vm.$Modal.confirm({
          content: vm.mlang('confirmSaleReturnContent'),
          onOk: () => {
            vm.$Modal.confirm({
              content: vm.mlang('returnConfirmTip'),
              onOk: () => {
                const postData = {
                  cSaleReturnNo: params.row.cSaleReturnNo
                };
                confirmSaleReturn(postData).then((data) => {
                  vm.$Message.success(vm.mlang('confirmSaleReturnSuccess'));
                  vm.dispatch('VoucherList', 'reload');
                });
              }
            });
          }
        });
      }
    },
    mixin_batchRefund: (checkedArr, vm) => {
      let total = 0;
      let agent;
      let bizId;
      let salesOrgId;
      let saleNos = [];
      _.forEach(checkedArr, function(saleReturn, index) {
        let iAmount = saleReturn.fPayMoney - saleReturn.fRebateToCashMoney - saleReturn.fRefundAmount;
        let saleNo = saleReturn.cSaleReturnNo;
        agent = saleReturn.agent;
        bizId = saleReturn.bizId;
        salesOrgId = saleReturn.salesOrgId;
        saleNos.push(saleNo);
        total = util.numAdd(total, iAmount);
      });
      let saleReturn = {
        iAmount: total,
        agent,
        bizId,
        salesOrgId,
        len: checkedArr.length,
        cSaleReturnNo: _.join(saleNos, ',')
      };
      var iAmount = saleReturn.iAmount || saleReturn.fPayMoney - saleReturn.fRebateToCashMoney - saleReturn.fRefundAmount;

      vm.$Modal.confirm({
        content: vm.mlang('batchRefundContentA') + checkedArr.length + vm.mlang('batchRefundContentB'),
        onOk: () => {
          let url = `/corppage/Refunds/edit?menuid=repaymng&iAgentId=${saleReturn.agent}&iBizId=${saleReturn.bizId}&salesOrgId=${saleReturn.salesOrgId}&iAmount=${iAmount}&fromSaleReturns=true&saleReturnNos=${saleReturn.cSaleReturnNo}&isBatch=${saleReturn.len > 1 ? 1 : 0}`;
          vm.$util.menuClickOldPage(url, vm);
        }
      });
    },
    // 退货单、商家退货管理获取列表数据
    mixin_saleReturnQueryList(query) {
      let vm = this;
      if (this.dealOldYear(query) === false) return;
      query.datatype = 'json';
      query.querySchema.conditions.push({
        conditionType: 'eq',
        isDefine: false,
        isDetail: undefined,
        name: 'isOtherFranchiser',
        v1: this.mixin_isPlatForm(),
        valueType: 'BOOLEAN'
      });
      query.querySchema.conditions.forEach((v, index) => {
        if (v.name === 'createdDate') {
          v.valueType = 'LocalDateTime';
        }
      });
      let postData = this.isHistory ? { datatype: 'json', isReport: 1, data: query.querySchema } : { datatype: 'json', isArchive: 1, data: query.querySchema };
      getSaleReturnList(postData, this.isHistory).then((res) => {
        if (!this.isHistory) { this.afterListSearch(query, 'createDate'); }
        res.data = res.content;
        res.totalCount = res.count;
        const ids = [];
        const menuid = this.mixin_isPlatForm() ? 'merchantretrungoods' : 'returnedPurchaseList';
        res.content.map((v) => {
          v.cSaleReturnNoHref = `/corppage/SaleReturns/showSalereturnDetail?menuid=${menuid}&cSaleReturnNo=${v.cSaleReturnNo}`;
          if (vm.isHistory) {
            v.cSaleReturnNoHref += `&showOldYear=true&id=${v.id}`;
          }
          if (vm.isHistory) {
            let to = {};
            to = {
              name: 'saleReturnHistory',
              query: { id: v.id, showOldYear: true, cSaleReturnNo: v.cSaleReturnNo, titleType: 'detail' }
            };
            v.cSaleReturnNoTo = to;

            // v.cSaleReturnNoHref += `&showOldYear=true&id=${v.id}`;
          }
          v.cSaleReturnNoTitle = this.mlang('saleReturnDetail');
          ids.push(v.id);
        });
        this.data = deepCopy(res);
        if (res && res.totalElements && !this.isHistory) {
          getSaleReturnListBtn(ids).then((btnRes) => {
            res.content.map((v, index) => {
              const id = v.id;
              const btn = btnRes.find((item) => {
                return item.id === id;
              });
              v = Object.assign(v, btn);
            });
            this.data = deepCopy(res);
          });
        }
      });
    },
    // 退货单不同页面显示不同列
    mixin_saleReturnSetListColumns(columns) {
      columns.map((v) => {
        if (this.mixin_routers() === 'platform') {
          if (oneOf(v.fieldName, ['action'])) {
            v.isHidden = true;
          }
        }
      });
      return columns;
    },
    // 解锁
    mixin_saleReturndDeblockOrder(params, vm) {
      let cSaleReturnNo = params.cSaleReturnNo;
      vm.$Modal.confirm({
        title: '',
        content: this.mlang('salereturnsDeblockTip'),
        onOk: () => {
          deblockSaleReturn(cSaleReturnNo).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    }
  }
};

export default mixin;
