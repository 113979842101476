import { deepCopy } from '@/u-components/utils/assist';

const srcBillTreeRefer = (ctrl, vm) => {
  let _user = vm.$store.getters.currentUser;
  const columns = [
    {
      showCaption: vm.mlang('orders.salesQuotationName'),
      isShowIt: true,
      fieldName: 'name',
      width: 220
    },
    {
      showCaption: vm.mlang('orders.salesQuotationCode'),
      fieldName: 'code',
      width: 220,
      isShowIt: true
    },
    {
      key: 'nowidth',
      title: ''
    }
  ];
  const control = Object.assign(
    {},
    {
      key: 'salesQuotationRef', // 只有为这个才会显示特殊商品参照
      noMetaSetting: true,
      referType: 'Table',
      // 传递过去的参数
      passedparams: [
        {
          name: 'agentId',
          controlType: 'String',
          valueType: 'STRING',
          groupType: 2,
          conditionType: 'eq',
          v1: _user.agentId
        }
      ],
      showTitle: true,
      closeable: true,
      page: true,
      bRefer: true,
      controlType: 'Table',
      refReturnValue: 'id',
      refName: 'productName',
      columns,
      actions: { queryTree: '' }
    },
    ctrl
  );
  control.key = 'salesQuotationRef';
  if (!vm.$getBO('ORDERSHOWWAY')) {
    control.passedparams.push({
      name: 'saleOrgId',
      controlType: 'String',
      groupType: 2,
      valueType: 'STRING',
      conditionType: 'eq',
      v1: vm.orgId
    });
  }
  return deepCopy(control);
};
export default srcBillTreeRefer;
