export default {
  cancel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500069B","取消") /* "取消" */,
  sure: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500069C","确定") /* "确定" */,
  copyTemplateModalTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500069D","复制模板") /* "复制模板" */,
  newCodeLbl: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500069E","模板编码") /* "模板编码" */,
  newNameLbl: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500069F","模板名称") /* "模板名称" */,
  newCodePlaceholder: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A0","请输入模板编码") /* "请输入模板编码" */,
  newNamePlaceholder: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A1","请输入模板名称") /* "请输入模板名称" */,
  nonEmptyErrorMsg: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A2","不能为空") /* "不能为空" */,
  errorMsgTips: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A3","请进行正确填写！") /* "请进行正确填写！" */
};
