import { deliveryStockList } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const ReceiveAgreementNameRefer = (ctrl, vm, params) => {
  const control = Object.assign(
    {},
    {
      page: false,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refRetId: 'iStockId', // 拿refReturnValue的value值以iStockId:value形式传递给后端
      refReturnValue: 'id', // 参照回传的value值
      refName: 'cStockName', // 回传到输入框的值
      hasTableIndex: true, // 自己增加的属性判断src/components/common/refer/refer-table.vue文件中该queryList方法是否传入tableIndex参数
      columns: [
        {
          showCaption: vm.mlang('warehouseName'),
          fieldName: 'cStockName',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('stockOrgName'),
          fieldName: 'stockOrgName',
          width: 180,
          isShowIt: true
        }
      ],
      actions: { queryList: (params) => deliveryStockList(params, vm.data) },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default ReceiveAgreementNameRefer;
