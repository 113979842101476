<template>
  <ViewPicker
    v-bind="{...attrs,...props}"
    :value="formatValue"
    :format="format"
    v-on="listeners"
    @on-change="handleChange"></ViewPicker>
</template>

<script>
import dayjs from 'dayjs';
import { DatePicker as ViewPicker } from 'iview';

export default {
  name: 'UdhPicker',
  components: { ViewPicker },
  props: {
    value: {
      type: [Date, String, Array]
    },
    type: {
      type: String
    },
    autoTime: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // const isRange = this.type.includes('range');
    // const emptyArray = isRange ? [null, null] : [null];
    // const initialValue = isEmptyArray((isRange ? this.value : [this.value]) || []) ? emptyArray : this.parseDate(this.value);
    return {
      formatValue: null,
      prefixCls: 123
    };
  },
  computed: {
    isRange() {
      return this.type.includes('range');
    },
    format() {
      let format = 'yyyy-MM-dd';
      switch (this.type) {
        case 'date':
        case 'daterange':
          format = this.$util.sysDateFormat();
          break;
        case 'datetime':
        case 'datetimerange':
          format = this.$util.sysDateTimeFormat();
          break;
        default:
          break;
      }
      return format;
    },
    attrs() {
      return this.$attrs;
    },
    props() {
      return this.$props;
    },
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners['on-change'];
      return listeners;
    }
  },
  watch: {
    value: {
      handler(value, oldVal) {
        let formatValue = value;
        let start = '';
        let end = '';
        if (value) {
          switch (this.type) {
            case 'date':
              formatValue = this.$util.formatToCtxDate(formatValue);
              break;
            case 'datetime':
              formatValue = this.$util.formatToCtxDateTime(formatValue);
              break;
            case 'daterange':
              if (value[0]) {
                start = this.$util.formatToCtxDate(value[0]);
              }
              if (value[1]) {
                end = this.$util.formatToCtxDate(value[1]);
              }
              formatValue = [start, end];
              break;
            case 'datetimerange':
              if (value[0]) {
                start = this.$util.formatToCtxDateTime(value[0]);
              }
              if (value[1]) {
                end = this.$util.formatToCtxDateTime(value[1]);
              }
              formatValue = [start, end];
              break;
            default:
              break;
          }
        }
        this.formatValue = formatValue;
      },
      immediate: true,
      deep: true
    }

  },
  methods: {
    handleChange(value, type) {
      let v = value;
      let start, end;
      switch (this.type) {
        case 'date':
          v = this.$util.formatToUtc8Date(value);
          break;
        case 'datetimerange':
          // predicate-datepicker自动补全后面的时分秒23：59：59
          if (this.autoTime && type === 'date') {
            value[1] = dayjs(value[1], this.$util.dayJsDateTimeFormat())
              .subtract(1, 'seconds')
              .add(1, 'days')
              .format(this.$util.dayJsDateTimeFormat());
          }
          if (value[0]) {
            start = this.$util.formatToUtc8DateTime(value[0]);
          }
          if (value[1]) {
            let temp = dayjs(value[1]).format(this.$util.dayJsDateTimeFormat());
            let zeroFormat = '00:00:00';
            if (temp.indexOf(zeroFormat) > -1) {
              let tempTimer = temp.replace('00:00:00', '23:59:59');
              value[1] = tempTimer;
            }
            end = this.$util.formatToUtc8DateTime(value[1]);
          }
          v = [start, end];
          this.formatValue = v;
          break;
        case 'daterange':
          if (value[0]) {
            start = this.$util.formatToUtc8Date(value[0]);
          }
          if (value[1]) {
            end = this.$util.formatToUtc8Date(value[1]);
          }
          v = [start, end];
          break;
        default:
          break;
      }
      this.$emit('on-change', v, type);
    }

  }
};
</script>
