export default {
  nothasTotalAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DEB","无统计信息查看权限") /* "无统计信息查看权限" */,
  iKnow: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DEC","我知道了") /* "我知道了" */,
  sysNotice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DED","【系统公告】") /* "【系统公告】" */,
  dingfahuotongji: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DEE","订发货统计") /* "订发货统计" */,
  orderAmountThousand: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DEF","订货金额(千元)") /* "订货金额(千元)" */,
  statisticsLegend1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF0","订货金额(万元)") /* "订货金额(万元)" */,
  statisticsLegend2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF1","订货量(笔)") /* "订货量(笔)" */,
  diliveryAmountThousand: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF2","发货金额(千元)") /* "发货金额(千元)" */,
  statisticsLegend3: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF3","发货金额(万元)") /* "发货金额(万元)" */,
  statisticsLegend4: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF4","发货量(笔)") /* "发货量(笔)" */,
  AmountThousand: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF5","订/发货金额(千元)") /* "订/发货金额(千元)" */,
  statisticsyAxis1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF6","订/发货金额(万元)") /* "订/发货金额(万元)" */,
  statisticsyAxis2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF7","订/发货量(笔)") /* "订/发货量(笔)" */,
  statisticsyseries1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF0","订货金额(万元)") /* "订货金额(万元)" */,
  statisticsyseries2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF1","订货量(笔)") /* "订货量(笔)" */,
  sevenDay: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF8","7天") /* "7天" */,
  sanshitian: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DF9","30天") /* "30天" */,
  theYear: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DFA","本年") /* "本年" */,
  shouldSureOrder: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DFB","待确认订单") /* "待确认订单" */,
  shouldSureBackOrder: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DFC","待确认退货单") /* "待确认退货单" */,
  shouldSureGetOrder: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DFD","待确认收款单") /* "待确认收款单" */,
  productOrderTop: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DFE","商品订货排行") /* "商品订货排行" */,
  getPaysByAgentTOP: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DFF","客户收款排行") /* "客户收款排行" */,
  getOrdersByAgentTOP: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E00","客户订货排行") /* "客户订货排行" */,
  getDeliveryByAgentTOP: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E01","客户发货排行") /* "客户发货排行" */,
  preMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E02","上一月") /* "上一月" */,
  nextMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E03","下一月") /* "下一月" */,
  echartFormatName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E04","名称：") /* "名称：" */,
  echartFormatCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E05","金额：") /* "金额：" */,
  echartFormatPr: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E06","占比：") /* "占比：" */,
  paihang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E07","排行") /* "排行" */,
  dataDetail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E08","数据详情") /* "数据详情" */,
  yewugailan: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E09","业务概览") /* "业务概览" */,
  dinghuojine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E0A","订货金额（元）") /* "订货金额（元）" */,
  shoukuanliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E0B","收款量（笔）") /* "收款量（笔）" */,
  shoukuanjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E0C","收款金额（元）") /* "收款金额（元）" */,
  fahuojine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E0D","发货金额（元）") /* "发货金额（元）" */,
  tuikuanliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E0E","退款量（笔）") /* "退款量（笔）" */,
  tuikuanjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E0F","退款金额（元）") /* "退款金额（元）" */,
  tuihuoliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E10","退货量（笔）") /* "退货量（笔）" */,
  tuihuojine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E11","退货金额（元）") /* "退货金额（元）" */,
  feiyongliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E12","费用量（笔）") /* "费用量（笔）" */,
  feiyongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E13","费用金额（元）") /* "费用金额（元）" */

};
