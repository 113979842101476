import ajax from '@/libs/ajax';
import { asyncFuncMemoize } from '@/libs/util.js';

export const getBillMeta = (billId, orgId = window.localStorage.getItem('upOrgId')) => {
  const ORDERSHOWWAY = window.__app.mainV.$getBO('ORDERSHOWWAY');// false 为单商家模式
  let url = ORDERSHOWWAY ? `/meta/bill/${billId}/current` : `/meta/bill/${billId}/current?orgId=${orgId}`;
  return ajax.request({
    url,
    method: 'get',
    data: { isArchive: 1 }
  });
};

const getVoucherBillMeta = (billId, tplId = 'current', isMemoizeBill, orgId = window.__app.mainV.$route.query.salesOrgId) => {
  const ORDERSHOWWAY = window.__app.mainV.$getBO('ORDERSHOWWAY');
  let url = (orgId && !ORDERSHOWWAY) ? `/meta/bill/${billId}/${tplId}/bill?orgId=${orgId}` : `/meta/bill/${billId}/${tplId}/bill`;
  return ajax.request({
    url,
    method: 'get',
    data: { isArchive: 1 }
  });
  // .then(res => {
  //   if (billId === 198) {
  //     return require('@/components/common/voucher/198bill.json').data;
  //   }
  //   return res;
  // });
};
const getNotokenVoucherBillMeta = (billId, tplId = 'current', isMemoizeBill, orgId = window.__app.mainV.$route.query.salesOrgId) => {
  const ORDERSHOWWAY = window.__app.mainV.$getBO('ORDERSHOWWAY');
  let url = (orgId && !ORDERSHOWWAY) ? `/meta/bill/noToken/${billId}/${tplId}/bill?orgId=${orgId}` : `/meta/bill/noToken/${billId}/${tplId}/bill`;
  return ajax.request({
    url,
    method: 'get',
    data: {
      isArchive: 1,
      yhtTenantId: window.__app.mainV.$route.query.alias || ''
    }
  });
};

// params(billId, tplId, enableCache)
export const getVoucherBill = asyncFuncMemoize(getVoucherBillMeta, (billId, tplId) => `${billId}_${tplId}`);
export const getNotokenVoucherBill = asyncFuncMemoize(getNotokenVoucherBillMeta, (billId, tplId) => `${billId}_${tplId}`);

const resetSort = (columns) => {
  columns.map((v, k) => {
    if (v.name === 'action') {
      v['sort'] = 9999;
    } else {
      v['sort'] = k + 1;
    }
  });
};
export const saveBillItems = (billId, columns) => {
  resetSort(columns);
  return ajax.request({
    url: `/meta/bill/${billId}/tenant/default/billitem/batch`,
    method: 'post',
    data: {
      datatype: 'json',
      data: columns,
      isArchive: 1
    }
  });
};
export const saveUserBillMetaSave = (billId, columns) => {
  let ret = [];
  var user = window.__app.mainV.$store.getters.currentUser;
  columns.map((v, k) => {
    ret.push({
      billId: v.billId,
      groupId: v.groupId,
      fieldName: v.fieldName,
      showIt: v.showIt,
      userId: user.userId
    });
  });
  return ajax.request({
    url: '/marketingbill/bill/billSetting/userBillMetaSave',
    method: 'post',
    data: {
      datatype: 'json',
      data: ret,
      isMarket: 1
    }
  });
};
export const userBillMetaGet = (billId, orgId) => {
  return ajax.request({
    url: `/meta/bill/billSetting/userBillMetaGet/${billId}/${orgId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const saveFiltersItems = (billId, columns) => {
  resetSort(columns);
  return ajax.request({
    url: `/meta/bill/${billId}/tenant/default/filteritem/batch`,
    method: 'post',
    data: {
      datatype: 'json',
      data: columns,
      isArchive: 1
    }
  });
};

export const getPrintTemplatesByCode = (modelCode, params = {}) => {
  return ajax.request({
    url: `/voucher/print/template/${modelCode}`,
    method: 'get',
    data: { ...params, isArchive: 1 }
  });
};

// 获取U8C的默认模板列表
export const getU8CPrintTemplatesByCode = (classifyCode, orgId) => {
  let url = `/voucher/print/u8c/template/${classifyCode}`;
  if (orgId) url += `?orgId=${orgId}`;
  return ajax.request({
    url,
    method: 'get',
    data: { isArchive: 1 }
  });
};
// 获取U8C预览地址
export const getU8CPreviewUrl = (classifyCode, printCode, data) => {
  return ajax.request({
    url: `/voucher/print/u8c/builder/preview/${classifyCode}/${printCode}`,
    method: 'post',
    data: { data, datatype: 'json', isArchive: 1 }
  });
};

export const getSystemPrintTemplates = () => {
  return ajax.request({
    url: '/voucher/print/all/model/data',
    method: 'get',
    data: { isArchive: 1 }
  });
};

export const delTemplate = (code) => {
  return ajax.request({
    url: `/voucher/print/template/${code}`,
    method: 'delete',
    data: { isArchive: 1 }
  });
};

export const addTemplateGroup = (printType) => {
  return ajax.request({
    url: `/voucher/print/model/add/${printType}`,
    method: 'post',
    data: { isArchive: 1, data: 2 }
  });
};

export const setDefaultTemplate = (data) => {
  return ajax.request({
    url: '/voucher/print/template/default',
    method: 'put',
    data: {
      data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

export const copyTemplate = (subTemplate) => {
  let { tplPk, newCode, newName } = subTemplate;
  return ajax.request({
    url: '/voucher/print/template/copy',
    method: 'post',
    data: {
      data: {
        templatecode: newCode,
        templatename: newName,
        pk_print_template: tplPk
      },
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getPrintSubTemplates = (subTemplate) => {
  return ajax.request({
    url: `/voucher/print/template/${subTemplate}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 获取未分配的商家信息
// export const getUnallocatedBusiness = (tplPk, querySchema) => {
//   return ajax.request({
//     url: `/voucher/print/relation/merchant/${tplPk}`,
//     // url: '/voucher/print/relation/merchant/a88ccd6b-a4d5-4104-acab-81f9eec3b245',
//     method: 'post',
//     data: {
//       datatype: 'json',
//       data: querySchema,
//       isArchive: 1
//     }
//   });
// };
// 获取已分配的商家信息
export const getAllocatedBusiness = (tplPk) => {
  return ajax.request({
    url: `/voucher/print/relation/merchant/${tplPk}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 获取外部拓展js配置
export const getExtendJsConfig = () => {
  return ajax.request({
    url: '/archives/ext/extapiregister/query/clinentype',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const postDefineExtendJs = (params) => {
  let url = '';
  let postData = {};
  if (params.isLocal) {
    if (params.type && params.type.toLowerCase() === 'get') {
      postData = params.data;
    } else {
      postData = Object.assign(params.data, { datatype: 'json' });
    }
    return ajax.request({
      url: params.url,
      method: params.type && params.type.toLowerCase(),
      data: postData
    });
  } else {
    url = '/defineExtendJS';
    postData = { data: params, datatype: 'json' };
    return ajax.request({
      url: url,
      method: 'post',
      data: postData
    });
  }
};

// 删除分配商家
export const deleteAllocationBusiness = (data) => {
  return ajax.request({
    url: '/voucher/print/relation/remove',
    method: 'patch',
    data: {
      datatype: 'json',
      isArchive: 1,
      data: data
    }
  });
};
// 添加分配商家
export const addAllocationBusiness = (data) => {
  return ajax.request({
    url: '/voucher/print/relation/allocation',
    method: 'post',
    data: {
      isArchive: 1,
      data: data,
      datatype: 'json'
    }
  });
};

// 发货单增加打印次数
export const addPrintCount = (data, printType) => {
  return ajax.request({
    url: `/voucher/print/record?printType=${printType}`,
    method: 'post',
    data: {
      isArchive: 1,
      data,
      datatype: 'json'
    }
  });
};

// 外部post数据
export const postOutData = (url, data) => {
  return ajax.request({
    url,
    method: 'post',
    data
  });
};

export const createTemplate = ({ boCode }) => {
  let url = `/voucher/print/create/bankdesigner/${boCode}`;
  return ajax.request({
    url,
    method: 'post',
    data: {
      isArchive: 1
    }
  });
};
export const switchLanguage = (name) => {
  let res = ajax.request({
    url: '/yht/switchLocale',
    method: 'post',
    data: {
      data: {
        locale: name
      },
      datatype: 'json',
      isMarket: 1
    }
  });
  return res;
};
