export default {
  pleaseSelectProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC2","选择商品") /* "选择商品" */,
  shangpin: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC3","商品") /* "商品" */,
  inventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC4","现存量") /* "现存量" */,
  usedInventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC5","订单占用量") /* "订单占用量" */,
  notUsedInventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC6","可用库存量") /* "可用库存量" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC7","请正确填写!") /* "请正确填写!" */,
  productProperty: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC8","商品属性") /* "商品属性" */,
  setZero: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800042","清零") /* "清零" */,
  pleaseSelectOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC9","请先选择组织!") /* "请先选择组织!" */,
  pleaseAddStockInventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DCA","至少添加一条库存") /* "至少添加一条库存" */
};
