import publicPath from './public-path';
import util from './libs/util'; // 多语方法预制在这里，所以级别提高
import 'xe-utils';
// eslint-disable-next-line no-unused-vars
import XEUtils from 'xe-utils/methods/xe-utils';
import 'iview/dist/styles/iview.css'; // 这个一定放到第一个, 优先级最低,便于覆盖
import Vue from 'vue';
import iView from 'iview';
import uCompotents from './u-components/';
import VXETable from '@/u-components/components/vxe-table/packages/vxe-table';
import '@/u-components/components/vxe-table/styles/index.scss';
import { router } from './router/index';
import { appRouter } from './router/router';
import store from './store';
import App from './app.vue';
import $config from '@/config';
import i18n from '@/locale';
import '_assets/styles/fonts/iconfont.css';
import '_assets/styles/common.less';
import './theme/red.less';
import dayjs from 'dayjs';
import importDirective from '@/directive';
import { mapGetters } from 'vuex';
import * as filters from './filters'; // global filters
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { postDefineExtendJs } from '@/api/meta';
import infiniteScroll from 'vue-infinite-scroll';

dayjs.extend(customParseFormat);

Vue.config.devtools = process.env.NODE_ENV === 'development';

// register global utility filters.
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.prototype.$util = util;
Vue.prototype.$config = $config;

Vue.use(iView, {
  i18n: (key, value) => i18n.t(key, value),
  transfer: true
});
Vue.use(uCompotents, {
  i18n: (key, value) => i18n.t(key, value),
  locale: i18n.locale
});
Vue.use(VXETable, {
  i18n: (key, value) => i18n.t(key, value),
  locale: i18n.locale
});
Vue.use(infiniteScroll);
importDirective(Vue);

// mockData();
const mainV = new Vue({
  el: '#app',
  router: router,
  i18n,
  store,
  data: {
    currentPageName: ''
  },
  provide() {
    return {
      setThemeLink: this.setThemeLink,
      getTheme: this.getTheme,
      broadcastToExtend: this.broadcastToExtend
    };
  },
  computed: {
    ...mapGetters(['extendJsConfig']),
    lang() {
      return this.$store.state.app.lang;
    }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        this.loadExtendByRouteName();
      }
    }
  },
  mounted() {
    this.currentPageName = this.$route.name;
    // 显示打开的页面的列表
    this.$store.commit('setOpenedList');
    this.$store.commit('initCachepage');
    // 权限菜单过滤相关
    this.$store.commit('updateMenulist');
    window.__setThemeLink = this.setThemeLink;
    window.__setFunctionOptions = this.setFunctionOptions;
  },
  created() {
    const __app = window.__app;
    let dailyUrl = {
      'dinghuo-daily.yyuap.com': 'https://dinghuo-bip-daily.yonyoucloud.com/',
      'dinghuo-bip-daily-dedicated.yyuap.com': 'https://dinghuo-bip-daily-dedicated.yonyoucloud.com/'
    };
    let testUrl = {
      'dinghuo-test.yyuap.com': 'https://dinghuo-bip-test.yonyoucloud.com/'
    };
    let preUrl = {
      'dinghuo-y3me-pre.diwork.com': 'https://dinghuo-bip-pre.yonyoucloud.com/',
      'dinghuo-bip-pre.diwork.com': 'https://dinghuo-bip-pre.yonyoucloud.com/'
    };
    let productionUrl = {
      'dinghuo-yonbip.diwork.com': 'https://dinghuo-c2.yonyoucloud.com/',
      'dinghuo.diwork.com': 'https://dinghuo-c2.yonyoucloud.com/',
      'dinghuo-yonsuite.diwork.com': 'https://dinghuo-c2.yonyoucloud.com/',
      'dinghuo-yonbip-core1.diwork.com': 'https://dinghuo-c1.yonyoucloud.com/',
      'dinghuo-yonbip-core3.diwork.com': 'https://dinghuo-c3.yonyoucloud.com/',
      'dinghuo-yonbip-core4.diwork.com': 'https://dinghuo-c4.yonyoucloud.com/',
      'dinghuo-dbox.diwork.com': 'https://dinghuo-dbox.yonyoucloud.com/',
      'dinghuo-globalbip-new.yonyou.com': 'https://dinghuo-global.yonyoucloud.com/'
    };
    __app.urlMapping = window.__app.context.ysDownFileUrl.includes('bip-daily') ? dailyUrl : window.__app.context.ysDownFileUrl.includes('bip-test') ? testUrl : window.__app.context.ysDownFileUrl.includes('bip-pre') ? preUrl : productionUrl;
    __app.ajax = async function(params) {
      postDefineExtendJs(params).then((res) => {
        params.success(res);
      }).catch((err) => {
        params.error(err);
      });
    };
    __app.define = function(namespace, callback) {
      if (!namespace || !callback) {
        // eslint-disable-next-line no-console
        console.error('namespace and callback is required');
        return;
      }
      if (!__app.defines) {
        __app.defines = {};
      }
      __app.defines[namespace] = callback && callback();
    };
    __app.broadcast = function(namespace) {
      if (!__app.defines) return;
      const extendJs = __app.defines[namespace];
      if (extendJs && __app.tasks) {
        const todoTasks = __app.tasks.filter(v => !v.passed && v.namespace === namespace);
        todoTasks.forEach(task => {
          const { action, params } = task;
          const fn = extendJs[action];
          if (fn) {
            fn(params);
            task.passed = true;
          }
        });
      }
    };
    __app.broadcastToExtend = this.broadcastToExtend;
    this.setDeviceId();
    this.setThemeLink();
    this.setDefineThemeLink();
    if (window.__app.context.isMultiLang) {
      this.setI18nResource();
    }
    let tagsList = [];
    appRouter.map((item) => {
      if (item.children && item.children.length) {
        item.children.map((subItem) => {
          if (subItem.children && subItem.children.length) {
            tagsList.push(...subItem.children);
          } else {
            tagsList.push(subItem);
          }
        });
      } else {
        tagsList.push(item);
      }
    });
    this.$store.commit('setTagsList', tagsList);
  },
  methods: {
    getTheme() {
      if (this.$store.getters.currentUser) {
        this.setThemeLink(this.$store.getters.currentUser.theme);
      }
    },
    broadcastToExtend(config = {}) {
      const __app = window.__app;
      if (!__app.tasks) {
        __app.tasks = [];
      }
      const route = this.$route;
      let routeName = config.namespace || route.name;
      const namespace = this.$util.extendKey(routeName);
      const task = {
        passed: false,
        ...config,
        namespace
      };
      __app.tasks.push(task);
      __app.broadcast(namespace);
    },
    loadExtendByRouteName() {
      const extendJsConfig = this.extendJsConfig;
      const { name } = this.$route; // 潜规则，按照route的name去匹配
      const load = (js) => {
        if (js) {
          let url = js.extApiUrl;
          let flag = '?';
          if (url.indexOf('?') > -1) {
            flag = '&';
          }
          url += flag + dayjs().format('YYYYMMDD');
          this.$util.getScript(url, 0).then(() => {
            window.__app.broadcast(this.$util.extendKey(js.rotues));
          });
        }
      };
      if (extendJsConfig && this.extendJsConfig.length) {
        let js = null;
        if (window.__app.context.isPremises === 'true') {
          js = extendJsConfig.filter((v) => v.rotues === name || v.rotues === 'common');
          if (js.length) {
            js.map((v) => {
              load(v);
            });
          }
        } else {
          js = extendJsConfig.find((v) => v.rotues === name || v.rotues === 'common');
          if (js) {
            load(js);
          }
        }
      }
    },
    setThemeLink(_theme) {
      try {
        if (!_theme) {
          _theme = window.__app.context.theme === 'undefined' ? 'red' : window.__app.context.theme;
        }
        const lastTheme = localStorage.getItem('u-theme') === 'undefined' || localStorage.getItem('u-theme') === 'null' ? null : localStorage.getItem('u-theme');
        const theme = _theme || lastTheme || 'red';
        const skin = window.__app.context.skin;
        let themeLink = document.querySelector('link[name="theme"]');
        let skinLink = document.querySelector('link[name="skin"]');
        let stylePath = this.getThemeRootPath();
        if (skin) {
          let path = `/g/vendors/css/diy-skin/${skin}?v=${util.random()}`;
          skinLink.setAttribute('href', path);
        }
        // if (theme === 'red') {
        //   return;
        // }
        if ($config.env === 'development') {
          return;
        }
        let path = stylePath + theme + '.css?v=' + util.random();
        themeLink.setAttribute('href', path);
      } catch (error) {
        console.error('setTheme error'); //eslint-disable-line
      }
    },
    setDefineThemeLink() {
      let localtThemeUrl = window.localStorage.getItem('currentThemeUrl') || window.__app.context.extendCss;
      if (localtThemeUrl) {
        document.querySelector('link[name="definetheme"]').setAttribute('href', localtThemeUrl);
      }
    },
    setFunctionOptions() {
      this.$store.dispatch('GetUserBo');
    },
    getThemeRootPath() {
      let stylePath = '';
      if ($config.env === 'development') {
        stylePath = 'http://127.0.0.1:4000/dist/css/';
      } else {
        stylePath = publicPath + 'css/';
      }
      return stylePath;
    },
    setDeviceId() {
      util.setDeviceUUid();
    },
    setI18nResource() {
      this.$store.dispatch('getI18n');
    }
  },
  render: (h) => h(App)
});
window.__app.mainV = mainV;
