const mixin = {
  props: {
    params: {
      required: true,
      type: Object,
      default: {}
    },
    column: {
      required: true,
      type: Object,
      default: {}
    },
    bizMeta: {
      type: Object
    }
  },
  data() {
    return {
      popTrigger: 'hover'
    };
  }
};

export default mixin;
