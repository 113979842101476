<template>
  <div v-show="isQueryschema">
    <div :class="classes">
      <div
        v-if="!editable"
        ref="reference"
        class="ivu-select-selection"
        @mouseenter="hasMouseHoverHead = true"
        @mouseleave="hasMouseHoverHead = false"
        @click="showModal">
        <slot name="input">
          <!-- 多选删除单个 -->
          <div v-if="referMeta.multiple && referMeta.clearable" class="showValInput">
            <div v-for="item in showValueArr" :key="item.id" class="showValueItem">
              {{ item.value }}
              <Icon
                :class="[item.id]"
                custom="iconfont icon-guanbi1"
                @click.native.stop="clearSelect(item.id)"></Icon>
            </div>
          </div>
          <span v-if="placeholder && showDefinePlaceholder" :class="[prefixCls + '-selected-value']" style="color: #999;" :title="placeholder">{{ placeholder }}</span>
          <span v-else :class="[prefixCls + '-selected-value']" :title="showValue">{{ showValue }}</span>
          <Icon
            v-if="!disabled && (hasValue && hasMouseHoverHead) && clearable"
            :class="`ivu-select-arrow`"
            type="ios-close-circle"
            @click.native.stop="clearSingleSelect"></Icon>
          <Icon
            v-if="!disabled && (!hasValue || (hasValue && !hasMouseHoverHead)) || !clearable"
            :class="`ivu-select-arrow`"
            type="navicon-round"></Icon>
        </slot>
      </div>
      <div
        v-if="editable"
        mouseenter="hasMouseHoverHead = true"
        @mouseleave="hasMouseHoverHead = false">
        <Input v-model="showValue" :clearable="clearable" @on-blur="inputBlur" @on-clear="inputClear">
        <Icon
          slot="suffix"
          style="line-height: 12px;font-size:14px;"
          :class="`ivu-select-arrow`"
          type="navicon-round"
          @click="showModal"></Icon>
        </Input>
      </div>
    </div>
    <Modal
      v-if="control.controlType === 'OuterRefer'"
      v-model="modal"
      :mask-closable="true"
      :styles="styles"
      :title="title"
      :closable="true"
      class-name="refer-modal"
      @on-visible-change="visibleChange">
      <Row>
        <iframe
          :ref="control.fieldName"
          :src="control.refSource"
          :style="frameStyles"></iframe>
      </Row>
      <div slot="footer"></div>
    </Modal>
    <Modal
      v-if="control.controlType !== 'OuterRefer'"
      v-model="modal"
      :mask-closable="false"
      :styles="styles"
      :title="title"
      :closable="closeable"
      class-name="refer-modal"
      @on-visible-change="visibleChange">
      <Row v-if="modal">
        <slot name="header"></slot>
        <div
          v-if="(referType ==='TreeTable' || referMeta.referType === 'TreeTable') && !referMeta.productId && !referMeta.classId && !referMeta.groupId && !referMeta.isSpecialTable"
          class="leftPanel modal-tree-panel">
          <ReferTree
            v-if="referMeta.fieldType !== 21"
            :data="treeData"
            :is-filter="isTreeFilter"
            @on-tree-selected="handleTreeSelected"></ReferTree>
        </div>
        <!-- 凑单必选条件后期在支持 -->
        <div v-if="referMeta.isSpecialTable && false" class="leftPanel modal-tree-panel" style="width:200px">
          <slot name="myTable"></slot>
        </div>
        <div
          :class="{'select-agent':isSelectAgent}"
          :style="referRightStyle"
          class="rightPanel">
          <slot
            v-if="modal"
            name="slot-refer-table">
            <ReferTable
              v-if="modal && !(referMeta.key ==='productRef' || referMeta.key ==='customeRef' || referMeta.key ==='productEditRef' || referMeta.key === 'srcBillOrderRef' || referMeta.key === 'salesQuotationRef')"
              ref="refer-table"
              :data="tableData"
              :biz-meta="referMeta"
              :height="height"
              :table-index="tableIndex"
              :real-index="realIndex"
              @refer-table-loaded="referTableLoaded"
              @on-queryschema-loaded="queryschemaLoaded"></ReferTable>
            <ProductReferTable
              v-if="modal && referMeta.key==='productRef'"
              ref="refer-table"
              :is-immediately-call-interface="isImmediatelyCallInterface"
              :biz-meta="bizMeta"
              :height="height"
              :selected-class-id="selectedClassId"
              :selected-product-group-id="selectedProductGroupId"
              :bill-meta="referMeta"
              :is-new-api-of-product="isNewApiOfProduct"
              :source="source"></ProductReferTable>
            <ProductEditRef
              v-if="modal && referMeta.key==='productEditRef'"
              ref="refer-table"
              :biz-meta="bizMeta"
              :height="height"
              :bill-meta="referMeta"></ProductEditRef>
            <CustomeRefTable
              v-if="modal && referMeta.key==='customeRef'"
              ref="refer-table"
              :biz-meta="bizMeta"
              :height="height"
              :bill-meta="referMeta"
              @saveok="customeRefSaveOk"></CustomeRefTable>
            <SrcBillOrderTableNew
              v-if="modal && referMeta.key==='srcBillOrderRef' && newContratorder"
              ref="refer-table"
              :biz-meta="bizMeta"
              :height="height"
              :bill-meta="referMeta"
              @saveok="customeRefSaveOk">
            </SrcBillOrderTableNew>
            <SrcBillOrderTable
              v-if="modal && referMeta.key==='srcBillOrderRef' && !newContratorder"
              ref="refer-table"
              :biz-meta="bizMeta"
              :height="height"
              :bill-meta="referMeta"
              @saveok="customeRefSaveOk"></SrcBillOrderTable>
            <SalesQuotationProductTable
              v-if="modal && referMeta.key==='salesQuotationRef'"
              ref="refer-table"
              :biz-meta="bizMeta"
              :height="height"
              :bill-meta="referMeta"
              @saveok="customeRefSaveOk" />
          </slot>
        </div>
      </Row>

      <div
        v-show="!bizMeta.hideFooter"
        slot="footer">
        <Button
          type="text"
          class="cancel-btn"
          fieldid="cancel-btn"
          @click="hideModal">
          {{ mlang('cancel') }}
        </Button>
        <Button
          :loading="modalLoading"
          type="primary"
          fieldid="confirmation-btn"
          @click="sureClick">
          {{ mlang('confirmation') }}
        </Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import ReferTree from './refer-tree';

import CustomeRefTable from '_c/common/refer/customerAuth-ref/customer-ref-table.vue';
import SrcBillOrderTableNew from '_c/common/refer/srcBillOrder-ref/srcBillOrder-ref-table-new.vue';
import SrcBillOrderTable from '_c/common/refer/srcBillOrder-ref/srcBillOrder-ref-table.vue';
import SalesQuotationProductTable from '_c/common/refer/sales-quotation-product-table';
import ProductReferTable from '_c/common/refer/product-ref/product-ref-table';
import ProductEditRef from '_c/common/refer/product-ref/product-edit-ref';
import Emitter from '@/u-components/mixins/emitter';
import { findComponentUpward, oneOf } from '../../../u-components/utils/assist';
import ReferTable from './refer-table';
import { defineRefer } from '@/meta/refer-common';
import { deepCopy } from '@/u-components/utils/assist';

const prefixCls = 'ivu-select';
export default {
  name: 'Refer',
  components: { ReferTable, ReferTree, ProductReferTable, ProductEditRef, CustomeRefTable, SrcBillOrderTable, SrcBillOrderTableNew, SalesQuotationProductTable },
  mixins: [Emitter],
  props: {
    bizMeta: {
      type: Object,
      default: null
    },
    referType: {
      type: String,
      default: 'Table',
      validator(value) {
        return oneOf(value, ['Table', 'TreeTable']);
      }
    },
    isQueryschema: {
      type: [Boolean],
      default: true
    },
    referData: {
      type: Array
    },
    control: {
      default: {},
      type: Object
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    showInput: {
      type: [Boolean],
      default: true
    },
    editable: {
      type: [Boolean],
      default: false
    },
    showText: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    isSelectAgent: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tableIndex: {
      type: Number
    },
    realIndex: {
      type: Number
    },
    source: {
      type: String
    },
    isImmediatelyCallInterface: {
      type: Boolean,
      default: true
    },
    showTextArr: {
      type: Array
    },
    placeholder: {
      type: String
    },
    isNewApiOfProduct: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentUser: this.$store.getters.currentUser,
      tableData: {},
      treeData: [],
      referMeta: {},
      hasMouseHoverHead: false,
      prefixCls: prefixCls,
      showValue: this.showText,
      height: 464,
      treeHeight: 464,
      modal: false,
      modalLoading: false,
      showValueArr: [],
      modalHeight: '',
      modalWidth: '',
      isTreeFilter: true,
      selectedClassId: null,
      selectedProductGroupId: null,
      showDefinePlaceholder: true
    };
  },
  computed: {
    closeable() {
      let closeable = true;
      if (this.referMeta.closeable === false) {
        closeable = false;
      }
      return closeable;
    },
    title() {
      let title = this.referMeta.showCaption || this.referMeta.caption;
      if (this.referMeta.showTitle === false) {
        title = '';
      }
      return title;
    },
    referRightStyle() {
      const width = this.referType === 'TreeTable' ? 900 : ((this.referMeta.key === 'productRef' || this.referMeta.key === 'productEditRef') ? 1100 : this.modalWidth);
      return { width: `${width}px` };
    },
    classes() {
      return [
        `${prefixCls} ${prefixCls}-refer`,
        {
          [`${prefixCls}-single`]: true,
          [`${prefixCls}-show-clear`]: this.clearable
        },
        {
          'ivu-select-disabled': this.disabled
        }
      ];
    },
    showPlaceholder() {
      let status = false;
      if (typeof this.value === 'string') {
        if (this.value === '') {
          status = true;
        }
      } else if (Array.isArray(this.value)) {
        if (!this.value.length) {
          status = true;
        }
      } else if (this.value === null) {
        status = true;
      }

      return status;
    },
    hasValue() {
      return !this.showPlaceholder;
    },
    frameStyles() {
      return {
        top: '60px',
        width: ` ${this.modalWidth}px`,
        'overflow-y': 'auto',
        height: ` ${this.height}px`,
        border: 'none'
      };
    },
    styles() {
      if (this.referMeta.key === 'productRef' || this.referMeta.key === 'productEditRef') {
        let width = this.referMeta.classId || this.referMeta.groupId || this.referMeta.brandId || this.referMeta.productLineId || this.referMeta.hideReferTree ? 900 : 1100; // 有classId,则旁边的Ttree控件不显示，所以整个弹窗的宽度要变窄
        if (this.referMeta.productId || this.referMeta.classId) {
          width = 900;
        } else {
          width = 1100;
        }
        return {
          top: '60px',
          width: `${width}px`,
          height: 464
        };
      } else {
        return {
          top: '60px',
          width: ` ${this.modalWidth}px`,
          height: ` ${this.modalHeight}px`
        };
      }
    },
    newContratorder() {
      return this.$getBO('NEWCONTRATORDER')
    }
  },

  watch: {
    showText: {
      handler(val, old) {
        this.showValue = val;
      }
    },
    modal: {
      handler(show) {
        if (show) {
          this.getReferByAction();
          this.setTreeData();
        }
      }
    },
    control: {
      handler(val) {
        if (val.reload) {
          this.broadcast('ReferTable', 'referLoad');
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    this.getReferByAction();
  },
  mounted() {
    this.setWidthAndHeight();
  },
  methods: {
    setWidthAndHeight() {
      let clientHeight = document.body.clientHeight;
      let modalHeight = clientHeight - clientHeight * 0.32;
      if (modalHeight < 340) modalHeight = 340;
      let modalWidth = modalHeight * 1.6;
      if (modalWidth < 1100) modalWidth = 1100;
      this.modalWidth = modalWidth;
      this.height = modalHeight;
    },
    handleTreeSelected(selectNodes) {
      this.broadcast('ArchiveList', 'on-tree-selected', { selectNodes });
      this.broadcast('ProductRefTable', 'on-tree-selected', { selectNodes });
      this.broadcast('SrcBillRefTable', 'on-tree-selected', { selectNodes });
    },
    getReferByAction() {
      // 前端临时预制
      if (this.bizMeta.actions && this.bizMeta.actions[this.control.action] && !this.control.hasSetAction) {
        this.referMeta = this.bizMeta.actions[this.control.action](this.control);
      } else if (this.control.isDefine) {
        // 对自定义项进行处理走ReferTable这个组件
        this.referMeta = defineRefer(this.control, this);
      } else if (this.control.hasSetAction) {
        this.referMeta = this.control;
      }
      this.referMeta.hideModal = this.hideModal;
    },
    getTreeData() {
      if ((this.referType === 'TreeTable' || this.referMeta.referType === 'TreeTable') && this.bizMeta && this.bizMeta.actions && this.referMeta.action) {
        if (!this.bizMeta.actions[this.referMeta.action]) {
          console.error('no action ==>' + this.referMeta.action); // eslint-disable-line
        } else {
          let query = { conditions: this.referMeta.passedparams };
          this.referMeta.actions.queryTree(query || {}).then((res) => {
            if (this.control.screenTreeFn) {
              res = this.control.screenTreeFn(res);
              this.isTreeFilter = false;
              this.selectedClassId = res[0].id;
              this.treeData = res;
            } else if (this.control.srcTreeData) {
              let treeDatas = [];
              res.data.map(item => {
                treeDatas.push({
                  id: item.id,
                  title: item.name
                });
              });
              this.treeData = treeDatas;
            } else {
              this.treeData = res;
            }
          });
        }
      }
    },
    queryschemaLoaded() {
      this.setWidthAndHeight();
      this.height = this.height - (this.$refs['refer-table'].$refs.queryschema.$el.clientHeight || 0);
      this.treeHeight = 464;
    },
    referTableLoaded() {
      this.$emit('refer-table-loaded');
    },
    referClick() {
      this.modal = !this.disabled && !this.modal;
    },
    inputBlur() {
      this.$emit('input', this.showValue);
      this.$emit('on-ok', null, this.showValue, this.showValue, this.control);
      this.dispatch('FormItem', 'on-form-change', this.showValue);
    },
    inputClear() {

    },
    customeRefSaveOk() {
      this.modalLoading = false;
      this.hideModal();
      this.$emit('on-ok');
    },
    sureClick() {
      let selectRows = this.$refs['refer-table'].getSelectRow();
      //合同下单，如果没有选择合同进行提示
      if (this.referMeta.caption === this.mlang('saleContract') && this.referMeta.refName === "name" && !selectRows) {
        this.$Message.warning({ content: this.mlang('pleaseSelectSaleBillOrder') });
        return false;
      }

      //合同下单，如果选择的商品没有销售数量SubQty提示
      if (this.referMeta.caption === this.mlang('saleContract') && this.referMeta.refName === "productName" && selectRows) {
        if (selectRows && selectRows.length) {
          selectRows.forEach(item => delete item.tempStoreProduct)
        }
        let noSubQtyProduct = selectRows.some(row =>
        row.orderDetails && row.orderDetails.some(detail =>
            !detail.subQty || detail.subQty === 0
          )
        );
        if (noSubQtyProduct) {
          this.$Modal.error({
            content: this.mlang('subQtyisRequired')
          });
          return;
        }
      }
      if (selectRows === false) {
        if (this.referMeta.key === 'customeRef') {
          this.modalLoading = true;
        }
        return;
      }
      const refKey = this.referMeta['refReturnValue'] || 'id';
      const refName = this.referMeta['refName'] || 'cName';
      let showValue = '';
      let value = '';
      if (selectRows) {
        if (this.referMeta.multiple) {
          if (this.referMeta.clearable) {
            this.showValueArr = [];
            _.map(selectRows, (v, index) => {
              this.showValueArr.push({
                id: v.id,
                value: v[refName]
              });
            });
          } else {
            showValue = _.join(
              _.map(selectRows, (v, index) => {
                return v[refName];
              }),
              ','
            );
          }
          value = _.join(
            _.map(selectRows, (v) => {
              return v[refKey];
            }),
            ','
          );
        } else {
          if (!Array.isArray(refName)) {
            showValue = selectRows[refName];
          } else {
            refName.forEach((v) => {
              if (v.indexOf('.') !== -1) {
                showValue += selectRows[v.split('.')[0]][v.split('.')[1]];
              } else {
                showValue += selectRows[v];
              }
            });
          }

          value = selectRows[refKey];
        }
      }
      this.showValue = showValue;
      if (this.referMeta.fieldType === 20 || this.referMeta.fieldType === 21) {
        setTimeout(() => {
          this.showValue = showValue;
        }, 0);
      }
      this.showDefinePlaceholder = false
      this.$emit('input', value);
      this.$emit('on-ok', selectRows, value, showValue, this.control, this.showValueArr);
      this.dispatch('FormItem', 'on-form-change', value);
      this.hideModal();
    },
    showModal() {
      this.modal = !this.disabled && true;
      if (this.control.controlType === 'OuterRefer') {
        this.$nextTick(() => {
          var frame = this.$refs[this.control.fieldName];
          const form = findComponentUpward(this, 'ArchiveForm') || findComponentUpward(this, 'Voucher');
          let data = {
            url: this.control.refSource,
            data: form.getModelData(),
            control: this.control,
            context: {
              user: this.currentUser,
              clientType: 4
            },
            index: this.tableIndex
          };
          if (this.bizMeta.beforePostOuterRefer) {
            data = this.bizMeta.beforePostOuterRefer(data);
          }
          const postData = deepCopy(data);

          for (const key of Object.keys(postData.control)) { // 不能传递方法 postmessage
            if (typeof postData.control[key] === 'function') {
              delete postData.control[key];
            }
          }
          var obj = JSON.parse(JSON.stringify(postData));
          frame.contentWindow.postMessage(obj, this.control.refSource);
        });
        window.addEventListener(
          'message',
          (messageEvent) => {
            if (this.control.refSource.indexOf(messageEvent.origin) > -1) {
              const data = messageEvent.data;
              this.modal = false;
              this.$emit('on-ok', data, this.control);
              window.removeEventListener('message', (event) => {
                event.preventDefault();
              });
            }
          },
          false
        );
      }
    },
    hideModal() {
      this.modal = false;
      this.modalLoading = false;
    },
    clearSingleSelect() {
      this.showValue = '';
      // 刘明昊处理不了null的情况
      // 适配自定义类型基础档案特征
      if (this.control.fieldType === 20 || this.control.fieldType === 21) {
        this.$emit('input', '');
        this.$emit('on-ok', [], '', '', this.control);
      } else {
        this.$emit('input', null);
        if (this.control.controlType === 'OuterRefer') {
          this.$emit('on-ok', null, this.control, true);
        } else {
          this.$emit('on-ok', [], null, '', this.control);
        }
      }
      this.dispatch('FormItem', 'on-form-change', null);
    },
    visibleChange(visible) {
      if (!visible) {
        this.modal = false;
        this.modalLoading = false;
        this.selectedClassId = null;
      }
    },
    setTreeData() {
      if (!this.bizMeta) return;
      let { selectProductPopClasses, selectProductPopGroups } = this.bizMeta;
      if (selectProductPopClasses && selectProductPopClasses.length) {
        selectProductPopClasses.forEach((v) => { v.title = v.cName || v.name; });
        if (this.isNewApiOfProduct) {
          this.isTreeFilter = true;
        } else {
          selectProductPopClasses[0].selected = true; // 默认选中第一个
          this.selectedClassId = selectProductPopClasses[0].id;
          this.isTreeFilter = false;
        }
        this.treeData = selectProductPopClasses;
        this.selectedProductGroupId = null;
      } else if (selectProductPopGroups && selectProductPopGroups.length) {
        selectProductPopGroups.forEach((v) => { v.title = v.name; });
        selectProductPopGroups[0].selected = true; // 默认选中第一个
        this.selectedProductGroupId = selectProductPopGroups[0].id;
        this.treeData = selectProductPopGroups;
        this.isTreeFilter = false;
        this.selectedClassId = null;
      } else {
        this.selectedClassId = null;
        this.selectedProductGroupId = null;
        this.isTreeFilter = true;
        this.getTreeData();
      }
    },
    clearSelect(index) {
      this.showValueArr.map((v, i) => {
        if (index === v.id) {
          this.showValueArr.splice(i, 1);
        }
      });
      this.$emit('on-ok', this.showValueArr, null, '', this.control);
    }
  }
};
</script>
<style lang="less" scoped>
.showValueItem{
  font-size: 12px;
  display: inline-block;
  color: #333 !important;
  background: #f7f9fd !important;
  padding: 3px;
  margin-top: 5px;
  margin-right: 5px;
  i{
    font-size: 5px;
    padding: 0 3px;
    color: #aaa;
    transform: scale(0.8)
  }
}

/deep/.ivu-select-selection > .showValInput{
  padding: 0 10px 5px 5px;
}
</style>
