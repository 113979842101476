import ajax from '@/libs/ajax';

export const getSaleBillOrder = (data, agentRelationId, upBizId) => {
  return ajax.request({
    url: `/voucher/order/generateOrderBySaleScat?agentRelationId=${agentRelationId}&iBizId=${upBizId}`,
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const verifyMutexOrderDetailGroup = (data) => {
  return ajax.request({
    url: '/goods/promotion//selectfree/verifyMutexOrderDetailGroup',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 通过销售报价生单
export const generateOrderBySalesQuotation = (data) => {
  return ajax.request({
    url: '/voucher/order/package/submit/model?source=salesQuotation',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 行改价后，重算特殊优惠
export const recountSpecialApportion = (order) => {
  return ajax.request({
    url: '/pc/OrderSrv/recountSpecialApportion',
    method: 'post',
    data: {
      order,
      datatype: 'json'
    }
  });
};

// 订单列表批量发货
export const batchDelivery = (data) => {
  return ajax.request({
    url: '/pc/DeliverySrv/batchDelivery',
    method: 'post',
    data: {
      orderNos: data,
      datatype: 'json'
    }
  });
};

// 新建订单
export const initorderheaders = (data) => {
  return ajax.request({
    url: '/voucher/order/init/initorderheaders',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 新建订单
export const createNewOrder = (data) => {
  return ajax.request({
    url: '/voucher/order/init/order?isArchive=1',
    method: 'get',
    data
  });
};
// 新建订单
export const createNewOrderBizs = (data) => {
  return ajax.request({
    url: '/voucher/order/init/order/bizs?isArchive=1',
    method: 'get',
    data
  });
};

// 保存订货端订单
export const saveOrder = (neworder) => {
  return ajax.request({
    url: '/pc/OrderSrv/saveOrder',
    method: 'post',
    data: {
      datatype: 'json',
      loading: true,
      neworder
    }
  });
};

// 获取订货端订单详情
export const getAgentOrderDetail = (data) => {
  return ajax.request({
    url: `/marketingbill/order/order/${data.id}/detail?isMarket=1&source=${data.source}&ueFlag=${data.ueFlag}`,
    method: 'get'
  });
};

// 提交订货端订单
export const modifyOrder = (neworder, isU8C = false) => {
  let url = '/pc/OrderSrv/submitOrder';
  let paramName = 'neworder';
  let params = '?ueFlag=true';
  let data = {
    datatype: 'json',
    loading: true
  };
  if (isU8C) {
    url = '/voucher/order/submit/model';
    paramName = 'data';
    data['isArchive'] = 1;
  }
  data[paramName] = neworder;
  url += params;
  return ajax.request({
    url,
    method: 'post',
    data
  });
};
// 提交订货端订单
export const modifyOrderBizs = (neworder) => {
  let url = '/voucher/order/submit/model/bizs';
  let params = '?ueFlag=true';
  let data = {
    datatype: 'json',
    loading: true
  };
  let paramName = 'data';
  data['isArchive'] = 1;
  data[paramName] = neworder;
  url += params;
  return ajax.request({
    url,
    method: 'post',
    data
  });
};

// 根据商品id获取商品信息，构建订单行
export const makeOrderProductRows = (oOrderDetails) => {
  return ajax.request({
    url: '/pc/OrderSrv/packageOrderDetailProduct',
    method: 'post',
    data: {
      datatype: 'json',
      loading: true,
      oOrderDetails
    }
  });
};

export const packageSubmitOrderData = (order) => {
  return ajax.request({ url: '/voucher/order/package/submit/model', method: 'post', data: { data: order, datatype: 'json', isArchive: 1 } });
};
// /voucher/order/package/order/modify/price
export const packageOrderModifyPrice = (order) => {
  return ajax.request({ url: '/voucher/order/package/order/modify/price', method: 'post', data: { data: order, datatype: 'json', isArchive: 1 } });
};
export const packageSubmitOrderBizsData = (order) => {
  return ajax.request({ url: '/voucher/order/package/submit/model/bizs', method: 'post', data: { data: order, datatype: 'json', isArchive: 1 } });
};

export const getOrderDefineFormulaValue = (order) => {
  return ajax.request({ url: '/voucher/order/define/formula/value', method: 'post', data: { data: order, datatype: 'json', isArchive: 1 } });
};

// 局部重新加载商品信息
export const getImportNewOrderDetails = (order) => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/voucher/order/package/submit/model?isArchive=1&import=true' : '/pc/OrderSrv/getNewOrderDetails',
    method: 'post',
    data: window.__app.context.productLine === 'u8c' ? {
      datatype: 'json',
      data: order,
      isArchive: 1
    } : {
      datatype: 'json',
      neworder: order
    }
  });
};

// nc多组织
export const isNcMultiOrg = () => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/archives/erpsystem/erpsystemset/manyOrgan?isArchive=1' : '/pc/ErpServiceSrv/isManyOrgan',
    method: 'get'
  });
};

// 订货端订单编辑时删除商品处理规则
export const delShoppingCart = (data) => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/goods/shoppingcart/del' : '/pc/goods/ShoppingCartSrv/del',
    method: 'post',
    data
  });
};

// 获取订单上有效的优惠券
export const getValidCoupons = (order) => {
  return ajax.request({
    url: '/pc/OrderSrv/getOrdeValidCoupons',
    method: 'post',
    data: {
      datatype: 'json',
      order
    }
  });
};

// 浏览态订单详情
export const getDetailFlow = (data) => {
  return ajax.request({
    url: `/marketingbill/order/order/${data.id}/detail/flow?isMarket=1`,
    method: 'get'
  });
};
export const getPaymentSchedules = (id) => {
  return ajax.request({
    url: `voucher/order/getPaymentSchedules/${id}?isArchive=1`,
    method: 'get'
  });
};

// 获取订单历史记录
export const getOrderHistory = (data) => {
  return ajax.request({
    url: `/report/history/order/detail/${data.id}?isReport=1`,
    method: 'get'
  });
};

// 计算费用
export const calcOrderRebate = (order) => {
  return ajax.request({
    url: '/voucher/rebate/udh/calc/voucher',
    method: 'post',
    data: {
      data: order,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 获取订单的费用可用金额和费用剩余金额
export const getOrderUsableRebate = (order) => {
  // let { fPayMoney, fRebateMoney, fReight = 0, fMaxRebateRuleMoney = 0, fRebateCashMoney, bizId, fMaxRebateMoney, iAgentId, cOrderNo = '' } = order;
  // let fSalePayMoney = 0;
  // order.oOrderDetails && order.oOrderDetails.forEach((v) => {
  //   if (v.cOrderProductType === 'SALE' || v.cOrderProductType === 'GIFT') {
  //     fSalePayMoney = util.numAdd(v.fSalePayMoney || 0, fSalePayMoney);
  //   }
  // });
  // fSalePayMoney = util.numAdd(fSalePayMoney, fRebateMoney);
  // let data = {
  //   fPayMoney,
  //   fRebateMoney,
  //   fRebateCashMoney,
  //   bizId,
  //   fMaxRebateMoney,
  //   iAgentId,
  //   cOrderNo,
  //   maxRebateRuleMoney: fMaxRebateRuleMoney,
  //   reight: fReight,
  //   fSalePayMoney
  // };
  order.orderPrice = {
    originalPk: order.originalPk
  };
  return ajax.request({
    url: '/voucher/rebate/usablerebates/price',
    method: 'post',
    data: {
      data: order,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 输出
export const exportVoucherDetail = (data) => {
  return ajax.request({
    url: '/pc/basic/ExcelPrintSrv/excelPrint',
    method: 'post',
    data: {
      datatype: 'json',
      excelPrintVo: data
    }
  });
};

// 订单上备注相关
// 保存备注
export const saveOrderMemo = (data) => {
  return ajax.request({
    url: '/pc/OrderSrv/saveOrderMemo',
    method: 'post',
    data: {
      ...data,
      loading: true
    }
  });
};

// 修改备注
export const modifyOrderMemo = (data) => {
  return ajax.request({
    url: '/pc/OrderSrv/modifyOrderMemo',
    method: 'post',
    data: {
      ...data,
      loading: true
    }
  });
};

// 删除备注
export const delOrderMemo = (data) => {
  return ajax.request({
    url: '/pc/OrderSrv/delOrderMemo',
    method: 'post',
    data: {
      ...data,
      loading: true
    }
  });
};

// 判断当前客户是否有促销
export const isHavePromotion = (data = {}) => {
  return ajax.request({
    url: '/goods/promotion/calc/promotion/ishavepromotion',
    method: 'post',
    data: {
      data: data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 获取备注列表
export const findOrderMemos = (orderNo, pageSize) => {
  return ajax.request({
    url: '/pc/OrderSrv/findOrderMemos?cOrderNo=' + orderNo + '&pageSize=' + pageSize,
    method: 'get'
  });
};

// 雨虹隐藏订单备注
export const isHidenOrderMemos = () => {
  return ajax.request({
    url: '/hideOrderMemo',
    method: 'get'
  });
};

// 自定义按钮
export const defineBtn = (url, data) => {
  return ajax.request({
    url,
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data: {
        data
      }
    }
    //   isOutUrl: true
  });
};

// 促销相关
export const dealOrderTax = (order) => {
  return ajax.request({
    url: '/pc/OrderSrv/dealOrderTax',
    method: 'post',
    data: {
      order,
      datatype: 'json'
    }
  });
};

// 订单详情按钮
export const getOrderDetailBtns = (orderId) => {
  return ajax.request({
    url: `/marketingbill/order/orderDetailBtn?orderId=${orderId}&isMarket=1`,
    method: 'get'
  });
};
