export default {
  changeStateWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DDE","不能关闭全部规则") /* "不能关闭全部规则" */,
  delRuleContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DDF","确定删除该规则吗？") /* "确定删除该规则吗？" */,
  delRuleSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE0","删除成功!") /* "删除成功!" */,
  changeRuleStateContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE1","确定要开启这个规则吗？") /* "确定要开启这个规则吗？" */,
  batchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE2","请选择至少一条状态为停用的规则") /* "请选择至少一条状态为停用的规则" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE3","请选择规则") /* "请选择规则" */,
  changeRuleSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE4","状态改变成功") /* "状态改变成功" */,
  yuan: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE5","元") /* "元" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE6","请正确填写!") /* "请正确填写!" */,
  MOSTPERCENTAGE: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE7","不超过总订货金额") /* "不超过总订货金额" */,
  LEASTORDERMONEY: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE8","订货金额达到") /* "订货金额达到" */,
  LEASTORDERMONEY1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DE9","元,才可使用费用") /* "元,才可使用费用" */,
  MOSTREBATEMONEY: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DEA","使用的费用额不超过") /* "使用的费用额不超过" */

};
