export default {
  orderStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000414","订单状态") /* "订单状态" */,
  oneMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000415","近1个月") /* "近1个月" */,
  twoMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000416","近2个月") /* "近2个月" */,
  threeMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000417","近3个月") /* "近3个月" */,
  halfYear: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000418","近半年") /* "近半年" */,
  oneYear: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000419","近1年") /* "近1年" */,
  orderNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500041A","订单编码") /* "订单编码" */,
  customer: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500041B","客户") /* "客户" */,
  orderNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500041C","订单号") /* "订单号" */,
  payStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500041D","付款状态") /* "付款状态" */,
  dealRecord: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500041E","处理记录") /* "处理记录" */,
  specialProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500041F","特定商品") /* "特定商品" */,
  orderCreateDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000420","订单时间") /* "订单时间" */,
  confirmDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000421","确认时间") /* "确认时间" */,
  billDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000422","单据时间") /* "单据时间" */,
  hopeReceiveDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000423","期望收货日期") /* "期望收货日期" */,
  saleMan: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000424","业务员") /* "业务员" */,
  orderCreater: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000425","制单人") /* "制单人" */,
  downERP: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000426","下载到ERP") /* "下载到ERP" */,
  success: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000427","成功") /* "成功" */,
  fail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000428","失败") /* "失败" */,
  notDownload: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000429","未下载") /* "未下载" */,
  settlementWay: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580003E","结算方式") /* "结算方式" */,

  // 驳回oppose
  oTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500042A","请填写驳回意见,当前订单将被自动关闭!") /* "请填写驳回意见,当前订单将被自动关闭!" */,
  oPromotionTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500042B","请填写驳回意见,当前促销活动将被自动停用!") /* "请填写驳回意见,当前促销活动将被自动停用!" */,
  oTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500042C","驳回原因") /* "驳回原因" */,
  oRejectReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500042D","请填写驳回原因") /* "请填写驳回原因" */,
  // 关闭 close
  cTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500042E","请填写关闭原因,当前订单将被自动关闭") /* "请填写关闭原因,当前订单将被自动关闭" */,
  cTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500042F","关闭原因") /* "关闭原因" */,
  cCloseReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000430","请填写关闭原因") /* "请填写关闭原因" */,
  // 打开 openOrder
  open: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000431","打开") /* "打开" */,
  openOrderMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000432","将打开整张订单，确认打开") /* "将打开整张订单，确认打开" */,
  openOrderMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000433","确认打开订单") /* "确认打开订单" */,
  openOrderMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000434","么") /* "么" */,
  openCheckCreditMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000435","用户:") /* "用户:"" */,
  openCheckCreditMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000436","的信用额度是:") /* ""的信用额度是:" */,
  openCheckCreditMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000437",",当前余额是:") /* ",当前余额是:" */,
  openCheckCreditMsgD: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000438",",该订单超出信用余额，是否仍然打开该订单？") /* ",该订单超出信用余额，是否仍然打开该订单？" */,
  openCheckCreditMsgE: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000439",",该订单超出信用余额，不能打开该订单!") /* ",该订单超出信用余额，不能打开该订单!" */,
  // 回退returnOrder
  rContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500043A","确定将") /* "确定将" */,
  rContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500043B","退回到待确认状态吗？") /* "退回到待确认状态吗？" */,
  // 确认订单sure
  sWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500043C","当前订单已提交！") /* "当前订单已提交！" */,
  sContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500043D","是否继续操作？") /* "是否继续操作？" */,
  msgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500043E","确定要确认订单") /* "确定要确认订单" */,
  msgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500043F","吗？") /* "吗？" */,
  bMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000440","您的信用额度是:") /* "您的信用额度是:" */,
  bMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000437",",当前余额是:") /* ",当前余额是:" */,
  bMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000441",",该订单提交会超出信用余额，是否仍然提交该订单？") /* ",该订单提交会超出信用余额，是否仍然提交该订单？" */,
  aMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000440","您的信用额度是:") /* "您的信用额度是:" */,
  aMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000437",",当前余额是:") /* ",当前余额是:" */,
  aMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000442",",该订单提交会超出信用余额，不能提交该订单!") /* ",该订单提交会超出信用余额，不能提交该订单!" */,

  warning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000443","获取业务选项信息失败!") /* "获取业务选项信息失败!" */,
  // 批量
  batchPayError: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000444","批量付款只能选择同一客户的订单") /* "批量付款只能选择同一客户的订单" */,
  batchPayWarningA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000445","请选择至少一条可收款的订单!") /* "请选择至少一条可收款的订单!" */,
  batchPayWarningB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000446","批量收款一次最多50单!") /* "批量收款一次最多50单!" */,
  batchPayContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000447","是否要对选中的") /* "是否要对选中的" */,
  batchPayContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000448","个订单进行收款吗？") /* "个订单进行收款吗？" */,
  batchOpposeWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000449","请选择至少一条状态为待确认的订单") /* "请选择至少一条状态为待确认的订单" */,
  batchOpposeWarningD: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500044A","请选择至少一条可以发货（满足发货条件但待发货或部分发货）的订单") /* "请选择至少一条可以发货（满足发货条件但待发货或部分发货）的订单" */,
  batchDeliveryMsg: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500044B","确定要批量发货么") /* "确定要批量发货么" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500044C","请选择数据") /* "请选择数据" */,
  batchOpposeSuccessA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500044D","已完成批量驳回！选中") /* "已完成批量驳回！选中" */,
  batchOpposeSuccessB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500044E","个订单，其中成功驳回") /* "个订单，其中成功驳回" */,
  batchOpposeSuccessC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500044F","个") /* "个" */,
  batchConfrimContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000450","个订单进行确认吗？") /* "个订单进行确认吗？" */
};
