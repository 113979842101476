<template>
  <div class="agent-midde">
    <Tabs value="fieldid_orderAmount_thisWeek">
      <TabPane :label="mlang('thisWeek')" name="fieldid_orderAmount_thisWeek">
        <ul
          v-for="amount in orderAmountData"
          v-if="!(currentUser.__isChildAccount && (amount.name === mlang('returnGoods') || amount.name === mlang('refund')))"
          :key="amount.name"
          class="agent-amount">
          <li class="top-left top-left-order">
            <span>{{ amount.name }}</span>
          </li>
          <li class="top-left price" :title="amount.week | upCoinOperater | upHideString(amount.monthHideString)">
            <span class="price-symbol">{{ '' | upCoinOperater | upHideOperater(amount.weekHideString) }}</span>
            <span class="price-num">{{ amount.week | substr(8) | upCoinDigit | priceFree(false) | upHideString(amount.weekHideString) }}</span>
          </li>
        </ul>
      </TabPane>
      <TabPane :label="mlang('thisMounth')" name="fieldid_orderAmount_thisMounth">
        <ul
          v-for="amount in orderAmountData"
          v-if="!(currentUser.__isChildAccount && (amount.name === mlang('returnGoods') || amount.name === mlang('refund')))"
          :key="amount.name"
          class="agent-amount">
          <li class="top-left top-left-order">
            <span>{{ amount.name }}</span>
          </li>
          <li class="top-left price" :title="amount.month | upCoinOperater | upHideString(amount.monthHideString)">
            <span class="price-symbol">{{ '' | upCoinOperater | upHideOperater(amount.monthHideString) }}</span>
            <span class="price-num">{{ amount.month | substr(8) | upCoinDigit | priceFree(false) | upHideString(amount.monthHideString) }}</span>
          </li>
        </ul>
      </TabPane>
    </Tabs>
  </div>
</template>
<script>
import { getIndexStatisticOrder } from '@/api/order.js';
import { mapGetters } from 'vuex';
export default {
  name: 'OrderAmount',
  data() {
    return {
      orderAmountData: []
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getIndexStatisticOrder().then((res) => {
        this.orderAmountData = [
          // 订货
          {
            name: this.mlang('orderGoods'),
            svgName: 'dinghuo',
            week: res.weekOrderPrice,
            month: res.monthOrderPrice,
            weekHideString: 'weekOrderPrice',
            monthHideString: 'monthOrderPrice'
          },
          // 退货，因为服务没有weekSaleReturnPrice，monthSaleReturnPrice字段所以统一用订货字段
          // 子账号没有退货退款权限，页面上用***补上，这里直接控制了没数据时用***
          {
            name: this.mlang('returnGoods'),
            svgName: 'tuihuo',
            week: this.currentUser.__isChildAccount ? '***' : res.weekSaleReturnPrice,
            month: this.currentUser.__isChildAccount ? '***' : res.monthSaleReturnPrice,
            weekHideString: 'weekOrderPrice',
            monthHideString: 'weekOrderPrice'
          },
          // 付款
          {
            name: this.mlang('pay'),
            svgName: 'fukuan',
            week: res.weekPaysPrice,
            month: res.monthPaysPrice,
            weekHideString: 'weekPaysPrice',
            monthHideString: 'monthPaysPrice'
          },
          // 退款，同退货
          // {
          //   name: this.mlang('refund'),
          //   svgName: 'tuikuan',
          //   week: this.currentUser.__isChildAccount ? '***' : res.weekRefundPrice,
          //   month: this.currentUser.__isChildAccount ? '***' : res.monthRefundPrice,
          //   weekHideString: 'weekOrderPrice',
          //   monthHideString: 'weekOrderPrice'
          // }
        ];
      });
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ivu-tabs-nav .ivu-tabs-tab{
  padding: 0 10px 8px 0 !important;
  font-size: 12px;
  text-align: center;

}
/deep/.nav-text .ivu-tabs-tab:last-child{
  padding-left: 14px !important;
  padding-right:0 !important;
}
/deep/.ivu-tabs-nav .ivu-tabs-ink-bar{
  width: 14px !important;
  margin: 0 5px;
}
</style>
