<template>
  <Dropdown
    ref="citySelectDropDown"
    :visible="visible"
    class="city-select"
    trigger="custom"
    placement="bottom-start"
    @on-visible-change="handleVisibleChange"
    @on-clickoutside="handleOutSideClick">
    <div>
      <Input
        :value="address"
        :placeholder="placeholder || defaultPlaceHolder"
        :clearable="clearable"
        readonly
        icon="ios-arrow-down"
        @click.native="handleClick"
        @on-clear="handleClear" />
    </div>
    <DropdownMenu
      slot="list"
      :style="menuStyleObject"
      class="city-select-menu">
      <Tabs
        v-model="currentTab"
        type="card"
        @on-click="handlerTabClick">
        <TabPane
          v-for="(tab,index) in tabs"
          v-if="tab.isShowIt"
          :key="index"
          :label="tab.label"
          :disabled="tab.disable"
          :name="tab.name"
          class="city-select-pane">
          <div
            v-for="(places,key) in regionsList[index]"
            v-if="places && places.length"
            :key="key"
            class="place-row">
            <div
              v-if="key!=='other'"
              class="place-title">
              {{ key }}
            </div>
            <div class="place-content">
              <span
                v-for="(item,key) in places"
                :key="key"
                :class="['place-cell',{'place-cell-selected': addressObjectList[index] && (addressObjectList[index].id === item.id) } ]"
                @click="onSelected(tab,index,item)">{{ item.shortName }}</span>
            </div>
          </div>
          <Spin
            v-if="spinShow"
            size="large"
            fix></Spin>
        </TabPane>
      </Tabs>
    </DropdownMenu>
  </Dropdown>
</template>
<script>
import { getAddressByParent, getAddress } from '@/api/city-select/city-select';
import TabsState from './TabsState.js';
import Emitter from '../../mixins/emitter';
// import { deepCopy } from '@/u-components/utils/assist';

import _ from 'lodash';
export default {
  name: 'CitySelect',
  mixins: [Emitter],
  props: {
    value: {
      type: [Object, String],
      default: () => ({})
    },
    placeholder: {
      type: String
    },
    clearable: {
      type: Boolean,
      defaut: false
    }
  },
  data() {
    this.regionCache = new Map();
    const tabs = [{ label: this.mlang('country'), name: 'country', disable: false, isShowIt: true }, { label: this.mlang('province'), name: 'province', disable: true, isShowIt: false }, { label: this.mlang('city'), name: 'city', disable: true, isShowIt: false }, { label: this.mlang('area'), name: 'area', disable: true, isShowIt: false }];
    return {
      spinShow: false,
      visible: false, // 控制菜单的显示和隐藏
      currentTab: '',
      menuStyleObject: {
        width: '400px'
      },
      valueType: 'object', // 双向绑定变量的类型
      defaultPlaceHolder: this.mlang('citySelectPlaceHolder'),
      tabState: null,
      tabs,
      addressList: [],
      addressObjectList: [],
      regionsList: [],
      regionObj: {}, // 用于返回
      isNonShowCountry: true
    };
  },
  computed: {
    address() {
      // 在隐藏了第一个页签的情况下，若没有第二个页签的值，则不显示第一个页签的值
      if (this.isNonShowCountry && this.addressList.filter(v => v).length < 2) return '';
      return this.addressList.join(' ');
    },
    nameIndexMap() {
      return this.tabs.reduce((map, cur, ind) => {
        map[cur.name] = ind;
        return map;
      }, {});
    }
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if (val && JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.updateAddress();
        }
      },
      immediate: true
    }
  },
  created() {
    this.init();
  },
  mounted() {
    const that = this;
    this.handleResize = _.debounce(() => {
      that.setMenuWidth();
    }, 200);
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    init() {
      // 初始化tabs
      let top = this.tabs.length;
      let bottom = 0;
      this.tabState = new TabsState(this.addressList.length, top, bottom, this.tabs);
      // 用判断是否要显示国家
      this.getCountrys().then((regions) => {
        bottom = regions.length === 1 ? 1 : 0;
        this.tabState.setBottom(bottom);
        this.currentTab = this.tabState.getCurrentTab();
        this.isNonShowCountry = bottom;
        // 传入的数据为空,同时隐藏了首个页签，则需设置隐藏项的数据
        if (this.isNonShowCountry && !this.addressObjectList.length) {
          this.setAddressItem(0, regions[0]);
        }
        let params = { isNonShowCountry: this.isNonShowCountry };
        this.$emit('on-init', params);
      });
    },
    async updateAddress() {
      if (!this.tabState) this.tabState = new TabsState(this.addressList.length, this.tabs.length, 0, this.tabs);
      this.makeAddressList(this.value);
      await this.getAddressObjectList(this.addressList.join(' '));
      await this.resetTab();
    },
    makeAddressList(address) {
      if (!address) return;
      this.valueType = typeof address;
      switch (this.valueType) {
        case 'string':
          this.addressList = address ? address.split(/\s+/) : [];
          break;
        case 'object':
          var regionNameLst = ['country', 'province', 'city', 'area'];
          this.addressList.splice(this.tabState.getBottom());
          this.regionObj = address;
          for (let idx in regionNameLst) {
            if (!address[regionNameLst[idx]]) break;
            this.addressList.splice(idx, 1, address[regionNameLst[idx]]);
          }
          break;
      }
    },
    async getAddressObjectList(address) {
      if (!address) return;
      let postData = { 'defVal': address };
      let res = await getAddress(postData);
      res && res.forEach((region, idx) => { this.setAddressItem(region.level, region); });
      let lastObj = this.addressObjectList[this.addressObjectList.length - 1] || {};
      this.setRegionObjAtchInfo(lastObj);
    },
    // 点击外部区域：控制下拉菜单的显示和隐藏
    handleOutSideClick(event) {
      var has = event.path.find((val) => {
        return val.className && val.className.indexOf('city-select-menu') !== -1;
      });
      if (!has) this.visible = false;
    },
    // 点击输入框：控制下拉菜单的显示和隐藏
    handleClick() {
      this.visible = !this.visible;
    },
    // 清空操作：回到初始状态
    handleClear(event) {
      let bottom = this.tabState.getBottom();
      for (let idx = this.tabs.length - 1; idx >= bottom; idx--) {
        this.setAddressItem(idx);
      }
      let preObj = this.addressObjectList[bottom - 1] || {};
      this.setRegionObjAtchInfo(preObj);
      // tab
      this.tabState.backToBottom();
      this.currentTab = this.tabState.getCurrentTab();
    },
    async handleVisibleChange(visible) {
      if (visible) {
        // 设置下拉菜单的宽度
        this.setMenuWidth();
        await this.loadContent(this.currentTab);
      } else {
        let val = this.valueType === 'object' ? this.regionObj : JSON.parse(JSON.stringify(this.address));
        this.$emit('input', val);
        this.dispatch('FormItem', 'on-form-select');
      }
    },
    async handlerTabClick(name) {
      await this.loadContent(name);
    },
    async onSelected(tab, index, item) {
      this.setAddressItem(index, item); // update data
      this.setRegionObjAtchInfo(item);
      for (let idx = this.tabs.length - 1; idx > index; idx--) {
        this.setAddressItem(idx);
      } // update pre data
      this.$emit('on-select', this.regionObj);
      // tab
      this.tabState.backTo(index); // 重定义tab的状态
      if (this.tabState.isFull()) { // 到达末尾，关闭页签
        this.visible = false;
        return;
      }
      let content = await this.loadContent(this.tabState.getNextTab(), true);
      if (content && content.length) { // 计算是否还有内容
        this.tabState.go();
        this.currentTab = this.tabState.getCurrentTab();
      } else {
        this.visible = false; // 没有下一页的内容，关闭页签
      }
    },
    setMenuWidth(vm) {
      let inpputWidth = this.$refs['citySelectDropDown'].$el.offsetWidth;
      if (inpputWidth < 228) inpputWidth = 228;
      let tempStyleObject = this.menuStyleObject;
      this.menuStyleObject = Object.assign({}, tempStyleObject, {
        width: inpputWidth + 'px'
      });
    },
    async resetTab() {
      let nextTabIdx = this.addressList.filter(v => v).length;
      if (nextTabIdx >= this.tabs.length) nextTabIdx--;
      let resetIdx = nextTabIdx;
      let content = await this.loadContent(this.tabs[nextTabIdx].name, true); // 计算是否还有内容
      if (content === null) return; // 遇到错误数据, loadContent 里面已对这种情况进行处理
      if (!content.length) {
        // 下一级找不到数据，说明已到末尾，则切换到当前页签
        let currIdx = nextTabIdx - 1;
        if (currIdx < this.tabState.getBottom()) currIdx = this.tabState.getBottom();
        await this.loadContent(this.tabs[currIdx]);
        resetIdx = currIdx;
      }
      this.tabState.backTo(resetIdx);
      this.currentTab = this.tabState.getCurrentTab();
    },
    async getCountrys() { // 获取所有国家
      let iParentId = 0; let levelType = 0;
      let res = await this.getData({ iParentId, levelType });
      this.regionsList[levelType] = res;
      return _.flatten(Object.values(res || []));
    },
    async loadContent(name, flatten) {
      this.spinShow = true;
      let levelType = this.nameIndexMap[name];
      let iParentId = this.addressObjectList[levelType - 1] ? this.addressObjectList[levelType - 1].id || 0 : 0;
      // 兼容错误数据
      // 中国 中国 河南 安阳市
      // 中国 中国      北京
      // 中国 中国 国
      // 中国 中国 null 湖南
      // 中国 中国 贵阳
      if (iParentId === 0 && levelType !== iParentId) {
        this.tabState.backToBottom();
        this.currentTab = this.tabState.getCurrentTab();
        return null;
      }
      let postData = { iParentId, levelType };
      let res = await this.getData(postData);
      this.regionsList[levelType] = res;
      this.spinShow = false;
      return flatten ? _.flatten(Object.values(res || [])) : res;
    },
    async getData(postData) {
      if (this.regionCache.has(`${postData.iParentId}:${postData.levelType}`)) {
        return this.regionCache.get(`${postData.iParentId}:${postData.levelType}`);
      }
      let res = await getAddressByParent(postData);
      this.regionCache.set(`${postData.iParentId}:${postData.levelType}`, res);
      return res;
    },
    setAddressItem(itemIdx, item) {
      let shortName = (item ? item.shortName : '');
      this.addressList.splice(itemIdx, 1, shortName);
      this.addressObjectList.splice(itemIdx, 1, item);
      this.regionObj[this.tabs[itemIdx].name] = shortName;
    },
    // 为regionObj对象添加其他相关信息
    setRegionObjAtchInfo(item) {
      this.regionObj.regionCode = item.cRegionCode || item.code;
      this.regionObj.region = item.id;
      this.regionObj.zipCode = item.zipCode || '';
    }
  }
};
</script>
