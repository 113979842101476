<script>
  import Input from './Input'
  import Placeholder from './Placeholder'

  export default {
    name: 'vue-treeselect--single-value',
    inject: [ 'instance' ],

    render() {
      const { instance, $parent: { renderValueContainer } } = this
      const shouldShowValue = instance.hasValue && !instance.trigger.searchQuery && instance.selectedNodes[0] && instance.selectedNodes[0].id

      return renderValueContainer([
        shouldShowValue && (
          <div class="vue-treeselect__single-value">
            { instance.selectedNodes[0].label }
          </div>
        ),
        <Placeholder />,
        <Input ref="input" />,
      ])
    },
  }
</script>
