export default {
  orderStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000158","订单状态") /* "订单状态" */,
  oneMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000159","近1个月") /* "近1个月" */,
  twoMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500015A","近2个月") /* "近2个月" */,
  threeMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500015B","近3个月") /* "近3个月" */,
  halfYear: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500015C","近半年") /* "近半年" */,
  oneYear: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500015D","近1年") /* "近1年" */,
  orderNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500015E","订单编码") /* "订单编码" */,
  customer: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500015F","客户") /* "客户" */,
  orderNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000160","订单号") /* "订单号" */,
  payStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000161","付款状态") /* "付款状态" */,
  dealRecord: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000162","处理记录") /* "处理记录" */,
  specialProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000163","特定商品") /* "特定商品" */,
  orderCreateDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000164","订单时间") /* "订单时间" */,
  confirmDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000165","确认时间") /* "确认时间" */,
  billDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000166","单据时间") /* "单据时间" */,
  hopeReceiveDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000167","期望收货日期") /* "期望收货日期" */,
  saleMan: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000168","业务员") /* "业务员" */,
  orderCreater: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000169","制单人") /* "制单人" */,
  downERP: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500016A","下载到ERP") /* "下载到ERP" */,
  success: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500016B","成功") /* "成功" */,
  fail: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500016C","失败") /* "失败" */,
  notDownload: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500016D","未下载") /* "未下载" */,
  settlementWay: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580004C","结算方式") /* "结算方式" */,

  // 驳回oppose
  oTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500016E","请填写驳回意见,当前订单将被自动关闭!") /* "请填写驳回意见,当前订单将被自动关闭!" */,
  oTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500016F","驳回原因") /* "驳回原因" */,
  oRejectReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000170","请填写驳回原因") /* "请填写驳回原因" */,
  // 关闭 close
  cTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000171","请填写关闭原因,当前订单将被自动关闭") /* "请填写关闭原因,当前订单将被自动关闭" */,
  cTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000172","关闭原因") /* "关闭原因" */,
  cCloseReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000173","请填写关闭原因") /* "请填写关闭原因" */,
  // 打开 openOrder
  open: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000174","打开") /* "打开" */,
  openOrderMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000175","将打开整张订单，确认打开") /* "将打开整张订单，确认打开" */,
  openOrderMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000176","确认打开订单") /* "确认打开订单" */,
  openOrderMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000177","么") /* "么" */,
  openCheckCreditMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000178","用户:") /* "用户:"" */,
  openCheckCreditMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000179","的信用额度是:") /* ""的信用额度是:" */,
  openCheckCreditMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017A",",当前余额是:") /* ",当前余额是:" */,
  openCheckCreditMsgD: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017B",",该订单超出信用余额，是否仍然打开该订单？") /* ",该订单超出信用余额，是否仍然打开该订单？" */,
  openCheckCreditMsgE: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017C",",该订单超出信用余额，不能打开该订单!") /* ",该订单超出信用余额，不能打开该订单!" */,
  // 回退returnOrder
  rContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017D","确定将") /* "确定将" */,
  rContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017E","退回到待确认状态吗？") /* "退回到待确认状态吗？" */,
  // 确认订单sure
  sWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017F","当前订单已提交！") /* "当前订单已提交！" */,
  sContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000180","是否继续操作？") /* "是否继续操作？" */,
  msgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000181","确定要确认订单") /* "确定要确认订单" */,
  msgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000182","吗？") /* "吗？" */,
  bMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000183","您的信用额度是:") /* "您的信用额度是:" */,
  bMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017A",",当前余额是:") /* ",当前余额是:" */,
  bMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000184",",该订单提交会超出信用余额，是否仍然提交该订单？") /* ",该订单提交会超出信用余额，是否仍然提交该订单？" */,
  aMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000183","您的信用额度是:") /* "您的信用额度是:" */,
  aMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500017A",",当前余额是:") /* ",当前余额是:" */,
  aMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000185",",该订单提交会超出信用余额，不能提交该订单!") /* ",该订单提交会超出信用余额，不能提交该订单!" */,

  warning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000186","获取业务选项信息失败!") /* "获取业务选项信息失败!" */,
  // 批量
  batchPayError: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000187","批量付款只能选择同一客户的订单") /* "批量付款只能选择同一客户的订单" */,
  batchPayWarningA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000188","请选择至少一条可付款的订单!") /* "请选择至少一条可付款的订单!" */,
  batchPayWarningB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000189","批量付款一次最多50单!") /* "批量付款一次最多50单!" */,
  batchPayContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500018A","是否要对选中的") /* "是否要对选中的" */,
  batchPayContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500018B","个订单进行付款吗？") /* "个订单进行付款吗？" */,
  batchOpposeWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500018C","请选择至少一条状态为待确认的订单") /* "请选择至少一条状态为待确认的订单" */,
  batchOpposeWarningD: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500018D","请选择至少一条可以发货（满足发货条件但待发货或部分发货）的订单") /* "请选择至少一条可以发货（满足发货条件但待发货或部分发货）的订单" */,
  batchDeliveryMsg: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500018E","确定要批量发货么") /* "确定要批量发货么" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500018F","请选择数据") /* "请选择数据" */,
  batchOpposeSuccessA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000190","已完成批量驳回！选中") /* "已完成批量驳回！选中" */,
  batchOpposeSuccessB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000191","个订单，其中成功驳回") /* "个订单，其中成功驳回" */,
  batchOpposeSuccessC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000192","个") /* "个" */,
  batchConfrimContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000193","个订单进行确认吗？") /* "个订单进行确认吗？" */
};
