import ajax from '@/libs/ajax';
import util from '@/libs/util';

export const getSaleReturnList = (data, isHistory, isContainBody) => {
  return ajax.request({
    url: isHistory ? '/report/history/salereturn/list/queryschema' : `/marketingbill/salereturn/list/getListSchema?isContainBody=${isContainBody}`,
    method: 'post',
    data
  });
};
export const getSalereturnImportStatus = () => {
  return ajax.request({ url: '/marketingbill/salereturn/getImportStatus?isMarket=1', method: 'get' });
};
export const exportSalereturns = (data) => {
  return ajax.request({
    url: '/pc/SaleReturnSrv/exportSaleReturns',
    method: 'post',
    data
  });
};
export const exportSalereturnsYS = (postData) => {
  let url = '/mdf-node/uniform/bill/export?action=output&serviceCode=returnedPurchaseList&domainKey=udinghuo';
  url = postData.ids ? `${url}&cmdname=cmdExport2` : `${url}&cmdname=cmdExport1`;
  return ajax.request({
    url,
    method: 'post',
    data: {
      data: Object.assign({}, {
        billnum: 'voucher_salereturnlist',
        fileName: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580004B","销售退货") /* "销售退货" */,
        asyncKey: 'asyncOutput_' + util.uuid(32).replace('pc-', ''),
        externalData: { isAsync: true }
      }, postData),
      isArchive: 1,
      isU8C: 1,
      datatype: 'json'
    }
  });
};

export const approveSaleReturn = (cSaleReturnNo) => {
  return ajax.request({ url: `/voucher/salereturn/approve/${cSaleReturnNo}?isArchive=1`, method: 'post', data: {} });
};

export const opposeSaleReturn = (data) => {
  return ajax.request({ url: `/voucher/salereturn/oppose/${data.cSaleReturnNo}?isArchive=1`, method: 'post', data });
};

export const saleReturnConfirmBack = (cSaleReturnNo) => {
  return ajax.request({ url: `/voucher/salereturn/confirmback/${cSaleReturnNo}?isArchive=1`, method: 'post', data: {} });
};

export const confirmSaleReturn = (data) => {
  return ajax.request({ url: '/pc/SaleReturnSrv/confirmSaleReturn', method: 'post', data });
};

export const fetchSaleReturnLogisticInfo = (saleReturnNo) => {
  return ajax.request({ url: `/voucher/salereturn/logistic/info/${saleReturnNo}?isArchive=1`, method: 'get' });
};

export const getSaleReturnListBtn = (data) => {
  return ajax.request({
    url: 'marketingbill/salereturn/showBtn',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    },
    method: 'post'
  });
};
export const delSaleReturn = (cSaleReturnNo) => {
  return ajax.request({
    url: `/voucher/salereturn/${cSaleReturnNo}?isArchive=1`,
    data: {},
    method: 'DELETE'
  });
};
export const delYsSaleReturn = (obj) => {
  return ajax.request({
    url: '/mdf-node/uniform/bill/delete?serviceCode=returnedPurchaseList&domainKey=udinghuo',
    method: 'post',
    data: {
      data: {
        billnum: 'voucher_salereturn',
        data: JSON.stringify(obj)
      },
      isArchive: 1,
      isU8C: 1,
      datatype: 'json'
    }
  });
};
export const checkagent = (agid) => {
  return ajax.request({ url: `/orginazation/merchant/${agid}/agent/check?isArchive=1`, method: 'get' });
};
export const saleReturnBack = (data, hasSelectStock = false) => {
  let url = '';
  if (data.stockId) {
    url = `/voucher/salereturn/back/${data.cSaleReturnNo}?stockId=${data.stockId}`;
    if (hasSelectStock) {
      url = `${url}&receiveWarehouse4Ustock=${data.receiveWarehouse4Ustock}&receiveWarehouseName4Ustock=${data.receiveWarehouseName4Ustock}`;
    }
  } else {
    url = `/voucher/salereturn/back/${data.cSaleReturnNo}`;
    if (hasSelectStock) {
      url = `${url}?receiveWarehouse4Ustock=${data.receiveWarehouse4Ustock}&receiveWarehouseName4Ustock=${data.receiveWarehouseName4Ustock}`;
    }
  }
  return ajax.request({
    url,
    data: {
      // data: data,
      // datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
export const getMerchantIdApi = (agent) => {
  return ajax.request({ url: `/orginazation/merchant/agent/${agent}?isArchive=1`, method: 'get' });
};

export const getOldYearSelect = (data) => {
  return ajax.request({
    url: '/voucher/voucherTransfer/voucher/tablesuffix/queryschema',
    data: {
      data: {
        conditions: [{
          name: 'voucherType',
          conditionType: 'eq',
          isDefine: false,
          isOuterField: false,
          valueType: 'STRING',
          v1: data
        }],
        pager: {
          pageIndex: 1,
          pageSize: 100
        }
      },
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
// ERP解锁
export const deblockSaleReturn = (data) => {
  return ajax.request({
    url: `/voucher/salereturn/openLockSaleReturn/${data}?isArchive=1`,
    method: 'get'
  });
};
