export default {
  openDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500006E","开通时间") /* "开通时间" */,
  deadline: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500006F","到期时间") /* "到期时间" */,
  immediatelyActive: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000070","立即激活") /* "立即激活" */,
  useNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000071","使用人数") /* "使用人数" */,
  valueAddService: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000072","增值服务") /* "增值服务" */,
  serviceName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000073","服务名称") /* "服务名称" */,
  activeSuccesslly: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000074","激活成功！") /* "激活成功！" */,
  active: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000075","激活") /* "激活" */,
  activationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000076","激活码") /* "激活码" */,
  hasActivationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000077","已有激活码？") /* "已有激活码？" */,
  hasNodeActivationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000078","没有激活码？") /* "没有激活码？" */,
  pleaseTomarketCloudOrbusiness: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000079","请到云市场或云商务") /* "请到云市场或云商务"" */,
  myOrder: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500007A","我的订单") /* "我的订单" */,
  queryActivationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500007B","中查询激活码。") /* ""中查询激活码。" */,
  getActivationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500007C","，即可获取激活码。") /* ""，即可获取激活码。" */,
  buyProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500007D","购买产品") /* "购买产品" */,
  verificationCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500007E","验证码") /* "验证码" */,
  pleaseInput: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500007F","请输入") /* "请输入" */,
  clickChange: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000080","点击更换") /* "点击更换" */
};
