import accountParentView from '@/views/agent/home/components/accountHome.vue';

const roleTab = {
  path: 'mutiAgentRoleTabs',
  name: 'mutiAgentRoleTabs',
  component: accountParentView,
  redirect: { name: 'mutiAgentAuthMngOfRole' },
  meta: { module: 'mutiAgentRoleTabs' },
  children: [
    {
      path: 'mutiAgentAuthMngOfRole',
      name: 'mutiAgentAuthMngOfRole',
      meta: { module: 'mutiAgentRoleTabs' },
      component: () => import('@/views/agent/accounts/components/roleTab/authMngOfRole.vue')
    },
    {
      path: 'mutiAgentAuthMngOfUser',
      name: 'mutiAgentAuthMngOfUser',
      meta: { module: 'mutiAgentRoleTabs' },
      component: () => import('@/views/agent/accounts/components/roleTab/authMngOfUser.vue')
    }

  ]
};
export default roleTab;
