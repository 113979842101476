
const stockcenter = [
  // 库存列表
  {
    path: 'stocks',
    meta: { module: 'stocks' },
    name: 'stocks',
    component: () => import('@/views/corp/stockcenter/stocks/stocks.vue')
  },
  // 库存详情
  {
    path: 'stock',
    name: 'stock',
    meta: { module: 'stocks', notCache: true },
    component: () => import('@/views/corp/stockcenter/stocks/stock.vue')
  },
  {
    path: 'stockInventorys',
    name: 'stockInventorys',
    meta: {
      module: 'stockInventorys'
    },
    component: () => import('@/views/corp/stockcenter/stockInventorys/stockInventorys.vue')
  },
  {
    path: 'stockInventory',
    name: 'stockInventory',
    meta: {
      module: 'stockInventorys',
      notCache: true
    },
    component: () => import('@/views/corp/stockcenter/stockInventorys/stockInventory.vue')
  },
  // 库存提示设置
  {
    path: 'showStockTipSetting',
    name: 'showStockTipSetting',
    meta: {
      module: 'showStockTipSetting'
    },
    component: () => import('@/views/corp/stockcenter/showStockTipSetting/showStockTipSetting.vue')
  },
  // 库存提示设置详情
  {
    path: 'showStockTipSettingDetail',
    name: 'showStockTipSettingDetail',
    meta: {
      notCache: true,
      module: 'showStockTipSetting'
    },
    component: () => import('@/views/corp/stockcenter/showStockTipSetting/showStockTipSettingDetail.vue')
  }
];

export default stockcenter;
