import { submiters } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const submiterRefer = (ctrl, vm) => {
  const isU8C = vm.currentUser.__isU8C;
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: true,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'fullName',
      queryschema: [
        {
          name: 'person.fullName',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('xingming'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('accountType'),
          isShowIt: false,
          fieldName: 'UserTypeName',
          width: 120
        },
        {
          showCaption: vm.mlang('name'),
          fieldName: 'fullName',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('code'),
          isShowIt: !isU8C,
          width: 180,
          fieldName: 'code'
        },
        {
          showCaption: vm.mlang('loginAccount'),
          isShowIt: true,
          width: 180,
          fieldName: 'userName'
        }
      ],
      actions: { queryList: submiters }
    },
    ctrl
  );
  return deepCopy(control);
};
export default submiterRefer;
