import { financialQuery } from '@/api/agentPayment/payment.js';
import { deepCopy } from '@/u-components/utils/assist';
const agentFinancialRefer = (ctrl, vm) => {
  let userId = vm.currentUser.userId;
  let agentId = vm.currentUser.agentId;
  const control = Object.assign(
    {},
    {
      title: vm.mlang('agentFinancial'),
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      queryschema: [
        {
          name: 'bankName',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('accountOpeningBranch'),
          conditionType: 'like'
        },
        {
          name: 'bankAccount',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('bankNo'),
          conditionType: 'like'
        }

      ],
      passedparams: [{
        name: 'isEnabled',
        v1: true,
        valueType: 'Boolean',
        conditionType: 'eq'
      }, {
        conditionType: 'eq',
        name: 'userId',
        v1: userId,
        valueType: 'LONG'
      }, {
        conditionType: 'eq',
        name: 'agentId',
        v1: agentId,
        valueType: 'LONG'
      }],
      columns: [
        {
          showCaption: vm.mlang('bank'),
          isShowIt: true,
          fieldName: 'bankEntity',
          width: 180,
          render: (h, params) => {
            return h('span', {
              class: 'two-line-overflow-hide'
            }, [
              (params.row[params.column.fieldName]['name']),
              (params.row.isDefault ? h('span', { class: 'default-bank' }, vm.mlang('default')) : '')
            ]);
          }
        },
        {
          showCaption: vm.mlang('accountOpeningBranch'),
          fieldName: 'bankName',
          width: 300,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('bankAccount'),
          isShowIt: true,
          width: 170,
          fieldName: 'bankAccount'
        },
        {
          showCaption: vm.mlang('accountOpeningArea'),
          isShowIt: true,
          width: 170,
          fieldName: 'region'
        },
        {
          showCaption: vm.mlang('operate'),
          fieldName: 'action',
          isShowIt: true,
          width: 200,
          align: 'center',
          fixed: 'suspend',
          render: (h, params) => {
            return params.row.isDefault ? '' : h('span',
              {
                class: 'action-btn',
                style: {
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    vm.setAgentDefault(params.row.id, params.index); // 此接口要自行实现
                  }
                }
              },
              vm.mlang('setDefault')
            );
          }
        }

      ],
      actions: { queryList: financialQuery },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default agentFinancialRefer;
