import corpParentView from '@/views/corp/home/home.vue';

const brands = {
  path: 'pays',
  title: { i18n: 'pays' },
  name: 'pays',
  component: corpParentView,
  children: [
    {
      path: 'coupon',
      name: 'coupon',
      component: () => import('@/views/corp/customer/components/customer/coupon.vue')
    },
    {
      path: 'quickPays', // 快捷支付
      title: { i18n: 'title.quickPays' },
      name: 'quickPays',
      component: () => import('@/components/payments/quickPay/quickPays.vue')
    },
    {
      path: 'quickPay', // 快捷支付
      title: { i18n: 'title.quickPays' },
      name: 'quickPay',
      component: () => import('@/components/payments/quickPay/quickPay.vue')
    },
    {
      path: 'weixinqr',
      name: 'weixinqr',
      component: () => import('@/views/agent/payment/weixinqr.vue')
    },
    {
      path: 'NewAlipayWeb',
      name: 'NewAlipayWeb',
      component: () => import('@/views/agent/payment/NewAlipayWeb.vue')
    },
    {
      path: 'HsyCashier',
      name: 'HsyCashier',
      component: () => import('@/views/agent/payment/HsyCashier.vue')
    },
    {
      path: 'CustomChannel',
      name: 'CustomChannel',
      component: () => import('@/views/agent/payment/CustomChannel.vue')
    }
  ]
};

export default brands;
