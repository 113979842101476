import { getFunctionOptionByCode } from '@/api/bo';
import { getAllOrders, getOrderListBtn, opposeOrder,
  closeOrder, openCheckCredit, openOrder, checkCredit, checkMinQuantity, checkInventory,
  returnOrder, confirmOrder, batchOpposeOrder, rollbackOrderRebate, calcOrderRate,
  computePromotion, getSkuInventorys, deblock, getExceedInventoryOrders, getExceedInventoryDeliverys } from '@/api/order';
import { agentsRefer, agentsReferChanel } from '@/meta/refer-common';
import { batchDelivery } from '@/api/order/order';
import { addPrintCount, getPrintTemplatesByCode } from '@/api/meta';
import _ from 'lodash';
import { deepCopy, oneOf } from '@/u-components/utils/assist';
import util from '@/libs/util';
import dayjs from 'dayjs';
const mixin = {
  data() {
    return {
      dnumberboxChangeTimeMark: null,
      allOrderGroupIndex: null,
      iEntityGiveawayPreId: null,
      iEntityMoneyPreId: null,
      isGetDisIng: null,
      showBtn: {
        isGetDisIng: null
      },
      order: null,
      gc: {},
      dt: {},
      allSkuIds: null,
      oridinalOrder: null,
      a: {
        countTotal: null,
        unitTotal: null,
        auxTotal: null
      },
      totalRebateMoney: 0
    };
  },
  mounted() {},
  computed: {},

  methods: {
    mixin_discount({ fTransactionPrice, fSalePrice }) {
      if (fSalePrice === 0) {
        return '';
      } else {
        const discount = _.round(_.divide(fTransactionPrice, fSalePrice), 4);
        return _.round(_.multiply(discount, 100), 2) + '%';
      }
    },
    mixin_batchDelivery(params, vm, isDetail) {
      const btn = vm.meta.batchBtn.find(v => v.name === 'delivery');
      vm.$Modal.confirm({
        content: vm.mlang('batchDeliveryMsg'),
        onOk: () => {
          btn.disabled = true;
          const orderNos = params.map(v => {
            return v.cOrderNo;
          });
          getExceedInventoryDeliverys(orderNos).then((res) => {
            if (res.isExceed) {
              vm.$Modal.confirm({
                content: res.msg,
                onOk: () => {
                  batchDelivery(orderNos).then((data) => {
                    if (data.success) {
                      vm.$Message.success(vm.mlang('operateSuccess'));
                      vm.broadcast('VoucherList', 'reload');
                      if (vm.$getBO('PCORDERSIMPLEOPERATION') && vm.$getBO('DELIVERYAUTOCONFIRM')) {
                        let selectedIds = [];
                        data.responseWrappers.map((v) => {
                          v.voucherNos && v.voucherNos.length && v.voucherNos.map((c) => {
                            selectedIds.push({
                              cDeliveryNo: c
                            });
                          });
                        });
                        vm.isLoadding = true;
                        vm.$Message.info(vm.mlang('intoPrintTips'));
                        getPrintTemplatesByCode('deliveryPrintHeader').then((res) => {
                          if (res && res.length) {
                            let defaultTem = res.find((v) => {
                              return v.isDefault;
                            });
                            if (defaultTem) {
                              const vos = selectedIds.map(v => {
                                return v.cDeliveryNo;
                              });
                              let printUrl = defaultTem.printViewUrl;
                              let ids = selectedIds.map((v) => {
                                return v.cDeliveryNo;
                              });
                              ids = ids.join(',');
                              var params = ids;
                              printUrl += `&params=${params}`;
                              addPrintCount(vos, 'deliveryPrintHeader').then(() => {
                                this.$open(printUrl);
                              });
                            } else {
                              vm.mlang('notHasDefaultPrintContent');
                            }
                          } else {
                            vm.mlang('notHasDefaultPrintContent');
                          }
                        }).finally(() => {
                          vm.isLoadding = false;
                        });
                      }
                    }
                  }).finally(() => {
                    btn.disabled = false;
                  });
                }
              });
            } else {
              batchDelivery(orderNos).then((data) => {
                if (data.success) {
                  vm.$Message.success(vm.mlang('operateSuccess'));
                  vm.broadcast('VoucherList', 'reload');
                  if (vm.$getBO('PCORDERSIMPLEOPERATION') && vm.$getBO('DELIVERYAUTOCONFIRM')) {
                    let selectedIds = [];
                    data.responseWrappers.map((v) => {
                      v.voucherNos && v.voucherNos.length && v.voucherNos.map((c) => {
                        selectedIds.push({
                          cDeliveryNo: c
                        });
                      });
                    });
                    vm.isLoadding = true;
                    vm.$Message.info(vm.mlang('intoPrintTips'));
                    getPrintTemplatesByCode('deliveryPrintHeader').then((res) => {
                      if (res && res.length) {
                        let defaultTem = res.find((v) => {
                          return v.isDefault;
                        });
                        if (defaultTem) {
                          const vos = selectedIds.map(v => {
                            return v.cDeliveryNo;
                          });
                          let printUrl = defaultTem.printViewUrl;
                          let ids = selectedIds.map((v) => {
                            return v.cDeliveryNo;
                          });
                          ids = ids.join(',');
                          var params = ids;
                          printUrl += `&params=${params}`;
                          addPrintCount(vos, 'deliveryPrintHeader').then(() => {
                            this.$open(printUrl);
                          });
                        } else {
                          vm.mlang('notHasDefaultPrintContent');
                        }
                      } else {
                        vm.mlang('notHasDefaultPrintContent');
                      }
                    }).finally(() => {
                      vm.isLoadding = false;
                    });
                  }
                }
              }).finally(() => {
                btn.disabled = false;
              });
            }
          });
        }
      });
    },
    mixin_oppose(params, vm, isDetail) {
      vm.$Modal.prompt({
        content: vm.mlang('opposeMsg'),
        okText: vm.mlang('sure'),
        cancelText: vm.mlang('cancel'),
        tip: vm.mlang('orders.oTip'),
        title: vm.mlang('orders.oTitle'),
        onOk: (res, instance) => {
          const postData = {
            cOrderNo: params.cOrderNo,
            opposeMemo: res
          };
          if (!res) {
            vm.$Message.error(vm.mlang('orders.oRejectReason'));
            return false;
          }

          opposeOrder(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            if (isDetail === true) {
              vm.dispatch('Voucher', 'refresh');
            } else {
              vm.dispatch('VoucherList', 'reload');
            }
            instance.remove();
          });
        }
      });
    },
    mixin_close(params, vm, isDetail) {
      vm.$Modal.prompt({
        content: vm.mlang('opposeMsg'),
        tip: vm.mlang('orders.cTip'),
        okText: vm.mlang('sure'),
        cancelText: vm.mlang('cancel'),
        title: vm.mlang('orders.cTitle'),
        onOk: (res, instance) => {
          const postData = {
            cOrderNo: params.cOrderNo,
            opposeMemo: res
          };
          if (!res) {
            vm.$Message.error(vm.mlang('orders.cCloseReason'));
            return false;
          }
          closeOrder(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            if (isDetail === true) {
              vm.dispatch('Voucher', 'refresh');
            } else {
              vm.dispatch('VoucherList', 'reload');
            }
            instance.remove();
          });
        }
      });
    },
    mixin_openOrder(params, detail, vm, isDetail) {
      const CheckCredit = () => {
        openCheckCredit(params.oAgent.id).then((data) => {
          if (!data.creditCtrl) {
            // 不控制
            open();
          } else {
            const creditworthCode = data.value;
            // 额度
            const careditWorth = !params.oAgent.iCusCreLine ? 0 : params.oAgent.iCusCreLine;
            // 余额
            const remainderCred = !params.oAgent.iCreditValue ? 0 : params.oAgent.iCreditValue;
            // 余额<订单金额 超信用额度，根据业务选项，做相应操作
            const money = !detail ? params.fPayMoney : detail.fSalePayMoney;
            if (remainderCred < money) {
              if (creditworthCode === 'false' || creditworthCode === 'DELIVERYCONTROL') {
                // “不控制”或者“发货严格控制”
                open();
              } else if (creditworthCode === 'true') {
                // “仅订单上提示”
                const msg = vm.mlang('orders.openCheckCreditMsgA') + params.oAgent.cName + vm.mlang('orders.openCheckCreditMsgB') + careditWorth + vm.mlang('orders.openCheckCreditMsgC') + remainderCred + vm.mlang('orders.openCheckCreditMsgD');
                vm.$Modal.confirm({
                  content: msg,
                  onOk: () => {
                    open();
                  }
                });
              } else if (creditworthCode === 'ALLCONTROL' || creditworthCode === 'ORDERCONTROL') {
                // “订单严格控制”或者“全部严格控制”
                vm.$Message.warning(vm.mlang('orders.openCheckCreditMsgA') + params.oAgent.cName + vm.mlang('orders.openCheckCreditMsgB') + careditWorth + vm.mlang('orders.openCheckCreditMsgC') + remainderCred + vm.mlang('orders.openCheckCreditMsgE'));
              } else {
                vm.$Message.warning(vm.mlang('orders.warning'));
              }
            } else {
              open();
            }
          }
        });
      };

      const open = () => {
        const postData = {
          cOrderNo: params.cOrderNo,
          iOrderDetailId: (detail && detail.iOrderDetailId) || null
        };
        openOrder(postData).then((data) => {
          vm.$Message.success(vm.mlang('operateSuccess'));
          if (isDetail === true) {
            vm.dispatch('Voucher', 'refresh');
          } else {
            vm.dispatch('VoucherList', 'reload');
          }
        });
      };
      const msg = !detail ? vm.mlang('orders.openOrderMsgA') : vm.mlang('openOrderMsgB') + params.cOrderNo + vm.mlang('openOrderMsgC');
      vm.$Modal.confirm({
        content: msg,
        onOk: () => {
          CheckCredit();
        }
      });
    },
    mixin_returnOrder(params, vm, isDetail) {
      vm.$Modal.confirm({
        content: vm.mlang('orders.rContentA') + params.cOrderNo + vm.mlang('orders.rContentB'),
        onOk: () => {
          const postData = {
            cOrderNo: params.cOrderNo
          };
          returnOrder(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            if (isDetail === true) {
              vm.dispatch('Voucher', 'refresh');
            } else {
              vm.dispatch('VoucherList', 'reload');
            }
          });
        }
      });
    },
    mixin_sure(params, vm, isDetail) {
      // 校验最小起订量
      const sureCheckMinQuantity = () => {
        checkMinQuantity(params.cOrderNo).then((data) => {
          if (data.isQuanExceed) {
            vm.$Modal.confirm({
              content: data.message + vm.mlang('orders.sContent'),
              onOk: () => {
                // 传入预售标记
                // a.如果业务选项是提示，前端调用getExceedInventoryOrder，根据返回结果isExceed，如果为true,提示对应库存不足信息，是否继续确认，继续确认走orderBatchConfirm逻辑，如果为false,直接走orderBatchConfirm逻辑

                // b.如果业务选项是控制，前端直接调用orderBatchConfirm逻辑
                if (!vm.$getBO('ORDERINVENTORYCHECK')) {
                  sureCheckInventory();
                } else {
                  sureCheckCredit();
                }
              }
            });
          } else {
            // 传入预售标记
            if (!vm.$getBO('ORDERINVENTORYCHECK')) {
              sureCheckInventory();
            } else {
              sureCheckCredit();
            }
          }
        });
      };

      // 先校验客户库存设置，再下单
      const sureCheckInventory = (presaleId) => {
        const postData = {
          cOrderNo: params.cOrderNo,
          cAction: 'CONFIRM',
          // 预售标记跳过库存
          isPreSale: !!presaleId
        };
        checkInventory(postData).then((data) => {
          if (!data) {
            sureCheckCredit();
            return;
          }
          if (data.isControl === 'true' && data.isExceed) {
            // 严格控制下单量不能超过库存量,并且库存已超，做提醒，并且不再提交订单。
            vm.$Modal.warning({
              content: data.message
            });
          } else if (data.isControl === 'false' && data.isExceed) {
            // 能超过库存量,并且库存已超，做提醒，并提交订单
            vm.$Modal.confirm({
              content: data.message,
              onOk: () => {
                sureCheckCredit();
              }
            });
          } else {
            sureCheckCredit(); // 提交订单
          }
        });
      };

      // 检查信用额度
      const sureCheckCredit = () => {
        const postData = {
          iAgentId: params.iAgentId,
          bizId: params.bizId,
          orgId: params.salesOrgId
        };
        checkCredit(postData).then((data) => {
          if (!data.creditCtrl) {
            const msg = vm.mlang('orders.msgA') + params.cOrderNo + vm.mlang('orders.msgB');
            vm.$Modal.confirm({
              content: msg,
              onOk: () => {
                confirmFun(); // 提交订单
              }
            });
            return;
          }
          const creditworthCode = data.value;
          // 额度
          const careditWorth = params.oAgent.iCusCreLine || 0;
          // 余额
          const remainderCred = params.oAgent.iCreditValue || 0;
          // 余额<订单金额 超信用额度，根据业务选项，做相应操作
          if (remainderCred < params.fOrderPayMoney) {
            if (creditworthCode === 'false' || creditworthCode === 'DELIVERYCONTROL') {
              // “不控制”或者“发货严格控制”
              confirmFun(); // 提交订单
            } else if (creditworthCode === 'true') {
              // “仅订单上提示”
              const bmsg = vm.mlang('orders.bMsgA') + careditWorth + vm.mlang('orders.bMsgB') + remainderCred + vm.mlang('orders.bMsgC');
              vm.$Modal.confirm({
                content: bmsg,
                onOk: () => {
                  confirmFun(); // 提交订单
                }
              });
            } else if (creditworthCode === 'ALLCONTROL' || creditworthCode === 'ORDERCONTROL') {
              // “订单严格控制”或者“全部严格控制”
              const amsg = vm.mlang('orders.aMsgA') + careditWorth + vm.mlang('orders.aMsgB') + remainderCred + vm.mlang('orders.aMsgC');
              vm.$Modal.error({
                content: amsg
              });
            } else {
              vm.$Message.warning(vm.mlang('orders.warning'));
            }
          } else {
            confirmFun(); // 提交订单
          }
        });
      };
      // 确认
      const confirmFun = () => {
        const postData = {
          orderNos: [params.cOrderNo]
        };
        confirmOrder(postData).then((data) => {
          // 用来展示价盘错误
          // if (data.__message) {
          //   vm.$Modal.error({
          //     content: data.__message
          //   });
          //   return;
          // }
          vm.$Message.success(vm.mlang('operateSuccess'));
          if (isDetail === true) {
            vm.dispatch('Voucher', 'refresh');
          } else {
            vm.dispatch('VoucherList', 'reload');
          }
        });
      };

      if (params.cNextStatus === 'CONFIRMORDER') {
        const confirmOrderArray = [];
        confirmOrderArray.push(params);
        sureCheckMinQuantity();
      } else {
        vm.$Message.warning(vm.mlang('orders.sWarning'));
      }
    },
    mixin_batchPay: (batchPayArr, vm) => {
      const nos = [];
      const iAgentIds = [];
      let totalMoneys = 0;
      _.forEach(batchPayArr, (v, k) => {
        nos.push(v.cOrderNo);
        totalMoneys = totalMoneys + parseFloat(v.fPayMoney);
        iAgentIds.push(v.iAgentId);
      });
      const uniqAgentids = _.uniq(iAgentIds);
      if (uniqAgentids.length > 1) {
        vm.$Message.error(vm.mlang('batchPayError'));
        return;
      }
      vm.$Modal.confirm({
        title: ' ',
        content: vm.mlang('batchPayContentA') + batchPayArr.length + vm.mlang('batchPayContentB'),
        onOk: () => {
          const href = `/corppage/Payments/addPayment?menuid=receivablesDetail&orderno=${_.join(nos, '|')}`;
          vm.$util.menuClickOldPage(href, vm);
          // vm.$open(href);
        }
      });
    },
    mixin_batchOppose: (opsArr, vm) => {
      vm.$Modal.prompt({
        content: vm.mlang('opposeMsg'),
        okText: vm.mlang('sure'),
        cancelText: vm.mlang('cancel'),
        tip: vm.mlang('oTip'),
        title: vm.mlang('oTitle'),
        onOk: (res, instance) => {
          const nos = [];
          _.forEach(opsArr, (v, k) => {
            nos.push(v.cOrderNo);
          });
          const postData = {};
          postData.orderNos = _.join(nos, ',');
          postData.opposeMemo = res;
          if (!res) {
            vm.$Message.error(vm.mlang('oRejectReason'));
            return false;
          }
          batchOpposeOrder(postData).then((data) => {
            vm.$Message.success(vm.mlang('batchOpposeSuccessA') + data.total + vm.mlang('batchOpposeSuccessB') + data.success + vm.mlang('batchOpposeSuccessC'));
            vm.broadcast('VoucherList', 'reload');
            instance.remove();
          });
        }
      });
    },
    mixin_batchConfrim: (confirmOrderArray, vm) => {
      const nos = [];
      confirmOrderArray.map((v) => {
        nos.push(v.cOrderNo);
      });
      const confirmFun = () => {
        const postData = {
          orderNos: nos
        };
        confirmOrder(postData).then((res) => {
          // 用来展示价盘错误
          // if (res.__message) {
          //   vm.$Modal.error({
          //     content: res.__message
          //   });
          //   return;
          // }
          vm.$Message.success(vm.mlang('success'));
          vm.broadcast('VoucherList', 'reload');
        });
      };

      const confrimTip = () => {
        vm.$Modal.confirm({
          title: ' ',
          content: vm.mlang('batchPayContentA') + confirmOrderArray.length + vm.mlang('batchConfrimContent'),
          onOk: () => {
            confirmFun();
          }
        });
      };
      // "code": "true","name": "控制"},{"code": "false","name": "提示"},{"code": "none","name": "停用"}
      // a.如果业务选项是提示，前端调用getExceedInventoryOrders(List<String> orderNos,String cAction),如果为true,提示对应库存不足信息，是否继续确认，
      // 继续确认走orderBatchConfirm逻辑，如果为false,直接走orderBatchConfirm逻辑;
      // b.如果业务选项是控制，前端直接调用orderBatchConfirm逻辑
      const confirm = () => {
        if (vm.$getBO('ORDERINVENTORYCHECK')) {
          confrimTip();
        } else {
          const postData = {
            orderNos: nos,
            cAction: 'CONFIRM'
          };
          getExceedInventoryOrders(postData).then((res) => {
            if (res.isExceed) {
              vm.$Modal.confirm({
                content: res.message,
                onOk: () => {
                  confrimTip();
                }
              });
            } else {
              confrimTip();
            }
          });
        }
      };
      const checkCredit = () => {
        let msg = '';
        let isRemind = false;
        getFunctionOptionByCode('CREDITWORTHREMIND').then((data) => {
          const creditworthCode = data;
          for (let index = 0; index < confirmOrderArray.length; index++) {
            const o = confirmOrderArray[index];
            // 信用额度
            if (!o.oAgent.bCusCreCtl) {
              continue;
            }
            const careditWorth = !o.oAgent.iCusCreLine ? 0 : o.oAgent.iCusCreLine;
            // 余额
            const remainderCred = !o.oAgent.iCreditValue ? 0 : o.oAgent.iCreditValue;

            if (remainderCred < o.fPayMoney) {
              isRemind = true;
              msg = msg + '"' + o.cOrderNo + '"' + vm.mlang('openCheckCreditMsgG') + '"' + o.oAgent.cName + '(' + vm.mlang('code') + o.oAgent.cCode + ')' + vm.mlang('openCheckCreditMsgB') + careditWorth + vm.mlang('openCheckCreditMsgC') + remainderCred + vm.mlang('openCheckCreditMsgF') + (o.fPayMoney - remainderCred) + ';<br/>';
              // msg = msg + '"' + o.cOrderNo + vm.mlang('openCheckCreditMsgB') + careditWorth + vm.mlang('openCheckCreditMsgC') + remainderCred + ';<br/>';
            }
          }
          if (isRemind) {
            if (creditworthCode === 'false' || creditworthCode === 'DELIVERYCONTROL') {
              // “不控制”或者“发货严格控制”
              confirm();
            } else if (creditworthCode === 'true') {
              msg = msg + vm.mlang('confirmCheckCreditMsgA');
              vm.$Modal.confirm({
                title: ' ',
                content: msg,
                onOk: () => {
                  confirm();
                }
              });
            } else if (creditworthCode === 'ALLCONTROL' || creditworthCode === 'ORDERCONTROL') {
              msg = msg + vm.mlang('confirmCheckCreditMsgB');
              vm.$Modal.warning({
                title: ' ',
                content: msg
              });
            }
          } else {
            confirm();
          }
        });
      };

      /**
       * 流程：
       * 1、先校验客户库存量，允许下单后
       * 2、检查信用额度
       * 3、确认
       */
      checkCredit();
    },
    mixin_packageGroup(order) {
      for (let i = 0; i < order.oOrderDetails.length; i++) {
        const detail = order.oOrderDetails[i];
        detail.discount = this.mixin_discount(detail);
        if (!util.isNoValue(detail.cProductAuxUnitName) && detail.iAuxUnitQuantity !== 0) {
          detail.isAuxExist = true;
        }
        const index = order.oOrderDetailGroups.findIndex((v) => {
          return v.index === detail.iGroupIndex;
        });
        if (index < 0) {
          this.$Modal.warning({
            content: '根据分组ID获取分组信息失败！'
          });
          break;
        }
        // 将商品放入对应分组中
        if (util.isNoValue(order.oOrderDetailGroups[index].lsOrderDetails)) {
          order.oOrderDetailGroups[index].lsOrderDetails = [];
        }
        detail.iComPromotionGroupId = order.oOrderDetailGroups[index].iComPromotionGroupId;
        order.oOrderDetailGroups[index].lsOrderDetails.push(detail);
      }

      // 重新整理orderdetail的顺序
      order.oOrderDetails = [];
      order.oOrderDetailGroups.map((group) => {
        group.lsOrderDetails.map((detail, detailIndex) => {
          const cloneGroup = _.cloneDeep(group);
          delete cloneGroup.lsOrderDetails;
          if (detailIndex === 0) {
            detail._renderTr = group.bExistGroup;
          }
          detail.group = cloneGroup;

          order.oOrderDetails.push(detail);
        });
      });
      return order;
    },
    mixin_setPromotionOptionFun(order) {
      const entityPromotionObj = {
        id: 0,
        pName: '不使用'
      };
      for (let findIndex = 0; findIndex < order.oOrderDetailGroups.length; findIndex++) {
        const allOrderGroupItem = order.oOrderDetailGroups[findIndex];
        if (allOrderGroupItem.index === 0) {
          this.allOrderGroupIndex = findIndex;
          order.promotionOptionForPage = allOrderGroupItem;
          break;
        }
      }
      order.promotionOptionForPage = order.promotionOptionForPage || {};
      order.promotionOptionForPage.lsGiveawayPromotions = order.promotionOptionForPage.lsGiveawayPromotions || [];
      order.promotionOptionForPage.lsMoneyPromotions = order.promotionOptionForPage.lsMoneyPromotions || [];
      const has = order.promotionOptionForPage.lsGiveawayPromotions.find((v) => {
        return v.id === entityPromotionObj.id;
      });
      if (has) {
        // 已经添加过不适用,不需要再次添加
        order.promotionOptionForPage.lsGiveawayPromotions.unshift(entityPromotionObj);
        order.promotionOptionForPage.lsMoneyPromotions.unshift(entityPromotionObj);
      }

      this.iEntityGiveawayPreId = order.promotionOptionForPage.iEntityGiveawayPreId || entityPromotionObj.id;
      this.iEntityMoneyPreId = order.promotionOptionForPage.iEntityMoneyPreId || entityPromotionObj.id;
      if (order.promotionOptionForPage.lsGiveawayPromotions.length > 1) {
        order.promotionOptionForPage.iEntityGiveawayPreId = this.iEntityGiveawayPreId;
      }
      if (order.promotionOptionForPage.lsMoneyPromotions.length > 1) {
        order.promotionOptionForPage.iEntityMoneyPreId = this.iEntityMoneyPreId;
      }
      return order;
    },
    mixin_changeTransactionPrice(option, order, orderdetail) {
      console.log('mixin_changeTransactionPrice'); //eslint-disable-line
      orderdetail.fSalePayMoney = util.numMulti(option.value, orderdetail.iQuantity);
      const opt = _.cloneDeep(option);
      opt.key = 'fSalePayMoney';
      this.mixin_changeSalePayMoney(opt, orderdetail);
    },
    mixin_changeSalePayMoney(option, order, orderdetail) {
      console.log('mixin_changeSalePayMoney'); //eslint-disable-line
      orderdetail.fTransactionPrice = util.numDiv(option.value, orderdetail.iQuantity);
      this.specialMoney(order, orderdetail);
      this.computeRebate(order);
    },
    computeRebate(order) {
      rollbackOrderRebate(order).then((res) => {
        this.setVoucherData(res);
      });
    },
    specialMoney(order, orderdetail) {
      let prefTotalMoney = 0; // 优惠总金额  除去特殊优惠
      let specialMoney = 0;
      const productApportions = [];
      orderdetail.oOrderProductApportions.forEach((value) => {
        if (value.cApportionType !== 'PARTICULARLY') {
          productApportions.push(value);
          prefTotalMoney = util.numAdd(prefTotalMoney, value.fApportionMoney);
        } else {
          specialMoney = value.fApportionMoney;
        }
      });
      order.fParticularlyMoney = util.numSub(order.fParticularlyMoney, specialMoney);
      orderdetail.fParticularlyMoney = 0;
      // 商品特殊优惠  ：商品金额-商品分摊-商品金额
      specialMoney = util.numSub(util.numSub(orderdetail.fSaleCost, prefTotalMoney), orderdetail.fSalePayMoney).toFixed(2);
      // if(specialMoney>0) {  支持特殊优惠为负
      orderdetail.fParticularlyMoney = specialMoney;
      if (specialMoney !== 0) {
        productApportions.push({
          fApportionMoney: specialMoney,
          cApportionName: '特殊优惠',
          cApportionType: 'PARTICULARLY',
          cOrderProductType: 'SALE'
        });
      }
      order.fParticularlyMoney = util.numAdd(order.fParticularlyMoney, specialMoney);
      // }
      orderdetail.oOrderProductApportions = productApportions;
    },
    dnumberboxChange() {
      // if (this.dnumberboxChangeTimeMark) {
      //   clearTimeout(this.dnumberboxChangeTimeMark);
      // }
      // this.dnumberboxChangeTimeMark = setTimeout(() => {
      //   util.post(
      //     '/pc/OrderDefineSrv/getOrderDefineFormulaValue',
      //     {
      //       order: $scope.order,
      //       datatype: 'json'
      //     },
      //     function(data) {
      //       $scope.order.oOrderDefine = data.oOrderDefine;
      //       $scope.order.fPayMoney = data.fPayMoney;
      //       angular.forEach($scope.order.oOrderDetails, (v) => {
      //         let theOrderDetail = util.find(data.oOrderDetails, 'idKey', v.idKey);
      //         v.oOrderDetailDefine = theOrderDetail.oOrderDetailDefine;
      //       });
      //       $scope.defineCommon.getDefineDatas($scope);
      //     }
      //   );
      // }, 1000);
    },
    // 修改费用金额
    mixin_callBackRebate(order) {
      this.isGetDisIng = false;
      this.showBtn.isGetDisIng = false;

      this.getDiscount(false, true, true, order);
    },
    getDiscount(modifyRebate, isFromFanLi, isNotClearPromotionOption, order) {
      if (!isNotClearPromotionOption && order.promotionOptionForPage) {
        delete order.promotionOptionForPage.iEntityGiveawayPreId;
        delete order.promotionOptionForPage.iEntityMoneyPreId;
      }

      // 因调接口延时,用户可能将计价数量改为''导致后端解析出错,此时组织调用
      order.oOrderDetails.forEach((item) => {
        if (item.iQuantity === '' || item.iAuxUnitQuantity === '') {
          return false;
        }
      });
      // 判断请求的接口
      const url = isFromFanLi ? calcOrderRate : computePromotion;
      let postData = {
        data: this.order,
        datatype: 'json',
        isArchive: 1,
        loading: true
      };
      url(postData).then((data) => {
        this.setOrder(data);
      });
    },
    setOrder(data) {
      this.order = data;
      this.$emit('refresh-order', this.order);
      this.getProductUsableCount();
      this.countTotals();
      this.setPageData();
    },
    getProductUsableCount() {
      this.gc.SHOWPRODUCTUSABLECOUNT = this.$getBO('SHOWPRODUCTUSABLECOUNT');
      if (this.gc.SHOWPRODUCTUSABLECOUNT) {
        this.gc.OPENSTOCK = this.$getBO('OPENSTOCK');
      }
      if (this.gc.SHOWPRODUCTUSABLECOUNT === true && this.order.cNextStatus === 'CONFIRMORDER') {
        this.allSkuIds = _.uniq(this.order.oOrderDetails.map(_.iteratee('iSKUId')));
        if (!this.allSkuIds.length) return;
        const postData = {
          skuIds: this.allSkuIds,
          datatype: 'json',
          loading: false
        };
        getSkuInventorys(this.order.iAgentId, postData).then((data) => {
          this.dt.skuInventorys = data;
          if (!data) return;
          var skuidQuantity = {};
          this.order.oOrderDetails.map(function(value) {
            if (value.iSKUId) {
              if (skuidQuantity[value.iSKUId] !== undefined) {
                skuidQuantity[value.iSKUId] = _.add(skuidQuantity[value.iSKUId], value.iQuantity);
              } else {
                skuidQuantity[value.iSKUId] = value.iQuantity;
              }
            }
          });
          var skuStockNum = {};

          var OPENSTOCK = this.gc.OPENSTOCK;
          _.map(data, (value, key) => {
            if (!OPENSTOCK) {
              skuStockNum[key] = value[0] && value[0].iInventory;
            } else {
              var sum = 0;
              _.map(value, (v, k) => {
                sum += v.iInventory;
              });
              skuStockNum[key] = sum;
            }
          });
          this.order.oOrderDetails.map(function(value) {
            var skuid = value.iSKUId;
            var storeNum = skuStockNum[skuid];
            var thisSkuidAllQuaility = skuidQuantity[skuid];
            value.sku_stocks = data[skuid];
            value.storeNum = storeNum;
            value.storeColor = thisSkuidAllQuaility > storeNum ? '#F00' : '#666';
          });
          this.setOriOrder();
        });
      }
    },
    setOriOrder() {
      setTimeout(() => {
        this.oridinalOrder = Object.assign({}, this.order);
      }, 1000);
    },
    countTotals() {
      this.a.countTotal = this.order.oOrderDetails.length;
      this.a.unitTotal = 0;
      this.a.auxTotal = 0;
      let maxUnitPrecision = 0;
      let maxAuxUnitPrecision = 0;
      this.order.oOrderDetails.forEach((detail) => {
        var mainUnit = (detail.oSKU.oProduct && detail.oSKU.oProduct.oUnit && detail.oSKU.oProduct.oUnit.unitPrecision) || 0;
        var auxUnit = (detail.oSKU.oProductAuxUnit && detail.oSKU.oProductAuxUnit.oUnit && detail.oSKU.oProductAuxUnit.oUnit.unitPrecision) || 0;
        if (maxUnitPrecision < mainUnit) {
          maxUnitPrecision = mainUnit;
        }
        if (maxAuxUnitPrecision < auxUnit) {
          maxAuxUnitPrecision = auxUnit;
        }
      });
      this.order.oOrderDetails.forEach((detail) => {
        var iQuantity = detail.iQuantity || 0;
        var iAuxUnitQuantity = detail.iAuxUnitQuantity || 0;
        this.a.unitTotal = _.round(util.numAdd(this.a.unitTotal, iQuantity), maxUnitPrecision);
        this.a.auxTotal = _.round(util.numAdd(this.a.auxTotal, iAuxUnitQuantity), maxAuxUnitPrecision);
      });
    },
    setPageData() {
      this.totalRebateMoney = util.numAdd(this.order.fRebateMoney, this.order.fRebateCashMoney);
    },
    // 整单赠品
    mixin_selectGiveawayCallBack(group, selectedGroupIndex) {
      this.order.oOrderDetailGroups[selectedGroupIndex] = group;
      this.getReload(false);
    },
    getReload(modifyRebate) {
      // let disTimeMark = null;
      // this.isGetDisIng = true;
      // if (disTimeMark) {
      //   clearTimeout(disTimeMark);
      // }
      // disTimeMark = setTimeout(function() {
      //   this.getDiscount(modifyRebate);
      // }, 300);
    },
    // 销售订单、商家订单、单据清理获取列表数据
    mixin_orderQueryList(query) {
      if (this.dealOldYear(query) === false) return;
      query.datatype = 'json';
      delete query.querySchema.fields;
      delete query.querySchema.orders;
      query.querySchema.conditions.push({
        name: 'isOtherFranchiser',
        v1: this.mixin_isPlatForm(),
        valueType: 'BOOLEAN',
        conditionType: 'eq'
      });
      // 企业端订货端一个接口，后端要求销售订单必须上送
      query.querySchema.conditions.push({
        name: 'isPresell',
        v1: false,
        valueType: 'BOOLEAN',
        conditionType: 'eq'
      });
      let cloneQuery = _.cloneDeep(query);
      query.querySchema.conditions.forEach((v) => {
        if (v.name === 'orderDate') {
          if (v.v1 && !v.v2) {
            let es = dayjs(v.v1).add(6, 'month').format('YYYY-MM-DD');
            v.v2 = `${es} 23:59:59`;
          } else if (v.v2 && !v.v1) {
            let es = dayjs(v.v2).subtract(6, 'month').format('YYYY-MM-DD');
            v.v1 = `${es} 00:00:00`;
          }
        }
        if (v.name === 'isAgentSubmit') {
          v.v1 = v.v1 === 'true';
        }
      });
      getAllOrders(query, this.isHistory).then((res) => {
        if (!this.isHistory) { this.afterListSearch(cloneQuery, 'orderDate', true); };
        const orderIds = [];
        res.total = res.count || res.totalElements;
        res.orders = res.orders || res.content;
        const menuid = this.mixin_isPlatForm() ? 'merchantorder' : 'orderList';
        res.orders.map((v) => {
          let cOrderNoHref = `/corppage/Orders/detail?routeModule=orderList&menuid=${menuid}&cOrderNo=${v.cOrderNo}`;
          if (this.mixin_routers() === 'platform') {
            cOrderNoHref += '&editable=0&isMerchantOrder=1';
          }
          if (this.isHistory) {
            let to = {};
            to = {
              name: 'orderHistory',
              query: { id: v.id, showOldYear: true, cOrderNo: v.cOrderNo }
            };
            v.cOrderNoTo = to;

            // cOrderNoHref += `&showOldYear=true&id=${v.id}`;
          }
          cOrderNoHref += '#/detailOrder';
          v.cOrderNoHref = cOrderNoHref;
          v.isShowPayBtn = false;
          v.cOrderNoTitle = this.mlang('orderDetail');
          orderIds.push(v.id);
        });
        res.content = res.orders;
        this.data = res;
        if (!this.isHistory) {
          getOrderListBtn(orderIds).then((btnArr) => {
            res.orders.map((v, k) => {
              let btnRes = btnArr.find((j) => {
                return j.voucherNo === v.cOrderNo;
              });
              if (btnRes) {
                v.isShowOpenDetail = btnRes.isShowOpenDetail; // 打开
                v.isShowModifyBtn = btnRes.isShowModifyBtn; // 修改
                v.isShowDelBtn = btnRes.isShowDeletedBtn; // 删除
                v.isShowSaleReturnBtn = btnRes.isShowSaleReturnBtn; // 退货
                v.isShowTakeDeliveryBtn = btnRes.isShowTakeDeliveryBtn; // 收货确认
                v.isCopyOrder = btnRes.isShowCopyBtn; // 再次购买
                v.isShowCommentBtn = btnRes.isShowCommentBtn; // 评价
                v.isShowClosedDetail = btnRes.isShowClosedDetail; // 关闭
                v.isShowOrderRollBack = btnRes.isShowOrderRollBack; // 回退
                v.isShowPayBtn = btnRes.isShowPayBtn; // 付款
                v.isShowConfirmBtn = btnRes.isShowConfirmBtn; // 确认
                v.isShowOpposeBtn = btnRes.isShowOpposeBtn; // 驳回
                v.isShowCreateDeliveryBtn = btnRes.isShowCreateDeliveryBtn; // 创建发货单
                v.isShowAgentUserSubmitBtn = btnRes.isShowAgentUserSubmitBtn; // 提交
                v.isShowOrderUnlockBtn = btnRes.isShowOrderUnlockBtn;// 解锁
                v.downloadEInvoiceBtn = btnRes.downloadEInvoiceBtn; // 电子发票
              }
            });
            res.totalCount = res.count;
            res.data = res.orders;
            this.data = res;
          });
        }
      });
    },
    // 客户参照
    mixin_agentReferAction(filterMeta) {
      const ref = this.mixin_isPlatForm() ? agentsReferChanel : agentsRefer;
      if (this.currentUser.__isBiz && this.mixin_isPlatForm()) {
        let meta = {
          queryschema: [
            {
              name: 'value',
              controlType: 'String',
              groupType: 2,
              valueType: 'STRING',
              isShowIt: true,
              placeholder: this.mlang('productNameOrCode') + '/' + this.mlang('bizAgentErpCode'),
              conditionType: 'like'
            }
          ],
          passedparams: [
            {
              name: 'erpCodeInBiz',
              isSkip: false,
              isOuterField: false,
              valueType: 'STRING',
              conditionType: 'like',
              conditionGroup: {
                key: 1,
                isAndLogic: false
              }
            }
          ]
        };
        Object.assign(filterMeta, meta);
      }
      let mt = ref(filterMeta, this);
      if (this.doAgentReferAction) { // 业务影响
        mt = this.doAgentReferAction(mt);
      }
      return mt;
    },
    // 设置查询参数
    mixin_setVoucherListQuerySchema(queryschema = []) {
      const normalGroup = queryschema.find((v) => {
        return v.groupType === 2;
      });
      const rowGroup = queryschema.find((v) => {
        return v.groupType === 1;
      });
      let orderArr = ['iPrdouctClassId', 'iAgentLevelId', 'iAgentIndustryId', 'iAgentAreaId', 'bizId', 'promotionType'];
      let deliveryArr = ['agentCategoryId', 'agentGroupId', 'bizId'];
      let saleReturnArr = ['agentCategoryId', 'agentAreaId', 'bizId'];
      rowGroup &&
        rowGroup.currentFilterItems.map((filter) => {
          if (this.mixin_isPlatForm()) {
            if (filter.name === 'orgId') {
              filter.caption = this.mlang('belongOrg');
            }
          }
          if (filter.name === 'oldYear') {
            filter.defaultValue = null;
            filter.enumString = null;
            if (this.$route.query.type !== 'history') {
              filter.isHidden = true;
            } else {
              filter.isShowIt = true;
            }
          }
        });
      normalGroup &&
        normalGroup.currentFilterItems.map((filter) => {
          if (this.mixin_routers() === 'sale') {
            if (filter.name === 'bizId') {
              filter.isHidden = true;
            }
          }
          if (this.mixin_isPlatForm()) {
            if (filter.name === 'stockId') {
              filter.isHidden = true;
            }
          }
          if (filter.name === 'shippingChoiceCode') {
            filter.valueKey = 'cCode';
          }
          if (this.$route.query.type === 'history' && ((orderArr.includes(filter.name) && filter.sourceBillId === 3) || (deliveryArr.includes(filter.name) && filter.sourceBillId === 4) || (saleReturnArr.includes(filter.name) && filter.sourceBillId === 5))) {
            filter.isHidden = true;
          }
        });
      return queryschema;
    },
    // 订单时间的默认值
    mixin_setOrderDateDefaultVal(meta) {
      if (!meta) return;
      if (!meta.defaultValue) meta.defaultValue = {};
      if (this.mixin_routers() === 'sale') meta.defaultValue.orderDate = '-3';
    },
    // 订单不同页面显示不同列
    mixin_ordersSetListColumns(columns) {
      columns.map((v) => {
        if (this.mixin_routers() === 'sale') {
          if (v.fieldName === 'bizName') {
            v.isHidden = true;
          }
        }
        if (this.mixin_routers() === 'platform') {
          if (oneOf(v.fieldName, ['salesOrgName', 'settlementOrgName', 'action'])) {
            v.isHidden = true;
          }
        }
        if (this.mixin_routers() === 'extension') {
          const hideColumns = ['action', 'bizName', 'salesOrgName', 'settlementOrgName', 'erpNo', 'cSettlementName', 'dOrderDate', 'dConfirmDate', 'fRealMoney'];
          if (oneOf(v.fieldName, hideColumns)) {
            v.isHidden = true;
          }
        }
      });
      return columns;
    },
    // 订单列表导出前过滤
    mixin_ordersBeforeExport(query) {
      // const type = this.mixin_isPlatForm();
      query.querySchema.conditions.push({
        conditionType: 'eq',
        isDefine: false,
        isDetail: undefined,
        name: 'isOtherFranchiser',
        v1: this.mixin_isPlatForm(),
        valueType: 'BOOLEAN'
      });
      query.querySchema.conditions.map((v) => {
        if (v.name === 'isHaveDelivery') {
          v.valueType = 'BOOLEAN';
          query.querySchema.conditions.push({
            conditionType: 'eq',
            isDefine: false,
            isOuterField: false,
            name: 'statusCode',
            v1: 'ENDORDER',
            valueType: 'STRING'
          });
        };
        if (v.name === 'isAgentSubmit') {
          v.v1 = v.v1 === 'true';
        }
      });
      return query;
    },
    // 解锁
    mixin_deblockOrder(params, vm) {
      let postData = {
        cOrderNo: params.cOrderNo
      };
      vm.$Modal.confirm({
        title: '',
        content: this.mlang('deblockTip'),
        onOk: () => {
          deblock(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    }
  }
};

export default mixin;
