export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E14","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E15","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E16","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E17","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E18","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E19","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E1A","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E1B","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E1C","停用") /* "停用" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E1D","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E1E","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E1F","查看执行情况") /* "查看执行情况" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E20","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  handleBatchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E21","没有权限操作该平台的促销") /* "没有权限操作该平台的促销" */,
  opposeSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000E22","驳回成功") /* "驳回成功" */
};
