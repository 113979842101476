<template>
  <div class="width100">
    <Form
      ref="form-meta"
      :model="currentVM"
      :label-width="100"
      label-position="right">
      <template v-for="control in controls">
        <FormItem
          :prop="control.fieldName"
          :rules="rule(control)"
          :label="control.showCaption+':'"
          class="archive-item">
          <RadioGroup
            v-if="control.controlType === 'Radio'"
            v-model="currentVM[control.fieldName]">
            <template v-for="item in control.enumString">
              <Radio :label="item.value">
                {{ item.title }}
              </Radio>
            </template>
          </RadioGroup>
          <CheckboxGroup
            v-if="control.controlType === 'Checkbox'"
            v-model="currentVM[control.fieldName]">
            <template v-for="item in control.enumString">
              <Checkbox :label="item.value">
                {{ item.title }}
              </Checkbox>
            </template>
          </CheckboxGroup>
          <Input
            v-if="control.controlType === 'String'"
            v-model="currentVM[control.fieldName]"
            :disabled="control.disabled"
            type="text"></Input>
          <InputNumber
            v-if="control.controlType === 'Number'"
            v-model="currentVM[control.fieldName]"
            :max="control.max"
            :min="control.min"
            :precision="control.numPoint"
            class="ivu-input-wrapper"
            type="text">
          </InputNumber>
        </formitem>
      </template>
    </Form>
  </div>
</template>
<script>
import { deepCopy } from '@/u-components/utils/assist';
// import ImgUpload from './img-upload-one';
import _ from 'lodash';
export default {
  name: 'FormMeta',
  components: {
    // ImgUpload
  },
  mixins: [],
  props: {
    bizMeta: {
      required: true,
      type: Object
    },
    controls: {
      type: Array,
      default: []
    },
    editData: {
      type: Object,
      default: {}
    }
  },
  data() {
    const vm = this.makeData();
    return {
      currentVM: vm
    };
  },
  computed: {
    cloneEditRows() {
      return deepCopy(this.control.editRows).filter((v) => {
        return v.bEnable !== false;
      });
    }
  },
  watch: {
    editData: {
      handler(v) {
        this.currentVM = { ...this.currentVM, ...v };
      },
      deep: true
    }
  },
  mounted() {},
  created() {},
  methods: {
    rule(control) {
      const rule = {
        trigger: 'blur'
      };
      if (control.isRequired) {
        rule.required = true;
        rule.message = this.mlang('notNull');
      }
      if (control.controlType === 'Number') {
        rule.type = 'number';
      }
      return rule;
    },
    makeData() {
      const editRows = this.controls;
      const currentVM = {};
      editRows.forEach((row) => {
        const key = row.fieldName;
        currentVM[key] = _.isUndefined(row.defaultValue) ? null : row.defaultValue;
      });
      return currentVM;
    },
    setVm(data) {
      this.currentVM = Object.assign({}, this.currentVM, data);
    },
    handleSelectTabSelect(val, fieldName) {
      // setTimeout(() => {
      //   this.$refs['form-meta'].validateField(fieldName)
      // }, 0);
    }
  }
};
</script>
