
const creditManagement = [
  // 信用检查规则列表
  {
    path: 'creditRules',
    name: 'creditRules',
    meta: {
      module: 'creditRules'
    },
    component: () => import('@/views/corp/creditManagement/creditRules/creditRules.vue')
  },
  // 信用检查规则详情
  {
    path: 'creditRule',
    name: 'creditRule',
    meta: {
      module: 'creditRules',
      notCache: true
    },
    component: () => import('@/views/corp/creditManagement/creditRules/creditRule.vue')
  },
  {
    path: 'temporaryCredits',
    name: 'temporaryCredits',
    meta: {
      module: 'temporaryCredits'
    },
    component: () => import('@/views/corp/creditManagement/temporaryCredit/temporaryCredits.vue')
  },
  {
    path: 'temporaryCredit',
    name: 'temporaryCredit',
    meta: {
      module: 'temporaryCredits',
      notCache: true
    },
    component: () => import('@/views/corp/creditManagement/temporaryCredit/temporaryCredit.vue')
  },
  {
    path: 'objectCredits',
    name: 'objectCredits',
    component: () => import('@/views/corp/creditManagement/components/objectCredit/objectCredits.vue')
  },
  {
    path: 'objectCredit',
    name: 'objectCredit',
    meta: {
      module: 'objectCredits',
      notCache: true
    },
    component: () => import('@/views/corp/creditManagement/components/objectCredit/objectCredit.vue')
  },
  {
    path: 'creditCalculation',
    name: 'creditCalculation',
    meta: {
      module: 'creditCalculation'
    },
    component: () => import('@/views/corp/creditManagement/components/creditCalculation/creditCalculation.vue')
  },
  {
    path: 'creditConsumption',
    name: 'creditConsumption',
    meta: {
      module: 'creditConsumption'
    },
    component: () => import('@/views/corp/creditManagement/components/creditConsumption/creditConsumption.vue')
  },
  {
    path: 'creditConsumptionTest',
    name: 'creditConsumptionTest',
    meta: {
      module: 'creditConsumptionTest'
    },
    component: () => import('@/views/corp/creditManagement/components/creditConsumption/creditConsumptionTest.vue')
  },
  {
    path: 'agentCreditsArchives',
    name: 'agentCreditsArchives',
    meta: {
      module: 'agentCreditsArchives',
      notCache: true
    },
    component: () => import('@/views/corp/creditManagement/agentCreditsArchives/agentCreditsArchives.vue')
  },
  {
    path: 'creditConsumptionBill',
    name: 'creditConsumptionBill',
    meta: {
      module: 'creditConsumptionBill'
    },
    component: () => import('@/views/corp/creditManagement/components/creditConsumption/creditConsumptionBill.vue')
  }
];
export default creditManagement;
