export default {
  defaultAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000734","默认权限") /* "默认权限" */,
  addSeparateGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000735","新增分单组") /* "新增分单组" */,
  splitMoment: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000736","分单时机") /* "分单时机" */,
  splitMoment1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000737","触发确认时先拆单再确认") /* "触发确认时先拆单再确认" */,
  splitMoment2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000738","触发确认时先确认再拆单") /* "触发确认时先确认再拆单" */,
  splitMoment3: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000739","触发提交时先提交再拆单") /* "触发提交时先提交再拆单" */,
  separatePromotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500073A","促销处理规则") /* "促销处理规则" */,
  separatePromotionType1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500073B","维持分单前促销不变") /* "维持分单前促销不变" */,
  separatePromotionType2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500073C","分单后，各新单重新计算促销") /* "分单后，各新单重新计算促销" */,
  demesion: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500073D","分单维度") /* "分单维度" */,
  stockSplit: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500073E","存量处理规则") /* "存量处理规则" */,
  stockSplit1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500073F","有货一单无货一单") /* "有货一单无货一单" */,
  fendangroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000740","分单分组") /* "分单分组" */,
  fendanzumingcheng: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000741","分单组名称") /* "分单组名称" */,
  addProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000742","新增商品") /* "新增商品" */,
  demesionClickContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000743","切换维度将清空所有的分单规则，确认切换么？") /* "切换维度将清空所有的分单规则，确认切换么？" */,
  nameNullMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000744","分单分组名称未填写!") /* "分单分组名称未填写!" */
};
