<template>
  <div :class="['vue-tel-input', wrapperClasses, { disabled: disabledDial }]">
    <div
      v-click-outside="clickedOutside"
      :class="['vti__dropdown', { open: open }]"
      @keydown="keyboardNav"
      @click="toggleDropdown"
      @keydown.esc="reset">
      <span class="vti__selection">
        <div v-if="enabledFlags" :class="['vti__flag', activeCountry.iso2]">
          <span class="vti__countryName">+{{ activeCountry.dialCode }}</span>
          <slot name="arrow-icon" :open="open">
            <Icon
              type="ios-arrow-down" />
          </slot>
        </div>
      </span>
      <ul v-show="open" ref="list" class="vti__dropdown-list">
        <li
          v-for="(pb, index) in sortedCountries"
          :key="pb.iso2 + (pb.preferred ? '-preferred' : '')"
          :class="['vti__dropdown-item', getItemClass(index, pb.iso2), {'selected': pb.isSelected}]"
          @click="choose(pb, true)"
          @mousemove="selectedIndex = index">
          <div v-if="enabledFlags" :class="['vti__flag', pb.iso2]" />
          <SvgIcon v-if="pb.isSelected" icon-class="duigou" class-name="duigou" />
          <span v-else class="noduigou"></span>
          <span v-if="!disabledDialCode">
            +{{ pb.dialCode }}
          </span>
          <span>{{ pb.name }}</span>
        </li>
      </ul>
    </div>
    <!-- <span class="vti__country-code">
      +{{ activeCountry.dialCode }}
    </span> -->
    <input
      :id="inputId"
      ref="input"
      v-model="phone"
      type="tel"
      :placeholder="parsedPlaceholder"
      :disabled="disabledInput"
      :required="required"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :style="disabledStyle"
      :name="name"
      :class="['vti__input', inputClasses]"
      :maxlength="maxLen"
      :tabindex="inputOptions && inputOptions.tabindex ? inputOptions.tabindex : 0"
      @blur="onBlur"
      @input="onInput"
      @keyup.enter="onEnter"
      @keyup.space="onSpace"
      @change="onChange"
      @keydown="onKeyDown" />
  </div>
</template>

<script>
import PhoneNumber from 'awesome-phonenumber';
import { getCountry } from '@/api/phone';
import Emitter from '@/u-components/mixins/emitter';
// import SvgIcon from '@/u-components/components/svg-icon';

// Polyfill for Event.path in IE 11: https://stackoverflow.com/a/46093727
function getParents(node, memo) {
  const parsedMemo = memo || [];
  const { parentNode } = node;

  if (!parentNode) {
    return parsedMemo;
  }

  return getParents(parentNode, parsedMemo.concat(parentNode));
}

function setCaretPosition(ctrl, pos) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

  // IE8 and below
  } else if (ctrl.createTextRange) {
    const range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}

export default {
  name: 'VueTelInput',
  directives: {
    // Click-outside by BosNaufal: https://github.com/BosNaufal/vue-click-outside
    'click-outside': {
      bind(el, binding, vNode) {
        // Provided expression must evaluate to a function.
        // if (typeof binding.value !== 'function') {
        //   const compName = vNode.context.name;
        //   let warn = `[Vue-click-outside:] provided expression ${binding.expression} is not a function, but has to be`;
        //   if (compName) {
        //     warn += `Found in component ${compName}`;
        //   }
        //   console.warn(warn);
        // }
        // Define Handler and cache it on the element
        const { bubble } = binding.modifiers;
        const handler = (e) => {
          // Fall back to composedPath if e.path is undefined
          const path = e.path ||
            (e.composedPath ? e.composedPath() : false) ||
            getParents(e.target);
          if (bubble || (path.length && !el.contains(path[0]) && el !== path[0])) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        // add Event Listeners
        document.addEventListener('click', handler);
      },
      unbind(el) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      }
    }
  },
  mixins: [Emitter],
  props: {
    disabledDialCode: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    disabledFetchingCountry: {
      type: Boolean,
      default: false
    },
    disabledDial: {
      type: Boolean,
      default: false
    },
    disabledInput: {
      type: Boolean,
      default: false
    },
    disabledFormatting: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: ''
    },
    invalidMsg: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    enabledFlags: {
      type: Boolean,
      default: true
    },
    preferredCountries: {
      type: Array,
      default: () => []
    },
    onlyCountries: {
      type: Array,
      default: () => []
    },
    ignoredCountries: {
      type: Array,
      default: () => []
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'telephone'
    },
    wrapperClasses: {
      type: [String, Array, Object],
      default: ''
    },
    inputClasses: {
      type: [String, Array, Object],
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    dropdownOptions: {
      type: Object,
      default: () => {}
    },
    inputOptions: {
      type: Object,
      default: () => {}
    },
    maxLen: {
      type: Number,
      default: 25
    },
    validCharactersOnly: {
      type: Boolean,
      default: false
    },
    dynamicPlaceholder: {
      type: Boolean,
      default: false
    },
    defaultCountry: {
      type: String
    }
  },
  data() {
    return {
      phone: '',
      activeCountry: { iso2: '' },
      open: false,
      finishMounted: false,
      selectedIndex: null,
      typeToFindInput: '',
      typeToFindTimer: null,
      cursorPosition: 0,
      allCountries: []
    };
  },
  computed: {
    disabledStyle() {
      if (this.disabledInput) {
        return {
          background: '#f3f3f3'
        };
      } else {
        return {};
      }
    },
    parsedPlaceholder() {
      if (!this.finishMounted) {
        return '';
      }
      if (this.dynamicPlaceholder) {
        const mode = this.mode || 'international';
        return PhoneNumber.getExample(this.activeCountry.iso2, 'mobile').getNumber(mode);
      }
      return this.placeholder;
    },
    parsedMode() {
      if (this.mode) {
        if (['e164', 'significant'].includes(this.mode)) {
          return this.mode;
        }
      }

      if (!this.phone || this.phone[0] !== '+') {
        return 'significant';
      }
      return 'e164';
    },
    filteredCountries() {
      // List countries after filtered
      if (this.onlyCountries.length) {
        return this.getCountries(this.onlyCountries);
      }

      if (this.ignoredCountries.length) {
        return this.allCountries.filter(
          ({ iso2 }) => !this.ignoredCountries.includes(iso2) &&
            !this.ignoredCountries.includes(iso2)
        );
      }

      return this.allCountries;
    },
    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(this.preferredCountries)
        .map(country => ({ ...country, preferred: true }));

      return [...preferredCountries, ...this.filteredCountries];
    },
    phoneObject() {
      const result = PhoneNumber(this.phone, this.activeCountry.iso2).toJSON();
      Object.assign(result, {
        isValid: result.valid,
        country: this.activeCountry
      });
      return result;
    },
    phoneText() {
      let key = 'input';
      if (this.phoneObject.valid) {
        key = this.parsedMode;
      }
      return this.phoneObject.number[key] || '';
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'phoneObject.valid': function(value) {
      if (value) {
        this.phone = this.phoneText;
      }
      this.$emit('validate', this.phoneObject);
      this.$emit('onValidate', this.phoneObject); // Deprecated
    },
    value() {
      this.phone = this.value;
    },
    open(isDropdownOpened) {
      // Emit open and close events
      if (isDropdownOpened) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
    phone(newValue, oldValue) {
      if (this.validCharactersOnly && !this.testCharacters(this.activeCountry)) {
        this.$nextTick(() => { this.phone = oldValue; });
      } else if (newValue) {
        if (newValue[0] === '+') {
          const code = PhoneNumber(newValue).getRegionCode();
          if (code) {
            this.activeCountry = this.findCountry(code) || this.activeCountry;
          }
        }
      }
      // Reset the cursor to current position if it's not the last character.

      if (oldValue && this.cursorPosition < oldValue.length) {
        this.$nextTick(() => { setCaretPosition(this.$refs.input, this.cursorPosition); });
      }
    },
    activeCountry(value) {
      if (value && value.iso2) {
        this.$emit('country-changed', value);
      }
    },
    defaultCountry(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.activeCountry = this.findCountry(newValue);
      }
    }
  },
  mounted() {
    this.initializeCountry()
      .then(() => {
        if (!this.phone &&
          this.inputOptions &&
          this.inputOptions.showDialCode &&
          this.activeCountry.dialCode) {
          this.phone = `+${this.activeCountry.dialCode}`;
        }
        this.$emit('validate', this.phoneObject);
        this.$emit('onValidate', this.phoneObject); // Deprecated
      })
      // .catch(console.error)
      .finally(() => {
        this.finishMounted = true;
      });
  },
  created() {
    if (this.value) {
      this.phone = this.value.trim();
    }
  },
  methods: {
    initializeCountry() {
      return new Promise((resolve) => {
        /**
         * 1. If the phone included prefix (+12), try to get the country and set it
         */

        if (this.phone && this.phone[0] === '+') {
          const activeCountry = PhoneNumber(this.phone).getRegionCode();
          if (activeCountry) {
            this.choose(activeCountry);
            resolve();
            return;
          }
        }
        /**
         * 2. Use default country if passed from parent
         */
        // if (this.defaultCountry) {
        //   const defaultCountry = this.findCountry(this.defaultCountry);
        //   if (defaultCountry) {
        //     this.choose(defaultCountry);
        //     resolve();
        //     return;
        //   }
        // }
        const fallbackCountry = this.findCountry(this.preferredCountries[0]) ||
          this.filteredCountries[0];
        /**
         * 3. Check if fetching country based on user's IP is allowed, set it as the default country
         */
        if (!this.disabledFetchingCountry) {
          getCountry()
            .then((res) => {
              const data = res && res.data;
              this.allCountries = data && data.map(country => ({
                name: country.name, // 区号名称
                iso2: country.code, // 国家名简写，现在是区号编码
                dialCode: country.code, // 区号编码
                pattern: country.pattern, // 正则
                isDefault: country.isDefault, // 是否默认
                isSelected: country.isDefault, // 当前选择的区号
                viewName: country.viewName // 区号名称
              }));
              let activeCountry;
              let sysDefaultCountry = this.allCountries.find(country => country.isDefault);
              if (this.defaultCountry) {
                activeCountry = this.allCountries.find(country => country.dialCode === this.defaultCountry);
              } else {
                activeCountry = sysDefaultCountry;
              }
              if (localStorage.activeCountry) {
                activeCountry = JSON.parse(localStorage.getItem('activeCountry'));
              }
              this.activeCountry = activeCountry || this.allCountries[0];
            })
            .catch(() => {
              // console.warn(error);
              /**
               * 4. Use the first country from preferred list (if available) or all countries list
               */
              this.choose(fallbackCountry);
            })
            .finally(() => {
              resolve();
            });
        } else {
          /**
           * 4. Use the first country from preferred list (if available) or all countries list
           */
          this.choose(fallbackCountry);
          resolve();
        }
      });
    },
    inputIcon() {
      let icon = '';
      if (this.clearable && this.value) {
        icon = 'ios-close';
      } else if (this.icon) {
        icon = this.icon;
      }
      return icon;
    },
    /**
     * Get the list of countries from the list of iso2 code
     */
    getCountries(list = []) {
      return list
        .map(countryCode => this.findCountry(countryCode))
        .filter(Boolean);
    },
    findCountry(iso = '') {
      return this.allCountries.find(country => country.iso2 === iso);
    },
    getItemClass(index, iso2) {
      const highlighted = this.selectedIndex === index;
      const lastPreferred = index === this.preferredCountries.length - 1;
      const preferred = this.preferredCountries.some(c => c === iso2);
      return {
        highlighted,
        'last-preferred': lastPreferred,
        preferred
      };
    },
    choose(country, toEmitInputEvent = false) {
      this.activeCountry.isSelected = false;
      country.isSelected = true;
      let parsedCountry = country;
      if (typeof parsedCountry === 'string') {
        parsedCountry = this.findCountry(parsedCountry);
      }
      if (!parsedCountry) {
        return;
      }
      this.activeCountry = parsedCountry || this.activeCountry || {};
      localStorage.setItem('activeCountry', JSON.stringify(this.activeCountry));
      if (this.phone &&
        this.phone[0] === '+' &&
        this.activeCountry.iso2 &&
        this.phoneObject.number.national) {
        // Attach the current phone number with the newly selected country
        this.phone = PhoneNumber(this.phoneObject.number.national, this.activeCountry.iso2)
          .getNumber('international');
      } else if (this.inputOptions && this.inputOptions.showDialCode && parsedCountry) {
        // Reset phone if the showDialCode is set
        this.phone = `+${parsedCountry.dialCode}`;
      }
      if (toEmitInputEvent) {
        this.$emit('input', this.phoneText, this.phoneObject);
      }
    },
    testCharacters(activeCountry) {
      const re = /^[0-9]*$/;
      return re.test(this.phone);
    },
    onInput(e) {
      if (this.validCharactersOnly && !this.testCharacters()) {
        return;
      }
      // this.$refs.input.setCustomValidity(this.phoneObject.valid ? '' : this.invalidMsg);
      // Returns response.number to assign it to v-model (if being used)
      // Returns full response for cases @input is used
      // and parent wants to return the whole response.
      this.$emit('input', this.phoneText, this.phoneObject);
      // this.$emit('onInput', this.phoneObject); // Deprecated
      // Keep the current cursor position just in case the input reformatted
      // and it gets moved to the last character.
      if (e && e.target) {
        this.cursorPosition = e.target.selectionStart;
      }
    },
    onBlur() {
      this.$emit('on-blur', event, this.phoneObject);
      this.$nextTick(() => {
        this.dispatch('FormItem', 'on-form-blur', this.phone);
      });
    },
    onChange() {
      this.$emit('on-change', event);
    },
    onFocus() {
      this.$emit('on-focus', event);
    },
    onEnter() {
      this.$emit('on-enter', event);
    },
    onSpace() {
      this.$emit('on-space', event);
    },
    focus() {
      this.$refs.input.focus();
    },
    toggleDropdown() {
      if (this.disabledDial) {
        return;
      }
      this.open = !this.open;
    },
    clickedOutside() {
      this.open = false;
    },
    keyboardNav(e) {
      if (e.keyCode === 40) {
        // down arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = Math.min(this.sortedCountries.length - 1, this.selectedIndex + 1);
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop + selEle.clientHeight >
          this.$refs.list.scrollTop + this.$refs.list.clientHeight) {
          this.$refs.list.scrollTop = selEle.offsetTop -
            this.$refs.list.clientHeight +
            selEle.clientHeight;
        }
      } else if (e.keyCode === 38) {
        // up arrow
        e.preventDefault();
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = this.sortedCountries.length - 1;
        } else {
          this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }
        const selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop < this.$refs.list.scrollTop) {
          this.$refs.list.scrollTop = selEle.offsetTop;
        }
      } else if (e.keyCode === 13) {
        // enter key
        if (this.selectedIndex !== null) {
          this.choose(this.sortedCountries[this.selectedIndex]);
        }
        this.open = !this.open;
      } else {
        // typing a country's name
        this.typeToFindInput += e.key;
        clearTimeout(this.typeToFindTimer);
        this.typeToFindTimer = setTimeout(() => {
          this.typeToFindInput = '';
        }, 700);
        // don't include preferred countries so we jump to the right place in the alphabet
        const typedCountryI = this.sortedCountries
          .slice(this.preferredCountries.length)
          .findIndex(c => c.name.startsWith(this.typeToFindInput));
        if (typedCountryI >= 0) {
          this.selectedIndex = this.preferredCountries.length + typedCountryI;
          const selEle = this.$refs.list.children[this.selectedIndex];
          const needToScrollTop = selEle.offsetTop < this.$refs.list.scrollTop;
          const needToScrollBottom = selEle.offsetTop + selEle.clientHeight >
            this.$refs.list.scrollTop + this.$refs.list.clientHeight;
          if (needToScrollTop || needToScrollBottom) {
            this.$refs.list.scrollTop = selEle.offsetTop - this.$refs.list.clientHeight / 2;
          }
        }
      }
    },
    reset() {
      this.selectedIndex = this.sortedCountries.map(c => c.iso2).indexOf(this.activeCountry.iso2);
      this.open = false;
    },
    onKeyDown() {
      this.$emit('key-down', event);
    }
  }
};
</script>
<style lang="less" scoped>
.vue-tel-input {
  position: relative;
  display: flex;
  text-align: left;
  margin-top: 50px;
  padding: 5px 0;
  font-size: 14px;
  height: 46px;
  border-radius: 4px;
  border: 1px solid rgba(222,224,227,1);
  &:focus{
    border: none;
  }
}
.vue-tel-input.disabled{
  .selection,
  .dropdown,
  .vti__dropdown,
  .vti__country-code,
  .vti__input{
    cursor: no-drop;
    background-color: #f3f3f3;
  }
}
.vti__dropdown {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
  padding: 7px;
  cursor: pointer;
  width: 74px;
  outline-color: transparent;
}
.vti__dropdown.show {
  max-height: 300px;
  overflow: scroll;
}
.vti__dropdown.open {
  //background-color: #f3f3f3;
}

.vti__selection {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ivu-icon-ios-arrow-down{
    top: 5px;
    right: 0;
    &::before{
      font-size: 10px;
    }
  }
}
.vti__selection .vti__country-code {
  color: #666;
}
.vti__flag {
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  .vti__countryName{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    margin-right: 8px;
    display: block;
    line-height: 20px;
    font-size: 16px;
  }
}
.vti__dropdown-list {
  z-index: 2;
  padding: 0;
  margin: 0;
  text-align: left;
  list-style: none;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  width:300px;
  top: 46px;
  left: -1px;
  background:rgba(255,255,255,1);
  box-shadow:0px 2px 3px 0px rgba(0,0,0,0.1);
  border-radius:4px;
  border:1px solid rgba(217,217,217,1);
  .duigou{
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: -.15em;
  }
  .noduigou{
    display: inline-block;
    width: 10px;
    height: 8px;
  }
  .selected{
    background-color: #FFF5E2;
  }
}
.vti__dropdown-arrow {
  transform: scaleY(0.5);
  display: inline-block;
  color: #666;
}
.vti__dropdown-item {
  cursor: pointer;
  line-height: 38px;
}
.vti__dropdown-item.highlighted {
  background-color: #F7F9FD;
}
.vti__dropdown-item .vti__flag {
  display: inline-block;
  margin-right: 5px;
}
.vti__input {
  border: none;
  border-radius: 0 2px 2px 0;
  width: 100%;
  outline: none;
  padding-left: 7px;
  background-color: #fff;
}
span.vti__country-code {
    display: flex;
    align-items: center;
    padding: 0 7px;
}
.vti__dropDown_select{
  margin: 1.5px 0 0 0;
  .vti__input /deep/ .ivu-input{
    margin-top: 0px;
    padding: 0;
    border: none;
    border-radius: 0;
  }
}
.vue-tel-input /deep/ .vti__dropDown_select .ivu-select-dropdown{
  margin: 10px 0;
}

</style>
