export default {
  activityName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500024E","活动名称") /* "活动名称" */,
  activityDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500024F","活动时间") /* "活动时间" */,
  creator: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000250","制单人") /* "制单人" */,
  entireType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000251","促销类型") /* "促销类型" */,
  promotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000252","活动分类") /* "活动分类" */,
  activityRange: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000253","商品范围") /* "商品范围" */,
  calculateRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000254","促销基准") /* "促销基准" */,
  isGiveawayShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000255","赠品金额处理") /* "赠品金额处理" */,
  accumulationDataType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000256","累计数据类型") /* "累计数据类型" */,
  accumulationDataType1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000257","订单累计出库") /* "订单累计出库" */,
  accumulationDataType2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000258","销售出库单累计") /* "销售出库单累计" */,
  withMainProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000259","随主商品赠送") /* "随主商品赠送" */,
  giveawayRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500025A","赠品计算规则") /* "赠品计算规则" */,
  isCalcWithDisCountShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500025B","赠品数量计算") /* "赠品数量计算" */,
  isCalcWithDisCountShow1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500025C","满足条件后完全按比例赠送") /* "满足条件后完全按比例赠送" */,
  isEnableMaxCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500025D","最大优惠次数") /* "最大优惠次数" */,
  dimension: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500025E","促销维度") /* "促销维度" */,
  promotionTypes2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500025F","减金额") /* "减金额" */,
  promotionTypes3: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000260","赠品") /* "赠品" */,
  activityRanges0: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000261","全部商品") /* "全部商品" */,
  activityRanges1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000262","部分商品") /* "部分商品" */,
  calculateRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000263","出库数量") /* "出库数量" */,
  calculateRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000264","出库金额") /* "出库金额" */,
  isGiveawayShow1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000265","赠品金额强制为0") /* "赠品金额强制为0" */,
  isGiveawayShow2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000266","赠品参与分摊") /* "赠品参与分摊" */,
  giveawayRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000267","赠一定金额的赠品") /* "赠一定金额的赠品" */,
  giveawayRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000268","赠一定数量的赠品") /* "赠一定数量的赠品" */,
  productProperty: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000269","商品属性") /* "商品属性" */,
  jiliangdanwei: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500026A","计量单位") /* "计量单位" */,
  salePrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500026B","销售价格") /* "销售价格" */,
  tiaojianmanjian: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500026C","条件（批发计价单位）") /* "条件（批发计价单位）" */,
  tiaojianmane: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500026D","条件（满额）") /* "条件（满额）" */,
  zengpin: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000260","赠品") /* "赠品" */,
  giveawayMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500026E","赠品金额") /* "赠品金额" */,
  jianjia: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500026F","减价") /* "减价" */,
  giveawayNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000270","赠品计价数量") /* "赠品计价数量" */,
  selectErrorMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000271","至少选择一条优惠!") /* "至少选择一条优惠!" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000272","请正确填写!") /* "请正确填写!" */,
  selectDefaultProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000273","选择默认商品") /* "选择默认商品" */,
  pleaseSetDimenssion: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000274","请正确设置维度") /* "请正确设置维度" */,
  selectDefaultProductClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000275","选择商品分类") /* "选择商品分类" */,
  selectDefaultBrand: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000276","选择品牌") /* "选择品牌" */,
  selectDefaultProductLine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000277","选择产品线") /* "选择产品线" */,
  pleaseSelectOrgFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000278","请先选择组织！") /* "请先选择组织！" */,
  pleaseSelectCurrencyFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000279","请先选择币种!") /* "请先选择币种!" */,
  giveawayRoundType: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500027A","赠品数量取值方式") /* "赠品数量取值方式" */,
  giveawayRoundTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500027B","按计价单位精度") /* "按计价单位精度" */,
  giveawayRoundTypes2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500027C","向下取整") /* "向下取整" */,
  giveawayRoundTypes3: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500027D","向上取整") /* "向上取整" */,
  creatExpense: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500027E","促销剩余量生成客户费用单") /* "促销剩余量生成客户费用单" */,
  yes: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500027F","是") /* "是" */,
  no: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000280","否") /* "否" */,
  accDataType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000254","促销基准") /* "促销基准" */,
  accPeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000281","累计周期") /* "累计周期" */,
  pleaseSelectaccPeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000282","请选择累计周期") /* "请选择累计周期" */,
  productScope: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000253","商品范围") /* "商品范围" */,
  pleaseSelectproductScope: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000283","请选择商品范围") /* "请选择商品范围" */,
  pleaseSelectscopeValue: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000284","请选择范围值") /* "请选择范围值" */,
  scopeValue: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000285","范围值") /* "范围值" */,
  accMount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000286","累计金额") /* "累计金额" */,
  accNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000287","累计数量") /* "累计数量" */,
  perCondition: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000288","每满条件") /* "每满条件" */,
  gift: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000260","赠品") /* "赠品" */,
  giftNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000289","赠品数量") /* "赠品数量" */,
  gitMount: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500026E","赠品金额") /* "赠品金额" */,
  all: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500028A","全部") /* "全部" */,
  product: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500028B","商品") /* "商品" */,
  productClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500028C","商品分类") /* "商品分类" */,
  productline: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500028D","产品线") /* "产品线" */,
  brand: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500028E","品牌") /* "品牌" */,
  pleaseSelectGiveawayProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500028F","请选择赠品") /* "请选择赠品" */,
  opposeReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000290","驳回原因") /* "驳回原因" */
};
