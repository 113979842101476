export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A4","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A5","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A6","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A7","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A8","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006A9","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006AA","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006AB","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006AC","停用") /* "停用" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006AD","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006AE","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006AF","查看执行情况") /* "查看执行情况" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B0","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  handleBatchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B1","没有权限操作该平台的促销") /* "没有权限操作该平台的促销" */,
  opposeMsg: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B2","请填写驳回原因") /* "请填写驳回原因" */,
  oTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B3","请填写驳回原因,当前促销活动将被自动停用!") /* "请填写驳回原因,当前促销活动将被自动停用!" */,
  oTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B4","驳回原因") /* "驳回原因" */,
  oRejectReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B2","请填写驳回原因") /* "请填写驳回原因" */
};
