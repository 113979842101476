import AutoComplete from './components/auto-complete';
import FormItem from './components/form-item';
import UImage from './components/image';
import InputNumber from './components/input-number';
import Modal from './components/modal';
import PredicateDatepicker from './components/predicate-datepicker';
import DatePicker from './components/u-picker';
import Radio from './components/radio';
import SelectTab from './components/select-tab';
import ScrollBar from './components/scroll-bar';
import SvgIcon from './components/svg-icon'; // svg组件
import Table from './components/table';
import ScTable from './components/scroll-table';
import BigTable from './components/vue-bigdata-table';

import Refer from '_c/common/refer/refer';

import Tabs from './components/tabs';
import TagButton from './components/tag-button';
import Tree from './components/tree';
import TreeSelect from './components/tree-select';
import TextArea from './components/textarea';
import Common from './components/common';
import Order from './components/order';
import Product from './components/product';
import countTo from './components/count-to';
import InputMeter from './components/input-meter';
import TelInput from './components/tel-input';
import VueUeditorWrap from './components/vue-ueditor-wrap';
import '../assets/icons';
import store from '@/store';
import locale from './locale/index';
import util from '@/libs/util.js';
require('@/libs/download');

const components = {
  AutoComplete,
  FormItem,
  UImage,
  InputNumber,
  ScrollBar,
  SvgIcon,
  Modal,
  PredicateDatepicker,
  DatePicker,
  Radio,
  RadioGroup: Radio.Group,
  SelectTab,
  Table,
  ScTable,
  BigTable,
  Refer,
  Tabs: Tabs,
  TabPane: Tabs.Pane,
  TagButton,
  Tree,
  TreeSelect,
  TextArea,
  InputMeter,
  ...Common,
  ...Order,
  ...Product,
  countTo,
  TelInput,
  VueUeditorWrap
};

const install = function(Vue, opts = {}) {
  if (install.installed) return;
  locale.use(opts.locale);
  locale.i18n(opts.i18n);
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key]);
  });

  Vue.prototype.$Modal = Modal;

  Object.defineProperty(Vue.prototype, '$util', { value: util });

  Vue.prototype.agentLink = function(obj = {}) {
    obj.vm = this;
    return util.agentLink(obj);
  };

  Vue.prototype.mlang = function(key) {
    let str = '';
    if (this.$route && this.$te(`${this.$route.name}.${key}`)) {
      str = this.$t(`${this.$route.name}.${key}`);
    }
    if (this.$te(`${key}`)) {
      str = this.$t(`${key}`);
    } else {
      str = this.$t(`${this.$route.name}.${key}`);
    }
    return str;
  };
  Vue.prototype.$open = function() {
    let href = arguments[0];
    if (!href) {
      console.error('no href'); //eslint-disable-line
      return;
    }
    const win = window.open(...arguments);
    return win;
  };
  Vue.prototype.$download = function(href) {
    const createDownloadForm = (url, params) => {
      console.log('createDownloadForm params:'); //eslint-disable-line
      console.log(params); //eslint-disable-line
      let eleForm = document.createElement('form');
      eleForm.method = 'get';
      // 先注释掉了，每次导出都会跳转页面
      // eleForm.target = '_blank'
      eleForm.action = url;
      eleForm.style.display = 'none';

      const eleInput = document.createElement('input');
      eleInput.name = 'json';
      eleInput.value = JSON.stringify(params);

      document.body.appendChild(eleForm);
      eleForm.appendChild(eleInput);
      eleForm.submit();
      document.body.removeChild(eleForm);
      eleForm = null;
    };
    createDownloadForm(href);
    // window.downloadFile(href);
  };
  Vue.prototype.$hasAuth = function(arg) {
    return util.hasAuth(store, arg);
  };
  Vue.prototype.$getBO = function(code, bizId = '') {
    return util.getBo(store, code, bizId);
  };
  // ®加样式
  Vue.prototype.$trustHtml = function(value) {
    if (value && value.indexOf('®') > -1) {
      value = value.replace(/®/g, '<span class="smaler-font"><sup>®</sup></span>');
    }
    return value;
  };
  Vue.prototype.$downloadFileWithPath = function(href) {
    if (href) {
      const a = document.createElement('a');
      a.setAttribute('download', '');
      a.setAttribute('href', href);
      a.setAttribute('target', '_blank');
      a.click();
    }
  };
  const langObj = {
    'zh-cn': 'zhs',
    'zh-tw': 'zht',
    'en-us': 'en',
    'id-id': 'id_ID'
  };
  // 附件多语转换
  Vue.prototype.$fileLocale = langObj[util.getLocal() || window.localStorage.getItem('lang') || 'zh-cn'];

  const Bus = new Vue({
    methods: {
      emit(event, ...args) {
        this.$emit(event, ...args);
      },
      on(event, callback) {
        this.$on(event, callback);
      },
      off(event, callback) {
        this.$off(event, callback);
      }
    }
  });
  Vue.prototype.$bus = Bus;
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

const API = {
  version: 1.0, // eslint-disable-line no-undef
  locale: locale.use,
  i18n: locale.i18n,
  install,
  ...components
};

export default API;
