import ajax from '@/libs/ajax';

export const getRegionRoot = () => {
  return ajax.request({
    url: '/archives/system/region/root',
    data: {
      isArchive: 1
    },
    method: 'get'
  });
};

export const getRegionSubnodes = (id) => {
  return ajax.request({
    url: `/archives/system/region/${id}/subnodes`,
    data: {
      isArchive: 1
    },
    method: 'get'
  });
};

export const getAllRegion = () => {
  return ajax.request({
    url: 'archives/system/getAllRegion?isArchive=1',
    method: 'get'
  });
};
