export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000745","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000746","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000747","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000748","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000749","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500074A","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500074B","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500074C","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500074D","停用") /* "停用" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500074E","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500074F","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000750","查看执行情况") /* "查看执行情况" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000751","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  handleBatchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000752","没有权限操作该平台的促销") /* "没有权限操作该平台的促销" */
};
