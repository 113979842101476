export default {
  createOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500049A","创建组织") /* "创建组织" */,
  activityName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500049B","活动名称") /* "活动名称" */,
  activeTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500049C","活动时间") /* "活动时间" */,
  creator: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500049D","制单人") /* "制单人" */,
  marketName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500049E","营销方案名称") /* "营销方案名称" */,
  themeName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500049F","营销活动名称") /* "营销活动名称" */,
  activeState: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A0","状态") /* "状态" */,
  promotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A1","促销方式") /* "促销方式" */,
  strPromotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A2","促销类型") /* "促销类型" */,
  stepCalcRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A3","促销条件") /* "促销条件" */,
  isCalcEntireProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A4","活动门槛计算依据") /* "活动门槛计算依据" */,
  giveawayCalcRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A5","赠送计算规则") /* "赠送计算规则" */,
  isMutexClassPre: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A6","与分类促关系") /* "与分类促关系" */,
  giveawaySelectRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A7","订单赠品选择") /* "订单赠品选择" */,
  isCalcWithDisCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A8","赠品数量计算") /* "赠品数量计算" */,
  Proportional: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004A9","满足条件后完全按比例赠送") /* "满足条件后完全按比例赠送" */,
  isGiveawayShow: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AA","赠品金额处理") /* "赠品金额处理" */,
  mutexActivities: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AB","互斥条件") /* "互斥条件" */,
  isEnableStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AC","阶梯优惠") /* "阶梯优惠" */,
  openStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AD","启用(全额累进)") /* "启用(全额累进)" */,
  isEnableMaxPreferential: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AE","最大优惠量") /* "最大优惠量" */,
  openMaxPre: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AF","启用(按照计价数量单位计算)") /* "启用(按照计价数量单位计算)" */,
  isEnableMaxCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B0","最大优惠次数") /* "最大优惠次数" */,
  maxPreCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B1","每客户享受本促销最多") /* "每客户享受本促销最多" */,
  ci: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B2","次") /* "次" */,
  demension: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B3","促销维度") /* "促销维度" */,
  batchDel: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B4","批量删除") /* "批量删除" */,
  addProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B5","新增商品") /* "新增商品" */,
  addRow: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B6","增行") /* "增行" */,
  product: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B7","商品") /* "商品" */,
  strPromotionType1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B8","单品促销") /* "单品促销" */,
  strPromotionType2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004B9","商品组合促销") /* "商品组合促销" */,
  strPromotionType3: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004BA","商品分类促销") /* "商品分类促销" */,
  strPromotionType4: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004BB","商品分组促销") /* "商品分组促销" */,
  promotionType1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004BC","打折") /* "打折" */,
  promotionType2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004BD","减价") /* "减价" */,
  promotionType3: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004BE","一口价") /* "一口价" */,
  promotionType4: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004BF","赠商品") /* "赠商品" */,
  stepCalcRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C0","满件") /* "满件" */,
  stepCalcRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C1","满额") /* "满额" */,
  isCalcEntireProduct1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C2","活动商品") /* "活动商品" */,
  isCalcEntireProduct2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C3","整单") /* "整单" */,
  isMutexClassPre1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C4","以单品促为准") /* "以单品促为准" */,
  isMutexClassPre2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C5","优先单品促") /* "优先单品促" */,
  pleaseSetDimenssion: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C6","请正确设置维度") /* "请正确设置维度" */,
  giveawaySelectRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C7","赠品不可选，各梯度由高到低自动匹配") /* "赠品不可选，各梯度由高到低自动匹配" */,
  giveawaySelectRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C8","自由选择各梯度可赠商品数量") /* "自由选择各梯度可赠商品数量" */,
  mutexActivitie1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004C9","整单促销(针对金额促销)") /* "整单促销(针对金额促销)" */,
  mutexActivitie2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004CA","整单促销(针对买赠促销)") /* "整单促销(针对买赠促销)" */,
  isGiveaway1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004CB","赠品金额强制为0") /* "赠品金额强制为0" */,
  isGiveaway2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004CC","赠品参与分摊") /* "赠品参与分摊" */,
  giveawayCalcRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004CD","赠一定数量的赠品") /* "赠一定数量的赠品" */,
  giveawayCalcRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004CE","赠一定金额的赠品") /* "赠一定金额的赠品" */,
  productProperty: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004CF","商品属性") /* "商品属性" */,
  salePrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D0","原价") /* "原价" */,
  ifstepCalcRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D1","条件（批发计价单位）") /* "条件（批发计价单位）" */,
  ifstepCalcRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D2","条件（满额）") /* "条件（满额）" */,
  disratelv: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D3","折扣率%") /* "折扣率%" */,
  notDisrate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D4","倒扣率%") /* "倒扣率%" */,
  notUsedPreferentialNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D5","已使用数量") /* "已使用数量" */,
  del: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D6","删除") /* "删除" */,
  fireMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D7","单价减") /* "单价减" */,
  giveways: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D8","赠品") /* "赠品" */,
  giveawayMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004D9","赠品金额") /* "赠品金额" */,
  giveawayNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004DA","赠品数量") /* "赠品数量" */,
  isCapping: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004DB","限制") /* "限制" */,
  addGroupStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004DC","增加阶梯") /* "增加阶梯" */,
  sbfd: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004DD","上不封顶") /* "上不封顶" */,
  productClassProes: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004DE","商品分类") /* "商品分类" */,
  productGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004DF","商品分组") /* "商品分组" */,
  pleaseSelectOrgFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E0","请先选择组织！") /* "请先选择组织！" */,
  pleaseSelectCurrencyFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E1","请先选择币种!") /* "请先选择币种!" */,
  pleaseSelectProductClassFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E2","请先选择商品分类!") /* "请先选择商品分类!" */,
  xuanze: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E3","选择") /* "选择" */,
  xuanzeliwai: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E4","选择例外商品") /* "选择例外商品" */,
  selectClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E5","选择分类") /* "选择分类" */,
  pleaseSelectProductClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E6","请选择商品分类") /* "请选择商品分类" */,
  pleaseSelectCurrectProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E7","请选择已选商品分类下的商品!") /* "请选择已选商品分类下的商品!" */,
  selectGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E8","选择分组") /* "选择分组" */,
  repeatProducthasNO: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004E9","重复商品已被忽略") /* "重复商品已被忽略" */,
  selectErrorMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004EA","至少选择一条优惠!") /* "至少选择一条优惠!" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004EB","请正确填写!") /* "请正确填写!" */,
  selectDefaultProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004EC","选择默认商品") /* "选择默认商品" */,
  selectDefaultProductClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004ED","选择商品分类") /* "选择商品分类" */,
  zhishaotianjiayitiaozengpin: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004EE","至少选择一条赠品") /* "至少选择一条赠品" */,
  demorenshangpin: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004EF","的默认商品") /* "的默认商品" */,
  pleaseSelectProductGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F0","请选择商品分组") /* "请选择商品分组" */,
  zongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F1","总金额") /* "总金额" */,
  zongshuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F2","总数量") /* "总数量" */,
  yishiyong: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F3","已使用") /* "已使用" */,
  meikehu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F4","每客户") /* "每客户" */,
  zengpinzuidayouhuiliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F5","赠品最大优惠量") /* "赠品最大优惠量" */,
  fireMoneyCanNotBigToProductMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F6","减价金额不能大于商品金额") /* "减价金额不能大于商品金额" */,
  notCanSetRepeatProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F7","不能设置重复商品") /* "不能设置重复商品" */,
  zuidayouhuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AE","最大优惠量") /* "最大优惠量" */,
  shengyukeyongliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F8","剩余可用量") /* "剩余可用量" */,
  maxPreferentialRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004F9","主商品最大优惠数量") /* "主商品最大优惠数量" */,
  maxPreferentialRules3: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004FA","赠品最大优惠计价数量") /* "赠品最大优惠计价数量" */,
  maxPreferentialRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004FB","主商品最大优惠金额") /* "主商品最大优惠金额" */,
  zuidayouhuiliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004AE","最大优惠量") /* "最大优惠量" */,
  shengyukeyongshuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004FC","剩余可用数量") /* "剩余可用数量" */,
  zuidayouhuishuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004FD","最大优惠数量") /* "最大优惠数量" */,
  zuidayouhuijine: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004FE","最大优惠金额") /* "最大优惠金额" */,
  zuidakeyongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE050004FF","最大可用金额") /* "最大可用金额" */,
  shengyukeyongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000500","剩余可用金额") /* "剩余可用金额" */

};
