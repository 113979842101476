import ajax from '@/libs/ajax';

export const getOrginazations = (data) => {
  return ajax.request({
    url: '/orginazation/org/user/list/2',
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getCombineDetail = (data) => {
  return ajax.request({
    url: `/goods/promotion/combination/vo/${data.id}`,
    method: 'get',
    data: {
      isCopy: data.isCopy ? 1 : 0,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

export const savePromotion = (data) => {
  return ajax.request({
    url: '/goods/promotion/combination/vo',
    method: 'POST',
    data: {
      data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};
// 组合促销查看执行情况
export const getCombineExeStatusList = (query) => {
  return ajax.request({
    url: '/goods/promotion/getCombinationPreferentialRecord',
    method: 'POST',
    data: {
      data: query.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const submitProductDetailData = (data) => {
  let res = ajax.request({
    url: '/bill/submit',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    }
  });
  return res;
};
export const unSubmitProductDetailData = (data) => {
  return ajax.request({
    url: '/bill/unsubmit',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    }
  });
};
