<template>
  <div
    class="informatio no-data">
    <!-- <SvgIcon
      icon-class="nodata"
      class-name="no-data-icon"></SvgIcon> -->
    <i class="icon icon-no-data-td"></i>
    <p>
      <slot>
        {{ mlang('noResults') }}
      </slot>
    </p>
  </div>
</template>

<script>
import SvgIcon from '../svg-icon';
export default {
  name: 'NoData',
  components: {
    SvgIcon
  },
  data: function() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
</style>
