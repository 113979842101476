export default {
  // 批量操作
  MarkAsRead: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DD1","一键已读") /* "一键已读" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DD2","请选择需要批量操作的消息") /* "请选择需要批量操作的消息" */,
  markAsReadConfirmTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DD3","是否将选中的消息标记为已读") /* "是否将选中的消息标记为已读" */,
  batchDelConfirmTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DD4","您确定要删除选中的数据吗?") /* "您确定要删除选中的数据吗?" */,
  markSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DD5","消息已读标记成功") /* "消息已读标记成功" */,
  // 时间查询的条件
  nearlyOneMonthMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DD6","近一个月消息") /* "近一个月消息" */,
  oneMonthAgoMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DD7","一个月以前消息") /* "一个月以前消息" */
};
