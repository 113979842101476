import { contacterForOrder } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const contacterForOrderRefer = (ctrl, vm) => {
  const RowAction = () => {
    return (
      <div class="action">
        <Row class-name="text" type="flex" justify="center">
          {(
            <span class="beDefaultBtn" style="width:60px" on-click={setDefault}>
              {this.mlang('setDefault')}
            </span>
          )}
        </Row>
      </div>
    );
  }
  const control = Object.assign(
    {},
    {
      page: false,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'fullName',
      queryschema: [
        {
          name: 'value',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('xingming'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'fullName',
          width: 120,
          render: (h, params) => {
            return h('span', {
              class: 'two-line-overflow-hide'
            }, [
              (params.row[params.column.fieldName]),
              (params.row.isDefault ? h('span', { class: 'default-bank' }, vm.mlang('default')) : '')
            ]);
          }
        },
        {
          showCaption: vm.mlang('position'),
          fieldName: 'position',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('telphone'),
          isShowIt: true,
          width: 180,
          fieldName: 'mobile'
        },
        {
          showCaption: vm.mlang('mail'),
          isShowIt: true,
          width: 180,
          fieldName: 'email'
        },
        {
          showCaption: vm.mlang('operate'),
          fieldName: 'action',
          isShowIt: true,
          width: 200,
          align: 'center',
          fixed: 'suspend',
          render: (h, params) => {
            return params.row.isDefault ? '' : h('span',
              {
                class: 'action-btn',
                style: {
                  cursor: 'pointer'
                },
                on: {
                  click: () => {
                    vm.setDefaultContactFromOrder(params.row.id); // 此接口要自行实现
                  }
                }
              },
              vm.mlang('setDefault')
            );
          }
        }
      ],
      actions: { 
        queryList: contacterForOrder
      },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default contacterForOrderRefer;
