import ajax from '@/libs/ajax';
import util from '@/libs/util';

export const getAllOrders = (data, isHistory, isContainBody) => {
  if (isHistory) {
    return ajax.request({
      url: '/report/history/order/list/queryschema',
      method: 'post',
      data: {
        data: data.querySchema,
        isReport: 1,
        datatype: 'json'
      }
    });
  } else {
    return ajax.request({
      url: `/marketingbill/order/list/getListSchema?isContainBody=${isContainBody}`,
      method: 'post',
      data: {
        data: data.querySchema,
        datatype: 'json',
        isMarket: 1
      }
    });
  }
  // .then(res => {
  //   return orderlist.data;
  // });
};
export const getOrderImportStatus = () => {
  return ajax.request({ url: '/marketingbill/order/getImportStatus?isMarket=1', method: 'get' });
};

export const delOrder = (data) => {
  return ajax.request({
    url: '/pc/OrderSrv/delOrder',
    method: 'post',
    data
  });
};

// 基于UDH-61810 只将订单删除接口进行修改
export const delSaleOrder = (obj) => {
  return ajax.request({
    url: '/voucher/order/deleteOrderById',
    method: 'get',
    data: {
      orderId: obj.id,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// export const delSaleOrder = (obj) => {
//   obj.code = obj.cOrderNo;
//   return ajax.request({
//     url: '/mdf-node/uniform/bill/delete?serviceCode=orderList&domainKey=udinghuo',
//     method: 'get',
//     data: {
//       data: {
//         billnum: 'voucher_order',
//         data: JSON.stringify(obj)
//       },
//       isArchive: 1,
//       isU8C: 1,
//       datatype: 'json'
//     }
//   });
// };

export const exportOrder = (data) => {
  return ajax.request({
    url: '/pc/OrderSrv/exportOrders',
    method: 'post',
    data
  });
};

export const exportSaleOrder = (postData) => {
  let url = '/mdf-node/uniform/bill/export?action=output&serviceCode=orderList&domainKey=udinghuo';
  url = postData.ids ? `${url}&cmdname=cmdExport2` : `${url}&cmdname=cmdExport1`;
  return ajax.request({
    url,
    method: 'post',
    data: {
      data: Object.assign({}, {
        billnum: 'voucher_orderlist',
        fileName: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580003F","销售订单") /* "销售订单" */,
        // isSum: true,
        asyncKey: 'asyncOutput_' + util.uuid(32).replace('pc-', ''),
        externalData: { isAsync: true }
      }, postData),
      isArchive: 1,
      isU8C: 1,
      datatype: 'json'
    }
  });
};

export const getOrderListBtn = (data) => {
  return ajax.request({
    url: '/marketingbill/order/showBtn',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    }
  });
};

export const fetchOrderMemo = (cOrderNo) => {
  return ajax.request({ url: '/pc/OrderSrv/findOrderMemos', method: 'get', data: { cOrderNo } });
};

export const fetchOrderStatusRecordByOrderId = (orderId) => {
  return ajax.request({ url: `/voucher/order/getOrderStatusRecordByOrderId/${orderId}`, method: 'get', data: { isArchive: 1 } });
};
export const fetchHistoryOrderStatusRecordById = (id) => {
  return ajax.request({ url: `/report/history/order/getOldOrderStatusRecordByOrderId?isReport=1&orderId=${id}`, method: 'get', data: {} });
};

export const fetchOrdergetPaymentByOrderNo = (voucherType, cOrderNo) => {
  return ajax.request({ url: `/voucher/payment/paymentverification/${voucherType}/${cOrderNo}`, method: 'get', data: { isArchive: 1 } });
};
export const fetchHistoryOrdergetPaymentById = (voucherId, voucherType) => {
  return ajax.request({ url: `/report/history/payment/getPaymentByVoucherId/${voucherId}/${voucherType}`, method: 'get', data: { isReport: 1 } });
};

export const opposeOrder = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/setOpposeOrder', method: 'post', data });
};
export const batchOpposeOrder = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/setBatchOpposeOrder', method: 'post', data });
};

export const openOrder = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/openOrderDetail', method: 'post', data });
};

export const openCheckCredit = (iAgentId) => {
  return ajax.request({ url: '/agent/infrastructure/creditCtrlUse?isArchive=1', method: 'get', data: { iAgentId } });
};

export const closeOrder = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/closedOrderDetail', method: 'post', data });
};

export const returnOrder = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/orderConfirmBack', method: 'post', data });
};

export const checkInventory = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/getExceedInventoryOrder', method: 'post', data });
};

export const checkCredit = (data) => {
  data.datatype = 'json';
  return ajax.request({
    url: '/agent/infrastructure/creditCtrlUse?isArchive=1',
    method: 'post',
    data
  });
};

export const confirmOrder = (data) => {
  data.datatype = 'json';
  return ajax.request({ url: '/pc/OrderSrv/orderBatchConfirm', method: 'post', data });
};

export const checkMinQuantity = (cOrderNo) => {
  return ajax.request({ url: '/pc/OrderSrv/getCheckMinQuantityByOrder', method: 'get', data: { cOrderNo } });
};

export const fetchOrderLogisticInfo = (orderNo) => {
  return ajax.request({ url: '/pc/DeliverySrv/getDeliverysLogisticInfoForOrder', method: 'get', data: { orderNo } });
};

export const getOrderDetail = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/getDetailFlow', method: 'get', data });
};

export const separateBill = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/saveOrderSplit', method: 'post', data });
};

export const breateSearch = (url, data) => {
  return ajax.request({ url: url, method: 'post', data });
};

export const rebateGoodsSearch = (url, data) => {
  return ajax.request({ url: url, method: 'get', data });
};

export const rollbackOrderRebate = (order) => {
  return ajax.request({ url: '/pc/RebateSrv/rollbackOrderRebate', method: 'post', order });
};

export const calcOrderRate = (data) => {
  return ajax.request({ url: '/voucher/rebate/udh/calc/voucher', method: 'post', data });
};

export const computePromotion = (data) => {
  return ajax.request({ url: '/voucher/order/package/submit/model', method: 'post', data });
};

export const getSkuInventorys = (url, data) => {
  // return ajax.request({ url: '/pc/Product/getSkuInventorys?iAgentId=' + url, method: 'post', data });
  return ajax.request({
    url: `/archives/product/getSkuInventorys?iAgentId=${url}&skuIds=${data.skuIds}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const getProduct = (url, data) => {
  return ajax.request({ url, method: 'post', data });
};

export const getUpProductSpecs = (id, bizProductId) => {
  return ajax.request({ url: '/archives/product/getUpProductSpecs?isArchive=1', method: 'get', data: { id, bizProductId } });
};
export const submitOrderForSecondUser = (data) => {
  return ajax.request({ url: '/pc/OrderSrv/submitOrderForSecondUser', method: 'post', data });
};

export const getPayMoneysByOrderNos = (cOrderNos) => {
  return ajax.request({
    url: '/voucher/payment/voucherinfo',
    method: 'post',
    data: {
      data: cOrderNos,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const calcOrderCoupon = (order) => {
  return ajax.request({
    url: '/pc/OrderSrv/calcOrderCoupons',
    method: 'post',
    data: { order, datatype: 'json' }
  });
};

export const calcOrderPoint = (order) => {
  return ajax.request({ url: '/voucher/order/package/submit/model', method: 'post', data: { data: order, datatype: 'json', isArchive: 1 } });
};

export const getOrderPayDetail = (cOrderNos) => {
  return ajax.request({ url: '/pc/OrderSrv/getOrderPayDetail', method: 'get', data: { cOrderNos } });
};

// 统计本周、本月订单、付款金额
export const getIndexStatisticOrder = (id, bizProductId) => {
  return ajax.request({ url: '/statistics/indexStatisticOrder?isArchive=1', method: 'get' });
};
// 获取各订单状态交易数据
export const getTradingData = () => {
  return ajax.request({
    url: '/voucher/order/getCountForAllStatus?isArchive=1',
    method: 'get',
    data: {
      params: 'CONFIRMORDER,DELIVERGOODS,TAKEDELIVERY,PAYMENT'
    }
  });
};

export const creditCtrlUse = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/creditCtrlUse?isArchive=1',
    method: 'get',
    data
  });
};
// ERP解锁
export const deblock = (data) => {
  return ajax.request({
    url: '/pc/OrderSrv/orderLockOpenByOrderNo',
    method: 'post',
    data
  });
};
export const getExceedInventoryDeliverys = (data) => {
  return ajax.request({
    url: '/pc/DeliverySrv/getExceedInventoryDeliverys',
    method: 'post',
    data: {
      orderNos: data,
      datatype: 'json'
    }
  });
};
// 获取yhttoken
export const getYHTtoken = () => {
  return ajax.request({
    url: '/getYHTtoken',
    method: 'get'
  });
};
// 获取yhttoken
export const clearYHTtoken = () => {
  return ajax.request({
    url: '/clearYHTtoken',
    method: 'get'
  });
};
// 获取yhttoken
export const getAgentFlag = () => {
  return ajax.request({
    url: '/getAgentFlag',
    method: 'get'
  });
};
export const tokenAlive = () => {
  return ajax.request({
    url: '/gscm/user/keepalive',
    method: 'post',
    data: {
      data: {},
      datatype: 'json',
      isMarket: 1
    }
  });
};
// 获取电子发票
export const getEleSalesInvoice = (orderId) => {
  return ajax.request({
    url: '/marketingbill/salesinvoice/getEleSalesInvoice',
    method: 'post',
    data: {
      orderId,
      datatype: 'json',
      isMarket: 1
    }
  });
};

// UDH-81167 满足启用企业会员&允许使用积分且当前客户是企业会员&【交易对象为组织或者交易对象为商家（商家对应客户为企业会员）】
export const judgePointDeductionConditions = (params) => {
  return ajax.request({
    url: `/uhy/member/usepoints/${params.agentId}/${params.salesOrgId}`,
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

//  草稿保存
export const saveDraft = (data, draftName) => {
  return ajax.request({
    url: draftName ? `/voucher/order/tempdata/save?name=${draftName}` : `/voucher/order/tempdata/save`,
    method: 'post',
    data: {
      datatype: 'json',
      isArchive: 1,
      data
    }
  });
};
