<template>
  <Select
    ref="select"
    :label="label"
    :disabled="disabled"
    :clearable="clearable"
    :placeholder="placeholder"
    :size="size"
    :placement="placement"
    :value="tmpValue"
    :remote-method="remoteMethod"
    :transfer="transfer"
    class="ivu-auto-complete"
    filterable
    remote
    auto-complete
    @on-change="handleChange">
    <slot name="input">
      <Input
        ref="input"
        slot="input"
        v-model="currentValue"
        :element-id="elementId"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :size="size"
        :icon="inputIcon"
        @on-keydown="handleKeydown"
        @on-focus="handleFocus"
        @on-blur="handleBlur"></Input>
    </slot>
    <slot>
      <Option
        v-for="item in filteredData"
        :key="item"
        :value="item">
        {{ item }}
      </Option>
    </slot>
  </Select>
</template>
<script>
import { oneOf } from '../../utils/assist';
import Emitter from '../../mixins/emitter';

export default {
  name: 'AutoComplete',
  mixins: [Emitter],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: [String, Number],
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    size: {
      type: String,
      validator(value) {
        return oneOf(value, ['small', 'large', 'default']);
      }
    },
    icon: {
      type: String
    },
    filterMethod: {
      type: [Function, Boolean],
      default: false
    },
    placement: {
      type: String,
      validator(value) {
        return oneOf(value, ['top', 'bottom']);
      },
      default: 'bottom'
    },
    transfer: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    elementId: {
      type: String
    }
  },
  data() {
    return {
      tmpValue: '',
      currentValue: this.value,
      disableEmitChange: false // for Form reset
    };
  },
  computed: {
    inputIcon() {
      let icon = '';
      if (this.clearable && this.currentValue) {
        icon = 'ios-close';
      } else if (this.icon) {
        icon = this.icon;
      }
      return icon;
    },
    filteredData() {
      if (this.filterMethod) {
        return this.data.filter((item) => this.filterMethod(this.currentValue, item));
      } else {
        return this.data;
      }
    }
  },
  watch: {
    value(val) {
      if (this.currentValue !== val) {
        this.disableEmitChange = true;
      }
      this.currentValue = val;
    },
    currentValue(val) {
      this.$refs.select.query = val;
      this.$emit('input', val);
      if (this.disableEmitChange) {
        this.disableEmitChange = false;
        return;
      }
      this.$emit('on-change', val);
      this.dispatch('FormItem', 'on-form-change', val);
    }
  },
  methods: {
    remoteMethod(query) {
      this.$emit('on-search', query);
    },
    handleChange(val) {
      this.currentValue = val;
      this.$refs.input.blur();
      this.$emit('on-select', val);
    },
    handleFocus(event) {
      this.$emit('on-focus', event);
    },
    handleBlur(event) {
      this.$emit('on-blur', event);
    },
    handleKeydown(event) {
      this.$emit('key-down', event);
    },
    handleClear() {
      if (!this.clearable) return;
      this.currentValue = '';
      this.$refs.select.reset();
    }
  }
};
</script>
