export default {
  defaultAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B6","默认权限") /* "默认权限" */,
  returnGoodsConfirm: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B7","退货确认") /* "退货确认" */,
  returnConfirmTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B8","确认实际入库数量与退货数量一致？如不一致可在退货单中修改累计入库数量后再进行审核") /* "确认实际入库数量与退货数量一致？如不一致可在退货单中修改累计入库数量后再进行审核" */,
  refund: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001B9","退款") /* "退款" */,
  opposeTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001BA","驳回原因") /* "驳回原因" */,
  approveSaleReturnContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001BB","确认将") /* "确认将" */,
  approveSaleReturnContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001BC","执行退货审批操作") /* "执行退货审批操作" */,
  approveSaleReturnSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001BD","确认成功") /* "确认成功" */,
  confirmDialogError: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580003B","请输入驳回原因!") /* "请输入驳回原因!" */,
  confirmDialogSuccess: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580003C","驳回成功!") /* "驳回成功!" */,
  saleReturnConfirmBackContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001BB","确认将") /* "确认将" */,
  saleReturnConfirmBackContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001BE","回退吗？") /* "回退吗？" */,
  saleReturnConfirmBackSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001BF","回退成功！") /* "回退成功！" */,
  confirmSaleReturnContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C0","退货签收") /* "退货签收" */,
  confirmSaleReturnSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C1","退货确认成功！") /* "退货确认成功！" */,
  // 批量
  batchRefundContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C2","是否要对选中的") /* "是否要对选中的" */,
  batchRefundContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C3","张退货单进行退款？") /* "张退货单进行退款？" */,
  batchRefundWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C4","请选择至少一条退款状态为未退款的退货单") /* "请选择至少一条退款状态为未退款的退货单" */,
  onlySupportOneAgent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C5","请选择同一客户") /* "请选择同一客户" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C6","请选择退货单") /* "请选择退货单" */
};
