import corpParentView from '@/views/corp/home/home.vue';

const settle = {
  path: 'settle',
  title: { i18n: 'settle' },
  name: 'settle',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'payments',
      title: { i18n: 'title.payments' },
      name: 'payments',
      meta: { module: 'payments' },
      component: () => import('@/views/corp/settle/components/payments.vue')
    },
    {
      path: 'quickPays', // 快捷支付
      title: { i18n: 'title.quickPays' },
      name: 'quickPays',
      meta: { module: 'payments', notCache: true },
      component: () => import('@/components/payments/quickPay/quickPays.vue')
    },
    {
      path: 'quickPay', // 快捷支付
      title: { i18n: 'title.quickPays' },
      name: 'quickPay',
      meta: { module: 'payments', notCache: true },
      component: () => import('@/components/payments/quickPay/quickPay.vue')
    },
    {
      path: 'refunds',
      title: { i18n: 'title.refunds' },
      name: 'refunds',
      component: () => import('@/views/corp/settle/components/refunds.vue')
    },
    {
      path: 'refund',
      title: { i18n: 'title.refund' },
      name: 'refund',
      meta: { module: 'refunds' },
      component: () => import('@/views/corp/settle/components/refund.vue')
    },
    {
      path: 'shareRules',
      name: 'shareRules',
      meta: { module: 'shareRules' },
      component: () => import('@/views/corp/settle/components/shareRules.vue')
    },
    {
      path: 'shareRule',
      name: 'shareRule',
      meta: { module: 'shareRules', notCache: true },
      component: () => import('@/views/corp/settle/components/shareRule.vue')
    },
    // {
    //   path: 'refundRules',
    //   title: { i18n: 'title.refundRules' },
    //   name: 'refundRules',
    //   component: () => import('@/views/corp/settle/components/refundRules.vue')
    // },
    // {
    //   path: 'discountRebate',
    //   title: { i18n: 'title.discountRebate' },
    //   name: 'discountRebate',
    //   component: () => import('@/views/corp/settle/customerRebate/discountRebate/discountRebate.vue')
    // },
    // {
    //   path: 'goodsRebate',
    //   title: { i18n: 'title.goodsRebate' },
    //   name: 'goodsRebate',
    //   component: () => import('@/views/corp/settle/customerRebate/goodsRebate/goodsRebate.vue')
    // },
    {
      path: 'withdrawalmngs',
      meta: {
        title: '提现管理',
        module: 'withdrawalmngs',
        bill: true
      },
      name: 'withdrawalmngs',
      component: () => import('@/views/corp/settle/components/withdrawalmng/withdrawalmngs.vue')
    },
    {
      path: 'withdrawalmng',
      meta: {
        title: '提现单',
        module: 'withdrawalmngs',
        notCache: true,
        bill: true
      },
      name: 'withdrawalmng',
      component: () => import('@/views/corp/settle/components/withdrawalmng/withdrawalmng.vue')
    }
  ]
};
export default settle;
