export default {
  descText: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500075F","[左值<库存量<=右值]") /* "[左值<库存量<=右值]" */,
  menuSettingHint: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000760","不设置库存区间将显示实际库存量") /* "不设置库存区间将显示实际库存量" */,
  stockBetween: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000761","库存区间") /* "库存区间" */,
  inventoryTipType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000762","库存提示方式") /* "库存提示方式" */,
  realInventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000763","实际库存") /* "实际库存" */,
  mineWord: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000764","自定义文字") /* "自定义文字" */,
  inventoryTipWord: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000765","库存提示文字") /* "库存提示文字" */,
  maxFiveWord: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000766","最多5个字") /* "最多5个字" */
};
