export default {
  merchantsClass: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B0', '商家名称') /* "商家名称" */,
  recentPurchase: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B1', '最近购买') /* "最近购买" */,
  collectibleGoods: lang.templateByUuid('UID:P_UDHWN_182D942C05880009', '收藏商品') /* "收藏商品" */,
  levelTwo: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B2', '二级分类') /* "二级分类" */,
  levelThree: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B3', '三级分类') /* "三级分类" */,
  orderSelected: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B4', '已选清单') /* "已选清单" */,
  generateOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B5', '生成订单') /* "生成订单" */,
  startOrder: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B6', '起订') /* "起订" */,
  selectAttr: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B7', '选择属性') /* "选择属性" */,
  specie: lang.templateByUuid('UID:P_UDHWN_17DD50DE05000BD3', '种类'),
  pricingQuantity: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005B9', '计价数量') /* "计价数量" */,
  orderAmount: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005BA', '订单金额') /* "订单金额" */,
  folding: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005BB', '收起') /* "收起" */,
  unfold: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005BC', '展开') /* "展开" */,
  moneyyuan: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005BD', '金额(元):') /* "金额(元):" */,
  fireMoney: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005BE', '单价减') /* "单价减" */,
  addCartErrTip1: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005BF', '数量不能小于起订量') /* "数量不能小于起订量" */,
  addCartErrTip2: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005C0', '数量不能大于限购量') /* "数量不能大于限购量" */,
  addCartSuccess: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005C1', '成功加入购物车') /* "成功加入购物车" */,
  addCartErrTip3: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005C2', '加入购物车失败') /* "加入购物车失败" */,
  noSelected: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005C3', '未选择商品') /* "未选择商品" */,
  deleteSelected: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005C4', '删除选择商品') /* "删除选择商品" */,
  giveawayNumber: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005C5', '赠品计价数量') /* "赠品计价数量" */,
  stockPlaceholder: lang.templateByUuid('UID:P_UDHWN_17DD50DE050005C6', '请选择仓库') /* "请选择仓库" */,
  qocuxiao: lang.templateByUuid("UID:P_UDHWN_191ABDB00520000C","促销") /* "促销" */
};
