import corpParentView from '@/views/corp/home/home.vue';

const priceCenter = {
  path: 'priceCenter',
  title: { i18n: 'priceCenter' },
  name: 'priceCenter',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'productPriceSetting',
      title: { i18n: 'title.productPriceSetting' },
      name: 'productPriceSetting',
      meta: {
        module: 'productPriceSettingShows',
        notCache: true
      },
      component: () => import('@/views/corp/priceCenter/productPriceSetting/productPriceSetting.vue')
    },
    {
      path: 'productPriceShow',
      name: 'productPriceShow',
      meta: {
        title: '商品价格表',
        notCache: true,
        module: 'productPriceShow'
      },
      component: () => import('@/views/corp/priceCenter/productPriceShow/productPriceShow.vue')
    },
    {
      path: 'agentPriceSetting',
      title: { i18n: 'title.agentPriceSetting' },
      name: 'agentPriceSetting',
      meta: {
        notCache: true,
        module: 'agentPriceSettingShowes'
      },
      component: () => import('@/views/corp/priceCenter/agentPriceSetting/agentPriceSetting.vue')
    },
    {
      // 客户价格
      path: 'agnPriceShow',
      name: 'agnPriceShow',
      meta: {
        title: '客户价格表',
        notCache: true,
        module: 'agnPriceShow'
      },
      component: () => import('@/views/corp/priceCenter/agnPriceShow/agnPriceShow.vue')
    },
    {
      path: 'pricePlates',
      title: { i18n: 'title.pricePlates' },
      name: 'pricePlates',
      meta: { module: 'pricePlates' },
      component: () => import('@/views/corp/priceCenter/pricePlate/pricePlates.vue')
    },
    {
      path: 'pricePlate',
      title: { i18n: 'title.pricePlates' },
      name: 'pricePlate',
      meta: { module: 'pricePlates', notCache: true },
      component: () => import('@/views/corp/priceCenter/pricePlate/pricePlate.vue')
    },
    // 客户价格设置
    {
      path: 'agentPriceSettingShowes',
      title: { i18n: 'title.agentPriceSettingShowes' },
      name: 'agentPriceSettingShowes',
      meta: {
        module: 'agentPriceSettingShowes',
        title: '客户价格设置',
        notCache: true
      },
      component: () => import('@/views/corp/priceCenter/agentPriceSetting/agentPriceSettingShowes.vue')
    },
    // 商品价格设置
    {
      path: 'productPriceSettingShows',
      name: 'productPriceSettingShows',
      meta: {
        title: '商品价格设置',
        module: 'productPriceSettingShows',
        notCache: true
      },
      component: () => import('@/views/corp/priceCenter/productPriceSetting/productPriceSettingShows.vue')
    },
    // 商品价格设置详情
    {
      path: 'productPriceSettingShow',
      name: 'productPriceSettingShow',
      meta: {
        notCache: true,
        module: 'productPriceSettingShows'
      },
      component: () => import('@/views/corp/priceCenter/productPriceSetting/productPriceSettingShow.vue')
    },
    // 客户价格设置详情
    {
      path: 'agentPriceSettingShow',
      name: 'agentPriceSettingShow',
      meta: {
        notCache: true,
        module: 'agentPriceSettingShowes'
      },
      component: () => import('@/views/corp/priceCenter/agentPriceSetting/agentPriceSettingShow.vue')
    },
    // 客户折扣(商品)列表
    {
      path: 'customerDiscProds',
      name: 'customerDiscProds',
      meta: {
        module: 'customerDiscProds'
      },
      component: () => import('@/views/corp/priceCenter/customerDiscProd/customerDiscProds.vue')
    },
    // 客户折扣(商品)详情
    {
      path: 'customerDiscProd',
      name: 'customerDiscProd',
      meta: {
        module: 'customerDiscProds',
        notCache: true
      },
      component: () => import('@/views/corp/priceCenter/customerDiscProd/customerDiscProd.vue')
    },
    // 客户折扣(商品分类)列表
    {
      path: 'customerDiscProdCates',
      name: 'customerDiscProdCates',
      meta: {
        module: 'customerDiscProdCates'
      },
      component: () => import('@/views/corp/priceCenter/customerDiscProdCate/customerDiscProdCates.vue')
    },
    // 客户折扣(商品分类)列表
    {
      path: 'customerDiscProdCate',
      name: 'customerDiscProdCate',
      meta: {
        module: 'customerDiscProdCates',
        notCache: true
      },
      component: () => import('@/views/corp/priceCenter/customerDiscProdCate/customerDiscProdCate.vue')
    }
  ]
};

export default priceCenter;
