<template>
  <div class="agent-orgRelation">
    <!-- 展示下拉1 -->
    <Poptip v-if="currentUser.singleBizRelation && currentUser.singleBizRelation.isShowSingleBizSelect" trigger="hover" content="content" placement="bottom-start">
      <p class="text">
        <!-- 只有一级没有orgName -->
        <span>{{ showName }}</span>
        <i class="iconfont icon-xiangxia-copy"></i>
      </p>
      <Tree slot="content" ref="tree" class="agent-treeData" :data="currentUser.singleBizRelation.singleBizSelectTreeData" @on-select-change="onSelectChange" />
    </Poptip>
    <!-- 不展示下拉 -->
    <span v-else class="no-expand-text">{{ showName }}</span>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { findComponentUpward } from '@/u-components/utils/assist';

export default {
  name: 'OrgRelation',
  data() {
    return {
      ORDERSHOWWAY: this.$getBO('ORDERSHOWWAY') // 是否单商家模式
    };
  },
  computed: {
    ...mapGetters(['currentUser']),

    showName() {
      let name = '';
      if (this.currentUser.__isRepresent) {
        name = this.currentUser.corpName;
      } else {
        // ORDERSHOWWAY false 为单商家模式
        if (!this.ORDERSHOWWAY && (this.currentUser.__isMutiBiz || this.currentUser.__isMutiOrg)) {
          name = this.currentUser.singleBizRelation && this.currentUser.singleBizRelation.currentTradingBiz && this.currentUser.singleBizRelation.currentTradingBiz.title;
        } else {
          name = this.currentUser.corpName;
        }
      }
      // 交易关系被删除 选第一个
      if (!name) {
        let items = this.compileFlatState();
        if (items && items.length) {
          this.onSelectChange([items[0].node]);
        }
      }
      return name;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['setUserInfo', 'GetAgentRelations', 'GetShoppingNum']),
    init() {
      // this.getOrgRelationList();
    },
    compileFlatState() {
      // so we have always a relation parent/children of each node
      let keyCounter = 0;
      let childrenKey = this.childrenKey;
      const flatTree = [];
      function flattenChildren(node, parent) {
        node.nodeKey = keyCounter++;
        flatTree[node.nodeKey] = { node: node, nodeKey: node.nodeKey };
        if (typeof parent !== 'undefined') {
          flatTree[node.nodeKey].parent = parent.nodeKey;
          flatTree[parent.nodeKey][childrenKey].push(node.nodeKey);
        }

        if (node[childrenKey]) {
          flatTree[node.nodeKey][childrenKey] = [];
          node[childrenKey].forEach((child) => flattenChildren(child, node));
        }
      }
      this.currentUser.singleBizRelation.singleBizSelectTreeData.forEach((rootNode) => {
        flattenChildren(rootNode);
      });
      return flatTree;
    },
    onSelectChange(item) {
      if (item[0] && !item[0].currencyId) {
        this.$Modal.error({
          content: this.mlang('notHasCurrencyMessage')
        });
        return false;
      }
      if (item[0].bizId && item[0].agentRelationId) {
        if (item[0].agentRelationId === -1) {
          localStorage.upBizId = item[0].children[0].bizId;
          localStorage.upAgentRelationId = item[0].children[0].agentRelationId;
          localStorage.upBizTitle = item[0].children[0].title;
          localStorage.upOrgId = item[0].children[0].orgId;
        } else {
          localStorage.upBizId = item[0].bizId;
          localStorage.upAgentRelationId = item[0].agentRelationId;
          localStorage.upBizTitle = item[0].title;
          localStorage.upOrgId = item[0].orgId;
        }
        // 代下单和门户预览、零售跳转登录
        if (!this.ORDERSHOWWAY && localStorage.getItem('isRepresentToOrder') !== 'true' && localStorage.getItem('isCorpToOrder') !== 'true') {
          localStorage.storageRelationId = localStorage.upAgentRelationId;
        }

        // this.GetAgentRelations({ forceReload: true });
        // this.GetShoppingNum();
        let arr = ['iPaytype', 'recoProducts', 'productPageSize', 'allProducts', 'pcScartSelected', 'scartInvaild', 'storeOrder', 'regionList'];
        if (arr.length) {
          arr.map((v) => {
            if (window.localStorage.getItem(v)) {
              window.localStorage.removeItem(v);
            }
          });
        }
        if (!this.ORDERSHOWWAY && this.$route.name === 'aorder' && this.$route.query.source !== 'newOrder') {
          this.agentLink({ type: 'orderDetail', add: true, query: { source: 'newOrder' } });
        }
        // const agentMain = findComponentUpward(this, 'AgentMain');
        // agentMain && agentMain.resetPage();
        setTimeout(() => {
          window.location.reload();
        }, 0);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.agent-orgRelation {
  width: 600px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
