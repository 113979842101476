import myParentView from '@/views/agent/accounts/components/my.vue';
import account from './accounts'; // 个人信息
import userbase from './userbase'; // 账户信息
import users from './users'; // 人员管理
import role from './role'; // 角色管理
import roleTab from './roleTab'; // 功能权限
import data from './data'; // 业务数据权限
const accounts = {
  path: 'my',
  name: 'my',
  component: myParentView,
  children: [
    userbase,
    account,
    users,
    role,
    roleTab,
    data,
    {
      path: 'mutiAgentsList',
      name: 'mutiAgentsList',
      redirect: { name: 'mutiAgentsList' },
      meta: { module: 'mutiAgentsList' },
      children: [
        {
          path: 'mutiAgentsList',
          name: 'mutiAgentsList',
          meta: { module: 'mutiAgentsList' },
          component: () => import('@/views/agent/accounts/components/user/users.vue')
        },
        {
          path: 'mutiAgentsBaseInfo',
          name: 'mutiAgentsBaseInfo',
          meta: { module: 'mutiAgentsList' },
          component: () => import('@/views/agent/accounts/components/user/user.vue')
        }
      ],
      component: () => import('@/views/agent/accounts/components/user/users.vue')
    }
  ]
};

export default accounts;
