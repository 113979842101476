export default {
  expenseBill: lang.templateByUuid('UID:P_UDHWN_1B62196A04300004', '费用单') /* "费用单" */,
  expenseBillNumber: lang.templateByUuid('UID:P_UDHWN_1B62196A04300005', '费用单号') /* "费用单号" */,
  validityPeriod: lang.templateByUuid('UID:P_UDHWN_1B62196A04300006', '有效期') /* "有效期" */,
  useRecord: lang.templateByUuid('UID:P_UDHWN_1B62196A04300007', '使用记录') /* "使用记录" */,
  rebatesCreateDate: lang.templateByUuid('UID:P_UDHWN_1B62196A04300008', '创建时间') /* "创建时间" */,
  rebatesAuditStatus: lang.templateByUuid('UID:P_UDHWN_1B62196A04300009', '审核状态') /* "审核状态" */,
  rebatesdConfirmDate: lang.templateByUuid('UID:P_UDHWN_1B62196A0430000A', '审核时间') /* "审核时间" */,
  rebatesfRebateMoney: lang.templateByUuid('UID:P_UDHWN_1B62196A0430000B', '费用金额') /* "费用金额" */,
  rebatesusedMoney: lang.templateByUuid('UID:P_UDHWN_1B62196A0430000C', '使用金额') /* "使用金额" */,
  rebatesfSurplusMoney: lang.templateByUuid('UID:P_UDHWN_1B62196A0430000D', '余额') /* "余额" */,
  rebatescUseWayName: lang.templateByUuid('UID:P_UDHWN_1B62196A0430000E', '兑付方式') /* "兑付方式" */,
  rebatescVoucherTypeName: lang.templateByUuid('UID:P_UDHWN_1B62196A0430000F', '单据类型') /* "单据类型" */,
  rebastescOrderNos: lang.templateByUuid('UID:P_UDHWN_1B62196A04300010', '单据号') /* "单据号" */,
  rebatescreateDate: lang.templateByUuid('UID:P_UDHWN_1B62196A04300011', '使用时间') /* "使用时间" */,
  rebatesorderRebateMoney: lang.templateByUuid('UID:P_UDHWN_1B62196A0430000C', '使用金额') /* "使用金额" */,
  rebatesrecordStatusName: lang.templateByUuid('UID:P_UDHWN_1B62196A04300012', '使用状态') /* "使用状态" */,
  rebatesto: lang.templateByUuid('UID:P_UDHWN_1B62196A04300013', '至') /* "至" */
};
