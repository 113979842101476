import ajax from '@/libs/ajax';

export const fetchCurrentUserAuth = () => {
  return ajax.request({
    url: '/authority/function/currentuser/auth?isArchive=1',
    method: 'get'
  });
};

export const fetchLeftStatus = (data) => {
  const postData = {
    data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/permissionclassstatus/status',
    method: 'post',
    data: postData
  });
};

export const getRightAuthStatus = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/class/permissions',
    method: 'post',
    data: postData
  });
};

// 商品分类 新增自动有权限
export const getLeftTreeNodeAutoCreateFlag = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/owner/autoauthconfig',
    method: 'post',
    data: postData
  });
};
// 保存数据新权限接口
export const saveAuth = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/data/permissions',
    method: 'post',
    data: postData
  });
};

export const getLeftTreeMessage = (messageId) => {
  return ajax.request({
    url: `/amqp/message/${messageId}?isArchive=1`,
    method: 'get'
  });
};

// check权限接口
export const checkAuth = (code) => {
  const data = {
    auth: code
  };
  return ajax.request({
    url: '/authority/function/checkAuth?isArchive=1',
    method: 'get',
    data: data
  });
};

// 清空数据新权限接口
export const clearAuth = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/delete/permissions',
    method: 'post',
    data: postData
  });
};

// 客户权限导出
export const exportAgentAuth = (data) => {
  return ajax.request({
    url: '/pc/auth/AuthSrv/exportAgentBusiEntity',
    method: 'get',
    data
  });
};

// 人员商品权限导出
export const exportUserProductAuth = (data) => {
  return ajax.request({
    url: '/pc/auth/AuthSrv/exportPersonnelProduct',
    method: 'get',
    data
  });
};

// 人员客户权限导出
export const exportUserCustomerAuth = (data) => {
  return ajax.request({
    url: '/pc/auth/AuthSrv/exportPersonnelAgent',
    method: 'get',
    data
  });
};

//  清除所有客户商品权限
export const clearAllAuths = (autoAuthType) => {
  return ajax.request({
    url: `/auth/AuthSrv/delAllAuthData?autoAuthType=${autoAuthType}`,
    method: 'get'
  });
};
// 根据商品分类查询其下所有商品
export const fetchAuthProductByClassId = (data) => {
  data.isOnlyOneClass = true;
  return ajax
    .request({
      url: '/goods/product/list/auth/vo/page?pageindex=1&pagesize=20000',
      method: 'post',
      data: {
        data,
        isArchive: 1,
        datatype: 'json'
      }
    })
    .then((res) => {
      return res.content;
    });
};

export const getAllAuths = (data) => {
  return ajax.request({
    url: '/authority/function/0/authgroup/tree',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const getUserAuths = (id) => {
  return ajax.request({
    url: '/authority/function/getUserAuths',
    method: 'get',
    data: {
      id: id,
      isArchive: 1
    }
  });
};
export const getRoleAuths = (id) => {
  return ajax.request({
    url: '/authority/function/roleauth/' + id,
    method: 'get',
    data: {
      id: id,
      isArchive: 1
    }
  });
};
export const saveRoleAuths = (data) => {
  return ajax.request({
    url: '/authority/function/saveRoleAuths',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const saveUserAuths = (data) => {
  return ajax.request({
    url: '/authority/function/saveUserAuths',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 根据商品查询人员/客户权限
export const getUserAuthStatus = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/product/permissions',
    method: 'post',
    data: postData
  });
};
export const saveReferAuth = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/data/product/permissions',
    method: 'post',
    data: postData
  });
};
// 根据人员id查询是否为管理员角色
export const UserIsAdmin = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/permissionadminroles/status',
    method: 'post',
    data: postData
  });
};
// 批量查询有权客户
export const getBatchCustomerAuthStatus = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/agent/list',
    method: 'post',
    data: postData
  });
};
// 批量查询有权人员
export const getBatchUserAuthStatus = (data) => {
  const postData = {
    data: data,
    datatype: 'json',
    isArchive: 1
  };
  return ajax.request({
    url: '/authority/data/user/list',
    method: 'post',
    data: postData
  });
};
export const getSkuPermissions = (data) => {
  return ajax.request({
    url: '/authority/data/dimension/permissions',
    method: 'post',
    data: {
      isArchive: 1,
      data: data,
      datatype: 'json'
    }
  });
};
export const getHeartbeat = () => {
  return ajax.request({
    url: '/passport/heartbeat/ping?isArchive=1' + '&date=' + new Date().getTime(),
    method: 'get'
  });
};

/**
 * @method getLimitTime 获取限制时间
 * @params bizId集群
 */
export const getLimitTime = (params) => {
  return ajax.request({
    url: '/marketingbill/portal/order/cycle/setting',
    method: 'post',
    data: {
      data: params,
      datatype: 'json',
      isMarket: 1
    }
  });
};
