<template>
  <tr :class="rowClasses(row._index)"><slot></slot></tr>
</template>
<script>
import { findComponentUpward } from '../../utils/assist';
export default {
  props: {
    row: Object,
    prefixCls: String
  },
  computed: {
    parent(){
      return findComponentUpward(this ,'TableBody');
    },
    objData() {
      return this.parent.objData;
    }
  },
  methods: {
    rowClasses(_index) {
      return [
        `${this.prefixCls}-row`,
        this.rowClsName(_index),
        {
          [`${this.prefixCls}-row-highlight`]: this.objData[_index] && this.objData[_index]._isHighlight,
          [`${this.prefixCls}-row-sum`]: this.objData[_index] && this.objData[_index]._isSum,
          [`${this.prefixCls}-row-page-sum`]: this.objData[_index] && this.objData[_index]._isPageSum,
          [`${this.prefixCls}-row-all-sum`]: this.objData[_index] && this.objData[_index]._isAllSum,
          [`${this.prefixCls}-row-hover`]: this.objData[_index] && this.objData[_index]._isHover
        }
      ];
    },
    rowClsName(_index) {
      const table = findComponentUpward(this,'Table');
      return table.rowClassName(this.objData[_index], _index);
    }
  }
};
</script>
