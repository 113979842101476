import corpParentView from '@/views/corp/home/home.vue';

const integrationextension = {
  path: 'integrationextension',
  title: { i18n: 'integrationextension' },
  name: 'integrationextension',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'systemLog',
      title: { i18n: 'title.systemLog' },
      name: 'systemLog',
      component: () => import('@/views/corp/integrationextension/components/system-log.vue')
    },
    {
      path: 'extApis',
      title: { i18n: 'title.extApis' },
      name: 'extApis',
      meta: { module: 'extApis' },
      component: () => import('@/views/corp/integrationextension/components/extApi/ext-apis.vue')
    },
    {
      path: 'extApi',
      title: { i18n: 'title.extApi' },
      name: 'extApi',
      meta: { module: 'extApis', notCache: true },
      component: () => import('@/views/corp/integrationextension/components/extApi/ext-api.vue')
    },
    {
      path: 'errorLogs',
      title: { i18n: 'title.errorLogs' },
      name: 'errorLogs',
      component: () => import('@/views/corp/integrationextension/components/errorLog/errorlogs.vue')
    },
    {
      path: 'msgQueueLogs',
      title: { i18n: 'title.msgQueueLogs' },
      name: 'msgQueueLogs',
      meta: {
        module: 'msgQueueLogs'
      },
      component: () => import('@/views/corp/integrationextension/components/msgQueueLogs/msgQueueLogs.vue')
    },
    {
      path: 'defines',
      name: 'defines',
      component: () => import('@/views/corp/integrationextension/components/defines/defines.vue')

    },
    {
      path: 'clearOrder',
      name: 'clearOrder',
      meta: {
        module: 'clearOrder'
      },
      component: () => import('@/views/corp/integrationextension/components/clearOrder/clearOrder.vue')
    }
  ]
};
export default integrationextension;
