import { productPresaleList } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const productPresale = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: true,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'name',
      queryschema: [
        {
          name: 'name',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('demensionName'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('name'),
          fieldName: 'name',
          width: 250,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('presaleType'),
          isShowIt: true,
          width: 180,
          fieldName: 'presaleType',
          render: (h, params) => {
            if (params.row.presaleType === 1) {
              return <span>{vm.mlang('presaleStatus')}</span>;
            } else {
              return <span>{vm.mlang('bookStatus')}</span>;
            }
          }
        },
        {
          showCaption: vm.mlang('startDateTime'),
          isShowIt: true,
          width: 180,
          fieldName: 'startDate'
        },
        {
          showCaption: vm.mlang('endDateTime'),
          isShowIt: true,
          width: 180,
          fieldName: 'endDate'
        },
        {
          showCaption: vm.mlang('status'),
          isShowIt: true,
          width: 180,
          fieldName: 'isValid',
          render: (h, params) => {
            if (params.row.isValid) {
              return <span>{vm.mlang('enable')}</span>;
            } else {
              return <span>{vm.mlang('disable')}</span>;
            }
          }
        }

      ],
      actions: { queryList: productPresaleList }
    },
    ctrl
  );
  return deepCopy(control);
};
export default productPresale;
