const policyPromotionDetail = {
  path: 'promotionGroup/policyPromotionDetail',
  name: 'PolicyPromotionDetail',
  component: () => import('@/views/agent/promotionGroup/policyPromotionDetail'),
  meta: {
    menuCode: 'agentPromotionGroup'
  }
};

export default policyPromotionDetail;
