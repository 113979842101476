<template>
  <a
    v-if="params.row[column.name+'Href']"
    :class="classes"
    @click.prevent="linkHref">{{ params.row[column.name] }}
  </a>
  <span v-else>
    {{ params.row[column.name] }}
  </span>
</template>

<script>
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'OutHref',
  mixins: [CellMixin],
  props: {
    main: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return [
        {
          'a-normal': !this.main
        }
      ];
    }
  },
  methods: {
    linkHref() {
      const href = this.params.row[this.column.fieldName + 'Href'];
      const title = this.params.row[this.column.fieldName + 'Title'];
      this.$util.menuClickOldPage(href, this, { title });
    }
  }
};
</script>
