<template>
  <table
    :id="'billId_'+metaSetting.billId"
    :fieldid="'headCol_billId_'+metaSetting.billId"
    :style="styles"
    cellspacing="0"
    cellpadding="0"
    border="0">
    <colgroup :id="'billId_'+metaSetting.billId+'_HeadCol'">
      <col
        v-for="(column, index) in columns"
        :key="index"
        :style="`width: ${setCellWidth(column)}px`">
      <col
        v-if="$parent.showVerticalScrollBar"
        :width="$parent.scrollBarWidth" />
    </colgroup>
    <thead>
      <tr v-for="(cols, rowIndex) in headRows" :key="rowIndex">
        <th
          v-for="(column, index) in cols"
          :key="index"
          :colspan="column.colSpan"
          :rowspan="column.rowSpan"
          :class="alignCls(column)"
          :field-name="column.key">
          <div v-if="showTh(column)" :class="cellClasses(column)">
            <template v-if="column.type === 'expand'">
              <span v-if="!column.renderHeader">{{ column.title || '' }}</span>
              <render-header
                v-else
                :render="column.renderHeader"
                :column="column"
                :index="index"></render-header>
            </template>
            <template v-else-if="column.type === 'selection'">
              <Checkbox
                v-show="!column.isHideCheckAll"
                :value="isSelectAll"
                :indeterminate="indeterminate"
                :disabled="!data.length || (column.disabledFn && column.disabledFn(column))"
                @on-change="selectAll"></Checkbox>
            </template>
            <template v-else>
              <span
                v-if="!column.renderHeader"
                :title="column.title"
                :class="{[`one-line-overflow-hide`]:true,[prefixCls + '-cell-sort']: column.sortable}"
                @click="handleSortByHead(getColumn(rowIndex, index)._index)">{{ column.title }}</span>
              <render-header
                v-else
                :render="column.renderHeader"
                :column="column"
                :index="index"></render-header>
              <span
                v-if="column.sortable"
                :class="[prefixCls + '-sort']">
                <i
                  :class="{on: getColumn(rowIndex, index)._sortType === 'asc'}"
                  class="ivu-icon ivu-icon-arrow-up-b"
                  @click="handleSort(getColumn(rowIndex, index)._index, 'asc')"></i>
                <i
                  :class="{on: getColumn(rowIndex, index)._sortType === 'desc'}"
                  class="ivu-icon ivu-icon-arrow-down-b"
                  @click="handleSort(getColumn(rowIndex, index)._index, 'desc')"></i>
              </span>
              <Poptip
                v-if="isPopperShow(column)"
                v-model="getColumn(rowIndex, index)._filterVisible"
                placement="bottom"
                popper-class="ivu-table-popper"

                @on-popper-hide="handleFilterHide(getColumn(rowIndex, index)._index)">
                <span :class="[prefixCls + '-filter']">
                  <i
                    :class="{on: getColumn(rowIndex, index)._isFiltered}"
                    class="ivu-icon ivu-icon-ios-funnel-outline"></i>
                </span>

                <div
                  v-if="getColumn(rowIndex, index)._filterMultiple"
                  slot="content"
                  :class="[prefixCls + '-filter-list']">
                  <div :class="[prefixCls + '-filter-list-item']">
                    <checkbox-group v-model="getColumn(rowIndex, index)._filterChecked">
                      <checkbox
                        v-for="(item, index) in column.filters"
                        :key="index"
                        :label="item.value">
                        {{ item.label }}
                      </checkbox>
                    </checkbox-group>
                  </div>
                  <div :class="[prefixCls + '-filter-footer']">
                    <Button
                      :disabled="!getColumn(rowIndex, index)._filterChecked.length"
                      type="text"
                      size="small"
                      @click.native="handleFilter(getColumn(rowIndex, index)._index)">
                      {{ t('i.table.confirmFilter') }}
                    </Button>
                    <Button
                      type="text"
                      size="small"
                      @click.native="handleReset(getColumn(rowIndex, index)._index)">
                      {{ t('i.table.resetFilter') }}
                    </Button>
                  </div>
                </div>
                <div
                  v-else
                  slot="content"
                  :class="[prefixCls + '-filter-list']">
                  <ul :class="[prefixCls + '-filter-list-single']">
                    <li
                      :class="itemAllClasses(getColumn(rowIndex, index))"
                      @click="handleReset(getColumn(rowIndex, index)._index)">
                      {{ t('i.table.clearFilter') }}
                    </li>
                    <li
                      v-for="item in column.filters"
                      :key="item.value"
                      :class="itemClasses(getColumn(rowIndex, index), item)"
                      @click="handleSelect(getColumn(rowIndex, index)._index, item.value)">
                      {{ item.label }}
                    </li>
                  </ul>
                </div>
              </Poptip>
            </template>
          </div>
        </th>
        <th
          v-if="$parent.showVerticalScrollBar && rowIndex===0"
          :class="scrollBarCellClass()"
          :rowspan="headRows.length"></th>
      </tr>
    </thead>
  </table>
</template>
<script>
import renderHeader from './header';
import Mixin from './mixin';
import Locale from '../../mixins/locale';
import { initWidth } from '@/libs/table-width'; ;

export default {
  name: 'TableHead',
  components: { renderHeader },
  mixins: [Mixin, Locale],
  props: {
    prefixCls: String,
    styleObject: Object,
    columns: Array,
    objData: [Object, Array],
    data: Array, // rebuildData
    columnsWidth: Object,
    fixed: {
      type: [Boolean, String],
      default: false
    },
    columnRows: Array,
    fixedColumnRows: Array,
    indeterminate: {
      type: Boolean,
      default: false
    },
    metaSetting: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    styles() {
      const style = Object.assign({}, this.styleObject);
      const width = parseInt(this.styleObject.width);
      style.width = `${width}px`;
      return style;
    },
    isSelectAll() {
      return this.$parent.isSelectAll();
    },
    headRows() {
      const isGroup = this.columnRows.length > 1;
      if (isGroup) {
        return this.fixed ? this.fixedColumnRows : this.columnRows;
      } else {
        return [this.columns];
      }
    }
  },
  mounted() {
    setTimeout(() => this.initWidth(`billId_${this.metaSetting.billId}`, this.styleObject), 500);
  },
  methods: {
    initWidth(id) {
      return initWidth(id);
    },
    cellClasses(column) {
      return [
        `${this.prefixCls}-cell`,
        {
          [`${this.prefixCls}-hidden`]: !this.fixed && column.fixed && (column.fixed === 'left' || column.fixed === 'right' || column.fixed === 'suspend')
        }
      ];
    },
    scrollBarCellClass() {
      let hasRightFixed = false;
      for (let i in this.headRows) {
        for (let j in this.headRows[i]) {
          if (this.headRows[i][j].fixed === 'right') {
            hasRightFixed = true;
            break;
          }
          if (hasRightFixed) break;
        }
      }
      let hasSuspendFixed = false;
      for (let i in this.headRows) {
        for (let j in this.headRows[i]) {
          if (this.headRows[i][j].fixed === 'suspend') {
            hasSuspendFixed = true;
            break;
          }
          if (hasSuspendFixed) break;
        }
      }
      return [
        {
          [`${this.prefixCls}-hidden`]: hasRightFixed || hasSuspendFixed
        }
      ];
    },
    itemClasses(column, item) {
      return [
        `${this.prefixCls}-filter-select-item`,
        {
          [`${this.prefixCls}-filter-select-item-selected`]: column._filterChecked[0] === item.value
        }
      ];
    },
    itemAllClasses(column) {
      return [
        `${this.prefixCls}-filter-select-item`,
        {
          [`${this.prefixCls}-filter-select-item-selected`]: !column._filterChecked.length
        }
      ];
    },
    selectAll() {
      const status = !this.isSelectAll;
      this.$parent.selectAll(status);
    },
    handleSort(index, type) {
      const column = this.columns[index];
      const _index = column._index;

      if (column._sortType === type) {
        type = 'normal';
      }
      this.$parent.handleSort(_index, type);
    },
    handleSortByHead(index) {
      const column = this.columns[index];
      if (column.sortable) {
        const type = column._sortType;
        if (type === 'normal') {
          this.handleSort(index, 'asc');
        } else if (type === 'asc') {
          this.handleSort(index, 'desc');
        } else {
          this.handleSort(index, 'normal');
        }
      }
    },
    handleFilter(index) {
      this.$parent.handleFilter(index);
    },
    handleSelect(index, value) {
      this.$parent.handleFilterSelect(index, value);
    },
    handleReset(index) {
      this.$parent.handleFilterReset(index);
    },
    handleFilterHide(index) {
      this.$parent.handleFilterHide(index);
    },
    // 因为表头嵌套不是深拷贝，所以没有 _ 开头的方法，在 isGroup 下用此列
    getColumn(rowIndex, index) {
      const isGroup = this.columnRows.length > 1;

      if (isGroup) {
        const id = this.headRows[rowIndex][index].__id;
        return this.columns.filter((item) => item.__id === id)[0];
      } else {
        return this.headRows[rowIndex][index];
      }
    },
    showTh(column) {
      let show = true;
      let fixHeader = !!this.fixed;
      let fixColumn = !!column.isFixed;
      if (fixHeader) {
        if (fixColumn) {
          show = true;
        } else {
          show = false;
        }
      } else {
        if (fixColumn) {
          show = false;
        } else {
          show = true;
        }
      }

      return show;
    }
  }
};
</script>
