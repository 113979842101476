import { deliveryAddressList } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const deliveryAddressRefer = (ctrl, vm) => {
  const tableHeader = [
    {
      title: vm.mlang('fahuoren'),
      isShowIt: true,
      key: 'receiver',
      width: 80
    },
    {
      title: vm.mlang('fahuodizhi'),
      isShowIt: true,
      key: 'receiveAddress',
      width: 260,
      render: (h, params) => {
        let { row } = params;
        let data = `${row.mergerName || ''}${row.address || ''}`;
        const defaultShippingAddrsStyle = {
          textAlign: 'center',
          borderRadius: '2px 2px 6px 0px',
          marginRight: '5px',
          padding: '0 4px',
          height: '18px',
          lineHeight: '16px',
          display: 'inline-block',
          transform: 'scale(0.9)',
          border: '1px solid #f04134',
          color: '#f04134',
          boxSizing: 'border-box'
        };
        return <span title={data} class={'two-line-overflow-hide'}>{(row.isDefault ? (<span style={defaultShippingAddrsStyle} class={'default-shippingAddrs'}>{vm.mlang('default')}</span>) : '')}{data}</span>;
      }
    },
    {
      title: vm.mlang('zipCode'),
      isShowIt: true,
      key: 'zipCode',
      width: 120
    },
    {
      title: vm.mlang('mobile'),
      isShowIt: true,
      key: 'mobile',
      width: 120
    },
    {
      title: vm.mlang('telphone'),
      isShowIt: true,
      key: 'telePhone',
      width: 120
    },
    {
      title: `${vm.mlang('mail')}`,
      isShowIt: true,
      key: 'email',
      width: 120
    }
  ];
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      hideQueryschema: true,
      refName: 'deliveryInfo',
      // 传递过去的参数
      passedparams: [
      ],
      queryschema: [
      ],
      columns: tableHeader,
      actions: { queryList: deliveryAddressList },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default deliveryAddressRefer;
