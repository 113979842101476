export default {
  errorMsgTips: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000771","请正确填写！") /* "请正确填写！" */,
  nonNullTips: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000772","内容不能为空！") /* "内容不能为空！" */,
  doNeedforCustomerToPayFees: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000773","是否需要客户支付手续费") /* "是否需要客户支付手续费" */,
  joinMembership: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000774","开通会员") /* "开通会员" */,
  commission: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000775","佣金") /* "佣金" */,
  save: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000776","保存") /* "保存" */,
  edit: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000777","编辑") /* "编辑" */,
  rate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000778","费率") /* "费率" */,
  ratePlaceHolder: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000779","费率千分之一,输入:0.001") /* "费率千分之一,输入:0.001" */,
  rateLabel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500077A","手续费率: 千分之") /* "手续费率: 千分之" */,
  uploadAttach: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500077B","上传附件") /* "上传附件" */,
  fastRateLabel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500077C","(快捷)费率: 千分之") /* "(快捷)费率: 千分之" */,
  maximumHandlingFeeLabel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500077D","每笔最高手续费：") /* "每笔最高手续费：" */,
  minimumHandlingFeeLabel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500077E","每笔最低手续费：") /* "每笔最低手续费：" */,
  checkAccount: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500077F","查账") /* "查账" */,
  register: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000780","注册") /* "注册" */,
  onlineOpen: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000781","开启") /* "开启" */,
  close: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000782","关闭") /* "关闭" */,
  yuan: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000783","元") /* "元" */,
  switchDisabledTips: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000784","开关当前不能启用,先完善支付接口设置信息！") /* "开关当前不能启用,先完善支付接口设置信息！" */,
  phoneNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000785","手机号") /* "手机号" */,
  pleaseEnterVerifyCodeFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000786","请输入验证码") /* "请输入验证码" */,
  pleaseEnterjiaoyanma: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000787","请输入校验码") /* "请输入校验码" */,
  payingVisibleUsers: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000788","支付启用") /* "支付启用" */,
  needFeesUsers: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000789","手续费承担") /* "手续费承担" */,
  chanPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078A","畅捷支付") /* "畅捷支付" */,
  HSY: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078B","好收银") /* "好收银" */,
  aliPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078C","支付宝") /* "支付宝" */,
  wechat: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078D","微信") /* "微信" */,
  wechatPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078E","微信支付") /* "微信支付" */,
  mailianPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078F","脉链支付") /* "脉链支付" */,
  cloudBill: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000790","云账单") /* "云账单" */,
  bankOnlinePay: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000791","银行在线支付") /* "银行在线支付" */,
  unionPayBusiness: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000792","银联商务") /* "银联商务" */,
  instantArrival: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000793","即时到账") /* "即时到账" */,
  mobilePayment: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000794","手机支付") /* "手机支付" */,
  onlineBankingPayment: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000795","网银支付") /* "网银支付" */,
  PCTerminal: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000796","电脑端") /* "电脑端" */,
  mobileTerminal: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000797","手机端") /* "手机端" */,
  PCTerminalSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000798","电脑端设置") /* "电脑端设置" */,
  mobileTerminalSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000799","手机端设置") /* "手机端设置" */,
  scanCodePayment: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500079A","扫码支付") /* "扫码支付" */,
  publicAccountPayment: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500079B","公众号支付") /* "公众号支付" */,
  account: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500079C","账号") /* "账号" */,
  please: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500079D","请") /* "请" */,
  enter: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500079E","输入") /* "输入" */,
  merchant: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500079F","商户") /* "商户" */,
  secretKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A0","密钥") /* "密钥" */,
  publicKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A1","公钥") /* "公钥" */,
  payment: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A2","支付") /* "支付" */,
  number: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A3","号") /* "号" */,
  ABCBank: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A4","农业银行") /* "农业银行" */,
  EManager: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A5","农行e管家") /* "农行e管家" */,
  ABCEManager: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A6","农行e管家(A)") /* "农行e管家(A)" */,
  CMBBank: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A7","招商银行") /* "招商银行" */,
  ChinaConstructionBank: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A8","建设银行") /* "建设银行" */,
  partnerID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007A9","合作者身份ID") /* "合作者身份ID" */,
  EHouseKeeper: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007AA","e管家") /* "e管家" */,
  publicAccount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007AB","公众号") /* "公众号" */,
  AppSecretLabel: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007AC","AppSecret(应用密钥)") /* "AppSecret(应用密钥)" */,
  AppSecret: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007AD","应用密钥") /* "应用密钥" */,
  creditCard: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007AE","信用卡") /* "信用卡" */,
  debitCard: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007AF","储蓄卡") /* "储蓄卡" */,
  publicAccounts: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B0","对公账户") /* "对公账户" */,
  externalMemberNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B1","外部会员号") /* "外部会员号" */,
  memberNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B2","会员号") /* "会员号" */,
  memberName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B3","会员名称") /* "会员名称" */,
  accountType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B4","账号类型") /* "账号类型" */,
  individual: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B5","个人") /* "个人" */,
  business: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B6","企业") /* "企业" */,
  selfEmployed: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B7","个体工商户") /* "个体工商户" */,
  merchantNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B8","商户号") /* "商户号" */,
  agentNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007B9","用户号") /* "用户号" */,
  terminalNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007BA","终端号") /* "终端号" */,
  merPrivateKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007BB","商户私钥证书") /* "商户私钥证书" */,
  platformPublicKye: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007BC","平台公钥证书") /* "平台公钥证书" */,
  certiKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007BD","证书密码") /* "证书密码" */,
  institutionalMerchantNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007BE","机构商户号") /* "机构商户号" */,
  source: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007BF","消息来源") /* "消息来源" */,
  SourceNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C0","来源编号") /* "来源编号" */,
  MD5Key: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C1","MD5密钥") /* "MD5密钥" */,
  AESKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C2","AES密钥") /* "AES密钥" */,
  merchantID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C3","商户ID") /* "商户ID" */,
  settlement: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580003A","结算方式") /* "结算方式" */,
  platformMerchantNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C4","平台商户号") /* "平台商户号" */,
  beneficiaryMerchantID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C5","收款商户编号") /* "收款商户编号" */,
  chinaMerchantsBankPublicKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C6","招商银行公钥") /* "招商银行公钥" */,
  chinaMerchantsBankAPPID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C7","招商银行APPID") /* "招商银行APPID" */,
  chinaMerchantsBankAPPSecretKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C8","招商银行秘钥") /* "招商银行秘钥" */,
  merchantCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007C9","商家编码") /* "商家编码" */,
  merchantPrivateKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007CA","商家私钥") /* "商家私钥" */,
  groupCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007CB","集团编码") /* "集团编码" */,
  WechatOfficialAccountAPPID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007CC","微信公众号APPID") /* "微信公众号APPID" */,
  cashierID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007CD","收银员ID") /* "收银员ID" */,
  storeID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007CE","门店ID") /* "门店ID" */,
  platformNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007CF","平台编号") /* "平台编号" */,
  merchantType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D0","商户类型") /* "商户类型" */,
  managerPhone: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D1","管理员手机号") /* "管理员手机号" */,
  companyName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D2","企业名称") /* "企业名称" */,
  companyType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D3","企业类型") /* "企业类型" */,
  companyNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D4","企业代码") /* "企业代码" */,
  companyNoType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D5","代码类型") /* "代码类型" */,
  corporateName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D6","法人姓名") /* "法人姓名" */,
  corporatePhone: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D7","法人手机号") /* "法人手机号" */,
  corporateNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D8","证件号码") /* "证件号码" */,
  corporateNoType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007D9","证件类型") /* "证件类型" */,
  profitOrganizations: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007DA","营利性组织") /* "营利性组织" */,
  nonProfitOrganizations: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007DB","非营利性组织") /* "非营利性组织" */,
  bizRegistrationNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007DC","企业注册号") /* "企业注册号" */,
  unifiedSocialCreditCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007DD","统一社会信用代码") /* "统一社会信用代码" */,
  IDcard: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007DE","身份证") /* "身份证" */,
  officerID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007DF","军官证") /* "军官证" */,
  passport: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E0","护照") /* "护照" */,
  accountBook: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E1","户口簿") /* "户口簿" */,
  soldierCard: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E2","士兵证") /* "士兵证" */,
  mainlandTravelPermitForHongKongAndMacao: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E3","港澳来往内地通行证") /* "港澳来往内地通行证" */,
  mainlandTravelPermitForTaiwanCompatriots: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E4","台湾同胞来往内地通行证") /* "台湾同胞来往内地通行证" */,
  temporaryID: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E5","临时身份证") /* "临时身份证" */,
  residencePermit: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E6","外国人居留证") /* "外国人居留证" */,
  policeCard: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E7","警官证") /* "警官证" */,
  other: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E8","其他") /* "其他" */,
  examine: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007E9","查看") /* "查看" */,
  splitManagement: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007EA","分账管理") /* "分账管理" */,
  CHANPAY: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078A","畅捷支付") /* "畅捷支付" */,
  ALIPAY: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007EB","支付宝支付") /* "支付宝支付" */,
  WXPAY: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500078E","微信支付") /* "微信支付" */
};
