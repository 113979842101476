import customerList from './customerList';
import customerGroups from './customerGroups';

export default {
  viewCustomer: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000466","查看客户") /* "查看客户" */,
  usernameError: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000467","登录账号输入有误") /* "登录账号输入有误" */,
  forProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000468","对商品") /* "对商品" */,
  haveHauthCoustomers: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000469","有权的客户") /* "有权的客户" */,
  haveHauthUsers: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500046A","有权的人员") /* "有权的人员" */,
  customerGroups,
  customerList
};
