import corpParentView from '@/views/corp/home/home.vue';

const products = {
  path: 'products',
  title: { i18n: 'product' },
  name: 'products',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'product',
      title: { i18n: 'title.product' },
      name: 'product',
      meta: { module: 'product' },
      component: () => import('@/views/corp/product/components/product.vue')
    },
    {
      path: 'brands',
      title: { i18n: 'title.brands' },
      name: 'brands',
      meta: { module: 'brands' },
      component: () => import('@/views/corp/product/components/brand/brands.vue')
    },
    {
      path: 'brand',
      title: { i18n: 'title.brands' },
      name: 'brand',
      meta: { module: 'brands', notCache: true },
      component: () => import('@/views/corp/product/components/brand/brand.vue')
    },
    {
      path: 'productlines',
      title: { i18n: 'title.productlines' },
      name: 'productlines',
      meta: { module: 'productlines' },
      component: () => import('@/views/corp/product/components/productline/productlines.vue')
    },
    {
      path: 'productline',
      title: { i18n: 'title.productlines' },
      name: 'productline',
      meta: { module: 'productlines', notCache: true },
      component: () => import('@/views/corp/product/components/productline/productline.vue')
    }
  ]
};

export default products;
