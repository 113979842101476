import XEUtils from 'xe-utils/methods/xe-utils';
import VXETable from './v-x-e-table';
import Table from './table';
import Column from './column';
import Header from './header';
import Body from './body';
import Loading from './loading';
import Grid from './grid';
import Filter from './filter';
import Edit from './edit';
import Resize from './resize';

// 按需加载的组件
export const components = [
  // 模块
  Column,
  Header,
  Body,
  Loading,
  Grid,
  Edit,
  Resize,
  Filter,
  // 核心
  Table
];

// 默认安装
function install(Vue, options) {
  if (XEUtils.isPlainObject(options)) {
    VXETable.setup(options);
  }
  components.map(component => Vue.use(component));
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

VXETable.install = install;

export * from './v-x-e-table';
export * from './column';
export * from './header';
export * from './body';
export * from './loading';
export * from './grid';
export * from './edit';
export * from './resize';
export * from './filter';
export * from './table';
export default VXETable;
