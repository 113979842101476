import { getCurrency } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const currencyRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: false,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'name',
      queryschema: [
      //   {
      //     name: 'value',
      //     controlType: 'String',
      //     groupType: 2,
      //     valueType: 'STRING',
      //     isShowIt: true,
      //     placeholder: vm.mlang('nameOrCode'),
      //     conditionType: 'like'
      //   }
      ],
      columns: [
        {
          showCaption: vm.mlang('name'),
          fieldName: 'name',
          width: 250,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('currencyAbbr'),
          isShowIt: true,
          width: 180,
          fieldName: 'code'
        }
      ],
      actions: { queryList: getCurrency }
    },
    ctrl
  );
  return deepCopy(control);
};
export default currencyRefer;
