<style lang="less">
@import './msg-push.less';
</style>
<template>
  <Poptip
    content="content"
    trigger="hover"
    :placement="placement"
    :offset="offset"
    @on-popper-show="onPopperShow">
    <div
      v-if="!currentUser.__isAgentSide"
      class="message-con"
      @click="showMessage">
      <Badge
        :count="value"
        overflow-count="99">
        <SvgIcon
          class="message-logo"
          icon-class="message"></SvgIcon>
      </Badge>
    </div>
    <div v-if="currentUser.__isAgentSide">
      <span class="text">
        {{ mlang('pushmessage') }}
        <i :class="['iconfont', 'icon-beizhu', {'msgred': !!msgList.length}]"></i>
        <span v-if="msgUnreadCount > 0" class="unreadCount">({{ msgUnreadCount }})</span>
      </span>
      <span class="iconfont icon-xiangxia-copy"></span>
    </div>
    <div
      slot="content"
      class="message-content">
      <div
        v-for="item in data"
        :key="item.id"
        class="subject"
        @click="toDetail(item.bulletinVo)">
        <P v-if="item.bulletinVo.title" class="cTitle">
          {{ item.bulletinVo.title }}
        </P>
        <P v-if="item.bulletinVo.voucherNo" class="cVoucherNo">
          {{ item.bulletinVo.voucherNo }}
        </P>
        <P v-if="item.bulletinVo.content && item.bulletinVo.content.content" class="cVoucherNo">
          {{ item.bulletinVo.content.content }}
        </P>
        <p v-if="item.bulletinVo.content && item.bulletinVo.content.notifyTime" class="dCreated">
          {{ item.bulletinVo.content.notifyTime }}
        </p>
      </div>
      <div
        v-if="!data.length"
        class="no-message">
        <div class="no-message-title">
          {{ mlang('nomessage') }}
        </div>
        <div class="no-message-image"></div>
      </div>
      <div
        class="seeAll"
        style="text-align: center;"
        @click="showMessage">
        {{ mlang('showMessage') }}
      </div>
    </div>
  </Poptip>
</template>

<script>
import { mapGetters } from 'vuex';
import MessageMixin from '@/mixin/message.js';
import store from '@/store';

export default {
  name: 'MsgPush',
  mixins: [MessageMixin],
  props: {
    offset: {
      type: Number,
      default: 12
    },
    placement: {
      type: String,
      default: 'bottom-end'
    }
  },
  data: () => {
    return {
      hasFetch: false,
      data: []
    };
  },
  computed: {
    msgUnreadCount() {
      return this.$store.getters.newsCount;
    },
    msgList() {
      return this.$store.getters.newMsg;
    },
    ...mapGetters(['currentUser'])
    // data() {
    //   return this.$store.getters.newMsg;
    // }
  },
  created() {
    setInterval(() => {
      store.dispatch('GetUserNewsCount');
    }, 1000 * 60 * 10);
  },
  methods: {
    toDetail(item) {
      this.mixin_clickBulletinDetail(item, this);
    },
    onPopperShow() {
      if (this.msgList && this.msgList.length) {
        this.msgList.map(v => {
          if (v.bulletinVo.content && v.bulletinVo.dataType === 'JSON') {
            if (typeof v.bulletinVo.content === 'string') {
              v.bulletinVo.content = JSON.parse(v.bulletinVo.content);
            }
          } else {
            v.bulletinVo.content = {};
          }
        });
        this.data = this.msgList;
      }
    },
    showMessage() {
      // util.openNewPage(this, 'message_index');
      if (this.currentUser.__isAgentSide) {
        this.$router.push('/agent/message/index');
      } else {
        this.$router.push({
          name: 'message'
        });
      }
      // window.open('/v#/corp/message');
    }
  }
};
</script>
