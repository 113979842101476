<template>
  <div>
    <VoucherList
      ref="voucherList"
      :data="data"
      :query="query"
      :biz-meta="meta">
    </VoucherList>
  </div>
</template>

<script>
import VoucherList from '_c/common/voucher-list';
import Mixin from '@/mixin/archiveList.js';
import Emitter from '@/u-components/mixins/emitter';
import ArchiveListMixin from '@/mixin/archive-list.js';
import { getCustomerRefund } from '@/api/customerRefund.js';
import { upCoinDigit } from '@/filters';
export default {
  name: 'CustomerRefund',
  components: { VoucherList },
  mixins: [Emitter, Mixin, ArchiveListMixin],
  data() {
    const meta = this.getDefaultMeta();
    return {
      data: {},
      query: this.queryList,
      meta,
      isLoaing: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {
    getDefaultMeta() {
      var that = this;
      const meta = {
        billId: 331,
        isAgent: false,
        actions: {},
        async: true,
        setBillMeta(bill) {
          // 将单据时间改为单据日期
          let dPayFinishDate = bill.currentTemplate.currentTplGroups[0].currentBillItems.find((v) => {
            return v.fieldName === 'dPayFinishDate';
          });
          dPayFinishDate.caption = that.mlang('documentDate');
          dPayFinishDate.showCaption = that.mlang('documentDate');
          return bill;
        }
      };
      return meta;
    },
    queryList(query) {
      query.datatype = 'json';
      if (this.isLoaing) {
        return;
      }
      this.isLoaing = true;
      let getCustomerRefundParmas = { 'conditions': [], 'pager': { 'pageIndex': 1, 'pageSize': 10, 'pageSizeOpts': [10, 20, 30, 50], 'currentPage': 1, 'totalCount': 0 } };
      getCustomerRefund(getCustomerRefundParmas).then((res) => {
        // const paymentIds = [];
        res.content.forEach((v) => {
          v.iAmount = upCoinDigit(v.iAmount);
          v.clonePayNo = v.cPayNo;
          let to = {};
          to = {
            name: 'refund',
            query: { id: v.id }
          };
          v.cPayNoTo = to;
        });
        res.total = res.totalCount;
        this.isLoaing = false;
        this.data = res;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.voucher-list /deep/ .ivu-table-footer {
  .bottom-page {
    box-shadow: none;
    border-bottom: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
