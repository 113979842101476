const productPromotionDetail = {
  path: 'promotionGroup/productPromotionDetail',
  name: 'productPromotionDetail',
  component: () => import('@/views/agent/promotionGroup/productPromotionDetail'),
  meta: {
    menuCode: 'agentPromotionGroup'
  }
};

export default productPromotionDetail;
