const expense = {
  path: 'aorders/expense',
  name: 'Expense',
  component: () => import('@/views/agent/expense/expense.vue'),
  meta: {
    menuCode: 'agentOrderShow'// 新页面之间跳转，跳转页面时选中对应菜单，值从config.js里找需要选中的菜单的code
  }
};

export default expense;
