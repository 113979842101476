import ajax from '@/libs/ajax';
import util from '@/libs/util.js';
import store from '@/store/index.js';

export const getAgentLevelSelect = () => {
  return ajax.request({ url: '/agent/infrastructure/agentlevel/list?isArchive=1', method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
export const getAgentIndustrySelect = () => {
  return ajax.request({ url: '/pc/agent/AgentIndustrySrv/getAgentIndustryListSimpleInfo', method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};

// 客户分组带权限
export const getAgentGroupAuthSelect = (query) => {
  return ajax.request(
    {
      url: '/pc/agent/AgentDataAuthGroupSrv/getAgentAuthGroupListWithNullGroup', method: 'get', data: query
    }
  ).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
export const getBizsSelect = () => {
  return ajax.request({ url: '/orginazation/merchant/user/list?isArchive=1', method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
// 获取当前用户有权限的组织
/**
 *获取当前用户任职的组织
 * @param orgId
 * @return
 */
export const fetchUserOrgs = () => {
  return ajax.request({
    url: '/orginazation/org/user/list',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 获取当前用户的销售组织
export const getSaleOrgsSelect = () => {
  return ajax.request({ url: '/orginazation/org/user/list/2', method: 'get', data: { isArchive: 1 } }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
// 获取当前用户的库存组织
export const getStockOrgsSelect = () => {
  return ajax.request({ url: '/orginazation/org/user/list/8', method: 'get', data: { isArchive: 1 } }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
// 根据销售组织id获取财务组织
export const getSettlementOrgsSelectBySaleOrgId = (salesOrgId) => {
  return ajax.request({ url: `/orginazation/bizproxy/${salesOrgId}/settlement/org/list`, method: 'get', data: { isArchive: 1 } }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};

export const getRefundPayDirectionsSelect = () => {
  return ajax.request({ url: '/pc/RefundSrv/getRefundPayDirections', method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        // v.value = v.value;
        v.title = v.name;
      });
    return data;
  });
};

export const getFinalSettlementWaySelect = () => {
  return ajax.request({ url: window.__app.context.productLine === 'u8c' ? '/voucher/order/settlementway/modelVo?isArchive=1' : '/pc/basic/BaseDataSrv/getFinalSettlementWay', method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.cName;
        v.name = v.cName;
      });
    return data;
  });
};
// 获取库存
export const getStockSelect = () => {
  return ajax.request({ url: '/goods/inventory/stock/getStocksWithAuth?isArchive=1', method: 'get' }).then((data) => {
    data &&
      data.data &&
      data.data.map((v) => {
        v.value = v.id;
        v.title = v.cName;
      });
    return data.data;
  });
};

// 获取品牌
export const getBrandsSelect = () => {
  return ajax.request({ url: '/orginazation/brand/enable/list?isArchive=1', method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
// 获取产品线
export const getProductlinesSelect = () => {
  return ajax.request({ url: '/orginazation/productline/enable/list?isArchive=1', method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};

// 渠道客户分类
export const getChannelAgentCategory = () => {
  return ajax
    .request({
      url: '/agent/infrastructure/category/platform/tree',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      return util.reduceChildren(data, 'subNodes', 'name');
    });
};
// 渠道客户区域
export const getChannelAgentArea = () => {
  return ajax
    .request({
      url: '/agent/infrastructure/area/platform/tree',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      return util.reduceChildren(data, 'subNodes', 'name');
    });
};
// 渠道客户级别
export const getChannelAgentLevel = () => {
  return ajax.request({
    url: '/agent/infrastructure/agentlevel/platform/list',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 渠道客户行业
export const getChannelAgentIndustry = () => {
  return ajax.request({
    url: '/agent/infrastructure/industry/platform/list',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

/**
 * 获取销售管理维度所有组织
 * @return
 */
export const fetchSaleOrgs = (authId) => {
  return ajax
    .request({
      url: (typeof authId === 'string') ? `/orginazation/org/user/list/2?isArchive=1&authId=${authId}` : '/orginazation/org/user/list/2?isArchive=1',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      return util.reduceChildren(data, 'aaaa', 'name', 'code', 'id');
    });
};

/**
 * 根据bizId获取组织
 * @return
 */
export const getStocks = (bizId) => {
  return ajax.request({
    url: '/goods/inventory/stock/list/vo/query',
    method: 'post',
    data: {
      data: { bizId },
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 获取公告分类
export const getNoticeClasses = (data) => {
  return ajax
    .request({
      url: '/archives/notice/category/list',
      method: 'get',
      data: {
        orgId: data,
        isArchive: 1
      }
    })
    .then((data) => {
      return util.reduceChildren(data, 'aaaa', 'name', 'code', 'id');
    });
};

// 公告获取维度类型
export const getAgentPriceSettingPlatformType = (data) => {
  return ajax
    .request({
      url: '/goods/price/customerjustify/platformtype',
      method: 'get',
      data: { isArchive: 1 }
    })
    .then((data) => {
      return util.reduceChildren(data, 'aaaa', 'name', 'value', 'value');
    });
};

// 获取角色
export const fetchRoles = () => {
  return ajax
    .request({
      url: '/auth/infrastructure/roles',
      method: 'get',
      data: { isArchive: 1 }
    })
    .then((data) => {
      return util.reduceChildren(data, 'aaaa', 'name', 'code');
    });
};

// 获取订货端角色
export const fetchAgentRoles = () => {
  let query = {
    conditions: [],
    pager: { pageIndex: 1, pageSize: 9999 }
  };
  return ajax.request({
    url: '/auth/infrastructure/agentrole/page',
    method: 'post',
    data: {
      data: query,
      datatype: 'json',
      isArchive: 1
    }
  }).then((data) => {
    return util.reduceChildren(data.content, 'aaaa', 'name', 'code');
  });
};
// 角色
export const roles = () => {
  return ajax.request({ url: '/auth/infrastructure/roles', data: { isArchive: 1 }, method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'subAreas', 'name');
  });
};

// 获取客户调价单维度商家
export const getAgentPriceSettingMerchantType = (data) => {
  return ajax
    .request({
      url: '/goods/price/customerjustify/merchanttype',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      data &&
        data.map((v) => {
          v._value = v.value;
        });
      return util.reduceChildren(data, 'aaaa', 'name', 'value', 'value');
    });
};

// 促销获取维度类型
export const dimensiontypeSingleDiscountShow = (data) => {
  return ajax
    .request({
      url: '/goods/promotion/dimensiontype',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      // u8c环境过滤掉
      if (store.state.user.currentUser.__isU8C) {
        data = data.filter((v) => {
          return v.name !== 'AGENTGROUP';
        });
      }
      data.map((v) => {
        v.cName = v.title;
      });
      return data;
    });
};
// 获取公告维度接口
export const getNoticeDemensions = () => {
  return ajax.request({
    url: '/archives/notice/demensionenum/client',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 库存量列表获取仓库
export const stockListAction = (data) => {
  return ajax
    .request({
      url: '/goods/inventory/stock/getStocks?isArchive=1',
      method: 'get',
      data: {}
    })
    .then((data) => {
      return util.reduceChildren(data, 'aaaa', 'cName', 'cCode', 'id');
    });
};
// 获取财务组织
export const fetchfinOrgs = () => {
  return ajax
    .request({
      url: '/orginazation/org/user/list/16',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      return util.reduceChildren(data, 'aaaa', 'name', 'code', 'id');
    });
};
// 根据组织id查仓库
export const getStockByOrg = (data = {}) => {
  return ajax.request({
    url: '/goods/inventory/stock/list/query',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 获取发票类型
export const getInvoiceType = () => {
  return ajax.request({
    url: '/electronic/invoicType',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 根据saleOrgId获取财务组织
export const getSettlementOrgsBySaleOrgId = (saleOrgId) => {
  return ajax.request({
    url: '/orginazation/bizproxy/saletosettlementorg/list',
    method: 'post',
    data: {
      data: [{
        saleOrgId
      }],
      datatype: 'json',
      isArchive: 1
    }
  }).then((res) => {
    return (res && res[0] && res[0].settlementOrgs) || [];
  });
};
export const getPopularBanks = () => {
  return ajax.request({
    url: '/archives/bank/hot/list?isArchive=1',
    method: 'get'
  });
};

export const getAllBanks = () => {
  return ajax.request({
    url: '/archives/bank/list?isArchive=1',
    method: 'get'
  });
};

export const getPopularAndAllBanks = () => {
  return Promise.all([getPopularBanks(), getAllBanks()]);
};

// 获取币种
export const getCurrency = () => {
  return ajax
    .request({
      url: '/archives/currency/list',
      method: 'get',
      data: {
        isArchive: 1,
        datatype: 'json'
      }
    })
    .then((data) => {
      if (data) {
        data.map((v) => {
          v.label = v.name;
          v.title = v.name;
          v.value = v.id;
        });
      }
      return data;
    });
};

export const getBizDepartment = (orgId) => {
  return ajax.request({
    url: `/orginazation/department/user/${orgId}`,
    method: 'get',
    data: {
      isArchive: 1,
      enable: true
    }
  });
};
// 根据agentId获取销售组织
export const getSaleOrgsByAgentId = (agentId) => {
  return ajax.request({
    url: `/orginazation/org/agent/${agentId}/list/2`,
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 交易类型
export const getTransactionTypeSelect = () => {
  return ajax.request({ url: window.__app.context.productLine === 'u8c' ? '/voucher/order/transactiontype/list/modelVo?isArchive=1' : '/pc/basic/TransactionTypeSrv/getTransactionTypeList', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubCategories', 'cName');
  });
};
export const getTransType = (order, formId, editSaleReturn) => {
  let getTransTypeUrl =  `marketingbill/transtype/getTransType?isMarket=1&bizId=${order.bizId}&orgId=${order.salesOrgId}`;
  if (formId === 'saleReturn') {
    getTransTypeUrl += '&formId=saleReturn'
    if (editSaleReturn && order.transactionTypeId) {
      getTransTypeUrl += `&transactionTypeId=${order.transactionTypeId}`
    }
  }

  return ajax.request({
    url: getTransTypeUrl,
    method: 'get'
  });
};

export const getRebateUseWay = () => {
  return ajax.request({
    url: '/enum/voucher/rebate/RebateUseWayEnum/info',
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 所有品牌分类
export const getBrandClassSelect = (query) => {
  return ajax.request({
    data: {
      datatype: 'json',
      isArchive: 1,
      data: {
        isFlat: false
      }
    },
    url: '/goods/infrastructure/brand/list/query',
    method: 'post'
  });
};
export const getBrandClassSelectByName = (conditions) => {
  return ajax.request({
    data: {
      datatype: 'json',
      isArchive: 1,
      data: {
        conditions
      }
    },
    url: '/goods/infrastructure/brand/list/querySchema',
    method: 'post'
  });
};
