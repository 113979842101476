<template>
  <div :class="classes">
    <Spin
      v-show="loading"
      :full-screen="true"
      size="large"
      fix></Spin>
    <slot name="header"></slot>
    <slot name="queryschema"></slot>
    <div
      v-show="!bizMeta.hiddenQuerySchemaArea && flattenQueryschemaArr.length >0 && !fullScreen"
      class="queryschema-area">
      <QuerySchema
        ref="queryschema"
        :biz-meta="cloneMeta"
        :filters="flattenQueryschemaArr"
        @on-filter-change="dynamicChange"
        @on-queryschema-search="queryschemaSearch"
        @on-show-more="handleResize"
        @on-queryschema-loaded="queryschemaLoaded">
      </QuerySchema>
    </div>
    <div :class="btnWrapper">
      <RadioGroup v-if="bizMeta.tableColumnsSetting && billMeta" v-model="showColumns" :style="radioStyle" @on-change="radioChange">
        <Radio label="header">
          {{ mlang('tableHeader') }}
        </Radio>
        <Radio label="all">
          {{ mlang('tableHeader') }} + {{ mlang('tableDetail') }}
        </Radio>
      </RadioGroup>
      <!--u8c规范菜单-->
      <MenuBar :biz-meta="bizMeta" @btnClick="menuBtnClick" @toggleFullScreen="toggleFullScreen">
        <!--打印导入导出区-->
        <template slot="inputOutput">
          <!--导入导出组件-->
          <ImportExport
            :biz-meta="bizMeta"
            :get-queryschema="getQueryschemaData"
            :get-select-rows-ids="getSelectRowIds"
            class="inputOutput"
            @import-success="loadData">
            <!--打印组件-->
            <template slot="extend">
              <PrintCtrl
                v-if="bizMeta.print"
                ref="printCtrl"
                :model-code="bizMeta.print.modelCode"
                @on-print-click="menuPrintBtnClick"></PrintCtrl>
            </template>
          </ImportExport>
        </template>
      </MenuBar>
      <slot name="exactBtns"></slot>
    </div>
    <!-- <div
      v-if="isShowImport"
      :class="btnWrapper">
      <div
        :class="btnsClass">
        <div
          class="importAndOutPut-btn-box">
          <Button
            v-if="bizMeta.addBtn && $hasAuth(bizMeta.addBtn.auth)"
            :disabled="bizMeta.addBtn.disabled"
            type="primary"
            icon="plus"
            @click="addClick"
          >{{ mlang('add') }}</Button>
          <slot name="exactBtns"></slot>
        </div>
        <div>
          <ImportExport
            :biz-meta="bizMeta"
            :get-queryschema="getQueryschemaData"
            :get-select-rows-ids="getSelectRowIds"
            @import-success="loadData"
          ></ImportExport>
        </div>
        <slot name="custom"></slot>
        <slot name="extBatchbtn"></slot>
        <div
          v-if="visibleBatchBtn && visibleBatchBtn.length"
          class="importAndOutPut-btn-box">
          <Dropdown
            style="width:90px;"
            @on-click="batchClick">
            <Button class="batchBtn">
              {{ mlang('batchoperate') }}
              <Icon type="arrow-down-b"></Icon>
            </Button>
            <DropdownMenu
              slot="list"
              class="batchoperate">
              <template v-for="btn in visibleBatchBtn">
                <DropdownItem
                  :name="btn.name"
                  :key="btn.name">{{ btn.title }}</DropdownItem>
              </template>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>-->
    <slot name="extArea"></slot>
    <Row v-if="!bizMeta.infiniteScroll" type="flex" justify="center" align="middle" class="advanced-router">
      <Col span="24">
      <Table
        ref="voucherTable"
        :key="tableKey"
        :bill-meta="billMeta"
        :columns="tableColumns"
        :height="height"
        :data="tableData"
        :loading="tableLoading"
        :row-class-name="bizMeta.rowClassName"
        :highlight-row="highlightRow"
        :meta-setting="metaSettingProps"
        :cell-merge="bizMeta.cellMerge"
        :not-need-table-footer="true"
        :source="bizMeta.source"
        :all-meta-columns="allMetaColumns"
        @on-current-change="selectedRowChange"
        @on-row-click="tableRowClick"
        @on-selection-change="tableSectionChange"
        @on-select-all="tableSelectAll"
        @on-horizontalScrollBar-change="handleResize"
        @on-checkall="onCheckAll">
        <div v-if="showFooter && !bizMeta.agentPage" slot="footer" class="bottom-page">
          <div v-if="showPager" style="float: right;">
            <Page
              :total="pager.totalCount"
              :current.sync="pager.currentPage"
              :page-size="pager.pageSize"
              :page-size-opts="pager.pageSizeOpts"
              show-sizer
              show-elevator
              show-total
              @on-change="changePage"
              @on-page-size-change="changePageSize"></Page>
          </div>
        </div>
      </Table>
      <!-- <div
        class="bottom-page"
        style="margin: 10px 0px;">

        </div>-->
      <div v-if="showPager && bizMeta.agentPage" class="agent-bottom-page">
        <Page
          :total="pager.totalCount"
          :current.sync="pager.currentPage"
          :page-size="pager.pageSize"
          :page-size-opts="pager.pageSizeOpts"
          show-sizer
          show-elevator
          show-total
          @on-change="changePage"
          @on-page-size-change="changePageSize"></Page>
      </div>
      </Col>
    </Row>
    <Row v-else class="advanced-router" :style="scrollStyle">
      <div
        v-infinite-scroll="loadData"
        :infinite-scroll-disabled="dataLoadStop"
        infinite-scroll-distance="10">
      </div>
      <Table
        ref="voucherTable"
        :key="tableKey"
        :bill-meta="billMeta"
        :columns="tableColumns"
        :data="tableData"
        :row-class-name="bizMeta.rowClassName"
        :highlight-row="highlightRow"
        :meta-setting="metaSettingProps"
        :cell-merge="bizMeta.cellMerge"
        :not-need-table-footer="true"
        :source="bizMeta.source"
        :all-meta-columns="allMetaColumns"
        @on-current-change="selectedRowChange"
        @on-row-click="tableRowClick"
        @on-selection-change="tableSectionChange"
        @on-select-all="tableSelectAll"
        @on-horizontalScrollBar-change="handleResize"
        @on-checkall="onCheckAll">
      </Table>
      <div v-show="dataLoadStop && tableData.length" class="list-load-end">
        <span>{{ mlang('loading') }}</span>
      </div>
    </Row>
  </div>
</template>

<script>
import ExImportResult from './im-or-export-result';
import Mixin from '@/mixin/archiveList.js';
import ListMixin from '@/mixin/archive-list.js';
import FormMixin from '@/mixin/form.js';
import CommonMixin from '@/mixin/common.js';
import VoucherArchiveMixin from '@/mixin/voucher-archive.js';
import { deepCopy, scrollTop, findComponentDownward } from '@/u-components/utils/assist';
import { getBillMeta } from '@/api/meta';
import _ from 'lodash';
import { on, off } from '@/u-components/utils/dom';
import elementResizeDetectorMaker from 'element-resize-detector';
// import * as filters from '@/filters';
import util from '@/libs/util.js';
import MenuBar from './menu-bar/menu-bar';
import PrintCtrl from '@/components/print-ctrl/print-ctrl.vue';
// import { orderMockMeta } from '@/meta/refer-common';
let userID;

export default {
  name: 'VoucherList',
  components: {
    ExImportResult,
    MenuBar,
    PrintCtrl
  },
  inject: ['mainResize'],
  mixins: [CommonMixin, Mixin, VoucherArchiveMixin, ListMixin, FormMixin],
  props: {
    data: {
      required: true,
      type: Object,
      default: {}
    },
    query: {
      required: true,
      type: Function
    },
    beforeQuery: {
      type: Function
    },
    bizMeta: {
      required: true,
      type: Object
    },
    isNeedPlaceHolderCol: {
      type: Boolean
    },
    placeHolderWidth: {
      type: Number,
      default: 60
    }
  },
  data() {
    let pageSize = 10;
    if (localStorage) {
      try {
        if (localStorage) {
          userID = this.$store.getters.currentUser.userId;
        }
      } catch (error) {
        console.log(error); //eslint-disable-line
      }
      pageSize = localStorage.getItem(`pageSize-${this.bizMeta.billId}-${userID}`) ? Number(localStorage.getItem(`pageSize-${this.bizMeta.billId}-${userID}`)) : 10;
    }
    let pageSizeOpts = [10, 20, 30, 50];
    if (this.bizMeta.pageSizeOpts) {
      pageSizeOpts = this.bizMeta.pageSizeOpts;
    }
    return {
      loading: false,
      activated: false,
      showImportProcess: false,
      showImportResult: false,
      billMeta: null,
      checkedArr: [],
      billQuerySchema: [],
      queryschema: null,
      flattenQueryschemaArr: [],
      isSelectAll: false,
      selectedRows: [],
      cloneMeta: deepCopy(this.bizMeta),
      selectedRow: null,
      queryschemaObj: null,
      searchConName3: 123,
      billColumns: [],
      tableData: [],
      tableColumns: [],
      tableLoading: true,
      metaSettingProps: {
        enable: false,
        billId: this.bizMeta.billId,
        columns: []
      },
      pager: {
        pageIndex: 1,
        pageSize,
        pageSizeOpts,
        currentPage: 1,
        totalCount: 0
      },
      fullScreen: false,
      dataLoadStop: false,
      scrollStyle: {},
      showColumns: 'header',
      radioStyle: {
        'float': 'left',
        'margin-left': '55px'
      },
      TENANTDEFINEMETA: this.$getBO('TENANTDEFINEMETA') > 0, // 能设置meta
      allMetaColumns: []
    };
  },
  computed: {
    btnWrapper() {
      return [
        'importAndOutPut-box',
        {
          'voucherlist-no-query-wrapper': !(!this.bizMeta.hiddenQuerySchemaArea && this.flattenQueryschemaArr.length > 0),
          'p-t-5': this.fullScreen
        }
      ];
    },
    btnsClass() {
      return [
        'importAndOutPut pull-right',
        {
          'action-only-btn': !(!this.bizMeta.hiddenQuerySchemaArea && this.flattenQueryschemaArr.length > 0)
        }
      ];
    },
    isShowImport() {
      // return (this.visibleBatchBtn && this.visibleBatchBtn.length) || this.$slots.extBatchbtn || this.$slots.custom || this.bizMeta.export || this.bizMeta.import || (this.bizMeta.addBtn && this.$hasAuth(this.bizMeta.addBtn.auth));
      return true;
    },
    classes() {
      const prefixCls = 'voucher-list';
      const billNo = this.billMeta && this.billMeta.billNo;
      const isHeight60 = this.bizMeta && this.bizMeta.isHeight60;
      return [`${prefixCls}`, { [`${prefixCls}-${billNo}`]: billNo }, { 'voucher-list-h60': isHeight60 }];
    },
    visibleBatchBtn() {
      if (!this.bizMeta.batchBtn) {
        return [];
      }
      return this.bizMeta.batchBtn.filter((v) => {
        return v.isHidden !== true;
      });
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    tableKey() {
      return `table-ref-${this.cloneMeta.billId}`;
    },
    highlightRow() {
      var meta = this.cloneMeta;
      return meta.hasOwnProperty('isHighlighRow') ? meta.isHighlighRow : !meta.multiple;
    },
    showFooter() {
      var meta = this.cloneMeta;
      return meta.hasOwnProperty('isShowFooter') ? meta.isShowFooter : true;
    },
    showPager() {
      var meta = this.cloneMeta;
      return meta.hasOwnProperty('isShowPager') ? meta.isShowFooter : !!(this.pager.totalCount > 0);
    },
    height() {
      return this.bizMeta.height ? this.bizMeta.height : null;
    }

  },
  watch: {
    bizMeta: {
      handler(val) {
        if (!val) return;
        this.cloneMeta = deepCopy(this.bizMeta);
        // this.queryschemaObj = this.makeQueryschemaObj();
        // this.setColumns();
      },
      deep: true
    },
    data: {
      handler() {
        const cloneData = deepCopy(this.data);
        if (cloneData.content && cloneData.content.length === 0) {
          if (cloneData.totalPages) {
            if (this.pager.pageIndex > cloneData.totalPages) {
              this.pager.pageIndex = cloneData.totalPages;
              this.loadData();
              return;
            }
          }
        }
        this.tableData = cloneData.content;
        this.bizMeta.multiple && this.setCheckedData();
        this.pager.totalCount = cloneData.totalElements || cloneData.total || cloneData.count;
        this.tableLoading = false;
        this.dataLoadStop = false;
        if (!this.bizMeta.infiniteScroll) {
          this.$nextTick(() => {
            this.scrollTop();
          });
        }
      },
      deep: true
    }
  },
  activated() {
    if (!this.activated) {
      if (this.$route.params && this.$route.params.forceReload) {
        this.$emit('activated-force-reload-all');
        this.reloadAll();
      } else {
        this.$emit('activated-reload-data');
        this.reloadData();
      }
    }
  },
  deactivated() {
    this.activated = false;
  },
  created() {
    this.activated = true;
    if (!this.bizMeta.billId) {
      console.error('billId is required'); //eslint-disable-line
      return;
    }
    this.init();
  },
  mounted() {
    this.handleResize();
    on(window, 'resize', this.handleResize);
    this.observer = elementResizeDetectorMaker();
    // this.observer.listenTo(this.$el, this.handleResize);
    this.$on('reload-all', (val) => {
      //
      this.reloadAll();
    });
    this.$on('reload-table', (val) => {
      this.checkedArr = [];
      this.isSelectAll = false;
      this.init({ type: 'table' });
    });

    this.$on('reload', (val) => {
      this.reloadData();
    });
    this.scrollStyle = {
      'height': `${document.body.clientHeight - 513}px`,
      'overflow-y': 'auto'
    };
  },
  beforeDestroy() {
    off(window, 'resize', this.handleResize);
    this.$off('reload-all');
    this.$off('reload-table');
    this.$off('reload');
    this.observer.removeListener(this.$el, this.handleResize);
  },
  methods: {
    radioChange(value) {
      if (value === 'all') {
        this.makeAllColumns();
      } else {
        this.makeColumns();
      }
      this.loadData();
      this.$emit('on-header-body-change', value);
    },
    setLoading(val) {
      this.loading = val;
    },
    reloadAll() {
      this.checkedArr = [];
      this.isSelectAll = false;
      this.init();
    },
    menuPrintBtnClick(url) {
      let btn = {
        key: 'print',
        name: 'print',
        url
      };
      this.$emit('on-batch-click', btn.name, this.checkedArr, url);
    },
    menuBtnClick(btn) {
      if (btn.name === 'add') {
        this.addClick();
      } else {
        this.batchClick(btn.name);
      }
    },
    reloadData() {
      this.checkedArr = [];
      this.isSelectAll = false;
      if (!this.bizMeta.infiniteScroll || (this.bizMeta.infiniteScroll && this.data.scrollPageIndex === 1)) {
        this.loadData();
      }
    },
    init(config = {}) {
      this.$Loading.start();
      getBillMeta(this.bizMeta.billId)
        .then((res) => {
          this.$Loading.finish();
          if (this.bizMeta.setBillMeta) {
            res = this.bizMeta.setBillMeta(res);
          }
          this.billMeta = res;
          // this.billMeta = orderMockMeta(1, this);
          if (config.type === 'table') {
            this.makeColumns();
          } else {
            this.makeColumns();
            this.makeQueryschema();
          }
        })
        .catch((err) => {
          console.error(err); // eslint-disable-line no-console
          this.$Loading.error();
        });
    },
    setFilterItem(fieldName, key, value) {
      this.$refs['queryschema'].setFilterItem(fieldName, key, value);
    },
    setFilterItemByAction(action, fieldName, key, value) {
      this.$refs['queryschema'].setFilterItemByAction(action, fieldName, key, value);
    },
    loadData() {
      if (this.data.dataNomore && this.bizMeta.infiniteScroll) {
        return false;
      }
      this.tableLoading = true;
      this.dataLoadStop = true;
      const querySchema = this.getQueryschemaData();
      if (this.beforeQuery && this.beforeQuery(querySchema) === false) {
        this.tableLoading = false;
        return false;
      }
      this.emitQuery(querySchema);
      // 加载打印模板数据
      if (this.bizMeta.print) {
        this.$refs['printCtrl'].$emit('on-refresh-print-templates', this.bizMeta.print.modelCode);
      }
    },
    onCheckAll(val) {
      this.isSelectAll = true;
    },
    setCheckedData() {
      if (this.checkedArr && this.checkedArr.length) {
        this.tableData.map((v) => {
          const hasCheck = this.checkedArr.find((ck) => {
            return ck.id === v.id;
          });
          if (hasCheck) {
            v._checked = true;
          }
        });
      }
    },
    scrollTop() {
      const Table = findComponentDownward(this, 'Table');
      const body = Table.$refs['refBodyWrapper'];
      scrollTop(body, body.scrollTop, 0, 1000);
    },
    emitQuery(queryschema) {
      this.query(queryschema, this.showColumns);
    },
    queryschemaLoaded() {
      this.checkedArr = [];
      if (!this.bizMeta.infiniteScroll) {
        this.loadData();
      }
    },
    selectedRowChange(currentRow) {
      this.selectedRow = currentRow;
    },
    changePage(page) {
      this.isSelectAll = false;
      this.pager.pageIndex = page;
      this.loadData();
    },
    changePageSize(pageSize) {
      localStorage && localStorage.setItem(`pageSize-${this.bizMeta.billId}-${userID}`, pageSize);
      this.pager.pageSize = pageSize;
      this.loadData();
    },
    onModalShow() {
      this.$emit('on-modal-show');
    },
    tableRowClick(rowData, index) {
      // this.tableData[index]._checked = !this.tableData[index]._checked;
    },
    tableSelectAll(selection, status) {
      this.isSelectAll = status;
    },
    tableSectionChange(selection, row, status) {
      this.isSelectAll = this.$refs.voucherTable.isSelectAll();
      this.selectedRows = selection;
      if (status) {
        this.checkedArr = _.uniqBy(this.checkedArr.concat(selection), 'id');
      } else {
        row.forEach((v) => {
          const arr = deepCopy(this.checkedArr);
          _.remove(arr, (n) => {
            return v.id === n.id;
          });
          this.checkedArr = arr;
        });
      }
      this.$emit('table-selection-change', this.checkedArr);
    },
    selectAll(v) {
      this.$refs.voucherTable.selectAll(v);
    },
    getSelectRowIds() {
      const ids = [];
      const selectRows = this.getSelectRow();
      if (selectRows && selectRows.length) {
        selectRows.forEach((v) => {
          ids.push(v.id);
        });
      }
      return ids;
    },
    getSelectRow() {
      return this.cloneMeta.multiple ? this.checkedArr : this.selectedRow;
    },
    formChange(newVal, oldVal) {
      this.formData = deepCopy(newVal);
      this.$emit('on-form-change', newVal, oldVal);
    },
    queryschemaSearch(queryschema) {
      this.checkedArr = [];
      this.pager.currentPage = 1;
      this.pager.pageIndex = 1;
      this.loadData();
    },
    setColumns(columns = []) {
      if (this.bizMeta.tableMetaSetting) {
        this.metaSettingProps.enable = true;
      }
      // if (this.currentUser && this.currentUser.userType !== 1) {
      //   this.metaSettingProps.enable = false;
      // }
      this.metaSettingProps.columns = columns;
      this.metaSettingProps.billNo = this.billMeta.billNo;

      const visibleColumns = this.billColumns.filter((v) => {
        return v.isShowIt;
      });

      let tableColumns = [];
      const noWidthColumn = {
        key: 'nowidth',
        title: ''
      };
      const fixWidthColumn = {
        key: 'fixWidthColumn',
        title: ''
      };

      const placeHolderWidthColumn = {
        key: 'placeHolderWidthColumn',
        title: '',
        width: this.placeHolderWidth
      };

      if (this.cloneMeta.multiple) {
        tableColumns = [this.c_fixSelectionColumn, this.c_fixIndexColumn, ...visibleColumns];
      } else {
        // isNoSerial: true 不显示序号
        tableColumns = this.cloneMeta.isNoSerial
          ? [...visibleColumns]
          : [this.c_fixIndexColumn, ...visibleColumns];
      }
      tableColumns.push(noWidthColumn, fixWidthColumn);

      if (this.isNeedPlaceHolderCol) {
        tableColumns.push(placeHolderWidthColumn);
      }
      this.c_setColumns(tableColumns);
      const maxHeight = this.bizMeta && this.bizMeta.isHeight60 ? '60px' : '40px';

      tableColumns.map((column, k) => {
        column.maxHeight = maxHeight;
        if (column.isDefine) {
          column.controlType = 'Cell';
        }
        if (!column.width) {
          // column.width = 150;
        }
        if ((column.formatData && column.formatData.indexOf('0.') > -1) || column.isPrice || this.$util.isLegalFormatData(column)) {
          if (this.$util.isLegalFormatData(column)) {
            column = this.$util.makeFormatData(column);
          }

          column.render = (h, params) => {
            const v = column.key.includes('.') ? this.getPointKeyVal(column, params) : params.row[column.key];
            let val = v;
            const isNumber = typeof v === 'number';
            if (this.$util.isLegalFormatData(column) && isNumber) {
              const param = {
                column,
                data: params.row,
                value: val
              };
              val = this.$util.getNumberFormatDataValue(param);
            } else if (column.formatData && column.formatData.indexOf('0.') > -1 && isNumber) {
              // return this.c_renderSpan(h, filters.formatNumber(v, column.formatData));
              val = this.$util.toFixed(v, 2, 1, true);// 强制格式化2位展示，100.00
              val = this.$util.formatNumber(val);
            }

            return this.c_renderSpan(h, val);
          };
        }

        // 除了 Date和DateTime 和Cell 走控件render渲染
        if (column.controlType && column.controlType !== 'Cell' && column.controlType !== 'DateTime' && column.controlType !== 'Date') {
          let ctrlType = column.controlType;
          if (column.controlType === 'Status') { // Status 走Enum渲染逻辑
            ctrlType = 'Enum';
          }
          column.render = (h, params) => {
            return h(ctrlType, { key: k, props: { params, column, bizMeta: this.bizMeta, isList: true } });
          };
        } else if (column.controlType && (column.controlType === 'DateTime' || column.controlType === 'Date')) {
          // Date和DateTime 特殊渲染
          column.render = (h, params) => {
            const v = params.row[column.key];
            // console.log(v);
            const formatValue = column.controlType === 'DateTime' ? util.formatToCtxDateTime(v) : util.formatToCtxDate(v);
            // console.log(format);
            return this.c_renderSpan(h, formatValue);
          };
        }
        if (column.isEnum) {
          try {
            column.enumArray = JSON.parse(column.enumString);
            if (column.enumStoreType === 'NAME') {
              column.enumArray.map((v) => {
                v.value = v.name;
              });
            }
            if (column.enumStoreType === 'SPECIAL') {
              column.enumArray.map((v) => {
                v.value = v.specialValue;
              });
            }
          } catch (error) {
            console.error(' enumString error-->' + column.fieldName); //eslint-disable-line
          }
        }
        if (column.name === 'action') {
          column.fixed = 'suspend';
          if (this.bizMeta.btns && this.bizMeta.btns.length) {
            this.bizMeta.btns.map((btn) => {
              if (btn.action) {
                btn.click = this.bizMeta.actions[btn.action];
              }
              if (btn.isCommon === true) {
                if (btn.key === 'edit') {
                  btn.click = (params) => {
                    const editRoute = this.bizMeta.editRoute && this.bizMeta.editRoute(params);
                    if (editRoute) {
                      this.editClick(editRoute);
                    }
                  };
                }
              }
            });
            column.btns = this.bizMeta.btns;
          }
        }
        if (column.action) {
          if (this.bizMeta.actions[column.action]) {
            column = this.bizMeta.actions[column.action](column);
          } else {
            console.error('no action ->' + column.action); //eslint-disable-line
          }
        }
        return tableColumns;
      });
      this.tableColumns = tableColumns;
    },
    setQueryschema(queryschemaGroups = []) {
      this.flattenQueryschema(queryschemaGroups);
    },
    flattenQueryschema(queryschemaGroups) {
      const flatQueryschema = [];
      queryschemaGroups.map((group, index) => {
        group.currentFilterItems.map((item, index2) => {
          if (item.isHidden !== true) {
            item.groupType = group.groupType;
            flatQueryschema.push(item);
          }
        });
      });
      this.flattenQueryschemaArr = flatQueryschema;
      if (this.flattenQueryschemaArr.length === 0) {
        // this.$refs.queryschema.init();
      }
    },
    toggleFullScreen(fullScreen) {
      this.fullScreen = fullScreen;
      this.setFullHeight();
    },
    setFullHeight() {
      this.$nextTick(() => {
        if (this.fullScreen) {
          const rowHeight = this.bizMeta && this.bizMeta.isHeight60 ? 60 : 40;
          const singlePageEl = document.getElementById('single-page-right');
          const singlePageHeight = singlePageEl.clientHeight;
          const btnAreaHeight = 66;
          const tableDataHeight = rowHeight * this.tableData.length + 48 + btnAreaHeight;
          const pagerHeight = 50;
          let height = singlePageHeight - btnAreaHeight;
          if (this.showFooter) {
            height = height - pagerHeight;
          }
          if (tableDataHeight < height) {
            height = null;
          }

          this.height = height;
        } else {
          this.height = null;
        }
      });
    },
    handleResize() {
      this.mainResize && !sessionStorage.isAgentSide && this.mainResize();
      this.$emit('on-handle-resize');
    },
    batchClick(name) {
      this.$emit('on-batch-click', name, this.checkedArr);
    },
    dynamicChange(control, enumKey) {
      this.$emit('on-filter-change', control, enumKey, this.flattenQueryschemaArr);
    },
    addClick() {
      if (this.bizMeta.addBtn.vue) {
        if (this.bizMeta.addBtn.route.query) {
          this.bizMeta.addBtn.route.query.titleType = 'add';
        } else {
          this.bizMeta.addBtn.route.query = {
            titleType: 'add'
          };
        }
        this.$router.push(this.bizMeta.addBtn.route);
      } else if (this.bizMeta.addBtn.href) {
        util.menuClickOldPage(this.bizMeta.addBtn.href, this, this.bizMeta.addBtn);
        // this.$open(this.bizMeta.addBtn.href);
      } else if (_.isFunction(this.bizMeta.addBtn.clickFunc)) {
        this.bizMeta.addBtn.clickFunc();
      } else if (this.bizMeta.addBtn.agentLink) { // 订货端用
        this.agentLink(this.bizMeta.addBtn.agentLink);
      }
    },
    findColumn(key) {
      return this.tableColumns.find((v) => {
        return v.fieldName === key;
      });
    },
    getPointKeyVal(column, params){
      if(params.row[column.key]){
        return params.row[column.key];
      }
      const arr = column.key.split('.');
      let res = params.row;
      arr.map((ele)=>{
        res = res && res[ele]
      })
      return res;
    }
  }
};
</script>
