export default {
  moneyRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000675","红包规则") /* "红包规则" */,
  redpackQuota: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000676","红包限额") /* "红包限额" */,
  redpackTips: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000677","如果产生新的红包活动，上次配置的限额和已用金额将作废，以本次填写的金额为准重新计算") /* "如果产生新的红包活动，上次配置的限额和已用金额将作废，以本次填写的金额为准重新计算" */,
  controlQuota: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000678","控制限额") /* "控制限额" */,
  setNewRedpackActivity: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800044","产生新的红包活动") /* "产生新的红包活动" */,
  yuan: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000679","元") /* "元" */,
  hasSentMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500067A","已发金额") /* "已发金额" */,
  redpackRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500067B","规则说明：") /* "规则说明：" */,
  redpackRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500067C","红包规则下拉、红包限额选项、开始时间、已发金额") /* "红包规则下拉、红包限额选项、开始时间、已发金额" */,
  redpackRule3: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500067D","红包限额：控制限额不勾选，金额输入框及产生新的红包活动置灰不可选，勾选控制限额，金额输入框及产生新的红包活动可编辑，金额必输内容，产生新的红包活动可选可不选") /* "红包限额：控制限额不勾选，金额输入框及产生新的红包活动置灰不可选，勾选控制限额，金额输入框及产生新的红包活动可编辑，金额必输内容，产生新的红包活动可选可不选" */,
  redpackRule4: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500067E","开始时间是系统产生的，不可更改") /* "开始时间是系统产生的，不可更改" */,
  redpackRule5: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500067F","底部保存对已更改内容进行保存，如果更改内容后未保存，则数据不变，仍是之前的数据内容") /* "底部保存对已更改内容进行保存，如果更改内容后未保存，则数据不变，仍是之前的数据内容" */,
  redpackRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000680","红包发送条件") /* "红包发送条件" */,
  deliveryMinMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000681","发货单最小金额") /* "发货单最小金额" */,
  receiveMinMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000682","签收单最小金额") /* "签收单最小金额" */
};
