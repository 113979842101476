<template>
  <Refer
    ref="select-agentGroup-ref"
    v-model="value"
    :biz-meta="bizMeta"
    :control="control"
    :is-queryschema="false"
    @on-ok="onOk"></Refer>
</template>

<script>
import { agentGroupReferWithAuth } from '@/meta/refer-common';
import Refer from '_c/common/refer/refer';
const control = (vm) => {
  return { action: 'agentGroupReferAction', caption: vm.mlang('customerGroup') };
};
export default {
  name: 'SelectAgentGroup',
  components: {
    Refer
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    beforeQuery: {
      type: Function
    }
  },
  data() {
    return {
      bizMeta: {
        actions: { agentGroupReferAction: this.agentGroupReferAction }
      },
      value: null,
      control: control(this)
    };
  },
  watch: {
  },
  methods: {
    agentGroupReferAction(filterMeta) {
      if (this.multiple) {
        filterMeta.multiple = true;
      }
      if (this.beforeQuery) {
        filterMeta.beforeQuery = this.beforeQuery;
      }
      return agentGroupReferWithAuth(filterMeta, this);
    },
    showRefModal() {
      this.$refs['select-agentGroup-ref'].showModal();
    },
    onOk(rows) {
      this.$emit('on-selected', rows);
    }
  }
};
</script>
<style>
.select-agent .ivu-table-wrapper {
  height: 403px !important;
}
</style>
