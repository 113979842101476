import { noop } from './noop';

export const warning =
  process.env.NODE_ENV === 'production'
    ? /* istanbul ignore next */ noop
    : function warning(checker, complainer) {
      if (!checker()) {
        const message = ['[Vue-Treeselect Warning]'].concat(complainer());
        // eslint-disable-next-line no-console
        console.error(...message);
      }
    };
