const tenant = [
  {
    path: 'managementList',
    name: 'managementList',
    meta: { module: 'managementList', notCache: true },
    component: () => import('@/views/corp/tenantManagement/managementList.vue')
  },
  {
    path: 'managementDetail',
    name: 'managementDetail',
    meta: { module: 'managementList', notCache: true },
    component: () => import('@/views/corp/tenantManagement/managementDetail.vue')
  }
];

export default tenant;
