const Row = 'Row';
const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {},

  methods: {
    setRouterState(router, state) {
      if (router.query) {
        router.query.titleType = state;
      } else {
        router.query = {
          titleType: state
        };
      }
      return router;
    },
    editClick(router) {
      this.setRouterState(router, 'editor');
      router.params = {
        editable: true
      };
      this.$router.push(router);
    },
    detailClick(router) {
      this.setRouterState(router, 'detail');
      this.$router.push(router);
    },
    StatusAction(item) {
      item.render = (h, params) => {
        const key = params.column.fieldName;
        return <span>{params.row[key] ? this.mlang('enable') : this.mlang('disable')}</span>;
      };
      return item;
    },
    mixin_statusBtn(params, statusControl) {
      let dataKey = statusControl.fieldName;
      let trueValue = () => {
        const trueItem = statusControl.enumArray.find((v) => {
          return v.value;
        });
        return trueItem['value'];
      };
      let falseValue = () => {
        const falseItem = statusControl.enumArray.find((v) => {
          return !v.value;
        });
        return falseItem['value'];
      };

      let open = () => {
        const trueItem = statusControl.enumArray.find((v) => {
          return v.value;
        });
        return trueItem['title'];
      };
      let close = () => {
        const falseItem = statusControl.enumArray.find((v) => {
          return !v.value;
        });
        return falseItem['title'];
      };
      let currentValue = params.row[dataKey];
      let text = currentValue == trueValue() ? close() : open(); //eslint-disable-line
      let toValue = currentValue == trueValue() ? falseValue() : trueValue(); //eslint-disable-line
      const status = () => {
        this.statusClick(params.row, toValue, statusControl);
      };
      return <span on-click={status}>{text}</span>;
    },
    RowAction(item) {
      item.render = (h, params) => {
        const edit = () => {
          return this.edit(params.row);
        };
        const del = () => {
          return this.del(params.row);
        };
        let showEdit = true;
        let showDel = true;
        if (this.meta.auth && this.meta.auth.edit) {
          showEdit = this.$hasAuth(this.meta.auth.edit);
        }
        if (this.meta.auth && this.meta.auth.del) {
          showDel = this.$hasAuth(this.meta.auth.del);
        }
        return (
          <div class="action">
            <Row class-name="text" type="flex" justify="center">
              {
                showEdit ? <span class="rollBackBtn" on-click={edit}>
                  {this.mlang('edit')}
                </span>
                  : ''
              }
              {
                showDel ? <span class="rollBackBtn" on-click={del}>
                  {this.mlang('delete')}
                </span>
                  : ''
              }

            </Row>
          </div>
        );
      };
      return item;
    },
    mutiBtnRowAction(item) {
      item.render = (h, params) => {
        if (this.btnList && this.btnList.length) {
          this.btnList.forEach((v) => {
            v.handleClick = () => {
              v.func(params.row);
            };
          });
          return h('div', { class: ['action'] }, [
            h(
              'Row',
              {
                Props: {
                  className: 'text',
                  type: 'flex',
                  justify: 'center'
                }
              },
              this.btnList.map((btn) => {
                let styleClass = ['rollBackBtn'];
                if (btn.className) {
                  styleClass.push(...btn.className);
                }
                return h(
                  'span',
                  {
                    class: styleClass,
                    on: { click: btn.handleClick }
                  },
                  this.mlang(btn.name)
                );
              })
            )
          ]);
        } else {
          return h('div', { class: ['action'] }, [
            h('Row', {
              Props: {
                className: 'text',
                type: 'flex',
                justify: 'center'
              }
            })
          ]);
        }
      };
    }
  }
};

export default mixin;
