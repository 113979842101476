export default {
  name: 'TableExpand',
  functional: true,
  props: {
    row: Object,
    render: Function,
    index: Number,
    tableIndex: Number,
    column: {
      type: Object,
      default: null
    },
    columns: {
      type: Array
    }
  },
  created(){},
  render: (h, ctx) => {
    const params = {
      row: ctx.props.row,
      column: ctx.props.column,
      columns: ctx.props.columns,
      index: ctx.props.index,
      tableIndex: ctx.props.tableIndex
    };
    if (ctx.props.column) params.column = ctx.props.column;
    return ctx.props.render(h, params);
  }
};
