const customer = [
  {
    path: 'potentialAgents',
    name: 'potentialAgents',
    meta: { module: 'potentialAgents' },
    component: () => import('@/views/corp/customer/components/potential-agents.vue')
  },
  {
    path: 'potentialAgent',
    name: 'potentialAgent',
    meta: { module: 'potentialAgents', notCache: true },
    component: () => import('@/views/corp/customer/components/potentialAgent/potentialAgent.vue')
  },
  {
    path: 'customerGroup',
    name: 'customerGroup',
    meta: { module: 'customerGroups', notCache: true },
    component: () => import('@/views/corp/customer/components/customerGroup/customerGroup.vue')
  },
  {
    path: 'customerGroups',
    name: 'customerGroups',
    meta: { module: 'customerGroups' },
    component: () => import('@/views/corp/customer/components/customerGroup/customerGroups.vue')
  },
  {
    path: 'customerLevel',
    name: 'customerLevel',
    meta: { module: 'customerLevels', notCache: true },
    component: () => import('@/views/corp/customer/components/customerLevel/customerLevel.vue')
  },
  {
    path: 'customerLevels',
    name: 'customerLevels',
    meta: { module: 'customerLevels' },
    component: () => import('@/views/corp/customer/components/customerLevel/customerLevels.vue')
  },
  {
    path: 'showStatistics',
    name: 'showStatistics',
    component: () => import('@/views/corp/customer/components/showStatistics/showStatistics.vue')
  },
  {
    path: 'agentIndustry',
    name: 'agentIndustry',
    meta: { module: 'industrySettings', notCache: true },
    component: () => import('@/views/corp/customer/components/industry/industrySetting.vue')
  },
  {
    path: 'customerFundAccount',
    name: 'customerFundAccount',
    meta: { module: 'customerFundAccount', notCache: true },
    component: () => import('@/views/corp/customer/components/customerFundAccount/customerFundAccount.vue')
  },
  {
    path: 'customerFundAccountRebate',
    name: 'customerFundAccountRebate',
    meta: {},
    component: () => import('@/views/corp/customer/components/customerFundAccount/customerFundAccountRebate.vue')
  },
  {
    path: 'customerFundAccountPayMoney',
    name: 'customerFundAccountPayMoney',
    meta: {},
    component: () => import('@/views/corp/customer/components/customerFundAccount/customerFundAccountPayMoney.vue')
  },
  {
    path: 'industrySettings',
    name: 'industrySettings',
    meta: { module: 'industrySettings' },
    component: () => import('@/views/corp/customer/components/industry/industrySettings.vue')
  },
  {
    path: 'customerList',
    name: 'customerList',
    meta: { module: 'customerList' },
    component: () => import('@/views/corp/customer/components/customer/customerList.vue')
  },
  {
    path: 'customerDetail',
    name: 'customerDetail',
    meta: { module: 'customerList', notCache: true },
    component: () => import('@/views/corp/customer/components/customer/customer.vue'),
    children: [
      {
        path: 'agentbase',
        name: 'agentbase',
        meta: { module: 'customerList', notCache: true },
        component: () => import('@/views/corp/customer/components/customer/agentBaseInfo.vue')
      },
      {
        path: 'agentpoint',
        name: 'agentpoint',
        meta: { module: 'customerList', notCache: true },
        component: () => import('@/views/corp/customer/components/customer/point.vue')
      },
      {
        path: 'agentcoupon',
        name: 'agentcoupon',
        meta: { module: 'customerList', notCache: true },
        component: () => import('@/views/corp/customer/components/customer/coupon.vue')
      },
      {
        path: 'agentcontacts',
        name: 'agentcontacts',
        meta: { module: 'customerList', notCache: true },
        component: () => import('@/views/corp/customer/components/customer/agentContact.vue'),
        children: [
          {
            path: 'agentcontactsdetail',
            name: 'agentcontactsdetail',
            meta: { module: 'customerList' },
            component: () => import('@/views/corp/customer/components/customer/contact/contact.vue')
          },
          {
            path: 'agentcontactslist',
            name: 'agentcontactslist',
            meta: { module: 'customerList' },
            component: () => import('@/views/corp/customer/components/customer/contact/contacts.vue')
          }
        ]
      },
      {
        path: 'agentaddress',
        name: 'agentaddress',
        meta: { module: 'customerList', notCache: true },
        component: () => import('@/views/corp/customer/components/customer/agentAddress.vue'),
        children: [
          {
            path: 'agentaddressdetail',
            name: 'agentaddressdetail',
            meta: { module: 'customerList' },
            component: () => import('@/views/corp/customer/components/customer/address/address.vue')
          },
          {
            path: 'agentaddresslist',
            name: 'agentaddresslist',
            meta: { module: 'customerList' },
            component: () => import('@/views/corp/customer/components/customer/address/addresses.vue')
          }
        ]
      },
      {
        path: 'agentbank',
        name: 'agentbank',
        meta: { module: 'customerList', notCache: true },
        component: () => import('@/views/corp/customer/components/customer/agentBank.vue'),
        children: [
          {
            path: 'agentbankdetail',
            name: 'agentbankdetail',
            meta: { module: 'customerList' },
            component: () => import('@/views/corp/customer/components/customer/bank/bank.vue')
          },
          {
            path: 'agentbanklist',
            name: 'agentbanklist',
            meta: { module: 'customerList' },
            component: () => import('@/views/corp/customer/components/customer/bank/banks.vue')
          }
        ]
      },
      {
        path: 'agentinvoice',
        name: 'agentinvoice',
        meta: { module: 'customerList' },
        component: () => import('@/views/corp/customer/components/customer/agentInvoice.vue'),
        children: [
          {
            path: 'agentinvoicedetail',
            name: 'agentinvoicedetail',
            meta: { module: 'customerList' },
            component: () => import('@/views/corp/customer/components/customer/invoice/invoice.vue')
          },
          {
            path: 'agentinvoicelist',
            name: 'agentinvoicelist',
            meta: { module: 'customerList', notCache: true },
            component: () => import('@/views/corp/customer/components/customer/invoice/invoices.vue')
          }
        ]
      }
    ]
  },
  // 客户分类
  {
    path: 'customerCategorys',
    name: 'customerCategorys',
    meta: { notCache: true },
    component: () => import('@/views/corp/customer/components/customerCategory/customerCategorys.vue')
  },
  {
    path: 'customerAreas',
    name: 'customerAreas',
    meta: { notCache: true },
    component: () => import('@/views/corp/customer/components/customerArea/customerAreas.vue')
  }

];
export default customer;
