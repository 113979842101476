// mdd 改造权限码 / 订货权限码
import authObj from './authId.json';
import myAuthObj from './myAuthId.json';
const org = (config) => {
  var obj = authObj;
  var myObj = myAuthObj;
  var reg = /^[\/]{0,1}orginazation\/org\/user\/\d*\/list/g;
  var reg1 = /^[\/]{0,1}orginazation\/merchant\/\d*\/org\/user\/list\/\d*/g;
  var reg2 = /^[\/]{0,1}orginazation\/org\/user\/list\/\d*/g;
  var reg3 = /^[\/]{0,1}orginazation\/org\/user\/\d*\/list\/d*/g;
  var reg4 = /^[\/]{0,1}meta\/bill\/\d*\/current/g;
  if ((config.url === '/orginazation/org/user/list' || reg.test(config.url) || reg1.test(config.url) || reg2.test(config.url) || reg3.test(config.url) || reg4.test(config.url)) && obj[window.__app.mainV.$route.name]) {
    if (window.__app.mainV.$route.query.fixed) {
      config.params.authId = obj[window.__app.mainV.$route.name];
    } else {
      config.params.authId = myObj[window.__app.mainV.$route.name];
    }
  }
  return config;
};
export default org;
