import { receiveAgreementList } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const ReceiveAgreementNameRefer = (ctrl, vm, params) => {
  const control = Object.assign(
    {},
    {
      page: false,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refRetId: 'receiveAgreementId', // 拿refReturnValue的value值以receiveAgreementId:value形式传递给后端
      refReturnValue: 'id', // 参照回传的value值
      refName: 'receiveAgreementName', // 回传到输入框的值
      columns: [
        {
          showCaption: vm.mlang('receiveAgreementCode'),
          isShowIt: true,
          fieldName: 'code',
          width: 120
        },
        {
          showCaption: vm.mlang('receiveAgreementName'),
          fieldName: 'receiveAgreementName',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('installmentPayment'),
          fieldName: 'instalment',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('protocolSpecification'),
          fieldName: 'comment',
          width: 180,
          isShowIt: true
        }
      ],
      actions: { queryList: () => {
        return receiveAgreementList({ orgId: ctrl.beforeQuery(params).orgId });
      } },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default ReceiveAgreementNameRefer;
