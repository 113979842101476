<template>
  <span class="status-ctrl">
    <span
      v-if="column.name !== 'cSaleReturnSourceType' && (isEnum && showValue !== '') || !isEnum"
      :class="classes">
    </span>
    <template v-if="isEnum">
      {{ showValue }}
    </template>
    <template else>
      {{ params.row[nameKey] }}
    </template>
  </span>
</template>

<script>
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'Status',
  mixins: [CellMixin],
  props: {
    isEnum: {
      type: Boolean,
      default: false
    },
    nameKey: {
      type: String,
      default: ''
    },
    idKey: {
      type: [String, Number],
      default: ''
    },
    lowerCaseIdkey: {
      type: [String, Number],
      default: ''
    },
    showValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    classes() {
      return [
        'one-line-overflow-hide', `status-${this.idKey}`,
        {
          [`status-${this.lowerCaseIdkey}`]: this.lowerCaseIdkey
        }
      ];
    }
  },
  methods: {}
};
</script>
