export default {
  functionOptionsType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000767","业务类型") /* "业务类型" */,
  functionOptionsTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000768","业务选项") /* "业务选项" */,
  thisSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000769","当前设置") /* "当前设置" */,
  selectSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500076A","可选设置") /* "可选设置" */,
  functionOptionsTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500076B","业务选项说明") /* "业务选项说明" */,
  changeSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500076C","修改成功！") /* "修改成功！" */,
  tipMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500076D","提示信息") /* "提示信息" */,
  pleaseWriteOkNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500076E","请输入正确的数字") /* "请输入正确的数字" */
};
