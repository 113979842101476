import ajax from '@/libs/ajax';
import { combDistrictStr } from '@/libs/district.js';
import util from '@/libs/util.js';

// 为收货地址、银行列表、发票列表添加 region
const setDistrictStr = res => { res && combDistrictStr(res); };

const batchSetDistrictStr = res => {
  // eslint-disable-next-line no-console
  console.log(res);
  res && res.content && res.content.forEach(setDistrictStr);
  return res;
};

// 获取支付方式
export const financialQuery = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/financial/query',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isArchive: 1
    }
  }).then(batchSetDistrictStr);
};

// 获取支付方式
export const getpaytype = (param) => {
  return ajax.request({
    url: `/voucher/payment/paytype?isArchive=1${param}`,
    method: 'get'
  });
};

// 获取结算方式
export const getFinalSettlementWay = () => {
  return ajax.request({ url: window.__app.context.productLine === 'u8c' ? '/voucher/order/settlementway/modelVo?isArchive=1' : '/pc/basic/BaseDataSrv/getFinalSettlementWay', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubNodes', 'cName');
  });
};

// 获取订单信息
export const getPaymentById = (data) => {
  return ajax.request({
    url: `/voucher/payment/getPayment?id=${data.id}&isArchive=1`,
    method: 'get'
  });
};
// 获取订单信息
export const getPaymentDetail = (id) => {
  return ajax.request({
    url: `/voucher/payment/paymentvoucher/detail/${id}?isArchive=1`,
    method: 'get'
  });
};
export const getPaymentDetailById = (id) => {
  return ajax.request({
    url: `/report/history/payment/detail/${id}?isReport=1`,
    method: 'get'
  });
};

// 获取财务组织
export const getSettlementOrgs = (data) => {
  return ajax.request({
    url: `/orginazation/bizproxy/${data}/settlement/org/list?isArchive=1`,
    method: 'get'
  });
};
// 获取财务组织
export const getAllSettlementOrgs = (data) => {
  return ajax.request({
    url: '/orginazation/org/user/list/16?isArchive=1',
    method: 'get'
  });
};

// 获取财务部门
export const getSettlementDepartment = (orgId) => {
  return ajax.request({
    url: `/orginazation/department/data/${orgId}?isArchive=1&enable=true`,
    method: 'get'
  });
};
export const getCorprationFinancials = (data) => {
  return ajax.request({
    url: '/pc/corp/CorprationSrv/getCorprationFinancials',
    method: 'get',
    data
  });
};

export const getDefaultCorpBank = (agentId, params) => {
  return ajax.request({
    url: `/orginazation/corprationfinancial/${agentId}/agent/default?isArchive=1${params}`,
    method: 'get'
  });
};

// 获取网银付款方式
export const getCyberbankPayTypes = (data) => {
  let type = 0; // 表示PC端
  data['datatype'] = 'json';
  data['isArchive'] = 1;
  return ajax.request({
    url: `/cyberbank/${data.bizId}/${type}/paytypes`,
    method: 'get',
    data
  });
};

// 各网银付款方式的费率
export const getPoundageDetailInfo = (data) => {
  return ajax.request({
    url: `/cyberbank/${data.bizId}/cyberbankpoundage?isArchive=1`,
    method: 'get'
  });
};

// 获取费率信息
export const getCyberbankdetail = (data) => {
  return ajax.request({
    url: `/cyberbank/${data.bizId}/cyberbankdetail?isArchive=1=`,
    method: 'get',
    data
  });
};

// 获取个人银行 企业银行
export const getAllUnionpayBankInfo = () => {
  return ajax.request({
    url: '/pc/basic/PaySrv/getAllUnionpayBankInfo',
    method: 'get'
  });
};

// 获取商家列表
export const getBizs = () => {
  return ajax.request({
    url: '/orginazation/merchant/user/list?isArchive=1',
    method: 'get'
  });
};
// 招行支付方式
export const getCmbPayWay = (data) => {
  let url = `/cmb/bank/cmbpayway/${data.bizId}/false?yy=1`;
  if (data['orgId']) {
    url += `&orgId=${data.orgId}`;
  }
  return ajax.request({
    url,
    method: 'post',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 获取畅捷支付相关支付渠道
export const getChanpayChannels = () => {
  return ajax.request({
    url: '/cyberbank/chanpaychannelgroup?isArchive=1',
    method: 'get'
  });
};
export const getChanpayGatewayPcChannels = () => {
  return ajax.request({
    url: '/cyberbank/chanpaychannelgroup?isArchive=1',
    method: 'get'
  });
};

export const getCalcTotalPays = (data) => {
  return ajax.request({
    url: '/pc/basic/PaySrv/calcTotalPays',
    method: 'get',
    data
  });
};
// 提交
export const paysave = (data) => {
  let url = '/voucher/payment/paysave';
  return ajax.request({
    url,
    method: 'post',
    data: {
      datatype: 'json',
      data: data.pv,
      isArchive: 1
    }
  });
};

/**
 * @method getCmbPayInfo 招商支付暂不支持ys
 */
export const getCmbPayInfo = (data) => {
  return ajax.request({
    url: '/pc/basic/PaySrv/getCmbPayInfo',
    method: 'post',
    data: {
      model: data,
      datatype: 'json'
    }
  });
};
export const updateABCBankPaymentVoucher = (url) => {
  return ajax.request({
    url: url,
    method: 'get'
  });
};

export const confirmChanpayQPay = (data) => {
  return ajax.request({
    url: '/pc/basic/PaySrv/confirmChanpayQPay',
    method: 'post',
    data
  });
};
// 汇款银行设置默认银行
export const setDefaultAgentBank = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/financial/setdefault',
    method: 'post',
    data
  });
};
// 设置默认收款银行
export const setDefaultCorpBank = (url, data) => {
  return ajax.request({
    url: url,
    method: 'put',
    data
  });
};

export const getVoucherByVoucherNO = (data) => {
  return ajax.request({
    url: '/voucher/payment/voucherinfo',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const getNotUsedPaymentsByOrg = (data) => {
  return ajax.request({
    url: '/voucher/payment/paymentdetailvo/notused/page',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const saveOrderBalance = (data) => {
  return ajax.request({
    url: '/voucher/payment/paymentvoucher/balancepay',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getInitPayCurrency = (data) => {
  return ajax.request({
    url: '/voucher/payment/paymentvoucher/initpaymentvoucher',
    method: 'post',
    data
  });
};

export const settlemethodcontrast = (data) => {
  return ajax.request({
    url: '/voucher/order/settlemethodcontrast/query',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const updateABCPaymentVoucher = (url) => {
  return ajax.request({
    url: url,
    method: 'get'
  });
};
// 获取收款银行
export const getCorprationFinancialList = (data) => {
  return ajax.request({
    url: '/orginazation/corprationfinancial/page',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const setDefaultCorprationFinancial = (id, orgId) => {
  return ajax.request({
    url: `/orginazation/corprationfinancial/default/${id}`,
    method: 'put',
    data: {
      orgId,
      id,
      isArchive: 1
    }
  });
};
export const getCloudbill = () => {
  return ajax.request({
    url: '/cloudbill/payBanks?isArchive=1',
    method: 'get'
  });
};
export const getABCPaymentType = () => {
  return ajax.request({
    url: '/cyberbank/Abc/paymentType?isArchive=1',
    method: 'get'
  });
};
export const getDefineData = (bizId) => {
  return ajax.request({
    url: `/meta/bill/6/bizId/define/list?bizId=${bizId}&isArchive=1`,
    method: 'get'
  });
};
// 根据商家获取销售组织
export const getSaleOrgs = (bizId) => {
  return ajax.request({
    url: `/orginazation/merchant/${bizId}/org/user/list/2?isArchive=1`,
    method: 'get'
  });
};

export const getAgentFund = (data) => {
  return ajax.request({
    url: '/voucher/payment/getAgentFund?isArchive=1',
    method: 'get',
    data: {
      ...data
    }
  });
};
// 费用账户适配返商品类型明细展示(PC)
/*********
 *
 * /marketingbill/rebate/surplusRebate
  传参：通过querySchema方式传 bizId   salesOrgId    agentid
  返回值：amountCount    amountProductCount   numberProductCount
 *
**
*/
export const getSurplusRebate = (data) => {
  return ajax.request({
    url: '/marketingbill/rebate/surplusRebate',
    method: 'post',
    data: {
      ...data,
      isMarket: 1
    }
  });
};
export const getNccCredit = (agentCode, financeOrgCode, saleOrgCode) => {
  return ajax.request({
    url: `/ncc/credit/queryList?isArchive=1&agentCode=${agentCode}&financeOrgCode=${financeOrgCode}&saleOrgCode=${saleOrgCode}`,
    method: 'get'
  });
  // return ajax.request({
  //   url: '/ncc/credit/queryList?isArchive=1&agentId=lskehu&financeOrgId=A01',
  //   method: 'get'
  // });
};

export const getCreditAccountByCond = (agentId, financeOrgId, saleOrgId) => {
  return ajax.request({
    url: '/creditmix/getCreditAccountByCond',
    method: 'post',
    data: {
      datatype: 'json',
      isMarket: 1,
      data: {
        agentId,
        financeOrgId,
        saleOrgId
      }
    }
  });
};

// 财务新老架构 /marketing/option/isNewFinanceFi
export const isNewFinanceFi = () => {
  return ajax.request({
    url: '/marketing/option/isNewFinanceFi',
    method: 'get',
    data: {
      isMarket: 1
    }
  });
};

// 根据单据获取可用的资金账户列表
export const getCapitalAccountList = (params) => {
  return ajax.request({
    url: '/account/voucher/preBusinessVer',
    method: 'post',
    data: {
      isMarket: 1,
      datatype: 'json',
      data: { ...params }
    }
  });
};

// 余额支付-单据核销接口
export const capitalAccountSubmit = (params) => {
  return ajax.request({
    url: '/account/voucher/businessVer',
    method: 'post',
    data: {
      isMarket: 1,
      datatype: 'json',
      data: { ...params }
    }
  });
};
// 在线支付状态 /cyberbank/common/queryPaymentStatus/${document.getElementById("out_trade_no").innerHTML}?isArchive=1
export const queryPaymentStatus = (no) => {
  return ajax.request({
    url: `/cyberbank/common/queryPaymentStatus/${no}?isArchive=1`,
    method: 'get'
  });
};
// 支付信息 /cyberbank/payOrderProxy
export const payOrderProxy = (data) => {
  return ajax.request({
    url: '/cyberbank/payOrderProxy',
    method: 'get',
    data: {
      ...data,
      isArchive: 1,
      isNewPay: 1
    }
  });
};
