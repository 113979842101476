export default {
  nickName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500066C","昵称") /* "昵称" */,
  dimension: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500066D","维度") /* "维度" */,
  selectServiceHotline: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500066E","选择客服类型") /* "选择客服类型" */,
  fillInCorrectly: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500066F","请正确填写！") /* "请正确填写！" */,
  service: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000670","客服") /* "客服" */,
  notSpace: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000671","不能输入空格！") /* "不能输入空格！" */,
  notLong: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000672","长度不能超过30位！") /* "长度不能超过30位！" */,
  hotLine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000673","热线") /* "热线" */,
  oneMoreService: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000674","请选择新增客服类型！") /* "请选择新增客服类型！" */
};
