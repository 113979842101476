export default {
  defaultAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000321","默认权限") /* "默认权限" */,
  toExamine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000322","审核") /* "审核" */,
  modify: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000323","修改") /* "修改" */,
  discardedTrial: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000324","弃审") /* "弃审" */,
  invoice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000325","开票") /* "开票" */,
  watchInvoice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000326","查发票") /* "查发票" */,
  confirmDeliveryContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000327","是否确认发货？") /* "是否确认发货？" */,
  confirmDeliverySuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000328","审核通过！") /* "审核通过！" */,
  backConfirmDveryWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000329","此发货单为ERP创建，请在ERP中弃审！") /* "此发货单为ERP创建，请在ERP中弃审！" */,
  backConfirmDveryContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500032A","是否要对发货单") /* "是否要对发货单" */,
  backConfirmDveryContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500032B","进行弃审？") /* "进行弃审？" */,
  backConfirmDverySuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500032C","弃审成功!") /* "弃审成功!" */,
  delDeliveryContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500032D","确定要删除发货单") /* "确定要删除发货单" */,
  delDeliveryContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500032E","吗？") /* "吗？" */,
  delDeliverySuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500032F","删除成功!") /* "删除成功!" */,
  // 批量审核
  batchSureWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000330","请选择至少一条状态为发货待审的发货单") /* "请选择至少一条状态为发货待审的发货单" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000331","请选择发货单") /* "请选择发货单" */,
  batchSureContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000332","是否要对选中的") /* "是否要对选中的" */,
  batchSureContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000333","张发货单中可能存在商品库存不足情况，如果继续审核则库存不足的订单行也会确认发货。是否继续审核发货单？") /* "张发货单中可能存在商品库存不足情况，如果继续审核则库存不足的订单行也会确认发货。是否继续审核发货单？" */,
  // 开发票
  eInvoice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000334","电子发票") /* "电子发票" */,
  leixing: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000335","类型") /* "类型" */,
  buyerTaxNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000336","纳税人识别号") /* "纳税人识别号" */,
  cInvoiceTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000337","发票抬头") /* "发票抬头" */,
  cInvoiceType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000338","发票类型") /* "发票类型" */,
  cInvoiceContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000339","发票内容") /* "发票内容" */,
  emailAddress: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500033A","邮箱地址") /* "邮箱地址" */,
  cInvoiceTelephone: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500033B","手机号码") /* "手机号码" */,
  iInvoiceMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500033C","可开票金额") /* "可开票金额" */,
  emailError: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500033D","邮箱格式不正确") /* "邮箱格式不正确" */,
  telephoneError: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500033E","手机号码格式不正确") /* "手机号码格式不正确" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500033F","请正确填写!") /* "请正确填写!" */,
  qiye: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000340","企业") /* "企业" */,
  geren: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000341","个人") /* "个人" */
};
