<template>
  <div class="archive archive-form p-b-60">
    <Row
      v-if="tree"
      class-name="actions">
      <Button
        v-if="showAdd"
        :disabled="addDisabled"
        icon="add"
        @click="add">
        {{ mlang('add') }}
      </Button>
      <Button
        v-if="showDel"
        :disabled="delDisabled"
        icon="delete"
        @click="del">
        {{ mlang('delete') }}
      </Button>
    </Row>
    <Row
      v-if="tree"
      class-name="title">
      <h1>{{ title }}</h1>
    </Row>
    <Row
      v-if="controls.length"
      :style="contentStyles"
      class-name="content">
      <div class="width100">
        <Form
          ref="form-meta"
          :model="formData"
          :label-width="120"
          :style="formStyles"
          label-position="right">
          <template v-for="(control,index) in controls">
            <template v-if="control.controlType == 'SelectTab'">
              <FormItem
                v-if="control.isShowIt"
                :ref="`form-item-${control.fieldName}`"
                :key="index"
                :prop="control.fieldName"
                :rules="rule(control)"
                :label="control.showCaption"
                :class="`archive-item item-${control.fieldName}`">
                <Row style="float:left">
                  <Col style="width:400px;">
                  <SelectTab
                    v-model="formData[control.fieldName]"
                    :element-id="control.fieldName"
                    :default-array="control.defaultArray"
                    :more-array="control.moreArray"
                    :name-key="control.setting.namekey"
                    :sort-key="control.setting.sortkey"
                    @on-change="control.setting.callback"></SelectTab>
                  </Col>
                </Row>
              </FormItem>
            </template>
            <template v-else>
              <FormItem
                v-if="control.isShowIt"
                :ref="`form-item-${control.fieldName}`"
                :key="index"
                :prop="control.fieldName"
                :rules="rule(control)"
                :label="control.showCaption"
                :class="setClassName(control)">
                <Row
                  :class-name="`row-${control.fieldName}`"
                  style="float:left">
                  <Col
                    :class-name="`col-${control.fieldName} item-${((control.isCanModify || control.controlType == 'Upload') ? control.controlType : 'Cell')}`"
                    :style="(control.specialType && control.specialType === 'renderTable')|| control.controlType == 'Upload' ? '' : 'width:400px;'">
                  <template v-if="!control.isCanModify">
                    <span>{{ getReadOnlyText(formData[control.fieldName], control,formData,formData) }}</span>
                  </template>
                  <template v-else>
                    <RadioGroup
                      v-if="control.controlType === 'Radio'"
                      v-model="formData[control.fieldName]"
                      :disabled="control.disabled"
                      @on-change="control.change">
                      <template v-for="(item,index2) in control.enumArray">
                        <Radio
                          :key="index2"
                          :label="item.value"
                          :disabled="control.disabled">
                          {{ item.title }}
                        </Radio>
                      </template>
                    </RadioGroup>
                    <RadioGroup
                      v-if="control.controlType === 'Status'"
                      v-model="formData[control.fieldName]"
                      :disabled="control.disabled"
                      @on-change="control.change">
                      <template v-for="(item,index2) in control.enumArray">
                        <Radio
                          :key="index2"
                          :label="item.value"
                          :disabled="control.disabled">
                          {{ item.title }}
                        </Radio>
                      </template>
                    </RadioGroup>
                    <CheckGroup
                      v-if="control.controlType === 'CheckboxGroup'"
                      v-model="formData[control.fieldName]"
                      :meta="control"
                      @change="checkGroupChange"></CheckGroup>
                    <Checkbox
                      v-if="control.controlType === 'Checkbox'"
                      v-model="formData[control.fieldName]"
                      :disabled="control.disabled"
                      @on-change="control.change"></Checkbox>
                    <Input
                      v-if="control.controlType === 'String'"
                      v-model="formData[control.fieldName]"
                      :disabled="control.disabled"
                      :clearable="control.isClearable && !control.disabled"
                      :maxlength="control.maxLength"
                      type="text"
                      @on-blur="control.blur"></Input>
                    <Textarea
                      v-if="control.controlType === 'Textarea'"
                      v-model="formData[control.fieldName]"
                      :disabled="control.disabled"
                      :maxlength="control.maxLength"></Textarea>
                    <InputNumber
                      v-if="control.controlType === 'Number'"
                      v-model="formData[control.fieldName]"
                      :max="control.max"
                      :min="control.min"
                      :string="control.isDefine"
                      :precision="control.numPoint"
                      class="ivu-input-wrapper"
                      type="text"></InputNumber>
                    <DatePicker
                      v-if="control.controlType === 'Date'"
                      :value="formData[control.fieldName]"
                      :editable="false"
                      :options="control.options"
                      type="date"
                      clearable
                      placement="bottom-start"
                      format="yyyy-MM-dd"
                      @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
                    <DatePicker
                      v-if="control.controlType === 'DateTime'"
                      :value="formData[control.fieldName]"
                      :editable="false"
                      :options="control.options"
                      type="datetime"
                      clearable
                      placement="bottom-start"
                      @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
                    <template v-if="control.controlType === 'Upload'">
                      <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :default-file-list="formData[control.fieldName]"
                        :on-success="handleUploadSuccess(control)"
                        :accept="uploadAccept(control)"
                        :format="uploadFormat(control)"
                        :max-size="control.maxSize*1024"
                        :on-exceeded-size="handleUploadMaxSize(control)"
                        :before-upload="handleBeforeUpload(control)"
                        :data="control.fields"
                        :action="control.action"
                        :multiple="setMultiple(control)">
                        <Button icon="ios-cloud-upload-outline">
                          {{ uploadBtnText(control) }}
                        </Button>
                        <span
                          @click.stop
                          v-html="uploadDesc(control)"></span>
                      </Upload>
                      <!-- <div
                        v-for="item in formData[control.fieldName]"
                        class="archive-upload-list">

                        <template v-if="item.status === 'finished' || item.id">
                          <img :src="item.url">
                          <div class="archive-upload-list-cover">
                            <Icon
                              type="ios-eye-outline"
                              @click.native="handlePreview(item)"></Icon>
                            <Icon
                              type="ios-trash-outline"
                              @click.native="handleUploadRemove(item,control)"></Icon>
                          </div>
                        </template>
                        <template v-else>
                          <Progress
                            v-if="item.showProgress"
                            :percent="item.percentage"
                            hide-info></Progress>
                        </template>
                        </div>-->
                    </template>
                    <template
                      v-if="control.controlType === 'TreeSelect' && control.fieldName === 'parentId'">
                      <TreeSelect
                        v-model="formData[control.fieldName]"
                        :instance-id="control.fieldName"
                        :normalizer="treeSelectNormalizer"
                        :options="treeParentData"
                        :disabled="control.disabled"
                        :no-options-text="mlang('noResults')"
                        :no-results-text="mlang('noResults')"
                        :loading-text="mlang('loading')"
                        :clearable="control.clearable && !control.disabled"
                        class="ivu-select archive-tree-select"
                        placeholder
                        @select="control.select" />
                    </template>
                    <template v-if="control.controlType === 'TelInput'">
                      <TelInput
                        v-model="formData[control.fieldName]"
                        :default-country="formData[control.regionCode || 'countryCode']"
                        :valid-characters-only="true"
                        :disabled-dial="false"
                        :disabled-input="false"
                        :show-icon="false"
                        class="content-phone"
                        @input="telInput(control,...arguments)"
                        @on-blur="control.blur" />
                    </template>
                    <template v-if="control.controlType === 'DateRange'">
                      <DatePicker
                        :value="formData[control.fieldName]"
                        :editable="false"
                        :options="control.options"
                        :clearable="true"
                        split-panels
                        type="daterange"
                        placement="bottom-start"
                        format="yyyy-MM-dd"
                        @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
                    </template>
                    <template v-if="control.controlType === 'DateTimeRange'">
                      <DatePicker
                        :value="formData[control.fieldName]"
                        :editable="false"
                        :options="control.options"
                        :clearable="true"
                        split-panels
                        type="datetimerange"
                        placement="bottom-start"
                        @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
                    </template>
                    <template
                      v-if="control.controlType === 'TreeSelect' && control.fieldName !== 'parentId'">
                      <!-- 通用型:待修改 -->
                      <TreeSelect
                        v-model="formData[control.fieldName]"
                        :instance-id="control.fieldName"
                        :normalizer="treeSelectNormalizer"
                        :options="control.data"
                        :disabled="control.disabled"
                        :no-results-text="mlang('noResults')"
                        :no-options-text="mlang('noResults')"
                        :loading-text="mlang('loading')"
                        :disable-branch-nodes="control.disableBranchNodes"
                        :clearable="control.isClearable && !control.disabled"
                        placeholder
                        class="ivu-select archive-tree-select"
                        @input="treeSelectInput"
                        @select="control.select" />
                    </template>
                    <template v-if="control.controlType === 'ReferDimension'">
                      <ReferDimension
                        ref="referDimension"
                        :is-promotion-multiple="isPromotionMultiple"
                        :show-label="showLabel"
                        :demension-meta="control"
                        :current-data="formData"
                        :is-channel-agent="control.isChannelAgent"
                        @demension-change-event="demensionChangeEvent"></ReferDimension>
                    </template>
                    <Select
                      v-if="control.controlType == 'Select'"
                      v-model="formData[control.fieldName]"
                      :element-id="control.fieldName"
                      :filterable="control.filterable || false "
                      :multiple="control.multiple || false"
                      :disabled="control.disabled"
                      :placeholder="control.placehoder || ''"
                      @on-open-change="control.onOpenChange"
                      @on-change="control.change">
                      <Option
                        v-for="option in control.enumArray"
                        :key="option.value"
                        :label="option.title"
                        :value="option.value">
                      </Option>
                    </Select>
                    <!-- <SelectTab
                    v-if="control.controlType == 'SelectTab'"
                    v-model="formData[control.fieldName]"
                    :element-id="control.fieldName"
                    :default-array="control.defaultArray"
                    :more-array="control.moreArray"
                    :name-key="control.setting.namekey"
                    :sort-key="control.setting.sortkey"
                    @on-change="control.setting.callback"
                      ></SelectTab>-->
                    <span v-if="control.controlType === 'Cell'">{{ formData[control.fieldName] }}</span>
                    <template v-if="control.controlType === 'Refer'">
                      <Refer
                        :id="control.fieldName"
                        ref="refer-{{control.fieldName}}"
                        v-model="formData[control.fieldName]"
                        :refer-type="control.referType"
                        :show-text="formData[control.showText]"
                        :biz-meta="bizMeta"
                        :control="control"></Refer>
                    </template>
                    <template v-if="control.controlType === 'OuterRefer'">
                      <Refer
                        :id="control.fieldName"
                        ref="refer-{{control.fieldName}}"
                        v-model="formData[control.fieldName]"
                        :refer-type="control.referType"
                        :show-text="formData[control.fieldName]"
                        :biz-meta="bizMeta"
                        :control="control"
                        @on-ok="outerReferOk"></Refer>
                    </template>
                    <city-select
                      v-if="control.controlType === 'CitySelect'"
                      v-model="formData[control.fieldName]"
                      @on-init="handleCitySelectInit(...arguments,control)"
                      @input="handleCitySelected(...arguments,control)"></city-select>
                    <template v-if="control.controlType === 'RichEditor'">
                      <vue-ueditor-wrap
                        ref="ueditor"
                        v-model="formData[control.fieldName]"
                        :upload-type="bizMeta.uploadType"
                        :control="control"
                        :config="UEditorConfig"
                        class="archive-richeditor"
                        @change="handleRicheditorChange"></vue-ueditor-wrap>
                    </template>
                    <ArchiveRender
                      v-if="control.controlType === 'render'"
                      :control="control"
                      :data="formData"
                      :render="control.render"></ArchiveRender>
                  </template>
                  <div v-if="control.controlType === 'Upload'">
                    <VoucherPreviewUpload
                      :class="[{'view-preview-upload-ctrl': !control.isCanModify }]"
                      :form-data="formData"
                      :editable="true"
                      :form-file-data="formData[control.fieldName]"
                      :item="control"
                      @handleUploadDel="handleUploadDel" />
                  </div>
                  </Col>
                </Row>
              </FormItem>
            </template>
          </template>
        </Form>
      </div>
    </Row>
    <Modal
      v-model="previewVisible"
      :title="mlang('preview')">
      <img
        v-if="previewVisible"
        :src="previewImgUrl"
        style="width: 100%" />
    </Modal>
    <div
      v-if="showFooter"
      class="bottom-page archive-bottom">
      <Row>
        <Button
          type="primary"
          @click="save">
          {{ mlang('save') }}
        </Button>
        <slot name="archive-form-button">
          <!-- <Button
            v-if="this.$route.query.isEdit"
            type="info"
          @click="save">{{ mlang('saveAndback') }}</Button>-->
          <!-- <Button
            v-if="this.$route.query.titleType=='add'"
            type="info"
          @click="saveAndAdd">{{ mlang('saveAndAdd') }}</Button>-->
        </slot>
        <Button @click="cancel">
          {{ mlang('cancel') }}
        </Button>
      </Row>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import ArchiveRender from './archive-render';
import ArchiveForm from '_c/common/archive-form/archive-form';
import Refer from '_c/common/refer/refer';
import ReferTree from '_c/common/refer/refer-tree';
import ReferDimension from '_c/common/refer/refer-dimension';
import FormMeta from '_c/common/form-meta/form-meta';
import CommonMixin from '@/mixin/common.js';
import UploadMixin from '@/mixin/upload.js';
import VoucherArchiveMixin from '@/mixin/voucher-archive.js';
import FormMixin from '@/mixin/form.js';
import Textarea from '@/u-components/components/textarea';
import SelectTab from '@/u-components/components/select-tab';
import CitySelect from '@/u-components/components/city-select-t/city-select.vue';
import Mixin from '@/mixin/archiveList.js';
import { getBillMeta } from '@/api/meta';
import { on, off } from '@/u-components/utils/dom';
import { deepCopy } from '@/u-components/utils/assist';
import Emitter from '@/u-components/mixins/emitter';
import VoucherPreviewUpload from '../voucher/voucher-preview-upload.vue';

import dayjs from 'dayjs';
import { getReadOnlyText } from '@/mixin/commonMethods.js';

export default {
  name: 'ArchiveForm',
  components: {
    Refer,
    Textarea,
    ReferTree,
    FormMeta,
    ArchiveForm,
    ArchiveRender,
    SelectTab,
    CitySelect,
    VoucherPreviewUpload,
    ReferDimension
  },
  mixins: [Emitter, CommonMixin, Mixin, VoucherArchiveMixin, FormMixin, UploadMixin],
  props: {
    isPromotionMultiple: {
      type: Boolean,
      default: true
    },
    bizMeta: {
      required: true,
      type: Object
    },
    tree: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default() {
        return [];
      }
    },
    treeParentData: {
      type: Array,
      default() {
        return [];
      }
    },
    actionStatusVal: {
      type: String,
      default: 'C'
    },
    addDisabled: {
      type: Boolean,
      default: false
    },
    delDisabled: {
      type: Boolean,
      default: false
    },
    isOrgsBasePage: {
      type: Boolean,
      default: false
    },
    setContentHeight: {
      type: [String, Number] // 用于控制档案内容的高度
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      previewVisible: false,
      previewImgUrl: null,
      // 顶部按钮
      oriControls: [],
      controls: [],
      formData: {},
      oriData: {},
      editOriData: {},
      height: 450,
      lastActionStatus: null,
      actionStatus: null,
      uploadList: [],
      uploadControl: null,
      statusEnumArray: [
        { 'name': 'enable', 'title': this.mlang('enable'), 'value': 1 },
        { 'name': 'disable', 'title': this.mlang('disable'), 'value': 0 }
      ],
      formStyles: {
      }
    };
  },
  computed: {
    title() {
      // 顶部按钮
      let key = this.actionStatus === 'C' ? 'add' : 'edit';
      let text = this.mlang(key);
      return `${text}${this.bizMeta.title}`;
    },
    parentData() {
      if (this.actionStatus === 'C') {
        return this.treeData;
      }

      return this.treeData;
    },
    showFooter() {
      return this.showEdit && !this.bizMeta.hiddenFooter;
    },
    showEdit() {
      let def = true;
      if (this.bizMeta.auth && this.bizMeta.auth.edit) {
        def = this.$hasAuth(this.bizMeta.auth.edit);
      }
      return def;
    },
    showAdd() {
      let def = true;
      if (this.bizMeta.auth && this.bizMeta.auth.add) {
        def = this.$hasAuth(this.bizMeta.auth.add);
      }
      return def;
    },
    showDel() {
      let def = true;
      if (this.bizMeta.auth && this.bizMeta.auth.del) {
        def = this.$hasAuth(this.bizMeta.auth.del);
      }
      return def;
    },
    contentStyles() {
      let self = this;
      if (this.setContentHeight) {
        self.formStyles = {};
        return {
          height: `${this.setContentHeight}px`,
          overflow: 'auto'
        };
      } else {
        return {};
      }
    },
    UEditorConfig() {
      const UMEDITOR_HOME_URL = window.__app.context.vendorsHome + '/js/umeditor/';
      let readonly = !!this.bizMeta.ueditorReadOnly;
      return {
        readonly,
        UMEDITOR_HOME_URL,
        initialFrameWidth: 750,
        initialFrameHeight: 300
      };
    }
  },
  watch: {
    actionStatusVal: {
      handler(val, oldVal) {
        this.actionStatus = val;
      }
    },
    actionStatus: {
      handler(val, oldVal) {
        if (val === oldVal) {
          return;
        }
        this.lastActionStatus = oldVal;
        if (val === 'C') {
          this.setAdd();
        }
        this.controls = deepCopy(this.oriControls);
      },
      deep: true
    }
  },
  created() {
    if (!this.bizMeta.billId) {
      console.error('billId is required'); //eslint-disable-line
      return;
    }
    this.init();
  },
  beforeDestroy() {
    off(window, 'resize', this.handleResize);
  },
  mounted() {
    this.handleResize();
    on(window, 'resize', this.handleResize);
  },
  methods: {
    getReadOnlyText,
    telInput(control, phone, phoneObj) {
      const countryKey = control.countryKey || 'countryCode';
      const phoneNumber = control.phoneNumber || 'mobile';
      this.setDetailByKey(countryKey, phoneObj.regionCode);
      this.setDetailByKey(phoneNumber, phone);
    },
    setAdd() {
      let param = {};
      if (this.bizMeta.beforeAdd && this.bizMeta.beforeAdd()) {
        param = this.bizMeta.beforeAdd();
      }
      if (this.bizMeta.actions.AddAction) {
        this.bizMeta.actions.AddAction(param).then((res) => {
          let parentId = null;
          let idKey = this.bizMeta.idKey || 'id';
          if (this.formData[idKey]) {
            parentId = this.formData[idKey];
          }
          const data = Object.assign({}, this.oriData, res, { parentId });
          this.formData = data;
          this.$emit('after-add', this.formData);
        });
      } else {
        let parentId = null;
        let idKey = this.bizMeta.idKey || 'id';
        if (this.formData[idKey]) {
          parentId = this.formData[idKey];
        }
        const data = Object.assign({}, this.oriData, { parentId });
        this.formData = data;
        this.$emit('after-add', this.formData);
      }
    },
    setClassName(control) {
      let ctrlList = ['Upload', 'RichEditor', 'Textarea'];
      let className = ['archive-item', `item-${control.fieldName}`];
      if (ctrlList.includes(control.controlType)) {
        className.push('item-mutil-line');
      }
      if (control.controlType === 'RichEditor') {
        className.push('item-richeditor');
      }
      return className;
    },
    setStyle(control) {},
    setMultiple(control) {
      return control.isMultiple || true;
    },
    findControl(fieldName) {
      return this.controls.find((v) => {
        return v.fieldName === fieldName;
      });
    },
    setControl(fieldName, key, value) {
      const control = this.findControl(fieldName);
      if (control) {
        // control[key] = value;
        this.$set(control, key, value);
      }
    },
    setDetailByKey(key, value) {
      const keyArr = this.mixin_getPropByPath(this.formData, key).keyArr;
      let tmpObj = this.formData;
      for (let index = 0; index < keyArr.length; index++) {
        const tmpKey = keyArr[index];
        if (index < keyArr.length - 1) {
          if (_.isNil(this.formData[tmpKey])) this.$set(this.formData, tmpKey, {});
          tmpObj = this.formData[tmpKey];
        } else if (index === keyArr.length - 1) {
          this.$set(tmpObj, tmpKey, value);
        }
      }
    },
    setDetail(data = {}) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const value = data[key];
          this.setDetailByKey(key, value);
        }
      }
      this.spilArr.forEach((v) => {
        this.formData[v] = this.mixin_getPropByPath(this.formData, v).v;
      });
      this.editOriData = deepCopy(this.formData);
    },
    getModelData() {
      return this.formData;
    },
    reloadData() {
      this.$refs['form-meta'].resetFields();
      this.formData = deepCopy(this.oriData);
    },
    test() {},
    treeSelectInput(val, controlName) {
      if (val === undefined) {
        this.formData[controlName] = null;
      }
      this.$nextTick(() => {
        this.$refs['form-meta'].validateField(controlName);
      });
    },

    checkGroupChange(fieldName, data) {
      this.formData[fieldName] = data;
      this.$emit('checkbox');
    },
    init(config = {}) {
      getBillMeta(this.bizMeta.billId)
        .then((res) => {
          this.billMeta = res;
          this.makeFormItems();
          this.makeData();
          this.$emit('ready');
          this.$nextTick(() => {
            if (this.$refs.upload) {
              this.uploadList = this.$refs.upload[0].fileList;
            }
          });
          // this.actionStatus = 'C';
        })
        .catch((err) => {
          console.error(err); // eslint-disable-line no-console
          this.$Loading.error();
        });
    },
    resetPage() {
      this.formData = deepCopy(this.oriData);
      // this.setAdd();
    },
    makeFormItems() {
      const _vm = this;
      this.spilArr = [];
      if (!this.billMeta) {
        return;
      }
      let tempGroup = this.billMeta.currentTemplate.currentTplGroups[0];
      let group;
      if (tempGroup.hasOwnProperty('corpBillTplGroups')) {
        group = tempGroup.corpBillTplGroups[0];
      } else {
        group = tempGroup;
      }
      if (this.bizMeta.setFormColumns) {
        group = this.bizMeta.setFormColumns(group);
      }
      if (group.currentBillItems && group.currentBillItems.length) {
        group.currentBillItems = group.currentBillItems.filter((v) => {
          return v.isHidden !== true;
        });
        group.currentBillItems.map((item, index) => {
          if (item.fieldName && item.fieldName.indexOf('.') > -1) {
            this.spilArr.push(item.fieldName);
          }
          // item.isCanModify = false;
          item.maxLength = item.maxLength || 255;
          if (item.controlType === 'Refer' && item.isDefine) {
            item.showText = item.fieldName;
          }
          if (item.controlType === 'Number') {
            item.max = 100000000;
            item.min = -100000000;
          }
          if (item.controlType === 'Upload') {
            item.maxSize = 2;
            item.maxQuantity = 5;
            item.fileType = 'image';
            this.uploadControl = item;
          }
          if (!item.blur) {
            item.blur = () => {};
          }
          if (!item.select) {
            item.select = () => {};
          }
          if (!item.change) {
            if (_vm.bizMeta.changes && _vm.bizMeta.changes[item.fieldName]) {
              item.change = (v) => {
                _vm.bizMeta.changes[item.fieldName](v, item);
              };
            } else {
              item.change = (status) => {
                return status;
              };
            }
          }
          if (!item.onOpenChange) {
            item.onOpenChange = () => {};
          }
          if (item.isEnum) {
            try {
              item.enumArray = JSON.parse(item.enumString);
              if (item.enumStoreType === 'NAME') {
                item.enumArray.map((v) => {
                  v.value = v.name;
                });
              }
              if (item.enumStoreType === 'SPECIAL') {
                item.enumArray.map((v) => {
                  v.value = v.specialValue;
                });
              }
            } catch (error) {
              console.error(' enumString error-->' + item.fieldName); //eslint-disable-line
            }
          }
          item.disabled = !!item.disabled;
          if (item.action) {
            if (this.bizMeta.actions[item.action]) {
              item = this.bizMeta.actions[item.action](item);
            } else {
              console.error('no action ->' + item.action); //eslint-disable-line
            }
          }
          group.currentBillItems[index] = item;
        });
      }
      this.controls = deepCopy(group.currentBillItems);
      this.oriControls = deepCopy(this.controls);
    },
    handleResize() {
      const singlePageEl = document.getElementById('single-page');
      const singlePageHeight = singlePageEl.clientHeight;
      this.height = singlePageHeight - 47;
    },
    add() {
      // this.actionStatus = 'C';
      this.$emit('changeStatus', 'C');
    },
    del() {
      this.$Modal.confirm({
        content: this.mlang('sureDel'),
        okText: this.mlang('ok'),
        title: '',
        onOk: (res, instance) => {
          this.actionStatus = 'D';
          let key = this.bizMeta.delIdKey || 'id';
          let param = {};
          param[key] = this.formData[key];
          if (this.bizMeta.beforeDel && this.bizMeta.beforeDel(param)) {
            param = this.bizMeta.beforeDel(param);
          }
          this.bizMeta.actions.DelAction(param).then((res) => {
            this.$Message.success({
              content: this.mlang('operateSuccess')
            });
            // this.editOriData = res;
            this.formData = deepCopy(this.oriData);
            // this.actionStatus = 'C';
            this.$emit('del-ok-child');
          });
        }
      });
    },
    save() {
      this.$refs['form-meta'].validate((valid) => {
        if (valid) {
          let saveData = this.formData;
          const cloneData = deepCopy(saveData);
          // this.makeNullData(saveData);
          if (this.bizMeta.beforeSave) {
            if (this.bizMeta.beforeSave(cloneData)) {
              saveData = this.bizMeta.beforeSave(cloneData);
            } else {
              return false;
            }
          }
          this.bizMeta.actions.SaveAction(saveData).then((res) => {
            this.actionStatus = 'U';
            this.$emit('changeStatus', 'U');
            this.$Message.success({
              content: this.mlang('operateSuccess')
            });
            this.formData = res;
            this.$emit('save-ok-child', this.formData);
            this.reloadPage();
          });
        } else {
          this.$Message.error(this.mlang('pleaseEnterRight'));
        }
      });
    },
    saveAndAdd() {
      this.$refs['form-meta'].validate((valid) => {
        if (valid) {
          let saveData = this.formData;
          if (this.bizMeta.beforeSave && this.bizMeta.beforeSave(this.formData)) {
            saveData = this.bizMeta.beforeSave(this.formData);
          }
          this.bizMeta.actions.SaveAction(saveData).then((res) => {
            this.actionStatus = 'U';
            this.$emit('changeStatus', 'U');
            this.$Message.success({
              content: this.mlang('operateSuccess')
            });
            this.formData = res;
            this.$emit('save-ok-child', this.formData);
            this.init();
          });
        } else {
          this.$Message.error(this.mlang('pleaseEnterRight'));
        }
      });
    },
    reloadAll() {
      this.formData = deepCopy(this.oriData);
      this.resetFields();
      this.setAdd();
    },
    resetFields() {
      this.$refs['form-meta'].resetFields();
    },
    resetForm() {
      this.resetFields();
      this.resetPage();
    },
    reloadPage() {
      if (!this.tree) {
        this.$router.push(this.bizMeta.backRoute);
      }
    },
    cancel() {
      this.$Modal.confirm({
        content: this.mlang('abandorSure'),
        className: 'modal-confirm',
        okText: this.mlang('ok'),
        onOk: (res, instance) => {
          this.$emit('cancel-click');
          this.actionStatus = this.lastActionStatus;
          this.resetFields();
          this.resetStatus();
          this.reloadPage();
        }
      });
    },
    resetStatus() {
      switch (this.actionStatus) {
        case 'C':
          this.formData = deepCopy(this.oriData);
          break;
        case 'U':
          this.formData = deepCopy(this.editOriData);
          break;
        default:
          break;
      }
    },
    rule(control) {
      const rule = this.mixin_makeRule(control, this.bizMeta);
      return rule;
    },
    makeData() {
      const formData = this.mixin_makeData(this.controls);
      this.formData = formData;
      this.oriData = deepCopy(formData);
    },
    expandTree() {
      this.$refs['refer-tree'].setTreeExpand(this.formData);
    },
    setDatePickerValue(value, type, control, key) {
      if (type === 'date' && value.indexOf(':') !== -1) {
        value = dayjs(value).format('YYYY-MM-DD');
        this.setDetailByKey(control.fieldName, value);
      }
      if (control.controlType === 'DateRange' && value) {
        if (value[0] !== '' && value[0] !== '') {
          this.setDetailByKey('startDate', dayjs(value[0]).format('YYYY-MM-DD'));
          this.setDetailByKey('endDate', dayjs(value[1]).format('YYYY-MM-DD'));
        } else {
          this.setDetailByKey('startDate', null);
          this.setDetailByKey('endDate', null);
          this.setDetailByKey('DateRange', null);
        }
      }
      if (control.controlType === 'DateTime' && value) {
        this.setDetailByKey(control.fieldName, dayjs(value).format('YYYY-MM-DD HH:mm:ss'));
      }
      if (control.controlType === 'DateTimeRange' && control.fieldName === 'DateTimeRange' && value) {
        if (value[0] !== '' && value[0] !== '') {
          this.setDetailByKey('startDate', dayjs(value[0]).format('YYYY-MM-DD HH:mm:ss'));
          this.setDetailByKey('endDate', dayjs(value[1]).format('YYYY-MM-DD HH:mm:ss'));
          this.setDetailByKey('DateTimeRange', [dayjs(value[0]).format('YYYY-MM-DD HH:mm:ss'), dayjs(value[1]).format('YYYY-MM-DD HH:mm:ss')]);
        } else {
          this.setDetailByKey('startDate', null);
          this.setDetailByKey('endDate', null);
          this.setDetailByKey('DateTimeRange', null);
        }
      }
    },
    outerReferOk(data, control) {
      this.setDetail(data);
    },
    handleUploadChange(value, control) {
      this.$set(this.formData, control.fieldName, value);
    },
    handleUploadDel(control, index) {
      let file = this.formData[control.fieldName][index];
      if (file.actionStatus === 'C') {
        this.formData[control.fieldName].splice(index, 1);
      } else {
        file.actionStatus = 'D';
        this.$set(file, 'beRemoved', true);
      }
      this.$set(this.formData, control.fieldName, this.formData[control.fieldName]);
    },
    // 返回维度数据
    demensionChangeEvent(control, demensiontype, demensionOptions) {
      this.$set(this.formData, control.fieldName, demensiontype);
      if (!control.isSpecial && demensiontype === 0 && this.formData[control.fieldName] !== undefined) {
        delete this.formData[control.fieldName];
      }
      this.$set(this.formData, control.demensionsName, demensionOptions);
      if (control.controlType === 'ReferDimension' && control.isOnlyAgent) {
        return;
      }
      if (demensiontype === 1) {
        this.controls.map((v) => {
          if (v.controlType === 'ReferDimension' && v.isOnlyAgent) {
            v.isShowIt = false;
          }
        });
      } else {
        this.controls.map((v) => {
          if (v.controlType === 'ReferDimension' && v.isOnlyAgent) {
            v.isShowIt = true;
          }
        });
      }
    },
    handleCitySelected(region, control) {
      // (region) => { if(typeof control.onCitySelectd === 'function'){ control.onCitySelectd(region); } }
      if (typeof control.onCitySelectd === 'function') {
        control.onCitySelectd(region);
      }
    },
    handleRicheditorChange(value, control) {
      this.setDetailByKey(control.fieldName, value);
    },
    // 在citySelect的control添加参数，该参数用于之后校验，详情在mixin/form.js可见
    handleCitySelectInit({ isNonShowCountry }, control) {
      control.isNonShowCountry = isNonShowCountry;
    }
  }
};
</script>
<style lang="less" scoped>
.archive-upload-list {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-right: 20px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
.archive-upload-list img {
  width: 100%;
  height: 100%;
}
.archive-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.archive-upload-list:hover .archive-upload-list-cover {
  display: block;
}
.archive-upload-list-cover i {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
