<template>
  <span class="payment-status">
    <Poptip
      :options="popTipProps"
      :trigger="popTrigger"
      placement="bottom"
      popper-class="poptip">
      <Icon
        custom="iconfont icon-beizhu"></Icon>
      <div
        slot="content"
        class="payment-status-poptip-content remarks">
        <p><span class="memo-main-content">{{ params.row.cRemark }}</span></p>
      </div>
    </poptip>
  </span>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import { fetchOrderMemo } from '@/api/order';
export default {
  name: 'OrderMemo',
  mixins: [PopTipMixin, CellMixin],
  data: function() {
    return {
      memos: [],
      hasFetch: false
    };
  },
  methods: {
  }
};
</script>
