/**
 * 该方法用于兼容新老页面的跳转
 * 使用规则：
 * 1、在routerMap中注册新老页面路由
 *    1）routerMap中参数介绍：
 *        1）key：与config.js中同步（具体看config.js）；
 *        2）enable：前端是否开启新页面，默认false未开启，true开启
 *        3）oldUrl：默认老页面的路由地址（不含参数）
*        4）newRouterName：新页面的路由name
 *        5）menuCode：跳转页面时选中对应菜单，值从config.js里找需要选中的菜单的code
 * * 2、页面中所有跳转路由的地方都要调用agentLink函数
 *    1）agentLink参数说明：
 *        1）vm：this，方法已经注册到全局，调用时不需要传，required
 *        2）type：要跳转的页面key（即routerMap中的key），类型--String，required
 *        3）query：查询参数（新页面可以多加参数，但老页面有的新页面必须要有），类型--Object
 *        4）hash：哈希值（原本在老页面中用到，为了兼容老页面，现在新页面也要传），类型--String
 *        5）newWindow：是否开启新页签，true开启，false不开启，类型--Boolean
 *        6）url：用于未在本次改版中且未注册的页面，路由不会有新旧的差别，所以本版暂不修改，此类页面调用agentLink方法只需要传this、type（统一用noRegister）、老的完整uri即可
 *        7) add: 有的oldUrl新增和详情路径不一样，例如订单详情和新增，该字段用来区分,add为true 则读取oldAddUrl字段
 *    2）agentLink函数已注册到util.js中，具体调用可参考agent\home\components\productList.vue
 * 遗留问题：
 * 1、menu-bar/index里菜单跳转以后需要与该方法合并
 * 2、老页面点击跳页，路由参数不会改变。以后控制
 */

const routerMap = {
  // 商品详情
  productDetail: {
    enable: true,
    oldUrl: '/agentpage/products/index',
    newRouterName: 'productDetail',
    menuCode: 'agentProductMenu'
  },
  // 商品列表
  agentProductMenu: {
    enable: true,
    oldUrl: '/agentpage/products/list',
    newRouterName: 'products',
    menuCode: 'agentProductMenu'
  },
  // 首页
  agentIndex: {
    enable: true,
    oldUrl: '/agent/',
    newRouterName: 'home',
    menuCode: 'agentIndex'
  },
  // 订单列表
  agentOrderShow: {
    enable: true,
    oldUrl: '/agentpage/orders/list',
    newRouterName: 'Orders',
    menuCode: 'agentOrderShow'
  },
  // 资金账户
  agentCapital: {
    enable: true,
    oldUrl: '/agentpage/pays/list',
    newRouterName: 'Pays',
    menuCode: 'agentCapital'
  },
  // 数据统计
  agentReportsShow: {
    enable: false,
    oldUrl: '/agentpage/OrderStatistics/showStatistics',
    menuCode: 'agentReportsShow'
  },
  // 订单详情
  orderDetail: {
    enable: true,
    oldUrl: '/agentpage/Orders/detail',
    oldAddUrl: '/agentpage/Orders/neworder',
    menuCode: 'orderDetail',
    newRouterName: 'aorder'
  },
  // 发货单详情
  deliveryDetail: {
    enable: true,
    oldUrl: '/agentpage/Delivery/detail',
    menuCode: 'deliveryDetail',
    newRouterName: 'adelivery'
  },
  // 费用单详情
  expenseDetail: {
    enable: true,
    oldUrl: '/agentpage/ExpenseOrders/getDetail',
    menuCode: 'expenseDetail',
    newRouterName: 'Expense'
  },
  // 退货单详情
  saleReturnDetail: {
    enable: true,
    oldUrl: '/agentpage/SaleReturns/newSaleReturnIndex',
    menuCode: 'saleReturnDetail',
    newRouterName: 'SaleReturn'
  },
  // 支付单详情
  payDetail: {
    enable: true,
    oldUrl: '/agentpage/pays/payment',
    menuCode: 'payDetail',
    newRouterName: 'payment'
  },
  // 帮助中心
  helpCenter: {
    enable: true,
    oldUrl: '/agentpage/Helps/detail',
    newRouterName: 'helpCenter',
    menuCode: 'helpCenter'
  },
  // shoppingCart: {
  //   enable: true,
  //   oldUrl: 'agentpage/shoppingcarts/list',
  //   newRouterName: 'shoppingCart',
  //   menuCode: 'shoppingCart'
  // },
  // 本版未改版，不需要注册的统一用
  noRegister: {
    enable: false,
    menuCode: 'noRegister'
  }
};
// 详情跳转区分新老页面
const agentLink = ({ vm, type, query, hash = '', newWindow, url, add }) => {
  if (!vm || !type) {
    // eslint-disable-next-line no-console
    console.error('vm and type is must!');
    return;
  }

  const ueConfig = vm.$store.getters.ueConfig;
  const toRouter = routerMap[type];
  if (!toRouter) {
    // eslint-disable-next-line no-console
    console.error('type is error!');
    return;
  }

  // 没改版的页面直接传url和type
  if (url) {
    if (!newWindow) {
      // 有问号说明有参，没问号说明没参，为了兼容菜单选中
      const hasQue = /\?/.test(url);
      url += hasQue ? `&menuCode=${toRouter.menuCode}` : `?menuCode=${toRouter.menuCode}`;
      vm.$router.push({
        path: '/agent/frame',
        query: {
          uri: url
        }
      });
    } else {
      let outUrl = 'v#/agent/frame';
      outUrl += `?menuCode=${toRouter.menuCode}` + `&uri=${encodeURIComponent(url)}`;
      vm.$open(outUrl);
    }
    return;
  }
  if (ueConfig && ueConfig.enable && type === 'payDetail') {
    ueConfig.pages[type] = true;
  }
  if ((ueConfig && ueConfig.enable && ueConfig.pages[type] && toRouter.enable && toRouter.newRouterName) || this.$store.getters.currentUser.__isU8C) { // 跳转新页面
    query = Object.assign({}, { menuCode: toRouter.menuCode }, query);
    if (newWindow) { // 新开页签
      let routerUrl = vm.$router.resolve({ name: toRouter.newRouterName, query });
      let routerHref = '';
      if (routerUrl) {
        routerHref = `v${routerUrl.href}`;
      }
      vm.$open(routerHref);
    } else { // 本页跳转
      vm.$router.push({
        name: toRouter.newRouterName,
        query
      });
    }
  } else { // 跳转老页面
    const url = add ? toRouter.oldAddUrl : toRouter.oldUrl;
    let uri = `${url}?menuCode=${toRouter.menuCode}`;
    let queryStr = `menuCode=${toRouter.menuCode}`;
    if (query) {
      for (let key in query) {
        uri += `&${key}=${query[key]}`;
        queryStr += `&${key}=${query[key]}`;
      }
    }
    if (hash) {
      uri += `#/${hash}`;
    }
    if (newWindow) { // 新开页签
      let outUrl = `v#/agent/frame?${queryStr}&uri=${encodeURIComponent(uri)}`;
      vm.$open(outUrl);
    } else { // 本页跳转，嵌在frame里
      query = Object.assign({}, query, { menuCode: toRouter.menuCode, uri: uri });
      vm.$router.push({
        path: '/agent/frame',
        query
      });
    }
  }
};

export default agentLink;
