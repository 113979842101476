<template>
  <div class="archive-list">
    <VoucherList
      ref="voucherList"
      :data="data"
      :query="query"
      :biz-meta="meta"></VoucherList>
  </div>
</template>

<script>
import VoucherList from '_c/common/voucher-list';
import ArchiveListMixin from '@/mixin/archive-list.js';
import Emitter from '@/u-components/mixins/emitter';
import { getToken, getPageId } from '@/api/decorate';// getDecorates
import ajax from '@/libs/ajax';
// import { getStyle } from '@/u-components/utils/assist';

export default {
  name: 'Decorates',
  components: { VoucherList },
  mixins: [ArchiveListMixin, Emitter],
  data() {
    const meta = this.getDefaultMeta();
    return {
      data: {},
      query: this.queryList,
      meta,
      isLoaing: false,
      token: '',
      url: '',
      pageId: ''
    };
  },
  computed: {
    BO() {
      return this.$store.getters.BO;
    },
    lang() {
      let arr = this.$store.state.app.lang && this.$store.state.app.lang.split('-');
      let str = arr[1].toUpperCase();
      let lang = arr[0] + '_' + str;
      return lang;
    }
  },
  created() {
    let vm = this;
    const querys = [getToken()];
    ajax.all(querys).then(
      ajax.spread(function(token) {
        vm.token = token;
        vm.url = window.__app.context.HTTP_SHOP_DESIGNER;
      })
    );
  },
  methods: {
    getDefaultMeta() {
      const meta = {
        isShowFooter: false,
        billId: 312,
        isAgent: false,
        multiple: false,
        tableMetaSetting: false,
        actions: {
          RowAction: this.RowAction
        },
        async: true
      };
      return meta;
    },
    RowAction(item) {
      const vm = this;
      item.render = (h, params) => {
        const jump = () => {
          if (vm.url) {
            this.$open(`${vm.url}/designer?yxyToken=${vm.token}&pagelistid=${this.pageId}&locale=${this.lang}`);
          } else {
            console.log('config.js没有配置HTTP_SHOP_DESIGNER')//eslint-disable-line
          }
        };
        return (
          <div class="action">
            <Row class-name="text" type="flex" justify="center">
              <span class="rollBackBtn" on-click={jump}>
                {this.mlang('decorate')}
              </span>
            </Row>
          </div>
        );
      };
    },
    setMeta() {
      this.meta = this.getDefaultMeta();
    },
    queryList(query) {
      getPageId().then((pageId) => {
        this.pageId = pageId;
        this.data = { content: [{ id: pageId, name: this.mlang('home') }] };
      });
    }
  }
};
</script>
