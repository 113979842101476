<template>
  <Status
    :is-enum="true"
    :params="params"
    :column="column"
    :id-key="idKey"
    :lower-case-idkey="lowerCaseIdkey"
    :show-value="showValue">
  </Status>
</template>

<script>
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'Enum',
  mixins: [CellMixin],
  data() {
    return {
    };
  },
  computed: {
    showValue() {
      const binggo = this.getBingle();
      return binggo && binggo.title;
    },
    idKey() {
      const binggo = this.getBingle();
      return binggo && binggo.name;
    },
    lowerCaseIdkey() { // 兼容后端枚举
      const binggo = this.getBingle();
      return binggo && binggo.name.toLocaleLowerCase();
    }
  },
  methods: {
    getBingle() {
      if (this.column.isEnum && this.column.enumString) {
        let enumArray = [];
        try {
          enumArray = JSON.parse(this.column.enumString);
        } catch (error) {
          console.error('enumCtrl enumString error-->' + this.column.enumString); //eslint-disable-line
        }
        if (enumArray.length) {
          let binggo = enumArray.find((v) => {
            const entity = v[this.column.enumStoreType.toLowerCase()];
            return entity == this.params.row[this.column.fieldName]; //eslint-disable-line
          });
          return binggo;
        }
      }
    }
  }
};
</script>
