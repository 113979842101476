import selfInfoParentView from '@/views/agent/selfInfo/components/selfInfoListTab.vue';

const userbase = {
  path: 'userbase',
  name: 'userbase',
  meta: { module: 'userbase' },
  component: selfInfoParentView,
  children: [
    {
      path: 'usersafe',
      name: 'usersafe',
      meta: { module: 'userbase' },
      component: () => import('@/views/agent/selfInfo/components/usersafe/usersafe.vue')
    },
    {
      path: 'userbase',
      name: 'userbase',
      meta: { module: 'userbase' },
      component: () => import('@/views/agent/selfInfo/components/userBase/aUserBase.vue')
    }
  ]
};
export default userbase;
