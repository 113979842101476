<style lang="less">
@import './main.less';
</style>

<template>
  <div
    v-if="!currentUser.isAgent"
    class="main main-corp">
    <div class="main-header-con">
      <div class="main-header">
        <corp-header></corp-header>
      </div>
    </div>
    <div class="sidebar-menu-con">
      <CorpMenu
        ref="sideMenu"
        :menu-list="menuList"
        :open-names="openedSubmenuArr"
        :menu-theme="menuTheme"
        @on-change="handleSubmenuChange"></CorpMenu>
    </div>
    <div
      id="single-page-right"
      ref="single-page-right"
      :style="{left:'142px'}"
      :class="rightPageClass">
      <!-- <div class="tags-con">
                <tags-page-opened :pageTagsList="pageTagsList"></tags-page-opened>
      </div>-->
      <div
        id="single-page"
        class="single-page">
        <keep-alive
          v-if="isRouterAlive"
          :include="cacheList"
          :max="20">
          <router-view ref="corpView" :key="$route.fullPath" @hook:mounted="childMounted"></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapGetters } from 'vuex';
import CorpHeader from './components/headers.vue';
import CorpMenu from './components/corp-menus.vue';
import { routers } from '@/router/router';
import util from '@/libs/util.js';
import LayoutMixin from '@/mixin/layout.js';
import { hasScrolled } from '@/u-components/utils/assist';
import { getCloudServiceParams } from '@/api/user';

export default {
  name: 'CorpMain',
  components: {
    CorpHeader,
    CorpMenu
  },
  inject: ['getTheme', 'broadcastToExtend'],
  provide() {
    return {
      reloadPage: this.reloadPage,
      updateOpenName: this.updateOpenName,
      mainResize: this.mainResize,
      reload: this.reload
    };
  },
  mixins: [LayoutMixin],
  data() {
    return {
      showVerticalScrollBar: false,
      shrink: false,
      isRouterAlive: true,
      userName: '',
      isFullScreen: false
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    rightPageClass() {
      return [
        'single-page-con',
        'page-corp',
        {
          'show-vertical-scrollbar': this.showVerticalScrollBar
        }
      ];
    },
    tagNavList() {
      return this.$store.state.app.tagNavList;
    },
    cacheList() {
      const list = ['ParentView', ...(this.tagNavList.length ? this.tagNavList.filter((item) => !(item.meta && item.meta.notCache)).map((item) => _.upperFirst(_.camelCase(item.name))) : [])];
      return list;
    },
    notCacheName() {
      const list = [this.$route.meta && this.$route.meta.notCache ? this.$route.name : ''];
      return list;
    },
    tagRouter() {
      return this.$store.state.app.tagRouter;
    },
    pageTagsList() {
      return this.$store.state.app.pageOpenedList; // 打开的页面的页面对象
    },
    currentPath() {
      return this.$store.state.app.currentPath; // 当前面包屑数组
    },
    avatorPath() {
      return localStorage.avatorImgPath;
    },
    cachePage() {
      return this.$store.state.app.cachePage;
    },
    lang() {
      return this.$store.state.app.lang;
    },
    openedSubmenuArr() {
      return this.$store.state.app.openedSubmenuArr;
    },
    menuTheme() {
      return this.$store.state.app.menuTheme;
    },
    mesCount() {
      return this.$store.state.app.messageCount;
    }
  },
  watch: {
    $route(newRoute, oldRoute) {
      // let { meta } = newRoute;
      sessionStorage.isAgentSide = false;
      // const existRoute = this.tagNavList.find((v) => v.name === name);
      // if (existRoute) {
      //   //
      //   meta = existRoute.meta;
      // }
      // if (params) {

      // }
      const hasExistRoute = this.tagNavList.find((v) => v.fullPath === newRoute.fullPath);
      let to = newRoute;
      if (hasExistRoute) {
        to = hasExistRoute;
      }
      this.addTag({
        route: { ...to },
        type: 'push',
        vm: this
      });
      const newTagList = util.getNewTagList(this.tagNavList, newRoute, oldRoute);
      this.setTagNavList(newTagList);
      const bill = this.$getBO('MANYTAB') && newRoute.meta && newRoute.meta.bill;
      if (!bill & newRoute.name !== oldRoute.name) {
        this.hideSameModuleTag({ newRoute, oldRoute });
      }

      this.$refs.sideMenu.updateOpenName(newRoute.name);
    },
    lang() {
      util.setCurrentPath(this, this.$route.name); // 在切换语言时用于刷新面包屑
    }
  },
  mounted() {
    this.init();
    // 设置初始语言
    // this.setLocal(this.$i18n.locale);
    if (!this.currentUser.__isBiz) {
      this.openCloudService();
    }
  },
  created() {
    if (this.currentUser.isAgent) {
      window.location.href = util.getAgentUrl();
      return;
    }
    this.getTheme();
    sessionStorage.isAgentSide = false;
    // 显示打开的页面的列表
    this.$store.commit('setOpenedList');
    this.$store.commit('changeMainTheme', 'default');
    this.$store.dispatch('getUeConfig');
  },
  methods: {
    ...mapMutations(['setTagNavList', 'addTag', 'setHomeRoute', 'hideSameModuleTag', 'setLocal']),
    childMounted() {
      const vm = this.$refs['corpView'];
      this.broadcastToExtend({ action: 'mounted', params: { vm } });
    },
    init() {
      this.setTagNavList([]);
      this.setHomeRoute(routers);
      this.addTag({
        route: this.$store.state.app.homeRoute,
        vm: this
      });
      if (!this.tagNavList.find((item) => item.name === this.$route.name)) {
        this.addTag({
          route: this.$route,
          type: 'push',
          vm: this
        });
      }
      this.$store.commit('setMessageCount', 3);
    },
    toggleClick() {
      this.shrink = !this.shrink;
    },
    handleClickUserDropdown(name) {
      if (name === 'ownSpace') {
        util.openNewPage(this, 'ownspace_index');
        this.$router.push({
          name: 'ownspace_index'
        });
      } else if (name === 'loginout') {
        // 退出登录
        this.$store.commit('logout', this);
        this.$store.commit('clearOpenedSubmenu');
        this.$router.push({
          name: 'login'
        });
      }
    },
    checkTag(name) {
      let openpageHasTag = this.pageTagsList.some((item) => {
        if (item.name === name) {
          return true;
        }
      });
      if (!openpageHasTag) {
        //  解决关闭当前标签后再点击回退按钮会退到当前页时没有标签的问题
        util.openNewPage(this, name, this.$route.params || {}, this.$route.query || {});
      }
    },
    handleSubmenuChange(name) {
      let willpush = true;
      if (this.beforePush !== undefined) {
        if (!this.beforePush(name)) {
          willpush = false;
        }
      }
      if (willpush) {
        const menuItem = this.getMenuItemByName(name);
        if (menuItem.isSystem) {
          if (menuItem.openType === 2) {
            // 系统菜单 openType = 2 跳转外部链接
            this.$open(`/redirect?menuId=${menuItem.id}`);
          } else if (menuItem.openType === 1) {
            let url = `/redirect?menuId=${menuItem.id}`;
            menuItem.title = menuItem.caption;
            util.menuClickOldPage(url, this, menuItem);
          } else {
            if (menuItem && menuItem.bVue) {
              let query = util.param2Obj(menuItem.url);
              this.$router.push({
                name,
                query
              });
            } else {
              let url = menuItem && menuItem.url;
              menuItem.title = menuItem.caption;
              util.menuClickOldPage(url, this, menuItem);
            }
          }
        } else {
          if (menuItem.isNewWindow) {
            this.$open(`/redirect?menuId=${menuItem.id}`);
          } else {
            this.$router.push({
              name: 'frame',
              params: {
                module: menuItem.parent && menuItem.parent.name
              },
              query: {
                menuId: menuItem.id,
                menuid: menuItem.sysCode,
                title: menuItem.caption
              }
            });
          }
        }
      }
    },
    beforePush(name) {
      // if (name === 'accesstest_index') {
      //     return false;
      // } else {
      //     return true;
      // }
      return true;
    },
    fullscreenChange(isFullScreen) {
      // console.log(isFullScreen);
    },
    updateOpenName(m) {
      if (m) {
        this.$refs.sideMenu.updateOpenNameById(m);
      }
    },
    reloadPage() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
    mainResize() {
      this.$nextTick(() => {
        const el = this.$refs['single-page-right'];
        this.showVerticalScrollBar = hasScrolled(el);
      });
    },
    // 云客服
    openCloudService() {
      this.$util.getScript('://fwq.yonyou.com/assets2/js/popup/loadIService.js').then(res => {
        getCloudServiceParams().then(res => {
          if (res) {
            window.loadPopDialog(
              res.acid,
              {
                userId: res.detail.userId,
                tantentId: res.detail.tantentId,
                serviceNum: 'XTXIANGMU' },
              '',
              true,
              true);
          }
        });
      });
    }
  }
};
</script>
