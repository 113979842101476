<template>
  <div
    :class="wrapClasses"
    :style="styles">
    <div :class="classes">
      <div
        v-if="showSlotHeader"
        ref="title"
        :class="[prefixCls + '-title']">
        <slot name="header"></slot>
      </div>
      <div
        v-if="showHeader"
        ref="header"
        :class="[prefixCls + '-header','scroll-table-head']"
        @mousewheel="handleMouseWheel">
        <table-head
          :meta-setting="metaSetting"
          :prefix-cls="prefixCls"
          :style-object="tableHeaderStyle"
          :columns="cloneColumns"
          :column-rows="columnRows"
          :obj-data="objData"
          :columns-width="columnsWidth"
          :data="rebuildData"></table-head>
      </div>
      <!-- realdata-table↓↓↓ -->
      <div
        v-show="!((!!localeNoDataText && (!rebuildData || rebuildData.length === 0)) || (!!localeNoFilteredDataText && (!rebuildData || rebuildData.length === 0))) &&!loading"
        ref="refBodyWrapper"
        :class="[prefixCls + '-body','main-body']"
        :style="bodyStyle"
        @scroll="handleBodyScroll">
        <table-body
          ref="tbody"
          :meta-setting="metaSetting"
          :prefix-cls="prefixCls"
          :style-object="tableStyle"
          :columns="cloneColumns"
          :data="rebuildData"
          :columns-width="columnsWidth"
          :sub-key="subKey"
          :render-tr="renderTr"
          :cell-merge="cellMerge"
          :suspendkey="tableSusPendKey"
          :obj-data="objData"
          :row-height="rowHeight"></table-body>
      </div>
      <!-- nodata-table↓↓↓ -->
      <div
        v-show="((!!localeNoDataText && (!rebuildData || rebuildData.length === 0)) || (!!localeNoFilteredDataText && (!rebuildData || rebuildData.length === 0)))"
        :class="[prefixCls + '-tip','no-data-table']"
        :style="bodyStyle"
        @scroll="handleBodyScroll">
        <table
          cellspacing="0"
          cellpadding="0"
          border="0">
          <tbody
            :suspendkey="tableSusPendKey">
            <tr>
              <td
                v-if="!loading"
                :style="{'width':`${visibleTableWidth}px`,'height':'300px'}"
                class="no-data-td">
                <!-- <SvgIcon
                  icon-class="nodata"
                  class-name="no-data-icon"></SvgIcon> -->
                <i class="icon icon-no-data-td"></i>
                <span
                  v-if="!data || data.length === 0"
                  class="table-no-data"
                  v-html="localeNoDataText"></span>
                <span
                  v-else
                  v-html="localeNoFilteredDataText"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- left-table↓↓↓ -->
      <div
        v-if="isLeftFixed"
        v-show="!loading"
        :class="fixedLeftTableClasses"
        :style="fixedTableStyle">
        <div
          v-if="showHeader"
          :class="fixedHeaderClasses">
          <table-head
            :meta-setting="metaSetting"
            :prefix-cls="prefixCls"
            :style-object="fixedTableStyle"
            :columns="leftFixedColumns"
            :column-rows="columnRows"
            :render-tr="renderTr"
            :fixed-column-rows="leftFixedColumnRows"
            :obj-data="objData"
            :columns-width="columnsWidth"
            :data="rebuildData"
            :indeterminate="indeterminate"
            fixed="left"></table-head>
        </div>
        <div
          ref="refLeftfixedWrapper"
          :class="[prefixCls + '-fixed-body']"
          :style="fixedBodyStyle"
          @mousewheel="handleFixedMousewheel"
          @DOMMouseScroll="handleFixedMousewheel">
          <table-body
            ref="refLeftfixedBody"
            :meta-setting="metaSetting"
            :prefix-cls="prefixCls"
            :style-object="fixedTableStyle"
            :columns="leftFixedColumns"
            :data="rebuildData"
            :columns-width="columnsWidth"
            :render-tr="renderTr"
            :obj-data="objData"
            :sub-key="subKey"
            :suspendkey="tableSusPendKey"
            :row-height="rowHeight"
            fixed="left"></table-body>
        </div>
      </div>
      <!-- right-table↓↓↓ 基本不用-->
      <!-- <div
        v-if="isRightFixed"
        v-show="!loading"
        :class="fixedBodyRightClasses"
        :style="fixedRightTableStyle">
        <div
          v-if="showHeader"
          :class="fixedHeaderClasses">
          <table-head
            :prefix-cls="prefixCls"
            :style-object="fixedRightTableStyle"
            :columns="rightFixedColumns"
            :column-rows="columnRows"
            :fixed-column-rows="rightFixedColumnRows"
            :obj-data="objData"
            :columns-width="columnsWidth"
            :data="rebuildData"
            fixed="right"></table-head>
        </div>
        <div
          v-if="cloneData.length"
          ref="fixedRightBody"
          :class="[prefixCls + '-fixed-body']"
          :style="fixedBodyStyle"
          @mousewheel="handleFixedMousewheel"
          @DOMMouseScroll="handleFixedMousewheel">
          <table-body
            :prefix-cls="prefixCls"
            :style-object="fixedRightTableStyle"
            :columns="rightFixedColumns"
            :data="rebuildData"
            :columns-width="columnsWidth"
            :render-tr="renderTr"
            :obj-data="objData"
            :sub-key="subKey"
            :suspendkey="tableSusPendKey"
            fixed="right"></table-body>
        </div>
      </div>
       <div
        ref="refRightfixedBody"
        v-if="isRightFixed"
        :class="[prefixCls + '-fixed-right-header']"
        :style="fixedRightHeaderStyle">
      </div> -->
      <!-- 悬浮操作-table↓↓↓-->

      <div
        v-if="isSuspend"
        ref="fixedBodySuspend"
        :class="fixedBodySuspendClasses"
        :style="fixedSuspendTableStyle">
        <div
          v-if="showHeader"
          :class="fixedHeaderClasses">
          <table-head
            :meta-setting="metaSetting"
            :prefix-cls="prefixCls"
            :style-object="fixedSuspendTableStyle"
            :columns="suspendFixedColumns"
            :column-rows="columnRows"
            :fixed-column-rows="suspendFixedColumnRows"
            :obj-data="objData"
            :columns-width="columnsWidth"
            :data="rebuildData"
            fixed="suspend"></table-head>
        </div>
        <div
          v-show="!loading"
          ref="fixedSuspendBody"
          :class="fixedSuspendBodyClass"
          :style="fixedBodyStyle"
          @mousewheel="handleFixedMousewheel"
          @DOMMouseScroll="handleFixedMousewheel">
          <table-body
            :meta-setting="metaSetting"
            :prefix-cls="prefixCls"
            :style-object="fixedSuspendTableStyle"
            :columns="suspendFixedColumns"
            :data="rebuildData"
            :columns-width="columnsWidth"
            :render-tr="renderTr"
            :obj-data="objData"
            :sub-key="subKey"
            :suspendkey="tableSusPendKey"
            :cell-merge="cellMerge"
            :row-height="rowHeight"
            fixed="suspend"></table-body>
        </div>
      </div>
      <div
        v-if="isSuspend"
        :class="[prefixCls + '-fixed-right-header']"
        :style="fixedSuspendHeaderStyle">
      </div>

      <div
        v-if="showSlotFooter"
        ref="footer"
        :class="[prefixCls + '-footer']">
        <slot name="footer"></slot>
      </div>
      <!-- meta-setting -->
      <div v-if="metaSetting.enable" class="fixed-setting">
        <MetaSetting
          class-name="table-header-setting-icon"
          :icon="true"
          :columns="metaSetting.columns"
          :bill-id="metaSetting.billId"
          :source="source"
          :all-meta-columns="allMetaColumns">
        </MetaSetting>
      </div>
    </div>
    <Spin
      v-if="loading"
      fix
      size="large">
      <slot name="loading"></slot>
    </Spin>
  </div>
</template>

<script>
import tableHead from './table-head.vue';
import tableBody from './table-body.vue';
// import Spin from '../spin';
import { oneOf, getStyle, deepCopy, getScrollBarSize } from '../../utils/assist';
import { on, off } from '../../utils/dom';
import Csv from '../../utils/csv';
// import ExportCsv from './export-csv';
import Locale from '../../mixins/locale';
import elementResizeDetectorMaker from 'element-resize-detector';
import { getAllColumns, convertToRows, convertColumnOrder, getRandomStr } from './util';

const prefixCls = 'ivu-table';
let columnKey = 1;
export default {
  name: 'Table',
  components: {
    tableHead,
    tableBody
    // Spin
  },
  provide() {
    return {
      tableRoot: this
    };
  },
  mixins: [Locale],
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    allMetaColumns: {
      type: Array,
      default() {
        return [];
      }
    },
    size: {
      type: String,
      validator(value) {
        return oneOf(value, ['small', 'large', 'default']);
      }
    },
    width: {
      type: [Number, String]
    },
    height: {
      type: [Number, String]
    },
    maxHeight: {
      type: [Number, String]
    },
    stripe: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    highlightRow: {
      type: Boolean,
      default: false
    },
    rowClassName: {
      type: Function,
      default() {
        return '';
      }
    },
    renderTr: {
      type: Function,
      default() {
        return '';
      }
    },
    context: {
      type: Object
    },
    noDataText: {
      type: String
    },
    noFilteredDataText: {
      type: String
    },
    disabledHover: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    },
    subKey: {
      type: String
    },
    metaSetting: {
      type: Object,
      default() {
        return {};
      }
    },
    // 是否留出footer高度
    notNeedTableFooter: {
      type: Boolean,
      default: false
    },
    // 单元格合并
    cellMerge: Function,
    rowHeight: {
      type: Number,
      default: 40
    },
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    const colsWithId = this.makeColumnsId(this.columns);
    return {
      ready: false,
      tableWidth: 0,
      visibleTableWidth: 0,
      columnsWidth: {},
      prefixCls: prefixCls,
      compiledUids: [],
      objData: this.makeObjData(), // checkbox or highlight-row
      rebuildData: [], // for sort or filter
      cloneColumns: this.makeColumns(colsWithId),
      suspendCloneColumns: this.makeSuspendColumns(colsWithId),
      columnRows: this.makeColumnRows(false, colsWithId),
      leftFixedColumnRows: this.makeColumnRows('left', colsWithId),
      rightFixedColumnRows: this.makeColumnRows('right', colsWithId),
      suspendFixedColumnRows: this.makeColumnRows('suspend', colsWithId),
      allColumns: getAllColumns(colsWithId), // for multiple table-head, get columns that have no children
      showSlotHeader: true,
      showSlotFooter: true,
      bodyHeight: 0,
      scrollBarWidth: getScrollBarSize(),
      currentContext: this.context,
      cloneData: deepCopy(this.data), // when Cell has a button to delete row data, clickCurrentRow will throw an error, so clone a data
      showVerticalScrollBar: false,
      showHorizontalScrollBar: false,
      headerWidth: 0,
      headerHeight: 0,
      bCheckAll: false,
      scrollLeft: false,
      tableKey: this.$util.uuid(8), // 悬浮按钮表的唯一值
      indeterminate: false
    };
  },
  computed: {
    localeNoDataText() {
      if (this.noDataText === undefined) {
        return this.t('i.table.noDataText');
      } else {
        return this.noDataText;
      }
    },
    localeNoFilteredDataText() {
      if (this.noFilteredDataText === undefined) {
        return this.t('i.table.noFilteredDataText');
      } else {
        return this.noFilteredDataText;
      }
    },
    wrapClasses() {
      return [
        `${prefixCls}-wrapper`,
        {
          [`${prefixCls}-hide`]: !this.ready,
          [`${prefixCls}-with-header`]: this.showSlotHeader,
          [`${prefixCls}-with-footer`]: this.showSlotFooter
        }
      ];
    },
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-${this.size}`]: !!this.size,
          [`${prefixCls}-border`]: this.border,
          [`${prefixCls}-stripe`]: this.stripe,
          [`${prefixCls}-with-fixed-top`]: !!this.height
        }
      ];
    },
    fixedHeaderClasses() {
      return [
        `${prefixCls}-fixed-header`,
        {
          [`${prefixCls}-fixed-header-with-empty`]: !this.rebuildData.length
        }
      ];
    },
    fixedLeftTableClasses() {
      let noShadow = false;
      if (!this.showHorizontalScrollBar) {
        noShadow = true;
      } else if (this.showHorizontalScrollBar && !this.scrollLeft) {
        noShadow = true;
      }
      return [
        `${prefixCls}-fixed`,
        `${prefixCls}-fixed-left`,
        {
          [`${prefixCls}-fixed-no-shadow `]: noShadow
        }
      ];
    },
    fixedBodyRightClasses() {
      return [
        `${prefixCls}-fixed-right`,
        {
          [`${prefixCls}-fixed-no-shadow `]: !this.showHorizontalScrollBar
        }
      ];
    },
    fixedBodySuspendClasses() {
      var fixedClassName = `${prefixCls}-fixed-right ${prefixCls}-fixed-suspend`;
      if (this.tableKey) {
        fixedClassName = `${prefixCls}-fixed-right ${prefixCls}-fixed-suspend ${prefixCls}-suspend-` + this.tableKey;
      }
      return [
        fixedClassName,
        {
          [`${prefixCls}-fixed-no-shadow `]: !this.showHorizontalScrollBar
        }
      ];
    },
    tableSusPendKey() {
      let tableSusPendKey = '';
      if (this.tableKey) {
        tableSusPendKey = `${prefixCls}-suspend-` + this.tableKey;
      }
      return tableSusPendKey;
    },
    styles() {
      let style = {};
      if (this.height) {
        const height = parseInt(this.height);
        style.height = `${height}px`;
      } else {
        if (this.loading) {
          style.height = '300px';
        }
        if (this.maxHeight) {
          const maxHeight = parseInt(this.maxHeight);
          style.maxHeight = `${maxHeight}px`;
        }
      }
      if (this.width) style.width = `${this.width}px`;
      return style;
    },
    tableStyle() {
      let style = {};
      if (this.tableWidth !== 0) {
        let width = '';
        if (this.bodyHeight === 0) {
          width = this.tableWidth;
        } else {
          width = this.tableWidth - (this.showVerticalScrollBar ? this.scrollBarWidth : 0);
        }
        //                    const width = this.bodyHeight === 0 ? this.tableWidth : this.tableWidth - this.scrollBarWidth;
        style.width = `${width}px`;
      }
      return style;
    },
    tableHeaderStyle() {
      let style = {};
      if (this.tableWidth !== 0) {
        let width = '';
        width = this.tableWidth;
        style.width = `${width}px`;
      }
      return style;
    },
    fixedTableStyle() {
      let style = {};
      let width = 0;
      this.leftFixedColumns.forEach((col) => {
        if (col.fixed && col.fixed === 'left') width += col._width;
      });
      style.width = `${width}px`;
      return style;
    },
    fixedRightTableStyle() {
      let style = {};
      let width = 0;
      this.rightFixedColumns.forEach((col) => {
        if (col.fixed && col.fixed === 'right') width += col._width;
      });
      // width += this.scrollBarWidth;
      style.width = `${width}px`;
      style.right = `${this.showVerticalScrollBar ? this.scrollBarWidth : 0}px`;
      return style;
    },
    fixedSuspendTableStyle() {
      let style = {};
      let width = 0;
      this.suspendFixedColumns.forEach((col) => {
        if (col.fixed && col.fixed === 'suspend') width += col._width;
      });
      // width += this.scrollBarWidth;
      // style.width = `${width}px`;
      // ys宽度不够导致窜行加50像素 不影响其他
      style.width = `${width + 60}px`;
      style.right = `${this.showVerticalScrollBar ? this.scrollBarWidth : 0}px`;
      return style;
    },
    fixedRightHeaderStyle() {
      let style = {};
      let width = 0;
      let height = this.headerHeight + 1;
      if (this.showVerticalScrollBar) {
        width = this.scrollBarWidth;
      }
      style.width = `${width}px`;
      style.height = `${height}px`;
      return style;
    },
    fixedSuspendHeaderStyle() {
      let style = {};
      let width = 0;
      let height = this.headerHeight;
      if (this.showVerticalScrollBar) {
        width = this.scrollBarWidth;
      }
      style.width = `${width}px`;
      style.height = `${height}px`;
      return style;
    },
    bodyStyle() {
      let style = {};
      if (this.bodyHeight !== 0) {
        const height = this.bodyHeight;
        if (this.height) {
          style.height = `${height}px`;
        } else if (this.maxHeight) {
          style.maxHeight = `${height}px`;
        }
      }
      return style;
    },
    fixedSuspendBodyClass() {
      return [
        prefixCls + '-fixed-body',
        {
          'm-r-8': this.showVerticalScrollBar
        }
      ];
    },
    fixedBodyStyle() {
      let style = {};
      if (this.bodyHeight !== 0) {
        let height = this.bodyHeight - (this.showHorizontalScrollBar ? this.scrollBarWidth : 0);
        style.height = this.showHorizontalScrollBar ? `${height}px` : `${height - 2}px`;
      }
      return style;
    },
    leftFixedColumns() {
      return convertColumnOrder(this.cloneColumns, 'left');
    },
    rightFixedColumns() {
      return convertColumnOrder(this.cloneColumns, 'right');
    },
    suspendFixedColumns() {
      return convertColumnOrder(this.suspendCloneColumns, 'suspend');
    },
    isLeftFixed() {
      return this.columns.some((col) => col.fixed && col.fixed === 'left');
    },
    isRightFixed() {
      return this.columns.some((col) => col.fixed && col.fixed === 'right');
    },
    // 悬浮
    isSuspend() {
      return this.columns.some((col) => col.fixed && col.fixed === 'suspend');
    },
    // watch columns引用类型不能比较值相等 计算属性破话原型链
    updateCol() {
      return JSON.parse(JSON.stringify(this.columns));
    }

  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        // if(isEqual(newVal,oldVal)){
        //   return
        // }

        const oldDataLen = this.rebuildData.length;
        this.objData = this.makeObjData();
        this.rebuildData = this.makeDataWithSortAndFilter();
        this.handleResize();
        if (!oldDataLen) {
          this.fixedHeader();
        }
        // here will trigger before clickCurrentRow, so use async
        setTimeout(() => {
          this.cloneData = deepCopy(this.data);
        }, 0);
        this.$emit('update-data');
      },
      deep: true
    },
    updateCol: {
      handler(v, vold) {
        if (_.isEqual(v, vold)) {
          return;
        }
        // todo 这里有性能问题，可能是左右固定计算属性影响的
        const colsWithId = this.makeColumnsId(this.columns);
        this.allColumns = getAllColumns(colsWithId);
        this.cloneColumns = this.makeColumns(colsWithId);
        this.suspendCloneColumns = this.makeSuspendColumns(colsWithId);
        this.columnRows = this.makeColumnRows(false, colsWithId);
        this.leftFixedColumnRows = this.makeColumnRows('left', colsWithId);
        this.rightFixedColumnRows = this.makeColumnRows('right', colsWithId);
        this.suspendFixedColumnRows = this.makeColumnRows('suspend', colsWithId);
        this.rebuildData = this.makeDataWithSortAndFilter();
        this.handleResize();
        this.emitUpdateColumns();
      },
      deep: true
    },
    height() {
      this.handleResize();
    },
    showHorizontalScrollBar(val) {
      this.handleResize();
      if (val) {
        this.$emit('on-horizontalScrollBar-change');
      }
    },
    showVerticalScrollBar() {
      this.handleResize();
    },
    bCheckAll(val) {
      if (val) {
        this.$emit('on-checkall');
      }
    }
  },
  created() {
    if (!this.context) this.currentContext = this.$parent;
    this.showSlotHeader = this.$slots.header !== undefined;
    this.showSlotFooter = this.$slots.footer !== undefined;
    this.rebuildData = this.makeDataWithSortAndFilter();
  },
  mounted() {
    this.handleResize();
    this.$nextTick(() => {
      this.ready = true;
      this.emitUpdateColumns();
      this.emitReady();
    });
    on(window, 'resize', this.handleResize);
    this.observer = elementResizeDetectorMaker();
    this.observer.listenTo(this.$el, this.handleResize);
    this.$on('on-visible-change', (val) => {
      if (val) {
        this.handleResize();
      }
    });
  },
  beforeDestroy() {
    off(window, 'resize', this.handleResize);
    this.observer.removeListener(this.$el, this.handleResize);
  },
  methods: {
    voucherReloadData(datas) {
      const oldDataLen = this.rebuildData.length;
      this.objData = this.makeObjData(datas);
      this.rebuildData = datas;
      if (!oldDataLen) {
        this.fixedHeader();
      }
    },
    emitUpdateColumns() {
      this.$emit('columns-ready');
    },
    emitReady() {
      this.$emit('on-ready');
    },
    rowClsName(index) {
      return this.rowClassName(this.data[index], index);
    },
    handleResize() {
      // let tableWidth = parseInt(getStyle(this.$el, 'width')) - 1;
      let tableWidth = this.$el.offsetWidth - 1;
      this.visibleTableWidth = tableWidth;
      let columnsWidth = {};
      let sumMinWidth = 0;
      let hasWidthColumns = [];
      let noWidthColumns = [];
      let maxWidthColumns = [];
      let noMaxWidthColumns = [];
      this.cloneColumns.forEach((col) => {
        if (col.width) {
          hasWidthColumns.push(col);
        } else {
          noWidthColumns.push(col);
          if (col.minWidth) {
            sumMinWidth += col.minWidth;
          }
          if (col.maxWidth) {
            maxWidthColumns.push(col);
          } else {
            noMaxWidthColumns.push(col);
          }
        }
        col._width = null;
      });
      let unUsableWidth = hasWidthColumns.map((cell) => cell.width).reduce((a, b) => a + b, 0);
      let usableWidth = tableWidth - unUsableWidth - sumMinWidth - (this.showVerticalScrollBar ? this.scrollBarWidth : 0) - 1;
      let usableLength = noWidthColumns.length;
      let columnWidth = 0;
      if (usableWidth > 0 && usableLength > 0) {
        columnWidth = parseInt(usableWidth / usableLength);
      }
      for (let i = 0; i < this.cloneColumns.length; i++) {
        const column = this.cloneColumns[i];
        let width = columnWidth + (column.minWidth ? column.minWidth : 0);
        if (column.width) {
          width = column.width;
        } else {
          if (column?.key === 'placeHolderWidthColumn') {
            width = 60;
          }
          if (column._width) {
            width = column._width;
          } else {
            if (column.minWidth > width) {
              width = column.minWidth;
            } else if (column.maxWidth < width) {
              width = column.maxWidth;
            }
            if (usableWidth > 0) {
              usableWidth -= width - (column.minWidth ? column.minWidth : 0);
              usableLength--;
              if (usableLength > 0) {
                columnWidth = parseInt(usableWidth / usableLength);
              } else {
                columnWidth = 0;
              }
            } else {
              columnWidth = 0;
            }
          }
        }
        column._width = width;
        columnsWidth[column._index] = {
          width: width
        };
      }
      if (usableWidth > 0) {
        usableLength = noMaxWidthColumns.length;
        columnWidth = parseInt(usableWidth / usableLength);
        for (let i = 0; i < noMaxWidthColumns.length; i++) {
          const column = noMaxWidthColumns[i];
          let width = column._width + columnWidth;
          if (usableLength > 1) {
            usableLength--;
            usableWidth -= columnWidth;
            columnWidth = parseInt(usableWidth / usableLength);
          } else {
            columnWidth = 0;
          }
          column._width = width;
          columnsWidth[column._index] = {
            width: width
          };
        }
      }
      this.tableWidth = this.cloneColumns.map((cell) => cell._width).reduce((a, b) => a + b, 0) + (this.showVerticalScrollBar ? this.scrollBarWidth : 0) + 1;
      this.columnsWidth = columnsWidth;
      this.fixedHeader();
    },
    // 悬浮行鼠标移入移出处理
    suspendMouse(type, _index, tableSusPendKey) {
      var suspendTable = this.$refs['fixedBodySuspend'];
      if (suspendTable && tableSusPendKey) {
        suspendTable = document.getElementsByClassName(tableSusPendKey);
      }
      if (suspendTable && suspendTable.length) {
        let suspendTableRow = suspendTable[0].getElementsByClassName('ivu-table-row');

        if (suspendTableRow && suspendTableRow.length && _index < suspendTableRow.length) {
          // 动态获取行高
          let ivuTableBody = this.$refs['refBodyWrapper'];
          // let ivuTableBody = document.getElementsByClassName('ivu-table-body');
          if (ivuTableBody) {
            let ivuTableRow = ivuTableBody.getElementsByClassName('ivu-table-row');
            if (ivuTableRow && ivuTableRow.length) {
              let height = ivuTableRow[0].offsetHeight;
              for (let k = 0; k < suspendTableRow.length; k++) {
                if (ivuTableRow.length > k) {
                  height = ivuTableRow[k].offsetHeight;
                }
                if (height) {
                  suspendTableRow[k].setAttribute('height', height + 'px');
                  suspendTableRow[k].setAttribute('style', `height:${height}px;`);
                }
              }
            }
          }
          let mergeIndexFirst = _index;
          let objData = [];
          for (let i in this.objData) {
            objData[i] = this.objData[i];
          }
          if (this.cellMerge && this.cellMerge(_index, this.objData[_index], 'action')) {
            if (this.objData[_index].parentIndex !== undefined && this.objData[_index].sIndex !== undefined) {
              let tempIndex = objData.findIndex((v) => {
                // 找3级结构 合并单元格第一个
                return this.objData[_index].parentIndex === v.parentIndex && v.sIndex === 0;
              });
              if (tempIndex > -1) {
                mergeIndexFirst = tempIndex;
              }
              // 针对组合促销
              if (this.cellMerge(_index, this.objData[_index], 'action').special) {
                tempIndex = objData.findIndex((v) => {
                  return this.objData[_index].parentIndex === v.parentIndex && v.sIndex === this.objData[_index].sIndex && v.index === 0;
                });
                if (tempIndex > -1) {
                  mergeIndexFirst = tempIndex;
                }
              }
              // 找2级结构 合并单元格第一个
            } else if (this.objData[_index].parentIndex !== undefined && this.objData[_index].sIndex === undefined) {
              let tempIndex = objData.findIndex((v) => {
                // 找3级结构 合并单元格第一个
                return this.objData[_index].parentIndex === v.parentIndex && v.index === 0;
              });
              if (tempIndex > -1) {
                mergeIndexFirst = tempIndex;
              }
            }
          }
          let suspendTableCell = suspendTableRow[mergeIndexFirst].getElementsByClassName('ivu-table-cell');
          let suspendActionWidth = 0;
          if (suspendTableCell && suspendTableCell.length) {
            for (let j = 0; j < suspendTableCell.length; j++) {
              let suspendActionSpan = suspendTableCell[j].getElementsByTagName('span');
              if (suspendActionSpan && suspendActionSpan.length) {
                for (let i = 0; i < suspendActionSpan.length; i++) {
                  suspendActionWidth = suspendActionWidth + suspendActionSpan[i].offsetWidth + 5;
                }
              }
            }
            suspendActionWidth = `${suspendActionWidth + (suspendActionWidth ? 20 : 0)}px`; // 20为距离可视区域的右边界的距离
            if (type === 'in') {
              if (this.$store.state.user.orderActionBtnsStatus) {
                suspendTable[0].setAttribute('style', `width:${suspendActionWidth};z-index:4;`);
              } else {
                suspendTable[0].setAttribute('style', `width: ${50}px;z-index:4;`);
              }
            } else {
              suspendTable[0].setAttribute('style', `width:${suspendActionWidth};z-index:-1;`);
            }
            suspendTableCell[0].setAttribute('style', `width:${suspendActionWidth};`);
          }
          if (type === 'in') {
            suspendTableRow[mergeIndexFirst].setAttribute('style', 'visibility:visible');
          } else {
            suspendTableRow[mergeIndexFirst].setAttribute('style', 'visibility:hidden');
            suspendTableCell[0].removeAttribute('style');
          }
        }
      }
    },
    handleMouseIn(_index, tableSusPendKey, index) {
      if (this.disabledHover) return;
      if (this.objData[index]._isHover) return;
      this.objData[index]._isHover = true;
      this.suspendMouse('in', index, tableSusPendKey);
      this.$emit('hover-in', index);
    },
    handleMouseOut(_index, tableSusPendKey, index) {
      if (this.disabledHover) return;
      this.objData[index]._isHover = false;
      this.suspendMouse('out', index, tableSusPendKey);
      this.$emit('hover-out', index);
    },
    // 通用处理 highlightCurrentRow 和 clearCurrentRow
    handleCurrentRow(type, _index) {
      let oldIndex = -1;
      for (let i in this.objData) {
        if (this.objData[i]._isHighlight) {
          oldIndex = parseInt(i);
          this.objData[i]._isHighlight = false;
        }
      }
      if (type === 'highlight') this.objData[_index]._isHighlight = !this.objData[_index]._isHighlight;
      const oldData = oldIndex < 0 ? null : JSON.parse(JSON.stringify(this.cloneData[oldIndex]));
      const newData = type === 'highlight' ? JSON.parse(JSON.stringify(this.cloneData[_index])) : null;
      this.$emit('on-current-change', newData, this.objData[_index]._isHighlight);
    },
    highlightCurrentRow(_index) {
      if (!this.highlightRow) return;
      this.handleCurrentRow('highlight', _index);
    },
    clearCurrentRow() {
      if (!this.highlightRow) return;
      this.handleCurrentRow('clear');
    },
    clickCurrentRow(_index) {
      this.highlightCurrentRow(_index);
      this.$emit('on-row-click', this.cloneData[_index], _index);
    },
    dblclickCurrentRow(_index) {
      this.highlightCurrentRow(_index);
      this.$emit('on-row-dblclick', this.cloneData[_index], _index);
    },
    getSelection() {
      let selectionIndexes = [];
      for (let i in this.objData) {
        if (this.objData[i]._isChecked) selectionIndexes.push(parseInt(i));
      }
      return JSON.parse(JSON.stringify(this.data.filter((data, index) => selectionIndexes.indexOf(index) > -1)));
    },
    toggleSelect(_index) {
      let data = {};
      for (let i in this.objData) {
        if (parseInt(i) === _index) {
          data = this.objData[i];
          break;
        }
      }
      const status = !data._isChecked;
      this.objData[_index]._isChecked = status;
      const selection = this.getSelection();
      if (Array.isArray(selection) && selection.length > 0 && !this.isSelectAll()) {
        this.indeterminate = true;
      } else {
        this.indeterminate = false;
      }
      this.$emit(status ? 'on-select' : 'on-select-cancel', selection, JSON.parse(JSON.stringify(this.data[_index])));
      this.$emit('on-select-all', selection, this.isSelectAll());
      this.$emit('on-selection-change', selection, [JSON.parse(JSON.stringify(this.data[_index]))], status);
    },
    toggleExpand(_index) {
      let data = {};
      for (let i in this.objData) {
        if (parseInt(i) === _index) {
          data = this.objData[i];
          break;
        }
      }
      const status = !data._isExpanded;
      this.objData[_index]._isExpanded = status;
      this.$emit('on-expand', JSON.parse(JSON.stringify(this.cloneData[_index])), status);
    },
    toggleExpandTree(_index) {
      let data = {};
      for (let i in this.objData) {
        if (parseInt(i) === _index) {
          data = this.objData[i];
        }
      }
      const status = !data._isExpandedTree;
      this.objData[_index]._isExpandedTree = status;
      this.$emit('on-expand-tree', JSON.parse(JSON.stringify(this.cloneData[_index])), status);
    },
    selectAll(status) {
      // this.rebuildData.forEach((data) => {
      //     if(this.objData[data._index]._isDisabled){
      //         this.objData[data._index]._isChecked = false;
      //     }else{
      //         this.objData[data._index]._isChecked = status;
      //     }
      // });
      this.indeterminate = false;
      for (const data of this.rebuildData) {
        if (this.objData[data._index]._isDisabled) {
          continue;
        } else {
          this.objData[data._index]._isChecked = status;
        }
      }
      const selection = this.getSelection();
      this.$emit('on-select-all', selection, status);
      this.$emit('on-selection-change', selection, this.data, status);
    },
    isSelectAll() {
      let isSelectAll = true;
      if (!this.rebuildData.length) isSelectAll = false;
      if (!this.rebuildData.find((item) => !item._disabled)) isSelectAll = false; // #1751
      for (let i = 0; i < this.rebuildData.length; i++) {
        const data = this.objData[this.rebuildData[i]._index];
        if (!data._isChecked && !data._isDisabled && !data._isSum && !data._isPageSum) {
          isSelectAll = false;
          break;
        }
      }
      this.bCheckAll = isSelectAll;
      return isSelectAll;
    },
    fixedHeader() {
      if (this.height) {
        this.$nextTick(() => {
          const titleHeight = parseInt(getStyle(this.$refs.title, 'height')) || 0;
          const headerHeight = parseInt(getStyle(this.$refs.header, 'height')) || 0;
          // 不需要留出footer分页
          if (this.notNeedTableFooter) {
            this.bodyHeight = this.height - titleHeight - headerHeight;
          } else {
            const footerHeight = parseInt(getStyle(this.$refs.footer, 'height')) || 0;
            this.bodyHeight = this.height - titleHeight - headerHeight - footerHeight;
          }
          // const footerHeight = parseInt(getStyle(this.$refs.footer, 'height')) || 0;
          // this.bodyHeight = this.height - titleHeight - headerHeight;
          this.$nextTick(() => this.fixedBody());
        });
      } else {
        this.bodyHeight = 0;
        this.$nextTick(() => this.fixedBody());
      }
    },
    fixedBody() {
      if (this.$refs.header) {
        this.headerWidth = this.$refs.header.children[0].offsetWidth;
        this.headerHeight = this.$refs.header.children[0].offsetHeight;
        // this.showHorizontalScrollBar = this.headerWidth>this.$refs.header.offsetWidth;
      }
      if (!this.$refs.tbody || !this.rebuildData || this.rebuildData.length === 0) {
        this.showVerticalScrollBar = false;
      } else {
        let bodyContentEl = this.$refs.tbody.$el;
        let bodyEl = bodyContentEl.parentElement;
        let bodyContentHeight = bodyContentEl.offsetHeight;
        let bodyHeight = bodyEl.offsetHeight;
        this.showHorizontalScrollBar = bodyEl.offsetWidth < bodyContentEl.offsetWidth + (this.showVerticalScrollBar ? this.scrollBarWidth : 0);
        this.showVerticalScrollBar = this.bodyHeight ? bodyHeight - (this.showHorizontalScrollBar ? this.scrollBarWidth : 0) < bodyContentHeight : false;
        if (this.showVerticalScrollBar) {
          bodyEl.classList.add(this.prefixCls + '-overflowY');
        } else {
          bodyEl.classList.remove(this.prefixCls + '-overflowY');
        }
        if (this.showHorizontalScrollBar) {
          bodyEl.classList.add(this.prefixCls + '-overflowX');
        } else {
          bodyEl.classList.remove(this.prefixCls + '-overflowX');
        }
      }
    },
    hideColumnFilter() {
      this.cloneColumns.forEach((col) => (col._filterVisible = false));
    },
    handleBodyScroll(event) {
      if (event.target.scrollLeft) {
        this.scrollLeft = true;
      } else {
        this.scrollLeft = false;
      }
      if (this.showHeader) this.$refs.header.scrollLeft = event.target.scrollLeft;
      if (this.isLeftFixed && this.$refs.refLeftfixedBody) this.$refs.refLeftfixedWrapper.scrollTop = event.target.scrollTop;
      if (this.isRightFixed && this.$refs.fixedRightBody) this.$refs.fixedRightBody.scrollTop = event.target.scrollTop;
      // 悬浮
      if (this.isSuspend && this.$refs.fixedSuspendBody) this.$refs.fixedSuspendBody.scrollTop = event.target.scrollTop;
      this.hideColumnFilter();
    },
    handleFixedMousewheel(event) {
      let deltaY = event.deltaY;
      if (!deltaY && event.detail) {
        deltaY = event.detail * 40;
      }
      if (!deltaY && event.wheelDeltaY) {
        deltaY = -event.wheelDeltaY;
      }
      if (!deltaY && event.wheelDelta) {
        deltaY = -event.wheelDelta;
      }
      if (!deltaY) return;
      const body = this.$refs.refBodyWrapper;
      const currentScrollTop = body.scrollTop;
      if (deltaY < 0 && currentScrollTop !== 0) {
        event.preventDefault();
      }
      if (deltaY > 0 && body.scrollHeight - body.clientHeight > currentScrollTop) {
        event.preventDefault();
      }
      // body.scrollTop += deltaY;
      let step = 0;
      let timeId = setInterval(() => {
        step += 5;
        if (deltaY > 0) {
          body.scrollTop += 2;
        } else {
          body.scrollTop -= 2;
        }
        if (step >= Math.abs(deltaY)) {
          clearInterval(timeId);
        }
      }, 5);
    },
    handleMouseWheel(event) {
      const deltaX = event.deltaX;
      const $body = this.$refs.refBodyWrapper;
      if (deltaX > 0) {
        $body.scrollLeft = $body.scrollLeft + 10;
      } else {
        $body.scrollLeft = $body.scrollLeft - 10;
      }
    },
    sortData(data, type, index) {
      const key = this.cloneColumns[index].key;
      data.sort((a, b) => {
        if (this.cloneColumns[index].sortMethod) {
          return this.cloneColumns[index].sortMethod(a[key], b[key], type);
        } else {
          if (type === 'asc') {
            return a[key] > b[key] ? 1 : -1;
          } else if (type === 'desc') {
            return a[key] < b[key] ? 1 : -1;
          }
        }
      });
      return data;
    },
    handleSort(_index, type) {
      const index = this.GetOriginalIndex(_index);
      this.cloneColumns.forEach((col) => (col._sortType = 'normal'));
      const key = this.cloneColumns[index].key;
      if (this.cloneColumns[index].sortable !== 'custom') {
        // custom is for remote sort
        if (type === 'normal') {
          this.rebuildData = this.makeDataWithFilter();
        } else {
          this.rebuildData = this.sortData(this.rebuildData, type, index);
        }
      }
      this.cloneColumns[index]._sortType = type;
      this.$emit('on-sort-change', {
        column: JSON.parse(JSON.stringify(this.allColumns[this.cloneColumns[index]._index])),
        key: key,
        order: type
      });
    },
    handleFilterHide(index) {
      // clear checked that not filter now
      if (!this.cloneColumns[index]._isFiltered) this.cloneColumns[index]._filterChecked = [];
    },
    filterData(data, column) {
      return data.filter((row) => {
        // 如果定义了远程过滤方法则忽略此方法
        if (typeof column.filterRemote === 'function') return true;
        let status = !column._filterChecked.length;
        for (let i = 0; i < column._filterChecked.length; i++) {
          status = column.filterMethod(column._filterChecked[i], row);
          if (status) break;
        }
        return status;
      });
    },
    filterOtherData(data, index) {
      let column = this.cloneColumns[index];
      if (typeof column.filterRemote === 'function') {
        column.filterRemote.call(this.$parent, column._filterChecked, column.key, column);
      }
      this.cloneColumns.forEach((col, colIndex) => {
        if (colIndex !== index) {
          data = this.filterData(data, col);
        }
      });
      return data;
    },
    handleFilter(index) {
      const column = this.cloneColumns[index];
      let filterData = this.makeDataWithSort();
      // filter others first, after filter this column
      filterData = this.filterOtherData(filterData, index);
      this.rebuildData = this.filterData(filterData, column);
      this.cloneColumns[index]._isFiltered = true;
      this.cloneColumns[index]._filterVisible = false;
      this.$emit('on-filter-change', column);
    },
    /**
     * #2832
     * 应该区分当前表头的 column 是左固定还是右固定
     * 否则执行到 $parent 时，方法的 index 与 cloneColumns 的 index 是不对应的
     * 左固定和右固定，要区分对待
     * 所以，此方法用来获取正确的 index
     * */
    GetOriginalIndex(_index) {
      return this.cloneColumns.findIndex((item) => item._index === _index);
    },
    handleFilterSelect(_index, value) {
      const index = this.GetOriginalIndex(_index);
      this.cloneColumns[index]._filterChecked = [value];
      this.handleFilter(index);
    },
    handleFilterReset(_index) {
      const index = this.GetOriginalIndex(_index);
      this.cloneColumns[index]._isFiltered = false;
      this.cloneColumns[index]._filterVisible = false;
      this.cloneColumns[index]._filterChecked = [];
      let filterData = this.makeDataWithSort();
      filterData = this.filterOtherData(filterData, index);
      this.rebuildData = filterData;
      this.$emit('on-filter-change', this.cloneColumns[index]);
    },
    makeData() {
      let rowKey = 1;
      let data = deepCopy(this.data, true);
      data.forEach((row, index) => {
        row._index = index;
        if (row._rowKey === undefined) {
          row._rowKey = rowKey++;
        }
      });
      return data;
    },
    makeDataWithSort() {
      let data = this.makeData();
      let sortType = 'normal';
      let sortIndex = -1;
      let isCustom = false;
      for (let i = 0; i < this.cloneColumns.length; i++) {
        if (this.cloneColumns[i]._sortType !== 'normal') {
          sortType = this.cloneColumns[i]._sortType;
          sortIndex = i;
          isCustom = this.cloneColumns[i].sortable === 'custom';
          break;
        }
      }
      if (sortType !== 'normal' && !isCustom) data = this.sortData(data, sortType, sortIndex);
      return data;
    },
    makeDataWithFilter() {
      let data = this.makeData();
      this.cloneColumns.forEach((col) => (data = this.filterData(data, col)));
      return data;
    },
    makeDataWithSortAndFilter() {
      let data = this.makeDataWithSort();
      this.cloneColumns.forEach((col) => (data = this.filterData(data, col)));
      return data;
    },
    makeObjData(datas = this.data) {
      let data = {};
      datas.forEach((row, index) => {
        const newRow = deepCopy(row); // todo 直接替换
        newRow._isHover = false;
        if (newRow._disabled) {
          newRow._isDisabled = newRow._disabled;
        } else {
          newRow._isDisabled = false;
        }
        if (newRow._checked) {
          newRow._isChecked = newRow._checked;
        } else {
          newRow._isChecked = false;
        }
        if (newRow._expanded) {
          newRow._isExpanded = newRow._expanded;
        } else {
          newRow._isExpanded = false;
        }
        if (newRow._expandedTree) {
          newRow._isExpandedTree = newRow._expandedTree;
        } else {
          newRow._isExpandedTree = false;
        }
        if (newRow._renderTr) {
          newRow.hasRenderTr = newRow._renderTr;
        } else {
          newRow.hasRenderTr = false;
        }
        if (newRow._highlight) {
          newRow._isHighlight = newRow._highlight;
        } else {
          newRow._isHighlight = false;
        }
        data[index] = newRow;
      });
      return data;
    },
    // 修改列，设置一个隐藏的 id，便于后面的多级表头寻找对应的列，否则找不到
    makeColumnsId(columns) {
      return columns.map((item) => {
        if ('children' in item) item.children = this.makeColumnsId(item.children);
        if (item.fieldName) item.key = item.fieldName;
        if (item.showCaption && !item.title) {
          item.title = item.showCaption;
        }
        if (!item.__id) {
          item.__id = getRandomStr(6);
        }
        item.isFixed = !!item.fixed;
        return item;
      });
    },
    makeColumns(cols) {
      // 在 data 时，this.allColumns 暂时为 undefined
      let columns = deepCopy(getAllColumns(cols));
      let left = [];
      let right = [];
      let center = [];
      columns.forEach((column, index) => {
        column._index = index;
        column._columnKey = columnKey++;
        column._width = column.width ? column.width : ''; // update in handleResize()
        column._sortType = 'normal';
        column._filterVisible = false;
        column._isFiltered = false;
        column._filterChecked = [];
        if ('filterMultiple' in column) {
          column._filterMultiple = column.filterMultiple;
        } else {
          column._filterMultiple = true;
        }
        if ('filteredValue' in column) {
          column._filterChecked = column.filteredValue;
          column._isFiltered = true;
        }
        if ('sortType' in column) {
          column._sortType = column.sortType;
        }
        if (column.fixed && column.fixed === 'suspend') {
        } else {
          if (column.fixed && column.fixed === 'left') {
            left.push(column);
          } else if (column.fixed && column.fixed === 'right') {
            right.push(column);
          } else {
            center.push(column);
          }
        }
      });
      return left.concat(center).concat(right);
    },
    // 悬浮按钮render处理
    makeSuspendColumnsRender(column) {
      let _this = this;
      function render(h, params) {
        let btns = [];
        column.btns.forEach(function(btnItem) {
          let isShow = true;
          if (btnItem.conditionKey) {
            if (!params.row[btnItem.conditionKey]) {
              isShow = false;
            }
          }
          if (isShow) {
            btns.push(
              h(
                'span',
                {
                  class: {
                    'action-btn': true
                  },
                  style: {
                    cursor: 'pointer'
                  },
                  on: {
                    click: () => {
                      if (btnItem.click) {
                        btnItem.click(params);
                      }
                    }
                  }
                },
                btnItem.name
              )
            );
          }
        });
        return h('div', btns);
      }
      column.render = render;
    },
    // 悬浮操作按钮列处理
    makeSuspendColumns(cols) {
      // 在 data 时，this.allColumns 暂时为 undefined
      let columns = deepCopy(getAllColumns(cols));
      let center = [];
      columns.forEach((column, index) => {
        column._index = index;
        column._columnKey = columnKey++;
        column._width = column.width ? column.width : ''; // update in handleResize()
        column._sortType = 'normal';
        column._filterVisible = false;
        column._isFiltered = false;
        column._filterChecked = [];
        if ('filterMultiple' in column) {
          column._filterMultiple = column.filterMultiple;
        } else {
          column._filterMultiple = true;
        }
        if ('filteredValue' in column) {
          column._filterChecked = column.filteredValue;
          column._isFiltered = true;
        }
        if ('sortType' in column) {
          column._sortType = column.sortType;
        }
        if (column.fixed && column.fixed === 'suspend') {
          // 悬浮按钮render
          if (column.btns && column.btns.length) {
            this.makeSuspendColumnsRender(column);
          }
          center.push(column);
        }
      });
      return center;
    },
    // create a multiple table-head
    makeColumnRows(fixedType, cols) {
      return convertToRows(cols, fixedType);
    },
    exportCsv(params) {
      if (params.filename) {
        if (params.filename.indexOf('.csv') === -1) {
          params.filename += '.csv';
        }
      } else {
        params.filename = 'table.csv';
      }
      let columns = [];
      let datas = [];
      if (params.columns && params.data) {
        columns = params.columns;
        datas = params.data;
      } else {
        columns = this.allColumns;
        if (!('original' in params)) params.original = true;
        datas = params.original ? this.data : this.rebuildData;
      }
      let noHeader = false;
      if ('noHeader' in params) noHeader = params.noHeader;
      const data = Csv(columns, datas, params, noHeader);
      if (params.callback) params.callback(data);
      else ExportCsv.download(params.filename, data);
    }
  }
};
</script>
