import accessorieSetting from './accessorieSetting';
import combinationSales from './combinationSales';
import bizCombinationSales from './bizCombinationSales';
import productSalesListShow from './productSalesListShow';
import bizProductSalesListShow from './bizProductSalesListShow';
import singleDiscountListShow from './singleDiscountListShow';
import bizSingleDiscountListShow from './bizSingleDiscountListShow';
import productLimit from './productLimit';
import productPresales from './productPresales';
import productPresale from './productPresale';
import productPromotion from './productPromotion';
import combineSetting from './combineSetting';
import singleDiscountShow from './singleDiscountShow';
import productLimitDetail from './productLimitDetail';
import productGroupBuy from './productGroupBuy';
import productGroupBuys from './productGroupBuys';
import accumulationPromotions from './accumulationPromotions';
import accumulationPromotion from './accumulationPromotion';

export default {
  combinationSales,
  bizCombinationSales,
  bizProductSalesListShow,
  productSalesListShow,
  singleDiscountListShow,
  bizSingleDiscountListShow,
  accessorieSetting,
  productLimit,
  productPresales,
  productPresale,
  productLimitDetail,
  productPromotion,
  combineSetting,
  singleDiscountShow,
  productGroupBuy,
  productGroupBuys,
  accumulationPromotions,
  accumulationPromotion
};
