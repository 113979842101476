<style lang="less" scoped>
@import './activation-accounts.less';
</style>
<template>
  <div class="activation">
    <div
      v-if="currentUser.licenseLevel == 'FREE'"
      class="header-inline-center num-action"
      style="float:right !important">
      <span v-if="currentUser.licenseLevel == 'FREE'" class="active-txt-top ng-scope">
        {{ mlang('IsActivation') }}
        <a class="btn-active" @click="showActive(1)">{{ mlang('Activation') }}</a>
      </span>
    </div>
    <!--遮罩层-->
    <div v-if="activeMode != 0" class="active-mask-bg"></div>
    <!--超级管理员激活-->
    <div v-if="activeMode == 1" class="active-content active-sec-1">
      <div class="active-main">
        <div class="icon-active-top"></div>
        <a class="icon-active-close" @click="setActiveMode(0)"></a>
        <ul>
          <li>
            <input
              v-model="activeModel.activationCode"
              :placeholder="mlang('pleaseEnterActCode')"
              type="text">
            <i
              class="icon-active-info"
              @mouseleave="baseShowTip = false"
              @mouseenter="baseShowTip = true"></i>
            <div
              v-if="baseShowTip"
              class="active-info-txt">
              <div class="active-info-arrow"></div>
              <div class="active-txt">
                <h6>{{ mlang('hasExistActCode') }}</h6>
                <p>{{ mlang(pleaseGoYunMarket) }}“<em>{{ mlang('myOrder') }}</em>”{{ mlang('zhongchaxunjihuoma') }}</p>
                <h6>{{ mlang('nojihuoma') }}</h6>
                <p>{{ mlang('qingdaoyunshichanghuoyunshangwuzhong') }}<em>{{ mlang('goumaichanpin') }}</em>{{ mlang('jikehuoqujihuoma') }}</p>
              </div>
            </div>
          </li>
          <p v-if="error == 100">
            <i class="icon-active-error"></i>{{ errormsg }}
          </p>
          <li>
            <input
              v-model="activeModel.checkCode"
              :placeholder="mlang('qingshuruyanzhengma')"
              type="text"><span><img
                id="cVerifyActive"
                :src="activeImgUrl"
                @click="changeVerifyCode()"></span>
          </li>
          <p v-if="error == 402">
            <i class="icon-active-error"></i>{{ errormsg }}
          </p>
        </ul>
        <a
          class="btn-active-lg"
          @click="goActive()">{{ mlang('jihuo') }}</a>
      </div>
    </div>
    <!--普通管理员激活-->
    <div v-if="activeMode == 2" class="active-content active-sec-2">
      <div class="active-main">
        <div class="icon-active-top"></div>
        <a
          class="icon-active-close"
          @click="setActiveMode(0)"></a>
        <h2>{{ mlang('zhiyouguanliyuancaijihuo') }}</h2>
        <div class="btn-active-group">
          <a
            class="btn-active-lg"
            @click="logoutU()">{{ mlang('qiehuanzhanghao') }}</a>
          <a
            class="btn-default-lg"
            @click="setActiveMode(0)">{{ mlang('xiacizaishuo') }}</a>
        </div>
      </div>
    </div>
    <!--激活失败-->
    <div v-if="error == 200" class="active-content active-sec-3">
      <div class="active-main">
        <div class="icon-active-top"></div>
        <a
          class="icon-active-close"
          @click="setActiveMode(0)"></a>
        <h2><em>{{ mlang('aiyajihuomacuowu') }}</em></h2>
        <div class="btn-active-group">
          <a
            class="btn-active-lg"
            @click="setActiveMode(0)">{{ mlang('chongxinjihuo') }}</a>
          <a
            class="btn-default-lg"
            @click="setActiveMode(0)">{{ mlang('xiacizaishuo') }}</a>
        </div>
      </div>
    </div>
    <!--激活成功-->
    <div v-if="activeMode == 4" class="active-content active-sec-4">
      <div class="active-main">
        <div class="icon-active-top"></div>
        <a
          class="icon-active-close"
          @click="setActiveMode(0)"></a>
        <h3><i class="icon-active-ok"></i>{{ mlang('gongxijihuochenggong') }}</h3>
      </div>
    </div>
    <!--已激活刷新页面-->
    <div v-if="activeMode == 5" class="active-content active-sec-5">
      <div class="active-main">
        <div class="icon-active-top"></div>
        <a
          class="icon-active-close"
          @click="setActiveMode(0)"></a>
        <h2><em>{{ mlang('aiyayijingjihuo') }}<br>{{ mlang('qingshuaxinyemian') }}~~</em></h2>
        <div class="btn-active-group">
          <a class="btn-active-circle">{{ mlang('zhidaola') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activeAction } from '@/api/setting/corprations';
import activation from '@/mixin/common.js';
export default {
  name: 'MsgPush',
  mixins: [activation],
  data() {
    return {
      activeImgUrl: `/file/captcha/image/show/${this.getDeviceUUid()}/activeaction?isArchive=1&r=${Math.random()}`,
      activeMode: 0,
      activeModel: {
        activationCodeL: null,
        checkCode: null
      },
      baseShowTip: false,
      error: 0,
      errormsg: null
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {
    changeVerifyCode() {
      let timestamp = Date.parse(new Date());
      this.activeImgUrl = this.activeImgUrl + '&timestamp=' + timestamp;
    },
    // 激活
    showActive(a) {
      this.activeMode = a;
      let mode = 1;
      if (this.$store.getters.currentUser.userType !== 1) {
        mode = 2;
      }
      this.setActiveMode(mode);
    },
    // 关闭/或者切换激活页面的显示
    setActiveMode(v) {
      this.activeMode = v;
      this.error = 0;
    },
    // 激活
    goActive() {
      let activationCode = this.activeModel.activationCode;
      let checkCode = this.activeModel.checkCode;
      let postData = {
        devicedUUid: this.getDeviceUUid(),
        activationCode: activationCode,
        checkCode: checkCode
      };
      activeAction(postData).then((data) => {
        if (data.success) {
          this.selectedAgent(4);
        } else {
          this.error = data.status;
          this.errormsg = data.msg;
        }
      });
    },
    // 切换账户
    logoutU() {
      window.location.href = '/logout';
    }
  }
};
</script>
