import accountParentView from '@/views/agent/home/components/accountHome.vue';

const users = {
  path: 'mutiAgentsList',
  name: 'mutiAgentsList',
  component: accountParentView,
  redirect: { name: 'agentsList' },
  meta: { module: 'mutiAgentsList' },
  children: [
    {
      path: 'agentsList',
      name: 'agentsList',
      meta: { module: 'mutiAgentsList' },
      component: () => import('@/views/agent/accounts/components/user/users.vue')
    },
    {
      path: 'mutiAgentsBaseInfo',
      name: 'mutiAgentsBaseInfo',
      meta: { module: 'mutiAgentsList' },
      component: () => import('@/views/agent/accounts/components/user/user.vue')
    },
    {
      path: 'mutiAgentsContacts',
      name: 'mutiAgentsContacts',
      meta: { module: 'mutiAgentsList' },
      component: () => import('@/views/agent/accounts/components/user/contacts.vue')
    },
    {
      path: 'mutiAgentsAddrs',
      name: 'mutiAgentsAddrs',
      meta: { module: 'mutiAgentsList' },
      component: () => import('@/views/agent/accounts/components/user/addrs.vue')
    },
    {
      path: 'mutiAgentsBankAccounts',
      name: 'mutiAgentsBankAccounts',
      meta: { module: 'mutiAgentsList' },
      component: () => import('@/views/agent/accounts/components/user/bankAccounts.vue')
    },
    {
      path: 'mutiAgentsBankInvoices',
      name: 'mutiAgentsBankInvoices',
      meta: { module: 'mutiAgentsList' },
      component: () => import('@/views/agent/accounts/components/user/bankInvoices.vue')
    }

  ]
};
export default users;
