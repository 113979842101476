<template>
  <div class="order-status">
    <Enum
      :params="params"
      :column="column">
    </Enum>
    <Logistic
      v-if="params.row.cSaleReturnStatus=='SALERETURNING'"
      :datas="datas"
      :title="mlang('SaleReturnNo')"
      :has-fetch="hasFetch"
      @on-popper-show="onPopShow">
    </Logistic>
  </div>
</template>

<script>
import Logistic from '@/components/common/logistic/logistic';
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import { fetchSaleReturnLogisticInfo } from '@/api/salereturn';
export default {
  name: 'ReturnStatus',
  components: {
    Logistic
  },
  mixins: [PopTipMixin, CellMixin],
  data() {
    return {
      datas: [],
      hasFetch: false
    };
  },
  methods: {
    onPopShow() {
      if (!this.hasFetch) {
        fetchSaleReturnLogisticInfo(this.params.row.cSaleReturnNo).then((res) => {
          this.datas = res;
          // this.hasFetch = true;
        });
      }
    }
  }
};
</script>
