<template>
  <div>
    <div
      class="go-order-container">
      <div
        class="go-order-client">
        <span
          v-if="!currentUser.__isAgentSide">{{ mlang('switchText') }}</span>
        <p
          v-else
          class="agent-relation">
          <Icon class="iconfont icon-daikexiadan" title=" " />
          <span>{{ upAgentRelationName }}</span>
        </p>
        <!-- <SvgIcon icon-class="switch-agent"></SvgIcon> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getAgentInfoVo } from '@/api/user.js';
import { mapGetters } from 'vuex';

export default {
  name: 'GenerationOrder',
  inject: ['showSelectAgent'],
  components: {
  },
  data() {
    return {
      selectAgentMutiple: false,
      upAgentRelationName: ''
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  created() {
    this.getAgentInfoVo();
  },
  methods: {
    selectAgent() {
      this.showSelectAgent();
    },
    getAgentInfoVo() {
      if (localStorage.upAgentRelationId) {
        getAgentInfoVo(localStorage.upAgentRelationId).then((res) => {
          this.upAgentRelationName = res && res.name;
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
  // .go-order-container {
  //   float: right;
  //   height: 60px;
  //   margin-left: 24px;
  //   .go-order-client {
  //     margin-top: 14px;
  //     margin-left: 5px;
  //     width: 32px;
  //     height: 32px;
  //     border-radius: 50%;
  //     line-height: 32px;
  //     text-align: center;
  //     background: transparent;
  //     cursor: pointer;

  //   }
  // }
</style>
