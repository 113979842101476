<template>
  <div class="order-no">
    <Link
      :params="params"
      :column="column">
    </Link>
    <div :class="classObject">
      <Icon
        v-if="params.row.isAgentSubmit === false"
        class="icon-style"
        :title="mlang('daikexiadan')"
        custom="iconfont icon-daikexiadan"></Icon>

      <Icon
        v-if="params.row.cSource === 1 && bizMeta && (bizMeta.billId == 3 || bizMeta.billId == 173 || bizMeta.billId == 207)"
        class="icon-style"
        :title="mlang('shoujixiadan')"
        custom="iconfont icon-shoujixiadan"></Icon>
      <!-- 预售 -->
      <!-- <Icon
        v-if="params.row.presaleId && bizMeta && (bizMeta.billId == 207)"
        :title="mlang('yushoudingdan')"
        custom="iconfont yushou"></Icon> -->
      <div v-if="params.row.presaleId && bizMeta && (bizMeta.billId == 207)" class="presaleIcon">
        {{ mlang('presaleStatus') }}
      </div>
      <div v-if="params.row.promotionType === 'SECKILL' && bizMeta && (bizMeta.billId == 207)" class="presaleIcon">
        {{ mlang('secKill') }}
      </div>
      <div v-if="params.row.promotionType === 'GROUPBUY' && bizMeta && (bizMeta.billId == 207)" class="presaleIcon">
        {{ mlang('groupBuy') }}
      </div>
      <Icon
        v-if="params.row.bLockIn && !currentUser.__isAgentSide"
        :title="mlang('lock')"
        class="icon-style"
        custom="iconfont icon-suoding"></Icon>
      <order-memo
        v-if="params.row.isHaveMemo"
        :column="column"
        :params="params"></order-memo>
      <order-u8
        v-if="!currentUser.__isAgentSide"
        :column="column"
        :params="params">
      </order-u8>
    </div>
    <p v-if="bizMeta && bizMeta.billId == 207" class="text-gray">
      {{ isYs ? $util.formatToCtxDateTime(params.row.dOrderDate).substring(0,$util.formatToCtxDateTime(params.row.dOrderDate).indexOf(" ")) : $util.formatToCtxDateTime(params.row.dOrderDate) }}
    </p>
  </div>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'BillNo',
  mixins: [PopTipMixin, CellMixin],
  data: function() {
    return {
      currentUser: this.$store.getters.currentUser,
      isYs: window.__app.context.productLine
    };
  },
  computed: {
    classObject() {
      let _row = this.params.row;
      if (_row.isAgentSubmit === false || _row.bLockIn || _row.isHaveMemo || _row.cSource === 1 || _row.cDownloadSource || _row.downloadSource) {
        return { activeStyle: true };
      } else {
        return { activeStyle: false };
      }
    }
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.activeStyle {
  height: 20px;
}
.presaleIcon{
  display: inline-block;
  font-size: 5px;
  color: #ed7e61;
  height: 14px;
  line-height: 12px;
  padding: 0 2px;
  border: 1px solid rgba(238,34,35,1);
  border-bottom-right-radius: 4px;
  border-top-left-radius: 2px;
}
.icon-style {
  height: 100%;
  font-size: 14px;
  margin-bottom: 10px;
}
.order-no{
  padding: 2px 0;
}

</style>
