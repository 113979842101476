export default {
  defaultAuth: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000359","默认权限") /* "默认权限" */,
  detail: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500035A","详情") /* "详情" */,
  toExamine: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500035B","审核") /* "审核" */,
  modify: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500035C","修改") /* "修改" */,
  discardedTrial: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500035D","弃审") /* "弃审" */,
  confirmExpenseContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500035E","是否确认费用单？") /* "是否确认费用单？" */,
  confirmExpenseSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500035F","审核通过！") /* "审核通过！" */,
  backConfirmExpenseContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000360","是否对费用单") /* "是否对费用单" */,
  backConfirmExpenseContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000361","进行弃审？") /* "进行弃审？" */,
  backConfirmExpenseSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000362","弃审成功!") /* "弃审成功!" */,
  delExpenseContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000363","确定删除费用单") /* "确定删除费用单" */,
  delExpenseContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000364","吗？") /* "吗？" */,
  delExpenseSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000365","删除成功!") /* "删除成功!" */,
  // 驳回oppose
  oTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000366","请填写驳回意见,当前费用单将被自动关闭!") /* "请填写驳回意见,当前费用单将被自动关闭!" */,
  oTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000367","驳回原因") /* "驳回原因" */,
  oRejectReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000368","请填写驳回原因") /* "请填写驳回原因" */,
  opposeSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000369","驳回成功") /* "驳回成功" */,
  // 批量审核
  batchSureWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500036A","请选择至少一条状态为费用单待审的费用单") /* "请选择至少一条状态为费用单待审的费用单" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500036B","请选择费用单") /* "请选择费用单" */,
  batchSureContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500036C","是否要对选中的") /* "是否要对选中的" */,
  batchSureContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500036D","张费用单中可能存在商品库存不足情况，如果继续审核则库存不足的订单行也会确认费用单。是否继续审核费用单？") /* "张费用单中可能存在商品库存不足情况，如果继续审核则库存不足的订单行也会确认费用单。是否继续审核费用单？" */
};
