import { getPopularBanks, getAllBanks, getPopularAndAllBanks } from '@/api/refer-select.js';
const bank = {
  state: {
    //
    popularBanks: [],
    allBanks: []
  },
  getters: {
    popularBanks: state => state.popularBanks,
    allBanks: state => state.allBanks,
    popularAndAllBanks: state => [state.popularBanks, state.allBanks]
  },
  mutations: {
    //
    SET_POPULARBANKS: (state, popularBanks) => {
      state.popularBanks = popularBanks;
    },
    SET_ALLBANKS: (state, allBanks) => {
      state.allBanks = allBanks;
    }
  },
  actions: {
    GetPopularBanks: async({ state, commit }) => {
      // 这里是为了防止重复获取
      if (state.popularBanks.length) return;
      const list = await getPopularBanks().catch(e => false);
      if (list) commit('SET_POPULARBANKS', list);
    },
    GetAllBanks: async({ state, commit }) => {
      // 这里是为了防止重复获取
      if (state.allBanks.length) return;
      const list = await getAllBanks().catch(e => false);
      if (list) commit('SET_ALLBANKS', list);
    },
    GetPopularAndAllBanks: async({ state, commit }) => {
      // 这里是为了防止重复获取
      if (state.popularBanks.length && state.allBanks.length) return;
      let [popularBanks, allBanks] = await getPopularAndAllBanks().catch(e => false);
      if (popularBanks) commit('SET_POPULARBANKS', popularBanks);
      if (allBanks) commit('SET_ALLBANKS', allBanks);
    }
  }
};

export default bank;
