export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C0","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C1","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C2","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C3","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C4","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C5","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C6","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C7","审核") /* "审核" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C8","弃审") /* "弃审" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002C9","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002CA","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002CB","查看执行情况") /* "查看执行情况" */
};
