<template>
  <div class="router-all-box">
    <ul
      id="router-menu-box"
      class="router-menu-box">
      <template v-for="(item,index) in menuList">
        <template v-if="item.children && item.children.length">
          <Poptip
            :ref="`menu-poptip-${item.sysCode}`"
            v-model="item.state"
            :options="popTipProps"
            :placement="''+index>2?((index+4)>menuList.length?rightEnd:right):rightStart"
            trigger="hover"
            width="400">
            <li
              :class="isActiveNameMain(item) ? 'router-item active' : 'router-item'">
              <a
                class="a-link"
                @click.prevent="menuClick(item)">
                <i :class="item.icon"></i>
                <span>{{ item.caption | substr(4) | ellipsis(item.caption,8) }}</span>
              </a>
            </li>
            <div
              v-if="item.children && item.children.length > 0"
              slot="content"
              class="api">
              <div class="router-item-children-box">
                <ul>
                  <template v-for="child in item.children">
                    <li
                      v-if="child.children && child.children.length > 0"
                      :key="child.id"
                      class="hasChild">
                      <span class="text-span">{{ child.caption }}</span>
                      <ul>
                        <li
                          v-for="childitem in child.children"
                          :key="childitem.id"
                          @click.prevent="menuClick(childitem,item)">
                          <a>
                            <!--span :class="{active:$route.fullPath.indexOf(childitem.name)>-1}">{{ childitem.caption }}</span-->
                            <span :class="{active:isActiveName(childitem)}">{{ childitem.caption }}</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li
                      v-else
                      :key="child.id"
                      class="noChild"
                      @click.prevent="menuClick(child,item)">
                      <a>
                        <span :class="{active:isActiveName(child)}">{{ child.caption }}</span>
                      </a>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </Poptip>
        </template>
        <template v-else>
          <li
            :key="item.id"
            :class="{active:isActiveName(item)}"
            class="router-item">
            <a
              class="a-link"
              @click.prevent="menuClick(item)">
              <i :class="item.icon"></i>
              <span>{{ item.caption | substr(4) }}</span>
            </a>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import yiguan from '@/libs/yiguan.js';
export default {
  name: 'CorpMenu',
  props: {
    menuList: { type: Array, default: [] },
    iconSize: { type: Number, default: 16 },
    accordion: {
      type: Boolean,
      default: true
    },
    menuTheme: {
      type: String,
      default: 'light'
    },
    openNames: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      shrink: false,
      obj: {},
      popTipProps: {
        modifiers: {
          computeStyle: {
            gpuAcceleration: false
          },
          preventOverflow: {
            boundariesElement: 'viewport'
          }
        }
      },
      right: 'right',
      rightStart: 'right-start',
      rightEnd: 'right-end'
    };
  },
  created() {},
  mounted: function() {},
  updated() {},
  methods: {
    isActiveNameMain(item) {
      let isTrue = false;
      if (this.$route.name !== 'frame') { // 注册过路由的
        let arr = [];
        this.getArr(item, arr);
        arr.map((v) => {
          if (this.$route.query.type === 'history') {
            isTrue = v.parentSysCode === 'VOUCHERHISTORY';
          } else {
            if (this.$route.name === v.name) {
              isTrue = true;
            } else {
              if (this.$route.meta && this.$route.meta.module) {
                if (this.$route.meta.module === v.name) {
                  isTrue = true;
                }
              }
            }
          }
          return arr;
        });
      } else {
        const menuMainId = this.$route.query && this.$route.query.menuMainId;
        if (menuMainId) {
          return menuMainId === item.oriSysCode;
        } else {
          let isSub = false;
          if (item.children) {
            item.children.map(v => {
              if (!isSub) {
                isSub = this.isActiveName(v);
              }
            });
          }
          return isSub;
        }
      }

      return isTrue;
    },
    getArr(item, arr) {
      if (item.children) {
        item.children.map((v) => {
          this.getArr(v, arr);
        });
      } else {
        arr.push(item);
      }
    },
    isActiveName(item) {
      if (this.$route.name !== 'frame') { // 注册过路由的
        if (this.$route.query.type === 'history') {
          if (this.$route.meta.module === item.oriName && item.parentSysCode === 'VOUCHERHISTORY') {
            return true;
          } else {
            return false;
          }
        } else {
          if (this.$route.name === item.name) {
            return true;
          } else {
            if (this.$route.meta && this.$route.meta.module) {
              if (this.$route.meta.module === item.name) {
                return true;
              } else {
                return false;
              }
            }
          }
        }
        return false;
      } else {
        const menuId = this.$route.query && this.$route.query.menuid;
        return menuId === item.oriSysCode;
      }
    },
    getOpenedNamesByActiveName(name) {
      return this.$route.matched.map((item) => item.name).filter((item) => item !== name);
    },
    updateOpenName(name) {
      if (name === this.$config.homeName) this.openedNames = [];
      else this.openedNames = this.getOpenedNamesByActiveName(name);
    },
    updateOpenNameById(v) {
      this.openedNames = v;
    },
    menuClick(clickItem, menu) {
      if (clickItem.isDir) {
        return;
      }
      this.$emit('on-change', clickItem.name);
      this.monitoring(clickItem);
      if (menu) {
        const vm = this;
        setTimeout(() => {
          vm.$refs[`menu-poptip-${menu.sysCode}`][0].visible = false;
        }, 100);
      }
    },
    changeMenu(active) {
      this.$emit('on-change', active);
    },
    itemTitle(item) {
      return item.name;
    },
    monitoring(clickItem) {
      yiguan.track('function_click', {
        entry_mode: 'menu',
        function_id: clickItem.oriSysCode, // code值
        function_name: clickItem.caption
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import './corp-menu.less';
</style>
