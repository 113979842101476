<template>
  <Row>
    <Col span="11">
    <InputNumber
      v-model="v1"
      :max="max"
      :min="min"
      :precision="precision"
      :can-null="true"
      class="ivu-input-wrapper"
      :formatter="$util.formatNumberFn"
      @on-enter="searchEnter"
      @on-blur="blur"></InputNumber>
    </Col>
    <Col
      span="2"
      class-name="zhi"> {{ mlang('to') }}
    </Col>
    <Col span="11">
    <InputNumber
      v-model="v2"
      :max="max"
      :min="min"
      :precision="precision"
      :can-null="true"
      class="ivu-input-wrapper"
      :formatter="$util.formatNumberFn"
      @on-enter="searchEnter"
      @on-blur="blur"></InputNumber></Col>
  </Row>

  </div>
</template>
<script>
import Emitter from '@/u-components/mixins/emitter';
import QuerySchemaInputNumber from './queryschema-input-number';

export default {
  name: 'QueryschemaInputNumber',
  components: {
    QuerySchemaInputNumber
  },
  mixins: [Emitter],
  props: {
    max: {
      type: Number,
      default: 10000000000000000 // Infinity
    },
    min: {
      type: Number,
      default: -10000000000000000 // -Infinity
    },
    value: {
      type: Object,
      required: true
    },
    precision: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      oldPickerValue: [],
      pickerValue: [],
      v1: null,
      v2: null
    };
  },
  computed: {},
  watch: {
    value: {
      handler() {
        // this.setDate(true);
      }
    }
  },
  mounted() {},
  methods: {
    blur() {
      this.setValue();
    },
    searchEnter() {
      this.setValue();
      this.$emit('on-enter');
    },
    setValue(tagValue, dateValue) {
      // if (this.v2 < this.v1) {
      //   this.v2 = this.v1;
      // }
      // const v1 = typeof this.v1 === 'number' ? this.v1 : 0;
      // let v2 = typeof this.v2 === 'number' ? this.v2 : this.max;
      // let finalV1 = v1;
      // let finalV2 = v2;
      // if (v1 > v2) {
      //   finalV1 = v2;
      //   finalV2 = v1;
      // }
      // if (v1 === v2 && v2 === 0) {
      //   v2 = this.max;
      // }
      this.$emit('input', { v1: this.v1, v2: this.v2 });
    },
    setDate(reload) {
      let { v1, v2 } = this.value;
      this.v1 = v1;
      this.v2 = v2;
      if (!reload) {
        // this.setValue(tagValue, this.pickerValue);
      }
    }
  }
};
</script>
