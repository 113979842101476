<template>
  <Poptip
    :options="popTipProps"
    :trigger="trigger"
    placement="bottom"
    popper-class="poptip"
    @on-popper-show="onPopShow"
    @on-popper-hide="onPopHide">
    <div
      class="order-status-msg">
      <Icon
        custom="iconfont icon-wuliuxinxi"></Icon>
      {{ mlang('logisticInfo') }}
      <Icon type="arrow-down-b"></Icon>
    </div>
    <div
      slot="content"
      class="order-status-poptip-content logistics infor">
      <div
        v-if="!datas.length && isLoading"
        class="ivu-spin ivu-spin-large ivu-spin-fix">
        <div class="ivu-spin-main">
          <span class="ivu-spin-dot"></span> <div class="ivu-spin-text"></div>
        </div>
      </div>
      <div
        v-if="!datas.length && !isLoading"
        class="informatio no-data">
        <p>
          {{ mlang('noLogistic') }}
        </p>
      </div>
      <ul v-if="datas.length">
        <li
          v-for="(item,index) in datas"
          :key="item.id"
          :class="{'active':index === 0}">
          <h2>{{ title }}：{{ item.voucherNo }}</h2>
          <div
            class="informatio">
            <p v-if="item.logisticsFullName || item.logisticsBillNo">
              {{ item.logisticsFullName }} {{ item.logisticsFullName && item.logisticsBillNo && ':' }}{{ item.logisticsBillNo }}
            </p>
            <p
              v-if="item.logisticsUserName || item.logisticsUserPhone || item.logisticsUserPhone || item.logisticsCarNum"
              style="margin-bottom:20px">
              <span
                v-if="item.logisticsUserName"
                style="float:left;margin-right:5px">
                {{ item.logisticsUserName }}
              </span>
              <span
                v-if="item.logisticsUserPhone"
                style="float:left">
                {{ item.logisticsUserPhone }}
              </span>
              <span
                v-if="item.logisticsCarNum"
                style="float:right">
                {{ item.logisticsCarNum }}
              </span>
            </p>
            <div v-if="item.logisticsMsg && item.logisticsMsg.data">
              <template v-for="(detail,index2) in item.logisticsMsg.data">
                <template v-if="index2 < 1 || item.showAll">
                  <p
                    :key="detail.id"
                    :class="{'current':index2 ===0 }">
                    {{ detail.context }}
                  </p>
                  <p
                    :key="index2"
                    class="time">
                    {{ detail.time }}
                  </p>
                </template>
              </template>
              <p
                v-if="item.logisticsMsg.data && item.logisticsMsg.data.length >1"
                class="see-All"
                @click="showAllItem(item)">
                {{ item.showAll === true ? mlang('shouqi'):mlang('showALl') }}
              </p>
            </div>
            <!-- <div v-else-if="item.logisticsFullName && item.logisticsFullName.indexOf('顺丰') > -1">
              <p>
                <a
                  :href="`http://www.sf-express.com/cn/sc/dynamic_function/waybill/#search/bill-number/${item.logisticsBillNo}`"
                  class="primary-color"
                  target="_blank">由于顺丰接口限制，请点此链接去顺丰官网查询</a>
              </p>
            </div> -->
            <div
              v-else-if="item.logisticsMsg && !item.logisticsMsg.data && item.logisticsMsg.message"
              class="informatio no-data">
              <p>
                {{ item.logisticsMsg.message }}
              </p>
            </div>
            <div
              v-else-if="!item.logisticsMsg"
              class="informatio no-data">
              <p>
                {{ mlang('noLogistic') }}
              </p>
            </div>
            <!--<no-data v-else-if="!item.logisticsMsg">
              {{ mlang('noLogistic') }}
            </no-data>-->
          </div>
          <i class="round"><b></b></i>
        </li>
        <li v-if="!datas.length">
          <no-data>{{ mlang('noLogistic') }}</no-data>
        </li>
      </ul>
    </div>
  </poptip>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
export default {
  name: 'Logistic',
  mixins: [PopTipMixin],
  props: {
    datas: {
      type: Array,
      required: true
    },
    hasFetch: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      trigger: 'hover',
    };
  },
  methods: {
    showAllItem(item) {
      this.trigger = 'click';
      this.$set(item, 'showAll', !item.showAll);
    },
    onPopShow() {
      this.$emit('on-popper-show');
    },
    onPopHide() {
      this.trigger = 'hover';
    }
  }
};
</script>
