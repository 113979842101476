<template>
  <Refer
    ref="select-agent-ref"
    v-model="value"
    :biz-meta="bizMeta"
    :control="control"
    :is-queryschema="false"
    :is-select-agent="true"
    @on-ok="onOk"></Refer>
</template>

<script>
import { agentsRefer, agentsReferChanel, agentsReferALL } from '@/meta/refer-common';
import Refer from '_c/common/refer/refer';
const control = (vm) => {
  return { action: 'agentReferAction', caption: vm.mlang('agent') };
};
export default {
  name: 'SelectAgent',
  components: {
    Refer
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    isChannelAgent: {
      type: Boolean,
      default: false
    },
    orgId: {
      type: [Number, String]
    },
    beforeQuery: {
      type: Function,
      default: (v) => {
        return v;
      }
    },
    // 渠道客户参照是否显示客户来源过滤条件
    isShowCustomerSource: {
      type: Boolean,
      default: false
    },
    getAllAgents: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const currentUser = this.$store.getters.currentUser;
    return {
      bizMeta: {
        actions: { agentReferAction: this.agentReferAction }
      },
      value: null,
      control: control(this),
      currentUser
    };
  },
  watch: {
    orgId: {
      handler(val) {
        if (val) {
          this.$set(this.control, 'passedparams', [
            {
              name: 'orgId',
              v1: val,
              valueType: 'Long',
              conditionType: 'eq'
            }
          ]);
        } else {
          this.$delete(this.control, 'passedparams');
        }
      },
      immediate: true
    },
    isChannelAgent: {
      handler(val, oldVal) {
        if (val === oldVal) return;
        if (val) {
          this.bizMeta.actions.agentReferAction = this.agentsChannelReferAction;
        } else {
          this.bizMeta.actions.agentReferAction = this.getAllAgents ? this.agentsReferALLAction : this.agentReferAction;
        }
      },
      immediate: true
    }
  },
  methods: {
    agentReferAction(filterMeta) {
      filterMeta.isSelectAgent = true;
      if (this.multiple) {
        filterMeta.multiple = true;
      }
      if (this.beforeQuery) {
        filterMeta.beforeQuery = this.beforeQuery;
      }
      return agentsRefer(filterMeta, this);
    },
    agentsChannelReferAction(filterMeta) {
      filterMeta.isSelectAgent = true;
      filterMeta.isShowCustomerSource = this.isShowCustomerSource;
      if (this.multiple) {
        filterMeta.multiple = true;
      }
      if (this.beforeQuery) {
        filterMeta.beforeQuery = this.beforeQuery;
      }
      return agentsReferChanel(filterMeta, this);
    },
    agentsReferALLAction(filterMeta) {
      filterMeta.isSelectAgent = true;
      if (this.multiple) {
        filterMeta.multiple = true;
      }
      if (this.beforeQuery) {
        filterMeta.beforeQuery = this.beforeQuery;
      }
      return agentsReferALL(filterMeta, this);
    },
    showRefModal() {
      this.$refs['select-agent-ref'].showModal();
    },
    onOk(rows) {
      this.$emit('on-selected', rows);
    }
  }
};
</script>
<style>
.select-agent .ivu-table-wrapper {
  height: 403px !important;
}
</style>
