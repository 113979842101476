import Vue from 'vue';
import Modal from './modal.vue';
import { Button } from 'iview';
import Locale from '../../mixins/locale';
import i18n from '@/locale/index';
const prefixCls = 'ivu-modal-confirm';

Modal.newInstance = (properties) => {
  const _props = properties || {};

  const Instance = new Vue({
    mixins: [Locale],
    i18n,
    data: Object.assign({}, _props, {
      visible: false,
      width: 416,
      title: '',
      body: '',
      iconType: '',
      iconName: '',
      okText: undefined,
      cancelText: undefined,
      showCancel: false,
      loading: false,
      buttonLoading: false,
      scrollable: false,
      closable: false
    }),
    computed: {
      iconTypeCls() {
        return [`${prefixCls}-body-icon`, `${prefixCls}-body-icon-${this.iconType}`];
      },
      iconNameCls() {
        return ['ivu-icon', `ivu-icon-${this.iconName}`];
      },
      localeOkText() {
        if (this.okText) {
          return this.okText;
        } else {
          return this.t('ok');
        }
      },
      localeCancelText() {
        if (this.cancelText) {
          return this.cancelText;
        } else {
          return this.t('cancel');
        }
      }
    },
    methods: {
      cancel() {
        this.$children[0].visible = false;
        this.buttonLoading = false;
        this.onCancel();
        this.remove();
      },
      ok() {
        if (this.loading) {
          this.buttonLoading = true;
        } else {
          this.$children[0].visible = false;
          this.remove();
        }
        this.onOk();
      },
      remove() {
        this.destroy();
      },
      destroy() {
        this.$destroy();
        document.body.removeChild(this.$el);
        this.onRemove();
      },
      onOk() {},
      onCancel() {},
      onRemove() {}
    },
    render(h) {
      let footerVNodes = [];
      if (this.showCancel) {
        footerVNodes.push(
          h(
            Button,
            {
              props: {
                type: 'text',
                size: 'large'
              },
              on: {
                click: this.cancel
              }
            },
            this.localeCancelText
          )
        );
      }
      footerVNodes.push(
        h(
          Button,
          {
            props: {
              type: 'primary',
              size: 'large',
              loading: this.buttonLoading
            },
            on: {
              click: this.ok
            }
          },
          this.localeOkText
        )
      );

      // render content
      let bodyRender;
      if (this.render) {
        bodyRender = h(
          'div',
          {
            attrs: {
              class: `${prefixCls}-body ${prefixCls}-body-render`
            }
          },
          [this.render(h)]
        );
      } else {
        bodyRender = h(
          'div',
          {
            attrs: {
              class: `${prefixCls}-body`
            }
          },
          [
            h(
              'div',
              {
                class: this.iconTypeCls
              },
              [
                h('i', {
                  class: this.iconNameCls
                })
              ]
            ),
            h('p', {
              domProps: {
                innerHTML: this.body
              }
            })
          ]
        );
      }

      return h(
        Modal,
        {
          props: Object.assign({}, _props, {
            width: this.width,
            scrollable: this.scrollable,
            closable: this.closable
          }),
          domProps: {
            value: this.visible
          },
          on: {
            input: (status) => {
              this.visible = status;
            }
          }
        },
        [
          h(
            'div',
            {
              attrs: {
                class: prefixCls
              }
            },
            [
              h(
                'div',
                {
                  attrs: {
                    class: `${prefixCls}-head`
                  }
                },
                [
                  h('div', {
                    attrs: {
                      class: `${prefixCls}-head-title`
                    },
                    domProps: {
                      innerHTML: this.title
                    }
                  })
                ]
              ),
              bodyRender,
              h(
                'div',
                {
                  attrs: {
                    class: `${prefixCls}-footer`
                  }
                },
                footerVNodes
              )
            ]
          )
        ]
      );
    }
  });

  const component = Instance.$mount();
  document.body.appendChild(component.$el);
  const modal = Instance.$children[0];

  return {
    loading(v) {
      modal.$parent.buttonLoading = v;
    },
    show(props) {
      modal.$parent.showCancel = props.showCancel;
      modal.$parent.iconType = props.icon;

      switch (props.icon) {
        case 'info':
          modal.$parent.iconName = 'ios-information-circle';
          break;
        case 'success':
          modal.$parent.iconName = 'ios-checkmark-circle';
          break;
        case 'warning':
          modal.$parent.iconName = 'ios-alert';
          break;
        case 'error':
          modal.$parent.iconName = 'ios-close-face';
          break;
        case 'confirm':
          modal.$parent.iconName = 'ios-help-circle';
          break;
      }

      if ('width' in props) {
        modal.$parent.width = props.width;
      }

      if ('closable' in props) {
        modal.$parent.closable = props.closable;
      }

      if ('title' in props) {
        modal.$parent.title = props.title;
      }

      if ('content' in props) {
        modal.$parent.body = props.content;
      }

      if ('okText' in props) {
        modal.$parent.okText = props.okText;
      }

      if ('cancelText' in props) {
        modal.$parent.cancelText = props.cancelText;
      }

      if ('onCancel' in props) {
        modal.$parent.onCancel = props.onCancel;
      }

      if ('onOk' in props) {
        modal.$parent.onOk = props.onOk;
      }

      // async for ok
      if ('loading' in props) {
        modal.$parent.loading = props.loading;
      }

      if ('scrollable' in props) {
        modal.$parent.scrollable = props.scrollable;
      }

      // notice when component destroy
      modal.$parent.onRemove = props.onRemove;

      modal.visible = true;
    },
    remove() {
      modal.visible = false;
      modal.$parent.buttonLoading = false;
      modal.$parent.remove();
    },
    component: modal
  };
};

export default Modal;
