<template>
  <div class="vue-bigdata-table-item-table">
    <table
      v-show="showTable && fixedCol >= 0"
      :class="['vue-bigdata-table-data-table', 'vue-bigdata-table-fixed-table', showFixedBoxShadow ? 'box-shadow' : '']"
      cellspacing="0"
      cellpadding="0"
      border="0"
      @paste="handlePaste">
      <colgroup>
        <col
          v-for="(width, i) in widthArr"
          v-if="i <= fixedCol"
          :key="'colgroup-fixed-' + i"
          :width="width">
      </colgroup>
      <tbody>
        <tr
          v-for="(tr, index) in itemData"
          :key="index"
          :style="{background: currentMouseEnterIndex === index && canSelectText ? '#f7f9fd' : ''}"
          :class="getTrClass(indexBase,index,tr)"
          @click="handleClickTr(indexBase + index, tr.initRowIndex)"
          @mouseenter.stop="handleMouseIn(index,tr)"
          @mouseleave.stop="handleMouseLeave(index,tr)">
          <td
            v-if="showIndex"
            :class="['vue-bigdata-table-cell', 'vue-bigdata-table-data-table-center']">
            <div class="ivu-table-cell">
              {{ indexBase + index+1 }}
            </div>
            <!-- <render-dom
              :render="indexRender"
              :back-value="{index: (indexBase + index), params: indexRenderParams}"></render-dom> -->
          </td>
          <td
            v-for="(td, i) in columns"
            v-if="i <= fixedColCom && td.key !== 'index'"
            :key="`${indexBase}-${index}-${i}`"
            :data-row="indexBase + index"
            :data-col="`${indexBase}-${index}-${i}`"
            :class="
              getTdClass(i,td,indexBase,index,tr)"
            :style="rowStyles"
            @click="handleClickTd(indexBase + index, i, tr.initRowIndex)"
            @dblclick="handleDblclickTd(indexBase + index, i, (typeof td === 'object' && td !== null) ? td.value : td)">
            <div class="ivu-table-cell">
              <template v-if="!canEdit || (canEdit && `${indexBase + index}-${i}` !== edittingTd || !td.isCanModify) ">
                <div
                  v-if="!showCellRender[showIndex ? (i) : i]"
                  :title="tr[td.key]"
                  class="vue-bigdata-table-cell one-line-overflow-hide">
                  {{ (typeof td === 'object' && td !== null) ? tr[td.key] : td }}
                </div>
                <template v-else>
                  <render-dom
                    :render="showCellRender[showIndex ? (i) : i]"
                    :back-value="{rowIndex: indexBase + index, col: i,column:td,rowData:tr,row:tr, value: (typeof td === 'object' && td !== null) ?tr[td.key] : td}"></render-dom>
                </template>
              </template>
              <render-dom
                v-else
                :render="editCellRender"
                :back-value="{rowIndex: indexBase + index, col: i,column:td,rowData:tr,row:tr, value: (typeof td === 'object' && td !== null) ? td.value : td, beforeSave}"></render-dom>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-show="showTable"
      ref="itemTable"
      :style="{position: fixedCol < 0 ? '' : 'absolute'}"
      class="vue-bigdata-table-data-table vue-bigdata-table-content-table"
      cellspacing="0"
      cellpadding="0"
      border="0"
      width="100%"
      @paste="handlePaste">
      <colgroup>
        <col
          v-for="(width, i) in widthArr"
          :key="'colgroup-' + i"
          :width="width">
      </colgroup>
      <tbody>
        <tr
          v-for="(tr, index) in itemData"
          v-show="tr.actionStatus !== 'D'"
          :key="index"
          :style="{background: currentMouseEnterIndex === index && canSelectText ? '#f7f9fd' : ''}"
          :class="getTrClass(indexBase,index,tr)"
          @click="handleClickTr(indexBase + index, tr.initRowIndex)"
          @mouseenter.stop="handleMouseIn(index,tr)"
          @mouseleave.stop="handleMouseLeave(index,tr)">
          <td
            v-if="showIndex"
            :class="['vue-bigdata-table-cell', 'vue-bigdata-table-data-table-center']">
            {{ indexBase + index+1 }}
            <!-- <render-dom
              v-if="fixedCol < 0"
              :render="indexRender"
              :back-value="(indexBase + index)"></render-dom> -->
          </td>
          <td
            v-for="(td, i) in columns"
            v-if="td.key !== 'index'"
            :key="`${indexBase}-${index}-${i}`"
            :data-row="indexBase + index"
            :data-col="`${indexBase}-${index}-${i}`"
            :class="getTdClass(i,td,indexBase,index,tr)"
            :style="rowStyles"
            @click="handleClickTd(indexBase + index, i, tr.initRowIndex)"
            @dblclick="handleDblclickTd(indexBase + index, i, (typeof td === 'object' && td !== null) ? td.value : td)">
            <div class="ivu-table-cell">
              <template v-if="!canEdit || (canEdit && `${indexBase + index}-${i}` !== edittingTd) || !td.isCanModify">
                <!-- {{ td.key }}
              {{ i >= fixedCol }}
              {{ showIndex }}
              {{ !showCellRender[showIndex ? (i) : i] }} -->
                <!-- {{ tr }} -->
                <div
                  v-if="(!showCellRender[showIndex ? (i ) : i]) && (i >= fixedCol)"
                  :title="tr[td.key]"
                  class="vue-bigdata-table-cell one-line-overflow-hide">
                  {{ (typeof td === 'object' && td !== null) ? tr[td.key] : td }}
                </div>
                <template v-else-if="i >= fixedCol">
                  <render-dom
                    :render="showCellRender[showIndex ? (i ) : i]"
                    :back-value="{rowIndex: indexBase + index, col: i,column:td,rowData:tr, row:tr,value: (typeof td === 'object' && td !== null) ? tr[td.key] : td}"></render-dom>
                </template>
              </template>
              <render-dom
                v-else
                :render="editCellRender"
                :back-value="{rowIndex: indexBase + index, col: i, column:td,rowData:tr,row:tr,value: (typeof td === 'object' && td !== null) ? td.value : td, beforeSave, initRowIndex: tr.initRowIndex}"></render-dom>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import renderDom from './renderDom';
export default {
  name: 'ItemTable',
  components: {
    renderDom
  },
  props: {
    times: Number,
    tableIndex: Number,
    itemData: Array,
    rowStyles: Object,
    widthArr: Array,
    columns: Array,
    itemNum: Number,
    showIndex: Boolean,
    indexRender: Function,
    stripe: Boolean,
    fixedCol: Number,
    currentScrollToRowIndex: Number,
    canEdit: Boolean,
    edittingTd: String,
    startEditType: String,
    showFixedBoxShadow: Boolean,
    editCellRender: Function,
    beforeSave: Function,
    canSelectText: Boolean,
    startSelect: Object,
    endSelect: Object,
    disabledHover: Boolean,
    highlightRow: Boolean,
    highlightRowIndex: Number,
    indexRenderParams: Object
  },
  data() {
    return {
      prefix: 'vue-bigdata-table-data-table',
      tableWidth: 0,
      currentMouseEnterIndex: -1,
      editInputValue: ''
    };
  },
  computed: {
    showTable() {
      // console.log(this.itemData.length);
      return this.itemData.length > 0;
    },
    indexBase() {
      return this.times * this.itemNum * 3 + this.itemNum * (this.tableIndex - 1);
    },
    showCellRender() {
      return this.columns.map((item) => {
        return item.render ? item.render : undefined;
      });
    },
    baseIndex() {
      return this.showIndex ? 1 : 0;
    },
    fixedColCom() {
      return this.showIndex ? this.fixedCol - 1 : this.fixedCol;
    }
  },
  methods: {
    getTdClass(i, td, indexBase, index, tr) {
      return [
        'vue-bigdata-table-cell',
        this.setAlign(i),
        (typeof td === 'object' && td !== null) ? td.className : '',
        this.getSelectCellClasses(indexBase + index, i),
        tr.__isFirstActive && !i ? 'highlight-first-active' : ''
      ];
    },
    getTrClass(indexBase, index, tr) {
      return [
        {
          'stripe-gray': this.stripe && (indexBase + index) % 2 !== 0,
          'scroll-to-row-tip': this.currentScrollToRowIndex === indexBase + index,
          'highlight-active': tr.__isActive

        }
      ];
    },
    setAlign(i) {
      let columns = this.columns[i];
      if (!columns) return;
      let col = columns;
      return this.prefix + '-' + col.align;
    },
    backValue(row, col) {
      return {
        row: row,
        col: col
      };
    },
    handleMouseIn(index, tr) {
      if (!this.disabledHover) return;
      this.currentMouseEnterIndex = index;
      if (tr && tr.onHover) {
        tr.onHover(index);
      }
    },
    handleMouseLeave(index, tr) {
      this.currentMouseEnterIndex = -1;
      if (tr && tr.onHover) {
        tr.onHover(-1);
      }
    },
    handleClickTr(row, initRowIndex) {
      this.$emit('on-click-tr', row, initRowIndex);
    },
    handleClickTd(row, col, initRowIndex) {
      if (this.canEdit && this.startEditType === 'click') this.editCell(row, col);
      this.$emit('on-click-td', { row, col, edittingTd: this.edittingTd, initRowIndex });
    },
    editCell(row, col) {
      this.$emit('on-edit-cell', row, col);
    },
    handleDblclickTd(row, col, value) {
      this.editInputValue = value;
      if (this.canEdit && this.startEditType === 'dblclick') this.editCell(row, col);
    },
    getSelectCellClasses(row, col) {
      let startSelect = this.startSelect;
      let endSelect = this.endSelect;
      let startRow = parseInt(startSelect['row']);
      let endRow = parseInt(endSelect['row']);
      let startCol = parseInt(startSelect['col']);
      return [
        startRow === row && startCol === col ? 'start-select-cell' : '',
        endRow === row && endSelect['col'] === col ? 'end-select-cell' : '',
        startRow === row && endSelect['col'] === col ? 'right-top-select-cell' : '',
        endRow === row && startCol === col ? 'left-bottom-select-cell' : '',
        startRow === row && col > startCol && col < endSelect['col'] ? 'top-center-select-cell' : '',
        endRow === row && col > startCol && col < endSelect['col'] ? 'bottom-center-select-cell' : '',
        startCol === col && row > startRow && row < endRow ? 'left-center-select-cell' : '',
        endSelect['col'] === col && row > startRow && row < endRow ? 'right-center-select-cell' : ''
      ];
    },
    handlePaste(e) {
      const data = e.clipboardData.getData('text/plain');
      const rowsData = data.split(/[\n\u0085\u2028\u2029]|\r\n?/g).map((row) => {
        return row.split('\t');
      });
      this.$emit('on-paste', rowsData);
    }
  }
};
</script>
