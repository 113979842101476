import { getProductGroupsReal } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const productGroupsRealRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'name',
      // 传递过去的参数
      passedparams: [
      ],
      queryschema: [
        {
          name: 'name',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('productGroupName'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          fieldName: 'code'
        },
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'name'
        }
        // {
        //   showCaption: vm.mlang('bizName'),
        //   isShowIt: vm.$store.getters.currentUser.__showBiz,
        //   fieldName: 'bizName',
        //   width: 120
        // },
        // {
        //   showCaption: vm.mlang('org'),
        //   isShowIt: vm.$store.getters.currentUser.showOrg,
        //   fieldName: 'orgName',
        //   width: 120
        // }
      ],
      actions: { queryList: getProductGroupsReal },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default productGroupsRealRefer;
