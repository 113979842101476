import corpParentView from '@/views/corp/home/home.vue';

const other = {
  path: 'other',
  title: { i18n: 'other' },
  name: 'other',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'frame',
      name: 'frame',
      meta: {
        notCache:true
      },
      component: () => import('@/views/corp/other/components/frame.vue')
    }
  ]
};
export default other;
