export default {
  settlementMethod: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580004A","结算方式") /* "结算方式" */,
  qiyeduan: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B7","企业端") /* "企业端" */,
  dinghuoduan: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B8","订货端") /* "订货端" */,
  yidongduan: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002B9","移动端") /* "移动端" */,
  menuSetting2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002BA","菜单") /* "菜单" */,
  menuSettingList2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002BB","菜单列表") /* "菜单列表" */,
  addMenuClass2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002BC","新增菜单") /* "新增菜单" */,
  enterpriseTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002BD","最多支持三级菜单") /* "最多支持三级菜单" */,
  orderMobileTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002BE","最多支持一级菜单") /* "最多支持一级菜单" */,
  mobileImgTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002BF","仅支持JPG、JPEG、BMP、PNG格式（建议使用PNG格式，最佳尺寸80*80px）") /* "仅支持JPG、JPEG、BMP、PNG格式（建议使用PNG格式，最佳尺寸80*80px）" */
};
