import ajax from '@/libs/ajax';
// 订单提示功能配置
export const ueOrderTipConfig = () => {
  return ajax.request({
    url: '/ueOrderTip',
    method: 'get'
  });
};

// 获取部分页面的特殊配置
export const getPageConfig = () => {
  return ajax.request({
    url: '/pageConfig',
    method: 'get'
  });
};
// 申请加盟协议
export const ueJoinConfig = () => {
  return ajax.request({
    url: '/ueJoin',
    method: 'get'
  });
};
