import { users } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const submiterRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: true,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'fullName',
      queryschema: [

        {
          name: 'person.fullName',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('xingming'),
          conditionType: 'like'
        },
        {
          name: 'code',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('code'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('name'),
          fieldName: 'fullName',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          width: 180,
          fieldName: 'code'
        },
        {
          showCaption: vm.mlang('role'),
          isShowIt: !vm.$store.getters.currentUser.__isMutiOrg,
          width: 140,
          fieldName: 'roleName'
        },
        {
          showCaption: vm.mlang('loginAccount'),
          isShowIt: true,
          width: 180,
          fieldName: 'userName'
        },
        {
          showCaption: vm.mlang('mobile'),
          isShowIt: true,
          width: 180,
          fieldName: 'mobile'
        },
        {
          showCaption: vm.mlang('mail'),
          isShowIt: true,
          width: 180,
          fieldName: 'email'
        }
      ],
      actions: { queryList: users }
    },
    ctrl
  );
  return deepCopy(control);
};
export default submiterRefer;
