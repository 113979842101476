import ajax from '@/libs/ajax';

export const getMenuUrlById = (menuId) => {
  return ajax
    .request({
      url: `/archives/menu/${menuId}/menuvo`,
      method: 'get',
      data: {
        isArchive: 1
      }
    });
};
export const getMenuUrlByCode = (code) => {
  return ajax.request({
    url: `/archives/menu/menuvo/${code}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
