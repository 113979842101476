import { render, staticRenderFns } from "./ex-or-import-result-modal.vue?vue&type=template&id=81a59cc6&scoped=true"
import script from "./ex-or-import-result-modal.vue?vue&type=script&lang=js"
export * from "./ex-or-import-result-modal.vue?vue&type=script&lang=js"
import style0 from "./ex-or-import-result-modal.vue?vue&type=style&index=0&id=81a59cc6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81a59cc6",
  null
  
)

export default component.exports