<template>
  <div>
    <VoucherList
      ref="voucherList"
      :data="data"
      :query="query"
      :biz-meta="meta">
    </VoucherList>
  </div>
</template>

<script>
// 金额费用
import VoucherList from '_c/common/voucher-list';
// import { bizRefer } from '@/meta/refer-common';
import { getDiscountRebate, RebateSourceSrv, exportDiscountRebate, getUsableRebatesAccount } from '@/api/rebateCenter/customerRebate/discountRebate.js';
import { getBizs } from '@/api/agentPayment/payment.js';
import ArchiveListMixin from '@/mixin/archive-list.js';
import Emitter from '@/u-components/mixins/emitter';

export default {
  name: 'DiscountRebates',
  components: {
    VoucherList
  },
  mixins: [Emitter, ArchiveListMixin],
  data() {
    const detailRouteName = 'discountRebate';
    const meta = this.getDefaultMeta({ detailRouteName });
    return {
      data: {},
      query: this.queryList,
      meta,
      isFromSumary: this.$route.query.formSumary,
      applicationMethod: [
        {
          cName: this.mlang('ALL'),
          cCode: ''
        },
        {
          cName: this.mlang('TOORDER'),
          cCode: 'TOORDER'
        },
        {
          cName: this.mlang('TOPRODUCT'),
          cCode: 'TOPRODUCT'
        },
        {
          cName: this.mlang('TOCASH'),
          cCode: 'TOCASH'
        }
      ],
      statuses: [
        {
          cName: this.mlang('ALL'),
          cCode: ''
        },
        {
          cName: this.mlang('NOTCONFIRM'),
          cCode: 'NOTCONFIRM'
        },
        {
          cName: this.mlang('ENDCONFIRM'),
          cCode: 'ENDCONFIRM'
        }
      ],
      detailRouteName
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {
    // bizReferAction(filterMeta) {
    //   return bizRefer(filterMeta, this);
    // },
    bizAction(filterMeta) {
      return getBizs().then((data = []) => {
        data.forEach((item) => {
          item.title = item.name;
          item.value = item.id;
        });
        return data;
      });
    },
    getDefaultMeta(data) {
      const rebateExport = this.$hasAuth('agentRebateExport');
      const meta = {
        billId: 322,
        isAgent: false,
        multiple: false,
        isHighlighRow: false,
        actions: {
          bizAction: this.bizAction,
          //  bizReferAction: this.bizReferAction,
          rebateSourceAction: RebateSourceSrv
        },
        setBillMeta: this.setBillMeta,
        async: true
      };
      if (rebateExport && !this.$route.query.formSumary) {
        meta.export = {
          action: exportDiscountRebate,
          beforeExport: this.beforeExport
        };
      }
      return meta;
    },
    setBillMeta(billMeta) {
      let filters = billMeta.currentFilter.currentGroups[0].currentFilterItems;
      let bills = billMeta.currentTemplate.currentTplGroups[0].currentBillItems;
      let filter = filters.find(item => item.name === 'bizId');
      filter.isShowIt = this.currentUser.__showBiz;
      filters.forEach((v) => {
        if (['beabled', 'bizId', 'rebateSourceId'].includes(v.name) && this.isFromSumary) {
          v.isShowIt = false;
        }
        if (v.name === 'rebateSourceId') {
          v.isShowIt = !this.currentUser.__isU8C;
        }
        if (this.currentUser.__isU8C && v.name === 'useWayCode') {
          v.enumString = JSON.stringify([{ 'name': 'TOPRODUCT', 'title': this.mlang('TOPRODUCT'), 'value': 'TOPRODUCT' }, { 'name': 'TOCASH', 'title': this.mlang('TOCASH'), 'value': 'TOCASH' }]);
        }
      });
      let rebateStatus = bills.find(item => item.fieldName === 'rebateStatus');
      rebateStatus.isShowIt = !this.isFromSumary;
      return billMeta;
    },
    beforeExport(query) {
      if (localStorage.upOrgId && localStorage.upOrgId !== 'undefined') {
        query.querySchema.conditions.push({
          name: 'salesOrgId',
          v1: Number(localStorage.upOrgId),
          valueType: 'LONG',
          conditionType: 'eq'
        });
      }
      return query;
    },
    queryList(query) {
      if (this.isFromSumary) {
        let url = `/pc/RebateSrv/getUsableRebatesAccount?bizId=${this.$route.query.bizId}&salesOrgId=${this.$route.query.salesOrgId}&pageSize=${query.querySchema.pager.pageSize}&pageIndex=${query.querySchema.pager.pageIndex}`;
        query.querySchema.conditions && query.querySchema.conditions.forEach((v) => {
          if (v.name === 'rebateNo') {
            url += `&searchRebateNo=${v.v1}`;
          }
          if (v.name === 'validStartDate') url += `&startDate=${v.v1}`;
          if (v.name === 'validEndDate') url += `&endDate=${v.v1}`;
          if (v.name === 'useWayCode') url += `&cUseWayCode=${v.v1}`;
        });
        getUsableRebatesAccount(url).then((res) => {
          res.data.forEach((v) => {
            v.rebateNo = v.cRebateNo;
            v.createDate = v.dCreateDate;
            v.validEndDate = v.dValidEndDate;
            v.validStartDate = v.dValidStartDate;
            v.rebateMoney = v.fRebateMoney;
            v.surplusMoney = v.fSurplusMoney;
            v.useWayCode = v.cUseWayCode;
            let to = {
              path: '/agent/frame',
              query: {
                uri: this.$util.agentPageUrl(`/agentpage/Rebates/detail?cRebateNo=${v.rebateNo}`)
              }
            };
            v.rebateNoTo = to;
          });
          res.content = res.data;
          res.total = res.totalCount;
          this.isLoaing = false;
          this.data = res;
        });
      } else {
        query.querySchema.conditions.push({
          name: 'rebateCode',
          v1: 'CASHDISCOUNTS',
          valueType: 'STRING',
          conditionType: 'eq'
        });
        query.querySchema.conditions.push({
          name: 'rebateStatus',
          v1: 'ENDCONFIRM',
          valueType: 'string',
          conditionType: 'eq'
        });
        getDiscountRebate(query).then((res) => {
          res && res.content && res.content.map((v) => {
            if (v.validStartDate && v.validEndDate) {
              v.validityPeriod = `${v.validStartDate} ${this.mlang('to')} ${v.validEndDate}`;
            }
            let cUseWayName = this.applicationMethod.find((val) => {
              return val.cCode === v.cUseWayCode;
            });
            if (cUseWayName) {
              v.cUseWayName = cUseWayName.cName;
            }
            let cRebateStatusName = this.statuses.find((val) => {
              return val.cCode === v.cRebateStatus;
            });
            if (cRebateStatusName) {
              v.cRebateStatusName = cRebateStatusName.cName;
            }
            let to = {
              path: '/agent/frame',
              query: {
                uri: this.$util.agentPageUrl(`/agentpage/Rebates/detail?cRebateNo=${v.rebateNo}`)
              }
            };
            v.rebateNoTo = to;
          });
          this.data = res;
          this.isLoaing = false;
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.voucher-list /deep/ .ivu-table-footer {
  .bottom-page {
    box-shadow: none;
    border-bottom: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
