class TabsState {
  constructor(current, top, bottom, tabs) {
    this.top = top || tabs.length;
    this.bottom = bottom || 0;
    this.tabs = tabs;
    this.next = current > bottom ? current : bottom;
    this.initTabs();
  }
  // 初始tabs的状态
  initTabs() {
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].isShowIt = (this.bottom <= i && i <= this.top); // 设置哪些显示
      this.tabs[i].disable = i > this.next; // 设置哪些可用
    }
  }
  getTabs() {
    return this.tabs;
  }
  getCurrentTab() {
    let current = this.next >= this.tabs.length ? this.tabs.length - 1 : this.next;
    return this.tabs[current].name;
  }
  getNextTab() {
    return this.tabs[this.next + 1] ? this.tabs[this.next + 1].name : null;
  }
  getBottom() {
    return this.bottom;
  }
  setBottom(bottom) {
    this.bottom = bottom;
    this.next = this.next > bottom ? this.next : bottom;
    this.initTabs();
  }
  backTo(next) {
    if (next < this.bottom || this.top < next) {
      return;
    }
    this.next = next;
    for (let i = 0; i < this.top; i++) {
      this.tabs[i].disable = (i > next);
    }
  }
  backToBottom() {
    this.backTo(this.bottom);
  }
  go() {
    if (this.isFull()) return false;
    this.next++;
    if (this.next < this.tabs.length) { this.tabs[this.next].disable = false; }
    return true;
  }
  isFull() {
    return this.next >= this.top - 1;
  }
}

export default TabsState;
