<template>
  <Poptip :transfer="false" content="content" trigger="hover" placement="bottom" popper-class="header-update">
    <span class="span-cursor">
      <Icon class="iconfont dinghuo-gengduohuotuichu" />
    </span>
    <div slot="content">
      <!-- <p class="header-exact" v-if="!hideMyaccount">
        <a href="javascript:void(0)" fieldid="fieldid_updateLog_myAccount" @click="toMyAccount()">
          <Icon class-name="international-icon" custom="iconfont icon-account-information"></Icon>
          {{ mlang('myAccount') }}
        </a>
      </p>
      <p v-if="!hideLogout" class="header-exact">
        <a href="/logout" fieldid="fieldid_updateLog_logout" @click="toLogout">
          <Icon class-name="international-icon" type="logout"></Icon>
          {{ mlang('logoutSystem') }}
        </a>
      </p> -->
      <template v-if="showHubbleBtn">
        <p class="header-exact">
          <a href="javascript:void(0)" fieldid="fieldid_updateLog_start_hubble" @click="startHubble">
            <Icon class-name="international-icon" type="ios-videocam-outline"></Icon>
            {{ mlang('startHubbleTitle') }}
          </a>
        </p>
        <p class="header-exact">
          <a href="javascript:void(0)" fieldid="fieldid_updateLog_end_hubble" @click="endHubble">
            <Icon class-name="international-icon" type="ios-videocam"></Icon>
            {{ mlang('endHubbleTitle') }}
          </a>
        </p>
      </template>
    </div>
  </Poptip>
</template>
<script>
import printCookie from '@/components/print-ctrl/print-cookie.js';
import { getAgentMainance } from '@/api/customer/agentBaseInfo';
import { getAllRegion } from '@/api/region';
import Cookies from 'js-cookie';
import Modal from '@/u-components/components/modal';
import util from '@/libs/util.js';
import _ from 'lodash';
export default {
  name: 'UpdateLog',
  data() {
    return {
      hideLogout: window.localStorage.getItem('isCorpToOrder'),
      hideMyaccount: localStorage.isRepresentToOrder === 'true',
      showHubbleBtn: window.localStorage.getItem('hubble'),
      hubbleStatus: 1,
      hubbleuUid: '',
      currentUser: this.$store.getters.currentUser,
      startTime: '',
      bprId: 'udh_' + _.random(1, 10000000000)
    };
  },
  mounted() { },
  methods: {
    async toMyAccount() {
      const currentUser = this.$store.getters.currentUser;
      getAgentMainance().then((data) => {
        let isCantmaintance = '';
        data && Object.entries(data).forEach(function(v) { if (v[1]) { isCantmaintance += (v[0] + ';'); } });
        this.$router.push({
          name: 'agentbase',
          query: {
            isCantmaintance,
            agentId: currentUser.agentId,
            userId: currentUser.userId
          }
        });
      });
      const dbManager = await util.getIndexedDBManager();
      let regionList = await dbManager.getItem('regionList');
      //!window.localStorage.getItem('regionList')
      !regionList && getAllRegion().then(async(res) => {
        await dbManager.setItem('regionList', JSON.stringify(res))
        //localStorage.setItem('regionList', JSON.stringify(res));
      });
    },
    toLogout() {
      let arr = ['iPaytype', 'recoProducts', 'productPageSize', 'allProducts', 'pcScartSelected', 'scartInvaild', 'storeOrder', 'isRepresentToOrder', 'regionList'];
      this.removeStorage(arr);
      printCookie.clearToken();
      if (window !== top) {
        if (window.localStorage.getItem('isDataCenter')) window.localStorage.removeItem('isDataCenter');
        window.parent.postMessage({ key: 'logout' }, '*');
      }
    },
    removeStorage(arr) {
      if (arr.length) {
        arr.map((v) => {
          if (window.localStorage.getItem(v)) {
            window.localStorage.removeItem(v);
          }
        });
      }
    },
    startHubble() {
      const { ymcurl, udhpcHost } = window.__app.context;
      let expires = new Date(new Date() * 1 + 60 * 1000 * 5);
      this.startTime = new Date().getTime();
      let url = `${ymcurl}/iuap-ymc-server/proxy/server/monitor/record?isDiwork=true&uid=${this.bprId}&host=${udhpcHost}&userId=${this.currentUser.userId}`;
      Modal.confirm({
        title: `${this.mlang('startHubbleTitle')}`,
        content: `${this.mlang('startHubbleTitle')}`,
        onOk: () => {
          let params = {
            host: udhpcHost,
            uid: this.bprId,
            userId: this.currentUser.userId,
            userName: this.currentUser.fullName,
            isDiwork: true
          };
          const __app = window.__app;
          __app.ajax({
            type: 'POST',
            url,
            data: params,
            dataType: 'json',
            headers: {
              cookie: document.cookie,
              referer: udhpcHost,
              'Connection': 'keep-alive',
              'Content-Type': 'application/json'
            },
            success: (data) => {
              Cookies.set('mdd_monitor_uid', this.bprId, { expires: expires });
              Cookies.set('mdd_monitor_record', true, { expires: expires });
              Cookies.set('ymc_recording', true, { expires: expires });
              Cookies.set('ymc_flag', `bprId=${this.bprId};bprStartTs=${this.startTime}`, { expires: expires });
            },
            error: (errMsg) => { }
          });
        },
        onCancel: () => { }
      });
    },
    endHubble() {
      const { ymcurl, udhpcHost } = window.__app.context;
      Modal.confirm({
        title: `${this.mlang('endHubbleTitle')}`,
        content: `<div>
          ${this.mlang('endHubbleTitle')} <br />
          ${this.mlang('hubbleUId')}: ${this.bprId}
        </div>`,
        onOk: () => {
          let params = {
            host: udhpcHost,
            uid: Cookies.get('mdd_monitor_uid'),
            userId: this.currentUser.userId,
            userName: this.currentUser.fullName,
            isDiwork: true
          };
          const __app = window.__app;
          __app.ajax({
            type: 'POST',
            url: `${ymcurl}/iuap-ymc-server/proxy/server/monitor/record?isDiwork=true&uid=${Cookies.get('mdd_monitor_uid')}&host=${udhpcHost}&userId=${this.currentUser.userId}&userName=${encodeURIComponent(this.currentUser.fullName)}`,
            data: params,
            dataType: 'json',
            headers: {
              cookie: document.cookie,
              referer: udhpcHost
            },
            success: (data) => {
              Cookies.set('mdd_monitor_record', false);
              Cookies.remove('mdd_monitor_uid', '');
            },
            error: (errMsg) => { }
          });
          __app.ajax({
            type: 'post',
            url: `${ymcurl}/iuap-ymc-server/proxy/server/bprcall?bprId=${this.bprId}&callBack=${this.bprId}&startTime=${this.startTime}&endTime=${new Date().getTime()}&userId=${this.currentUser.userId}&userName=${encodeURIComponent(this.currentUser.fullName)}`,
            data: params,
            dataType: 'json',
            headers: {
              cookie: document.cookie,
              referer: udhpcHost,
              Accept: '*/*',
              'Content-Type': 'application/json; charset=UTF-8'
            },
            success: (data) => {
              Cookies.remove('ymc_flag', '');
              Cookies.remove('ymc_recording', false);
            },
            error: (errMsg) => { }
          });
        },
        onCancel: () => { }
      });
    }
  }
};
</script>
