/* eslint-disable */
import Vue from 'vue';
import iView from 'iview';
import Util from '../libs/util';
import VueRouter from 'vue-router';
import { routers, otherRouter, appRouter } from './router';
import { getToken } from '@/libs/auth'; // 验权
import store from '@/store';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

// 路由配置
const RouterConfig = {
  // mode: 'history',
  routes: routers
};

export const router = new VueRouter(RouterConfig);
const currentUser = JSON.parse(localStorage.getItem('defaultUserInfo'));
router.beforeEach(async (to, from, next) => {
  iView.LoadingBar.start();
  Util.title(to, router.app);
  if (getToken()) {
    let isAgentSide = !!to.meta.isAgentSide;
    sessionStorage.isAgentSide = isAgentSide;
    // 判断是否有token
    if (to.path === '/login' || to.path === '/register' || to.path === '/join' || to.path === '/cusRegister' || to.path === '/crmAuthPage' || to.path === '/forget' || to.path === '/applyToJoin' || to.path === '/404') {
      next(); //
      // window.location.href = '/login';
      iView.LoadingBar.finish(); // router在hash模式下 手动改变hash 重定向回来 不会触发afterEach 暂时hack方案 ps：history模式下无问题，可删除该行！
    } else if (to.path === '/' || to.path === '/corppage') {
      window.location.href = '/corp/#/index';
    } else {
      if (isAgentSide) {
        if(Cookies.get('hasAgentToken') !== 'true'  && window.top === window.self){
          if(!localStorage.getItem('dataCenterUrl')){
            window.location.href = '/login';
          }
          return;
        }

        if (currentUser && [1, 3, 9, 10].includes(currentUser.userType)) {
          //正在代下单 检查是否选择了代下单的客户
          if (!localStorage.upAgentId) {
            next(); //跳agent/main.vue 里面有业务逻辑处理
            return;
          }
        }
      }
      if (localStorage.getItem('dataCenterUrl') && window.top === window.self) {
        next();
        return;
      }
      await store.dispatch('GetUserInfo').catch((e) => console.error(e)); //用户信息
      let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
      await store.dispatch('GetAgentRelations').catch((e) => console.error(e)); //交易关系
      if ((!user.relations || user.relations.length <= 0) && isAgentSide && (!localStorage.getItem('tourist') || localStorage.getItem('tourist') === 'false')) {
        next();
        return false;
      }
      // await store.dispatch('GetUserBo').catch((e) => console.error(e)); //业务选项
      store.dispatch('GetPriceExact'); //价格精度
      store.dispatch('GetUserNewsCount'); //消息
      await store.dispatch('GetPermissionList').catch((e) => console.error(e)); //权限
      if (isAgentSide) {
        //await store.dispatch('GetUserSettingPrice').catch((e) => console.error(e)); //隐藏价格设置
        // await store.dispatch('GetAgentRelations').catch((e) => console.error(e)); //交易关系
        store.dispatch('GetShoppingNum'); //购物车数量
      }
      await store.dispatch('GetUserSettingPrice').catch((e) => console.error(e));
      await store.dispatch('GetExtendJsConfig'); //拓展js配置
      let curRouterObj = Util.getRouterObjByName([otherRouter, ...appRouter], to.name, to.type);
      if (to.type) {
        const routeByType = appRouter.map((v) => {
          return v.type === to.type;
        });
        curRouterObj = Util.getRouterObjByName([otherRouter, ...routeByType], to.name, to.type);
      }
      if (curRouterObj) {
        next(); //
      } else {
        console.error('no router->' + JSON.stringify(to));
      }
    }
  } else {
    Util.goLogin();
    iView.LoadingBar.finish(); // router在hash模式下 手动改变hash 重定向回来 不会触发afterEach 暂时hack方案 ps：history模式下无问题，可删除该行！
    // }
  }
});

router.afterEach((to) => {
  // router.app.$store.dispatch('GetUserFunctionOptions');
  if (to.name !== 'login' && to.name !== 'register' && to.name !== 'forget' && to.name !== 'join' && to.name !== 'cusRegister' && to.name !== 'crmAuthPage') {
    // router.app.$store.dispatch('GetUserNewsCount');
    if (sessionStorage.isAgentSide !== 'true') {
      Util.openNewPage(router.app, to.name, to.params, to.query);
    }
  }
  iView.LoadingBar.finish();
  window.scrollTo(0, 0);
});
export default router;
