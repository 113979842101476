export default {
  name: 'VxeTableLoading',
  props: {
    visible: Boolean
  },
  render(h) {
    return h('div', {
      class: 'ivu-spin ivu-spin-large ivu-spin-fix',
      style: {
        display: this.visible ? 'block' : 'none'
      }
    }, [
      h('div', {
        class: 'ivu-spin-main'
      }, [
        h('span', { class: 'ivu-spin-dot' })
      ])
    ]);
  }
};
