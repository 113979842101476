<template>
  <div>
    <div v-if="flattenQueryschemaArr.length">
      <QuerySchema
        ref="queryschema"
        :b-refer="true"
        :biz-meta="cloneMeta"
        :filters="flattenQueryschemaArr"
        @on-queryschema-search="queryschemaSearch"
        @on-queryschema-loaded="queryschemaLoaded"></QuerySchema>
    </div>
    <div style="margin:0 0 13px 13px;">
      <Checkbox v-model="singleHasAuth" :disabled="singleHasAuthDisabled" @on-change="singleHasAuthChange">
        {{ mlang('onlyShowHaveAuth') }}{{ isUser ? mlang('tenant') : mlang('clientName') }}
        ({{ isUser ? mlang('tenantTip') : mlang('clientNameTip') }})
      </Checkbox>
    </div>
    <div>
      <Table
        ref="mainTable"
        :width="mainTableWidth"
        :columns="mainColumns"
        :data="scTableData"
        :loading="mainTableLoading"
        :height="height"
        @on-select-all="onTableSelectAll"
        @on-current-change="onTableChange"
        @on-selection-change="onTableSectionChange">
        <div slot="footer">
          <div style="float: right; margin-right:20px;">
            <Page
              :total="pager.totalCount"
              :current.sync="pager.currentPage"
              :page-size="pager.pageSize"
              :page-size-opts="pager.pageSizeOpts"
              show-sizer
              show-elevator
              show-total
              @on-change="changePage"
              @on-page-size-change="changePageSize"></Page>
          </div>
        </div>
      </Table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { deepCopy } from '@/u-components/utils/assist';
import CommonMixin from '@/mixin/common.js';
import Mixin from '@/mixin/archiveList.js';
import { saveReferAuth, getBatchCustomerAuthStatus, getBatchUserAuthStatus } from '@/api/auth/auth.js';

const prefixCls = 'ivu-select';
export default {
  name: 'CustomerAuthRefTable',
  mixins: [CommonMixin, Mixin],
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    billMeta: {
      default: {},
      type: Object
    },
    showInput: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      currentUser: this.$store.getters.currentUser,
      singleHasAuth: false,
      selectedRow: null,
      flattenQueryschemaArr: [],
      mainTableCheckedArr: [],
      queryschema: null,
      classid: null,
      cloneMeta: null,
      mainTableLoading: false,
      queryschemaObj: {},
      selectProduct: null,
      mainColumns: [],
      customerData: [],
      prefixCls: prefixCls,
      height: 0,
      modal: false,
      modalLoading: false,
      scTableData: [],
      permission: [],
      singleHasAuthDisabled: false,
      userOwnerIds: [],
      sendOrgId: '',
      pager: {
        pageIndex: 1,
        pageSize: 50,
        pageSizeOpts: [50, 100, 200],
        currentPage: 1,
        totalCount: 0
      },
      storePostData: {},
      mainTableWidth: 1099,
      saveSuccess: this.mlang('saveSuccess')

    };
  },
  computed: {
    isUser() {
      return this.cloneMeta.type === 6 || this.cloneMeta.type === 17;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.makeMeta();
      this.makeQueryschema();
      this.makeMainColumns();
    },
    changePage(page) {
      this.pager.pageIndex = page;
      this.loadProductData();
    },
    changePageSize(pageSize) {
      this.pager.pageSize = pageSize;
      this.loadProductData();
    },
    makeMeta() {
      this.cloneMeta = deepCopy(this.billMeta);
      this.sendOrgId = (this.currentUser.__isMutiOrg && !this.currentUser.__isBiz) ? this.cloneMeta.orgId : '';
    },
    queryschemaSearch(queryschema) {
      this.queryschema = queryschema;
      if (this.cloneMeta.type === 6 || this.cloneMeta.type === 17) {
        this.queryschema.conditions.push({
          conditionType: 'eq',
          name: 'userType',
          v1: this.cloneMeta.userType,
          valueType: 'SHORT'
        });
      }
      this.loadProductData(this.queryschema);
    },
    loadProductData(postData) {
      if (!postData) {
        postData = this.storePostData;
      } else {
        if (postData.conditions) {
          postData.conditions.map((v) => {
            if (this.cloneMeta.type === 6 || this.cloneMeta.type === 17) {
              if (v.name === 'person.fullName') {
                postData['userName'] = v.v1;
              }
              if (v.name === 'code') {
                postData['userCode'] = v.v1;
              }
            } else if (this.cloneMeta.type === 2 || this.cloneMeta.type === 19) {
              if (v.name === 'agent.name') {
                postData['agentName'] = v.v1;
              }
              if (v.name === 'agent.code') {
                postData['agentCode'] = v.v1;
              }
            }
            postData[v.name] = v.v1;
          });
          delete postData.conditions;
        }
        postData.objectId = this.cloneMeta.objectId;
        postData.orgId = this.sendOrgId;
        postData.type = this.cloneMeta.type;
        this.storePostData = postData;
      }
      postData.hasPermission = this.singleHasAuth;// 是否有权
      postData.pageIndex = this.pager.pageIndex;
      postData.pageSize = this.pager.pageSize;

      this.mainTableLoading = true;
      if (this.cloneMeta.type === 6 || this.cloneMeta.type === 17) {
        getBatchUserAuthStatus(postData).then((res) => {
          this.afterGetPersonList(res);
        });
      } else if (this.cloneMeta.type === 2 || this.cloneMeta.type === 19) {
        getBatchCustomerAuthStatus(postData).then((res) => {
          this.afterGetPersonList(res);
        });
      }
    },
    afterGetPersonList(res) {
      this.mainTableLoading = false;
      this.userOwnerIds = [];
      this.pager.totalCount = res.totalElements;
      this.customerData = res.content;
      this.scTableData = res.content;
      this.customerData.map((v, index) => {
        let permissionObj = v.permissionData;
        let disablePermission = false;

        if (this.cloneMeta.type === 6 || this.cloneMeta.type === 17) {
          this.userOwnerIds.push(v.userId);
          disablePermission = permissionObj ? permissionObj.adminGroup || (permissionObj.rolePermission && permissionObj.rolePermission.length > 0) : false;
        } else if (this.cloneMeta.type === 2 || this.cloneMeta.type === 19) {
          this.userOwnerIds.push(v.agentRelationId);
          disablePermission = permissionObj ? permissionObj.adminGroup || (permissionObj.groupPermission && permissionObj.groupPermission.length > 0) : false;
        }

        if (permissionObj) { // permissionData不为空代表有权限
          if (disablePermission) { // 管理员组 || groupPermission || rolePermission
            this.$set(v, 'oriChecked', true);
            this.$set(v, 'checked', true);
            this.$set(v, '_checked', true);
            // this.$set(v, '_fromPermision', v);
            this.$set(v, '_disabled', true);
            this.$set(v, 'disabled', true);
          } else if (permissionObj.userPermission && permissionObj.userPermission.length > 0) {
            this.$set(v, 'oriChecked', true);
            this.$set(v, 'checked', true);
            this.$set(v, '_checked', true);
            v._fromPermision = deepCopy(v);
            this.onTableChange(v, true);
          }
        }
      });
    },
    makeQueryschema() {
      let queryschemas = deepCopy(this.billMeta.queryschema);
      this.flattenQueryschemaArr = queryschemas.filter((v) => {
        return v.isHidden !== true;
      });
    },

    getQueryschemaData() {
      const schema = this.$refs.queryschema.getQueryschemaRealData();
      if (this.$refs.queryschema.bizMeta.passedparams) {
        schema.conditions = this.$refs.queryschema.bizMeta.passedparams;
      }
      return schema;
    },
    queryschemaLoaded() {
      this.height = 420;
      this.queryschema = this.getQueryschemaData();
      if (this.sendOrgId) {
        this.queryschema.conditions = [{
          name: 'orgId', conditionType: 'eq', valueType: 'LONG', v1: this.sendOrgId
        }];
      }
      if (this.cloneMeta.type === 6 || this.cloneMeta.type === 17) {
        this.queryschema.conditions.push({
          conditionType: 'eq',
          name: 'userType',
          v1: this.cloneMeta.userType,
          valueType: 'SHORT'
        });
      }
      this.loadProductData(this.queryschema);
    },
    makeMainColumns() {
      const noWidthColumn = {
        key: 'nowidth',
        title: ''
      };
      const selectionColumn = {
        type: 'selection',
        width: 40,
        align: 'center',
        disabledFn() {
          return false;
          // if (vm.cloneMeta.orgId) {
          //   return false;
          // } else {
          //   return true;
          // }
        }
      };
      if (this.cloneMeta.multiple) {
        this.cloneMeta.columns = [selectionColumn, ...this.cloneMeta.columns, noWidthColumn];
      } else {
        this.cloneMeta.columns = [this.c_radioColumn, ...this.cloneMeta.columns, noWidthColumn];
      }
      this.mainColumns = this.cloneMeta.columns;
    },
    setStatus(rowData) {
      const oriChecked = !!rowData.oriChecked;
      const checked = rowData.checked;
      const item = this.cloneMeta.item;
      let ownerId = '';
      if (this.cloneMeta.type === 6 || this.cloneMeta.type === 17) { // 授权给人员
        ownerId = rowData.userId;
      } else if (this.cloneMeta.type === 2 || this.cloneMeta.type === 19) { // 授权给客户
        ownerId = rowData.agentRelationId;
      }
      const entity = {
        leftMainId: item.rowData.id,
        classId: item.rowData.productClassId,
        objectId: item.rowData.id,
        orgId: this.sendOrgId,
        ownerId: ownerId,
        type: this.cloneMeta.permissionType,
        actionStatus: 'C'
      };
      let isAdd = checked && !oriChecked;
      let idDel = !checked && oriChecked;

      if (rowData._fromPermision) {
        entity.id = rowData._fromPermision.permissionData.userPermission[0].id;
        entity.version = rowData._fromPermision.permissionData.userPermission[0].version;
        if (idDel) {
          entity.actionStatus = 'D';
        } else if (isAdd) {
          entity.actionStatus = 'U';
        }
      }
      if (isAdd || idDel) {
        this.permission.push(entity);
      }
    },
    onTableChange(currentRow, status) {
      if (status) {
        currentRow.checked = true;
        currentRow._checked = true;
        // 增加判断 如果原有权限 去掉勾选 又勾选需要removePermission
        if ((this.cloneMeta.type === 2 || this.cloneMeta.type === 19) && currentRow.oriChecked) {
          this.removePermission(currentRow.agentRelationId);
        } else if ((this.cloneMeta.type === 6 || this.cloneMeta.type === 17) && currentRow.oriChecked) {
          this.removePermission(currentRow.userId);
        }
        this.setStatus(currentRow);
      } else {
        currentRow.checked = false;
        currentRow._checked = false;

        if (currentRow.oriChecked) {
          this.setStatus(currentRow);
        } else {
          if (this.cloneMeta.type === 2 || this.cloneMeta.type === 19) {
            this.removePermission(currentRow.agentRelationId);
          } else if (this.cloneMeta.type === 6 || this.cloneMeta.type === 17) {
            this.removePermission(currentRow.userId);
          }
        }
        // eslint-disable-next-line no-console
        // console.log(this.permission);
      }
    },
    onTableSectionChange(selection, row, status) {
      if (selection.length !== 0) {
        row.map((v) => {
          if (!v.disabled) {
            this.onTableChange(v, status);
          }
        });
      }
    },
    onTableSelectAll(selections, status) {
      if (status) {
        this.customerData.map((v) => {
          this.onTableChange(v, true);
          v._checked = true;
        });
      } else {
        if (selections.length === 0) {
          this.customerData.map((v) => {
            this.onTableChange(v, false);
          });
        }
      }
    },

    removePermission(id) {
      const arr = deepCopy(this.permission) || [];
      _.remove(arr, (v) => {
        return v['ownerId'] === id;
      });
      this.permission = deepCopy(arr) || [];
    },
    getSelectRow() {
      let submitData = {
        ownerId: this.cloneMeta.ownerId ? this.cloneMeta.ownerId : this.userOwnerIds[0],
        type: this.cloneMeta.type,
        orgId: this.sendOrgId,
        permission: this.permission,
        autoCreateData: []
      };
      saveReferAuth(submitData).then((res) => {
        this.permission = [];
        this.$Message.success({
          content: this.saveSuccess,
          onClose: () => {
            this.$emit('saveok');
          }
        });
      });
      return false;
    },
    singleHasAuthChange(status) {
      this.singleHasAuth = status;
      this.pager.currentPage = 1;
      this.changePage(1);
    }
  }
};
</script>
<style lang="less" scoped>
/deep/.ivu-table-wrapper {
  .ivu-table-footer{
    height: 48px
  }
}
/deep/.main-body{
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
