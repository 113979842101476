import { selectSrcBillNo } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const srcBillRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      title: vm.mlang('orders.srcBillName'),
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      fieldName: 'srcBillId',
      refReturnValue: 'id',
      refName: 'name',
      queryschema: [
        {
          name: 'keyword',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          conditionType: 'eq',
          isShowIt: true,
          placeholder: vm.mlang('orders.srcNameSrcCode')
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('orders.srcName'),
          isShowIt: true,
          fieldName: 'name',
          width: 120
        },
        {
          showCaption: vm.mlang('orders.srcCode'),
          fieldName: 'code',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('orders.salesOrgName'),
          isShowIt: true,
          width: 150,
          fieldName: 'salesOrgName'
        },
        {
          showCaption: vm.mlang('orders.srcNameSrcStatus'),
          isShowIt: true,
          width: 150,
          fieldName: 'contractStatus',
          render: (h, params) => {
            if (params.row.contractStatus === 5) {
              return <span>{vm.mlang('orders.inForce')}</span>;
            } else if (params.row.contractStatus === 6) {
              return <span>{vm.mlang('orders.onFrozen')}</span>;
            } else if (params.row.contractStatus === 7) {
              return <span>{vm.mlang('orders.onTerminated')}</span>;
            }
          }
        },
        {
          showCaption: vm.mlang('orders.srcPlanEffectiveDate'),
          isShowIt: true,
          width: 150,
          fieldName: 'planEffectiveDate'
        }
      ],
      actions: { queryList: selectSrcBillNo }
    },
    ctrl
  );
  return deepCopy(control);
};
export default srcBillRefer;
