export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500036E","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500036F","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000370","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000371","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000372","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000373","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000374","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000375","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000376","停用") /* "停用" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000377","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000378","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000379","查看执行情况") /* "查看执行情况" */,
  batchOpposeWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500037A","请选择至少一条状态为停用的信息") /* "请选择至少一条状态为停用的信息" */
};
