import ajax from '@/libs/ajax';

export function getAccountMenu() {
  return ajax.request({
    url: '/archives/menu/getAccountMenu?isArchive=1',
    method: 'get'
  }).then(res => {
    res && res.map(v => {
      v.key = v.url.replace('#/', '');
      if (v.url === '#/mutiAgentRoleTabs') {
        v.tabs = [{
          'isOpen': true,
          'name': lang.templateByUuid('UID:P_UDHWN_17DD50DE05000973', '功能权限'),
          'url': '#mutiAgentAuthMngOfRole'
        }];
      }
      if (v.tabs && v.tabs.length) {
        v.tabs.map(t => {
          t.key = t.url.replace('#', '');
        });
      }
    });
    return res;
  });
}
export function getUserCenter() {
  return ajax.request({
    url: '/passport/yht/usercenter?isArchive=1',
    method: 'get'
  });
}
export function loginByUsername(username, password) {
  // const data = {
  //   username,
  //   password
  // };
  var dddd = 123;
  return ajax.request({
    url: '/login/login',
    method: 'post',
    data: { tyt: dddd }
  });
}

export function getUserFunctionOptions() {
  return ajax
    .request({
      url: '/pc/FunctionOptionSrv/getFunctionOptionsAll',
      method: 'get'
    })
    .then((res) => {
      const result = {};
      if (res) {
        for (const key in res) {
          let value = res[key];
          if (value === 'false') {
            value = false;
          } else if (value === 'true') {
            value = true;
          }
          result[key] = value;
        }
      }
      return result;
    });
}

// 用于获取显示于页面头部的消息数
export function getNumbersOfMessage() {
  return ajax.request({
    url: '/messagecenter/bulletin/unreadcount',
    // url: '/pc/BulletinSrv/getBulletinCountByUserIdService',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
}
// 用于获取显示于页面头部消息
export function getMessages() {
  return ajax.request({
    url: '/messagecenter/bulletin/pagelist',
    // url: '/pc/BulletinSrv/getBulletinsForNewForCommonService?count=3',
    method: 'get',
    data: {
      pagesize: 3,
      pageindex: 1,
      isread: false,
      isArchive: 1
    }
  });
}
export function getMessagesUnreadCount() {
  return ajax.request({
    url: '/messagecenter/bulletin/unreadcount',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
}
// 用于获取消息和消息数
export function getUserNewsCount() {
  const NumbersOfMessageService = getMessages();
  const messageUnreadCount = getMessagesUnreadCount();
  // const MessagesService = getMessages();
  return Promise.all([NumbersOfMessageService, messageUnreadCount]);
}

export function logout() {
  return ajax.request({
    url: '/login/logout',
    method: 'post'
  });
}

export function getCurrentUserInfo() {
  return ajax.request({
    url: '/passport/user/context',
    method: 'get',
    data: { isArchive: 1 }
  });
}

// 0 企业端 1 代理商端
export function getMenuList(pos) {
  return ajax.request({
    url: `/archives/menu/${pos}/menus`,
    method: 'get',
    data: { isArchive: 1 }
  });
}

export function getUserOrgs() {
  return ajax.request({
    url: '/orginazation/org/user/list',
    method: 'get',
    data: { isArchive: 1 }
  });
}

export function switchLogin(userId) {
  return ajax.request({
    url: `/passport/user/${userId}/switch`,
    data: {
      isArchive: 1
    },
    method: 'get'
  });
}

export const VerificationCode = (validCodeUrl, captcha, isArchive = 1) => {
  return ajax.request({
    url: validCodeUrl,
    method: 'post',
    data: {
      isArchive,
      datatype: 'json',
      data: captcha
    }
  });
};

export const onOk = (data, okurl, method = 'post') => {
  return ajax.request({
    url: okurl,
    method,
    data
  });
};

// 已废弃
export const goActive = (data) => {
  return ajax.request({ url: '/pc/corp/CorprationSrv/activeAction', method: 'post', data });
};

export const saveUser = (data) => {
  return ajax.request({
    url: '/passport/user',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const delUser = (id) => {
  return ajax.request({
    url: `/passport/user/${id}`,
    method: 'delete',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const userDetail = (id) => {
  return ajax.request({
    url: `/passport/user/${id}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const getOperators = (data) => {
  return ajax
    .request({
      url: '/passport/operators',
      method: 'post',
      data: {
        data: data.querySchema,
        isArchive: 1,
        datatype: 'json'
      }
    })
    .then((res) => {
      res.total = res.totalElements;
      res.content.map((v) => {
        v.id = v.userId;
        v.version = v.userVersion;
      });
      return res;
    });
};

export const getUserPositions = (userId) => {
  return ajax.request({
    url: `/passport/user/${userId}/positions`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const batchSaveUser = (userId, data) => {
  return ajax.request({
    url: `/passport/user/${userId}/positions`,
    method: 'post',
    data: {
      data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};
// 获取yht用户信息
export const yhtUserInfo = (userId) => {
  return ajax.request({
    url: `/passport/yhtuser/${userId}`,
    method: 'get',
    data: {
      isArchive: 1,
      nodeal: true
    }
  });
};

/**
 * 根据personId获取用户信息
 * @param personId
 * @return
 */
export const getUsersByPersonId = (personId) => {
  return ajax.request({
    url: `/passport/person/${personId}/users`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const checkUserByMobile = (data) => {
  return ajax.request({
    url: `/passport/person/validkey/${data.mobile}`,
    method: 'get',
    data: {
      isArchive: 1,
      countryCode: data.countryCode
    }
  });
};

/**
 * 获取当前用户主题
 */
export const getCorprationTheme = () => {
  return ajax.request({
    url: '/passport/tenant/themes?isArchive=1',
    method: 'get'
  });
};
export const getDefineTheme = (data) => {
  return ajax.request({
    url: '/passport/getThemes?isArchive=1&isPhone=0',
    method: 'get',
    data
  });
};

// 检验客户私有用户是否达到上限
/**
 * @method checkAgentUserUpperLimit 废弃
 */
// export const checkAgentUserUpperLimit = () => {
//   return ajax.request({
//     url: '/pc/agent/AgentSrv/getAgentUserSize',
//     method: 'get'
//   });
// };

// 订货端修改密码
export const changePassword = (data) => {
  return ajax.request({
    url: '/passport/current/password/change',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 订货端账户安全，手机/邮箱验证获取校验码
export const getValidCode = (clientNo, data) => {
  return ajax.request({
    url: `/captcha/verify/send/${clientNo}/verifyuser?isArchive&countryCode=${data.countryCode}`,
    method: 'post',
    data: {
      data: data.checkCode,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const getValidRegisterCode = (clientNo, data) => {
  return ajax.request({
    url: `/captcha/verify/send/${clientNo}/agentjoin?isArchive&countryCode=${data.countryCode}`,
    method: 'post',
    data: {
      data: data.checkCode,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const checkPhoneOk = (phone, data) => {
  return ajax.request({
    url: `/captcha/verify/check/${phone}/agentjoin?countryCode=${data.countryCode}`,
    method: 'post',
    data: {
      data: data.validCode,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 订货端账户安全，手机验证
export const phoneOk = (phone, data) => {
  return ajax.request({
    url: `/passport/current/phone/${phone}/verify?countryCode=${data.countryCode}`,
    method: 'post',
    data: {
      data: data.validCode,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 订货端账户安全，邮箱验证获取校验码
export const emailOk = (email, data) => {
  return ajax.request({
    url: `/passport/current/email/${email}/verify`,
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 人员列表，停用启用方法
export const userChangeState = (id, activate, linkage) => {
  return ajax.request({
    url: `/passport/user/${id}/${activate}/${linkage}/activate`,
    method: 'post',
    data: {
      isArchive: 1
    }
  });
};

// 获取订货端客户信息
export const getAgentInfoVo = (upAgentRelationId) => {
  return ajax.request({
    url: `/agent/infrastructure/${upAgentRelationId}/agentinfo`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 获取云客服参数
export const getCloudServiceParams = () => {
  return ajax.request({
    url: 'thirdparty/contactCloud/getContactCloudParams',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 获取订货端客服信息
export const getCustomerService = () => {
  return ajax.request({
    url: '/archives/contactway/list/agent',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
