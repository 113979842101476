import { getAgentLevel } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const agentLevelRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      title: vm.mlang('agentName'),
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      fieldName: 'agentLevelId',
      controlType: 'Refer',
      refReturnValue: 'agentLevelId',
      reffieldName: 'name',
      refName: 'name',
      queryschema: [],
      columns: [
        {
          showCaption: vm.mlang('agentLevel'),
          isShowIt: true,
          fieldName: 'name',
          width: 120
        },
        {
          showCaption: vm.mlang('levelCode'),
          isShowIt: true,
          fieldName: 'code',
          width: 120
        }
      ],
      actions: { queryList: getAgentLevel },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default agentLevelRefer;
