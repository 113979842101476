export default {
  changeStateWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000753","不能关闭全部规则") /* "不能关闭全部规则" */,
  delRuleContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000754","确定删除该规则吗？") /* "确定删除该规则吗？" */,
  delRuleSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000755","删除成功!") /* "删除成功!" */,
  changeRuleStateContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000756","确定要开启这个规则吗？") /* "确定要开启这个规则吗？" */,
  batchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000757","请选择至少一条状态为停用的规则") /* "请选择至少一条状态为停用的规则" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000758","请选择规则") /* "请选择规则" */,
  yuan: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000759","元") /* "元" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500075A","请正确填写!") /* "请正确填写!" */,
  MOSTPERCENTAGE: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500075B","不超过总订货金额") /* "不超过总订货金额" */,
  LEASTORDERMONEY: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500075C","订货金额达到") /* "订货金额达到" */,
  LEASTORDERMONEY1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500075D","元,才可使用费用") /* "元,才可使用费用" */,
  MOSTREBATEMONEY: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500075E","使用的费用额不超过") /* "使用的费用额不超过" */

};
