import { delPayment, backPayment, confirmPayment, opposePayment, checkPaymentStatus, deblockPayMent } from '@/api/payment';
const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {},

  methods: {
    mixin_del: (params, vm) => {
      vm.$Modal.confirm({
        content: vm.mlang('delContent'),
        onOk: () => {
          const postData = {
            paymentId: params.row.id
          };
          delPayment(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_backPay: (params, vm) => {
      vm.$Modal.confirm({
        content: vm.mlang('backPayContent'),
        onOk: () => {
          const postData = {
            paymentId: params.row.id
          };
          backPayment(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_oppose: (params, vm) => {
      vm.$Modal.prompt({
        content: vm.mlang('opposeContent'),
        tip: vm.mlang('opposeTip'),
        okText: vm.mlang('sure'),
        cancelText: vm.mlang('cancel'),
        title: vm.mlang('opposeTitle'),
        onOk: (res, instance) => {
          const postData = {
            paymentId: params.row.id,
            cMemo: res
          };
          if (!res) {
            vm.$Message.error(vm.mlang('opposeError'));
            return false;
          }
          opposePayment(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.dispatch('VoucherList', 'reload');
            instance.remove();
          });
        }
      });
    },
    mixin_sure: (params, vm) => {
      vm.$Modal.confirm({
        content: vm.mlang('sureContent'),
        onOk: () => {
          const postData = {
            paymentId: params.row.id,
            cOrderNo: params.row.cOrderNo
          };
          confirmPayment(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_checkPayStatus: (params, vm) => {
      const cPayNo = params.row.cPayNo;
      checkPaymentStatus(cPayNo).then((data) => {
        let payStatus = data.paymentStatusEnum;
        let msg = data.message;
        if (msg) vm.$Message.warning(msg);
        if (payStatus === 2) vm.dispatch('VoucherList', 'reload');
        // if (payStatus === 3) params.row.isShowDeletedBtn = true;
      });
    },
    // 解锁
    mixin_payMentDeblockOrder(params, vm) {
      let cPayNo = params.cPayNo;
      vm.$Modal.confirm({
        title: '',
        content: this.mlang('paymentsDeblockTip'),
        onOk: () => {
          deblockPayMent(cPayNo).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    }
  }
};

export default mixin;
