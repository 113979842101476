export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000047","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000048","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000049","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500004A","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500004B","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500004C","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500004D","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500004E","审核") /* "审核" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500004F","弃审") /* "弃审" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000050","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000051","复制") /* "复制" */,
  chgContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000052","变更") /* "变更" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000053","查看执行情况") /* "查看执行情况" */,
  switchBlackAndWhiteListPrompt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000054","确定要切换黑白名单") /* "确定要切换黑白名单" */,
  whitelistedGoods: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000055","白名单商品") /* "白名单商品" */,
  blacklistedGoods: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000056","黑名单商品") /* "黑名单商品" */,
  rebateMoneyNumTips: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000057","费用金额必须大于0") /* "费用金额必须大于0" */
};
