import deliverys from './deliverys';
import rebateRuleList from './rebateRuleList';
import orders from './orders';
import salereturns from './salereturns';
import expenses from './expenseList';
import presaleOrders from './presaleOrders';
export default {
  orderManager: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B8","订单管理") /* "订单管理" */,
  CONFIRMORDER: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006B9","待确认") /* "待确认" */,
  DELIVERGOODS: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006BA","待发货") /* "待发货" */,
  TAKEDELIVERY: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006BB","待收货") /* "待收货" */,
  ENDORDER: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006BC","已完成") /* "已完成" */,
  OPPOSE: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006BD","已取消") /* "已取消" */,
  NOTPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006BE","未付款") /* "未付款" */,
  PARTPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006BF","部分付款") /* "部分付款" */,
  CONFIRMPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C0","部分付款待确认") /* "部分付款待确认" */,
  CONFIRMPAYMENT_ALL: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C1","付款待确认") /* "付款待确认" */,
  FINISHPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C2","已付款") /* "已付款" */,
  noLogistic: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C3","暂时查询不到物流信息哦~") /* "暂时查询不到物流信息哦~" */,
  saleOrder: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C4","销售订单") /* "销售订单" */,
  delNoInvProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C5","删除无货商品") /* "删除无货商品" */,
  wholeOrderGiveProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C6","整单赠品") /* "整单赠品" */,
  selectAccumulateGiveProduct: lang.templateByUuid("UID:P_UDHWN_18F6B51404F80005","赠品替换") /* "赠品替换" */,
  selectProductPackage: lang.templateByUuid("UID:P_UDHWN_18F6B51404F80006","确认商品") /* "确认商品" */,
  sureDelNoStock: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C8","您确认删除库存不足的商品或组合吗?") /* "您确认删除库存不足的商品或组合吗?" */,
  gift: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006C9","赠品") /* "赠品" */,
  shuchuSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006CA","输出成功") /* "输出成功" */,
  gaidingdanzhifufsweikuandaoqingzhifu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006CB","该订单的支付方式为款到发货,请及时支付!") /* "该订单的支付方式为款到发货,请及时支付!" */,
  gaidingdanzhifufsweikuandaodengdaiqueren: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006CC","该订单的支付方式为款到发货,已付款项正等待企业确认!") /* "该订单的支付方式为款到发货,已付款项正等待企业确认!" */,
  zijinzhanghu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006CD","资金账户") /* "资金账户" */,
  chakan: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006CE","查看") /* "查看" */,
  presale: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006CF","预售") /* "预售" */,
  calcpromotionWarnning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D0","请再次确认订单信息") /* "请再次确认订单信息" */,
  delOrderSure: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D1","确认要删除此订单吗?") /* "确认要删除此订单吗?" */,
  yufukuanzhanghuzhong: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D2","预付款账户中,可以在") /* "预付款账户中,可以在" */,
  fanlizhanghuzhong: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D3","费用账户中,可以在") /* "费用账户中,可以在" */,
  yufukuanhefanlizhanghuzhong: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D4","预付款账户和费用账户中,可以在") /* "预付款账户和费用账户中,可以在" */,
  dingdanjineyizhuahyidaokehud: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D5","订单付款金额已转到客户的") /* "订单付款金额已转到客户的" */,
  yingfuzhengdanzongjined: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D6","应付整单总金额的") /* "应付整单总金额的" */,
  elsezidongbohui: lang.templateByUuid("UID:P_UDHWN_17DD50DE050006D7","否则订单自动驳回") /* "否则订单自动驳回" */,
  orders,
  deliverys,
  rebateRuleList,
  salereturns,
  expenses,
  presaleOrders
};
