<template>
  <div class="header-box">
    <a class="header-logo">
      <img
        :src="currentUser.corpLogo"
        :class="{'img-width':imgWidth,'img-height':!imgWidth }"
        alt="logo" />
    </a>
    <div class="tag-nav-wrapper">
      <tags-nav @input="handleClick" @on-close="handleCloseTag" @refresh="refreshCurrent" />
    </div>

    <div class="menu-right">
      <div class="header-inline-center caretHover">
        <Poptip :transfer="false" offset="56" content="content" trigger="hover" placement="bottom">
          <p :title="clientValue">
            <span
              :class="currentUser.isBiz?'merchants':(currentUser.isAgent?'client':'enterprise')"
              class="header-user-type">{{ clientKey }}</span>
            {{ currentUser.fullName }}
            <span>
              <i class="ivu-icon ivu-icon-arrow-down-b ivu-select-arrow"></i>
            </span>
          </p>
          <div slot="content">
            <div class="switch-account js-switch-account">
              <div class="list-block media-list">
                <ul class="new-switch-account">
                  <li
                    :class="currentUser.isBiz?'merchants':(currentUser.isAgent?'client':'enterprise')">
                    <i
                      v-if="switchAccountData && switchAccountData.length"
                      class="icon current-user-icon"></i>
                    <div
                      :class="['headerName',{'oneHeaderName':!(switchAccountData && switchAccountData.length) }]">
                      <p :title="clientValue">
                        <em>{{ clientValue }}</em>
                        <span>{{ clientKey }}</span>
                      </p>
                      <p class="client">
                        {{ currentUser.fullName }}
                      </p>
                      <!-- <a
                        class="account-manager"
                        href="/agentpage/Users/account?menuid=account#userbase">
                        {{ mlang('myAccount') }}</a> -->
                      <!-- <a
                        class="account-manager"
                        @click="goToUserBase">{{ mlang('myAccount') }}</a> -->
                    </div>
                  </li>
                  <template v-if="switchAccountData && switchAccountData.length">
                    <li
                      v-for="user in switchAccountData"
                      :key="user.id"
                      :class="user.isBiz?'merchants':(user.isAgent?'client':'enterprise')"
                      @click="switchUser(user)">
                      <div class="headerName">
                        <p :title="clientValue">
                          <em>{{ user.showName }}</em>
                          <span>{{ type(user) }}</span>
                        </p>
                        <p class="client">
                          {{ user.fullName }}
                        </p>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </Poptip>
      </div>
      <ActivationAccounts></ActivationAccounts>
      <Modal v-model="isModel" :styles="{top: '50px'}" :title="mlang('Verification')">
        <div slot="close">
          <i class="ivu-icon ivu-icon-ios-close-empty" @click="onCancel"></i>
        </div>
        <form class="headers-form" action>
          <p class="Verification">
            <label for>{{ onOkData.validateType ==2 ? mlang('mailbox'): mlang('mobile') }}</label>
            <Input
              :placeholder="placeholder"
              :disabled="true"
              style="width: 300px;"></Input>
          </p>
          <p class="Verification">
            <label for>
              <span class="left">*</span>
              {{ mlang('VerificationCode') }}
            </label>
            <Input
              v-model="postData.captcha"
              :placeholder="mlang('VerificationCodePrompt')"
              :maxlength="18"
              style="width: 200px;"
              @on-focus="IsVerificationCode"></Input>

            <img :title="mlang('ImgPrompt')" :src="checkcodeimgurl" @click="getNewCheckCodeImage" />
          </p>
          <p class="Verification">
            <label for>
              <span class="left">*</span>
              {{ mlang('ValidationCode') }}
            </label>
            <Input
              v-model="onOkData.validCode"
              :maxlength="18"
              :placeholder="mlang('ValidationCodePrompt')"
              style="width: 200px;"></Input>

            <a v-if="isSwitch" @click="sendReviewCode">{{ mlang('ObtainVerificationCode') }}</a>
            <span v-if="!isSwitch" class="sendReviewmsg">{{ cue }}</span>
          </p>
          <p v-if="isPrompt" class="Prompt">
            {{ mlang('Prompt') }}
          </p>
        </form>
        <div slot="footer">
          <Button type="text" class="cancel-btn" @click="onCancel">
            {{ mlang('cancel') }}
          </Button>
          <Button type="primary" size="large" @click="onOk">
            {{ mlang('sure') }}
          </Button>
        </div>
      </Modal>
      <SelectAgent
        ref="selectAgent"
        :multiple="selectAgentMutiple"
        @on-selected="onSelectedAgent"></SelectAgent>
      <div
        v-if="$hasAuth('insteadOrder')"
        class="go-order-container">
        <div
          :title="mlang('goAgent')"
          class="go-order-client"
          @click="selectAgent">
          <span>{{ mlang('switchText') }}</span>
          <!-- <SvgIcon icon-class="switch-agent"></SvgIcon> -->
        </div>
      </div>
      <MsgPush class="header-message"></MsgPush>
      <language style="margin-top: 19px;margin-left: 20px;" />
      <div class="header-update-log">
        <Poptip
          :transfer="false"
          offset="12"
          content="content"
          trigger="hover"
          placement="bottom-end">
          <span class="span-cursor">
            <Icon type="more" size="22" />
          </span>
          <div slot="content">
            <!-- <p class="header-exact">
              <a href="https://api.udinghuo.cn/@documentation/zh/home" target="_blank">
                <Icon class-name="international-icon" custom="iconfont icon-gengxinrizhi"></Icon>
                {{ mlang('updateLog') }}
              </a>
            </p> -->
            <p class="header-exact">
              <a
                href="javascript:void(0)"
                @click="toAgentAccount()">
                <Icon class-name="international-icon" type="logout"></Icon>
                {{ mlang('title.base') }}
              </a>
            </p>
            <p v-if="isShowOrderSystem" class="header-exact">
              <!--工单入口-->
              <a @click.prevent="openWorkOrderSystem">
                <Icon class-name="international-icon" custom="iconfont icon-work-order"></Icon>
                {{ mlang('workOrderSystem') }}
              </a>
            </p>
            <p class="header-exact">
              <a href="/logout">
                <Icon class-name="international-icon" type="logout"></Icon>
                {{ mlang('logoutSystem') }}
              </a>
            </p>
            <!-- <p class="header-phone">010-86393388</p> -->
          </div>
        </Poptip>
      </div>
      <!-- <language
        v-if="isMultiLang"
        :lang="lang"
        :langs="langs"
        style="margin-right: 10px;"
        @on-lang-change="changeLocal" /> -->
      <!-- <language
        v-if="$config.useI18n"
        :lang="local"
        style="margin-right: 10px;"
        @on-lang-change="changeLocal"/> -->
      <!-- <div class="header-logout">
        <P>
          <a
            href="/logout"
            title="退出">{{ mlang('logout') }}</a>
        </P>
      </div>-->
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { getAuth, switchAccount } from '@/api/auth';
import { switchLogin, VerificationCode, onOk } from '@/api/user';
import { createWorkOrder } from '@/api/workOrderSystem';
import MsgPush from '@/components/msg-push/msg-push';
import SelectAgent from '@/components/select-agent/select-agent.vue';
import Language from '@/components/language';
import ActivationAccounts from '@/components/activation-accounts/activation-accounts.vue';
import UpdateLog from '@/components/update-log/update-log.vue';
import TagsNav from './tags-nav';
import util from '@/libs/util.js';
import yiguan from '@/libs/yiguan.js';

// import { findComponentUpward } from '@/u-components/utils/assist';
export default {
  name: 'CorpHeader',
  inject: ['reloadPage'],
  components: {
    MsgPush,
    TagsNav,
    SelectAgent,
    UpdateLog,
    ActivationAccounts,
    Language
  },
  data() {
    return {
      insteadOrder: this.$hasAuth('insteadOrder'),
      selectedAgent: {},
      selectAgentMutiple: false,
      selectAgenetShow: false,
      imgError: 'this.src="' + require('_images/defaultAvater.png') + '"',
      offset: 16,
      msgPushData: [],
      switchAccountData: [],
      openPageArr: [
        {
          name: '页签一',
          url: '/v#/corp/order/order',
          active: true
        }
      ], // 储存用户打开的页签
      cue: '',
      isModel: false,
      placeholder: null,
      isSwitch: true,
      isPrompt: false,
      checkcodeimgurl: `/file/captcha/image/show/${this.getDeviceUUid()}/verifyuser?isArchive=1&r=${Math.random()}`,
      okUrl: '',
      postData: {
        captcha: null,
        contactWay: null,
        phone: null
      },
      onOkData: {
        validCode: null,
        userId: null,
        email: null,
        phone: null,
        key: null
      },
      timeMark: 120,
      clientKey: null,
      clientValue: null,
      imgWidth: true // logo宽度是否大于高度
    };
  },
  // mixins: [AgentMixin],
  computed: {
    ...mapGetters(['tagNavList', 'isMultiLang']),
    cacheList() {
      return ['ParentView', ...(this.tagNavList.length ? this.tagNavList.filter((item) => !(item.meta && item.meta.notCache)).map((item) => item.name) : [])];
    },
    tagRouter() {
      return this.$store.state.app.tagRouter;
    },
    currentUser() {
      let _this = this;
      var img = new Image();
      img.src = this.$store.getters.currentUser.corpLogo;
      img.onload = function() {
        if (img.width / img.height < 142 / 60) {
          _this.imgWidth = false;
        } else {
          _this.imgWidth = true;
        }
      };
      return this.$store.getters.currentUser;
    },
    theme() {
      return this.$store.state.app.themeColor;
    },
    local() {
      return this.$store.state.app.local;
    },
    isShowOrderSystem() {
      return !(this.currentUser.isBiz || this.currentUser.isAgent) && (!this.currentUser.licenseLevel.includes('FREE'));
    }
  },
  watch: {
    theme: (val, oldVal) => {
      console.log('new: %s, old: %s', val, oldVal); // eslint-disable-line
    },
    $route(newRoute) {
      // const { name, query, params, meta } = newRoute;
      // this.addTag({
      //   route: { name, query, params, meta },
      //   type: 'push'
      // });
      // this.setTagNavList(util.getNewTagList(this.tagNavList, newRoute));
    }
  },
  mounted() {
    /**
     * @description 初始化设置面包屑导航和标签导航
     */
    // this.setTagNavList();
    // this.addTag({
    //   route: this.$store.state.app.homeRoute
    // });
  },

  created() {
    this.switchAccount();
    if (this.currentUser.isBiz) {
      // 商家端
      this.clientKey = this.mlang('bizLogo');
      this.clientValue = this.currentUser.bizName;
    } else if (this.currentUser.isAgent) {
      // 订货端
      this.clientKey = this.mlang('agentLogo');
      this.clientValue = this.currentUser.agentName;
    } else {
      // 企业端
      this.clientKey = this.mlang('corpLogo');
      this.clientValue = this.currentUser.bizName;
    }
  },
  methods: {
    ...mapMutations(['setBreadCrumb', 'setTagNavList', 'addTag', 'setHomeRoute', 'setLocal']),
    /**
     * @param {Array} routers 路由列表数组
     * @description 用于找到路由列表中name为home的对象
     */
    getHomeRoute(routers, homeName = 'corp_home_index') {
      let i = -1;
      let len = routers.length;
      let homeRoute = {};
      while (++i < len) {
        let item = routers[i];
        if (item.children && item.children.length) {
          let res = this.getHomeRoute(item.children, homeName);
          if (res.name) return res;
        } else {
          if (item.name === homeName) homeRoute = item;
        }
      }
      return homeRoute;
    },
    /**
     * 往前找，打开前一个
     */
    getPrevRoute(list, route) {
      let res = {};
      if (list.length === 2) {
        res = this.getHomeRoute(list);
      } else {
        const index = list.findIndex((item) => item.meta.uuid === route.meta.uuid);
        if (index === list.length - 1) {
          res = list[list.length - 2];
        } else {
          res = list[index - 1];
        }
      }
      return res;
    },

    turnToPage(route) {
      let { name, params, query, meta } = {};
      if (typeof route === 'string') name = route;
      else {
        name = route.name;
        params = route.params;
        query = route.query;
        meta = route.meta;
      }
      if (name.indexOf('isTurnByHref_') > -1) {
        window.open(name.split('_')[1]);
        return;
      }
      this.$router.push({
        name,
        params,
        query,
        meta
      });
    },
    handleClick(item) {
      this.turnToPage(item);
    },
    handleCloseTag(res, type, route) {
      let eqMethod = util.routeEqualNew;
      if (this.$getBO('MANYTAB')) {
        eqMethod = util.routeEqual;
      }
      if (type === 'all') {
        this.turnToPage(this.$config.homeName);
      } else if (eqMethod(this.$route, route)) {
        if (type !== 'others') {
          const nextRoute = this.getPrevRoute(this.tagNavList, route);
          this.$router.push(nextRoute);
        }
      }
      // const main = findComponentUpward(this, 'CorpMain');

      this.setTagNavList(res);
    },
    // 刷新当前页
    refreshCurrent(res, route, item) {
      this.reloadPage();
    },

    type(user) {
      let type = '';
      if (user.isBiz) {
        type = this.mlang('bizLogo');
      } else if (user.isAgent) {
        type = this.mlang('agentLogo');
      } else {
        type = this.mlang('corpLogo');
      }
      return type;
    },
    typeShowName(user) {
      let type = '';
      if (user.isBiz) {
        // 商家端
        type = this.mlang('personnel');
      } else if (user.isAgent) {
        // 订货端
        type = this.mlang('clientName');
      } else {
        // 企业端
        type = this.mlang('tenant');
      }
      return type;
    },
    selectAgent() {
      this.$refs.selectAgent.showRefModal();
    },
    onSelectedAgent(row) {
      this.$util.onSelectedAgent(row);
    },
    goAgent() {
      getAuth('insteadOrder').then((res) => {
        if (res) {
          this.selectAgent();
        } else {
          this.$Message.warning(this.mlang('noAuth'));
        }
      });
    },
    switchAccount() {
      let _this = this;
      switchAccount().then((res) => {
        if (res) {
          res.map((v) => {
            if (v.isBiz) {
              v.showName = v.bizName;
            } else if (v.isAgent) {
              v.showName = v.agentName;
            } else {
              v.showName = v.corpName;
            }
          });
          _this.switchAccountData = res;
        } else {
          _this.$Message.warning(this.mlang('noAuth'));
        }
      });
    },
    getNewCheckCodeImage() {
      this.checkcodeimgurl = this.checkcodeimgurl + '&t=' + new Date();
    },
    getDeviceUUid() {
      if (localStorage) {
        return localStorage.getItem('udh_device_uuid');
      }
    },
    switchUser(user) {
      this.getNewCheckCodeImage();
      const { mobileValid: userMobileValid, emailValid: userEmailValid, mobile: userMobile, email: userEmail } = this.currentUser;
      const { mobileValid: switchMobileValid, emailValid: switchEmailValid, mobile: switchMobile, email: switchEmail } = user;
      let emailPass = userEmail === switchEmail && userEmailValid && switchEmailValid;
      let mobilePass = userMobile === switchMobile && userMobileValid && switchMobileValid;
      if (emailPass || mobilePass) {
        this.switchLogin(user);
      } else {
        let validateType = 1;
        if (mobilePass && !emailPass) {
          validateType = 2;
        }
        this.placeholder = validateType === 2 ? user.email : user.mobile;
        this.isModel = true;
        this.postData.contactWay = user.email;
        this.postData.phone = user.mobile;
        this.onOkData.validateType = validateType;
        this.onOkData.email = user.email;
        this.onOkData.phone = user.mobile;
        this.onOkData.userId = user.userId;
        this.onOkData.key = validateType === 2 ? user.email : user.mobile;
        this.okUrl = validateType === 2 ? `/passport/current/email/${this.onOkData.email}/verify` : `/passport/current/phone/${this.onOkData.key}/verify`;
      }
    },
    switchLogin(user) {
      switchLogin(user.userId).then((data) => {
        this.$util.clearUpAgentInfo();
        if (_.isArray(data.relations) && data.relations.length) {
          user.relationId = data.relations[0].id;
          localStorage.upAgentRelationId = user.relationId;
        }
        this.$store.dispatch('setUserInfo', user);
        const cb = () => {
          window.location = user.isAgent ? this.$util.getAgentUrl() : '/corp/';
        };
        yiguan.login(user, cb);
      });
    },
    IsVerificationCode() {
      this.isPrompt = false;
    },
    sendReviewCode(user) {
      if (!this.postData.captcha) {
        this.isPrompt = true;
      } else {
        const key = this.onOkData.key;
        const url = `/captcha/verify/send/${key}/verifyuser`;
        VerificationCode(url, this.postData.captcha)
          .then((res) => {
            this.isSwitch = false;
            this.cue = this.mlang('cue');
            var timeInterMark4 = setInterval(() => {
              this.cue = this.timeMark + this.mlang('cueTime');
              this.timeMark--;
              if (this.timeMark <= 0) {
                clearInterval(timeInterMark4);
                this.isSwitch = true;
              }
            }, 1000);
          })
          .catch((res) => {
            if (res.code === 999) {
              this.getNewCheckCodeImage();
            }
          });
      }
    },
    onOk() {
      const postData = {
        data: this.onOkData.validCode,
        datatype: 'json',
        isArchive: 1
      };
      onOk(postData, this.okUrl).then((res) => {
        this.isModel = false;
        this.switchLogin(this.onOkData);
        this.postData.captcha = null;
        this.onOkData.validCode = null;
        this.isPrompt = false;
      });
    },
    onCancel() {
      this.isModel = false;
      this.postData.captcha = null;
      this.onOkData.validCode = null;
      this.isPrompt = false;
      this.isSwitch = true;
      this.timeMark = 120;
    },
    changeLocal(key) {
      this.setLocal(key);
      location.reload();
    },
    openWorkOrderSystem() {
      createWorkOrder().then((url) => {
        this.$open(url, 'workOrderSystem');
      });
    },
    goToUserBase() {
      this.$router.push({
        name: 'userBase'
      });
    },
    toAgentAccount() {
      util.menuClickOldPage('/agentpage/Users/account?menuid=account#/userbase', this);
    }
  }
};
</script>

<style lang="less" scoped>
@import './headers.less';
</style>
