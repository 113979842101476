import ajax from '@/libs/ajax';
import { oneOf } from '@/u-components/utils/assist';
export const getList = (query) => {
  let dimensions = {
    1: 'agentId',
    3: 'agentCategoryId',
    4: 'agentLevelId',
    2: 'agentAreaId',
    8: 'agentGroupId'
  };
  query.querySchema.conditions.map((v) => {
    if (oneOf(v.name, Object.values(dimensions))) {
      v.name = 'dimensionObjId';
    }
  });

  // query.querySchema.conditions.push({
  //   name: 'isProductClass',
  //   conditionType: 'eq',
  //   isDefine: false,
  //   valueType: 'Short',
  //   v1: 0
  // });

  //   4.获取组合促销列表
  // /combination/list   POST  querySchema
  // 条件：
  // {
  //   "dimensionObjId"://客户各种维度ID
  //   "dimensionType"://维度类型
  //   "classId"://分类信息
  //   "name"://促销名称
  // }
  return ajax.request({
    url: '/goods/promotion/entire/list',
    // url: '/goods/promotion/product/list',
    method: 'POST',
    data: {
      data: query.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const batchDel2 = (checkedIds) => {
  return ajax.request({

    url: '/goods/promotion/entire/delete',
    method: 'POST',
    data: {
      datatype: 'json',
      isArchive: 1,
      data: checkedIds
    }
  });
};

export const enableContent2 = (data, bFlag) => {
  let checkedIds = [];
  checkedIds.push(data['id']);
  return ajax.request({
    url: `/goods/promotion/entire/valid?valid=${bFlag}`,
    method: 'POST',
    data: {
      data: checkedIds,
      datatype: 'json',
      isArchive: 1
      // id: data.id,
      // valid: !data.isValid
    }
  });
};

export const copyIt2 = (data, bFlag) => {
  let checkedIds = [];
  checkedIds.push(data['id']);
  return ajax.request({
    // true/false  POST
    url: '/goods/purchase/status?status=' + (bFlag ? 'true' : 'false'),
    method: 'POST',
    data: {
      data: checkedIds,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const viewIt2 = (data, bFlag) => {
  let checkedIds = [];
  checkedIds.push(data['id']);
  return ajax.request({
    // true/false  POST
    url: '/goods/purchase/status?status=' + (bFlag ? 'true' : 'false'),
    method: 'POST',
    data: {
      data: checkedIds,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const editContent2 = (data) => {
  return ajax.request({
    // /goods/price/disrate/{id}/status
    url: '/editContent2editContent2editContent2',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 获取整单促销详情接口
export const editSingleDiscountShow = (data) => {
  return ajax.request({
    url: `/goods/promotion/entire/${data.id}?isCopy=${data.isCopy}`,
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 获取整单促销开票类型接口
export const getInvoiceType = (id) => {
  return ajax.request({
    url: '/enum/orginazation/agent/InvoiceType/info',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 保存整单促销接口
export const saveSingleDiscountShow = (data) => {
  return ajax.request({
    url: '/goods/promotion/entire',
    method: 'POST',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 整单优惠查看执行情况
export const getSingleDiscountExeStatusList = (query) => {
  return ajax.request({
    url: '/goods/promotion/getEntirePreferentialRecord',
    method: 'POST',
    data: {
      data: query.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const submitProductDetailData = (data) => {
  let res = ajax.request({
    url: '/bill/submit',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    }
  });
  return res;
};
export const unSubmitProductDetailData = (data) => {
  return ajax.request({
    url: '/bill/unsubmit',
    method: 'post',
    data: {
      data: data,
      datatype: 'json',
      isMarket: 1
    }
  });
};
// ys 商家促销管理 审核 驳回
export const entireAudit = (id) => {
  return ajax.request({
    url: `/goods/promotion/entire/merchant/audit?isArchive=1&promotionId=${id}`,
    method: 'get'
  });
};
export const entireUnAudit = (data) => {
  return ajax.request({
    url: '/goods/promotion/entire/merchant/oppse',
    method: 'POST',
    data: {
      data: data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const getMerchantEntireList = (query) => {
  let dimensions = {
    1: 'agentId',
    3: 'agentCategoryId',
    4: 'agentLevelId',
    2: 'agentAreaId',
    8: 'agentGroupId'
  };
  query.querySchema.conditions.map((v) => {
    if (oneOf(v.name, Object.values(dimensions))) {
      v.name = 'dimensionObjId';
    }
  });
  return ajax.request({
    url: '/goods/promotion/entire/merchant/list',
    method: 'POST',
    data: {
      data: query.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};
