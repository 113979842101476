import MetaSetting from './meta-setting';
import BillNo from './bill-no';
import OutHref from './out-href';
import QuerySchema from '_c/common/queryschema/queryschema';
import Link from '_c/common/Link.vue';
import ImportExport from '_c/common/import-export';
import Enum from '_c/common/enum';
import NoData from './no-data.vue';
import CheckGroup from './check-group.vue';
import Status from '_c/common/status';
import Toggle from '_c/common/toggle';
// import VoucherEditColumn from '_c/common/voucher/voucher-edit-column';
import GroupRowspan from '@/components/ordert-detail/selectGiveaway/grouprowspan/grouprowspan';

export default { MetaSetting, QuerySchema, Enum, OutHref, BillNo, ImportExport, NoData, Status, GroupRowspan, CheckGroup, Toggle, Link };
