<template>
  <span>
    <span
      v-if="(params.row.isDownSuccess === true || params.row.isDownSuccess === false) && params.row.cDownloadSource"
      class="order-u8">
      <Poptip
        :options="popTipProps"
        trigger="hover"
        placement="bottom"
        popper-class="poptip">
        <template v-if="params.row.isDownSuccess">
          <a
            v-if="params.row.cDownloadSource==1||params.row.cDownloadSource==9||params.row.cDownloadSource==10||params.row.cDownloadSource==11"
            :class="{'u8 u8-active':params.row.cDownloadSource==1,'nc nc-active':params.row.cDownloadSource==9,'u9 u9-active':params.row.cDownloadSource==10,'u8-cloud u8-cloud-active':params.row.cDownloadSource==11}"
            class="icon-list">
          </a>
        </template>
        <template v-if="!params.row.isDownSuccess">
          <a
            v-if="params.row.cDownloadSource==1||params.row.cDownloadSource==9||params.row.cDownloadSource==10||params.row.cDownloadSource==11"
            :class="{'u8-gray':params.row.cDownloadSource==1,'nc-gray':params.row.cDownloadSource==9,'u9-gray':params.row.cDownloadSource==10,'u8-cloud-gray':params.row.cDownloadSource==11}"
            class="icon-list"></a>
        </template>
        <div
          slot="content"
          class="">
          <Row
            v-if="params.row.isDownSuccess"
            class-name="padding10">
            <Col>{{ '已被下载到'+(params.row.cDownloadSource==1?'u8':(params.row.cDownloadSource==9?'nc':(params.row.cDownloadSource==10?'u9':(params.row.cDownloadSource==11?'u8-cloud':'未知系统')))) }}</Col>
          </Row>
          <Row
            v-if="params.row.isDownSuccess === false"
            class-name="padding10">
            <Col>{{ params.row.cDownloadMsg }}</Col>
          </Row>
        </div>
      </poptip>
    </span>
    <span
      v-if="(params.row.downSuccess === true || params.row.downSuccess === false) && params.row.downloadSource"
      class="order-u8">
      <Poptip
        :options="popTipProps"
        trigger="hover"
        placement="bottom"
        popper-class="poptip">
        <template v-if="params.row.downSuccess ">
          <a
            v-if="params.row.downloadSource==1||params.row.downloadSource==9||params.row.downloadSource==10||params.row.downloadSource==11"
            :class="{'u8 u8-active':params.row.downloadSource==1,'nc nc-active':params.row.downloadSource==9,'u9 u9-active':params.row.downloadSource==10,'u8-cloud u8-cloud-active':params.row.downloadSource==11}"
            class="icon-list">
          </a>
        </template>
        <template v-if="!params.row.downSuccess ">
          <a
            v-if="params.row.downloadSource==1||params.row.downloadSource==9||params.row.downloadSource==10||params.row.downloadSource==11"
            :class="{'u8-gray':params.row.downloadSource==1,'nc-gray':params.row.downloadSource==9,'u9-gray':params.row.downloadSource==10,'u8-cloud-gray':params.row.downloadSource==11}"
            class="icon-list"></a>
        </template>
        <div
          slot="content"
          class="">
          <Row
            v-if="params.row.downSuccess "
            class-name="padding10">
            <Col>{{ '已被下载到'+(params.row.downloadSource==1?'u8':(params.row.downloadSource==9?'nc':(params.row.downloadSource==10?'u9':(params.row.downloadSource==11?'u8-cloud':'未知系统')))) }}</Col>
          </Row>
          <Row
            v-if="params.row.downSuccess === false"
            class-name="padding10">
            <Col>{{ params.row.downloadMsg }}</Col>
          </Row>
        </div>
      </poptip>
    </span>
  </span>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'OrderU8',
  mixins: [PopTipMixin, CellMixin],
  data: function() {
    return {};
  },
  methods: {}
};
</script>
<style lang="less" scoped>
@import './order-u8.less';
</style>
