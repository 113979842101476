import corpParentView from '@/views/corp/home/home.vue';

const auth = {
  path: 'auth',
  name: 'auth',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      // 旧版本将废弃
      path: 'authSwitch',
      name: 'authSwitch',
      component: () => import('@/views/corp/auth/components/auth-switch.vue')
    },
    {
      path: 'permissionSwitch',
      name: 'permissionSwitch',
      component: () => import('@/views/corp/auth/components/permission-switch.vue')
    },
    {
      path: 'productGroups',
      name: 'productGroups',
      meta: { module: 'productGroups' },
      component: () => import('@/views/corp/product/components/productGroup/productGroups.vue')
    },
    {
      path: 'productGroup',
      name: 'productGroup',
      meta: { module: 'productGroups', notCache: true },
      component: () => import('@/views/corp/product/components/productGroup/productGroup.vue')
    },
    {
      path: 'customerAuth',
      name: 'customerAuth',
      meta: { module: 'customerAuth', notCache: true },
      component: () => import('@/views/corp/auth/components/customerauth.vue')
    },
    {
      path: 'userProductAuth',
      name: 'userProductAuth',
      meta: { module: 'userProductAuth', notCache: true },
      component: () => import('@/views/corp/auth/components/user-product-auth.vue')
    },
    {
      path: 'userCustomerAuth',
      name: 'userCustomerAuth',
      meta: { module: 'userCustomerAuth', notCache: true },
      component: () => import('@/views/corp/auth/components/user-customer-auth.vue')
    },
    {
      path: 'userStockAuth',
      name: 'userStockAuth',
      meta: { module: 'userStockAuth', notCache: true },
      component: () => import('@/views/corp/auth/components/user-stock-auth.vue')
    },
    {
      path: 'userCustomerGroupAuth',
      name: 'userCustomerGroupAuth',
      meta: { module: 'userCustomerGroupAuth', notCache: true },
      component: () => import('@/views/corp/auth/components/user-customer-group-auth.vue')
    },
    {
      path: 'userFunctionAuth',
      name: 'userFunctionAuth',
      meta: { module: 'userFunctionAuth', notCache: true },
      component: () => import('@/views/corp/auth/components/userFunctionAuth.vue')
    }
  ]
};

export default auth;
