import { getDeliveryList, getDeliveryListBtn, checkInventory, confirmDelivery, backConfirmDvery, delDelivery, exportDelivery } from '@/api/delivery';
import { deepCopy } from '@/u-components/utils/assist';

const mixin = {
  data() {
    return {
    };
  },
  mounted() {},
  computed: {},

  methods: {
    mixin_confirmDelivery(params, vm) {
      const confirmDv = () => {
        let confirmArray = [];
        confirmArray.push(params);
        let postData = {
          cDeliveryNos: confirmArray,
          datatype: 'json'
        };
        confirmDelivery(postData).then((data) => {
          vm.$Message.success(vm.mlang('confirmDeliverySuccess'));
          vm.dispatch('VoucherList', 'reload');
        });
      };

      checkInventory(params).then((data) => {
        if (data.isExceed) {
          vm.$Modal.confirm({
            content: data.msg,
            onOk: () => {
              confirmDv();
            }
          });
        } else {
          vm.$Modal.confirm({
            content: vm.mlang('confirmDeliveryContent'),
            onOk: () => {
              confirmDv();
            }
          });
        }
      });
    },
    mixin_backConfirmDvery(params, vm) {
      if (params.row.cOutSysKey) {
        vm.$Message.warning(vm.mlang('backConfirmDveryWarning'));
      } else {
        vm.$Modal.confirm({
          content: vm.mlang('backConfirmDveryContentA') + params.row.cDeliveryNo + vm.mlang('backConfirmDveryContentB'),
          onOk: () => {
            const postData = {
              cDeliveryNo: params.row.cDeliveryNo
            };
            backConfirmDvery(postData).then((data) => {
              vm.$Message.success(vm.mlang('backConfirmDverySuccess'));
              vm.dispatch('VoucherList', 'reload');
            });
          }
        });
      }
    },
    mixin_delDelivery(params, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('delDeliveryContentA') + params.row.cDeliveryNo + vm.mlang('delDeliveryContentB'),
        onOk: () => {
          const postData = {
            cDeliveryNo: params.row.cDeliveryNo
          };
          delDelivery(postData).then(() => {
            vm.$Message.success(vm.mlang('delDeliverySuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    mixin_batchSure: (batchSureArr, vm) => {
      // console.log(batchSureArr);
      let nos = [];
      batchSureArr.map((v) => {
        nos.push(v.cDeliveryNo);
      });
      vm.$Modal.confirm({
        content: vm.mlang('batchSureContentA') + batchSureArr.length + vm.mlang('batchSureContentB'),
        onOk: () => {
          const postData = {
            cDeliveryNos: nos,
            datatype: 'json'
          };
          confirmDelivery(postData).then((data) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.broadcast('VoucherList', 'reload');
          });
        }
      });
    },
    // 查询列表数据
    mixin_deliverysQueryList(query) {
      if (this.dealOldYear(query) === false) return;
      const type = this.mixin_isPlatForm();
      query.querySchema.conditions.push({
        name: 'isOtherFranchiser',
        v1: type,
        valueType: 'BOOLEAN',
        conditionType: 'eq'
      });
      query.querySchema.conditions.forEach((v) => {
        if (v.name === 'created') {
          if (!v.v1 && v.v2) {
            v.v1 = '1900-01-01 00:00:00';
          }
          if (!v.v2 && v.v1) {
            v.v2 = '9999-12-31 23:59:59';
          }
        }
      });
      let postData = {
        datatype: 'json',
        isArchive: 1,
        data: query.querySchema
      };
      getDeliveryList(postData, this.isHistory).then((res) => {
        if (!this.isHistory) this.afterListSearch(query, 'created');
        const ids = [];
        const menuid = type ? 'merchantsDeliverys' : 'deliveryOrderList';
        res.content.map((v) => {
          if (this.isHistory) {
            let to = {};
            to = {
              name: 'orderHistory',
              query: { id: v.iOrderId, showOldYear: true, cOrderNo: v.cOrderNo }
            };
            v.cOrderNoTo = to;
            let toDelivery = {};
            toDelivery = {
              name: 'deliveryHistory',
              query: { id: v.id, showOldYear: true, cDeliveryNo: v.cDeliveryNo, orderId: v.iOrderId }
            };
            v.cDeliveryNoTo = toDelivery;
          } else {
            v.cDeliveryNoTitle = this.mlang('deliveryDetail');
            // 发货单链接
            let cDeliveryNoHref = `/corppage/Deliverys/newDelivery?routeModule=deliveryOrderList&menuid=${menuid}&cDeliveryNo=${v.cDeliveryNo}`;
            if (this.mixin_routers() === 'platform') {
              cDeliveryNoHref += '&editable=0&isMerchantDelivery=1';
            }
            if (v.cStatusCode !== 'NOTDELIVER') {
              cDeliveryNoHref += '&notEdit=true';
            }
            cDeliveryNoHref += '#/detailOrder';
            v.cDeliveryNoHref = cDeliveryNoHref;
            // 订单链接
            let cOrderNoHref = `/corppage/Orders/detail?routeModule=orderList&menuid=${menuid}&cOrderNo=${v.cOrderNo}`;
            if (this.$route.query.type === 'platform') {
              cOrderNoHref += '&editable=0&isMerchantOrder=1';
            }
            cOrderNoHref += '#/detailOrder';
            v.cOrderNoHref = cOrderNoHref;

            v.cOrderNoTitle = this.mlang('orderDetail');
          }
          ids.push(v.id);
          v.isShowPayBtn = false;
          v.isShowUnConfirmBtn = false;
        });
        this.data = deepCopy(res);
        if (!this.mixin_isPlatForm() && ids.length && !this.isHistory) { // 商家发货不调, 没有发货单也不调
          getDeliveryListBtn(ids).then((btnRes) => {
            res.content.map((v) => {
              const id = v.id;
              const btn = btnRes.find((item) => {
                return item.id === id;
              });
              v = Object.assign(v, btn);
            });
            this.data = deepCopy(res);
          });
        }
      });
    },
    mixin_exportDelivery(query) {
      const type = this.mixin_isPlatForm();
      const other = {
        'name': 'isOtherFranchiser',
        'v1': true,
        'valueType': 'BOOLEAN',
        'conditionType': 'eq'
      };
      if (type && !query.querySchema.conditions.other) {
        query.querySchema.conditions.push(other);
      }
      return exportDelivery(query);
    },
    mixin_setListColumns(columns) {
      const type = this.mixin_isPlatForm();
      if (type) {
        columns.map((v) => {
          const hideColumns = ['stockOrgName', 'salesOrgName'];
          if (hideColumns.includes(v.fieldName)) {
            v.isHidden = true;
          }
        });
      }
      return columns;
    }
  }
};

export default mixin;
