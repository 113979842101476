<template>
  <div class="sum-item">
    <div class="sum-title">
      {{ title }}
    </div>
    <div class="sum-count">
      <template v-if="to.url || to.name || to.path">
        <a class="Neworder" :fieldid="`a_${to.name}`" @click="handleClick(to)">{{ content }}</a>
      </template>
      <template v-else>
        {{ content }}
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SummaryItem',
  props: {
    to: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String
    },
    content: [Number, String]
  },
  methods: {
    handleClick(to) {
      if (to.url) this.agentLink({ type: 'noRegister', url: to.url });
      else if (to.name) this.$router.push({ name: to.name, query: to.query || {} });
      else if (to.path) this.$router.push({ ...to });
    }
  }
};
</script>
<style scoped>
.sum-item {
  margin: 10px;
}
.sum-count {
  font-family: ziti;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
}
</style>
