<template>
  <Modal
    :value="invoiceModal"
    :mask="false"
    :mask-closable="false"
    :title="electronicInvoice"
    footer-hide
    width="561"
    @on-visible-change="onVisibleChange"
    @on-cancel="cancelHandle">
    <Table border :loading="loading" :columns="columns" :data="invoiceData" :no-data-text="mlang('noResults')"></Table>
  </Modal>
</template>

<script>
import { getEleSalesInvoice } from '@/api/order';
export default {
  name: 'ElectronicInvoiceModal',
  props: {
    orderId: {
      type: String,
      defalut: ''
    },
    showModal: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: true,
      electronicInvoice: this.mlang('electronicInvoice'),
      columns: [
        {
          title: this.mlang('einvoiceNo'),
          key: 'einvoiceNo',
          width: 110
        },
        {
          title: this.mlang('einvoiceType'),
          key: 'invoiceType',
          width: 130
        },
        {
          title: this.mlang('einvoiceHm'),
          key: 'einvoiceHm',
          width: 110
        },
        {
          title: this.mlang('totalAmountIncludingTaxOfTheProject'),
          key: 'total',
          width: 110
        },
        {
          title: this.mlang('electronicInvoice'),
          key: 'einvoiceDownUrl',
          width: 100,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                on: {
                  click: () => {
                    if (this.invoiceData[params.index].einvoiceDownUrl) {
                      this.$open(this.invoiceData[params.index].einvoiceDownUrl);
                    } else {
                      this.$Message.error(this.mlang('DownloadAddressOfTheElectronicInvoiceWasNotFound'));
                    }
                  }
                }
              }, this.mlang('download'))
            ]);
          }
        }
      ],
      invoiceData: []
    };
  },
  computed: {
    invoiceModal() {
      return this.showModal;
    }
  },
  methods: {
    onVisibleChange(params) {
      if (params) {
        getEleSalesInvoice(this.orderId).then((res) => {
          res.forEach((item) => {
            if (item.invoiceType === '1') {
              item.invoiceType = this.mlang('vatElectronicOrdinaryInvoice');
            } else if (item.invoiceType === '2') {
              item.invoiceType = this.mlang('vatElectronicSpecialInvoice');
            }
          });
          this.invoiceData = res;
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.loading = true;
      }
    },
    cancelHandle() {
      this.invoiceData = [];
      this.$emit('update:showModal', false);
    }
  }

};
</script>

<style scoped>
  /deep/ .ivu-modal-content{
    min-height: 320px;
  }
</style>
