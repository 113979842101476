import ajax from '@/libs/ajax';
export const getDecorates = () => {
  return ajax.request({
    url: '/thirdparty/corp/Template/getTemplateList',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const getToken = () => {
  return ajax.request({
    url: '/archives/system/yxytoken',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const getPageId = () => {
  return ajax.request({
    url: '/shopDecoration/pageid',
    method: 'get',
    data: {
      isArchive: 2
    }
  });
};
