// 客户交易关系
import { getSaleReturnProductList } from '@/api/agentSaleReturn/agentSaleReturn';
import { deepCopy } from '@/u-components/utils/assist';
const agentsRefer = (ctrl, vm) => {
  const productTableHeader = [
    {
      showCaption: vm.mlang('productCode'),
      isShowIt: true,
      fieldName: 'cProductCode',
      width: 150
    },
    {
      showCaption: vm.mlang('productName'),
      fieldName: 'cProductName',
      width: 180,
      isShowIt: true
    },
    {
      showCaption: vm.mlang('specDescription'),
      isShowIt: true,
      width: 170,
      fieldName: 'cSpecDescription'
    },
    {
      showCaption: vm.mlang('sendQuantity'),
      isShowIt: true,
      width: 150,
      fieldName: 'iSendQuantityUnit'
    },
    {
      showCaption: vm.mlang('saleReturnQuantity'),
      isShowIt: true,
      width: 150,
      fieldName: 'fSaleReturnQuantityUnit'
    },
    {
      showCaption: vm.mlang('productDescription'),
      isShowIt: true,
      width: 120,
      fieldName: 'oSKU.modelDescription'
    },
    {
      showCaption: vm.mlang('productModel'),
      isShowIt: true,
      width: 120,
      fieldName: 'oSKU.model'
    }
  ];
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      title: vm.mlang('selectProduct'),
      page: true,
      bRefer: true,
      bread: false,
      multiple: true,
      controlType: 'Refer',
      reffieldName: 'name',
      refName: 'name',
      queryschema: [
        {
          name: 'cProductCodeOrName',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('agentNameOrCode'),
          conditionType: 'like'
        }
      ],
      columns: productTableHeader.filter((item, index) => {
        if (item.fieldName === 'cProductCode' && !vm.$getBO('QUICKORDERISSHOWPRODUCTCODEPC')) {
          return false;
        }
        if (item.fieldName === 'oSKU.modelDescription' && !vm.$getBO('PRODUCTSPECIFICATION')) {
          return false;
        }
        if (item.fieldName === 'oSKU.model' && !vm.$getBO('PACKAGESPECIFICATIONS')) {
          return false;
        }
        return item;
      }),
      passedparams: [{
        name: 'bizId',
        v1: ctrl.bizId,
        valueType: 'LONG',
        conditionType: 'eq'
      }, {
        conditionType: 'eq',
        name: 'orgId',
        v1: ctrl.orgId,
        valueType: 'LONG'
      }],
      actions: { queryList: getSaleReturnProductList },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default agentsRefer;
