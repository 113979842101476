<template>
  <ul :class="['agent-content-left', {'category-toggle': routerPath, 'category-shadow': !routerPath}]">
    <div class="agent-content-inner1">
      <div class="agent-content-inner2" @mouseleave="hideMenu">
        <a
          v-for="product1 in slidProducts"
          :key="product1.id"
          href="javascript:void(0)"
          class="agent-list categorys-list"
          @click="agentLinkAndHide({type: 'agentProductMenu',query: {node:1,id:product1.id,classifyDepth: product1.iDepth?product1.iDepth+1:1, classifyId: product1.id, classifyName: product1.cName}})"
          @mouseenter="showMenu(product1)">
          {{ product1.cName }}
        </a>
        <transition name="menu">
          <div v-show="isShowThreeMenu" class="agent-Twolevellist">
            <div class="agent-Twolevellisticon">
              <!-- <div
                v-show="!menudata.lsChildClass"
                class="agent-Twolevellisticon-diyi">
                <a
                  class="agent-title"
                  href="javascript:void(0)"
                  @click="agentLinkAndHide({type: 'agentProductMenu', query: {node:1,id:menudata.id,classifyDepth: menudata.iDepth?menudata.iDepth+1:1, classifyId: menudata.id, classifyName: menudata.cName}})">
                  <span class="agent-titlecon">{{ menudata.cName }}</span>
                </a>
              </div> -->
              <div
                v-for="product2 in menudata.lsChildClass"
                v-show="menudata.lsChildClass"
                :key="product2.id"
                class="agent-Twolevellisticon-diyi">
                <a
                  class="agent-title"
                  href="javascript:void(0)"
                  @click="agentLinkAndHide({product: menudata, type: 'agentProductMenu', query: {node:2,parentClassifyName2: menudata.cName, parentClassifyId2: menudata.id, parent: menudata.id, id:product2.id,classifyDepth: product2.iDepth?product2.iDepth+1:1, classifyId: product2.id, classifyName: product2.cName}})">
                  <!-- <i class="iconfont erjifenlei-zhuangshi"></i> -->
                  <span class="agent-titlecon">{{ product2.cName }}</span>
                </a>
                <ul class="agent-listcon">
                  <li
                    v-for="product3 in product2.lsChildClass"
                    :key="product3.id">
                    <a
                      href="javascript:void(0)"
                      @click="agentLinkAndHide({type: 'agentProductMenu', query: {node:3,parentClassifyName2: menudata.cName, parentClassifyId2: menudata.id,parentClassifyName3: product2.cName, parentClassifyId3: product2.id,parent: `${menudata.id}|${product2.id}`, id:product3.id,classifyDepth: product3.iDepth?product3.iDepth+1:1, classifyId: product3.id, classifyName: product3.cName}})">
                      {{ product3.cName }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </ul>
</template>
<script>
import { getAllProductsClasses } from '@/api/product/productLine.js';
export default {
  name: 'Categorys',
  data() {
    return {
      slidProducts: [],
      isToggle: 'catogory-toggle',
      menudata: {},
      isShowThreeMenu: false
    };
  },
  computed: {
    routerPath() {
      return this.$route.path !== '/agent/home';
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    showMenu(data) {
      if (data.bFinalLevel || !data.lsChildClass) {
        this.isShowThreeMenu = false;
        this.menudata = data;
      } else {
        this.isShowThreeMenu = true;
        this.menudata = data;
      }
    },
    hideMenu() {
      this.isShowThreeMenu = false;
    },
    init() {
      getAllProductsClasses().then((res) => {
        this.slidProducts = res;
      });
    },
    agentLinkAndHide({ type, query }) {
      this.agentLink({ type, query });
      this.$emit('hideFirstMenu');
      this.hideMenu();
    }
  }
};
</script>
<style lang="less" scoped>
  .menu-leave-active{
    transition: width .4s;
  }
  .menu-leave-to {
    width: 0 !important;
  }
  .agent-main .agent-header .agent-btm .agent-content-left .categorys-list{
    line-height: normal !important;
    height: auto;
    padding: 10px 20px;
  }
</style>
