export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000725","确定执行整单生效?") /* "确定执行整单生效?" */,
  OperateCurrent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000726","是要对当前的") /* "是要对当前的" */,
  OperateContents: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000727","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000728","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000729","条") /* "条" */,
  delContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500072A","删除") /* "删除" */,
  editContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500072B","编辑") /* "编辑" */,
  effectContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500072C","生效") /* "生效" */,
  disEffectContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500072D","失效") /* "失效" */,
  customerLevel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500072E","客户等级") /* "客户等级" */,
  channelLevel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500072F","渠道等级") /* "渠道等级" */,
  confirmDel: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000730","确定删除么？") /* "确定删除么？" */,
  RMB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000731","人民币") /* "人民币" */
};
