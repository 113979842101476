import numeral from 'numeral';
import _, { isString } from 'lodash';
import util from '@/libs/util';
import dayjs from 'dayjs';
export function formatInputMoney(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

/**
 *  按全局格式化参数格式化数字并按精度显示 新版V2
 *  in 10000.056（Number）
 *  out 10,000.05（String）
 */
export function numberFormat(params) {
  const value = util.numberFormat(params);
  return value;
}

// 列表三位一逗号并且保留2位小数
export function formatMoney(v, formatMoney = '') {
  numeral.defaultFormat(`${formatMoney}0,0.00`);
  return numeral(v).format(`${formatMoney}0,0.00`);
}

// formatNumber
export function formatNumber(v, formatMoney = '0,0.00') {
  numeral.defaultFormat(`${formatMoney}`);
  return numeral(v).format(`${formatMoney}`);
}
// 币种
export function currency(val, type = '') {
  return `${type}${val}`;
}
// 三位一逗号
export function toThousands(num = 0, type = '1') {
  if (type === '2' || type === 2) {
    num = num.toFixed(2);
  }
  const res = num.toString().replace(/\d+/, function(n) {
    // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function($1) {
      return $1 + ',';
    });
  });
  return res;
}
// 截取字符串
export function substr(input, length) {
  if (!util.isNoValue(input)) {
    if (!_.isString(input)) {
      input = input.toString();
    }
    if (input.length > length) {
      let str = [];
      let j = 0;
      for (let i = 0; i < input.length; i++) {
        if (input[i].match(/[^\u4e00-\u9fa5]/g)) {
          // console.info("英文"+input[i]);
          j = j + 0.8;
        } else {
          // console.info("中文"+input[i]);
          j = j + 2;
        }
        str.push(input[i]);
        let cond = j > (length - 1) * 2;
        if (cond) {
          input = str.join('');
          break;
        }
      }
    }
    // input = input.length > length ? (input.substr(0, length - 3) + '...') : input;
  }
  return input;
}

// 价格精度控制
export function upExactNumber(input, unit, currency) {
  const app = window.__app.mainV;
  let hidPriceExact = app.$store.getters.hidePriceExact;

  let priceExact = hidPriceExact;
  let exact = priceExact.pricePrecision;
  let exactType = priceExact.priceRoundType;

  if (unit) {
    // 如果有单位,则按照单位设置计算
    exact = unit.unitPrecision;
    exactType = unit.priceRoundType;
  }
  // YS传币种，设置价格精度
  if (currency) {
    exact = currency.moneyDigit;
    exactType = currency.moneyRount;
  }

  if (!app.$util.isNoValue(input)) {
    let inputStr = input.toString();
    if (inputStr.indexOf('.') > -1) {
      if (!_.isNumber(input)) {
        input = parseFloat(input);
      }
      if (isNaN(input)) {
        throw new Error('upExactNumberNAN');
      } else {
        input = app.$util.toFixed(input, exact, exactType);
      }
    }
  }
  return input || input === 0 ? parseFloat(input) : '';
}

// 商品价格为‘0’时的价格展示 或者 订货端隐藏价格
export function priceFree(input, name) {
  if (name === false) {
    return input;
  }
  const app = window.__app.mainV;
  let freeDis = app.$getBO('PRODUCTPRICESHOW'); // 商品价格为‘0’时的价格展示"
  let PRODUCTNOTSHOWPRICE = app.$getBO('PRODUCTNOTSHOWPRICE'); // 订货端隐藏价格
  if (PRODUCTNOTSHOWPRICE && sessionStorage.isAgentSide === 'true' && name !== '0') {
    return '';
  }
  if (!app.$util.isNoValue(input)) {
    let price = input;
    // 输入价格有可能是0.00;
    if (parseFloat(price) === 0) {
      input = freeDis;
    }
    return input;
  }
}
// 隐藏价格
export function upHideString(input, name) {
  const app = window.__app.mainV;
  let userSettingPrice = app.$store.getters.userSettingPrice;
  if (!userSettingPrice || !userSettingPrice.length) {
    return input;
  }
  let findItem = userSettingPrice.find((v) => v.fieldsCode === name);
  if (findItem) {
    return findItem.replaceValue;
  } else {
    return input;
  }
}
// 隐藏价格的账户隐藏币符，新版为了把币符和金额区分开
export function upHideOperater(input, name) {
  const app = window.__app.mainV;
  let userSettingPrice = app.$store.getters.userSettingPrice;
  if (!userSettingPrice || !userSettingPrice.length) {
    return input;
  }
  let findItem = userSettingPrice.find((v) => v.fieldsCode === name);
  if (findItem && findItem.settingCode === 'HIDPRICE') {
    return '';
  } else {
    return input;
  }
}
// 加...
export function ellipsis(input, realValue, length) {
  if (realValue.length > input.length) {
    return input + '...';
  }
  return input;
}
// 合计金额处理精度  type=""或者money为金额类  type="price"为单价类
export function upCoinDigit(input, type, defineCurrency) {
  const app = window.__app.mainV;
  if (app.$getBO('B2BMALLPRICEACCURACY') == 2) {
    return input;
  }
  // eslint-disable-next-line no-undef
  let currentUser = __app.mainV.$store.getters.currentUser;
  let hidPriceExact = {
    moneyDigit: 2,
    moneyRount: 1
  };
  let moneyDigit = hidPriceExact.moneyDigit;
  let moneyRount = hidPriceExact.moneyRount;
  let [checked, bizId, orgId] = [null, null, null];
  if (currentUser && currentUser.relations) {
    checked = formatBizs(currentUser.relations);
    if (checked) {
      bizId = checked.bizId;
      orgId = checked.orgId;
    }
  }
  let relations = currentUser.relations || [];
  let agentRelation = relations.find(item => {
    return bizId === item.bizId && orgId === item.orgId;
  });
  if (agentRelation && agentRelation.currency) {
    const currency = agentRelation.currency;
    // 如果有单位,则按照单位设置计算
    if (!type || (type && type === 'money')) {
      if (currency.moneyDigit || currency.moneyDigit === 0) {
        moneyDigit = currency.moneyDigit;
      }
      if (currency.moneyRount || currency.moneyRount === 0) {
        moneyRount = currency.moneyRount;
      }
    }
    if (type && type === 'price') {
      if (currency.priceDigit || currency.priceDigit === 0) {
        moneyDigit = currency.priceDigit;
      }
      if (currency.moneyRount || currency.priceRount === 0) {
        moneyRount = currency.priceRount;
      }
    }
  }
  if (defineCurrency) {
    if (type === 'money' || !type) {
      moneyDigit = defineCurrency.moneyDigit || moneyDigit;
      moneyRount = defineCurrency.moneyRount || moneyRount;
    }
    if (type === 'price') {
      moneyDigit = defineCurrency.priceDigit || moneyDigit;
      moneyRount = defineCurrency.priceRount || moneyRount;
    }
  }
  const inputObj = {
    value: input,
    precision: moneyDigit,
    roundType: moneyRount
  };
  if (input || input === 0) {
    let inputStr = input.toString();
    if (inputStr.indexOf('.') > -1) {
      if (!_.isNumber(input)) {
        input = parseFloat(input);
      }
      if (isNaN(input)) {
        throw new Error(lang.templateByUuid("UID:P_UDHWN_17E3A78E05800013","upCoinDigit过滤器处理的值为 NAN") /* "upCoinDigit过滤器处理的值为 NAN" */);
      } else {
        input = util.numberFormat(inputObj);
      }
    } else {
      if (isNaN(input)) {
        throw new Error(lang.templateByUuid("UID:P_UDHWN_17E3A78E05800013","upCoinDigit过滤器处理的值为 NAN") /* "upCoinDigit过滤器处理的值为 NAN" */);
      } else {
        input = util.numberFormat(inputObj);
      }
    }
  }
  return input || input === 0 ? input : ''; // 为了防止把10,000.00这种格式化成10
}
// 加币符
export function upCoinOperater(input, originCurrency) {
  if (originCurrency) {
    input = input === undefined ? '' : input;
    let currTypeSign = originCurrency.currTypeSign ? originCurrency.currTypeSign : '';
    input = `${currTypeSign}${input}`;
    return input;
  }
  // eslint-disable-next-line no-undef
  const mlang = window.__app.mainV.$t;
  let currentUser = window.__app.mainV.$store.getters.currentUser;
  let [checked, bizId, orgId] = [null, null, null];
  if (currentUser && currentUser.relations) {
    checked = formatBizs(currentUser.relations);
    if (checked) {
      bizId = checked.bizId;
      orgId = checked.orgId;
    }
  }
  let relations = currentUser.relations || [];
  let agentRelation = relations.find(item => {
    return bizId === item.bizId && orgId === item.orgId;
  });
  if (agentRelation) {
    const currency = agentRelation.currency;
    if (currency) {
      input = input === undefined ? '' : input;
      let currTypeSign = currency.currTypeSign ? currency.currTypeSign : '';
      input = `${currTypeSign}${input}`;
    }
    return input;
  } else {
    input = input === undefined ? '' : input;
    return `${mlang('bifu')}${input}`;
  }
}
export function upSubstr(input, length) {
  if (input) {
    if (!_.isString(input)) {
      input = input.toString();
    }
    if (input.length > length) {
      var str = [];
      var j = 0;
      for (var i = 0; i < input.length; i++) {
        if (input[i].match(/[^\u4e00-\u9fa5]/g)) {
          // console.info("英文"+input[i]);
          j = j + 1.1;
        } else {
          // console.info("中文"+input[i]);
          j = j + 2;
        }
        str.push(input[i]);
        if (j > (length - 2) * 2) {
          str.push('...');
          input = str.join('');
          break;
        }
      }
    }
  }
  return input;
}
// 处理数组，拿到bizId和orgId
export function formatBizs(data = []) {
  var relationStorge = localStorage.upAgentRelationId;
  let result = {};
  data.forEach((item, index) => {
    if (relationStorge) {
      item.isChecked = item.id === relationStorge;
    } else {
      item.isChecked = index === 0;
    }
    if (item.isChecked) {
      result = item;
    }
  });
  return result;
}
// 时间日期格式化
export function upDate(input, format) {
  if (util.isNoValue(format)) {
    format = 'YYYY-MM-DD';
  }
  input = dayjs(input).format(format);
  return input;
};
