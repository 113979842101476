import util from '@/libs/util.js';
import { mapGetters } from 'vuex';
const mixin = {
  data() {
    return {};
  },
  created() {
    if (!this.currentUser.__isU8C) {
      this.$store.dispatch('GetMenuList');
    }
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(['currentUser', 'menuList'])
  },

  methods: {
    getMenuItemByName(name) {
      return util.getMenuItemByName(this.menuList, name);
    },
    setValidateRules() {
      this.meta.editRows.map((v, k) => {
        const { required, model, maxLength, placeholder } = v;
        const rule = {};
        rule[model] = [];
        const rules = rule[model];
        if (required) {
          rules.push({
            required: true,
            message: placeholder,
            trigger: 'blur'
          });
        }
        if (v.maxLength) {
          rules.push({
            max: maxLength,
            message: this.mlang('maxLength').replace('{txt}', maxLength),
            trigger: 'blur'
          });
        }
        if (rules.length) {
          this.meta.validateRules = Object.assign({}, this.meta.validateRules, rule);
        }
      });
    },
    setEditRows() {
      this.meta.editRows = this.meta.editRows || [];
      this.meta.editRows.map((v, k) => {
        v.placeholder = v.placeholder || this.mlang('pleaseEnter') + v.label;
        v.maxLength = v.maxLength || 255;
      });
    }
  }
};

export default mixin;
