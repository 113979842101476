<template>
  <div>
    <div v-if="selectedGroupTmp.cPromotionType=='SINGLECLASS'">
      <p
        v-for="classItem in group.lsProductClasses"
        :key="classItem.id">
        {{ classItem.cName }}
      </p>
    </div>
    <p v-if="group.oProduct">
      {{ group.oProduct.cName }}
    </p>
    <p
      v-if="group.oSku && group.oSku.cSpecDescription">
      【<span>{{ roup.oSku.cSpecDescription }}</span>】
    </p>
    <div v-if="selectedGroupTmp.bCalcWithNum">
      <p class="text-gray90">
        购买数量：<span>{{ group.iQuantity }}</span>
      </p>
    </div>
    <div v-if="!selectedGroupTmp.bCalcWithNum">
      <p
        class="text-gray90">
        购买金额：<span>{{ group.iTotalMoney }}</span>
      </p>
    </div>
    <p v-if="group.bEnableMaxPreferential">
      最大优惠数量：<span>{{ group.iNotUsedPreNum }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    rowData: {
      type: Object
    }
  },
  data() {
    return {
      selectedGroupTmp: this.rowData.selectedGroupTmp,
      group: this.rowData.group
    };
  }
};
</script>
