export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500086E","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500086F","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000870","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000871","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000872","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000873","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000874","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000875","审核") /* "审核" */,
  chgContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000876","变更") /* "变更" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000877","弃审") /* "弃审" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000878","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000879","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500087A","查看执行情况") /* "查看执行情况" */
};
