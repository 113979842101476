import { deepCopy } from '@/u-components/utils/assist';
const productGroupsRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'value',
      refName: 'value',

      queryschema: [
        {
          caption: vm.mlang('xingming'),
          name: 'person.fullName',
          controlType: 'String',
          valueType: 'STRING',
          groupType: 2,
          isShowIt: true,
          placeholder: vm.mlang('xingming'),
          conditionType: 'like',
          style: { 'margin-right': '15px' }
        },
        {
          caption: vm.mlang('code'),
          name: 'code',
          controlType: 'String',
          valueType: 'STRING',
          groupType: 2,
          isShowIt: true,
          placeholder: vm.mlang('code'),
          conditionType: 'like',
          style: { 'margin-right': '15px' }
        },
        {
          caption: vm.mlang('role'),
          name: 'roleId',
          controlType: 'Select',
          groupType: 2,
          valueType: 'LONG',
          isShowIt: true,
          placehoder: vm.mlang('role'),
          isEnum: true,
          width: 220,
          enumString: JSON.stringify(vm.queryschemaArry.queryRolesArr),
          conditionType: 'eq',
          style: { 'margin-right': '15px' }
        }

      ],
      columns: [
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          fieldName: 'code'
        },
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'fullName'
        }
      ],
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  if (vm.control.isShowOrgSelect) {
    control.queryschema.unshift({
      caption: vm.mlang('org'),
      name: 'orgId',
      controlType: 'Select',
      groupType: 2,
      valueType: 'LONG',
      isShowIt: true,
      placehoder: vm.mlang('org'),
      isEnum: true,
      width: 220,
      enumString: JSON.stringify(vm.queryschemaArry.queryOrgArr),
      conditionType: 'eq',
      disabled: !!vm.control.orgId,
      style: { 'margin-right': '15px' }
    });
  }
  control.queryschema.map((v) => {
    v.queryschemaCowStyle = {
      'width': '330px'
    };
  });
  return deepCopy(control);
};
export default productGroupsRefer;
