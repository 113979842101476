<script>
export default {
  name: 'VueTreeselectTip',
  functional: true,

  props: {
    type: {
      type: String,
      required: true
    },
    icon: {
      type: String
    }
  },

  render(_, context) {
    const { props, children } = context;

    return (
      <div class={`vue-treeselect__tip vue-treeselect__${props.type}-tip`}>
        {
          props.icon ? (
            <div class="vue-treeselect__icon-container">
              <span class={`vue-treeselect__icon-${props.icon}`} />
            </div>
          ) : null
        }
        <span class={`vue-treeselect__tip-text vue-treeselect__${props.type}-tip-text`}>
          {children}
        </span>
      </div>
    );
  }
};
</script>
