export default {
  activityName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500037C","活动名称") /* "活动名称" */,
  defaultModel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500037D","默认模板") /* "默认模板" */,
  calculateType: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500037E","计算方式") /* "计算方式" */,
  unifiedPricing: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500037F","统一计价") /* "统一计价" */,
  rmbunit: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000380","元") /* "元" */,
  setting: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800043","设置") /* "设置" */,
  caps: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000381","封顶") /* "封顶" */,
  defaultDeliveryCost: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000382","默认配送费用") /* "默认配送费用" */,
  unitPricing: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000383","单位计价") /* "单位计价" */,
  calculationLogic: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000384","计算逻辑") /* "计算逻辑" */,
  fullProgressive: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000385","全额累进") /* "全额累进" */,
  excessProgressive: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000386","超额累进") /* "超额累进" */,
  conditionsMail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000387","条件包邮") /* "条件包邮" */,
  mailDescription: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000388","包邮描述") /* "包邮描述" */,
  validTime: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000389","有效时间") /* "有效时间" */,
  preferetionWay: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500038A","优惠方式") /* "优惠方式" */,
  spendTo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500038B","消费满") /* "消费满" */,
  piece: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500038C","件") /* "件" */,
  packageMail: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500038D","包邮") /* "包邮" */,
  notEmpty: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500038E","不能为空") /* "不能为空" */,
  pieceNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500038F","件数") /* "件数" */,
  first: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000390","首") /* "首" */,
  toContinue: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000391","续") /* "续" */,
  stage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000392","阶段") /* "阶段" */,
  cost: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000393","费用") /* "费用" */,
  amount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000394","金额") /* "金额" */,
  allPackageMail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000395","全场包邮") /* "全场包邮" */,
  calculateQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000396","按件数计算") /* "按件数计算" */,
  calculateAmount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000397","按金额计算") /* "按金额计算" */,
  fillInCorrectly: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000398","请正确填写!") /* "请正确填写!" */,
  isGiveUp: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000399","确定要放弃么") /* "确定要放弃么" */,
  diyModel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500039A","自定义模板") /* "自定义模板" */,
  systrmModel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500039B","系统模板") /* "系统模板" */,
  modelType: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500039C","模板类型") /* "模板类型" */,
  modelLink: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500039D","链接") /* "链接" */

};
