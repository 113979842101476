import { markMessagesAsRead, delMessage } from '@/api/message';
import util from '@/libs/util.js';
import { mapGetters } from 'vuex';
import store from '@/store';
const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    // 用于删除选中的单条消息
    mixin_delMessage(ids, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('delConfirmTitle'),
        onOk: () => {
          const postData = ids; // 数组类型，保存要删除的消息id
          delMessage(postData).then(() => {
            vm.$Message.success(vm.mlang('delSuccess'));
            vm.dispatch('VoucherList', 'reload');
          });
        }
      });
    },
    // 用于删除选中的消息
    mixin_delSelectedMessages(ids, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('batchDelConfirmTitle'),
        onOk: () => {
          const postData = ids; // 数组类型，保存要删除的消息id
          delMessage(postData).then(() => {
            vm.$Message.success(vm.mlang('delSuccess'));
            vm.broadcast('VoucherList', 'reload');
          });
        }
      });
    },

    // 标记选中的消息为已读
    mixin_MarkSelectedMessagesAsRead(bldList, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('markAsReadConfirmTitleC'),
        onOk: () => {
          const postData = []; // 数组类型，保存标记为已读的消息id
          markMessagesAsRead(postData).then(() => {
            vm.$Message.success(vm.mlang('markSuccess'));
            vm.broadcast('VoucherList', 'reload');
            store.dispatch('GetUserNewsCount');
          });
        }
      });
    },
    mixin_clickBulletinDetail: (item, vm) => {
      const postList = [];
      let type = 'noRegister';
      let query = {};
      let hash = '';
      let url = '';
      if (!item.voucherNo || (item.voucherType === 'ORDER' && !item.content.orderId)) {
        return false;
      }
      postList.push(item.content.notifyId);
      markMessagesAsRead(postList).then(() => {
        if (vm.currentUser.__isAgentSide) {
          // 订单
          if (vm.$hasAuth('agentOrderShow') && item.voucherType === 'ORDER') {
            type = 'orderDetail';
            query = {
              menuid: 'orderDetail',
              cOrderNo: item.voucherNo,
              id: item.content && item.content.orderId,
              iOrderId: item.content && item.content.orderId,
              titleType: 'detail'
            };
            hash = 'detailOrder';
          }
          // 支付
          if (vm.$hasAuth('agentReturnPayShow') && item.voucherType === 'PAYMENT') {
            type = 'payDetail';
            query = {
              cPayNo: item.voucherNo,
              titleType: 'detail'
            };
          }
          // 退款
          if (item.voucherType === 'REFUND') {
            url = '/agentpage/Refunds/detail?cPayNo=' + item.voucherNo;
          }
          // 费用
          if (vm.$hasAuth('agentExpenseOrderList') && item.voucherType === 'EXPENSEORDER') {
            type = 'expenseDetail';
            query = {
              cExpenseOrderNo: item.voucherNo,
              titleType: 'detail'
            };
          }
          //
          if (item.voucherType === 'REBATE') {
            url = '/agentpage/Rebates/detail?cRebateNo=' + item.voucherNo;
          }
          // 退货
          if (item.voucherType === 'SALERETURN') {
            type = 'saleReturnDetail';
            query = {
              cSaleReturnNo: item.voucherNo,
              notEdit: true,
              titleType: 'detail',
              salereturnId: item.content && item.content.orderId
            };
          }
          if (item.voucherType !== 'OUTER') {
            vm.agentLink({ newWindow: true, type, query, hash, url });
          } else {
            window.open(item.linkUrl);
          }
        } else {
          // 跳转订单详情
          let title = '';
          let url = '';
          if (item.voucherType === 'ORDER') {
            title = vm.mlang('orderDetail');
            url = '/corppage/Orders/detail?menuid=orderList&cOrderNo=' + item.voucherNo + '#/detailOrder';
          }
          // 跳转支付详情
          if (item.voucherType === 'PAYMENT') {
            title = vm.mlang('paymentDetail');
            url = '/corppage/Payments/detail?cPayNo=' + item.voucherNo;
          }
          // 跳转退款详情
          if (item.voucherType === 'REFUND') {
            title = vm.mlang('refundDetail');
            url = '/corppage/Refunds/detail?cPayNo=' + item.voucherNo;
          }
          // 跳转退款详情
          if (item.voucherType === 'EXPENSEORDER') {
            title = vm.mlang('expenseDetail');
            url = '/corppage/ExpenseOrders/getDetail?menuid=expenseOrderList&cExpenseOrderNo=' + item.voucherNo;
          }
          // 跳转退活详情
          if (item.voucherType === 'SALERETURN') {
            title = vm.mlang('saleReturnDetail');
            url = '/corppage/SaleReturns/showSalereturnDetail?menuid=returnedPurchaseList&cSaleReturnNo=' + item.voucherNo;
          }
          if (item.voucherType === 'REBATE') {
            title = vm.mlang('rebateDetail');
            url = '/corppage/rebates/rebateDetail?cRebateNo=' + item.voucherNo;
          }
          if (item.voucherType !== 'OUTER') {
            util.menuClickOldPage(url, vm, { title });
          } else {
            window.open(item.linkUrl);
          }
        }
      });
    }
  }
};

export default mixin;
