<template>
  <div class="archive archive-user mine-noticesetting">
    <ArchiveForm
      ref="archiveForm"
      :biz-meta="meta"
      @ready="ready"></ArchiveForm>
  </div>
</template>

<script>
import ArchiveForm from '_c/common/archive-form/archive-form';
import { saveNoticeSetting, editNoticeSetting } from '@/api/productNotice';
export default {
  name: 'ProductNotice',
  components: {
    ArchiveForm
  },
  data() {
    return {
      noticeSettingId: this.$route.query.id,
      meta: {
        beforeSave: this.beforeSave,
        billId: 161,
        tableMetaSetting: false,
        actions: {
          SaveAction: saveNoticeSetting
        },
        backRoute: {
          name: 'productNotices'
        }
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  mounted() {},
  created() {},
  methods: {
    ready() {
      const archive = this.$refs.archiveForm;
      if (this.noticeSettingId) {
        editNoticeSetting(this.noticeSettingId).then((res) => {
          archive.setDetail(res);
        });
      }
    },
    beforeSave(data) {
      data.systemNoticeTerminal = 1;
      data.popup = true;
      return data;
    }
  }
};
</script>
<style lang="less" scoped>
.mine-noticesetting /deep/ .edui-container {
  box-shadow: none;
}
</style>
