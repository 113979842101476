export default {
  shoppingCartNoProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005DC","您的购物车中没有商品!") /* "您的购物车中没有商品!" */,
  minOrderQuantityAndLimitQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005DD","起订量/限购量") /* "起订量/限购量" */,
  minOrderQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005DE","起订量") /* "起订量" */,
  skuLimitQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005DF","sku限购量") /* "sku限购量" */,
  productLimitQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E0","商品限购量") /* "商品限购量" */,
  groupDisabled: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E1","组合已失效") /* "组合已失效" */,
  hadDiscount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E2","已减") /* "已减" */,
  groupSum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E3","优惠价") /* "优惠价" */,
  barcode: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E4","条码") /* "条码" */,
  clearDisabled: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E5","清除失效/下架商品") /* "清除失效/下架商品" */,
  allAmount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E6","金额总计") /* "金额总计" */,
  promotionalOffers: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E7","促销优惠") /* "促销优惠" */,
  immediateSettlement: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E8","立即结算") /* "立即结算" */,
  pleaseSelectToDelete: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005E9","请先勾选需要删除的项") /* "请先勾选需要删除的项" */,
  sureToDeletShoppingCartProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005EA","您确认删除吗") /* "您确认删除吗" */,
  ShoppingCartHaveNoDisabled: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005EB","当前购物车中没有失效商品") /* "当前购物车中没有失效商品" */,
  numberShort: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005EC","的数量小于") /* "的数量小于" */,
  numberBeyond: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005ED","的数量超过") /* "的数量超过" */,
  selectProuductPlease: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005EE","请先选择商品!") /* "请先选择商品!" */,
  productBeyond: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005EF","商品超过") /* "商品超过" */,
  buyBeyond300: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F0","下单的商品数量不能大于300!") /* "下单的商品数量不能大于300!" */,
  comDiscount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F1","组合折") /* "组合折" */,
  comPriceBreak: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F2","组合减") /* "组合减" */,
  productDiscount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F3","商品折") /* "商品折" */,
  productPriceBreak: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F4","商品减") /* "商品减" */,
  productGift: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F5","商品赠") /* "商品赠" */,
  wholeOrderGift: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F6","整单赠") /* "整单赠" */,
  leijiGift: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F7","累计赠") /* "累计赠" */,
  wholeOrderdiscount: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F8","整单折") /* "整单折" */,
  wholeOrderPriceBreak: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005F9","整单减") /* "整单减" */,
  onlyCanSubmitOneRelation: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005FA","只能选择一个组织的商品下单") /* "只能选择一个组织的商品下单" */,
  limitQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005FB","限购量") /* "限购量" */,
  descModel: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005FC","规格说明/型号") /* "规格说明/型号" */
};
