import _ from 'lodash';
const districtEnum = ['country', 'province', 'city', 'area'];

// 将对象上的country, province, city, area合并成字符串
export const combDistrictStr = (row, fieldName = 'region') => {
  row[fieldName] = getDistrictStr(row);
};

// 获取对象上的country, province, city, area合并成的字符串
export const getDistrictStr = (row) => {
  let address = '';
  if (row) {
    for (let field of districtEnum) {
      if (!row[field]) break;
      address += `${row[field]} `;
    }
  }
  return address.trimEnd();
};

// 将对象上的字符串 拆解成 country, province, city, area
export const resolveDistrictStr = (row, fieldName = 'region') => {
  if (row && row[fieldName]) {
    row[fieldName].split(/[,\s]/g).forEach((v, idx) => {
      row[districtEnum[idx]] = v || '';
    });
  }
};

// 将对象上的country, province, city, area合并成对象
export const combDistrictObj = (row, fieldName = 'region') => {
  let obj = {};
  if (row) {
    for (let field of districtEnum) {
      if (!row[field]) break;
      obj[field] = row[field];
    }
  }
  row[fieldName] = obj;
};

// 分解对象上的 country, province, city, area
export const resolveDistrictObj = (row, fieldName = 'region') => {
  let obj = row[fieldName];
  if (row && obj) {
    for (let field of districtEnum) {
      if (!obj[field]) break;
      row[field] = obj[field];
    }
  }
};
// 分解 "14|140400000000|140423000000|" ---> ["14","140400000000","40423000000,"]
export const pathStringToArry = (data, fieldName = 'region') => {
  let arr = data.path && data.path.split('|');
  if(Array.isArray(arr)){
    arr.pop();
  }
  data[fieldName] = arr;
  return data;
};
