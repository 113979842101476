import productNoticesPage from '@/views/corp/productNotice/productNotices.vue';
import productNoticePage from '@/views/corp/productNotice/productNotice.vue';

const productNotice = [
  {
    path: 'productNotices',
    name: 'productNotices',
    meta: {
      module: 'productNotices'
    },
    component: productNoticesPage
  },
  {
    path: 'productNotice',
    name: 'productNotice',
    meta: {
      module: 'productNotices',
      notCache: true
    },
    component: productNoticePage
  }
];
export default productNotice;
