<template>
  <div
    v-click-outside:mousedown.capture="onClickoutside"
    @click.prevent="itemClick"
    class="cell-edit"
  >
    <template v-if="!isEditing">
      <span class="two-line-overflow-hide" style="line-height:40px">{{ editShowValue }}</span>
    </template>
    <template v-else>
      <Cell :row="row" :column="column" :index="index" :render="column.render"></Cell>
    </template>
  </div>
</template>

<script>
import { directive as clickOutside } from 'v-click-outside-x';
import TransferDom from '@/u-components/directives/transfer-dom';
import Cell from './expand';
export default {
  name: 'TableEdit',
  props: {
    row: Object,
    index: Number,
    column: {
      type: Object,
      default: null
    }
  },
  components: {
    Cell
  },

  directives: { clickOutside, TransferDom },
  data() {
    return {
      isEditing: false
    };
  },
  computed: {
    editShowValue() {
      const option = {
        ...this.$props
      };
      return this.column.editShowKey ? this.row[this.column.editShowKey] : this.column.editShowTxt && this.column.editShowTxt(option);
    }
  },
  methods: {
    onClickoutside(e) {
      if (this.isEditing) {
        let canClose = true;
        if (e && e.type === 'mousedown') {
          const path = e.path;
          path &&
            path.map((p) => {
              if (canClose) {
                const include = this.$util.constant.TRANSFERDIV.includes(p.className);
                if (include) {
                  canClose = false;
                }
              }
            });
        }
        if (canClose) {
          setTimeout(() => {
            this.isEditing = false;
          }, 0);
        }
      }
    },
    itemClick(e) {
      this.isEditing = true;
    }
  }
};
</script>
