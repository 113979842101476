<template>
  <div
    class="action">
    <Row
      class-name="text"
      type="flex"
      justify="center">
      <span
        v-if="params.row.isShowConfirmBtn && receivablesConfrim"
        class="sureBtn solidBtn"
        @click="sure(params)">
        {{ mlang('sure') }}
      </span>
      <span
        v-if="params.row.isShowOrderUnlockBtn && receiptUnLock && !currentUser.__isAgentSide"
        @click="deblock(params.row)">
        {{ mlang('deblock') }}
      </span>
      <span
        v-if="params.row.isShowVerificationBtn && corpPaymentBalance"
        class="rollBackBtn"
        @click="verification(params)">
        {{ mlang('verification') }}
      </span>
      <span
        v-if="params.row.isShowOpposeBtn && receiptReturn"
        class="opposeBtn"
        @click="oppose(params)">
        {{ mlang('oppose') }}
      </span>
      <span
        v-if="params.row.isShowUnConfirmBtn && receiptAbandon"
        class="rollBackBtn"
        @click="backPay(params)">
        {{ mlang('backPay') }}
      </span>
      <span
        v-if="params.row.isShowBalanceBtn && paymentChange"
        class="
        openBtn"
        @click="change(params)">
        {{ mlang('change') }}
      </span>
      <span
        v-if="params.row.isShowModifyBtn && editReceipt"
        class="opposeBtn"
        @click="update(params)">
        {{ mlang('update') }}
      </span>
      <span
        v-if="isShowDelBtn"
        class="closeBtn"
        @click="del(params)">
        {{ mlang('delete') }}
      </span>
      <span
        v-if="isShowCheckPay"
        @click="checkPayStatus(params)">
        {{ mlang('checkAccounts') }}
      </span>
    </Row>
  </div>
</template>

<script>
import Emitter from '@/u-components/mixins/emitter';
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import PaymentMixin from '@/mixin/payment.js';
import util from '@/libs/util.js';
export default {
  name: 'OrderAction',
  mixins: [Emitter, PopTipMixin, CellMixin, PaymentMixin],
  data: function() {
    return {
      editReceipt: this.$hasAuth('editReceipt'),
      paymentChange: this.$hasAuth('paymentChange'),
      receivablesDelete: this.$hasAuth('receivablesDelete'),
      receiptAbandon: this.$hasAuth('receiptAbandon'),
      corpPaymentBalance: this.$hasAuth('corpPaymentBalance'),
      receivablesConfrim: this.$hasAuth('receivablesConfrim'),
      receiptReturn: this.$hasAuth('receiptReturn'),
      loading: true,
      receiptUnLock: this.$hasAuth('receiptUnLock'), // 解锁的权限码
      currentUser: this.$store.getters.currentUser
    };
  },
  computed: {
    isShowDelBtn() {
      return ([1, 3, 5].includes(this.params.row.iPayMentStatusCode) || this.params.row.isShowDeletedBtn) && this.receivablesDelete;
    },
    isShowCheckPay() {
      return (this.params.row.iPayMentStatusCode === 1);
    }
  },
  methods: {
    update(params) {
      util.menuClickOldPage(`/corppage/Payments/edit?menuid=receivablesDetail&id=${params.row.id}`, this, { title: this.mlang('paymentDetail') });
    },
    change(params) {
      util.menuClickOldPage(`/corppage/Payments/edit?menuid=receivablesDetail&id=${params.row.id}&ischange=1`, this, { title: this.mlang('paymentDetail') });
    },
    del(params) {
      this.mixin_del(params, this);
    },
    backPay(params) {
      this.mixin_backPay(params, this);
    },
    verification(params) {
      util.menuClickOldPage(`/corppage/Payments/verificationPaymentBalance?payno=${params.row.cPayNo}`, this, { title: this.mlang('verificationDetail') });
    },
    sure(params) {
      this.mixin_sure(params, this);
    },
    oppose(params) {
      this.mixin_oppose(params, this);
    },
    checkPayStatus(params) {
      this.mixin_checkPayStatus(params, this);
    },
    // ERP解锁
    deblock(params) {
      this.mixin_payMentDeblockOrder(params, this);
    }
  }
};
</script>
