<template>
  <div class="print-ctrl btn-box">
    <div
      v-if="!system.win"
      class="print-ctrl__select">
      <Select
        v-model="printViewUrl"
        :placeholder="placeholder">
        <Option
          v-for="option in printTemplates"
          :key="option.pk_print_template"
          :label="option.templatename"
          :value="option.printViewUrl"></Option>
      </Select>
    </div>
    <div class="print-ctrl__btn">
      <!-- <template v-if="system.win"> -->
      <Dropdown
        :trigger="isOneBtnPrint ? 'click' : 'hover'"
        placement="bottom-start"
        class="btn-item"
        @on-click="batchClick">
        <Button
          v-if="!isOneBtnPrint">
          {{ printBtnText }}
          <Icon type="arrow-down-b"></Icon>
        </Button>
        <div
          v-else
          class="deft-op-btn ivu-btn ivu-btn-default">
          <span @click.stop="batchClick(visibleBatchBtn[0].name)">
            {{ mlang('print') }}
          </span>
          <div class="split"></div>
          <Icon type="arrow-down-b"></Icon>
        </div>
        <DropdownMenu
          slot="list"
          class="print-list">
          <template v-for="btn in visibleBatchBtn">
            <DropdownItem
              :key="btn.name"
              :name="btn.name"
              :divided="'set-print-template' === btn.name">
              {{ btn.title }}
            </DropdownItem>
          </template>
        </DropdownMenu>
      </Dropdown>
      <!-- </template> -->
      <!-- <template v-else>
        <div
          class="btn-item">
          <div class="ivu-dropdown-rel single-print-btn">
            <Button @click="handlerPrintClick">{{ printBtnText }}</Button>
          </div>
        </div>
      </template> -->
    </div>
    <Modal
      v-model="printModal"
      width="360"
      class="print-modal"
      @on-visible-change="handleVisibleChange"
      @on-cancel="handlerCancel">
      <p
        slot="header">
        <span>打印模板选择</span>
      </p>
      <div class="print-list">
        <Select
          v-model="printViewUrl"
          :placeholder="templPlaceholder">
          <Option
            v-for="option in printTemplates"
            :key="option.id"
            :label="option.templatename"
            :value="option.printViewUrl"></Option>
        </Select>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getPrintTemplatesByCode } from '@/api/meta';
// import protocolCheck from '@/libs/protocolcheck.js';
import { findComponentsUpward } from '@/u-components/utils/assist';
export default {
  name: 'PrintCtrl',
  props: {
    modelCode: {
      type: String
    },
    btnText: {
      type: String
    },
    placeholder: {
      type: String
    },
    singeTemplParams: {
      type: String // 给单据打印用的
    },
    isOneBtnPrint: {
      type: Boolean,
      default: true
    },
    canSetTempl: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      printViewUrl: null,
      oldPrintViewUrl: null,
      printTemplates: [],
      system: {
        win: false, // Windows平台
        mac: false, // Mac平台
        x11: false // Unix平台
      },
      visibleBatchBtn: [{ name: 'direct-print', title: '直接打印' }, { name: 'preview-print', title: '预览打印' }, { name: 'set-print-template', title: '打印模板设置' }, { name: 'set-document', title: '打印配置文档' }],
      // printMap: {}, // key:value - printViewUrl:printUrl,
      printModal: false,
      templData: [],
      browser: null,
      timer: null,
      timerInterval: 2200,
      iframeEles: []
    };
  },
  computed: {
    printBtnText() {
      return this.btnText || this.mlang('print');
    },
    templPlaceholder() {
      return this.placeholder || this.mlang('defaultPrintTemplate');
    }
  },
  watch: {
    modelCode: {
      handler(val) {
        if (!val) return;
        this.getPrintTemplatesBy(val);
      },
      immediate: true
    },
    canSetTempl: {
      handler(val) {
        if (val === undefined) return;
        if (!val) this.visibleBatchBtn.pop();
      },
      immediate: true
    }
  },
  mounted() {
    let platform = navigator.platform;
    this.system.win = platform.indexOf('Win') === 0;
    this.system.mac = platform.indexOf('Mac') === 0;
    this.system.x11 = platform.indexOf('X11') === 0 || (platform.indexOf('Linux') === 0);
    this.browser = this.$util.getBrowserType();
    let hideBatchPrint = true;
    if (!this.system.win || hideBatchPrint) {
      this.visibleBatchBtn.shift();
      this.visibleBatchBtn.pop();
    }
    this.$on('on-refresh-print-templates', (code) => {
      this.getPrintTemplatesBy(code);
    });
  },

  methods: {
    batchClick(name) {
      // 模板设置和打印文档
      if (name === 'set-print-template') {
        this.printModal = true;
        return;
      } else if (name === 'set-document') {
        let docUrl = '://cdn.yonyoucloud.com/iprint/explain.html';
        this.$open(docUrl);
        return;
      }
      // 具体的操作
      if (!this.verify()) {
        return;
      }
      if (name === 'direct-print') {
        if (this.browser !== 'Chrome') {
          this.$Modal.warning({
            content: '<div><p>您使用的不是谷歌浏览器，如果想使用直接打印，请点击<a href="https://www.google.cn/chrome/" target="_blank">下载谷歌浏览器</a>并按操作<a href="https://cdn.yonyoucloud.com/iprint/explain.html" target="_blank">文档</a>配置。</p></div>',
            okText: '关闭'
          });
          return;
        }
        let params;
        let voucherlist = findComponentsUpward(this, 'VoucherList')[0];
        if (voucherlist) {
          const selectedIds = voucherlist.checkedArr;
          if (!selectedIds || !selectedIds.length) {
            this.$Message.warning('请选择单据！');
            return;
          }
          let ids = selectedIds.map((v) => {
            // 没有发货单号就是订单打印
            return v.cDeliveryNo || v.cOrderNo;
          });
          // ids = ids.join(',');
          params = ids;
        } else {
          params = [this.singeTemplParams];
        }
        this.$Modal.warning({
          content: '<div>打印开始，有' + params.length + '个打印任务需处理，请耐心等待！</div><div>温馨提示：窗口将在3秒后自动关闭！</div>'
        });
        setTimeout(() => {
          this.$Modal.remove();
          this.timer = setTimeout(this.print(this.printViewUrl, 0, params, this), this.timerInterval);
        }, this.timerInterval);
        // let url = this.printMap[this.printViewUrl] + '&params=' + params + '&testData={\"mdm_MoneyTypeTb\":[{\"nid\":\"主键测试0\",\"code\":\"币种编码测试0\",\"name\":\"币种名称测试0\"}]}';
        // protocolCheck(url, (val) => {
        //   this.$Modal.confirm({
        //     content: '<div><p style="padding-top:0;">您还未安装打印插件，请点击【下载】按钮下载并安装！</p><p style="color:#666;font-size:12px;">温馨提示：若已下载并安装，请点击关闭</p></div>',
        //     okText: '下载',
        //     cancelText: '关闭',
        //     onOk: () => {
        //       let downloadUrl = 'https://cdn.yonyoucloud.com/iprint/用友云打印助手.exe';
        //       location.href = downloadUrl;
        //     },
        //     onCancel: () => {
        //     }
        //   });
        // });
      } else {
        this.$emit('on-print-click', this.printViewUrl);
      }
    },
    handlerPrintClick(event) {
      if (this.canSetTempl && !this.verify()) {
        return;
      }
      this.$emit('on-print-click', this.printViewUrl);
    },
    verify() {
      if (!this.printTemplates || !this.printTemplates.length) {
        this.$Message.warning(this.mlang('setPrintTemplateTips'));
        return false;
      }
      if (!this.printViewUrl) {
        this.$Message.warning(this.mlang('selectPrintTemplateTips'));
        return false;
      }
      return true;
    },
    handlerCancel() {
      this.printViewUrl = this.oldPrintViewUrl;
    },
    handleVisibleChange(visible) {
      if (visible) {
        this.oldPrintViewUrl = this.printViewUrl;
      }
    },
    print(printViewUrl, preIdx, selectedIds, self) {
      let iframeEle = document.createElement('iframe');
      iframeEle.width = '100%';
      iframeEle.height = '0';
      iframeEle.style.position = 'absolute';
      iframeEle.style.visibility = 'hidden';
      self.iframeEles.push(iframeEle);
      document.body.appendChild(self.iframeEles[preIdx]);
      iframeEle = null;
      clearTimeout(self.timer);
      if (preIdx < selectedIds.length) {
        let url = window.location.origin + printViewUrl + '&pid=5&params=' + selectedIds[preIdx];
        self.iframeEles[preIdx].src = url;
        self.timer = setTimeout(() => {
          self.print(printViewUrl, preIdx + 1, selectedIds, self);
        }, self.timerInterval);
      } else {
        setTimeout(() => {
          self.$Modal.warning({
            content: '<div>打印结束！</div><div>温馨提示：窗口将在3秒后自动关闭！</div>'
          });
          setTimeout(() => { self.$Modal.remove(); }, self.timerInterval);
          self.iframeEles.forEach((ele) => { document.body.removeChild(ele); });
          self.iframeEles = [];
        }, self.timerInterval * self.iframeEles.length);
      }
    },
    getPrintTemplatesBy(code) {
      getPrintTemplatesByCode(code).then((data) => {
        // this.printMap = {};
        // data.forEach((v) => {
        //   this.printMap[v.printViewUrl] = v.printUrl;
        // });
        const defaultTemplate = data.find((v) => {
          return v.isDefault;
        });
        this.templData = data;
        this.printTemplates = data;
        this.printViewUrl = defaultTemplate && defaultTemplate.printViewUrl;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.print-ctrl {
   &__select {
      min-width: 180px;
 //   margin-right: 10px;
      float: left;
      .ivu-select {
        display: inline-block;
        /deep/ .ivu-select-selection {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

   }
    &__btn {
      float: left;
      .deft-op-btn {
        padding: 0 4px 0 12px;
        line-height: 26px;
        .split {
          margin: 0 2px;
          display: inline-block;
          height: 1.4em;
          width: 1px;
          vertical-align: middle;
          position: relative;
          top: -.06em;
          background: #e8eaec;
        }
      }

      .btn-item .single-print-btn {
        button.ivu-btn {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }

      }
   }
}
.print-list {
  .ivu-dropdown-item {
    padding: 7px 16px;
  }
  .ivu-dropdown-item-divided {
    margin-top: 0;
    &:before {
      height: 0;
    }
  }
}
.print-modal {
  /deep/ .ivu-modal{
    .ivu-modal-header {
      border-bottom: none;
    }
    .print-list {
      text-align: center;
      margin: 20px 30px;
    }
    .ivu-modal-footer {
      border-top: none;
    }
  }
}
</style>
