<template>
  <div class="order-no">
    <a
      :href="`/corppage/Orders/detail?menuid=orderList&cOrderNo=${params.row.cOrderNo}#/detailOrder`"
      target="_blank">{{ params.row.cOrderNo }}</a>
    <div class="orderno-svg">
      <Tooltip
        v-if="!params.row.isAgentSubmit"
        :content="mlang('daikexiadan')">
        <Icon
          v-if="!params.row.isAgentSubmit"
          custom="iconfont icon-daikexiadan"></Icon>
      </Tooltip>
      <Tooltip
        v-if="params.row.cSource === 1"
        :content="mlang('shoujixiadan')">
        <Icon
          v-if="params.row.cSource === 1"
          custom="iconfont icon-shoujixiadan"></Icon>
      </Tooltip>
      <order-memo
        v-if="params.row.isHaveMemo"
        :params="params"></order-memo>
      <Icon custom="iconfont icon-u8"></Icon>
    </div>
  </div>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'OrderNo',
  mixins: [PopTipMixin, CellMixin],
  data: function() {
    return {};
  },
  methods: {}
};
</script>
