<template>
  <div class="agent-navigation">
    <div class="agent-w">
      <ul class="agent-navigation-left">
        <li>
          <OrgRelation />
        </li>
      </ul>
      <ul class="agent-navigation-right">
        <li
          v-if="isTourist"
          style="line-height:100%;margin-right:20px">
          <a href="/agentpage/users/join"> <span class="ser">{{ mlang('applyToJoin') }}</span></a>
        </li>
        <li class="list list-name">
          <span>{{ mlang('hello') }}</span>
          <span v-if="!showChangeAccount">{{ currentUser.fullName }}</span>
          <ChangeAccount v-if="showChangeAccount" fieldid="fieldid_topBar_changeAccount" />
        </li>
        <li v-if="!hideLogout">
          <p class="header-exact log-span">
            <a href="/logout" fieldid="fieldid_updateLog_logout" @click="toLogout">
              {{ mlang('logout') }}
            </a>
          </p>
        </li>
        <li class="list list-company list-border">
          <GenerationOrder v-if="showGenerateOrder" fieldid="fieldid_topBar_generationOrder" />
        </li>
        <li class="list list-information">
          <MsgPush class="header-message" placement="bottom" :offset="-11" fieldid="fieldid_topBar_msgPush" />
        </li>
        <li class="list list-customerService list-right-border">
          <CustomerService fieldid="fieldid_topBar_customerService" />
        </li>
        <li v-if="!showGenerateOrder" class="list list-customerService list-right-border">
          <p class="header-exact">
            <a href="javascript:void(0)" fieldid="fieldid_updateLog_myAccount" @click="toMyAccount()">
              {{ mlang('myAccount') }}
            </a>
          </p>
        </li>
        <li v-if="currentUser.__isRepresent && !currentUser.__isU8C" class="list list-qi">
          <a :title="mlang('jinruguanliduan')" fieldid="fieldid_topBar_toCorp" @click="toCorp">
            <Icon class="iconfont icon-dinghuo-daixiadanbeifen" />
          </a>
        </li>
        <li v-if="showHubbleBtn" class="list list-xiaoxi">
          <UpdateLog fieldid="fieldid_topBar_updateLog" />
        </li>
        <li class="list list-language">
          <Language fieldid="fieldid_topBar_language" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MsgPush from '@/components/msg-push/msg-push';
import Language from '@/components/language';
import GenerationOrder from '@/components/generation-order/generation-order.vue';
import ChangeAccount from '@/components/change-account/change-account.vue';
import CustomerService from '@/components/customer-service/customer-service.vue';
import UpdateLog from '@/components/updateLog/updateLog.vue';
import OrgRelation from '@/components/org-relation/orgRelation.vue';
import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';
import printCookie from '@/components/print-ctrl/print-cookie.js';
import { getAgentMainance } from '@/api/customer/agentBaseInfo';
import { getAllRegion } from '@/api/region';
import util from '@/libs/util.js';

export default {
  name: 'TopBar',
  components: {
    MsgPush,
    GenerationOrder,
    ChangeAccount,
    CustomerService,
    UpdateLog,
    OrgRelation,
    Language
  },
  data() {
    return {
      value: null,
      hideLogout: window.localStorage.getItem('isCorpToOrder'),
      showGenerateOrder: window.localStorage.getItem('isRepresentToOrder'),
      showHubbleBtn: window.localStorage.getItem('hubble')
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'ueConfig']),
    showChangeAccount() {
      return !this.hideLogout && localStorage.isRepresentToOrder !== 'true';
    },
    isTourist() {
      return window.localStorage.getItem('tourist') === 'true';
    },
    isOnlyShowNew() {
      return window.localStorage.getItem('versionSwitchControl') === 'true';
    }
  },
  methods: {
    toMessage() {
      this.$router.push('/agent/message/index');
    },
    init() {},
    goOldPage() {
      location.href = '/agent/?old=1';
      localStorage.setItem('isNewVersion', false);
      Cookies.set('isNewVersion', false, { expires: 730 });
    },
    toCorp() {
      this.$util.clearUpAgentInfo();
      location.href = '/corp/';
    },
    async toLogout() {
      let arr = ['iPaytype', 'recoProducts', 'productPageSize', 'allProducts', 'pcScartSelected', 'scartInvaild', 'storeOrder', 'isRepresentToOrder', 'regionList'];
      this.removeStorage(arr);
      printCookie.clearToken();
      if (window !== top) {
        if (window.localStorage.getItem('isDataCenter')) window.localStorage.removeItem('isDataCenter');
        window.parent.postMessage({ key: 'logout' }, '*');
      }
      const dbManager = await util.getIndexedDBManager();
      await dbManager.removeItem('regionList');
    },
    removeStorage(arr) {
      if (arr.length) {
        arr.map((v) => {
          if (window.localStorage.getItem(v)) {
            window.localStorage.removeItem(v);
          }
        });
      }
    },
    async toMyAccount() {
      const currentUser = this.$store.getters.currentUser;
      getAgentMainance().then((data) => {
        let isCantmaintance = '';
        data && Object.entries(data).forEach(function(v) { if (v[1]) { isCantmaintance += (v[0] + ';'); } });
        this.$router.push({
          name: 'agentbase',
          query: {
            isCantmaintance,
            agentId: currentUser.agentId,
            userId: currentUser.userId
          }
        });
      });
      const dbManager = await util.getIndexedDBManager();
      let regionList = await dbManager.getItem('regionList');
      //! window.localStorage.getItem('regionList')
      !regionList && getAllRegion().then(async(res) => {
        await dbManager.setItem('regionList', JSON.stringify(res));
        // localStorage.setItem('regionList', JSON.stringify(res));
      });
    }

  }
};
</script>

<style lang="less" scoped>
.agent-navigation-right {
  /deep/ .header-inline-center {
    margin-top: 0;
    margin-left: 0;
    float: none;
    font-size: 12px;
  }
  /deep/ .headerName {
    text-align: left;
  }
  .list-customerService{
    margin-left: 10px;
  }
}
.log-span{
  margin-left: 12px;
}

.list-right-border{
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #D6D6D6;
  padding-right: 12px;
}
</style>
