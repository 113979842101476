import { getOldYearSelect } from '@/api/salereturn';
const mixin = {
  inject: ['reloadPage'],
  data() {
    return {
      isHistory: this.$route.query.type === 'history'
    };
  },
  mounted() {},
  computed: {},
  methods: {
    oldYearAction(item) {
      item.enumArray = [];
      item.clearable = false;
      let str = '';
      switch (this.$route.path) {
        case '/corp/orders':
          str = 'ORDER';
          break;
        case '/corp/payments':
          str = 'PAYMENT';
          break;
        case '/corp/salereturns':
          str = 'SALERETURN';
          break;
        case '/corp/refunds':
          str = 'REFUND';
          break;
        case '/corp/deliverys':
          str = 'SHIPPING';
          break;
        case '/corp/goodsRebates':
        case '/corp/discountRebates':
          str = 'REBATE';
          break;
      }
      getOldYearSelect(str).then((data) => {
        data.content && data.content.forEach((v) => {
          v.value = v.title = v.name = v.showName;
        });
        this.$refs['voucherList'].setFilterItem(item.name, 'enumArray', data.content);
        this.$refs['voucherList'].setFilterItem(item.name, 'defaultValue', data.content[0].showName);
        this.mixin_onFilterChange(item, data.content[0]);
      });
      return item;
    },
    dealOldYear(query) {
      let getoldYearCondition = query.querySchema.conditions.find((v) => { return v.name === 'oldYear'; });
      if (this.isHistory && !getoldYearCondition) return false;
      query.querySchema.conditions.forEach((v, index) => {
        if (v.name === 'oldYear') {
          query.querySchema.conditions.splice(index, 1);
        }
      });
    },
    mixin_onFilterChange(control, enumKey, tip) {
      if (control.name === 'oldYear') {
        if (enumKey) {
          if (tip) {
            this.$Modal.confirm({
              content: this.$route.path === '/corp/orders' ? this.mlang('oldYearSelectChangetip1') : this.mlang('oldYearSelectChangetip2'),
              onOk: (res) => {
                this.$set(this.meta, 'onlyOneYear', enumKey);
              }
            });
          } else {
            this.$set(this.meta, 'onlyOneYear', enumKey);
          }
        } else {
          this.$set(this.meta, 'onlyOneYear', -1);
        }
      }
    },
    afterListSearch(query, name, single) {
      let obj = query.querySchema.conditions.find((v) => {
        return v.name === name;
      });
      // 时间控件单个为空，查询的是选定时间的前后半年
      if (single) {
        this.$set(this.meta, 'resetSingleDateTime', false);
        if (obj && ((obj.v1 && !obj.v2) || (obj.v2 && !obj.v1))) {
          this.$set(this.meta, 'resetSingleDateTime', true);
        } else {
          this.$set(this.meta, 'resetSingleDateTime', false);
        }
      }
      // 时间控件前后为空查询只是近半年
      this.$set(this.meta, 'resetPredicate', false);
      // if (!obj || (obj && !obj.v1 && !obj.v2)) {
      //   this.$set(this.meta, 'resetPredicate', true);
      // } else {
      //   this.$set(this.meta, 'resetPredicate', false);
      // }
    }
  }
};

export default mixin;
