<template>
  <div class="agent-middle-main">
    <!-- <Col class="currentLocation">
    {{ mlang('home') }}
    <i class="afterFirst iconfont icon-right-disabled"></i>
    {{ mlang('myAccount') }}
    <i class="iconfont icon-right-disabled"></i>
    <span>{{ currentTab }}</span>
    </Col> -->
    <Row type="flex" justify="center" align="top" class-name="person-content">
      <Col ref="personL" span="4" class-name="person-l">
      <ul class="personInfo_nav">
        <li v-for="(item,index) in tabs" :key="index" class="list">
          <a :class="active(item)" @click="handleClick(item)">{{ item.name }}</a>
        </li>
      </ul>
      </Col>
      <Col ref="personR" span="20" class-name="person-r">
      <div class="personInfo_r">
        <router-view></router-view>
      </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { getAccountMenu, getUserCenter } from '@/api/user';
import { getAgentMainance } from '@/api/customer/agentBaseInfo';
export default {
  data() {
    const tabs = [];
    return {
      tabs,
      tabValue: tabs[0],
      currentTab: '',
      isCantmaintance: ''
    };
  },
  computed: {},
  created() {
    this.init();
    getAccountMenu().then((res) => {
      res = res.filter((item) => {
        return item.url !== '#/mutiAgentDataTabs';
      });
      let defaultUserInfo = JSON.parse(localStorage.defaultUserInfo);
      if(Array.isArray(res) && res.length > 1 && defaultUserInfo.tenantType == 4){
        var item = res[0];
        res[0] = res[1];
        res[1] = item;
      }
      this.tabs = res;
      this.$store.dispatch('setUserAccountMenu', res);
    });
  },
  methods: {
    init() {
      getAgentMainance().then((data) => {
        let auths = '';
        data && Object.entries(data).forEach(function(v) { if (v[1]) { auths += (v[0] + ';'); } });
        this.isCantmaintance = auths;
      });
    },
    active(item) {
      return item.key === this.$route.meta.module ? 'active-li' : '';
    },
    handleClick(item) {
      //个人信息跳转友互通
      if(item.url=='#/userbase'){
        getUserCenter().then((data)=>{
          if (data) {
            if(window!==top){
              window.parent.postMessage({ href: data, openNew: true }, '*');
            }else{
              window.open(data);
            }
          }
        })
        return;
      }
      const path = item.tabs[0].key;
      this.$router.push({
        name: path,
        query: {
          isCantmaintance: this.isCantmaintance
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.active-li {
  border-bottom-color: #0c479d !important;
  color: #0c479d;
}
.currentLocation{
  margin: 10px 0;
  font-size:12px;
  font-weight:400;
  color:rgba(153,153,153,1);
  span{
    color:rgba(102,102,102,1);
  }
  i{
    font-size: 5px;
    margin: 0 6px;
  }
  .afterFirst{
    margin-left: 32px;
  }
}
.person-content{
  min-height: 602px;
  display:flex;
  align-items: stretch;
  justify-content: inherit;
  margin-top: 15px;
  .person-l {
    width: 163px;
    background: #ffffff;
    padding-top:2px ;
    li{
      margin-top:14px ;
      margin-left: 20px;
      a{
        font-size:14px;
        font-weight:400;
        color:rgba(102,102,102,1);
        &:hover{
          font-size:16px;
          font-weight:500;
          color:rgba(51,51,51,1);
        }
      }
      .active-li{
        font-size:16px;
        font-weight:500;
        //color: red;
        color:rgba(51,51,51,1);
        position: relative;
        margin-left: 14px;
        &:before{
          width:6px;
          height:6px;
          background:rgba(238,34,35,1);
          border-bottom-right-radius: 4px;
          content: "";
          position: absolute;
          left:-14px;
          top: 8px;
        }
      }

    }
  }
  .person-r {
    width: 1017px;
    margin-left: 20px;
    background: #fff;
  }
}

</style>
