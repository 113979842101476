import ajax from '@/libs/ajax';
const zhCnMobilecountry = {
  code: 200,
  data: [
    {
      "id": "27",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "86",
      "name": "中国大陆",//@notranslate
      "pattern": "^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$",
      "isDefault": false,
      "order": "1",
      "viewName": "86(中国大陆)",//@notranslate
    },
    {
      "id": "28",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "852",
      "name": "中国香港",//@notranslate
      "pattern": "^(5|6|9)[0-9]{7}$",
      "isDefault": false,
      "order": "3",
      "viewName": "852(中国香港)",//@notranslate
    },
    {
      "id": "29",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "853",
      "name": "中国澳门",//@notranslate
      "pattern": "^6[0-9]{7}$",
      "isDefault": false,
      "order": "5",
      "viewName": "853(中国澳门)",//@notranslate

    },
    {
      "id": "30",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "886",
      "name": "中国台湾",//@notranslate
      "pattern": "^(9|09)[0-9]{8}$",
      "isDefault": false,
      "order": "7",
      "viewName": "886(中国台湾)",//@notranslate

    },
    {
      "id": "31",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "60",
      "name": "马来西亚",//@notranslate
      "pattern": "(^(10|010|12|012|13|013|14|014|16|016|17|017|18|018|19|019)[0-9]{7}$)|(^(11|011)[0-9]{8}$)",
      "isDefault": false,
      "order": "9",
      "viewName": "60(马来西亚)",//@notranslate

    },
    {
      "id": "32",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "63",
      "name": "菲律宾",//@notranslate
      "pattern": "^(09|9)[0-9]{9}$",
      "isDefault": false,
      "order": "9",
      "viewName": "63(菲律宾)",//@notranslate

    },
    {
      "id": "33",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "61",
      "name": "澳大利亚",//@notranslate
      "pattern": "^(04|4)[0-9]{8}$",
      "isDefault": false,
      "order": "11",
      "viewName": "61(澳大利亚)",//@notranslate

    },
    {
      "id": "34",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "62",
      "name": "印度尼西亚",//@notranslate
      "pattern": "(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{7}$)|(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{8}$)|(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{9}$)",
      "isDefault": false,
      "order": "13",
      "viewName": "62(印度尼西亚)",//@notranslate

    },
    {
      "id": "35",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "65",
      "name": "新加坡",//@notranslate
      "pattern": "^(8|9)[0-9]{7}$",
      "isDefault": false,
      "order": "15",
      "viewName": "65(新加坡)",//@notranslate

    },
    {
      "id": "36",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "66",
      "name": "泰国",//@notranslate
      "pattern": "^(06|08|09|6|8|9)[0-9]{8}$",
      "isDefault": false,
      "order": "17",
      "viewName": "66(泰国)",//@notranslate

    },
    {
      "id": "37",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "84",
      "name": "越南",//@notranslate
      "pattern": "(^(09|9)[0-9]{7}$)|(^(03|3|08|8|09|9)[0-9]{8}$)|(^(01|1|09|9)[0-9]{9}$)",
      "isDefault": false,
      "order": "19",
      "viewName": "84(越南)",//@notranslate

    },
    {
      "id": "38",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "95",
      "name": "缅甸",//@notranslate
      "pattern": "(^(09|9)[0-9]{7}$)|(^(09|9)[0-9]{8}$)|(^(09|9)[0-9]{9}$)",
      "isDefault": false,
      "order": "21",
      "viewName": "95(缅甸)",//@notranslate

    },
    {
      "id": "39",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "855",
      "name": "柬埔寨",//@notranslate
      "pattern": "^(010|011|012|014|015|016|017|060|061|066|067|068|069|070|071|076|077|078|079|081|085|086|087|088|089|090|092|093|095|096|097|098|099)[0-9]*$",
      "isDefault": false,
      "order": "22",
      "viewName": "855(柬埔寨)",//@notranslate

    },
    {
      "id": "79",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "00250",
      "name": "卢旺达",//@notranslate
      "pattern": "[0-9]{9,11}",
      "isDefault": false,
      "order": "123",
      "viewName": "00250(卢旺达)",//@notranslate

    }
  ]
}
const zhTwMobilecountry = {
    "data":[
        {
            "id":"40",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"86",
            "name":"中國大陸",//@notranslate
            "pattern":"^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$",
            "isDefault":false,
            "order":"1",
            "viewName":"86(中國大陸)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"41",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"852",
            "name":"中國香港",//@notranslate
            "pattern":"^(5|6|9)[0-9]{7}$",
            "isDefault":false,
            "order":"3",
            "viewName":"852(中國香港)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"42",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"853",
            "name":"中國澳門",//@notranslate
            "pattern":"^6[0-9]{7}$",
            "isDefault":false,
            "order":"5",
            "viewName":"853(中國澳門)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"43",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"886",
            "name":"中國台灣",//@notranslate
            "pattern":"^(9|09)[0-9]{8}$",
            "isDefault":false,
            "order":"7",
            "viewName":"886(中國台灣)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"44",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"60",
            "name":"馬拉西亞",//@notranslate
            "pattern":"(^(10|010|12|012|13|013|14|014|16|016|17|017|18|018|19|019)[0-9]{7}$)|(^(11|011)[0-9]{8}$)",
            "isDefault":false,
            "order":"9",
            "viewName":"60(馬拉西亞)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"45",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"63",
            "name":"菲律賓",//@notranslate
            "pattern":"^(09|9)[0-9]{9}$",
            "isDefault":false,
            "order":"9",
            "viewName":"63(菲律賓)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"46",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"61",
            "name":"澳大利亞",//@notranslate
            "pattern":"^(04|4)[0-9]{8}$",
            "isDefault":false,
            "order":"11",
            "viewName":"61(澳大利亞)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"47",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"62",
            "name":"印度尼西亞",//@notranslate
            "pattern":"(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{7}$)|(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{8}$)|(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{9}$)",
            "isDefault":false,
            "order":"13",
            "viewName":"62(印度尼西亞)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"48",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"65",
            "name":"新加坡",//@notranslate
            "pattern":"^(8|9)[0-9]{7}$",
            "isDefault":false,
            "order":"15",
            "viewName":"65(新加坡)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"49",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"66",
            "name":"泰國",//@notranslate
            "pattern":"^(06|08|09|6|8|9)[0-9]{8}$",
            "isDefault":false,
            "order":"17",
            "viewName":"66(泰國)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"50",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"84",
            "name":"越南",//@notranslate
            "pattern":"(^(09|9)[0-9]{7}$)|(^(03|3|08|8|09|9)[0-9]{8}$)|(^(01|1|09|9)[0-9]{9}$)",
            "isDefault":false,
            "order":"19",
            "viewName":"84(越南)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"51",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"95",
            "name":"緬甸",//@notranslate
            "pattern":"(^(09|9)[0-9]{7}$)|(^(09|9)[0-9]{8}$)|(^(09|9)[0-9]{9}$)",
            "isDefault":false,
            "order":"21",
            "viewName":"95(緬甸)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"52",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"855",
            "name":"柬埔寨",//@notranslate
            "pattern":"^(010|011|012|014|015|016|017|060|061|066|067|068|069|070|071|076|077|078|079|081|085|086|087|088|089|090|092|093|095|096|097|098|099)[0-9]*$",
            "isDefault":false,
            "order":"22",
            "viewName":"855(柬埔寨)",//@notranslate
            "langCode":"zh-tw"
        },
        {
            "id":"82",
            "version":0,
            "deletedValue":{
                "isDeleted":0
            },
            "code":"00250",
            "name":"盧旺達",//@notranslate
            "pattern":"[0-9]{9,11}",
            "isDefault":false,
            "order":"100",
            "viewName":"00250(盧旺達)",//@notranslate
            "langCode":"zh-tw"
        }
    ],
    "code":200
}
const enUsMobilecountry = {
  "data": [
    {
      "id": "53",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "86",
      "name": "China",
      "pattern": "^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$",
      "isDefault": false,
      "order": "1",
      "viewName": "86(China)",
      "langCode": "en-us"
    },
    {
      "id": "54",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "852",
      "name": "Hong Kong(China)",
      "pattern": "^(5|6|9)[0-9]{7}$",
      "isDefault": false,
      "order": "3",
      "viewName": "852(Hong Kong(China))",
      "langCode": "en-us"
    },
    {
      "id": "55",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "853",
      "name": "Macau(China)",
      "pattern": "^6[0-9]{7}$",
      "isDefault": false,
      "order": "5",
      "viewName": "853(Macau(China))",
      "langCode": "en-us"
    },
    {
      "id": "56",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "886",
      "name": "Taiwan(China)",
      "pattern": "^(9|09)[0-9]{8}$",
      "isDefault": false,
      "order": "7",
      "viewName": "886(Taiwan(China))",
      "langCode": "en-us"
    },
    {
      "id": "57",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "60",
      "name": "Malaysia",
      "pattern": "(^(10|010|12|012|13|013|14|014|16|016|17|017|18|018|19|019)[0-9]{7}$)|(^(11|011)[0-9]{8}$)",
      "isDefault": false,
      "order": "9",
      "viewName": "60(Malaysia)",
      "langCode": "en-us"
    },
    {
      "id": "58",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "63",
      "name": "Philippines",
      "pattern": "^(09|9)[0-9]{9}$",
      "isDefault": false,
      "order": "9",
      "viewName": "63(Philippines)",
      "langCode": "en-us"
    },
    {
      "id": "59",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "61",
      "name": "Australia",
      "pattern": "^(04|4)[0-9]{8}$",
      "isDefault": false,
      "order": "11",
      "viewName": "61(Australia)",
      "langCode": "en-us"
    },
    {
      "id": "60",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "62",
      "name": "Indonesia",
      "pattern": "(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{7}$)|(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{8}$)|(^(81|081|82|082|83|083|85|085|87|087|89|089)[0-9]{9}$)",
      "isDefault": false,
      "order": "13",
      "viewName": "62(Indonesia)",
      "langCode": "en-us"
    },
    {
      "id": "61",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "65",
      "name": "Singapore",
      "pattern": "^(8|9)[0-9]{7}$",
      "isDefault": false,
      "order": "15",
      "viewName": "65(Singapore)",
      "langCode": "en-us"
    },
    {
      "id": "62",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "66",
      "name": "Thailand",
      "pattern": "^(06|08|09|6|8|9)[0-9]{8}$",
      "isDefault": false,
      "order": "17",
      "viewName": "66(Thailand)",
      "langCode": "en-us"
    },
    {
      "id": "63",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "84",
      "name": "Viet Nam",
      "pattern": "(^(09|9)[0-9]{7}$)|(^(03|3|08|8|09|9)[0-9]{8}$)|(^(01|1|09|9)[0-9]{9}$)",
      "isDefault": false,
      "order": "19",
      "viewName": "84(Viet Nam)",
      "langCode": "en-us"
    },
    {
      "id": "64",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "95",
      "name": "Burma",
      "pattern": "(^(09|9)[0-9]{7}$)|(^(09|9)[0-9]{8}$)|(^(09|9)[0-9]{9}$)",
      "isDefault": false,
      "order": "21",
      "viewName": "95(Burma)",
      "langCode": "en-us"
    },
    {
      "id": "65",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "855",
      "name": "Kampuchea",
      "pattern": "^(010|011|012|014|015|016|017|060|061|066|067|068|069|070|071|076|077|078|079|081|085|086|087|088|089|090|092|093|095|096|097|098|099)[0-9]*$",
      "isDefault": false,
      "order": "22",
      "viewName": "855(Kampuchea)",
      "langCode": "en-us"
    },
    {
      "id": "80",
      "version": 0,
      "deletedValue": {
        "isDeleted": 0
      },
      "code": "00250",
      "name": "Rwanda",
      "pattern": "[0-9]{9,11}",
      "isDefault": false,
      "order": "123",
      "viewName": "00250(Rwanda)",
      "langCode": "en-us"
    }
  ],
  "code": 200
}
export const getCountry = () => {
  // return ajax.request({
  //   url: '/archives/system/mobilecountry',
  //   method: 'get',
  //   data: {
  //     isArchive: 1,
  //     nodeal: true
  //   }
  // });
  let langData = localStorage.getItem('lang') === 'en-us'
    ? enUsMobilecountry
    : localStorage.getItem('lang') === 'zh-tw'
      ? zhTwMobilecountry
      : zhCnMobilecountry
  return Promise.resolve(langData)
};
