//销售合同维护选中的合同以及商品
const contract = {
  state: {
    contract: null,
    tempStoreProducts: null,
    fromStoreOrder: false
  },
  getters: {
    GET_CONTRACT: (state, getters, rootState) => state.contract,
    GET_CONTRACT_TEMPSTOREPRODUCTS: (state, getters, rootState) => state.tempStoreProducts, //合同下单选择的商品
    GET_CONTRACT_FROMSTOREORDER: (state, getters, rootState) => state.fromStoreOrder //暂存订单时合同的标识
  },
  mutations: {
    SET_CONTRACT: (state, data) => {
      state.contract = data;
    },
    SET_CONTRACT_TEMPSTOREPRODUCTS: (state, data) => {
      state.tempStoreProducts = data;
    },
    SET_CONTRACT_FROMSTOREORDER: (state, data) => {
      state.fromStoreOrder = data;
    }
  },
  actions: {
  }
};

export default contract;
