import { deepCopy } from '@/u-components/utils/assist';
// import { selectSrcBillNo } from '@/api/refer-modal';

const srcBillTreeRefer = (ctrl, vm) => {
  let _user = vm.$store.getters.currentUser;
  const columns = [
    {
      showCaption: vm.mlang('orders.srcName'),
      isShowIt: true,
      fieldName: 'contractName',
      width: 120
    },
    {
      showCaption: vm.mlang('orders.srcCode'),
      fieldName: 'contractCode',
      width: 180,
      isShowIt: true
    }
    // {
    //   showCaption: vm.mlang('orders.salesOrgName'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'salesOrgName'
    // },
    // {
    //   showCaption: vm.mlang('orders.contractCtrlType'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'contractCtrlType'
    // },
    // {
    //   showCaption: vm.mlang('orders.productName'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'productName'
    // },
    // {
    //   showCaption: vm.mlang('orders.skuName'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'skuName'
    // },
    // {
    //   showCaption: vm.mlang('orders.priceQty'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'priceQty'
    // },
    // {
    //   showCaption: vm.mlang('orders.saleunitName'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'saleunitName'
    // },
    // {
    //   showCaption: vm.mlang('orders.subQty'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'subQty'
    // },
    // {
    //   showCaption: vm.mlang('orders.oriTaxUnitPrice'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'oriTaxUnitPrice'
    // },
    // {
    //   showCaption: vm.mlang('orders.cqtUnitName'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'cqtUnitName'
    // },
    // {
    //   showCaption: vm.mlang('orders.oriSum'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'oriSum'
    // },
    // {
    //   showCaption: vm.mlang('orders.oriMoney'),
    //   isShowIt: true,
    //   width: 150,
    //   fieldName: 'oriMoney'
    // }
  ];
  const control = Object.assign(
    {},
    {
      key: 'srcBillOrderRef', // 只有为这个才会显示特殊商品参照
      noMetaSetting: true,
      referType: 'Table',
      pagerSizeOpts: [10, 20],
      // 传递过去的参数
      passedparams: [
        {
          name: 'agentId',
          controlType: 'String',
          valueType: 'STRING',
          groupType: 2,
          conditionType: 'eq',
          v1: _user.agentId
        }
      ],
      showTitle: true,
      closeable: true,
      page: true,
      bRefer: true,
      controlType: 'Table',
      refReturnValue: 'id',
      refName: 'productName',
      queryschema: [
        {
          name: 'keyword',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          conditionType: 'eq',
          isShowIt: true,
          placeholder: vm.mlang('orders.srcNameSrcCode')
        },
      ],
      queryschema2: [
        {
          name: 'contractDetailKeyword',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          conditionType: 'eq',
          isShowIt: true,
          placeholder: vm.mlang('orders.searchSrcDetailCode')
        }
      ],
      queryschema3: [
        {
          name: 'productKeyword',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          conditionType: 'eq',
          isShowIt: true,
          placeholder: vm.mlang('orders.searchSrcDetailCode')
        }
      ],
      columns,
      actions: { queryTree: '' }
    },
    ctrl
  );
  control.key = 'srcBillOrderRef';
  if (!vm.$getBO('ORDERSHOWWAY')) {
    control.passedparams.push({
      name: 'saleOrgId',
      controlType: 'String',
      groupType: 2,
      valueType: 'STRING',
      conditionType: 'eq',
      v1: vm.orgId
    });
  }
  return deepCopy(control);
};
export default srcBillTreeRefer;
