export default {
  detail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB3","详情") /* "详情" */,
  modify: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB4","修改") /* "修改" */,
  abandon: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB5","弃审") /* "弃审" */,
  confirmRefundContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB6","是否确认退款单？") /* "是否确认退款单？" */,
  confirmRefundSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB7","审核通过！") /* "审核通过！" */,
  backConfirmRefundContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB8","是否对退款单") /* "是否对退款单" */,
  backConfirmRefundContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB9","进行弃审？") /* "进行弃审？" */,
  backConfirmRefundSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DBA","弃审成功!") /* "弃审成功!" */,
  delRefundContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DBB","确定删除退款单") /* "确定删除退款单" */,
  delRefundContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DBC","吗？") /* "吗？" */,
  delRefundSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DBD","删除成功!") /* "删除成功!" */,
  // 驳回oppose
  oTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DBE","请填写驳回意见,当前退款单将被自动关闭!") /* "请填写驳回意见,当前退款单将被自动关闭!" */,
  oTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DBF","驳回原因") /* "驳回原因" */,
  oRejectReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC0","请填写驳回原因") /* "请填写驳回原因" */,
  opposeSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DC1","驳回成功") /* "驳回成功" */
};
