<template>
  <div class="payment-status">
    <Poptip
      v-if="(params.row.presale && currentUser.__isAgentSide && showPresaleTip) || (params.row.groupBuyPromotion && currentUser.__isAgentSide && showGroupBuyTip) "
      :options="popTipProps"
      :trigger="popTrigger"
      placement="bottom"
      popper-class="poptip"
      @on-popper-show="onPopperShowPre">
      <Icon v-if="(params.row.presale && currentUser.__isAgentSide && showPresaleTip) || (params.row.groupBuyPromotion && currentUser.__isAgentSide && showGroupBuyTip)" custom="iconfont icon-genzongxinxi"></Icon>
      <div
        slot="content"
        class="payment-status-poptip-content">
        <div v-if="showPresaleTip">
          <span>{{ mlang('cutoff') }}</span>
          <span>{{ showEndTime }}</span>
          <span>，{{ mlang("shouldpay") }}</span>
          <span>{{ params.row.presale.depositPercent }}</span>
          <span>%，{{ mlang('orAutoRturn') }}</span>
        </div>
        <div v-if="showGroupBuyTip">
          <span>{{ mlang('cutoff') }}</span>
          <span>{{ showGroupBuyEndTime }}</span>
          <span>，{{ mlang("shouldpay") }}</span>
          <span>{{ params.row.groupBuyPromotion.depositPercent }}</span>
          <span>%，{{ mlang('orAutoRturn') }}</span>
        </div>
      </div>
    </poptip>
    <span v-if="(params.row.presale && currentUser.__isAgentSide && !showPresaleTip) || (params.row.groupBuyPromotion&& currentUser.__isAgentSide && !showGroupBuyTip)" class="disabledIcon" @mouseenter="onPopperShowPre">
      <Icon custom="iconfont icon-genzongxinxi"></Icon>
    </span>

    <Status
      :params="params"
      :column="column"
      :id-key="params.row['cPayStatusCode']"
      name-key="cOrderPaymentStatusName"></Status>
    <Poptip
      v-if="params.row.cPayStatusCode !='NOTPAYMENT' && params.row.cNextStatus!='OPPOSE' && params.row.fRealMoney>0"
      :options="popTipProps"
      :trigger="popTrigger"
      placement="bottom"
      popper-class="poptip"
      @on-popper-show="onPopperShow">
      <div class="payment-status-msg">
        <Icon
          custom="iconfont icon-fukuanxinxi"></Icon>
        {{ mlang('payrecord') }}
        <Icon type="arrow-down-b"></Icon>
      </div>
      <div
        slot="content"
        class="payment-status-poptip-content logistics payment-record">
        <ul>
          <li
            v-for="(payRecord,index) in payRecords"
            :key="index"
            :class="{active:index==0}">
            <dl>
              <dt>{{ payRecord.cVerificactionTypeName }}</dt>
              <dd class="pay">
                {{ payRecord.cVerificactionTypeName }} <span>{{ payRecord.cPvfStatusName }}</span>
              </dd>
              <dd class="money">
                {{ payRecord.iAmount | upCoinDigit('money') | priceFree('0') | upHideString('iAmount') }} <span>{{ payRecord.cPaymentStatusName }}</span>
              </dd>
              <dd class="time">
                {{ $util.formatToCtxDateTime(payRecord.dPayDate) }}
              </dd>
            </dl>
            <i class="round"><b></b></i>
          </li>
        </ul>
      </div>
    </poptip>
  </div>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import { fetchOrdergetPaymentByOrderNo, fetchHistoryOrdergetPaymentById, getOrderPayDetail } from '@/api/order';
import dayjs from 'dayjs';
export default {
  name: 'OrderStatus',
  mixins: [PopTipMixin, CellMixin],
  data: function() {
    return {
      currentUser: this.$store.getters.currentUser,
      payRecords: [],
      hasFetch: false,
      showPresaleTip: false,
      showGroupBuyTip: false
    };
  },
  computed: {
    showEndTime() {
      let order = this.params.row;
      let startTime = dayjs(order.dOrderDate);
      let endtime = null;
      if (order.presale.depositDealUnit === 1) {
        endtime = startTime.add(order.presale.depositEndDate, 'day');
      } else if (order.presale.depositDealUnit === 2) {
        endtime = startTime.add(order.presale.depositEndDate, 'hour');
      } else if (order.presale.depositDealUnit === 3) {
        endtime = startTime.add(order.presale.depositEndDate, 'minute');
      } else if (order.presale.depositDealUnit === 4) {
        endtime = startTime.add(order.presale.depositEndDate, 'second');
      }
      return (endtime.format('YYYY')) + this.mlang('year') + (endtime.format('MM')) + this.mlang('month') + (endtime.format('DD')) + this.mlang('oneday') + (endtime.format('HH')) + this.mlang('onehour') + (endtime.format('mm')) + this.mlang('oneminute') + (endtime.format('ss')) + this.mlang('second');
    },
    showGroupBuyEndTime() {
      let order = this.params.row;
      return dayjs(order.dOrderDate).add(order.groupBuyPromotion.depositDealPeriod, 'minute').format('YYYY-MM-DD HH:mm:ss');
    }
  },
  methods: {
    onPopperShow() {
      if (this.$route.query.type === 'history') {
        fetchHistoryOrdergetPaymentById(this.params.row.id, 'ORDER').then((res) => {
          this.payRecords = res;
        });
      } else {
        if (!this.hasFetch) {
          fetchOrdergetPaymentByOrderNo('ORDER', this.params.row.cOrderNo).then((res) => {
            res.map(item => {
              item.cVerificactionTypeName = item.verificactionType && item.verificactionType.title;
              item.cPvfStatusName = item.pvfStatusName;
              item.iAmount = item.amount;
              item.cPaymentStatusName = item.paymentStatusName;
              item.dPayDate = item.payDate;
            });
            this.payRecords = res;
          });
        }
      }
    },
    onPopperShowPre() {
      getOrderPayDetail(this.params.row.cOrderNo).then((data) => {
        let order = this.params.row;
        if (order.presale) {
          this.showPresaleTip = order.presale.depositRule && order.presale.depositRule === 2 && order.presale.depositConfirmRange && order.presale.depositConfirmRange === 2 && order.presale.depositPercent && data.fPayMoney > 0 && ((data.paidMoney / data.fPayMoney * 100) < order.presale.depositPercent);
        }
        if (order.groupBuyPromotion) {
          this.showGroupBuyTip = order.groupBuyPromotion && order.groupBuyPromotion.depositRule && order.groupBuyPromotion.pValid && order.groupBuyPromotion.depositPercent && data.fPayMoney > 0 && ((data.paidMoney / data.fPayMoney * 100) < order.groupBuyPromotion.depositPercent);
        }
      });
    }
  }
};
</script>
<style scoped>
.disabledIcon{
  cursor: pointer;
}
</style>
