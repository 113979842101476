export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000342","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000343","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000344","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000345","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000346","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000347","编辑") /* "编辑" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000348","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000349","停用") /* "停用" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500034A","删除") /* "删除" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500034B","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500034C","查看执行情况") /* "查看执行情况" */,
  pleaseOpenLimitConfig: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500034D","营销中台-营销公共参数“启用商品限购控制”为“停用”状态，请调整！") /* "营销中台-营销公共参数“启用商品限购控制”为“停用”状态，请调整！" */
};
