import ajax from '@/libs/ajax';
import util from '@/libs/util.js';

export const getDiscountRebate = (data, isHistory) => {
  let postData = {};
  if (!isHistory) {
    postData = {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    };
  } else {
    postData = {
      data: data.querySchema,
      datatype: 'json',
      isReport: 1
    };
  }

  return ajax.request({
    // url: '/pc/RebateSrv/getRebateSchema',
    url: isHistory ? '/report/history/rebate/list/queryschema' : '/voucher/rebate/querySchema',
    method: 'post',
    data: postData
  });
};

// 弃审
// export const backUnConfirmRebate = (data) => {
//   return ajax.request({
//     url: '/pc/RebateSrv/backUnConfirmRebate',
//     method: 'post',
//     data
//   });
// };

// // 审核
// export const confirmRebate = (data) => {
//   return ajax.request({
//     url: '/pc/RebateSrv/confirmRebate',
//     method: 'post',
//     data
//   });
// };

export const enableContent2 = (data, bFlag) => {
  let code = data['rebateNo'];
  if (bFlag) {
    return ajax.request({
      url: '/voucher/rebate/confirm/' + code,
      method: 'POST',
      data: {
        // data: checkedIds,
        datatype: 'json',
        isArchive: 1
      }
    });
  } else {
    return ajax.request({
      url: '/voucher/rebate/backUnConfirm/' + code,
      method: 'POST',
      data: {
        // data: checkedIds,
        datatype: 'json',
        isArchive: 1
      }
    });
  }
};

export const batchDel2 = (checkedIds) => {
  return ajax.request({
    // /pc/price/PriceJustifieSrv/outputProductPriceJustifiesNew
    // url: '/goods/price/pricejustify/del',
    // url: '/goods/purchase', /// goods/price/disrate/del/{id}
    // method: 'DELETE',
    url: '/voucher/rebate/' + checkedIds[0],
    method: 'POST',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 删除
export const del = (data) => {
  return ajax.request({
    url: '/pc/RebateSrv/delRebate',
    method: 'post',
    data
  });
};

// 费用来源
export const RebateSourceSrv = () => {
  return ajax
    .request({
      url: '/voucher/rebate/rebatesource/start/list',
      method: 'get',
      data: {
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((data) => {
      return util.reduceChildren(data, 'oSubNodes', 'name', null, 'id');
    });
};

export const exportDiscountRebate = (data) => {
  data.querySchema.conditions.push({
    name: 'rebateCode',
    v1: 'CASHDISCOUNTS',
    valueType: 'STRING',
    conditionType: 'eq'
  });
  return ajax.request({
    url: '/pc/RebateSrv/exportRebates',
    method: 'post',
    data
  });
};

export const exportGoodsRebate = (data) => {
  data.querySchema.conditions.push({
    name: 'rebateCode',
    conditionType: 'eq',
    valueType: 'STRING',
    v1: 'RETURNPRODUCT'
  });
  return ajax.request({
    url: '/pc/RebateSrv/exportRebates',
    method: 'post',
    data
  });
};

//  获取费用来源
export const getRebateSources = () => {
  return ajax
    .request({
      url: '/voucher/rebate/rebatesource/start/list',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      return util.reduceChildren(data, null, 'name', null, 'id');
    });
};
// 保存费用信息
export const saveRebateInfo = (data) => {
  return ajax.request({
    url: '/voucher/rebate',
    method: 'post',
    data: {
      data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 费用变更
export const changeRebate = (data) => {
  return ajax.request({
    url: '/voucher/rebate/change',
    method: 'post',
    data: {
      data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 获取费用详情
export const getRebateDetails = (rebateNo, isHistory, rebateId) => {
  if (isHistory) {
    return ajax.request({
      url: `/report/history/rebate/detail/${rebateId}?isReport=1`,
      method: 'get'
    });
  } else {
    return ajax.request({
      url: `/voucher/rebate/${rebateNo}`,
      method: 'get',
      data: {
        isArchive: 1,
        datatype: 'json'
      }
    });
  }
};

export const deleteRebate = (rebateNo) => {
  return ajax.request({
    url: `/voucher/rebate/${rebateNo}`,
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json'
    }
  });
};
export const getDiscountUseRecord = (rebateNo) => {
  return ajax.request({
    url: `/voucher/rebate/rebaterecord/${rebateNo}`,
    method: 'get',
    data: {
      dattype: 'json',
      isArchive: 1
    }
  });
};

export const getCommodityUseRecord = (id) => {
  return ajax.request({
    url: `/voucher/rebate/rebatereturnrecord/${id}`,
    method: 'get',
    data: {
      dattype: 'json',
      isArchive: 1
    }
  });
};

export const getUsablePrice = (data) => {
  return ajax.request({
    url: '/voucher/rebate/usablerebates/price',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const rollbackOrderRebate = (data) => {
  return ajax.request({
    url: '/voucher/rebate/rollback',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getUsableRebates = (data) => {
  return ajax.request({
    url: `/voucher/rebate/usablerebates/cashdiscounts?pageSize=${data.pageSize}&pageIndex=${data.pageIndex}`,
    method: 'get',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getUsableRebatesReturnProduct = (data) => {
  return ajax.request({
    url: `/voucher/rebate/usablerebates/returnproduct?pageSize=${data.pageSize}&pageIndex=${data.pageIndex}`,
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getRebateRecordStatus = () => {
  return ajax.request({
    url: '/enum/voucher/rebate/RebateRecordStatusEnum/info',
    method: 'get',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const getUsableRebatesAccount = (url) => {
  return ajax.request({
    url: url,
    method: 'get'
  });
};
