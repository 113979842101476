export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002CC","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002CD","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002CE","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002CF","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D0","条") /* "条" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D1","删除") /* "删除" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D2","编辑") /* "编辑" */,
  effectContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D3","生效") /* "生效" */,
  disEffectContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002D4","失效") /* "失效" */
};
