import corpParentView from '@/views/corp/home/home.vue';

const promotionZTE = {
  path: 'promotionZTE',
  title: { i18n: 'channelcenter' },
  name: 'promotionZTE',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    // 组合销售列表
    {
      path: 'combinationSales',
      name: 'combinationSales',
      meta: {
        module: 'combinationSales'
      },
      component: () => import('@/views/corp/promotionZTE/combinationSales/combinationSales.vue')
    },
     // 组合销售列表
     {
      path: 'bizCombinationSales',
      name: 'bizCombinationSales',
      meta: {
        module: 'bizCombinationSales'
      },
      component: () => import('@/views/corp/promotionZTE/combinationSales/bizCombinationSales.vue')
    },
    // 组合促销详情
    {
      path: 'combineDetail',
      name: 'combineDetail',
      meta: {
        module: 'combinationSales',
        notCache: true
      },
      component: () => import('@/views/corp/promotionZTE/combinationSales/combineDetail.vue'),
      children: [
        {
          path: 'combineSetting',
          name: 'combineSetting',
          meta: { module: 'combinationSales', notCache: true },
          component: () => import('@/views/corp/promotionZTE/combinationSales/combineSetting.vue')
        },
        {
          path: 'combineSettingOrg',
          name: 'combineSettingOrg',
          meta: { module: 'combinationSales', notCache: true },
          component: () => import('@/views/corp/promotionZTE/promotionManager/merOrg.vue')
        }
      ]

    },
    {
      path: 'groupSales',
      title: { i18n: 'title.groupSales' },
      name: 'groupSales',
      component: () => import('@/views/corp/promotionZTE/groupSales/merOrg.vue')
    },
    // 商品促销
    {
      path: 'productSalesListShow',
      name: 'productSalesListShow',
      meta: {
        module: 'productSalesListShow'
      },
      component: () => import('@/views/corp/promotionZTE/productPromotion/productSalesListShow.vue')
    },
    {
      path: 'productPromotionDetail',
      name: 'productPromotionDetail',
      meta: {
        notCache: true,
        module: 'productSalesListShow'
      },
      component: () => import('@/views/corp/promotionZTE/productPromotion/productPromotionDetail.vue'),
      children: [
        {
          path: 'productPromotion',
          name: 'productPromotion',
          meta: { module: 'productSalesListShow', notCache: true },
          component: () => import('@/views/corp/promotionZTE/productPromotion/productPromotion.vue')
        },
        {
          path: 'productSalesListShowOrg',
          name: 'productSalesListShowOrg',
          meta: { module: 'productSalesListShow', notCache: true },
          component: () => import('@/views/corp/promotionZTE/promotionManager/merOrg.vue')
        }
      ]
    },
    //ys 商家促销管理 商品促销
    {
      path: 'bizProductSalesListShow',
      name: 'bizProductSalesListShow',
      meta: {
        module: 'bizProductSalesListShow'
      },
      component: () => import('@/views/corp/promotionZTE/productPromotion/bizProductSalesListShow.vue')
    },
    {
      path: 'promotionManager',
      name: 'promotionManager',
      component: () => import('@/views/corp/promotionZTE/promotionManager/merOrg.vue')
    },
    {
      path: 'productLimit',
      name: 'productLimit',
      meta: {
        module: 'productLimit'
      },
      component: () => import('@/views/corp/promotionZTE/productLimit/productLimit.vue')
    },
    {
      path: 'productLimitDetail',
      name: 'productLimitDetail',
      meta: {
        notCache: true,
        module: 'productLimit'
      },
      component: () => import('@/views/corp/promotionZTE/productLimit/productLimitDetail.vue')
    },
    {
      path: 'exeStatusInfo',
      name: 'exeStatusInfo',
      meta: {
        notCache: true,
        notAutoTitle: true,
        module: 'productLimit'
      },
      component: () => import('@/views/corp/promotionZTE/exeStatusInfo/exeStatusInfo.vue')
    },
    {
      path: 'accessorieSetting',
      name: 'accessorieSetting',
      meta: {
        title: '推荐配件设置列表',
        module: 'accessorieSetting'
      },
      component: () => import('@/views/corp/promotionZTE/accessorieSetting/accessorieSetting.vue')
    },
    {
      path: 'accessorieSettingDetail',
      name: 'accessorieSettingDetail',
      meta: {
        title: '推荐配件',
        notCache: true,
        module: 'accessorieSetting'
      },
      component: () => import('@/views/corp/promotionZTE/accessorieSetting/accessorieSettingDetail.vue')
    },

    // 整单优惠
    {
      path: 'singleDiscountListShow',
      name: 'singleDiscountListShow',
      meta: {
        module: 'singleDiscountListShow'
      },
      component: () => import('@/views/corp/promotionZTE/singleDiscountListShow/singleDiscountListShow.vue')
    },
    // ys商家促销管理 - 整单优惠
    {
      path: 'bizSingleDiscountListShow',
      name: 'bizSingleDiscountListShow',
      meta: {
        module: 'bizSingleDiscountListShow'
      },
      component: () => import('@/views/corp/promotionZTE/singleDiscountListShow/bizSingleDiscountListShow.vue')
    },
    // 整单优惠详情
    {
      path: 'singleDiscountDetail',
      name: 'singleDiscountDetail',
      meta: {
        module: 'singleDiscountListShow',
        notCache: true
      },
      component: () => import('@/views/corp/promotionZTE/singleDiscountListShow/singleDiscountDetail.vue'),
      children: [
        {
          path: 'singleDiscountShow',
          name: 'singleDiscountShow',
          meta: { module: 'singleDiscountListShow', notCache: true },
          component: () => import('@/views/corp/promotionZTE/singleDiscountListShow/singleDiscountShow.vue')
        },
        {
          path: 'singleDiscountOrg',
          name: 'singleDiscountOrg',
          meta: { module: 'singleDiscountListShow', notCache: true },
          component: () => import('@/views/corp/promotionZTE/promotionManager/merOrg.vue')
        }
      ]
    },
    // 商品预售列表
    {
      path: 'productPresales',
      name: 'productPresales',
      meta: {
        title: '商品预售',
        module: 'productPresales'
      },
      component: () => import('@/views/corp/promotionZTE/productPresale/productPresales.vue')
    },
    // 商品预售详情
    {
      path: 'productPresale',
      name: 'productPresale',
      meta: {
        title: '商品预售',
        module: 'productPresales',
        notCache: true
      },
      component: () => import('@/views/corp/promotionZTE/productPresale/productPresale.vue')
    },
    // 商品团购列表
    {
      path: 'productGroupBuys',
      name: 'productGroupBuys',
      meta: {
        title: '商品团购',
        module: 'productGroupBuys',
        notCache: true
      },
      component: () => import('@/views/corp/promotionZTE/productGroupBuy/productGroupBuys.vue')
    },
    // 商品团购详情
    {
      path: 'productGroupBuy',
      name: 'productGroupBuy',
      meta: {
        title: '商品团购',
        module: 'productGroupBuy',
        notCache: true
      },
      component: () => import('@/views/corp/promotionZTE/productGroupBuy/productGroupBuy.vue')
    },
    // 累积量促销列表
    {
      path: 'accumulationPromotions',
      name: 'accumulationPromotions',
      meta: {
        title: '累积量促销',
        module: 'accumulationPromotions',
        notCache: true
      },
      component: () => import('@/views/corp/promotionZTE/accumulationPromotion/accumulationPromotions.vue')
    },
    // ys商家促销管理 - 累积量促销
    {
      path: 'bizAccumulationPromotions',
      name: 'bizAccumulationPromotions',
      meta: {
        module: 'bizAccumulationPromotions'
      },
      component: () => import('@/views/corp/promotionZTE/accumulationPromotion/bizAccumulationPromotions.vue')
    },
    // 累积量促销详情
    {
      path: 'accumulationPromotion',
      name: 'accumulationPromotion',
      meta: {
        title: '累积量促销',
        module: 'accumulationPromotion',
        notCache: true
      },
      component: () => import('@/views/corp/promotionZTE/accumulationPromotion/accumulationPromotion.vue')
    }
  ]
};
export default promotionZTE;
