import { deepCopy } from '@/u-components/utils/assist';
const mixin = {
  data() {
    let formData = {};
    return {
      formData,
      queryschema: {}
    };
  },
  mounted() {},
  computed: {},
  created() {},
  methods: {
    queryschemaLoaded() {
      this.$emit('on-queryschema-loaded');
    },
    setValidateRules() {
      this.cloneMeta.editRows.map((v, k) => {
        const { required, model, maxLength, placeholder, bEnable, controlType } = v;
        const rule = {};
        rule[model] = [];
        const rules = rule[model];
        if (required) {
          const defaultRule = {
            required: true,
            message: placeholder
          };
          if (controlType === 'selecttab') {
            defaultRule.type = 'integer';
          }
          rules.push(defaultRule);
        }
        if (v.maxLength) {
          rules.push({
            max: maxLength,
            message: this.mlang('maxLength').replace('{txt}', maxLength),
            trigger: 'blur'
          });
        }
        if (rules.length && bEnable !== false) {
          this.cloneMeta.validateRules = Object.assign({}, this.cloneMeta.validateRules, rule);
        }
      });
    },
    setEditRows() {
      this.cloneMeta.editRows = this.cloneMeta.editRows || [];
      this.cloneMeta.editRows.map((v, k) => {
        if (v.controlType === 'input' && v.bEnable !== false) {
          v.maxLength = v.maxLength || 255;
          v.placeholder = v.placeholder || this.mlang('pleaseEnter') + v.label;
        }
      });
    },
    getForm(refKey = 'form-modal') {
      return this.$refs.editForm.$refs[refKey].$refs['form-meta'];
    },
    resetForm() {
      // this.getForm().resetFields();
      // this.$refs.editForm.visibleChange(true);
    },
    setFromData(data = {}) {
      this.$refs['editForm'].$refs['form-modal'].setVm(data);
    },
    add() {
      this.resetForm();
      if (this.cloneMeta.actions.add) {
        this.cloneMeta.actions.add().then((res) => {
          this.setFromData(res);
          this.$emit('on-add', this.initFormData);
        });
      } else {
        this.setFromData();
        this.$emit('on-add', this.initFormDat);
      }
    },
    del(row) {
      const data = {
        id: row.id
      };
      this.cloneMeta.actions.del(data).then((res) => {
        this.$Message.success(this.mlang('delSuccess'));
        this.queryList();
      });
    },
    cancelEdit() {
      this.$refs.editForm.cancelEdit();
      this.setFormLoading(false);
    },
    setFormLoading(loading) {
      this.$refs.editForm.setLoading(loading);
    },
    saveEdit() {
      this.setFormLoading(true);
      this.getForm().validate((valid) => {
        if (valid) {
          const postData = {
            model: this.formData,
            datatype: 'json'
          };
          this.cloneMeta.actions
            .save(postData)
            .then((data) => {
              this.$Message.success(this.mlang('saveSuccess'));
              this.cancelEdit();
              this.queryList();
            })
            .catch((msg) => {
              this.setFormLoading(false);
            });
        } else {
          this.setFormLoading(false);
        }
      });
    },
    getDefaultBtn(h, options) {
      const { click, text, props = {} } = options;
      return h(
        'Button',
        {
          props: Object.assign(
            {
              type: 'text',
              size: 'small'
            },
            props
          ),
          on: {
            click: click
          }
        },
        text
      );
    },
    commonEditBtn(h, params, button) {
      const click = () => {
        if (button.href) {
          let param = { id: params.row.id };
          this.$router.push({
            name: button.href,
            params: param
          });
        } else {
          this.resetForm();
          const initFormData = deepCopy(params.row);
          this.$emit('on-edit', initFormData);
          this.setFromData(initFormData);
        }
      };
      return this.getDefaultBtn(h, { click, text: this.mlang('edit') });
    },
    commonDelBtn(h, params) {
      return h(
        'Poptip',
        {
          props: {
            options: {
              modifiers: {
                computeStyle: {},
                preventOverflow: {
                  boundariesElement: 'viewport'
                }
              }
            },
            confirm: true,
            title: this.mlang('delConfirmTitle')
          },
          on: {
            'on-ok': () => {
              this.del(params.row);
            }
          }
        },
        [
          h(
            'Button',
            {
              props: {
                type: 'text',
                placement: 'top',
                size: 'small'
              }
            },
            this.mlang('delete')
          )
        ]
      );
    },
    commonSwitchBtn(vm, h, params, config) {
      const { value, on, off, change } = config;
      return h(
        'i-switch',
        {
          props: {
            size: 'large',
            value: value
          },
          style: {
            marginRight: '5px'
          },
          on: {
            'on-change': (v) => {
              change && change(v, params.row, params.index);
            }
          }
        },
        [
          h(
            'span',
            {
              slot: 'open'
            },
            on
          ),
          h(
            'span',
            {
              slot: 'close'
            },
            off
          )
        ]
      );
    },
    alertNoInterface() {
      this.$Message.warning({ content: '当前功能尚未提供接口。' });
      return null;
    }
  }
};

export default mixin;
