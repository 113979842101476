<template>
  <div
    class="header-inline-center pull-right update-log"
    style="">
    <Poptip
      :transfer="false"
      :offset="12"
      content="content"
      trigger="hover"
      popper-class="userHeaderPoptip"
      placement="bottom-end">
      <span>
        <Icon
          size="20px"
          custom="iconfont icon-bangzhuzhongxin"></Icon>
      </span>
      <div slot="content">
        <p>
          <a
            href="https://api.udinghuo.cn/@documentation/zh/home"
            target="_blank">
            <Icon
              class-name="international-icon"
              custom="iconfont icon-gengxinrizhi"></Icon>
            {{ mlang('updateLog') }}
          </a>
        </p>
      </div>
    </poptip>
  </div>
</template>

<script>
export default {
  name: 'UpdateLog',
  data: function() {
    return {};
  },
  methods: {}
};
</script>
