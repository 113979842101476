<template>
  <div class="agent-header">
    <TopBar />
    <SearchBar v-if="!zoneHomePage" />
    <MenuBar :zone-home-page="zoneHomePage" />
  </div>
</template>

<script>
import TopBar from './components/top-bar/index';
import MenuBar from './components/menu-bar/index';
import SearchBar from './components/search-bar/index';

export default {
  name: 'AgentHeader',
  components: {
    TopBar,
    SearchBar,
    MenuBar
  },
  props: {
    zoneHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
</style>
