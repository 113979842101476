<template>
  <div v-if="offerList.length" class="offer-table-cell">
    <Poptip :options="popTipProps" :trigger="popTrigger" placement="bottom" popper-class="poptip">
      <div class="sales-promotion">
        {{ totalDiscountAmount }}
        <Icon type="arrow-down-b"></Icon>
      </div>
      <div slot="content" class="promotion-list">
        <ul>
          <li v-for="(offer,index) in offerList" :key="index">
            <div v-if="!offer.bHide" class="promotional-unit">
              <div
                v-if="(offer.pType && offer.pType != 0)"
                class="promotion-tip offer-method">
                {{ promotionMethod[offer.pType - 1] }}
              </div>
              <div class="promotion-content">
                {{ `${offer.cApportionName} ${isShowApportion ? mlang('saving') + formateMoney(offer.fApportionMoney) : ''}` }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Poptip>
  </div>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import { upHideOperater } from '@/filters/index';
export default {
  name: 'OfferTableCell',
  mixins: [PopTipMixin, CellMixin],
  inject: ['formateMoney'],
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      promotionMethod: [this.mlang('discount'), this.mlang('reducePrice'), this.mlang('giveProduct'), this.mlang('onePrice')]
    };
  },
  computed: {
    offerList() {
      if (this.params.row && Array.isArray(this.params.row.oOrderProductApportions)) {
        return this.params.row.oOrderProductApportions.filter(v => !v.bHide);
      }
      return [];
    },
    isShowApportion() {
      return upHideOperater(100, 'fApportionMoney'); // 100 随便写的数字，仅用于凑参数
    },
    totalDiscountAmount() {
      let total = 0;
      if (this.offerList.length === 0) return total;
      this.offerList.forEach((o) => {
        total += o.fApportionMoney;
      });
      return this.isShowApportion ? this.formateMoney(total) : '';
    }
  }
};
</script>

<style scoped>
/* .sales-promotion {
  color: #ff5735;
} */
.promotional-unit {
  color: #333333;
  padding: 6px 10px;
}
.promotion-tip {
  display: table-cell;
  vertical-align: top;
}
.promotion-content {
  display: table-cell;
  vertical-align: top;
  padding-left: 5px;
}
.offer-method {
  width: 38px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  background: #fffaeb;
  border: 1px solid #ffe9a7;
  border-radius: 2px;
  font-size: 10px;
  color: #ff9500;
}
</style>
