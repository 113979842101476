export default {
  org: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000C4","组织") /* "组织" */,
  activityName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000C5","活动名称") /* "活动名称" */,
  activityType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000C6","活动类型") /* "活动类型" */,
  activityMemo: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000C7","活动说明") /* "活动说明" */,
  activityDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000C8","活动时间") /* "活动时间" */,
  preDeliveryDays: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000C9","预计发货日期") /* "预计发货日期" */,
  afterActivity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000CA","活动结束") /* "活动结束" */,
  afterActivity1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000CB","天后开始发货，即") /* "天后开始发货，即" */,
  afterActivity2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000CC","后开始发货") /* "后开始发货" */,
  preDeliveryEndDays: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000CD","发货截止日期") /* "发货截止日期" */,
  afterDelivery: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000CE","预计发货日") /* "预计发货日" */,
  afterDelivery1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000CF","天后结束发货，即") /* "天后结束发货，即" */,
  afterDelivery2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D0","后结束发货") /* "后结束发货" */,
  stockPeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D1","备货周期") /* "备货周期" */,
  isValid: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D2","状态") /* "状态" */,
  confirmIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D3","启用") /* "启用" */,
  disConfirmIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D4","停用") /* "停用" */,
  depositRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D5","定金尾款规则") /* "定金尾款规则" */,
  depositConfirmRange: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D6","定金金额") /* "定金金额" */,
  depositConfirmRange1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D7","统一收取定金") /* "统一收取定金" */,
  depositConfirmRange2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D8","按整单金额比例收取") /* "按整单金额比例收取" */,
  dingjin: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000D9","%定金") /* "%定金" */,
  depositDealPeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000DA","定金周期") /* "定金周期" */,
  depositDealPeriod1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000DB","下单后") /* "下单后" */,
  depositDealPeriod2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000DC","未支付订金的订单自动取消") /* "未支付订金的订单自动取消" */,
  depositDealRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000DD","定金处理方式") /* "定金处理方式" */,
  balanceRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000DE","尾款控制") /* "尾款控制" */,
  balancePercentRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000DF","尾款比例") /* "尾款比例" */,
  balancePercentRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E0","订单支付金额需达到整单金额的") /* "订单支付金额需达到整单金额的" */,
  balancePercentRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E1","（包含定金）") /* "（包含定金）" */,
  balancePeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E2","尾款周期") /* "尾款周期" */,
  balancePeriod1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E3","至活动结束后") /* "至活动结束后" */,
  balancePeriod2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E4","秒后结束") /* "秒后结束" */,
  day: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E5","天") /* "天" */,
  xiaoshi: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E6","小时") /* "小时" */,
  fenzhong: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E7","分") /* "分" */,
  second: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E8","秒") /* "秒" */,
  notPayBalanceRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000E9","未按要求支付尾款处理规则") /* "未按要求支付尾款处理规则" */,
  promotionRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000EA","优惠策略") /* "优惠策略" */,
  promotionPriceRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000EB","预售价格设定依据") /* "预售价格设定依据" */,
  promotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000EC","优惠方式") /* "优惠方式" */,
  isEnablePromotionStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000ED","预售梯优惠") /* "预售梯优惠" */,
  promotionStepCalcRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000EE","阶梯条件") /* "阶梯条件" */,
  addLsPresaleSteps: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000EF","增加阶梯") /* "增加阶梯" */,
  demension: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F0","促销维度") /* "促销维度" */,
  addProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F1","新增商品") /* "新增商品" */,
  activityProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F2","活动商品") /* "活动商品" */,
  product: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F3","商品") /* "商品" */,
  activityFail: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F4","活动失败") /* "活动失败" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F5","操作成功") /* "操作成功" */,
  presaleQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F6","总可售计价数量：") /* "总可售计价数量：" */,
  usedPreSaleQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F7","总已预售计价数量：") /* "总已预售计价数量：" */,
  notPreSaleQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F8","剩余预售计价数量：") /* "剩余预售计价数量：" */,
  kehu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000F9","客户") /* "客户" */,
  isSettingSku: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000FA","按sku设置") /* "按sku设置" */,
  presaleTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000FB","预售") /* "预售" */,
  presaleTypes2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000FC","预订") /* "预订" */,
  depositRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000FD","不收定金") /* "不收定金" */,
  depositRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000FE","收取定金") /* "收取定金" */,
  promotionPriceRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050000FF","客户价表价") /* "客户价表价" */,
  promotionRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000100","单独设定客户价格以及优惠") /* "单独设定客户价格以及优惠" */,
  promotionRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000101","无优惠") /* "无优惠" */,
  promotionStepCalcRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000102","满件") /* "满件" */,
  promotionStepCalcRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000103","满额") /* "满额" */,
  promotionTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000104","打折") /* "打折" */,
  depositDealRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000105","不交尾款订金可退") /* "不交尾款订金可退" */,
  notPayBalanceRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000106","尾款周期结束后自动驳回该客户订单") /* "尾款周期结束后自动驳回该客户订单" */,
  balanceRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000107","尾款周期结束前统一收取剩余全部尾款") /* "尾款周期结束前统一收取剩余全部尾款" */,
  balanceRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000108","按客户信用处理客户尾款") /* "按客户信用处理客户尾款" */,
  orderQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000109","订单量") /* "订单量" */,
  orderMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500010A","金额小计") /* "金额小计" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500010B","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500010C","删除") /* "删除" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500010D","查看") /* "查看" */,
  skuSpeciInfo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500010E","商品Sku属性") /* "商品Sku属性" */,
  skuCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500010F","商品Sku编码") /* "商品Sku编码" */,
  presaleQuantityTable: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000110","可预定量(主单位)") /* "可预定量(主单位)" */,
  minOrderQuantityTable: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000111","客户起订量(主单位)") /* "客户起订量(主单位)" */,
  maxOrderQuantityTable: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000112","每客户最大订购量(主单位)") /* "每客户最大订购量(主单位)" */,
  tiaojianmanjian: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000113","条件（批发计价单位）") /* "条件（批发计价单位）" */,
  tiaojianmane: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000114","条件（满额）") /* "条件（满额）" */,
  jieti: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000115","阶梯") /* "阶梯" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000116","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000117","查看执行情况") /* "查看执行情况" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000118","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  zhishaotianjiayigeyushoushangpin: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000119","至少添加一个预售商品!") /* "至少添加一个预售商品!" */,
  shouldCorrectMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500011A","请正确填写!") /* "请正确填写!" */,
  doFailSure: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500011B","确定执行吗？") /* "确定执行吗？" */,
  promotionProgressName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500011C","处理状态") /* "处理状态" */,
  pleaseSelectOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500011D","请先选择组织！") /* "请先选择组织！" */,
  orderNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500011E","订单单号") /* "订单单号" */,
  all: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500011F","全部") /* "全部" */
};
