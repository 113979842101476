<template>
  <div>
    <div v-if="flattenQueryschemaArr.length">
      <QuerySchema
        ref="queryschema"
        :b-refer="true"
        :is-immediately-call-interface="isImmediatelyCallInterface"
        :biz-meta="cloneMeta"
        :filters="flattenQueryschemaArr"
        @on-queryschema-search="queryschemaSearch"
        @on-queryschema-loaded="queryschemaLoaded"></QuerySchema>
    </div>
    <Row>
      <div class="pull-left product-ref-center" style="width:500px">
        <Table
          ref="mainTable"
          :meta-setting="bizMeta"
          :width="mainTableWidth"
          :columns="mainColumns"
          :data="mainTableData"
          :loading="mainTableLoading"
          :height="height"
          :highlight-row="highlightRow"
          @on-row-click="onMainTableRowClick"
          @on-current-change="onMainTableChange"
          @on-selection-change="mainTableSectionChange">
          <div slot="footer">
            <div style="float: right;">
              <div v-if="ifProductionOptimize" class="diyPage">
                <li :class="{'ivu-page-disabled' : preDisabledStyle}" class="ivu-page-prev" title="上一页" @click="prePage(pager.currentPage)">
                  <a>
                    <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
                  </a>
                </li>
                <li class="ivu-page-item">
                  <a>{{ pager.currentPage }}</a>
                </li>
                <li :class="{'ivu-page-disabled' : nextDisabledStyle}" class="ivu-page-next" title="下一页" @click="nextPage(pager.currentPage)">
                  <a>
                    <i class="ivu-icon ivu-icon-ios-arrow-forward"></i>
                  </a>
                </li>
              </div>
              <Page
                v-else
                :total="pager.totalCount"
                :current.sync="pager.currentPage"
                simple
                @hook:mounted="pageMounted"
                @on-change="changePage" />
            </div>
          </div>
        </Table>
        <!-- <div
          class="modal-page"
          style="margin: 10px 0px;">

        </div>-->
      </div>
      <div
        v-if="cloneMeta.isAllowSelectSku && !cloneMeta.hideSkuTable"
        class="pull-right product-ref-right"
        style="width:395px">
        <Tabs size="small" type="card" @on-click="tabClick">
          <TabPane :label="mlang('guige')" name="sku">
            <keep-alive>
              <Table
                :height="388"
                :meta-setting="bizMeta"
                :show-header="subTableColumn.length>0"
                :columns="subTableColumn"
                :loading="subTableLoading"
                :highlight-row="subHighlightRow"
                :data="subTableData"
                @on-select-all="onSubTableSelectAll"
                @on-row-click="onSubTableRowClick"
                @on-selection-change="subTableSectionChange"
                @on-current-change="onSubTableChange"></Table>
            </keep-alive>
          </TabPane>
          <TabPane :label="mlang('canshu')" name="parameters">
            <no-data v-if="!parametersdata || !parametersdata.length" style="margin-top:105px;"></no-data>
            <div style="max-height:400px;overflow:auto">
              <ul v-if="parametersdata && parametersdata.length" class="tab-ul">
                <li v-for="parameter in parametersdata" :key="parameter.id">
                  <span>{{ parameter.cName }}</span>
                  <span>{{ parameter.cParamValue }}</span>
                </li>
              </ul>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </Row>
    <div v-show="moreVisible" class="product-more-search">
      <div class="content">
        <ul v-if="selectProduct && selectProduct.lsSpecs">
          <template v-for="spec in selectProduct.lsSpecs">
            <li :key="spec.id">
              <h2>{{ spec.cName }}</h2>
              <div class="clearfix">
                <template v-for="sku in spec.lsSpecItem">
                  <a
                    :key="sku.id"
                    :class="{'active':sku.active}"
                    @click="triggerSpecActive(sku)">{{ sku.cSpecItemName }}</a>
                </template>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="ivu-modal-footer">
        <Button class="cancel-btn" @click="hideMore">
          {{ mlang('cancel') }}
        </Button>
        <Button type="primary" class @click="skuSearchClick">
          {{ mlang('find') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { oneOf, deepCopy, findComponentUpward } from '@/u-components/utils/assist';
import CommonMixin from '@/mixin/common.js';
import PropsyncMixin from '@/mixin/propsync.js';
import Mixin from '@/mixin/archiveList.js';
import _ from 'lodash';
import { getProducts, getPromotionProducts, getRebateProducts } from '@/api/refer-modal';
import { getSkuAndParametersById, getProductSku, getRebateSkuAndParametersById } from '@/api/product';
const prefixCls = 'ivu-select';
// const Tooltip = 'Tooltip';
export default {
  name: 'ProductRefTable',
  mixins: [CommonMixin, PropsyncMixin, Mixin],
  props: {
    selectedProductGroupId: Number,
    selectedClassId:{
      type: [String, Number],
      default: ''
    },
    bizMeta: {
      type: Object,
      required: true
    },
    referType: {
      type: String,
      default: 'Table',
      validator(value) {
        return oneOf(value, ['Table', 'TreeTable']);
      }
    },
    isQueryschema: {
      type: [Boolean],
      default: true
    },
    billMeta: {
      default: {},
      type: Object
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: ''
    },
    showInput: {
      type: [Boolean],
      default: true
    },
    source: {
      type: String,
      default: ''
    },
    isImmediatelyCallInterface: {
      type: Boolean,
      default: true
    },
    isNewApiOfProduct: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedMainRow: null,
      selectedSubRow: null,
      flattenQueryschemaArr: [],
      mainTableCheckedArr: [],
      subTableCheckedArr: [],
      queryschema: null,
      classid: this.selectedClassId,
      groupid: this.selectedProductGroupId,
      cloneMeta: null,
      parametersTableLoading: false,
      mainTableLoading: false,
      mainTableIsSelectAll: false,
      subTableIsSelectAll: false,
      subTableLoading: false,
      moreVisible: false,
      queryschemaObj: {},
      selectProduct: null,
      mainColumns: [],
      parametersColumn: [
        {
          key: 'cName'
        },
        {
          key: 'cValue'
        }
      ],
      parametersdata: [],
      selectedSkus: [],
      selectedProducts: [],
      subTableColumn: [],
      subTableData: [],
      mainTableData: [],
      hasMouseHoverHead: false,
      prefixCls: prefixCls,
      showValue: '',
      height: 0,
      modal: false,
      modalLoading: false,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        pageSizeOpts: [10, 20, 30, 50],
        currentPage: 1,
        totalCount: 0
      },
      isKeyWordExactSearch: false, // 是否精确匹配
      isEnd: false,
      serviceTab: 0,
      preDisabledStyle: true,
      nextDisabledStyle: true,
      classids: null
    };
  },
  computed: {
    mainTableWidth() {
      return this.cloneMeta.isAllowSelectSku && !this.cloneMeta.hideSkuTable ? 500 : 900;
    },
    highlightRow() {
      return this.cloneMeta && !this.cloneMeta.multiple;
    },
    subHighlightRow() {
      return this.cloneMeta && this.cloneMeta.isSingleSku;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    ifProductionOptimize() {
      // UDH-55941-0金额商品不显示
      return this.$getBO('OPENPAGINATION');
    }
  },
  watch: {
    billMeta: {
      handler() {
        this.init();
      },
      deep: true
    }
  },
  created() {
    this.$on('on-tree-selected', ({ selectNodes }) => {
      if (selectNodes && selectNodes.length) {
        const classid = selectNodes[0].id;
        if (this.isProGroup) {
          this.groupid = classid;
        } else if (this.isNewApiOfProduct) {
          this.classids = classid;
        } else if (!this.isNewApiOfProduct) {
          this.classid = classid;
        }

        this.pager.currentPage = this.pager.pageIndex = 1;
        this.loadProductData();
      }
    });
    this.init();
    this.setPager();
  },
  mounted() {
    // this.loadProductData();
  },
  methods: {
    onSubTableSelectAll(selection, status) {
      if (selection.length === this.subTableData.length) {
        this.subTableData.map((v) => {
          if (!status) {
            delete v._checked;
          }
        });
      }
    },
    pageMounted() {
      let vm = this;
      if (document.getElementsByClassName('ivu-page-simple-pager') && document.getElementsByClassName('ivu-page-simple-pager')[0]) {
        document.getElementsByClassName('ivu-page-simple-pager')[0].firstChild.onblur = function(e) {
          vm.pager.pageIndex = Number(e.target.value) || 1;
          vm.loadProductData();
        };
      }
    },
    init() {
      // this.loadProductData();
      this.makeMeta();
      this.makeQueryschema();
      this.makeMainColumns();
    },
    makeMeta() {
      /**
        isSingleSku -- 是否只选择一个SKU
        isMustSelectSku --是否必须选择一个SKU
        isAllowSelectSku  --选择商品,不能选择SKU
        isAllowSelectProduct --可以选择商品,不选择SKU
      */
      this.cloneMeta = deepCopy(this.billMeta);
    },
    queryschemaSearch(queryschema) {
      this.queryschema = queryschema;
      this.pager.pageIndex = 1;
      this.pager.currentPage = 1;
      this.loadProductData();
    },
    async loadProductData() {
      let status = -1;
      let keyword = '';
      if (this.queryschema && this.queryschema.conditions.length) {
        const cond = this.queryschema.conditions.find((v) => {
          return v.name === 'value';
        });
        if (cond) {
          keyword = cond.v1;
        }
        const statusControl = this.queryschema.conditions.find((v) => {
          return v.name === 'status';
        });
        if (statusControl) {
          status = statusControl.v1;
        }
      }
      if (status === '') {
        status = -1;
      }
      if (this.cloneMeta.defaultStatus !== -1) {
        status = this.cloneMeta.defaultStatus;
      }
      let postData = {
        currentPage: this.pager.pageIndex,
        pageSize: this.pager.pageSize,
        keyword: keyword,
        isKeyWordExactSearch: !!JSON.parse(localStorage.getItem('popProductsExactSearch')),
        isAgent: this.currentUser.isAgent
      };
      if (!this.cloneMeta.isAgent) {
        postData.status = status;
      }
      if (this.cloneMeta.promotionId) {
        postData.promotionId = this.cloneMeta.promotionId;
      }
      if (this.cloneMeta.proId) {
        postData.proId = this.cloneMeta.proId;
      }
      if (this.cloneMeta.promotionType) {
        postData.promotionType = this.cloneMeta.promotionType;
      }
      if (this.classid) {
        postData.classid = this.classid;
      }
      if (this.groupid) {
        postData.productGroupId = this.groupid;
      }
      if (this.cloneMeta.classId) {
        postData.classid = this.cloneMeta.classId;
      }
      if (this.cloneMeta.groupId) {
        postData.productGroupId = this.cloneMeta.groupId;
      }
      if (this.billMeta.agentId > 0) {
        postData.iAgentId = this.billMeta.agentId;
      }
      if (this.billMeta.orgId > 0) {
        postData.orgId = this.billMeta.orgId;
      }

      if (this.billMeta.bizId > 0) {
        postData.bizId = this.billMeta.bizId;
      }
      if (this.billMeta.transactionTypeId && this.$getBO('CHECKTYPESCOMMODITYTRADE')) {
        postData.transactionTypeId = this.billMeta.transactionTypeId;
      }
      if (this.billMeta.brandId > 0) {
        postData.brandId = this.billMeta.brandId;
      }
      if (this.billMeta.productLineId > 0) {
        postData.productLineId = this.billMeta.productLineId;
      }
      // ys促销获取商品和sku必须传币种，已经在外层控制currencyId
      if (this.billMeta.currencyId) {
        postData.currencyId = this.billMeta.currencyId;
      }
      if (this.billMeta.searchProductArchives !== undefined) { // 适用范围true平台，false商家
        postData.searchProductArchives = this.billMeta.searchProductArchives;
      }
      if (this.isNewApiOfProduct && !postData.classid) {
        const classids = this.classids
          ? [this.classids]
          : (this.bizMeta?.selectProductPopClasses || []).map(v => v.id);
        postData.classids = classids;
      }
      if (this.cloneMeta.beforeQuery) {
        const result = this.cloneMeta.beforeQuery(postData);
        if (result === false) {
          const refer = findComponentUpward(this, 'Refer');
          refer && refer.hideModal();
          return;
        } else {
          postData = result;
        }
      }
      this.mainTableLoading = true;
      if (this.billMeta.isSpecialTable) {
        getPromotionProducts(postData).then((res) => {
          res.data.map((product) => {
            product.productName = product.cName;
            const hasCheck = this.selectedSkus.find((v) => {
              if (v.isSku) {
                return v.__parentUuid === product.__uuid;
              } else {
                return v.__uuid === product.__uuid;
              }
            });
            if (hasCheck) {
              product._checked = true;
            } else {
              product._checked = false;
            }
            if (this.cloneMeta.isMustSelectSku) {
            // product._disabled = product.hasSpecs;
            }
          });
          this.mainTableData = res.data;
          this.pager.totalCount = res.totalCount;
          this.mainTableLoading = false;
        });
      } else {
        if (this.billMeta.onlyOneProduct) {
          let postData = {
            id: this.billMeta.productId,
            orgId: this.billMeta.orgId,
            isAgent: this.currentUser.isAgent
          };
          if (this.source === 'orderGoodsRebate') {
            const rebateIdArr = [];
            if (this?.billMeta?.lsRebateReturnProducts) {
              this.billMeta.lsRebateReturnProducts.forEach(item => {
                rebateIdArr.push({ id: item.rebateId });
              });
            }
            postData.queryMaps = {
              rebateQuery: rebateIdArr
            };
            getRebateSkuAndParametersById(postData).then((res) => {
              let arr = [res];
              arr.map((product) => {
                product.productName = product.cName;
                const hasCheck = this.selectedSkus.find((v) => {
                  if (v.isSku) {
                    return v.__parentUuid === product.__uuid;
                  } else {
                    return v.__uuid === product.__uuid;
                  }
                });
                if (hasCheck) {
                  product._checked = true;
                } else {
                  product._checked = false;
                }
                return arr;
              });
              this.mainTableData = arr;
              this.pager.totalCount = 1;
              this.mainTableLoading = false;
            });
          } else {
            getSkuAndParametersById(postData).then((res) => {
              let arr = [res];
              arr.map((product) => {
                product.productName = product.cName;
                const hasCheck = this.selectedSkus.find((v) => {
                  if (v.isSku) {
                    return v.__parentUuid === product.__uuid;
                  } else {
                    return v.__uuid === product.__uuid;
                  }
                });
                if (hasCheck) {
                  product._checked = true;
                } else {
                  product._checked = false;
                }
                return arr;
              });
              this.mainTableData = arr;
              this.pager.totalCount = 1;
              this.mainTableLoading = false;
            });
          }
        } else {
          if (this.ifProductionOptimize && this.currentUser.isAgent) {
            postData.serviceTab = this.serviceTab;
            postData.isEnd = this.isEnd;
          }
          if (this.source === 'orderGoodsRebate') {
            const rebateIdArr = [];
            const productLinesArr = [];
            if (this?.billMeta?.lsRebateReturnProducts) {
              this.billMeta.lsRebateReturnProducts.forEach(item => {
                rebateIdArr.push({ id: item.rebateId });
              });
            }
            if (this?.billMeta?.orderDetails) {
              this.billMeta.orderDetails.forEach(item => {
                if (item.productLineId) {
                  productLinesArr.push(item.productLineId);
                }
              });
            }
            postData.classId = postData.classid;
            postData.queryMaps = {
              rebateQuery: rebateIdArr,
              productLines: productLinesArr
            };
            getRebateProducts(postData).then((res) => {
              const { serviceTab, isEnd } = res;
              this.serviceTab = serviceTab;
              this.isEnd = isEnd;
              res.data.map((product) => {
                product.productName = product.cName;
                const hasCheck = this.selectedSkus.find((v) => {
                  if (v.isSku) {
                    return v.__parentUuid === product.__uuid;
                  } else {
                    return v.__uuid === product.__uuid;
                  }
                });
                if (hasCheck) {
                  product._checked = true;
                } else {
                  product._checked = false;
                }
                if (this.cloneMeta.isMustSelectSku) {
                // product._disabled = product.hasSpecs;
                }
              });
              this.mainTableData = res.data || [];
              if (this.pager.currentPage === 1) {
                this.preDisabledStyle = true;
              } else {
                this.preDisabledStyle = false;
              }
              if (this.mainTableData.length < 10) {
                this.nextDisabledStyle = true;
              } else {
                this.nextDisabledStyle = false;
              }
              this.pager.totalCount = res.totalCount;
              this.mainTableLoading = false;
            });
          } else {
            if (typeof this.billMeta.skuStatus === 'number') {
              postData.status = this.billMeta.skuStatus;
            }
            getProducts(postData, this.ifProductionOptimize && this.currentUser.isAgent).then((res) => {
              const { serviceTab, isEnd } = res;
              this.serviceTab = serviceTab;
              this.isEnd = isEnd;
              res.data.map((product) => {
                product.productName = product.cName;
                const hasCheck = this.selectedSkus.find((v) => {
                  if (v.isSku) {
                    return v.__parentUuid === product.__uuid;
                  } else {
                    return v.__uuid === product.__uuid;
                  }
                });
                if (hasCheck) {
                  product._checked = true;
                } else {
                  product._checked = false;
                }
                if (this.cloneMeta.isMustSelectSku) {
                // product._disabled = product.hasSpecs;
                }
              });
              this.mainTableData = res.data || [];
              if (this.pager.currentPage === 1) {
                this.preDisabledStyle = true;
              } else {
                this.preDisabledStyle = false;
              }
              if (this.mainTableData.length < 10) {
                this.nextDisabledStyle = true;
              } else {
                this.nextDisabledStyle = false;
              }
              this.pager.totalCount = res.totalCount;
              this.mainTableLoading = false;
            });
          }
        }
      }
    },
    getProductParameters() {},

    removeSelectedSkus(id, key = '__uuid') {
      const arr = deepCopy(this.selectedSkus) || [];
      _.remove(arr, (sku) => {
        return sku[key] === id;
      });
      this.selectedSkus = deepCopy(arr) || [];
    },
    onSubTableRowClick(currentRow, index, status) {
      if (this.cloneMeta.isSingleSku) {
        return;
      }
      const dataRow = this.subTableData.find((v) => {
        return v.__uuid === currentRow.__uuid;
      });
      if (dataRow && status !== dataRow._checked) {
        this.$set(dataRow, '_checked', !dataRow._checked);
      }
      this.onSubTableChange(currentRow, dataRow._checked);
    },
    subTableSectionChange(selection, row, status) {
      row.map((v, index) => {
        if (!status) {
          this.removeSelectedSkus(v.__uuid);
          this.removeSelectedSkus(v.__parentUuid);
        }
        this.onSubTableRowClick(v, index, status);
      });
    },
    onSubTableChange(currentRow, status) {
      this.selectedSubRow = deepCopy(currentRow);
      this.removeSelectedSkus(currentRow.__uuid);
      const mainProduct = this.mainTableData.find((v) => v.__uuid === currentRow.__parentUuid);

      if (status) {
        this.selectedSkus.push(currentRow);
        if (this.cloneMeta.multiple) {
          this.$set(mainProduct, '_checked', true);
        }
        this.removeSelectedSkus(currentRow.__parentUuid); // 删除商品
      }
      if (this.cloneMeta.isMustSelectSku && !status) {
        const sameProductSkus = this.selectedSkus.filter((v) => v.__parentUuid === currentRow.__parentUuid);
        if (!sameProductSkus.length) {
          this.$set(mainProduct, '_checked', false);
        }
      }
    },
    onMainTableRowClick(currentRow, index) {
      if (!this.cloneMeta.multiple) {
        return;
      }
      const dataRow = this.mainTableData.find((v) => {
        return v.__uuid === currentRow.__uuid;
      });
      if (dataRow) {
        if (this.cloneMeta.multiple) {
          if (this.cloneMeta.isMustSelectSku) {
            const noSelectSkuProduct = this.selectedSkus.find((v) => v.isProduct);
            if (noSelectSkuProduct && noSelectSkuProduct.__uuid !== currentRow.__uuid) {
              this.$Message.warning({
                content: `${this.mlang('pleaseSelect')}[${noSelectSkuProduct.cName}]${this.mlang('jutiguige')}`,
                duration: 2
              });
              return;
            }
          }
          const checked = dataRow._checked;
          if (!currentRow._disabled) {
            this.$set(dataRow, '_checked', !checked);
          }
          this.onMainTableChange(currentRow, dataRow._checked);
        } else {
          this.$refs.mainTable.handleCurrentRow('highlight', index);
          // this.$set(dataRow, '_highlight', !dataRow._checked);
        }
      }
    },
    onMainTableChange(currentRow, status) {
      if (!this.cloneMeta.multiple) {
        this.selectedSkus = [];
      }
      this.selectedSubRow = null;
      this.selectedMainRow = deepCopy(currentRow);
      this.removeSelectedSkus(currentRow.__uuid); // 删除商品
      if (this.cloneMeta.isMustSelectSku && !status) {
        this.removeSelectedSkus(currentRow.__uuid, '__parentUuid'); // 删除sku
        if(this.subTableData.length && this.subTableData[0] && currentRow.id === this.subTableData[0].iProductId){
          this.subTableData.map((v) => {
            v._checked = false;
          });
        }
      }

      if (status) {
        this.selectedSkus.push(currentRow);
      }
      // if (this.cloneMeta.isAllowSelectProduct && status) {
      //   this.selectSkus.push(currentRow);
      // this.selectSkus = _.uniq([...this.selectSkus, currentRow]);
      // }
      if (!this.cloneMeta.isAllowSelectSku || !status) {
        return;
      }
      if (!currentRow.lsProductSkus) currentRow.lsProductSkus = [];

      if (currentRow.hasSpecs || (!currentRow.hasSpecs && !currentRow.lsProductSkus.length && this.cloneMeta.isMustSelectSku)) {
        this.subTableLoading = true;
        let postData = {
          id: currentRow.id,
          orgId: this.billMeta.orgId,
          isAgent: this.currentUser.isAgent
        };
        // // ys促销获取商品和sku必须传币种，已经在外层控制currencyId
        if (this.billMeta.currencyId) {
          postData.currencyId = this.billMeta.currencyId;
        }
        if (typeof this.billMeta.skuStatus === 'number') {
          postData.status = this.billMeta.skuStatus;
        }
        if (this.source === 'orderGoodsRebate') {
          const rebateIdArr = [];
          if (this?.billMeta?.lsRebateReturnProducts) {
            this.billMeta.lsRebateReturnProducts.forEach(item => {
              rebateIdArr.push({ id: item.rebateId });
            });
          }
          postData.queryMaps = {
            rebateQuery: rebateIdArr
          };
          getRebateSkuAndParametersById(postData).then((res) => {
            res.imgUrl = currentRow.imgUrl;
            res.lsProductParameters = res.lsProductParameters || [];
            res.lsProductSkus = res.lsProductSkus || [];
            const autoChecked = this.cloneMeta.isMustSelectSku && res.lsProductSkus.length === 1;
            const parent = deepCopy(currentRow);
            delete parent.lsProductSkus;
            res.lsProductSkus.map((v) => {
              v.oProduct = parent;
              v.__parentUuid = currentRow.__uuid;
              v.__uuid = v.id + '__' + v.bizProductId;
              if (status && autoChecked) {
                v._checked = true;
                this.selectedSkus.push(v);
                this.removeSelectedSkus(currentRow.__uuid);
              }
            });
            this.parametersdata = res.lsProductParameters;
            this.setSubColumnsAndData(res, status);
            this.subTableLoading = false;
          });
        } else {
          getSkuAndParametersById(postData).then((res) => {
            res.imgUrl = currentRow.imgUrl;
            res.lsProductParameters = res.lsProductParameters || [];
            res.lsProductSkus = res.lsProductSkus || [];
            const autoChecked = this.cloneMeta.isMustSelectSku && res.lsProductSkus.length === 1;
            const parent = deepCopy(currentRow);
            delete parent.lsProductSkus;
            res.lsProductSkus.map((v) => {
              v.oProduct = parent;
              v.__parentUuid = currentRow.__uuid;
              v.__uuid = v.id + '__' + v.bizProductId;
              if (status && autoChecked) {
                v._checked = true;
                this.selectedSkus.push(v);
                this.removeSelectedSkus(currentRow.__uuid);
              }
            });
            this.parametersdata = res.lsProductParameters;
            this.setSubColumnsAndData(res, status);
            this.subTableLoading = false;
          });
        }
      } else {
        const parent = deepCopy(currentRow);
        parent.lsProductParameters = currentRow.lsProductParameters || [];
        parent.lsProductSkus = currentRow.lsProductSkus || [];
        const autoChecked = this.cloneMeta.isMustSelectSku && parent.lsProductSkus.length === 1;
        parent.lsProductSkus.map((v) => {
          v.oProduct = parent;
          delete parent.lsProductSkus;
          v.__parentUuid = currentRow.__uuid;
          v.__uuid = v.id + '__' + v.bizProductId;
          if (status && autoChecked) {
            v._checked = true;
            this.selectedSkus.push(v);
            this.removeSelectedSkus(currentRow.__uuid);
          }
        });
        this.parametersdata = parent.lsProductParameters;
        this.setSubColumnsAndData(parent, status);
      }
    },
    mainTableSectionChange(selection, row, status) {
      this.mainTableIsSelectAll = this.$refs.mainTable.isSelectAll();
      this.selectedRows = selection;
      if (!status) {
        // this.subTableData = [];
        // this.subTableColumn = [];
      }
      row.map((v, index) => {
        if (!status) {
          this.removeSelectedSkus(v.__uuid);
          this.removeSelectedSkus(v.__parentUuid);
        }
        this.onMainTableRowClick(v, index);
        // this.onMainTableChange(v, status);
      });
    },
    setSubColumns(data) {
      const subColumns = [];
      if (this.cloneMeta.isSingleSku) {
        subColumns.push(this.c_radioColumn);
      } else {
        subColumns.push(this.c_selectionColumn);
      }
      const fixSettingColumn = {
        key: 'action',
        title: ' ',
        width: 40,
        fixed: 'suspend',
        renderHeader: (h, params) => {
          const title = h('span', params.column.title);
          const width = `${params.column.width - 20}px`;
          const more = h('Icon', {
            props: { 'class-name': 'table-header-more-search', type: 'ios-funnel-outline' },
            nativeOn: {
              click: this.showMore
            }
          });
          return h('div', { class: 'table-header-div', style: { width } }, [title, more]);
        }
      };
      if (data.lsSpecs && data.lsSpecs.length) {
        data.lsSpecs.map((v) => {
          const column = {
            title: v.cName,
            key: `key_${v.id}`,
            render: (h, params) => {
              const value = params.row[`key_${v.id}`];
              const span = h('span', { class: 'product-ref-code one-line-overflow-hide', attrs: { title: value } }, value);
              return span;
            }
          };
          subColumns.push(column);
        });
        subColumns.push(fixSettingColumn);
        this.subTableColumn = subColumns;
      } else {
        this.subTableColumn = [];
        if (data.lsProductSkus && data.lsProductSkus[0]) {
          this.selectedSubRow = deepCopy(data.lsProductSkus[0]);
        } else {
          this.selectedSubRow = data;
        }
      }
    },
    setSubData(data) {
      const product = deepCopy(data);
      this.selectProduct = product;
      this.setSubTableData(data.lsProductSkus, product);
    },
    setSubTableData(lsProductSkus, product) {
      const parent = deepCopy(product);
      delete parent.lsProductSkus;
      const subData = [];
      if (lsProductSkus && lsProductSkus.length) {
        lsProductSkus.map((sku) => {
          sku.oProduct = parent;
          sku.productName = product.cName;
          sku.productCode = product.cCode;
          if (sku.lsSkuSpecItems && sku.lsSkuSpecItems.length) {
            sku.lsSkuSpecItems.map((v) => {
              sku[`key_${v.iSpecId}`] = v.cSpecItemName;
            });
            subData.push(sku);
          }
        });
      }
      this.subTableData = subData;
    },
    setSubColumnsAndData(data, status) {
      data.productName = data.cName;
      if (data && data.lsSpecs) {
        data.lsSpecs.map((v) => {
          if (v.lsSpecItem) {
            v.lsSpecItem.map((item) => {
              item.active = false;
            });
          }
        });
      }

      if (data && data.lsProductSkus) {
        data.lsProductSkus.map((sku) => {
          const uiSpecids = [];
          if (status === true) {
            if (!this.cloneMeta.isAllowSelectProduct) {
              sku._checked = true;
            }
          } else if (status === false) {
            sku._checked = false;
          }
          const checked = this.selectedSkus.find((v) => {
            return v.__uuid === sku.__uuid;
          });
          if (checked) {
            sku._checked = true;
          }
          if (sku.lsSkuSpecItems) {
            sku.lsSkuSpecItems.map((spec) => {
              uiSpecids.push(spec.id);
            });
            sku.uiSpecids = uiSpecids;
          }
        });
      }
      this.setSubData(data);
      this.setSubColumns(data);
    },
    getSingleSelectRow() {
      const skus = this.selectedSkus;
      skus.forEach((v) => {
        if (!v.productName) {
          v.productName = v.oProduct.productName;
        }
      });
      const products = this.selectedSkus.filter(v => v.isProduct);
      if (!this.cloneMeta.isAllowSelectSku) {
        if (products && !products.length) {
          this.$Message.warning({ content: this.mlang('pleaseSelectProduct') });
          return false;
        }
        const selectedRow = products[0];
        if (this.cloneMeta.isMustSelectSku && selectedRow && !selectedRow.hasSpecs) {
          const sku = selectedRow.lsProductSkus && selectedRow.lsProductSkus[0];
          sku.productName = selectedRow.cName;
          return sku;
        }
      }
      if (this.cloneMeta.isMustSelectSku && (!skus.length || !skus[0].isSku)) {
        this.$Message.warning({ content: this.mlang('pleaseSelectSku') });
        return false;
      }
      return skus.length ? skus[0] : products[0];
    },
    getSelectRow() {
      if (this.selectedSkus && !this.selectedSkus.length) {
        this.$Message.warning({ content: this.mlang('pleaseSelectProduct') });
        return false;
      }
      const skus = this.selectedSkus;
      const products = this.selectedSkus.filter(v => v.isProduct);
      if (this.cloneMeta.multiple) {
        // 去掉已选sku的product行
        skus.map((sku) => {
          this.removeSelectedSkus(sku.__parentUuid);
        });

        if (this.cloneMeta.isMustSelectSku) {
          // let pass = true;
          for (let index = 0; index < products.length; index++) {
            const product = products[index];
            if (product.hasSpecs) {
              // pass = false;
              this.$Message.warning({ content: `${this.mlang('pleaseSelect')}${product.cName}${this.mlang('jutiguige')}` });
              return false;
            }
          }
        }
        return this.selectedSkus;
      } else {
        return this.getSingleSelectRow();
      }
    },
    tabClick(name) {},
    showMore() {
      this.moreVisible = true;
    },
    hideMore() {
      this.moreVisible = false;
    },
    save() {},
    makeMainColumns() {
      const noWidthColumn = {
        key: 'nowidth',
        title: ''
      };
      const selectionColumn = {
        type: 'selection',
        width: 40,
        align: 'center',
        isHideCheckAll: this.cloneMeta.isMustSelectSku
      };
      if (this.cloneMeta.multiple) {
        this.cloneMeta.columns = [selectionColumn, ...this.cloneMeta.columns, noWidthColumn];
      } else {
        this.cloneMeta.columns = [this.c_radioColumn, ...this.cloneMeta.columns, noWidthColumn];
      }
      const productColumn = this.cloneMeta.columns.find((v) => {
        return v.fieldName === 'name';
      });
      productColumn.render = (h, params) => {
        return (
          <div>
            <div title={params.row.cName} class="product-ref-name two-line-overflow-hide">
              {params.row.cName}
            </div>
          </div>
        );
      };
      this.mainColumns = this.cloneMeta.columns;
    },
    setColumns() {
      if (this.cloneMeta.multiple) {
        this.cloneMeta.columns = [this.radioColumn, ...this.cloneMeta.columns];
      } else {
        this.cloneMeta.columns = [this.selectionColumn, ...this.cloneMeta.columns];
      }
      let columns = this.cloneMeta.columns.filter((v) => {
        return v.key !== 'action';
      });
      if (columns) {
        const childObjArr = columns.filter((v) => {
          const spiltArr = _.split(v.key, '.');
          return spiltArr && spiltArr.length > 1;
        });
        childObjArr.map((v, k) => {
          v.render = (h, params) => {
            const keys = _.split(v.key, '.');
            let formatVal = params.row;
            keys.forEach((key) => {
              formatVal = formatVal || {};
              formatVal = formatVal[key] || '';
            });
            return h('span', {}, formatVal);
          };
        });
      }
      this.columns = this.cloneMeta.columns;
    },
    makeQueryschema() {
      let queryschemas = deepCopy(this.billMeta.queryschema);
      this.flattenQueryschemaArr = queryschemas.filter((v) => {
        return v.isHidden !== true;
      });
    },

    getQueryschemaData() {
      const schema = this.$refs.queryschema.getQueryschemaRealData();
      schema.pager = this.pager;
      return schema;
    },
    queryschemaLoaded() {
      this.height = 434;
      const querySchema = this.getQueryschemaData();
      this.queryschema = querySchema;
      this.loadProductData();
    },
    changePage(page) {
      this.pager.pageIndex = page;
      this.loadProductData();
    },
    setPager() {
      if (this.cloneMeta.pagerSizeOpts) {
        this.pager.pageSizeOpts = this.cloneMeta.pagerSizeOpts;
      }
      this.pager.pageSize = this.pager.pageSizeOpts[0];
    },
    triggerSpecActive(sku) {
      sku.active = !sku.active;
    },
    skuSearchClick() {
      const selectSpecItem = [];
      let subTableData = [];
      this.selectProduct.lsSpecs.map((v) => {
        let spec = [];
        v.lsSpecItem.map((item) => {
          if (item.active) {
            spec.push(item.id);
          }
        });
        if (spec.length) {
          selectSpecItem.push(spec);
        }
      });
      let conditionArrayStr = '';
      selectSpecItem.map((v) => {
        conditionArrayStr += v.length ? ',' + v.join('|') : '';
        return conditionArrayStr;
      });
      if (conditionArrayStr) {
        conditionArrayStr = conditionArrayStr.substring(1);
      }
      if (selectSpecItem.length) {
        const postData = {
          id: this.selectProduct.id,
          bizProductId: this.selectProduct.bizProductId,
          saleOrgId: this.selectProduct.orgId,
          specItemIds: conditionArrayStr
        };
        this.removeSelectedSkus(this.selectProduct.__uuid);
        getProductSku(postData).then((res) => {
          res.map((v) => {
            v.__parentUuid = this.selectProduct.__uuid;
            v.__uuid = v.id + '__' + v.bizProductId;
          });
          this.setSubTableData(res, this.selectProduct);
          this.hideMore();
        });
      } else {
        subTableData = this.selectProduct.lsProductSkus;
        this.setSubTableData(subTableData, this.selectProduct);
      }
    },
    // 前一页
    prePage(currentPage) {
      if (currentPage <= 1) {
        this.pager.currentPage = 1;
        return;
      } else {
        this.pager.currentPage = currentPage - 1;
      }
      this.changePage(this.pager.currentPage);
    },
    // 下一页
    nextPage(currentPage) {
      if (this.mainTableData.length < 10) {
        this.pager.currentPage = currentPage;
        return;
      } else {
        this.pager.currentPage = currentPage + 1;
      }
      this.changePage(this.pager.currentPage);
    }
  }
};
</script>
