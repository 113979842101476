<template>
  <div class="preview-upload-ctrl">
    <div
      v-for="file in formFileData"
      :key="file.id"
      class="pull-left">
      <div
        v-if="!file.beRemoved"
        class="fileList"
        @click="click(file)">
        <div class="fileImg">
          <template v-if="isImage(file.extName || file.cExtName)">
            <img :src="file.url || file.cUrl">
          </template>
          <template v-else>
            <SvgIcon :icon-class="getUploadIcon(file.extName || file.cExtName)"></SvgIcon>
          </template>
          <div
            v-if="editable"
            class="fileBtn">
            <a
              :href="file.url|| file.cUrl"
              target="_blank"
              class="download"><Icon type="ios-download-outline" /></a>
            <a
              v-if="editable"
              class="close"><Icon
                type="ios-trash-outline"
                @click.native="handleUploadRemove(file,item)"></icon></a>
          </div>
        </div>
        <div
          :title="file.originalName"
          class="fileName">
          {{ file.originalName||file.cOriginalName }}
        </div>
        <span
          v-if="file.fileSize"
          class="fileSize">{{ fileSize(file.fileSize|| file.cFileSize) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/u-components/components/svg-icon';
import { oneOf } from '@/u-components/utils/assist';
import util from '@/libs/util.js';
export default {
  name: 'VoucherList',
  components: {
    SvgIcon
  },
  props: {
    editable: {
      type: Boolean
    },
    formData: {
      type: Object
    },
    formFileData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    item: {
      type: Object
    }
  },
  data() {
    return {
      iconType: new Map([['.xls', 'Excel'], ['.xlsx', 'Excel'], ['.csv', 'Excel'], ['.docx', 'Word'], ['.doc', 'Word'], ['.pdf', 'Pdf'], ['.txt', 'Txt']]),
      imgExts: ['.jpg', '.jpeg', '.png', '.bmp', 'gif']
    };
  },
  created() {},
  methods: {
    click(file) {
      if (!this.editable) this.$open(file.url || file.cUrl);
    },
    isImage(ext) {
      if (ext?.indexOf('.') === -1) ext = `.${ext}`;
      ext = ext && ext.toLocaleLowerCase();
      return oneOf(ext, this.imgExts);
    },
    getUploadIcon(ext) {
      if (ext.indexOf('.') === -1) ext = `.${ext}`;
      return this.iconType.get(ext);
    },
    fileSize(limit) {
      return util.fileSize(limit);
    },
    handleUploadRemove(file, control) {
      const fileList = this.formFileData;
      let index = fileList.indexOf(file);
      this.$emit('handleUploadDel', control, index);
    }
  }
};
</script>
<style scoped lang="less">
.fileBtn {
  a:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}
</style>
