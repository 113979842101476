import accountParentView from '@/views/agent/home/components/accountHome.vue';

const accounts = {
  path: 'agentbase',
  name: 'agentbase',
  meta: { module: 'agentbase' },
  component: accountParentView,
  children: [
    // 嵌入angular页面的路由
    {
      path: 'agentbase',
      name: 'agentbase',
      meta: { module: 'agentbase' },
      component: () => import('@/views/agent/accounts/components/accountInfo/agentBase.vue')
    },
    {
      path: 'agentcontacts',
      name: 'agentcontacts',
      meta: { module: 'agentbase' },
      redirect: { name: 'agentcontactslist' },
      component: () => import('@/views/agent/accounts/components/accountInfo/agentContact.vue'),
      children: [
        {
          path: 'agentcontactslist',
          name: 'agentcontactslist',
          meta: { module: 'agentbase' , redirectFrom:'agentcontacts'},
          component: () => import('@/views/agent/accounts/components/accountInfo/contact/contacts.vue')
        },
        {
          path: 'agentcontactsdetail',
          name: 'agentcontactsdetail',
          meta: { module: 'agentbase' , redirectFrom:'agentcontacts'},
          component: () => import('@/views/agent/accounts/components/accountInfo/contact/contact.vue')
        }
      ]
    },
    {
      path: 'agentaddress',
      name: 'agentaddress',
      meta: { module: 'agentbase' },
      redirect: { name: 'agentaddresslist' },
      component: () => import('@/views/agent/accounts/components/accountInfo/agentContact.vue'),
      children: [
        {
          path: 'agentaddresslist',
          name: 'agentaddresslist',
          meta: { module: 'agentbase' , redirectFrom:'agentaddress' },
          component: () => import('@/views/agent/accounts/components/accountInfo/address/addresses.vue')
        },
        {
          path: 'agentaddressdetail',
          name: 'agentaddressdetail',
          meta: { module: 'agentbase'  , redirectFrom:'agentaddress'},
          component: () => import('@/views/agent/accounts/components/accountInfo/address/address.vue')
        }
      ]
    },
    {
      path: 'bank',
      name: 'bank',
      meta: { module: 'agentbase'},
      redirect: { name: 'banklist' },
      component: () => import('@/views/agent/accounts/components/accountInfo/agentContact.vue'),
      children: [
        {
          path: 'banklist',
          name: 'banklist',
          meta: { module: 'agentbase', redirectFrom:'bank'},
          component: () => import('@/views/agent/accounts/components/accountInfo/bank/banks.vue')
        },
        {
          path: 'bankdetail',
          name: 'bankdetail',
          meta: { module: 'agentbase', redirectFrom:'bank' },
          component: () => import('@/views/agent/accounts/components/accountInfo/bank/bank.vue')
        }
      ]
    },
    {
      path: 'mutiAgentsInvoices',
      name: 'mutiAgentsInvoices',
      redirect: { name: 'accountmutiAgentsInvoiceslist' },
      meta: { module: 'agentbase' },
      component: () => import('@/views/agent/accounts/components/accountInfo/accountAgentInvoice.vue'),
      children: [
        {
          path: 'accountmutiAgentsInvoicesdetail',
          name: 'accountmutiAgentsInvoicesdetail',
          meta: { module: 'agentbase', redirectFrom:'mutiAgentsInvoices' },
          component: () => import('@/views/agent/accounts/components/accountInfo//invoice/invoice.vue')
        },
        {
          path: 'accountmutiAgentsInvoiceslist',
          name: 'accountmutiAgentsInvoiceslist',
          meta: { module: 'agentbase' , redirectFrom:'mutiAgentsInvoices'},
          component: () => import('@/views/agent/accounts/components/accountInfo/invoice/invoices.vue')
        }
      ]
    },
    // vue页面用的路由
    {
      path: 'accountInfo',
      name: 'accountInfo',
      meta: { module: 'agentbase' },
      component: () => import('@/views/agent/accounts/components/accountInfo/accountInfo.vue'),
      children: [
        {
          path: 'accountagentbase',
          name: 'accountagentbase',
          meta: { module: 'agentbase'},
          component: () => import('@/views/agent/accounts/components/accountInfo/agentBase.vue')
        },
        {
          path: 'accountagentcontacts',
          name: 'accountagentcontacts',
          meta: { module: 'agentbase' },

          component: () => import('@/views/agent/accounts/components/accountInfo/agentContact.vue'),
          children: [
            {
              path: 'accountagentcontactsdetail',
              name: 'accountagentcontactsdetail',
              meta: { module: 'agentbase' },
              component: () => import('@/views/agent/accounts/components/accountInfo/contact/contact.vue')
            },
            {
              path: 'accountagentcontactslist',
              name: 'accountagentcontactslist',
              meta: { module: 'agentbase' },
              component: () => import('@/views/agent/accounts/components/accountInfo/contact/contacts.vue')
            }
          ]
        },
        {
          path: 'accountagentaddress',
          name: 'accountagentaddress',
          meta: { module: 'agentbase' },
          component: () => import('@/views/agent/accounts/components/accountInfo/agentAddress.vue'),
          children: [
            {
              path: 'accountagentaddressdetail',
              name: 'accountagentaddressdetail',
              meta: { module: 'agentbase' },
              component: () => import('@/views/agent/accounts/components/accountInfo/address/address.vue')
            },
            {
              path: 'accountagentaddresslist',
              name: 'accountagentaddresslist',
              meta: { module: 'agentbase' },
              component: () => import('@/views/agent/accounts/components/accountInfo/address/addresses.vue')
            }
          ]
        },
        {
          path: 'accountagentbank',
          name: 'accountagentbank',
          meta: { module: 'agentbase' },
          component: () => import('@/views/agent/accounts/components/accountInfo/agentBank.vue'),
          children: [
            {
              path: 'accountagentbankdetail',
              name: 'accountagentbankdetail',
              meta: { module: 'agentbase' },
              component: () => import('@/views/agent/accounts/components/accountInfo/bank/bank.vue')
            },
            {
              path: 'accountagentbanklist',
              name: 'accountagentbanklist',
              meta: { module: 'agentbase' },

              component: () => import('@/views/agent/accounts/components/accountInfo/bank/banks.vue')
            }
          ]
        }
      ]
    }
  ]
};

export default accounts;
