/* eslint-disable*/
// Generated by CoffeeScript 1.6.2
let param;
(function() {
  var buildParams, r20, rCRLF, rbracket, rsubmittable, rsubmitterTypes;

  r20 = /%20/g;

  rbracket = /\[\]$/;

  rCRLF = /\r?\n/g;

  rsubmitterTypes = /^(?:submit|button|image|reset|file)$/i;

  rsubmittable = /^(?:input|select|textarea|keygen)/i;

  buildParams = function(prefix, obj, add) {
    var name, _results;

    name = void 0;
    if (obj instanceof Array) {
      return obj.forEach(function(v, i) {
        if (rbracket.test(prefix)) {
          return add(prefix, v);
        } else {
          return buildParams(prefix + '[' + (typeof v === 'object' ? i : '') + ']', v, add);
        }
      });
    } else if (typeof obj === 'object') {
      _results = [];
      for (name in obj) {
        _results.push(buildParams(prefix + '[' + name + ']', obj[name], add));
      }
      return _results;
    } else {
      return add(prefix, obj);
    }
  };

  param = function(a) {
    var add, prefix, s;

    prefix = void 0;
    s = [];
    add = function(key, value) {
      if (typeof value === 'function') {
        value = value();
      } else {
        if (value == null) {
          value = '';
        } else {
          value = value;
        }
      }
      return (s[s.length] = encodeURIComponent(key) + '=' + encodeURIComponent(value));
    };
    if (a instanceof Array) {
      a.forEach(function() {
        return add(this.name, this.value);
      });
    } else {
      for (prefix in a) {
        buildParams(prefix, a[prefix], add);
      }
    }
    return s.join('&').replace(r20, '+');
  };

  if (typeof window !== 'undefined') {
    // window.param = param;
  }
}.call(window));

export default param;
