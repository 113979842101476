import ajax from '@/libs/ajax';
import util from '@/libs/util.js';
// 客户分类
export const getAgentCategorysTree = () => {
  return ajax
    .request({
      url: '/agent/infrastructure/category/vo/tree',
      data: { isArchive: 1 },
      method: 'get'
    })
    .then((data) => {
      return util.reduceChildren(data, 'subNodes', 'name');
    });
};
// 权限设置-客户分类
export const getAgentCategorysTreeAuths = (notAll) => {
  return ajax
    .request({
      url: '/agent/infrastructure/category/tree',
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data = []) => {
      if (!notAll) {
        data.unshift({
          id: '-1',
          name: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800012","无分类") /* "无分类" */
        });
      }
      return util.reduceChildren(data, 'subNodes', 'name');
    });
};

// 客户区域
export const getAgentAreasTree = () => {
  return ajax.request({ url: '/agent/infrastructure/agentarea/tree?isArchive=1', data: { iDepth: 1 }, method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'subNodes', 'name');
  });
};

// 上架的商品分类
export const getUpProductClassTree = () => {
  return ajax.request({ url: window.__app.context.productLine === 'u8c' ? '/goods/infrastructure/productclasses/list/up/modelVo?isArchive=1' : '/pc/ProductClass/getUpClasses', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'lsChildClass', 'cName');
  });
};
// 所有商品分类 包括未上架
export const getProductClassTree = (query) => {
  return ajax
    .request({
      data: {
        datatype: 'json',
        isArchive: 1,
        data: {
          isFlat: false
        }
      },
      url: '/goods/infrastructure/productclasses/list/query',
      method: 'post'
    })
    .then((data) => {
      return util.reduceChildren(data, 'subClasses', 'name');
    });
};
export const getProductClassTreeYS = (query) => {
  return ajax
    .request({
      url: '/goods/infrastructure/productclasses/list/up/vo?isArchive=1',
      method: 'get'
    })
    .then((data) => {
      return util.reduceChildren(data, 'lsChildClass', 'name');
    });
};

/**
 * @method getFellItemData 获取费用参照
 */
export const getFellItemData = (query) => {
  let simpleVOs = [];
  let masterOrgValue = ''; let isNeedMasterOrgValue = false;
  query.conditions.map(item => {
    if (item.simpleVOsData) {
      simpleVOs = simpleVOs.concat(item.simpleVOsData);
      if (item.simpleVOsData.length > 0) {
        isNeedMasterOrgValue = true;
      };
    } else {
      masterOrgValue = item.v1;
      simpleVOs.push({
        field: item.name,
        op: 'like',
        value1: item.v1
      });
    }
  });
  return ajax.request({
    url: '/bill/ref/getRefData?terminalType=1&serviceCode=voucher_amountrebatelist',
    method: 'post',
    data: {
      isMarket: 1,
      data: {
        refCode: 'finbd.bd_expenseitemref',
        masterOrgValue: isNeedMasterOrgValue ? masterOrgValue : '',
        condition: { simpleVOs: simpleVOs },
        datatype: 'grid',
        page: {
          pageIndex: query.pager.pageIndex,
          pageSize: query.pager.pageSize
        }

      },
      datatype: 'json'
    }
  }).then(val => {
    // let pageable = {
    //   offset: 0,
    //   pageNumber: 0,
    //   pageSize: 10,
    //   paged: true,
    //   sort: { sorted: false, unsorted: true },
    //   unpaged: false
    // };
    let res = {
      content: val.gridData && val.gridData.recordList,
      // pageable: pageable,
      // first: true,
      // last: false,
      // number: 0,
      // numberOfElements: 10,
      // size: 10,
      // sort: { sorted: false, unsorted: true },
      totalElements: val.gridData && val.gridData.recordCount
    };
    return res;
  });
};

// 结算方式
export const getSettlementWayTree = () => {
  return ajax.request({ url: window.__app.context.productLine === 'u8c' ? '/voucher/order/settlementwayModelvo/tree?isArchive=1' : '/pc/basic/ArchiveSrv/getSettlementWayTree', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubNodes', 'cName');
  });
};

// 行业
export const getAgentIndustry = () => {
  return ajax.request({ url: '/pc/agent/AgentIndustrySrv/getAgentIndustryListSimpleInfo', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubNodes', 'name');
  });
};
// 区域
export const getAgentArea = () => {
  return ajax.request({ url: '/agent/infrastructure/agentarea/tree?iDepth=1&isArchive=1', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'subNodes', 'name');
  });
};
// 级别
export const getAgentLevel = () => {
  return ajax.request({ url: '/agent/infrastructure/agentlevel/list?isArchive=1', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubNodes', 'name');
  });
};
// 分组
/**
 * @method getAgentGroup BIP促销维度无分组
 */
export const getAgentGroup = () => {
  return ajax.request({ url: '/pc/agent/AgentDataAuthGroupSrv/getAgentAuthGroupList', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubNodes', 'name');
  });
};

// 发运方式
export const getShippingChoiceTree = () => {
  return ajax.request({ url: '/voucher/order/shippingchoiceModelvo/list?isArchive=1', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubshippingChoice', 'cName');
  });
};
// 客户商品权限分组
export const getAgentDataAuthGroupTree = () => {
  return ajax.request({ url: '/pc/agent/AgentDataAuthGroupSrv/getAgentAuthGroupList', method: 'get' }).then((data) => {
    return util.reduceChildren(data, 'oSubAgentDataAuthGroup', 'name');
  });
};
// 地址
export const getAddress = (iParentId = 0, levelType = 0) => {
  return ajax
    .request({
      url: `/archives/address/getAddressForMobileByParent/${iParentId}/${levelType}?isArchive=1`,
      method: 'get'
    })
    .then((data) => {
      return util.reduceChildren(data, 'oSubNodes', 'name');
    });
};

// 库存量列表商品树
export const productClassAction = () => {
  return ajax
    .request({
      url: '/goods/infrastructure/productclasses/list?isArchive=1',
      data: {},
      method: 'get'
    })
    .then((data) => {
      return util.reduceChildren(data, 'lsChildClass', 'name');
    });
};

// 库存量列表获取仓库
export const stockListAction = (data) => {
  return ajax
    .request({
      url: '/goods/inventory/stock/getStocks?isArchive=1',
      method: 'get',
      data: {}
    })
    .then((data) => {
      return util.reduceChildren(data, 'aaaa', 'cName', 'cCode', 'id');
    });
};
// 商品分类树
export const productClassSelect = () => {
  return ajax
    .request({
      url: '/goods/infrastructure/productclasses/list',
      data: {
        isArchive: 1
      },
      method: 'get'
    })
    .then((data) => {
      return util.reduceChildren(data, 'lsChildClass', 'name');
    });
};

// 客户折扣获取定价维度接口
export const getDimensionList = (id) => {
  return ajax
    .request({
      url: '/goods/price/customerjustify/platformtype',
      data: {
        id,
        isArchive: 1
      },
      method: 'get'
    })
    .then((data) => {
      data.map((v) => {
        v.title = v.name;
      });
      return data;
    });
};
// 获取渠道等级
export const getInfrastructureLevel = (id) => {
  return ajax
    .request({
      url: '/agent/infrastructure/agentlevel/platform/list',
      data: {
        id,
        isArchive: 1
      },
      method: 'get'
    })
    .then((data) => {
      data.map((v) => {
        v.title = v.name;
        v.value = v.id;
      });
      return data;
    });
};
// 客户来源
export const getAgentSourceTree = () => {
  return ajax.request({
    url: '/agent/infrastructure/agent/sourcetype',
    method: 'get',
    data: {
      isArchive: 1
    }
  }).then((data) => {
    return util.reduceChildren(data, 'subDataSource');
  });
};

// 获取支付方式
export const getPayWayTree = () => {
  return ajax.request({
    url: '/cyberbank/payChannel',
    method: 'get',
    data: {
      isArchive: 1
    }
  }).then((data) => {
    return util.reduceChildren(data, 'children', 'name', null, null, 'values');
  });
};
