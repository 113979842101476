<template>
  <div>
    <div
      v-if="rowFilters && rowFilters.length"
      class="rowFilters">
      <div
        v-for="control in rowFilters"
        :key="control.fieldName"
        class="rowFilter">
        <div
          v-if="c_isRowCtrl(control.controlType) || (control.customerDataSource && (control.controlType === 'Select' || control.controlType === 'Enum')) || control.controlType === 'Select'"
          class="one-row">
          <Row
            v-show="control.visible"
            class="queryschema-row">
            <QuerySchemaLabel :title="control.caption" :style-set="control.style"></QuerySchemaLabel>
            <Col
              span="24"
              class="queryschema-control">
            <template v-if="control.controlType === 'Enum'">
              <TagButton
                v-for="tag in control.enumArray"
                :key="tag[control.enumStoreType.toLowerCase()]"
                v-model="p_viewData[control.name]"
                :fieldid="'tag_'+tag.name"
                :is-children-checked="isChildrenChecked(tag)"
                :name="tag[control.enumStoreType.toLowerCase()]"
                :show-pop-tip="tag['children'] && tag['children'].length > 0"
                @on-change="tagChange(...arguments,control)">
                {{ tag.title }}
                <div v-if="tag['children'] && tag['children'].length > 0" slot="childrenContent">
                  <div
                    v-for="(item,index) in tag.children"
                    :key="index"
                    class="queryschema-control-enum-children-slot"
                    :class="{ activeChildren:index===currentchildren}"
                    @click="tagChildrenChange(item,control,index,tag)">
                    {{ item.title }}
                  </div>
                </div>
              </TagButton>
            </template>
            <template v-if="control.controlType === 'PredicateDateTime'">
              <PredicateDatepicker
                v-model="p_viewData[control.name]"
                :control="control"
                :biz-meta="bizMeta"
                @on-tag-click="tagClick"></PredicateDatepicker>
            </template>
            <template v-if="control.controlType === 'Select' && control.customerDataSource">
              <TreeSelect
                v-if="initTreeSelectOptions(control)"
                v-model="p_viewData[control.name]"
                :instance-id="control.name"
                :normalizer="treeSelectNormalizer"
                :options="treeSelectOptions[control.name]"
                :no-results-text="mlang('noResults')"
                :no-options-text="mlang('noResults')"
                :loading-text="mlang('loading')"
                :clearable="(control.clearable || false)"
                style="width:300px;"
                class-name="ivu-select"
                placeholder
                @input="tagClick" />
            </template>
            <Select
              v-if="control.controlType == 'Select' && !control.customerDataSource"
              :ref="`ref_select_${control.name}`"
              v-model="p_viewData[control.name]"
              :fieldid="`${control.controlType}_${control.name}`"
              :style="selectStyles(control)"
              :placeholder="control.placehoder || ''"
              style="width:300px;"
              @on-change="selectTagChange(...arguments,control)"
              @on-open-change="handleOnSelectOpen(control,...arguments)">
              <Option
                v-for="option in control.enumArray"
                :key="option.value"
                :fieldid="`option_${control.name}_${option.value}`"
                :value="option.value">
                {{ option.title }}
              </Option>
            </Select>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <template v-if="cellFilters && cellFilters.length">
      <template v-for="(control,index) in cellFilters">
        <div
          v-show="showCellFilter(control,index)"
          :key="index"
          class="querycell">
          <Col
            v-if="c_isNormalCtrl(control.controlType)"
            class="querycell-ivu-col"
            :contorl="control"
            :style="control.queryschemaCowStyle"
            :span="span(control)">
          <Row
            :style="queryschemaRowStyle(cellFilters,index)"
            class-name="queryschema-row">
            <QuerySchemaLabel :title="control.caption" :style="control.style"></QuerySchemaLabel>
            <Col
              span="15"
              class="queryschema-control">
            <Input
              v-if="control.controlType === 'String'"
              v-model="p_viewData[control.name]"
              :placeholder="control.placeholder"
              :fieldid="`${control.controlType}_${control.name}`"
              :search="control.isSearch || false"
              class="input"
              :clearable="control.isClearable"
              @on-enter="searchEnter"
              @on-search="searchEnter" />

            <QuerySchemaInputNumber
              v-if="control.controlType === 'Number'"
              v-model="p_viewData[control.name]"
              :placeholder="bRefer ? control.caption:''"
              :max="control.max"
              :min="control.min"
              :precision="control.numPoint"
              :fieldid="`${control.controlType}_${control.name}`"
              class="ivu-input-wrapper"
              @on-enter="searchEnter"></QuerySchemaInputNumber>
            <QuerySchemaNumberRange
              v-if="control.controlType === 'NumberRange'"
              v-model="p_viewData[control.name]"
              :max="control.max"
              :min="control.min"
              :precision="control.numPoint"
              :fieldid="`${control.controlType}_${control.name}`"
              class="ivu-input-wrapper"
              @on-enter="searchEnter"></QuerySchemaNumberRange>
            <template v-if="control.controlType === 'Date'">
              <DatePicker
                :value="p_viewData[control.name]"
                :editable="false"
                :options="control.options"
                :fieldid="`${control.controlType}_${control.name}`"
                type="date"
                clearable
                placement="bottom-start"
                @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
            </template>
            <template v-if="control.controlType === 'TIME'">
              <TimePicker
                :value="p_viewData[control.name]"
                :editable="false"
                :has-default="false"
                :fieldid="`${control.controlType}_${control.name}`"
                :options="control.options"
                type="time"
                @on-change="setDatePickerValue(...arguments,control)"></TimePicker>
            </template>
            <template v-if="control.controlType === 'DateTime'">
              <DatePicker
                :value="p_viewData[control.name]"
                :editable="false"
                :options="control.options"
                :fieldid="`${control.controlType}_${control.name}`"
                type="datetime"
                @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
            </template>
            <template v-if="control.controlType === 'DateTimeRange'">
              <DatePicker
                :value="p_viewData[control.name]"
                :editable="false"
                :options="control.options"
                :auto-time="true"
                :fieldid="`${control.controlType}_${control.name}`"
                split-panels
                type="datetimerange"
                clearable
                placement="bottom-start"
                @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
            </template>
            <template v-if="control.controlType === 'DateRange'">
              <DatePicker
                :value="p_viewData[control.name]"
                :editable="false"
                :options="control.options"
                :placeholder="control.placeholder"
                :fieldid="`${control.controlType}_${control.name}`"
                split-panels
                type="daterange"
                clearable
                placement="bottom-start"
                @on-change="setDatePickerValue(...arguments,control)"></DatePicker>
            </template>
            <template v-if="control.controlType === 'Select' && control.customerDataSource">
              <TreeSelect
                v-if="initTreeSelectOptions(control)"
                v-model="p_viewData[control.name]"
                :instance-id="control.name"
                :normalizer="treeSelectNormalizer"
                :options="treeSelectOptions[control.name]"
                :no-results-text="mlang('noResults')"
                :no-options-text="mlang('noResults')"
                :loading-text="mlang('loading')"
                :style="selectStyles(control)"
                :fieldid="`${control.controlType}_customerDataSource0_${control.name}`"
                clearable
                class-name="ivu-select"
                placeholder />
            </template>
            <Select
              v-if="control.controlType == 'Select' && !control.customerDataSource"
              :ref="`ref_select_${control.name}`"
              v-model="p_viewData[control.name]"
              :style="selectStyles(control)"
              :placeholder="control.placehoder || ''"
              :clearable="control.clearable"
              :disabled="control.disabled"
              :fieldid="`${control.controlType}_customerDataSource1_${control.name}`"
              @on-clear="dynamicChange(control,...arguments)"
              @on-change="dynamicChange(control,...arguments)"
              @on-open-change="handleOnSelectOpen(control,...arguments)">
              <Option
                v-for="option in control.enumArray"
                :key="option.value"
                :label="option.title"
                :value="option.value"></Option>
            </Select>
            <Select
              v-if="control.controlType == 'DSelect' && !control.customerDataSource && !isFrame"
              :ref="`ref_select_${control.name}`"
              v-model="p_viewData[control.name]"
              :fieldid="`${control.controlType}_customerDataSource0_${control.name}`"
              :style="selectStyles(control)"
              :placeholder="control.placehoder || ''"
              :clearable="control.clearable"
              :disabled="control.disabled"
              @on-clear="dynamicChange(control,...arguments)"
              @on-change="dynamicChange(control,...arguments)"
              @on-open-change="handleOnSelectOpen(control,...arguments)">
              <Option
                v-for="option in control.enumArray"
                :key="option.value"
                :label="option.title"
                :value="option.value"></Option>
            </Select>
            <div v-if="control.controlType == 'DSelect' && !control.customerDataSource && isFrame" class="selectDiv">
              <div class="divInput">
                <div class="input" @click="openValue">
                  <input v-model="dSelectTitle" disabled type="text" :placeholder="control.placehoder || ''" :fieldid="`${control.controlType}_customerDataSource1_${control.name}`">
                </div>
                <Icon v-if="dSelectTitle" type="ios-close-circle" @click="dSelectClear(control)" />
                <Icon v-else type="ios-arrow-down" />
                <div v-show="listShow" class="list">
                  <ul @click="dSelectChange(control)">
                    <li v-for="option in control.enumArray" :key="option.value" @click="getvalue(option.value, option.title)">
                      {{ option.title }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <template v-if="control.controlType === 'TreeSelect'">
              <TreeSelect
                v-if="initTreeSelectOptions(control)"
                v-model="p_viewData[control.name]"
                :instance-id="control.name"
                :normalizer="treeSelectNormalizer"
                :options="treeSelectOptions[control.name]"
                :load-options="loadTreeSelectData"
                :auto-load-root-options="false"
                :no-results-text="mlang('noResults')"
                :no-options-text="mlang('noResults')"
                :loading-text="mlang('loading')"
                :clearable="true"
                :placeholder="control.placeholder || ''"
                :fieldid="`${control.controlType}_${control.name}`"
                class-name="ivu-select" />
            </template>
            <template v-if="control.controlType === 'TreeSelects'">
              <TreeSelect
                v-if="initTreeSelectOptions(control)"
                v-model="p_viewData[control.name]"
                :instance-id="control.name"
                :multiple="true"
                class="multipleStyle"
                :normalizer="treeSelectNormalizer"
                :options="treeSelectOptions[control.name]"
                :load-options="loadTreeSelectData"
                :auto-load-root-options="false"
                :no-results-text="mlang('noResults')"
                :no-options-text="mlang('noResults')"
                :loading-text="mlang('loading')"
                :clearable="true"
                :placeholder="control.placeholder || ''"
                :fieldid="`${control.controlType}_${control.name}`"
                class-name="ivu-select" />
            </template>
            <template v-if="control.controlType === 'Refer'">
              <Refer
                :id="control.name"
                ref="refer-{{control.name}}"
                v-model="p_viewData[control.name]"
                :refer-type="control.referType"
                :show-text="control.referTextShow ? control.referTextShow : ''"
                :biz-meta="bizMeta"
                :control="control"
                :fieldid="`${control.controlType}_${control.name}`">
                <ProductReferTable
                  v-if="control.key==='productRef'"
                  slot="refer-table"
                  v-model="p_viewData[control.name]"
                  :biz-meta="bizMeta"
                  :control="control"></ProductReferTable>
              </Refer>
            </template>
            </Col>
          </Row>
          </Col>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
// import dayjs from 'dayjs';
import CommonMixin from '@/mixin/common.js';
import PropsyncMixin from '@/mixin/propsync.js';
import QuerySchemaLabel from './queryschema-label';
import QuerySchemaInputNumber from './queryschema-input-number';
import QuerySchemaNumberRange from './queryschema-number-range';
import Refer from '_c/common/refer/refer';
import ProductReferTable from '_c/common/refer/product-ref/product-ref-table';
import { deepCopy } from '../../../u-components/utils/assist';

export default {
  name: 'QuerySchemaItem',
  components: {
    Refer,
    QuerySchemaLabel,
    ProductReferTable,
    QuerySchemaNumberRange,
    QuerySchemaInputNumber
  },
  mixins: [CommonMixin, PropsyncMixin],
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    bRefer: {
      type: Boolean,
      default: false
    },
    showMore: {
      type: Boolean,
      default: false
    },
    objData: {
      type: Object,
      required: true,
      propsync: false
    },
    filters: {
      required: true,
      type: Array,
      propsync: false // 不会被propsync实现双向绑定
    },
    viewData: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      rowFilters: [],
      cellFilters: [],
      numberObj: {},
      test1: null,
      treeSelectOptions: {},
      cellSpan: this.bRefer ? 2 : 3,
      options: null,
      currentchildren: null,
      isFrame: window !== top,
      listShow: false,
      dSelectValue: '',
      dSelectTitle: ''
    };
  },
  computed: {
  },
  watch: {
    filters: {
      handler () {
        this.setFilters();
        // const { queryschema, shcemaFilters, view, all, flatAll } = this.parseMetaToData();
        // this.i_queryschema = queryschema;
        // this.shcemaFilters = shcemaFilters;
        // this.view = view;
        // this.all = all;
        // this.flatAll = flatAll;
        // this.emitLoaded();
      },
      deep: true
    },
    objData: {
      handler () {
        this.numberObj = deepCopy(this.viewData);
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.setFilters();
  },
  methods: {
    openValue () {
      this.listShow = !this.listShow;
    },
    getvalue (value, title) {
      this.dSelectValue = value;
      this.dSelectTitle = title;
      this.listShow = false;
    },
    dSelectChange (control) {
      this.p_viewData[control.name] = this.dSelectValue;
      this.dynamicChange(control, this.dSelectValue);
    },
    dSelectClear (control) {
      this.p_viewData[control.name] = '';
      this.dSelectValue = '';
      this.dSelectTitle = '';
      this.dynamicChange(control, this.dSelectValue);
    },
    isChildrenChecked (tag) {
      let hasChildrenChecked = tag['children'] && tag['children'].find((v) => {
        return v.checked === true;
      });
      return !!hasChildrenChecked;
    },
    span (control) {
      // const controlType = (control.controlType);
      let span = 24 / this.cellSpan;
      return span;
    },
    showCellFilter (control, index) {
      if (this.rowFilters.length) {
        return this.showMore;
      } else {
        if (index < this.cellSpan || this.bRefer) {
          return true;
        } else {
          return this.showMore;
        }
      }
    },
    setFilters () {
      this.rowFilters = this.filters.filter((v) => {
        return v.groupType === 1 && v.isCustomerShow !== false;
      });

      this.cellFilters = this.filters.filter((v) => {
        return v.groupType === 2 && v.isCustomerShow !== false;
      });
    },
    searchEnter () {
      this.$emit('on-enter-press');
    },
    treeSelectNormalizer (node, ctrlName) {
      if (node.name) {
        node.cName = node.name;
      }
      const ctrl = this.getCtrlByName(ctrlName);
      const valueKey = ctrl.valueKey || 'id';
      const nameKey = ctrl.nameKey || 'cName';
      return {
        id: node[valueKey],
        label: node[nameKey],
        children: node.children
      };
    },
    getCtrlByName (ctrlName) {
      return this.objData[ctrlName];
    },
    handleOnSelectOpen (control, visible) {},

    /* eslint space-before-function-paren: ["error", {"anonymous": "ignore", "named": "always", "asyncArrow": "always"}] */
    /* eslint-env es6 */
    async loadTreeSelectData ({ callback, instanceId, action: uaction, parentNode }) {
      const ctrl = this.getCtrlByName(instanceId);
      const { action, isEnum, enumString } = ctrl;
      if (isEnum && enumString) {
        let values = [];
        try {
          values = JSON.parse(enumString);
        } catch (error) {
          console.error('parse error'); //eslint-disable-line
        }
        this.treeSelectOptions[ctrl.name] = values;
      } else {
        await this.bizMeta.actions[action]().then((res) => {
          this.treeSelectOptions[ctrl.name] = res;
        });
      }
    },
    initTreeSelectOptions (control) {
      if (!this.treeSelectOptions[control.name]) {
        if (control.enumArray && control.enumArray.length) {
          this.$set(this.treeSelectOptions, control.name, control.enumArray);
        } else {
          this.$set(this.treeSelectOptions, control.name, null);
        }
      }
      return true;
    },
    setviewDataValue (control, value, key) {
      this.$emit('on-set-view-data-value', control, value, key);
    },
    dynamicChange (control, value) {
      value = _.isUndefined(value) ? null : value;
      this.$emit('on-filter-change', control, value);
    },
    tagChange (value, enumKey, control) {
      if (value === false) {
        return;
      }
      this.clearOtherChildrenCheckedStatus(control);
      if (control.originName) {
        control.name = control.originName;
      }
      this.currentchildren = '';

      control.enumArray.map((v) => {
        if (v.children && v.children.length > 0 && this.viewData[v.children[0].name]) {
          delete this.viewData[v.children[0].name];
        }
      });
      this.viewData[control.name] = enumKey;
      this.dynamicChange(control, enumKey);
      this.tagClick(control, enumKey);
    },
    tagChildrenChange (item, control, index, tag) {
      this.clearOtherChildrenCheckedStatus(control);
      item.checked = true;
      control.originName = control.originName ? control.originName : control.name;
      this.currentchildren = index;
      this.viewData[control.name] = '';
      this.viewData[item.name] = item.value;
      control.name = item.name;
      this.dynamicChange(control, item.value);
      this.tagClick(control, item.value);
      // this.p_viewData[control.name] = tag.name;
    },
    selectTagChange (value, control) {
      this.dynamicChange(control, value);
      // this.tagClick(control, value);
    },
    tagClick (control, enumKey) {
      this.$emit('on-tag-click', control, enumKey);
    },
    clearOtherChildrenCheckedStatus (control) {
      control.enumArray.map((v) => {
        if (v.children && v.children.length > 0) {
          v.children.map((k) => {
            k.checked = false;
          });
        }
      });
    },
    setDatePickerValue (value, type, control, key) {
      // if (type === 'date' && value[0].indexOf(':') !== -1) {
      //   value[1] = dayjs(value[1])
      //     .subtract(1, 'seconds')
      //     .add(1, 'days')
      //     .format('YYYY-MM-DD HH:mm:ss');
      // }
      this.setviewDataValue(control, value, key);
    },
    selectStyles (control) {
      const style = {};
      if (control.width) {
        style.width = control.width + 'px';
      }
      return style;
    },
    queryschemaRowStyle (controls, index) {
      const style = {};
      if (this.bRefer) {
        if (controls.length > 2) {
          if (controls.length - 1 === index || controls.length - 2) {
            if (controls.length % 2 === 0 && index >= controls.length - 2) {
              style.marginBottom = '15px';
            } else if (controls.length % 2 === 1 && index >= controls.length - 1) {
              style.marginBottom = 0;
            } else {
              style.marginBottom = '15px';
            }
          }
        }
      }
      return style;
    }
  }
};
</script>
<style lang="less" scoped>
.queryschema-control-enum-children-slot{
  cursor: pointer;
  text-align: center;
  margin:10px auto;
  width: 66px;
  height: 24px;
  line-height: 20px;
  &:hover{
    border:1px solid #ddd;
    border-radius: 4px;
  }
}
.activeChildren{
  border:1px solid #505766;
  border-radius: 4px;
  &:hover{
    border:1px solid #505766;
    border-radius: 4px;
  }
}
.multipleStyle{
  z-index: 33;
  /deep/.vue-treeselect__multi-value{
    height: 25.82px;
    overflow: hidden;
  }
}
.queryschema .queryschema-left .queryschema-row .queryschema-label {
  width: 40% !important;
}
.selectDiv{
  .divInput{
  display: inline-block;
  width: 220px;
  height: 28px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdee2;
  position: relative;
  ul li{
    list-style: none;
  }
  .input{
    width: 100%;
    height: 26px;
    line-height: 26px;
    padding-left: 8px;
    padding-right: 24px;
    cursor: pointer;

  }
  .input input{
    border: none;
    outline: none;
    width: 100%;
    height: 26px;
    color: #666;
    background-color: #fff;
  }
  i{
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 14px;
    color: #808695;
  }
  .list{
    position: absolute;
    z-index: 1000;
    width: 220px;
    overflow: auto;
    margin: 5px 0;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0,0,0,.2);
    max-height: 300px;
  }
  .list ul li{
    padding: 6px 10px;
    width: 100%;
    height: 28px;
    cursor: pointer;
    line-height: 18px;
  color: #333;
  font-size: 12px;
    white-space: nowrap;
    list-style: none;
    cursor: pointer;
  }
  .list ul li:hover{
    background: #f3f5f9;
  }
}
}

</style>
