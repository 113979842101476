export default {
  changeRuleStateContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000858","确定要改变这个规则吗?") /* "确定要改变这个规则吗?" */,
  delRuleSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000859","删除成功") /* "删除成功" */,
  operateSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500085A","操作成功") /* "操作成功" */,
  changeStateWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500085B","不能关闭全部规则") /* "不能关闭全部规则" */,
  delRuleContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500085C","确定删除该规则吗？") /* "确定删除该规则吗？" */,
  changeRuleSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500085D","规则修改成功") /* "规则修改成功" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500085E","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  batchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500085F","请选择至少一条状态为停用的规则") /* "请选择至少一条状态为停用的规则" */
};
