import corpParentView from '@/views/corp/home/home.vue';

const channelcenter = {
  path: 'channelcenter',
  title: { i18n: 'channelcenter' },
  name: 'channelcenter',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'channelship',
      title: { i18n: 'title.channel' },
      name: 'channelship',
      component: () => import('@/views/corp/channelcenter/components/channelManagementRelationship.vue')
    },
    {
      path: 'channelagents',
      title: { i18n: 'title.channelagent' },
      name: 'channelagents',
      meta: { module: 'channelagents' },
      component: () => import('@/views/corp/channelcenter/components/channelagent/channelagentList.vue')
    },
    {
      path: 'channelagent',
      title: { i18n: 'title.channelagent' },
      name: 'channelagent',
      meta: { module: 'channelagents', notCache: true },
      component: () => import('@/views/corp/channelcenter/components/channelagent/channelagent.vue')
    },
    {
      path: 'channelTransactions',
      title: { i18n: 'title.channel' },
      name: 'channelTransactions',
      meta: { module: 'channelTransactions' },
      component: () => import('@/views/corp/channelcenter/components/channelTransaction/channelTransactions.vue')
    },
    {
      path: 'channelTransaction',
      title: { i18n: 'title.channel' },
      name: 'channelTransaction',
      meta: { module: 'channelTransactions', notCache: true },
      component: () => import('@/views/corp/channelcenter/components/channelTransaction/channelTransaction.vue')
    }
    // {
    //   path: 'channeltransaction',
    //   title: { i18n: 'title.channel' },
    //   name: 'channeltransaction',
    //   component: () => import('@/views/corp/channelcenter/components/channelTransAction.vue')
    // }
  ]
};

export default channelcenter;
