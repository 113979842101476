export default {
  hasOver: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000120","已经到底啦") /* "已经到底啦" */,
  inventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000121","库存") /* "库存" */,
  packUpScreen: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000122","收起筛选") /* "收起筛选" */,
  screening: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000123","展开筛选") /* "展开筛选" */,
  classify: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000124","分类") /* "分类" */,
  saleBizs: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000125","商家") /* "商家" */,
  brand: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000126","品牌") /* "品牌" */,
  otherScreen: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000127","其他筛选") /* "其他筛选" */,
  selectedConditions: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000128","已选条件") /* "已选条件" */,
  selectedBizData: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000129","已选商家") /* "已选商家" */,
  unfold: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500012A","展开") /* "展开" */,
  packUp: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500012B","收起") /* "收起" */,
  hotRank: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500012C","热销排行") /* "热销排行" */,
  saleNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500012D","销量") /* "销量" */,
  hasInventory: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500012E","有库存") /* "有库存" */,
  promotionalProducts: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500012F","促销产品") /* "促销产品" */,
  RMBSymbol: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000130","￥") /* "￥" */,
  fullReduction: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000131","满减") /* "满减" */,
  buyGifts: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000132","买赠") /* "买赠" */,
  discount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000133","打折") /* "打折" */,
  addCart: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000134","加入购物车") /* "加入购物车" */,
  minimumQuatity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000135","起订量") /* "起订量" */,
  moreSku: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000136","多规格") /* "多规格" */,
  selectSku: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000137","选择规格") /* "选择规格" */,
  noProducts: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000138","未查询到商品记录!") /* "未查询到商品记录!" */,
  searching: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000139","查询中...") /* "查询中..." */,
  countTotal: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500013A","合计数量") /* "合计数量" */,
  priceTotal: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500013B","合计金额") /* "合计金额" */,
  errorBuyMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500013C","购买计价数量不能为空") /* "购买计价数量不能为空" */,
  addToCartTip1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500013D","计价数量不能小于起订量！") /* "计价数量不能小于起订量！" */,
  addToCartTip2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500013E","计价数量不能大于限购量！") /* "计价数量不能大于限购量！" */,
  addToCartTip3: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500013F","超过了商品最大限购量！") /* "超过了商品最大限购量！" */,
  addToCartTip4: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000140","计价数量不能小于商品起订量！") /* "计价数量不能小于商品起订量！" */,
  packageIsNull: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000141","套餐尚未配置可选配的商品，不能购买") /* "套餐尚未配置可选配的商品，不能购买" */,
  productDescription: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000142","规格说明") /* "规格说明" */,
  productModel: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000143","型号") /* "型号" */
};
