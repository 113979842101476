import pricePlates from './pricePlates';
import agnPriceShow from './agnPriceShow';
import productPriceShow from './productPriceShow';
import agentSalesShowProduct from './agentSalesShowProduct';
import agentSalesShow from './agentSalesShow';
import productPriceSettingShows from './productPriceSettingShows';
import productPriceSettingShow from './productPriceSettingShow';
import agentPriceSettingShowes from './agentPriceSettingShowes';
import agentPriceSettingShow from './agentPriceSettingShow';
import customerDiscProd from './customerDiscProd';
import customerDiscProds from './customerDiscProds';
import customerDiscProdCates from './customerDiscProdCates';
import customerDiscProdCate from './customerDiscProdCate';

export default {
  pricePlates,
  pricePlate: pricePlates,
  agnPriceShow,
  productPriceShow,
  agentSalesShowProduct,
  agentSalesShow,
  productPriceSettingShows,
  productPriceSettingShow,
  agentPriceSettingShowes,
  agentPriceSettingShow,
  customerDiscProd,
  customerDiscProds,
  customerDiscProdCates,
  customerDiscProdCate
};
