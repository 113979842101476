export default {
  // 手工拆单
  separateBill: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500063B","手工拆单") /* "手工拆单" */,
  rule: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500063C","拆单规则") /* "拆单规则" */,
  next: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500063D","下一步") /* "下一步" */,
  nextTipA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500063E","根据拆分订单重算促销（拆分后的订单商品可改）") /* "根据拆分订单重算促销（拆分后的订单商品可改）" */,
  nextTipB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500063F","使用原单促销（拆分后的订单商品不可改）") /* "使用原单促销（拆分后的订单商品不可改）" */,
  separateBillWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000640","拆单后原单不可恢复") /* "拆单后原单不可恢复" */,
  order: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000641","原单") /* "原单" */,
  newOrder1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000642","新订单1") /* "新订单1" */,
  newOrder2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000643","新订单2") /* "新订单2" */,
  cProductName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000644","商品信息") /* "商品信息" */,
  fSalePrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000645","单价") /* "单价" */,
  iQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000646","计价数量") /* "计价数量" */,
  iAuxUnitQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000647","销售数量") /* "销售数量" */,
  stock: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000648","库存") /* "库存" */,
  stockTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000649","库存不足") /* "库存不足" */,
  case: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500064A","套") /* "套" */,
  count: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500064B","共") /* "共" */,
  warningA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500064C","请填写第") /* "请填写第" */,
  warningB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500064D","行新订单1计价数量或新订单2计价数量") /* "行新订单1计价数量或新订单2计价数量" */,
  warningC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500064E","第") /* "第" */,
  warningD: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500064F","行新订单1计价数量加新订单2计价数量相加不等于原单计价数量") /* "行新订单1计价数量加新订单2计价数量相加不等于原单计价数量" */,
  warningE: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000650","订单1商品计价数量不能全为0") /* "订单1商品计价数量不能全为0" */,
  warningF: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000651","订单2商品计价数量不能全为0") /* "订单2商品计价数量不能全为0" */,
  separateBillSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000652","拆单成功") /* "拆单成功" */,
  // 费用
  selectRebatesData: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000653","使用费用单抵扣") /* "使用费用单抵扣" */,
  selectRebatesDataTipA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000654","本次最多可用折扣费用") /* "本次最多可用折扣费用" */,
  selectRebatesDataTipB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000655","元,最多可用抵现费用") /* "元,最多可用抵现费用" */,
  selectRebatesDataTipC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000656","订单金额未达到") /* "订单金额未达到" */,
  selectRebatesDataTipD: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000657","元，不可使用费用") /* "元，不可使用费用" */,
  updata: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000658","修改") /* "修改" */,
  TabPaneA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000659","现金/折扣类费用") /* "现金/折扣类费用" */,
  TabPaneB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500065A","商品类费用") /* "商品类费用" */,
  errorA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500065B","获取客户信息失败!") /* "获取客户信息失败!" */,
  cRebateNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500065C","费用单号") /* "费用单号" */,
  fRebateMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500065D","费用金额(元)") /* "费用金额(元)" */,
  cUseWayName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500065E","应用方式") /* "应用方式" */,
  dValidEndDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500065F","有效期") /* "有效期" */,
  fSurplusMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000660","可用余额(元)") /* "可用余额(元)" */,
  fOrderRebateMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000661","本次使用金额(元)") /* "本次使用金额(元)" */,
  cName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000662","商品") /* "商品" */,
  cSpecDescription: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000663","属性") /* "属性" */,
  iSurplusQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000664","可用费用计价数量") /* "可用费用计价数量" */,
  oUnitcName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000665","费用单位") /* "费用单位" */,
  errorB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000666","费用金额已超出实付金额，请重新填写!") /* "费用金额已超出实付金额，请重新填写!" */,
  errorC: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000667","分摊费用金额已超过订单最大可分摊费用金额，请重新填写!") /* "分摊费用金额已超过订单最大可分摊费用金额，请重新填写!" */,
  errorD: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000668","费用金额已超出可用费用金额") /* "费用金额已超出可用费用金额" */,
  errorE: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000669","元，请重新填写!") /* "元，请重新填写!" */,
  errorF: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500066A","费用金额大于订单金额，请重新选择!") /* "费用金额大于订单金额，请重新选择!" */,
  noUse: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500066B","不使用") /* "不使用" */
};
