export default {
  limitProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000629","限购商品") /* "限购商品" */,
  limitRange: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500062A","限购周期") /* "限购周期" */,
  zhidanren: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500062B","制单人") /* "制单人" */,
  limitDimension: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500062C","限购维度") /* "限购维度" */,
  limitLevel: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500062D","限购层级") /* "限购层级" */,
  everyAgent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500062E","每客户") /* "每客户" */,
  everyOrderItem: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500062F","每单") /* "每单" */,
  zongLiang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000630","总量") /* "总量" */,
  addLimitGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000631","新增限购组") /* "新增限购组" */,
  limitGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000632","限购组") /* "限购组" */,
  allAgent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000633","所有客户") /* "所有客户" */,
  productSkuProperty: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000634","商品Sku属性") /* "商品Sku属性" */,
  productSkuCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000635","商品Sku编码") /* "商品Sku编码" */,
  limitCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000636","计价数量") /* "计价数量" */,
  auxLimitCount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000637","销售数量") /* "销售数量" */,
  leastAddGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000638","至少添加一个组!") /* "至少添加一个组!" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000639","请正确填写!") /* "请正确填写!" */,
  bumen: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500063A","部门") /* "部门" */
};
