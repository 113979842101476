import OrderNo from './order-no';
import OrderMemo from './order-memo';
import OrderStatus from './order-status';
import PaymentStatus from './payment-status';
import HandleRecord from './handle-record';
import OrderAction from './order-action';
import ReturnStatus from './return-status';
import ReturnAction from './return-action';
import PaymentAction from './payment-action';
import DeliveryAction from './delivery-action';
import OrderU8 from './order-u8';
export default { DeliveryAction, OrderNo, OrderStatus, PaymentStatus, HandleRecord, OrderMemo, OrderAction, ReturnAction, ReturnStatus, PaymentAction, OrderU8 };
