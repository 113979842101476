const parseAddress = (value) => {
  if (value === undefined || value === null || value === '') {
    return '';
  }
  if (typeof value === 'string') {
    return value;
  }
  let address = '';
  let addressName = ['country', 'province', 'city', 'area'];
  for (let i = 0, len = addressName.length; i < len; i++) {
    if (!value[addressName[i]]) break;
    address += `${value[addressName[i]]} `;
  }
  return address;
};

const getReadOnlyText = function(value, control, data, getAllDataFn) {
  let params = {
    column: control,
    data,
    value,
    getAllDataFn
  };
  switch (control.controlType) {
    case 'Number':
    case 'InputMeter':
      value = this.$util.getNumberFormatDataValue(params);
      break;
    case 'Checkbox':
      value = value ? this.mlang('yes') : this.mlang('no');
      break;
    case 'Upload':
      if (!value || !value.length) {
        value = this.mlang('noAttachments');
      } else {
        value = '';
      }
      break;
    case 'Date':
      if (value) {
        value = this.$util.formatToCtxDate(value);
      }
      break;
    case 'DateTime':
      if (value) {
        value = this.$util.formatToCtxDateTime(value);
      }
      break;
    case 'DateRange':
      if (Array.isArray(value)) {
        value = value.join(' - ').split(' - ');
        value.forEach((valItem, index) => {
          value[index] = this.$util.formatToCtxDateTime(valItem);
        });
        value = value.join(' - ');
      }
      break;
    case 'DateTimeRange':
      if (Array.isArray(value)) {
        value = value.join(' - ').split(' - ');
        value.forEach((valItem, index) => {
          value[index] = this.$util.formatToCtxDateTime(valItem);
        });
        value = value.join(' - ');
      }
      break;
    case 'Radio':
      if (control.isEnum && control.enumArray) {
        let values = '';
        let currValue = data[control.fieldName];
        values = control.enumArray.find((v) => {
          // eslint-disable-next-line eqeqeq
          return v.value == currValue;
        }, '');
        value = values && values.title;
      }
      break;
    case 'CitySelect':
      value = parseAddress(value);
      break;
    case 'MuliTextarea':
      value = '';
      break;
    case 'Select':
      if (control.enumArray) {
        let values = '';
        values = control.enumArray.find((v) => {
          return v.value === value;
        }, '');
        value = values && values.title;
      }
      break;
    default:
      break;
  }
  return value;
};

export {
  getReadOnlyText
};
