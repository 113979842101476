import CorpMain from '@/views/corp/Main.vue';
import SingleMain from '@/views/singlepage/Main.vue';
import AgentMain from '@/views/agent/Main.vue';
import { corpRoute } from './corp';
import { agentRoute } from './agent';
if (agentRoute && agentRoute.length) {
  let stack = [...agentRoute];
  while (stack.length) {
    let route = stack.pop();
    if (route.children) {
      stack.push(...route.children);
    } else {
      route.meta = Object.assign(route.meta || {}, { isAgentSide: true });
    }
  }
}
// 作为Main组件的子页面展示在左侧菜单显示的路由写在otherRouter里
let sysRouter = [
  {
    path: '/corp',
    name: 'corp',
    type: 'corp',
    redirect: '/corp/home/index',
    component: CorpMain,
    children: [
      ...corpRoute,
      {
        path: 'message',
        title: {
          i18n: 'message'
        },
        name: 'message',
        component: () => import('@/components/message/message.vue')
      },
      {
        path: 'orderdetail',
        title: 'orderdetail',
        name: 'orderdetail',
        component: () => import('@/views/corp/order/components/order-detail.vue')
      }
      // { path: 'ownspace', title: '个人中心', name: 'ownspace_index', component: () => import('@/views/own-space/own-space.vue') },
      // { path: 'order/:order_id', title: '订单详情', name: 'order-info', component: () => import('@/views/advanced-router/component/order-info.vue') }, // 用于展示动态路由
      // { path: 'shopping', title: '购物详情', name: 'shopping', component: () => import('@/views/advanced-router/component/shopping-info.vue') }, // 用于展示带参路由
      // { path: 'message', title: '消息中心', name: 'message_index', component: () => import('@/views/message/message.vue') }
    ]
  },
  {
    path: '/agent',
    name: 'agent',
    type: 'agent',
    redirect: '/agent',
    component: AgentMain,
    children: [
      ...agentRoute,
      {
        path: 'aUserBase',
        name: 'aUserBase',
        component: () => import('@/views/agent/selfInfo/components/userBase/aUserBase.vue')
      }
    ]
  },
  {
    path: '/singlepage',
    name: 'singlepage',
    type: 'singlepage',
    redirect: '/corp/home/index',
    component: SingleMain,
    children: [...corpRoute, ...agentRoute]
  }
];
// singlepage
if (window.location.href.indexOf('/singlepage') > -1) {
  sysRouter = sysRouter.filter((v) => {
    return v.type === 'singlepage';
  });
}
if (window.location.href.indexOf('/agent/') > -1) {
  sysRouter = sysRouter.filter((v) => {
    return v.type === 'agent';
  });
}
if (agentRoute && agentRoute.length) {
  let stack = [...agentRoute];
  while (stack.length) {
    let route = stack.pop();
    if (route.children) {
      stack.push(...route.children);
    } else {
      route.meta = Object.assign(route.meta || {}, { isAgentSide: true });
    }
  }
}
export const appRouter = sysRouter;
// 作为Main组件的子页面展示但是不在左侧菜单显示的路由写在otherRouter里
export const otherRouter = {
  path: '/o',
  name: 'otherRouter',
  redirect: '/home',
  children: [
    // { path: 'home2', title: { i18n: 'home' }, name: 'home_index', component: () => import('@/views/home/home.vue') },
    // { path: 'ownspace', title: '个人中心', name: 'ownspace_index', component: () => import('@/views/own-space/own-space.vue') },
    // { path: 'order/:order_id', title: '订单详情', name: 'order-info', component: () => import('@/views/advanced-router/component/order-info.vue') } // 用于展示动态路由
    // { path: 'shopping', title: '购物详情', name: 'shopping', component: () => import('@/views/advanced-router/component/shopping-info.vue') }, // 用于展示带参路由
    // { path: 'message', title: '消息中心', name: 'message_index', component: () => import('@/views/message/message.vue') }
  ]
};

// 不作为Main组件的子页面展示的页面单独写，如下
export const loginRouter = {
  path: '/login',
  name: 'login',
  meta: {
    title: 'Login - 登录'
  },
  component: () => import('@/views/login/login.vue')
};

export const registerRouter = {
  path: '/register',
  name: 'register',
  meta: {
    title: 'register - 注册'
  },
  component: () => import('@/views/login/register.vue')
};
export const joinRouter = {
  path: '/join',
  name: 'join',
  meta: {
    title: 'join - 申请加盟'
  },
  component: () => import('@/views/login/join.vue')
};

export const custRegisterRouter = {
  path: '/cusRegister',
  name: 'cusRegister',
  meta: {
    title: 'cusRegister - 客户注册'
  },
  component: () => import('@/views/login/cusRegister.vue')
};

export const CrmRobotAuthRouter = {
  path: '/crmAuthPage',
  name: 'crmAuthPage',
  meta: {
    title: 'yycrm - crm-robot-login'
  },
  component: () => import('@/views/login/crmAuthPage.vue')
};

export const forgetRouter = {
  path: '/forget',
  name: 'forget',
  meta: {
    title: 'forget - 忘记密码'
  },
  component: () => import('@/views/login/forget.vue')
};
// 申请加盟
export const applyToJoinRouter = {
  path: '/applyToJoin',
  name: 'applyToJoin',
  meta: {
    title: 'applyToJoin - 申请加盟'
  },
  component: () => import('@/views/login/applyToJoin.vue')
};

export const page404 = {
  path: '/404',
  name: '404',
  meta: {
    title: '404-页面不存在'
  },
  component: () => import('@/views/error-page/404.vue')
};

export const page403 = {
  path: '/403',
  meta: {
    title: '403-权限不足'
  },
  name: 'error-403',
  component: () => import('@//views/error-page/403.vue')
};

export const page500 = {
  path: '/500',
  meta: {
    title: '500-服务端错误'
  },
  name: 'error-500',
  component: () => import('@/views/error-page/500.vue')
};

export const preview = {
  path: '/preview',
  name: 'preview'
  // component: () => import('@/views/form/article-publish/preview.vue')
};

export const locking = {
  path: '/locking',
  name: 'locking'
  // component: () => import('@/views/main-components/lockscreen/components/locking-page.vue')
};
export const test = {
  path: '/test',
  title: 'test',
  name: 'test',
  component: () => import('@/views/corp/order/components/test.vue')
};

export const billItemConfig = {
  path: '/billItemConfig',
  name: 'billItemConfig',
  meta: { module: 'billItemConfig' },
  component: () => import('@/views/corp/billItemConfig/components/billItemConfig.vue')
};
// 所有上面定义的路由都要写在下面的routers里
export const routers = [test, loginRouter, registerRouter, joinRouter, custRegisterRouter, CrmRobotAuthRouter, forgetRouter, applyToJoinRouter, otherRouter, ...appRouter, preview, locking, page500, page403, page404, billItemConfig];
