import corpParentView from '@/views/corp/home/home.vue';

const organization = {
  path: 'organization',
  title: { i18n: 'organization' },
  name: 'organization',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'orgs',
      title: { i18n: 'title.orgs' },
      name: 'orgs',
      meta: { notCache: true },
      component: () => import('@/views/corp/organization/components/orgs.vue')
    },
    {
      path: 'orgAreaSetting',
      title: { i18n: 'title.orgAreaSetting' },
      meta: { notCache: true },
      name: 'orgAreaSetting',
      component: () => import('@/views/corp/organization/components/orgAreaSetting.vue')
    },
    {
      path: 'departments',
      title: { i18n: 'title.departments' },
      meta: { notCache: true },
      name: 'departments',
      component: () => import('@/views/corp/organization/components/departments.vue')
    },
    // {
    //   path: 'salesrelationship',
    //   title: { i18n: 'title.salesrelationship' },
    //   name: 'salesrelationship',
    //   component: () => import('@/views/corp/organization/components/salesrelationship.vue')
    // },
    {
      path: 'salesRelationships',
      title: { i18n: 'title.salesrelationship' },
      name: 'salesRelationships',
      meta: { module: 'salesRelationships' },
      component: () => import('@/views/corp/organization/components/salesRelationship/salesRelationships.vue')
    },
    {
      path: 'salesRelationship',
      title: { i18n: 'title.salesrelationship' },
      name: 'salesRelationship',
      meta: { module: 'salesRelationships', notCache: true },
      component: () => import('@/views/corp/organization/components/salesRelationship/salesRelationship.vue')
    }
  ]
};

export default organization;
