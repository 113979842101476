import productNoticesPage from '@/views/corp/decorate/decorates.vue';

const decorates = [
  {
    path: 'decorates',
    name: 'decorates',
    component: productNoticesPage
  }
];
export default decorates;
