<template>
  <div
    id="app"
    class="app-main">
    <router-view v-if="!isDSJ"></router-view>
    <a
      v-if="!isDSJ"
      id="new_window_a"
      style="display:none"
      target="_blank"></a>
    <u-frame v-if="isDSJ" ref="frame" :src="url" @frame-loaded="loaded"></u-frame>
  </div>
</template>

<script>
import UFrame from '@/components/iFrame/frame';
import { GenerateOrderByBidding } from '@/api/product';
import util from '@/libs/util';

export default {
  components: {
    UFrame
  },
  data() {
    return {
      theme: this.$store.state.app.themeColor,
      url: localStorage.getItem('dataCenterUrl'),
      isDSJ: false
    };
  },
  watch: {
    isDSJ(val) {
      if (val) {
        document.getElementsByTagName('body')[0].style['overflowX'] = 'hidden';
      } else {
        document.getElementsByTagName('body')[0].style['overflowX'] = 'auto';
      }
    }
  },
  async created() {
    if (window.top === window.self && this.url) {
      this.isDSJ = true;
    }
  },
  mounted() {
    window.addEventListener(
      'message',
      (messageEvent) => {
        this.messageDeal(messageEvent, messageEvent.data);
      },
      false
    );
    if (window.location.href.indexOf('isRepresent=true') > -1) {
      localStorage.removeItem('defaultUserInfo');
      window.localStorage.removeItem('initOrderHeaders');
      localStorage.removeItem('curAccount');
      localStorage.upAgentId = util.getUrlParam('agentId');
      localStorage.upAgentRelationId = util.getUrlParam('upAgentRelationId');
      localStorage.setItem('isRepresentToOrder', true);
      if (this.$util.getUrlParam('represent')) {
        localStorage.setItem('isRepresentCanOrder', true);
      }
    }
  },
  beforeDestroy() {},
  methods: {
    messageDeal(messageEvent, data) {
      if (this.isJSON(data)) {
        data = JSON.parse(data);
      }
      let condition = (Array.isArray(data) && data.length && data[0].billType === 1 && data[0].redirectTo === 'aorder') || (typeof data === 'object' && data.billType === 1 && data.redirectTo === 'aorder');

      if (condition) {
        let postData = {};
        if (Array.isArray(data)) {
          let cur = this.$store.getters.currentUser.relations && this.$store.getters.currentUser.relations.find((v) => {
            return v.orgId === String(data[0].salesOrgId);
          });
          postData = {
            srcbillType: data[0].billType,
            srcBillNO: data[0].billNo,
            srcBillId: data[0].billId,
            bizId: cur.bizId,
            salesOrgId: data[0].salesOrgId,
            oOrderDetails: []
          };
          data.map((v) => {
            let { salesOrgId, iQuantity, iProductId, sourceautoid, iSKUId, billType, billNo, billId, unitPrice, firstlineno, uplineno, inquiry } = v;
            postData.oOrderDetails.push({
              source: 'yonbip-hy-smk-ausa.ausa_auction_bill_card',
              firstsource: 'yonbip-hy-smk-ausa.ausa_auction_bill_card',
              sourceid: billId,
              sourceautoid,
              bizId: cur.bizId,
              salesOrgId,
              iQuantity,
              iProductId,
              iSKUId,
              firstsourceid: billId,
              firstupcode: billNo, // 值为 来源竞拍单单据号
              firstlineno, // 值为 来源竞拍单单据行号
              upcode: billNo, // 值为 来源竞拍单单据号
              uplineno, // 值为 来源竞拍单单据行号
              fSalePrice: unitPrice || '',
              inquiry: Number(inquiry || '')
            });
          });
        } else {
          let { salesOrgId, iQuantity, iProductId, sourceautoid, iSKUId, billType, billNo, billId, unitPrice, firstlineno, uplineno, inquiry } = data;
          let cur = this.$store.getters.currentUser.relations && this.$store.getters.currentUser.relations.find((v) => {
            return v.orgId === String(data.salesOrgId);
          });
          postData = {
            srcbillType: billType,
            srcBillNO: billNo,
            srcBillId: billId,
            bizId: cur.bizId,
            salesOrgId,
            oOrderDetails: [{
              source: 'yonbip-hy-smk-ausa.ausa_auction_bill_card',
              firstsource: 'yonbip-hy-smk-ausa.ausa_auction_bill_card',
              sourceid: billId,
              sourceautoid,
              bizId: cur.bizId,
              salesOrgId,
              iQuantity,
              iProductId,
              iSKUId,
              firstsourceid: billId,
              firstupcode: billNo, // 值为 来源竞拍单单据号
              firstlineno, // 值为 来源竞拍单单据行号
              upcode: billNo, // 值为 来源竞拍单单据号
              uplineno, // 值为 来源竞拍单单据行号
              fSalePrice: unitPrice || '',
              inquiry: Number(inquiry || '')
            }]
          };
        }
        GenerateOrderByBidding(postData).then(() => {
          const { href } = this.$router.resolve({
            name: 'aorder',
            query: {
              source: 'jingpai'
            }
          });
          window.open(href, 'blank');
        });
      }

      if (typeof data === 'object' && data.billType === 1 && data.redirectTo === 'Orders') {
        const { href } = this.$router.resolve({
          name: 'Orders',
          query: {
            source: 'jingpai',
            srcbillType: data.billType,
            srcBillNO: data.billNo
          }
        });
        window.open(href, 'blank');
      }

      // 多数据中心退出登录
      if (this.url && this.url.indexOf(messageEvent.origin) > -1 && messageEvent.data.key === 'logout') {
        if (window.localStorage.getItem('dataCenterUrl')) window.localStorage.removeItem('dataCenterUrl');
        window.top.location.href = '/';
      }
      // 切换租户
      if (this.url && messageEvent.data.key === 'udhTitleModify' && messageEvent.data.tenantName) {
        window.document.title = messageEvent.data.tenantName;
      }

      if (this.url && messageEvent.data.logined) {
        let url = messageEvent.origin + '/v#/agent/home';
        this.url = url;
        window.localStorage.setItem('dataCenterUrl', url);
      }
      if (data.to === 'productDetail' || data.to === 'aorder' || data.to === 'frame') {
        let paramsStr = this.serialize(data);
        let href = window.location.origin + '/v#/agent/home';
        let url = href.indexOf('?') > -1 ? `${href}&${paramsStr}` : `${href}?${paramsStr}`;
        this.$open(url);
      }

      if (messageEvent.data.href) {
        if (window.top !== window.self) {
          window.localStorage.setItem('isDataCenter', messageEvent.origin);
          let topUrl = messageEvent.data.href;
          if (messageEvent.data.openNew) {
            window.parent.postMessage({ href: topUrl, openNew: true }, '*');
          } else if (this.$util.getUrlParam('to', topUrl)) {
            if (window.localStorage.getItem('toUrlPage') === 'true') {
              let query = {};
              if (this.$util.getUrlParam('id', topUrl) && this.$util.getUrlParam('bizProductId', topUrl)) {
                query = {
                  id: this.$util.getUrlParam('id', topUrl),
                  bizProductId: this.$util.getUrlParam('bizProductId', topUrl)
                };
                this.$router.push({
                  name: this.$util.getUrlParam('to', topUrl),
                  query
                });
              } else if (this.$util.getUrlParam('cOrderNo', topUrl)) {
                query = {
                  cOrderNo: this.$util.getUrlParam('cOrderNo', topUrl),
                  id: this.$util.getUrlParam('id', topUrl),
                  source: 'copyOrder',
                  titleType: 'add'
                };
                this.$router.push({
                  name: this.$util.getUrlParam('to', topUrl),
                  query
                });
              } else if (this.$util.getUrlParam('uri', topUrl)) {
                this.$router.push({
                  path: '/agent/frame',
                  query: {
                    uri: this.$util.getUrlParam('uri', topUrl)
                  }
                });
              } else {
                this.$router.push({
                  name: 'Pays'
                });
              }
            } else if (!window.location.href.includes('pays')) {
              this.$router.push({
                name: 'home'
              });
            }
          }
        } else {
          if (messageEvent.data.openNew) {
            window.open(messageEvent.data.href);
          }
        }
      }
    },
    isJSON(str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str);
          return !!(typeof obj === 'object' && obj);
        } catch (e) {
          return false;
        }
      }
    },
    loaded() {
      if (window.top === window.self) {
        var frame = this.$refs['frame'].$refs['iframe'];
        frame.contentWindow.postMessage({
          href: window.location.href
        }, this.url);
      }
    },
    serialize(obj) {
      var ary = [];
      for (var p in obj) {
        if (obj.hasOwnProperty(p) && obj[p]) {
          ary.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
      return ary.join('&');
    }
  }
};
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  /* background: #f0f0f0;
  overflow: hidden; */
}
.app-main {
  width: 100%;
  height: 100%;
}
</style>
