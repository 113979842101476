import { deepCopy } from '@/u-components/utils/assist';
import { getProductClassTree, getProductClassTreeYS, productClassSelect } from '@/api/refer-tree';
const productRefer = (ctrl, vm) => {
  let user = vm.$store.getters.currentUser;
  const columns = [
    {
      showCaption: vm.mlang('product'),
      isShowIt: true,
      fieldName: 'name',
      width: vm.$getBO('SHOWPRODUCTFULLNAME') ? 330 : 220
    }
  ];
  //商品编码
  if(vm.$getBO('QUICKORDERISSHOWPRODUCTCODEPC')){
    columns.unshift({
      showCaption: vm.mlang('productCode'),
      isShowIt: true,
      fieldName: 'cCode',
      width: 95
    });
  }
  if (!user.__isBiz && user.__showOrg && !ctrl.hideOrg) {
    columns[0].width = 150;
    columns.push({
      showCaption: vm.mlang('org'),
      isShowIt: true,
      fieldName: 'orgName',
      width: 95
    });
  }
  if (user.__isMutiBiz && !ctrl.hideBiz) {
    columns[0].width = 150;
    columns.push({
      showCaption: vm.mlang('bizName'),
      isShowIt: true,
      fieldName: 'bizName',
      width: 95
    });
  }
  columns.push({
    showCaption: vm.mlang('jiliangdanwei'),
    isShowIt: true,
    fieldName: 'oUnit.cName',
    width: 70
  });
  
  //商品规格说明
  if(vm.$getBO('PRODUCTSPECIFICATION')){
    columns.push({
      showCaption: vm.mlang('productDescription'),
      isShowIt: true,
      fieldName: 'modelDescription',
      width: 95
    });
  }
  
  //商品型号
  if(vm.$getBO('PACKAGESPECIFICATIONS')){
    columns.push({
      showCaption: vm.mlang('productModel'),
      isShowIt: true,
      fieldName: 'model',
      width: 95
    });
  }
  
  const control = Object.assign(
    {},
    {
      key: 'productRef', // 只有为这个才会显示特殊商品参照
      noMetaSetting: true,
      referType: 'TreeTable',
      pagerSizeOpts: [10, 20],
      showTitle: true,
      closeable: true,
      page: true,
      bRefer: true,
      controlType: 'TreeRefer',
      refReturnValue: 'id',
      refName: 'productName',
      queryschema: [
        {
          name: 'value',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('productNameOrCode'),
          conditionType: 'like'
        }
      ],
      columns,
      actions: { queryTree: vm.$store.getters.currentUser.__isU8C ? (ctrl.isAllowShowAllClasses ? productClassSelect : getProductClassTreeYS) : getProductClassTree }
    },
    ctrl
  );
  control.key = 'productRef';
  const exisStatus = control.queryschema.find((v) => v.name === 'status');
  if (!control.hideStatus && !exisStatus) {
    const status = {
      name: 'status',
      controlType: 'Select',
      groupType: 2,
      valueType: 'LONG',
      isShowIt: true,
      placehoder: vm.mlang('status'),
      isEnum: true,
      width: 220,
      enumString: `[{"title":"${vm.mlang('shelves')}","value":"1","name":"1"},{"title":"${vm.mlang('notShelves')}","value":"0","name":"0"}]`,
      conditionType: 'eq'
    };
    control.queryschema.push(status);
  }
  return deepCopy(control);
};
export default productRefer;
