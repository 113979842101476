const order = {
  state: {
    orderDetailGroups: []
  },
  getters: {
    GET_ORDERDETAILGROUPS: (state, getters, rootState) => state.orderDetailGroups
  },
  mutations: {
    SET_ORDERDETAILGROUPS: (state, data) => {
      state.orderDetailGroups = data;
    }
  },
  actions: {
  }
};

export default order;
