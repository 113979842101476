import { ncSaleOrg } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const ncSaleOrgRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: true,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'saleorgName',
      queryschema: [
        {
          name: 'value',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('saleorgName'),
          conditionType: 'like'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('saleorgName'),
          isShowIt: true,
          fieldName: 'saleorgName',
          minWidth: 120
        },
        {
          showCaption: vm.mlang('groupCode'),
          isShowIt: true,
          minWidth: 180,
          fieldName: 'groupCode'
        },
        {
          showCaption: vm.mlang('saleOrgCode'),
          isShowIt: true,
          minWidth: 180,
          fieldName: 'saleorgCode'
        }
      ],
      actions: { queryList: ncSaleOrg },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default ncSaleOrgRefer;
