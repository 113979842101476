export default {
  hello: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500014A","您好，") /* "您好，" */,
  serviceCenter: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500014B","客服中心") /* "客服中心" */,
  hasOver: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500014C","已经到底啦") /* "已经到底啦" */,
  saleNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500014D","销量") /* "销量" */,
  orderGoods: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500014E","订货") /* "订货" */,
  returnGoods: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500014F","退货") /* "退货" */,
  notice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000150","公告") /* "公告" */,
  noNotice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000151","暂无公告") /* "暂无公告" */,
  subtract: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000152","减") /* "减" */,
  discount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000153","折") /* "折" */,
  gifts: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000154","赠") /* "赠" */,
  setToReaded: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800035","标记为已读") /* "标记为已读" */,
  hasReaded: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000155","已读") /* "已读" */,
  bencibuzaitishi: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000156","本次不再提示") /* "本次不再提示" */
};
