import { render, staticRenderFns } from "./srcBillOrder-ref-table-new.vue?vue&type=template&id=27ffc144&scoped=true"
import script from "./srcBillOrder-ref-table-new.vue?vue&type=script&lang=js"
export * from "./srcBillOrder-ref-table-new.vue?vue&type=script&lang=js"
import style0 from "./srcBillOrder-ref-table-new.vue?vue&type=style&index=0&id=27ffc144&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27ffc144",
  null
  
)

export default component.exports