<template>
  <div>
    <div
      v-if="params.row.oAgent.isEnableAgent && !currentUser.__isAgentSide"
      class="action">
      <Row
        class-name="text"
        type="flex"
        justify="center">
        <span
          v-if="params.row.isShowConfirmBtn && orderConfirm"
          class="btn-action"
          @click="sure(params.row)">
          {{ mlang('sure') }}
        </span>
        <span
          v-if="params.row.isShowCreateDeliveryBtn && createDeliveryOrder"
          class="btn-action"
          @click="delivery(params.row)">
          {{ mlang('delivery') }}
        </span>
        <span
          v-if="params.row.isShowPayBtn && orderPay"
          class="btn-action"
          @click="pay(params.row)">
          {{ mlang('receivePay') }}
        </span>
        <span
          v-if="params.row.isShowOpposeBtn && orderReject"
          class="btn-action"
          @click="oppose(params.row)">
          {{ mlang('oppose') }}
        </span>
        <span
          v-if="params.row.isShowOrderRollBack && orderReturn"
          class="btn-action"
          @click="returnOrder(params.row)">
          {{ mlang('return') }}
        </span>
        <span
          v-if="params.row.isShowClosedDetail && closedOrderDetail"
          class="btn-action"
          @click="close(params.row)">
          {{ mlang('close') }}
        </span>
        <span
          v-if="params.row.isShowOpenDetail && openOrderDetail"
          class="btn-action"
          @click="openOrder(params.row)">
          {{ mlang('open') }}
        </span>
        <span
          v-if="params.row.isShowOrderUnlockBtn && orderUnlock"
          class="btn-action"
          @click="deblock(params.row)">
          {{ mlang('deblock') }}
        </span>
      </Row>
    </div>
    <div v-if="params.row.oAgent.isEnableAgent && currentUser.__isAgentSide" class="action">
      <Row
        v-show="isShowBtns"
        class-name="text"
        type="flex"
        justify="center">
        <span
          v-if="(params.row.isShowModifyBtn && agentOrderModify) || (params.row.isShowModifyBtn && orderModify && currentUser.__isRepresent) "
          class="btn-action"
          @click="modifyNew(params.row)">
          {{ mlang('edit') }}
        </span>

        <!-- 付款 -->
        <span
          v-if="params.row.isShowPayBtn && agentOrderPay && !currentUser.__isRepresent"
          class="btn-action"
          @click="agentPay(params.row)">
          {{ mlang('pay') }}
        </span>
        <!-- 退货 -->
        <span
          v-if="params.row.isShowSaleReturnBtn && agentOrderReturns && $getBO('ALLOWRETURNAPPLY') !== 'DISABLE'"
          class="btn-action"
          @click="saleReturn(params.row)">
          {{ mlang('saleReturn') }}
        </span>
        <!-- 部分退货 -->
        <span
          v-if="params.row.showSaleReturnPartBtn && agentOrderReturns && $getBO('ALLOWRETURNAPPLY') !== 'DISABLE'"
          class="btn-action"
          @click="saleReturn(params.row, false)">
          {{ mlang('saleReturnPart') }}
        </span>
        <!-- 收货确认因订单详情去掉了签收功能，所以收货确认和签收按钮隐藏 ，目前没评价功能-->
        <span
          v-if="params.row.isShowTakeDeliveryBtn && !$getBO('RECEIVINGNOTE') && orderConfirmDelivery "
          class="btn-action"
          @click="signIn(params.row)">
          {{ mlang('receiveAndConfirm') }}
        </span>
        <!-- 签收 -->
        <span
          v-if=" params.row.isShowReceiveBtn && $getBO('RECEIVINGNOTE') && saveReceivingNote && currentUser.__isRepresent"
          class="btn-action"
          @click="signIn(params.row)">
          {{ mlang('signIn') }}
        </span>
        <span
          v-if=" params.row.isShowReceiveBtn && $getBO('RECEIVINGNOTE') && !currentUser.__isRepresent"
          class="btn-action"
          @click="signIn(params.row)">
          {{ mlang('signIn') }}
        </span>

        <!-- 评价 -->
        <!-- <span
          v-if="params.row.isShowCommentBtn"
          @click="comment(params.row)">
          {{ mlang('comment') }}
        </span> -->
        <!-- 再次购买 -->
        <span
          v-if="params.row.isCopyOrder && agentBuyProduct"
          class="btn-action"
          @click="buyAgain(params.row)">
          {{ mlang('buyAgain') }}
        </span>

        <span
          v-if="params.row.isShowConfirmBtn && orderConfirm && params.row.oAgent.isEnableAgent && currentUser.__isRepresent"
          class="btn-action"
          @click="agentSure(params.row)">
          {{ mlang('sure') }}
        </span>
        <span
          v-if="params.row.isShowOpposeBtn && orderReject && params.row.oAgent.isEnableAgent && currentUser.__isRepresent"
          class="btn-action"
          @click="oppose(params.row)">
          {{ mlang('oppose') }}
        </span>
        <!-- 提交 -->
        <span
          v-if="params.row.isShowAgentUserSubmitBtn && agentOrderSubmit"
          class="btn-action"
          @click="submit(params.row)">
          {{ mlang('submit') }}
        </span>
        <!-- 发货申请 -->
        <span
          v-if="params.row.allowToDelivery && !currentUser.__isRepresent"
          class="btn-action"
          @click="deliveryApply(params.row)">
          {{ mlang('deliveryApply') }}
        </span>
        <!-- 删除 -->
        <span
          v-if="params.row.isShowDelBtn && agentOrderDel"
          class="btn-action"
          @click="delOrder(params.row)">
          {{ mlang('delete') }}
        </span>
        <!-- 电子发票 -->
        <span
          v-if="params.row.downloadEInvoiceBtn"
          class="btn-action"
          @click="downloadEInvoiceBtn(params.row)">
          {{ mlang('electronicInvoice') }}
        </span>
      </Row>
      <Row>
        <span class="btn-action" @click="handleBtns">
          <Icon :custom="iconClass" style="transform: rotate(-90deg)"></Icon>
        </span>
      </Row>
    </div>
  </div>
</template>

<script>
import Emitter from '@/u-components/mixins/emitter';
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import Util from '@/libs/util.js';
import OrderMixin from '@/mixin/order.js';
import AgentOrderMixin from '@/mixin/agentOrder.js';
import ArchiveListMixin from '@/mixin/archive-list.js';

export default {
  name: 'OrderAction',
  mixins: [Emitter, PopTipMixin, CellMixin, Util, OrderMixin, ArchiveListMixin, AgentOrderMixin],
  data: function() {
    return {
      openOrderDetail: !this.params.row.presaleId ? this.$hasAuth('openOrderDetail') : this.$hasAuth('presellOrderDetailOpen'),
      closedOrderDetail: !this.params.row.presaleId ? this.$hasAuth('closedOrderDetail') : this.$hasAuth('presellOrderDetailClose'),
      orderReturn: !this.params.row.presaleId ? this.$hasAuth('orderReturn') : this.$hasAuth('presellOrderReturn'),
      orderPay: !this.params.row.presaleId ? this.$hasAuth('orderPay') : this.$hasAuth('presellOrderPay'),
      orderConfirm: !this.params.row.presaleId ? this.$hasAuth('orderConfirm') : this.$hasAuth('presellOrderConfirm'),
      orderReject: !this.params.row.presaleId ? this.$hasAuth('orderReject') : this.$hasAuth('presellOrderReject'),
      createDeliveryOrder: !this.params.row.presaleId ? this.$hasAuth('createDeliveryOrder') : this.$hasAuth('presellOrderCreateDelivery'),
      loading: true,
      currentUser: this.$store.getters.currentUser,
      BO: this.$store.getters.BO,
      agentOrderModify: this.$hasAuth('agentOrderModify'),
      orderModify: !this.params.row.presaleId ? this.$hasAuth('orderModify') : this.$hasAuth('presellOrderEdit'),
      agentOrderPay: this.$hasAuth('agentOrderPay'),
      agentOrderDel: this.$hasAuth('agentOrderDel'),
      agentOrderReturns: this.$hasAuth('agentOrderReturns'),
      orderConfirmDelivery: this.$hasAuth('orderConfirmDelivery'),
      saveReceivingNote: this.$hasAuth('saveReceivingNote'),
      agentBuyProduct: this.$hasAuth('agentBuyProduct'),
      agentOrderSubmit: this.$hasAuth('agentOrderSubmit'),
      orderUnlock: this.$hasAuth('orderUnlock'), // 解锁的权限码
      tempWidth: '400px'
    };
  },
  computed: {
    // 是否折叠鼠标悬浮时展示的订单行操作按钮
    isShowBtns() {
      return this.$store.state.user.orderActionBtnsStatus;
    },
    // 折叠Icon的类名切换
    iconClass() {
      return this.$store.state.user.orderActionBtnsStatus ? 'iconfont icon-zhankai' : 'iconfont icon-shouqi';
    }
  },
  created() {
  },
  methods: {
    // 切换操作按钮状态处理
    handleBtns() {
      if (this.$store.state.user.orderActionBtnsStatus) {
        this.$store.commit('SET_ORDERACTIONBTNSSTATUS', false);
        const el = document.querySelector('.ivu-table-fixed-suspend');
        this.tempWidth = el.style.width;
        el.style.width = '50px';
      } else {
        this.$store.commit('SET_ORDERACTIONBTNSSTATUS', true);
        document.querySelector('.ivu-table-fixed-suspend').style.width = this.tempWidth;
        document.querySelector('.ivu-table-fixed-suspend .ivu-table-tbody .ivu-table-cell').style.width = this.tempWidth;
      }
    },
    oppose(params) {
      this.mixin_agentOppose(params, this);
    },
    // 关闭
    close(params) {
      this.mixin_close(params, this);
    },
    // 打开
    openOrder(params) {
      this.mixin_openOrder(params, false, this); // eslint-disable-line
    },
    // 回退
    returnOrder(params) {
      this.mixin_returnOrder(params, this);
    },
    // 确认订单
    sure(params) {
      this.mixin_sure(params, this);
    },
    agentSure(params) {
      this.mixin_agentSure(params, this);
    },
    details(params) {
      Util.menuClickOldPage('/corppage/Orders/detail?cOrderNo=' + params.cOrderNo, this, { title: this.mlang('orderDetail') });
    },
    // 发货
    delivery(params) {
      // let routerObj = {
      //   name: 'delivery',
      //   query: { orderNo: params.cOrderNo }
      // };
      // this.editClick(routerObj);
      Util.menuClickOldPage(`/corppage/Deliverys/newDelivery?menuid=deliveryOrderList&cOrderNo=${params.cOrderNo}#/detailOrder`, this, { title: this.mlang('deliveryDetail') });
    },
    // 支付
    pay(params) {
      // isGuarantee=true 招行支付相关
      Util.menuClickOldPage('/corppage/Payments/addPayment?menuid=receivablesDetail&isGuarantee=false&orderno=' + params.cOrderNo, this, { title: this.mlang('paymentDetail') });
    },
    // 发货申请
    deliveryApply(order) {
      this.$router.push({
        path: '/agent/frame',
        query: {
          uri: `${window.__app.context.publicDomain}/mdf-node/meta/voucher/voucher_order/${order.id}?domainKey=udinghuo&pushBy=udinghuoOrder&serviceCode=orderList`
        }
      });
    },
    // ERP解锁
    deblock(params) {
      this.mixin_deblockOrder(params, this);
    },

    delOrder(order) {
      this.mixin_delOrder(order.id, this, true, null, order);
    },
    comment(order) {
      this.agentLink({
        type: 'orderDetail',
        query: {
          cOrderNo: order.cOrderNo,
          id: order.id,
          menuid: 'orderDetail',
          salesOrgId: order.salesOrgId
        },
        hash: 'orderComment'
      });
    },
    signIn(order) {
      this.agentLink({
        type: 'orderDetail',
        query: {
          cOrderNo: order.cOrderNo,
          id: order.id,
          menuid: 'orderDetail',
          selectTab: 'delivery',
          titleType: 'detail',
          salesOrgId: order.salesOrgId
        },
        hash: 'detailDelivery'
      });
    },
    buyAgain(order) {
      this.agentLink({
        type: 'orderDetail',
        query: {
          cOrderNo: order.cOrderNo,
          id: order.id,
          srcbillType: order.srcbillType,
          source: 'copyOrder',
          salesOrgId: order.salesOrgId
        },
        add: true
      });
    },
    agentPay(order) {
      this.agentLink({
        type: 'payDetail',
        query: {
          orderno: order.cOrderNo,
          orderId: order.id,
          isGuarantee: true
        }
      });
    },
    saleReturn(order, returnAll = true) {
      this.agentLink({ type: 'saleReturnDetail', query: { cOrderNo: order.cOrderNo, salesOrgId: order.salesOrgId, returnAll } });
    },
    modifyNew(order) {
      this.agentLink({ type: 'orderDetail', query: { cOrderNo: order.cOrderNo, id: order.id, titleType: 'editor', iOrderId: order.id, srcbillType: order.srcbillType, salesOrgId: order.salesOrgId }, add: true });
    },
    submit(order) {
      this.mixin_submit(order);
    },
    downloadEInvoiceBtn(order) {
      this.$bus.emit('invoiceModaStatus', { orderId: order.id, modaStatus: true });
    }
  }
};
</script>

<style lang="less" scoped>
.action {
  .btn-action {
    flex-shrink: 0;
  }
}
</style>
