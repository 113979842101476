export default {
  orderStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007EC","订单状态") /* "订单状态" */,
  oneMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007ED","近1个月") /* "近1个月" */,
  twoMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007EE","近2个月") /* "近2个月" */,
  threeMonth: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007EF","近3个月") /* "近3个月" */,
  halfYear: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F0","近半年") /* "近半年" */,
  oneYear: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F1","近1年") /* "近1年" */,
  orderNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F2","订单编码") /* "订单编码" */,
  customer: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F3","客户") /* "客户" */,
  orderNumber: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F4","订单号") /* "订单号" */,
  payStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F5","付款状态") /* "付款状态" */,
  dealRecord: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F6","处理记录") /* "处理记录" */,
  specialProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F7","特定商品") /* "特定商品" */,
  orderCreateDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F8","订单时间") /* "订单时间" */,
  confirmDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007F9","确认时间") /* "确认时间" */,
  billDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007FA","单据时间") /* "单据时间" */,
  hopeReceiveDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007FB","期望收货日期") /* "期望收货日期" */,
  saleMan: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007FC","业务员") /* "业务员" */,
  orderCreater: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007FD","制单人") /* "制单人" */,
  downERP: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007FE","下载到ERP") /* "下载到ERP" */,
  success: lang.templateByUuid("UID:P_UDHWN_17DD50DE050007FF","成功") /* "成功" */,
  fail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000800","失败") /* "失败" */,
  notDownload: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000801","未下载") /* "未下载" */,
  settlementWay: lang.templateByUuid("UID:P_UDHWN_17E3A78E0580003D","结算方式") /* "结算方式" */,

  // 驳回oppose
  oTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000802","请填写驳回意见,当前订单将被自动关闭!") /* "请填写驳回意见,当前订单将被自动关闭!" */,
  oTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000803","驳回原因") /* "驳回原因" */,
  oRejectReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000804","请填写驳回原因") /* "请填写驳回原因" */,
  // 关闭 close
  cTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000805","请填写关闭原因,当前订单将被自动关闭") /* "请填写关闭原因,当前订单将被自动关闭" */,
  cTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000806","关闭原因") /* "关闭原因" */,
  cCloseReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000807","请填写关闭原因") /* "请填写关闭原因" */,
  // 打开 openOrder
  open: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000808","打开") /* "打开" */,
  openOrderMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000809","将打开整张订单，确认打开") /* "将打开整张订单，确认打开" */,
  openOrderMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080A","确认打开订单") /* "确认打开订单" */,
  openOrderMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080B","么") /* "么" */,
  openCheckCreditMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080C","用户:") /* "用户:"" */,
  openCheckCreditMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080D","的信用额度是:") /* ""的信用额度是:" */,
  openCheckCreditMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080E",",当前余额是:") /* ",当前余额是:" */,
  openCheckCreditMsgD: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080F",",该订单超出信用余额，是否仍然打开该订单？") /* ",该订单超出信用余额，是否仍然打开该订单？" */,
  openCheckCreditMsgE: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000810",",该订单超出信用余额，不能打开该订单!") /* ",该订单超出信用余额，不能打开该订单!" */,
  openCheckCreditMsgF: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000811",",本单超信用:") /* ",本单超信用:" */,
  openCheckCreditMsgG: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000812","的客户:") /* "的客户:" */,
  confirmCheckCreditMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000813",",该订单超出信用余额，是否仍然确认该订单？") /* ",该订单超出信用余额，是否仍然确认该订单？" */,
  confirmCheckCreditMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000814",",该订单超出信用余额，不能确认该订单!") /* ",该订单超出信用余额，不能确认该订单!" */,
  // 回退returnOrder
  rContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000815","确定将") /* "确定将" */,
  rContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000816","退回到待确认状态吗？") /* "退回到待确认状态吗？" */,
  // 确认订单sure
  sWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000817","当前订单已提交！") /* "当前订单已提交！" */,
  sContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000818","是否继续操作？") /* "是否继续操作？" */,
  msgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000819","确定要确认订单") /* "确定要确认订单" */,
  msgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500081A","吗？") /* "吗？" */,
  bMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500081B","您的信用额度是:") /* "您的信用额度是:" */,
  bMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080E",",当前余额是:") /* ",当前余额是:" */,
  bMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500081C",",该订单提交会超出信用余额，是否仍然提交该订单？") /* ",该订单提交会超出信用余额，是否仍然提交该订单？" */,
  aMsgA: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500081B","您的信用额度是:") /* "您的信用额度是:" */,
  aMsgB: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500080E",",当前余额是:") /* ",当前余额是:" */,
  aMsgC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500081D",",该订单提交会超出信用余额，不能提交该订单!") /* ",该订单提交会超出信用余额，不能提交该订单!" */,

  warning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500081E","获取业务选项信息失败!") /* "获取业务选项信息失败!" */,
  // 批量
  batchPayError: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500081F","批量付款只能选择同一客户的订单") /* "批量付款只能选择同一客户的订单" */,
  batchPayWarningA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000820","请选择至少一条可付款的订单!") /* "请选择至少一条可付款的订单!" */,
  batchPayWarningB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000821","批量付款一次最多50单!") /* "批量付款一次最多50单!" */,
  batchPayContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000822","是否要对选中的") /* "是否要对选中的" */,
  batchPayContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000823","个订单进行付款吗？") /* "个订单进行付款吗？" */,
  batchOpposeWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000824","请选择至少一条状态为待确认的订单") /* "请选择至少一条状态为待确认的订单" */,
  batchOpposeWarningD: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000825","请选择至少一条可以发货（满足发货条件但待发货或部分发货）的订单") /* "请选择至少一条可以发货（满足发货条件但待发货或部分发货）的订单" */,
  batchDeliveryMsg: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000826","确定要批量发货么") /* "确定要批量发货么" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000827","请选择数据") /* "请选择数据" */,
  batchOpposeSuccessA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000828","已完成批量驳回！选中") /* "已完成批量驳回！选中" */,
  batchOpposeSuccessB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000829","个订单，其中成功驳回") /* "个订单，其中成功驳回" */,
  batchOpposeSuccessC: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500082A","个") /* "个" */,
  batchConfrimContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500082B","个订单进行确认吗？") /* "个订单进行确认吗？" */,

  // 订单列表销售合同参照过滤
  srcBillName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500082C","销售合同") /* "销售合同" */,
  srcNameSrcCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500082D","合同名称/合同编码") /* "合同名称/合同编码" */,
  searchSrcDetailCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500082E","商品名称/编码/条形码") /* "商品名称/编码/条形码" */,
  srcName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500082F","合同名称") /* "合同名称" */,
  srcCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000830","合同编码") /* "合同编码" */,
  salesOrgName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000831","销售组织") /* "销售组织" */,
  srcNameSrcStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000832","合同状态") /* "合同状态" */,
  inForce: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000833","已生效") /* "已生效" */,
  onFrozen: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000834","已冻结") /* "已冻结" */,
  onTerminated: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000835","已终止") /* "已终止" */,
  srcPlanEffectiveDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000836","计划生效日期") /* "计划生效日期" */,

  contractCtrlType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000837","合同类型") /* "合同类型" */,
  productClassName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000838","商品分类") /* "商品分类" */,
  productName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000839","商品名称") /* "商品名称" */,
  skuName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500083A","SKU名称") /* "SKU名称" */,
  cqtUnitName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500083B","计价单位") /* "计价单位" */,
  priceQty: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500083C","计价数量") /* "计价数量" */,
  saleunitName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500083D","销售单位") /* "销售单位" */,
  subQty: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500083E","销售数量") /* "销售数量" */,
  oriTaxUnitPrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500083F","含税单价") /* "含税单价" */,
  oriUnitPrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000840","无税单价") /* "无税单价" */,
  oriSum: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000841","含税金额") /* "含税金额" */,
  oriMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000842","无税金额") /* "无税金额" */,
  standardcontract: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000843","标准合同") /* "标准合同" */,
  quantitycontract: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000844","数量合同") /* "数量合同" */,
  pricecontract: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000845","价格合同") /* "价格合同" */,
  moneycontract: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000846","金额合同") /* "金额合同" */,
  changeSrcMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000847","切换销售合同会清空已选商品，是否继续?") /* "切换销售合同会清空已选商品，是否继续?" */,
  availableQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000848","可用数量") /* "可用数量" */,
  availableAmount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000849","可用金额") /* "可用金额" */,
  salesQuotation: lang.templateByUuid("UID:P_UDHWN_19C8E35805C80023","销售报价") /* "销售报价" */,
  salesQuotationName: lang.templateByUuid("UID:P_UDHWN_19C8E35805C8001E","销售报价名称") /* "销售报价名称" */,
  salesQuotationCode: lang.templateByUuid("UID:P_UDHWN_19C8E35805C8001F","销售报价编码") /* "销售报价编码" */,
  finalOffer: lang.templateByUuid("UID:P_UDHWN_19D8A8DA0420000B","最终报价") /* "最终报价" */,
  changeSalesQuotationWarning: lang.templateByUuid("UID:P_UDHWN_19C8E35805C80020","切换销售报价会清空已选商品，是否继续?") /* "切换销售报价会清空已选商品，是否继续?" */,
  pleaseSelectSalesQuotation: lang.templateByUuid("UID:P_UDHWN_19C8E35805C80021","请选择销售报价") /* "请选择销售报价" */,
  PleaseProductQuantity: lang.templateByUuid("UID:P_UDHWN_19C8E35805C80022","请填写正确商品数量") /* "请填写正确商品数量" */
};
