import Modal from '../u-components/components/modal';
import util from './util';
import ajax from '@/libs/ajax';
import { removeToken } from '@/libs/auth';
import _ from 'lodash';
const errorHandler = (res, config) => {
  const mlang = window.__app.mainV.$t;
  // res.code === 999 || res.code === 944 || res.code === 996 || res.code === 503 || res.code === 901 || res.code === 995 || res.code === 778
  const codes = [999, 944, 996, 503, 901, 995, 778, 777];
  if (res.code === 900 || res.code === 401 || res.code === 302 || (res.message && (res.message.indexOf(mlang('reLogin')) > -1 || res.message.indexOf('uorderapp.bulletin]getBulletinsForNewService') > -1))) {
    if (res.code === 900) {
      util.clearStorage();
    }
    Modal.error({
      content: res.code === 900 ? res.message || mlang('loginError') : mlang('loginError'),
      title: '',
      onOk: util.goLogin,
      onCancel: util.goLogin
    });
    return Promise.reject('error'); //eslint-disable-line
  } else if (codes.includes(res.code)) {
    let msg = res.message || mlang('serverError');
    if (config && config.data && config.data.nodeal) return res;
    Modal.remove();
    if (config.url.indexOf('/defineExtendJS') < 0) {
      Modal.error({
        content: msg
      });
    }
    console.error(res.message); //eslint-disable-line
    return Promise.reject(res);
  } else if (res.code === 986 && (config.url.match(/voucher\/delivery\/deliveryvoucher\/(.*)\/take/) || config.url.indexOf('/voucher/delivery/deliveryvoucher/take') > -1 || config.url.indexOf('/voucher/salereturn/back') > -1)) {
    //  当两个收货确认接口返回为986的时候
    return Promise.reject(res);
  } else if ([987, 986, 990, 991, 9992].includes(res.code)) {
    // !res.data && (res.data = {});
    // res.data.__message = res.message;
    if (config.url.indexOf('/getPromotionPackageData') === -1 && config.url.indexOf('/verifyMutexShoppingCartGroup') === -1 && config.url.indexOf('selectfree/getSelectFreeByShoppingCart') === -1 && !config.url.includes('voucher/delivery/deliveryvoucher/')) {
      return new Promise(function(resolve, reject) {
        Modal.confirm({
          title: '',
          content: res.message || '',
          onOk: () => {
            if (res.code === 987) { config.url += `${config.url.indexOf('?') === -1 ? '?' : '&'}isVerify=false`; }
            if (res.code === 986) { config.url += `${config.url.indexOf('?') === -1 ? '?' : '&'}isCyClePriceVerify=false`; }
            if (res.code === 990) { Object.assign(config.data.data, { isContractVerify: 1 }); }
            if (res.code === 991) {
              if (config.data.data) {
                config.data.data.extendParams = { ...config.data.data.extendParams, isAccountCheck: true };
              }
            };
            if (res.code === 9992) {
              if (config.data.data) {
                config.data.data.extendParams = { ...config.data.data.extendParams, isAccountCheck: false };
              }
            };
            if (res.data && !_.isNil(res.data.urlParams)) {
              if (res.code !== 990) {
                config.params = Object.assign({}, config.params, res.data.urlParams);
              }
            }
            if (config.url.indexOf('/voucher/order/submit/model/bizs') > -1) {
              config.data.data = res.data;
            }
            resolve(ajax.request(config));
          },
          onCancel: () => {
            reject(res);
          }
        });
      });
    } else {
      return Promise.reject(res);
    }
  } else if (res.code === 989) {
    Modal.info({
      content: mlang('overdueError')
    });
    return Promise.reject(res);
  } else if (res.code === 666) {
    Modal.error({
      content: mlang('crossTokenErrorMessage')
    });
  } else if (res.code === 9001) {
    Modal.info({
      content: res.message
    });
    return Promise.resolve(res);
  } else if (res.code === 200) {
    if (config.url.indexOf('/agent/infrastructure/agents/current/0') > -1 && res.data && res.data.content && Array.isArray(res.data.content) && res.data.content.length === 0) {
      Modal.error({
        content: mlang('notHasRelationsMesage'),
        onOk: function() {
          removeToken();
          localStorage.clear();
          window.location.href = '/logout';
        }
      });
      return;
    }
    if (config && config.data && config.data.nodeal) return res;
    if (res.data && res.data.totalElements) res.data.totalElements = Number(res.data.totalElements);
    if (res.data && res.data.totalCount) res.data.totalCount = Number(res.data.totalCount);
    return res.data;
  } else {
    if (config.url.indexOf('/defineExtendJS') > -1) {
      if (config && config.data && config.data.data && config.data.data.nodeal) return res;
      return res.data;
    } else {
      Modal.error({
        content: res.message
      });
      return Promise.reject(res);
    }
  }
};

export default errorHandler;
