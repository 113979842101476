<template>
  <ul class="agent-content-automatic">
    <Carousel
      v-if="hotProducts.length"
      v-model="value"
      :autoplay="setting.autoplay"
      :autoplay-speed="setting.autoplaySpeed"
      :dots="setting.dots"
      :radius-dot="setting.radiusDot"
      :trigger="setting.trigger"
      :arrow="setting.arrow">
      <CarouselItem
        v-for="(productArray,index) in hotProducts"
        :key="index">
        <div class="hotProducts-carousel">
          <li
            v-for="product in productArray"
            :key="product.id"
            class="list">
            <a
              class="list-link"
              href="javascript:void(0)"
              @click="toProductDetail(product)">
              <ul class="list-content">
                <li class="list-content-img">
                  <span class="sort-title uniform-label"><i>{{ product.sortTitle }}</i></span>
                  <img
                    :src="product.imgUrl"
                    alt="">
                </li>
                <li class="list-content-text">
                  <ul :class="{hasbizName:$getBO('ORDERSHOWWAY') && currentUser.__isMutiBiz && !currentUser.__showBiz}">
                    <!-- 服务貌似没有返回orgName和bizName -->
                    <li v-if="$getBO('ORDERSHOWWAY') && currentUser.__isMutiBiz && !currentUser.__showBiz" class="biz-name">{{ $util.sellerInfo(currentUser,product) }}</li>
                    <li
                      :title="product.cName"
                      class="describe">
                      {{ product.cName }}
                    </li>
                    <!-- 用来加促销标记，现在服务没有先不加 -->
                    <li class="promotio-tip"></li>
                    <li class="price">
                      <span :class="['price-symbol',{'hidesymbol':testStr(product.fSalePrice)}]">{{ '' | upCoinOperater | priceFree | upHideOperater('fSalePrice') }}</span>
                      <span :class="['price','bitifuling',{'nosetPrice':testStr(product.fSalePrice)}]" style="margin: 0;">{{ product.fSalePrice | upCoinDigit('price') | priceFree | upHideString('fSalePrice') }}</span>
                      <span style="font-size: 15px;">/{{ product.oUnit.cName }}</span>
                    </li>
                    <!-- <li class="sale">
                      <span
                        v-if="$getBO('ORDERSHOWWAY')"
                        class="left">{{ product.orgName ? product.bizName + '/' + product.orgName : product.bizName }}</span>
                      <span class="right">{{ mlang('saleNum') }}:{{ product.iSales || 0 }}</span>
                    </li> -->
                  </ul>
                </li>
              </ul>
            </a>
          </li>
        </div>
      </CarouselItem>
    </Carousel>
  </ul>
</template>
<script>
import { getTop3HotProducts } from '@/api/product.js';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { priceFree } from '@/filters/index.js';
export default {
  name: 'HotProduct',
  data() {
    return {
      value: 0,
      setting: {
        autoplay: false,
        autoplaySpeed: 2000,
        dots: 'none',
        radiusDot: false,
        trigger: 'click',
        arrow: 'always'
      },
      hotProducts: []
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.init();
  },
  methods: {
    toProductDetail(product) {
      if (window.top === window.self) {
        this.agentLink({ type: 'productDetail', query: { menuid: 'agentProductDetail', id: product.id, bizProductId: product.bizProductId }, newWindow: true });
      } else {
        this.$util.toFrameProductDetail(product.id, product.bizProductId);
      }
    },
    init() {
      getTop3HotProducts(6).then((res) => {
        // 加了排序，后端没返回序号，按照销量从大到小返回的数据，前端加了排序号
        res.forEach((v, index) => {
          v.sortTitle = `HOT ${index + 1}`;
        });
        this.hotProducts = _.chunk(res, 2);
      });
    },
    testStr(content) {
      return !this.$util.isNoValue(priceFree(content)) && isNaN(priceFree(content));
    }
  }
};
</script>
