import accountParentView from '@/views/agent/home/components/accountHome.vue';

const role = {
  path: 'mutiAgentRoleList',
  name: 'mutiAgentRoleList',
  component: accountParentView,
  redirect: { name: 'mutiAgentRoleList' },
  meta: { module: 'mutiAgentRoleList' },
  children: [
    {
      path: 'mutiAgentRoleList',
      name: 'mutiAgentRoleList',
      meta: { module: 'mutiAgentRoleList' },
      component: () => import('@/views/agent/accounts/components/role/roleList.vue')
    },
    {
      path: 'roleAdd',
      name: 'roleAdd',
      component: () => import('@/views/agent/accounts/components/role/roleAdd.vue')
    }
  ]
};
export default role;
