<style lang="less">
@import './scroll-bar.less';
</style>
<template>
  <div
    ref="scrollContainer"
    class="scroll-container"
    @wheel.prevent="handleScroll">
    <div
      ref="scrollWrapper"
      :style="{top: top + 'px'}"
      class="scroll-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const delta = 15;
export default {
  name: 'ScrollBar',
  data() {
    return {
      top: 0
    };
  },
  methods: {
    handleScroll(e) {
      // console.log(1);
      const eventDelta = e.wheelDelta || -e.deltaY * 3;
      const $container = this.$refs.scrollContainer;
      const $containerHeight = $container.offsetHeight;
      const $wrapper = this.$refs.scrollWrapper;
      const $wrapperHeight = $wrapper.offsetHeight;
      if (eventDelta > 0) {
        this.top = Math.min(0, this.top + eventDelta);
      } else {
        if ($containerHeight - delta < $wrapperHeight) {
          if (this.top < -($wrapperHeight - $containerHeight + delta)) {
            this.top = this.top;
          } else {
            this.top = Math.max(this.top + eventDelta, $containerHeight - $wrapperHeight - delta);
          }
        } else {
          this.top = 0;
        }
      }
    }
  }
};
</script>
