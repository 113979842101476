<template>
  <div
    v-if="enable"
    class="pull-left importAndOutPut-btn-box btn-box">
    <Button
      v-if="!exportBatch"
      :type="btnType"
      :fieldid="`batchBtn_${bizMeta.billId}`"
      class="batchBtn"
      @click="exeExport('all')">
      {{ mlang('export') }}
    </Button>
    <Dropdown
      v-if="exportBatch"
      placement="bottom-start"
      class="btn-item"
      @on-click="exeExport">
      <Button
        :type="btnType"
        class="batchBtn"
        :fieldid="`batchBtn_export_${bizMeta.billId}`">
        {{ mlang('export') }}
        <Icon type="arrow-down-b"></Icon>
      </Button>
      <DropdownMenu slot="list">
        <!-- <DropdownItem name="all">{{ mlang('exportAll') }}</DropdownItem> -->
        <DropdownItem v-if="!bizMeta.hideExportByQuery" name="condition" :fieldid="`batchBtn_exportResult_${bizMeta.billId}`">
          {{ mlang('exportResult') }}
        </DropdownItem>
        <DropdownItem
          v-if="bizMeta.multiple"
          name="id"
          :fieldid="`batchBtn_exportRecord_${bizMeta.billId}`">
          {{ mlang('exportRecord') }}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
export default {
  name: 'ExportExcel',
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    btnType: {
      type: String,
      default: 'default'
    },
    exportBatch: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  computed: {
    enable() {
      let enable = false;
      if (this.bizMeta.export) {
        if (this.bizMeta.export.auth) {
          const auth = this.$hasAuth(this.bizMeta.export.auth);
          if (auth) {
            enable = true;
          }
        } else {
          return true;
        }
      }
      return enable;
    },
    showExport() {
      let show = false;
      const exportObj = this.bizMeta.export;
      if (exportObj) {
        if (exportObj.auth) {
          const exportAuth = this.$hasAuth(exportObj.auth);
          if (exportAuth) {
            show = true;
          }
        } else {
          show = true;
        }
      }
      return show;
    }
  },
  methods: {
    exeExport(name) {
      this.$emit('on-click', name);
    }
  }
};
</script>
