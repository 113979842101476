import ajax from '@/libs/ajax';

// 查询退款记录
export const getRefundBySaleReturnNo = (cSaleReturnNo) => {
  return ajax.request({
    // url: `/pc/RefundSrv/getRefundBySaleReturnNo?cSaleReturnNo=${cSaleReturnNo}`,
    url: `/voucher/refund/${cSaleReturnNo}/refundrecord?isArchive=1`,
    method: 'get'
  });
};

export const getRefundBySaleReturnId = (saleReturnId) => {
  return ajax.request({
    url: `marketingbill/salereturn/upaymentvoucher/${saleReturnId}?isMarket=1`,
    method: 'get'
  });
};

export const getRefundById = (id) => {
  return ajax.request({
    url: `/report/history/refund/getRefundBySaleReturnId/${id}?isReport=1`,
    method: 'get'
  });
};

// 根据退货单号查询退货单详情
export const getSaleReturnBySaleReturnNo = (saleReturnId) => {
  return ajax.request({
    url: `marketingbill/salereturn/salereturn/${saleReturnId}/detail/flow?isMarket=1`,
    method: 'get'
  });
};

export const getSaleReturnById = (id) => {
  return ajax.request({
    url: `/report/history/salereturn/detail/${id}?isReport=1`,
    method: 'get'
  });
};

export const hideOrderAttach = () => {
  return ajax.request({
    url: '/hideOrderAttach',
    method: 'get'
  });
};
export const getSaleOrgs = (bizId) => {
  return ajax.request({ url: `/orginazation/merchant/${bizId}/org/user/list/2?isArchive=1`, method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};

export const getLogistics = (bizId) => {
  return ajax.request({ url: `/archives/logistics/corprationlogistics/list?bizId=${bizId}&isArchive=1`, method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
export const getNewSaleReturn = (postData) => {
  let url = `/voucher/salereturn/vo/newsalereturn?isArchive=1&cOrderNo=${postData.cOrderNo ? postData.cOrderNo : ''}&bizId=${postData.bizId ? postData.bizId : ''}&salesOrgId=${postData.salesOrgId ? postData.salesOrgId : ''}&agentRelationId=${postData.agentRelationId ? postData.agentRelationId : ''}`;
  if (postData.returnAll !== undefined) {
    url = `${url}&returnAll=${postData.returnAll}`;
  }
  return ajax.request({
    url,
    method: 'get'
  });
};

export const getDepartment = (orgId) => {
  return ajax.request({ url: `/orginazation/department/user/${orgId}?isArchive=1&enable=true`, method: 'get' }).then((data) => {
    data &&
      data.map((v) => {
        v.value = v.id;
        v.title = v.name;
      });
    return data;
  });
};
export const modifylogistics = (data) => {
  return ajax.request({ url: '/voucher/salereturn/vo/modifylogistics', method: 'post', data });
};

export const getSaleReturnProductList = (data) => {
  let bizId = '';
  let cProductCodeOrName = '';
  let orgId = '';
  data.conditions.map((v) => {
    if (v.name === 'cProductCodeOrName') {
      cProductCodeOrName = v.v1;
    }
    if (v.name === 'bizId') {
      bizId = v.v1 || '';
    }
    if (v.name === 'orgId') {
      orgId = v.v1 || '';
    }
  });

  return ajax.request({
    url: `/voucher/delivery/salereturn/product/list/vo?limitDays=720&pageSize=${data.pager.pageSize}&pageIndex=${data.pager.currentPage}&productCodeOrName=${cProductCodeOrName}&bizId=${bizId}&orgId=${orgId}`,
    data: {
      isArchive: 1
    },
    method: 'get'
  }).then((res) => {
    res.content.map((v, index) => {
      v.iSendQuantityUnit = v.cProductUnitName ? (v.iSendQuantity + v.cProductUnitName) : v.iSendQuantity;
      v.fSaleReturnQuantityUnit = v.cProductUnitName ? (v.fSaleReturnQuantity + v.cProductUnitName) : v.fSaleReturnQuantity;
      if(!v.id){
        v.id = `${data.pager.currentPage}_${v.iSKUId}_${index}`;
      }

    });
    const result = {
      totalCount: res.totalElements,
      data: res.content
    };
    return result;
  });
};

export const getDeliveryByProduct = (data) => {
  return ajax.request({
    url: `/voucher/delivery/product/list?limitDays=720&pageSize=${data.pageSize}&pageIndex=${data.pageIndex}&productSkuId=${data.iSKUId}&bizId=${data.bizId}&orgId=${data.orgId || ''}`,
    data: {
      isArchive: 1
    },
    method: 'get'
  }).then((res) => {
    const result = {
      totalCount: res.totalElements,
      data: res.content
    };
    return result;
  });
};

export const getDiscountPrice = (sku, orgId = '') => {
  return ajax.request({
    url: `/archives/product/getDiscountPrice?isArchive=1&skuId=${sku.iSKUId}&productId=${sku.iProductId}&count=${sku.fReturnQuantity}&bizProductId=${sku.bizProductId}&bizId=${sku.bizId}&saleOrgId=${orgId}`,
    method: 'get'
  }).then((res) => {
    return res;
  });
};

export const loadSaleReturnDetails = (postData) => {
  return ajax.request({
    url: '/voucher/salereturn/vo/loadsalereturndetails',
    method: 'post',
    data: postData
  }).then((res) => {
    return res;
  });
};

export const saveSaleReturn = (postData) => {
  return ajax.request({
    url: '/voucher/salereturn/vo/save',
    method: 'post',
    data: postData
  }).then((res) => {
    return res;
  });
};

export const modifySaleReturn = (postData) => {
  return ajax.request({
    url: '/voucher/salereturn/vo/save',
    method: 'post',
    data: postData
  }).then((res) => {
    return res;
  });
};
export const getDiscountPriceModal = (sku, orgId = '') => {
  return ajax.request({
    url: `/archives/product/getDiscountPrice?isArchive=1&skuId=${sku.id}&productId=${sku.iProductId}&count=${sku.iQuantity}&bizProductId=${sku.bizProductId}&bizId=${sku.bizId}&saleOrgId=${orgId}`,
    method: 'get'
  }).then((res) => {
    return res;
  });
};
export const saveSaleReturnMoneyByPrePrices = (postData) => {
  return ajax.request({
    url: '/voucher/salereturn/vo/saveSaleReturnMoneyByPrePrices',
    method: 'post',
    data: postData
  }).then((res) => {
    return res;
  });
};
// 导入完成后
export const getImportNewOrderDetailsInSaleReturn = (postData) => {
  return ajax.request({
    url: '/voucher/salereturn/package/salereturndetails/vo?isArchive=1&import=true',
    method: 'post',
    datatype: 'json',
    data: {
      data: postData,
      datatype: 'json',
      isArchive: 1
    }
  });
};
