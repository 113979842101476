<template>
  <div class="menu-bar">
    <template
      v-for="(btnGroup,index) in Object.entries(btnGroups)">
      <div
        v-if="btnGroup[0] === 'inputOutput' && isShowInputOutput"
        :key="index"
        class="function-area">
        <slot name="inputOutput"></slot>
      </div>
      <template
        v-else>
        <div
          v-if="btnGroup[1] && btnGroup[1].length"
          :key="index"
          class="function-area">
          <template v-for="(btn,index2) in btnGroup[1]">
            <Dropdown
              v-if="btn.btnType === 'dropDownBtn'"
              placement="bottom-start"
              class="btn-item"
              @on-click="dropdownClick(...arguments, btn.children)">
              <Button :class="btn.class">
                {{ btn.title }}
                <Icon type="arrow-down-b"></Icon>
              </Button>
              <DropdownMenu
                slot="list"
                class="print-list">
                <template v-for="(child,idx) in btn.children">
                  <DropdownItem
                    :name="idx">
                    {{ child.title }}
                  </DropdownItem>
                </template>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              v-else-if="btn.btnType === 'dropDownBtnDeft'"
              :trigger="'click'"
              placement="bottom-start"
              class="btn-item"
              @on-click="dropdownClick(...arguments, btn.children)">
              <div
                class="deft-op-btn ivu-btn ivu-btn-default">
                <span @click.stop="btnClick(btn.children[0])">
                  {{ btn.title }}
                </span>
                <div class="split"></div>
                <Icon type="arrow-down-b"></Icon>
              </div>
              <DropdownMenu
                slot="list"
                class="print-list">
                <template v-for="(child, idx) in btn.children">
                  <DropdownItem
                    :name="idx"
                    :fieldid="'dropdownItem_'+child.name+'_'+bizMeta.billId">
                    {{ child.title }}
                  </DropdownItem>
                </template>
              </DropdownMenu>
            </Dropdown>
            <div
              v-else
              :key="index2"
              class="btn-item">
              <div
                class="ivu-dropdown-rel">
                <Button
                  :class="btn.class"
                  :icon="btn.icon"
                  :type="btn.type"
                  :fieldid="btn.name+'_'+bizMeta.billId"
                  :disabled="btn.disabled"
                  @click="btnClick(btn)">
                  {{ btn.title }}
                </Button>
              </div>
            </div>
          </template>
        </div>
      </template>
    </template>
    <Row v-if="isShowFullScreenBtn" class-name="fullscreen-btn-div" type="flex" justify="center" align="middle">
      <Col :title="fullScrenTitle"><SvgIcon :icon-class="screenClass" class-name="fullscreen-svg" @click.native="toggleFullScreen()" @mouseover.native="fullScreenMouseover(true)" @mouseout.native="fullScreenMouseover(false)"></SvgIcon></Col>
    </Row>
  </div>
</template>
<script>
export default {
  name: 'MenuBar',
  props: {
    bizMeta: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isFullScreen: false,
      btnGroups: {
        add: [],
        stateAdj: [
          // 审核
        ],
        biz: [],
        inputOutput: [],
        more: []
      }
    };
  },
  computed: {
    fullScrenTitle() {
      return this.isFullScreen ? this.mlang('shouqiquanping') : this.mlang('quanping');
    },
    screenClass() {
      return this.isFullScreen ? 'shouqiquanping' : 'quanping';
    },
    isShowFullScreenBtn() {
      return sessionStorage.isAgentSide !== 'true';
    },
    isShowInputOutput() {
      return Boolean(this.bizMeta.print) || Boolean(this.bizMeta.export) || Boolean(this.bizMeta.import);
    }
  },
  watch: {
    bizMeta: {
      handler(meta) {
        if (!meta) return;
        this.resolveMeta(meta);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    fullScreenMouseover(bIn) {
      const color = bIn ? '#505766' : '#BAC0C7';
      const selecter = this.isFullScreen ? 'icon-shouqiquanping' : 'icon-quanping ';
      document.querySelector(`#${selecter} > path`).setAttribute('fill', color);
    },
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
      this.$emit('toggleFullScreen', this.isFullScreen);
    },
    btnClick(btn) {
      this.$emit('btnClick', btn);
    },
    dropdownClick(idx, btnList) {
      this.btnClick(btnList[idx]);
    },
    resolveMeta(meta) {
      let detNameList = ['del', 'delete'];
      this.btnGroups = {
        add: [],
        stateAdj: [
          // 审核
        ],
        biz: [],
        inputOutput: [],
        more: []
      };
      // 新增
      if (meta.addBtn && this.$hasAuth(meta.addBtn.auth)) {
        let addBtn = {
          name: 'add',
          key: 'add',
          icon: 'plus',
          type: 'primary',
          class: 'add-btn',
          disabled: meta.addBtn.disabled,
          title: this.mlang('add')
        };
        this.btnGroups.add = [addBtn];
      };
      // 更多
      let moreBtn = {
        name: 'more',
        key: 'more',
        btnType: 'dropDownBtn',
        title: this.mlang('more'),
        children: []
      };
      // 状态调整
      if (meta.stateAdjBtn) {
        let visibleStateAdjBtn = meta.stateAdjBtn.filter((v) => {
          return v.isHidden !== true;
        }) || [];

        while (visibleStateAdjBtn.length) {
          this.btnGroups.stateAdj.push(visibleStateAdjBtn.shift());
        }

        // let count = 3;
        // while (visibleStateAdjBtn.length && count) {
        //   this.btnGroups.stateAdj.push(visibleStateAdjBtn.shift());
        //   count--;
        // }
        // 更多
        // if (visibleStateAdjBtn.length) {
        //   moreBtn.children.push(...visibleStateAdjBtn);
        // }
      }
      // 业务处理
      if (meta.batchBtn) {
        let visibleBatchBtn = meta.batchBtn.filter((v) => {
          return v.isHidden !== true;
        }) || [];

        while (visibleBatchBtn.length) {
          let btn = visibleBatchBtn.shift();
          if (detNameList.includes(btn.name)) {
            moreBtn.children.push(btn);
          } else {
            this.btnGroups.biz.push(btn);
          }
        }

        // let count = 2;
        // while (visibleBatchBtn.length && count) {
        //   let btn = visibleBatchBtn.shift();
        //   if (detNameList.includes(btn.name)) {
        //     moreBtn.children.push(btn);
        //   } else {
        //     this.btnGroups.biz.push(btn);
        //   }
        //   count--;
        // }
        // 更多
        // if (visibleBatchBtn.length) {
        //   moreBtn.children.push(...visibleBatchBtn);
        // }
      }
      // 更多
      let moreBtnCount = moreBtn.children.length;
      if (moreBtnCount === 1 && detNameList.includes(moreBtn.children[0].name)) {
        this.btnGroups.more.push(moreBtn.children.pop());
      } // else if (moreBtnCount > 1) {
      //   this.btnGroups.more.push(moreBtn);
      // }
    }
  }
};
</script>

<style lang="less" scoped>
.menu-bar {
  display: flex;
  justify-content: flex-end;
  margin: 0px 20px 8px;
  .function-area {
    margin-left: 10px;
    display: table-cell;
    .btn-item {
      display: inline-block;
      &+.btn-item .ivu-btn {
        margin-left: -1px;
      }
      & :hover {
        z-index: 2;
      }
      &:not(:first-child) .ivu-btn {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
      &:not(:last-child) .ivu-btn {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      .deft-op-btn {
        padding: 0 4px 0 12px;
        line-height: 26px;
        .split {
          margin: 0 2px;
          display: inline-block;
          height: 1.4em;
          width: 1px;
          vertical-align: middle;
          position: relative;
          top: -.06em;
          background: #e8eaec;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
  .fullscreen-svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .fullscreen-btn-div{
    margin-left: 10px;
  }
</style>
