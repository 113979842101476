import ajax from '@/libs/ajax';
export const getSysNoticeSettings = (data) => {
  return ajax.request({
    url: '/archives/systemnotice/queryschema',
    method: 'post',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const delSysNoticeSettings = (id) => {
  return ajax.request({
    url: `/archives/systemnotice/${id}`,
    method: 'delete',
    data: { isArchive: 1 }
  });
};

// 公告设置详情
export const editNoticeSetting = (id) => {
  return ajax
    .request({
      url: `/archives/systemnotice/${id}`,
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      data.dateRange = [data.startDate, data.endDate];
      return data;
    });
};
// 公告设置详情保存
export const saveNoticeSetting = (data) => {
  return ajax.request({
    url: '/archives/systemnotice/save',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getNotice = () => {
  return ajax.request({
    url: '/archives/systemnotice/popup/systemnotice',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const confirmNotice = (id) => {
  return ajax.request({
    url: `/archives/systemnotice/${id}/confirm`,
    method: 'post',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const getBizNotice = () => {
  return ajax.request({
    url: '/archives/notice/popuplist/terminal/url',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 获取订货端公告弹窗
export const getAgentPopNotice = () => {
  return ajax.request({
    url: '/archives/notice/popuplist',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 设为已读 api/archives/notice/64843074773323/confirm
export const confirmAgentNotice = (id) => {
  return ajax.request({
    url: `/archives/notice/${id}/confirm`,
    method: 'post',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
