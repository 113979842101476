<template>
  <div class="bodyFour topvalue-20">
    <div class="orderList_tab">
      <ul>
        <li v-for="(item,index) in userList" :key="index" class="list">
          <a :class="item.name===$route.name?'activeList':''" @click="checkList($event,item)">{{ item.title }}</a>
        </li>
      </ul>
      <div style="clear:both;"></div>
    </div>
    <div class="userList">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      userList: [
        { title: this.mlang('personInfo'), name: 'userbase' },
        { title: this.mlang('accountSafe'), name: 'usersafe' }
      ],
      nowList: 0,
      nowPage: this.$route.path
    };
  },
  mounted() {
  },
  methods: {
    checkList(e, item) {
      this.$router.push({
        name: item.name
      });
    },
    checkPage() {
      var i = -1;
      this.userList.map((v) => {
        ++i;
        let nowArea = v.area;
        let trueArea = 'v#' + this.$route.path;
        if (nowArea === trueArea) {
          this.nowList = i;
          this.checkList(this.nowList);
        }
      });
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
.bodyFour {
  background: #fff;
  padding: 15px 0;
}
.list {
  float: left;
}
.list:last-child {
  border: none;
}
.list > a {
  font-size: 14px;
  margin-right: 2px;
  height: 44px;
  border-radius: 4px 4px 0 0;
  padding: 10px 0;
  margin: 0 20px;
  color: #666;
  position: relative;
}
.list a:after {
  position: absolute;
  bottom: 0;
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  width: 14px;
  border-bottom: solid 2px transparent;
}
/*.list .activeList:after {
  border-bottom-color: #505766;
}*/
.orderList_tab .list > a:hover:after {
  border-bottom-color: #505766;
}

.orderList_tab {
  border-bottom: 1px solid #e5e5e5;
  font-size: 16px;
  display: block;
  padding-bottom: 7px;
}
.orderList_tab .activeList {
  border-bottom-color: #505766 !important;
}
.pull-right {
  float: right !important;
}

.btn-warning {
  color: #fff;
  background-color: #f89d3b;
  border-color: #f79122;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 2px 14px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 0px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
