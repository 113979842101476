export default {
  notOpen: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500046F","未启用") /* "未启用" */,
  corpOrAgent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000470","企业端/订货端") /* "企业端/订货端" */,
  corpClient: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000471","企业端") /* "企业端" */,
  agentClient: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000472","订货端") /* "订货端" */,
  unKnowType: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000473","未知类型") /* "未知类型" */,
  biaotou: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000474","表头：") /* "表头：" */,
  biaoti: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000475","表体：") /* "表体：" */,
  biaowei: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000476","表尾") /* "表尾" */,
  defineSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000477","自定义项设置") /* "自定义项设置" */,
  mustWrite: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000478","必填") /* "必填" */,
  hexinqiye: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000479","核心企业") /* "核心企业" */,
  qudaoshangjia: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500047A","渠道商家") /* "渠道商家" */,
  fenlei: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500047B","分类") /* "分类" */,
  projectNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500047C","项目编号") /* "项目编号" */,
  fieldType: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500047D","字段类型") /* "字段类型" */,
  showName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500047E","显示名称") /* "显示名称" */,
  kongzhiluruchangdu: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500047F","控制录入长度") /* "控制录入长度" */,
  xiaoshuweishu: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000480","小数位数") /* "小数位数" */,
  defaultValue: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000481","默认值") /* "默认值" */,
  withFilter: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000482","参与过滤") /* "参与过滤" */,
  jiemiankecaozuo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000483","界面可操作") /* "界面可操作" */,
  kongzhiquanxian: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000484","控制权限") /* "控制权限" */,
  waibudizhi: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000485","外部地址") /* "外部地址" */,
  qingshurulianjie: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000486","请输入链接") /* "请输入链接" */,
  sortNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000487","排序号") /* "排序号" */,
  remark: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000488","备注") /* "备注" */,
  defineRemark: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000489","自定义项备注") /* "自定义项备注" */,
  archiveValue: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500048A","档案值") /* "档案值" */,
  yunsuanfu: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500048B","运算符") /* "运算符" */,
  gongshijiaoyan: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500048C","公式校验") /* "公式校验" */,
  orderHeader: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500048D","订单表头") /* "订单表头" */,
  orderBody: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500048E","订单表体") /* "订单表体" */,
  agentDefine: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500048F","客户自定义项") /* "客户自定义项" */,
  deliveryHeader: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000490","发货单表头") /* "发货单表头" */,
  deliveryBody: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000491","发货单表体") /* "发货单表体" */,
  shoukuanHeader: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000492","收款单表头") /* "收款单表头" */,
  qianzaikehuDefine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000493","潜在客户自定义项") /* "潜在客户自定义项" */,
  gongshijiaoyanchenggong: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000494","公式校验成功！") /* "公式校验成功！" */,
  gongshibuhefaMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000495","公式不合法，必须包含左侧字段！") /* "公式不合法，必须包含左侧字段！" */,
  gongshibuhefa: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000496","公式不合法") /* "公式不合法" */,
  xiaoshuweishuMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000497","小数位数必须为1~8之间的整数!") /* "小数位数必须为1~8之间的整数!" */,
  archiveMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000498","请填写档案中名称") /* "请填写档案中名称" */
};
