import { oneOf } from '@/u-components/utils/assist';
import _ from 'lodash';
const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {},

  methods: {
    mixin_makeData(controls) {
      const formData = {};
      controls.forEach((row) => {
        const key = row.fieldName;
        formData[key] = _.isUndefined(row.defaultValue) ? null : row.defaultValue;
        if (row.isDefine) {
          if (formData[key] === null) {
            formData[key] = '';
          }
        }
        // if (row.fieldName.indexOf('.') > -1) {
        //   util.objStr2Obj(formData, row.fieldName);
        // }
        if (row.controlType === 'Number') {
          if (!formData[key]) {
            formData[key] = 0;
          } else {
            formData[key] = parseFloat(formData[key]);
          }
        }
        if (row.controlType === 'CheckboxGroup') {
          formData[key] = formData[key] || [];
        }
        if (oneOf(row.controlType, ['SelectTab', 'RichEditor'])) {
          formData[key] = formData[key] || '';
        }
        if (row.controlType === 'Radio') {
          if (_.isString(formData[key])) {
            if (formData[key] === 'true') {
              formData[key] = true;
            } else if (formData[key] === 'false') {
              formData[key] = false;
            } else if (row.fieldType === 2) { // 数字默认值
              formData[key] = Number(formData[key]);
            }
          }
        }
        if (row.controlType === 'Upload') {
          formData[key] = formData[key] || [];
        }
        if (row.controlType === 'Status') {
          if (_.isString(formData[key])) {
            if (formData[key] === 'true') {
              formData[key] = 1;
            } else {
              formData[key] = 0;
            }
          }
        }
      });
      return formData;
    },
    mixin_makeRule(control, bizMeta) {
      let specialCtrl = ['CitySelect'];
      if (!control.isRequired && !specialCtrl.includes(control.controlType)) {
        return { required: false };
      }
      let rule = {
        required: true,
        trigger: 'blur',
        message: control.isTableField ? '' : this.mlang('notNull')
      };
      if (control.isDefine) {
        rule.type = 'string';
        if (control.controlType === 'Upload') {
          rule.type = 'array';
        }
      } else {
        switch (control.fieldType) {
          case 1:
            rule.type = 'string';
            break;
          case 2:
          case 3:
            rule.type = 'number';
            break;
          case 10:
            rule.type = 'array';
            break;
          case 11:
            rule.type = 'boolean';
            break;
          case 12:
            rule.type = 'object';
            break;
          default:
            rule.type = 'string';
            break;
        }
      }

      if (oneOf(control.controlType, ['TreeSelect', 'Select', 'CheckboxGroup', 'SelectTab', 'Refer'])) {
        rule.trigger = 'change';
      }
      if (control.controlType === 'Number') {
        rule.min = control.min;
      }
      if (control.controlType === 'DateTimeRange' || control.controlType === 'DateRange') {
        rule.type = 'array';
      }
      if (control.controlType === 'CheckboxGroup') {
        // rule.validator = (rule, value, callback) => {
        // const hasActionStatus = value.filter((v) => {
        //   return v.actionStatus === 'C' || (v.id && !v.actionStatus);
        // });
        // if (hasActionStatus.length) {
        //   callback();
        // } else {
        //   callback(new Error());
        // }
        // };
        rule.type = 'array';
        rule.min = 1;
        rule.trigger = 'change';
      }
      if (control.controlType === 'CitySelect') {
        rule.trigger = 'select';
        rule.type = 'object';
        // 校验是否为空
        const isNullValidator = (rule, value, callback) => {
          if (rule.required && !value) {
            callback(new Error(this.mlang('notNull')));
          }
        };

        rule.required = !!control.isRequired;
        // billId白名单, 位于白名单的billid不受业务选项（ADDRESSALLOWEDINCOMPLETE）的影响
        let whiteList = [];
        if (!this.$getBO('ADDRESSALLOWEDINCOMPLETE') && !whiteList.includes(control.sourceBillId)) {
          // 允许收货地址不完整
          rule.validator = (rule, value, callback) => {
            // 校验是否为空,若为空则给提示
            isNullValidator(rule, value, callback);
            if (!value) { callback(); return; }; // 内容为空则不进行下一步的校验,并对typeof null === 'object'的情况进行处理
            let address = [];
            let regionNames = ['area', 'city', 'province', 'country'];
            if (typeof value === 'object') {
              let idx = regionNames.length - 1;
              while (idx >= 0) { if (value[regionNames[idx]]) { address.push(value[regionNames[idx--]]); } else break; }
            } else {
              address = value ? value.split(/\s+/) : [];
            }
            let leftBorder = control.isNonShowCountry ? 1 : 0;
            let rightBorder = regionNames.length;
            if (address.length > leftBorder && address.length < rightBorder) {
              rule.message = '';
              callback(new Error(this.mlang('allowedAddressIncompleteTips')));
            }
            callback();
          };
        } else {
          rule.validator = (rule, value, callback) => { isNullValidator(rule, value, callback); callback(); };
        }
      }
      if (control.controlType === 'RegionSelect') {
        rule.trigger = 'select';
        rule.type = 'string';
        // 校验是否为空
        const isNullValidator = (rule, value, callback) => {
          if (rule.required && !value) {
            callback(new Error(this.mlang('notNull')));
          }
        };

        rule.required = !!control.isRequired;
        // billId白名单, 位于白名单的billid不受业务选项（ADDRESSALLOWEDINCOMPLETE）的影响
        let whiteList = [];
        if (!this.$getBO('ADDRESSALLOWEDINCOMPLETE') && !whiteList.includes(control.sourceBillId)) {
          // 允许收货地址不完整
          rule.validator = (rule, value, callback) => {
            // 校验是否为空,若为空则给提示
            isNullValidator(rule, value, callback);
            if (!value) { callback(); return; }; // 内容为空则不进行下一步的校验,并对typeof null === 'object'的情况进行处理
            callback();
          };
        } else {
          rule.validator = (rule, value, callback) => { isNullValidator(rule, value, callback); callback(); };
        }
      }
      if (bizMeta.rules && bizMeta.rules(control, rule)) {
        rule = bizMeta.rules(control, rule);
      }

      return rule;
    },
    mixin_getPropByPath(obj, path) {
      return this.$util.getPropByPath(obj, path);
    },
    mixin_numberFormat(column, row, value, needGlobalFormat) {
      let val = value;
      let { precisionFieldName, roundTypeFieldName } = column.formatDataObj;
      let precision = this.$util.getPropByPath(row, precisionFieldName).v;
      let roundType = this.$util.getPropByPath(row, roundTypeFieldName).v;
      val = this.$util.toFixed(value, precision, roundType, true);// 强制格式化2位展示，100.00
      // val = this.$util.addZero(val, precision);
      if (needGlobalFormat) {
        val = this.$util.formatNumber(val);
      }
      return val;
    },
    treeSelectNormalizer(node, ctrlName) {
      const ctrl = this.findControl(ctrlName);
      const valueKey = ctrl.valueKey || 'id';
      const nameKey = ctrl.nameKey || 'name';
      let parentId = this.formData && this.formData.parentId;
      const isDefaultExpanded = node[valueKey] === parentId;
      if (isDefaultExpanded && node.parent) {
        node.parent.isDefaultExpanded = true;
      }
      return {
        id: node[valueKey],
        label: node[nameKey],
        children: node.subNodes || node.children,
        isDefaultExpanded: isDefaultExpanded || undefined
      };
    }
  }
};

export default mixin;
