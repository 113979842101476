import { deepCopy } from '@/u-components/utils/assist';
import _ from 'lodash';
const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {},

  methods: {
    setPager() {
      if (this.cloneMeta.pagerSizeOpts) {
        this.pager.pageSizeOpts = this.cloneMeta.pagerSizeOpts;
        this.pager.pageSize = this.pager.pageSizeOpts[0];
      }
    },
    makeColumns() {
      try {
        let group = this.billMeta.currentTemplate.currentTplGroups.length === 1 ? this.billMeta.currentTemplate.currentTplGroups[0] : this.billMeta.currentTemplate.currentTplGroups.find((v) => {
          return v.groupType === 'Normal';
        });
        let columns = group.currentBillItems;
        if (this.bizMeta.setListColumns) {
          columns = this.bizMeta.setListColumns(columns);
        }
        const enableColumns = columns.filter((v) => {
          return v.isHidden !== true;
        });
        this.allMetaColumns = _.cloneDeep(columns);

        this.billColumns = deepCopy(enableColumns);
        this.setColumns(this.billColumns);
      } catch (error) {
        console.error('bill元数据格式错误'); //eslint-disable-line
      }
    },
    makeAllColumns() {
      try {
        let group = this.billMeta.currentTemplate.currentTplGroups.find((v) => {
          return v.groupType === 'HeaderAndBody';
        });
        let columns = group?.currentBillItems;
        if (this.bizMeta.setListColumns) {
          columns = this.bizMeta.setListColumns(columns);
        }
        const enableColumns = columns.filter((v) => {
          return v.isHidden !== true;
        });

        this.billColumns = deepCopy(enableColumns);
        this.setColumns(this.billColumns);
      } catch (error) {
        console.error('bill元数据格式错误'); //eslint-disable-line
      }
    },
    makeQueryschema() {
      let queryschemas;
      // if (!this.billMeta.currentFilter) {
      //   // this.queryschemaLoaded();
      //   return;
      // }
      try {
        this.billMeta.currentFilter = this.billMeta.currentFilter || {};
        queryschemas = deepCopy(this.billMeta.currentFilter.currentGroups || []);
      } catch (error) {
        console.error('filter元数据格式错误'); //eslint-disable-line
      }
      this.billQuerySchema = queryschemas;
      if (this.bizMeta.setVoucherListQuerySchema) {
        queryschemas = this.bizMeta.setVoucherListQuerySchema(queryschemas);
      }
      this.setQueryschema(queryschemas);
    },
    getQueryschemaData() {
      const schema = (this.$refs.queryschema && this.$refs.queryschema.getQueryschemaRealData()) || {};
      schema.pager = this.pager;
      return { querySchema: schema };
    },
    // 清空处理id变成0
    makeNullData(data) {
      if (_.isPlainObject(data)) {
        _.keys(data).map((key) => {
          const value = data[key];
          const control = this.findControl(key);
          if (control && value === null && data.id) {
            const controlType = control.controlType;
            if (['TreeSelect'].includes(controlType)) {
              data[key] = 0;
            }
          }
        });
      }
      // this.findControl()
    }
  }
};

export default mixin;
