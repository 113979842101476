<template>
  <div class="archive-list">
    <VoucherList
      ref="voucherList"
      :data="data"
      :query="query"
      :biz-meta="meta"></VoucherList>
  </div>
</template>

<script>
import VoucherList from '_c/common/voucher-list';
import ArchiveListMixin from '@/mixin/archive-list.js';
import Emitter from '@/u-components/mixins/emitter';
import { getSysNoticeSettings, delSysNoticeSettings } from '@/api/productNotice';

export default {
  name: 'ProductNotices',
  components: { VoucherList },
  mixins: [ArchiveListMixin, Emitter],
  data() {
    const meta = this.getDefaultMeta();
    return {
      data: {},
      query: this.queryList,
      meta,
      isLoaing: false,
      btnList: [
        {
          name: 'delete ',
          func: this.delete
        }
      ]
    };
  },
  created() {},
  methods: {
    getDefaultMeta() {
      const meta = {
        isShowFooter: true,
        billId: 160,
        tableMetaSetting: false,
        actions: {
          RowAction: this.noticeSettingAction
        },
        async: true
      };
      meta.addBtn = {
        route: {
          name: 'productNotice'
        },
        vue: true
      };

      return meta;
    },
    noticeSettingAction(item) {
      item.render = (h, params) => {
        let btnList = [
        ];
        btnList.push({
          name: 'edit',
          func: this.edit
        });
        btnList.push(
          {
            name: 'delete',
            func: this.del
          });
        if (btnList && btnList.length) {
          btnList.forEach((v) => {
            v.handleClick = () => {
              v.func(params.row);
            };
          });
          return h('div', { class: ['action'] }, [
            h(
              'Row',
              {
                Props: {
                  className: 'text',
                  type: 'flex',
                  justify: 'center'
                }
              },
              btnList.map((btn) => {
                let styleClass = ['rollBackBtn'];
                if (btn.className) {
                  styleClass.push(...btn.className);
                }
                return h(
                  'span',
                  {
                    class: styleClass,
                    on: { click: btn.handleClick }
                  },
                  this.mlang(btn.name)
                );
              })
            )
          ]);
        } else {
          return h('div', { class: ['action'] }, [
            h('Row', {
              Props: {
                className: 'text',
                type: 'flex',
                justify: 'center'
              }
            })
          ]);
        }
      };
      return item;
    },
    edit(row) {
      let routerObj = {
        name: 'productNotice',
        query: { id: row.id }
      };
      this.editClick(routerObj);
    },
    del(row) {
      const vm = this;
      this.$Modal.confirm({
        content: vm.mlang('sureDel'),
        className: 'modal-confrim',
        okText: vm.mlang('ok'),
        title: '',
        onOk: (res, instance) => {
          delSysNoticeSettings(row.id).then((res) => {
            vm.$Message.success(vm.mlang('operateSuccess'));
            vm.broadcast('VoucherList', 'reload');
          });
        }
      });
    },
    setMeta() {
      this.meta = this.getDefaultMeta();
    },
    queryList(query) {
      // PaymentSrv/getPaymentVoucherMoney
      getSysNoticeSettings(query).then((res) => {
        res.content &&
          res.content.forEach((v) => {
            let to = {};
            to = {
              name: 'noticesetting',
              query: { id: v.id }
            };
            v.titleTo = to;
          });
        this.data = res;
      });
    }
  }
};
</script>
