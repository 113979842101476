import mainView from '@/views/agent/agentCapital/ListTab.vue';
import payList from '@/views/agent/agentCapital/components/pays.vue';
import customerRefund from '@/views/agent/agentCapital/components/customerRefund.vue';
import summaryIframe from '@/views/agent/agentCapital/components/summary/summary-iframe.vue';
import rebateTab from '@/views/agent/agentCapital/components/rebates/rebateTab.vue';
import discountRebateList from '@/views/agent/agentCapital/components/rebates/discountRebates.vue';

const order = {
  path: 'agentCaptial',
  title: { i18n: 'agentCaptial' },
  name: 'AgentCaptial',
  component: mainView,
  children: [
    {
      path: 'pays',
      title: { i18n: 'title.pays' },
      name: 'Pays',
      component: payList,
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: 'customerRefund',
      title: { i18n: 'title.customerRefund' },
      name: 'CustomerRefund',
      component: customerRefund,
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: '/agent/agentCaptial/amountCountAccount',
      title: { i18n: 'title.amountCount' },
      name: 'amountCount',
      component: summaryIframe,
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: '/agent/agentCaptial/amountProductCount',
      title: { i18n: 'title.amountProduct' },
      name: 'amountProductCount',
      component: summaryIframe,
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: '/agent/agentCaptial/numberProductCount',
      title: { i18n: 'title.numberProduct' },
      name: 'numberProductCount',
      component: summaryIframe,
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: 'rebates',
      title: { i18n: 'title.rebates' },
      name: 'Rebates',
      component: rebateTab,
      children: [
        {
          path: 'discountRebates',
          title: { i18n: 'title.discountRebates' },
          name: 'DiscountRebates',
          component: discountRebateList,
          meta: { menuCode: 'agentCapital' }
        },
        {
          path: 'goodRebates',
          title: { i18n: 'title.goodRebates' },
          name: 'GoodRebates',
          component: () => import('@/views/agent/agentCapital/components/rebates/goodRebates.vue'),
          meta: { menuCode: 'agentCapital' }
        }
      ],
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: 'pointTransaction',
      title: { i18n: 'title.pointTransaction' },
      name: 'PointTransaction',
      component: () => import('@/views/agent/agentCapital/components/agentPoints.vue'),
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: 'coupon',
      title: { i18n: 'title.coupon' },
      name: 'Coupon',
      component: () => import('@/views/corp/customer/components/customer/coupon.vue'),
      meta: { menuCode: 'agentCapital' }
    },
    {
      path: 'credit',
      title: { i18n: 'title.credit' },
      name: 'Credit',
      component: () => import('@/views/agent/agentCapital/components/credit.vue'),
      meta: { menuCode: 'agentCapital' }
    }
  ]
};

export default order;
