export default {
  hasBeenSetted: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000295","已设置") /* "已设置" */,
  hasBeenNorSetted: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000296","未设置") /* "未设置" */,
  setting: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800036","设置") /* "设置" */,
  getInterfaceArguments: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000297","获取接口参数") /* "获取接口参数" */,
  getAPIInterfaceArguments: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000298","获取API接口参数") /* "获取API接口参数" */,
  warningTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000299","注意：停用API将导致旧API接口失效，需要重新获取并配置API参数") /* "注意：停用API将导致旧API接口失效，需要重新获取并配置API参数" */,
  disable: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500029A","停用") /* "停用" */,
  update: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500029B","修改") /* "修改" */,
  getFreeCaptcha: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500029C","获取免费验证码") /* "获取免费验证码" */,
  registeredSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500029D","注册成功！") /* "注册成功！" */,
  saveSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500029E","保存成功！") /* "保存成功！" */,
  operateSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500029F","操作成功！") /* "操作成功！" */,
  disableSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A0","停用成功！") /* "停用成功！" */,
  name: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A1","名字") /* "名字" */,
  arguments: lang.templateByUuid("UID:P_UDHWN_17DD50DE050002A2","参数") /* "参数" */
};
