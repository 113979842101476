export default {
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000501","条") /* "条" */,
  batchOpposeWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000502","请选择至少一条运费信息") /* "请选择至少一条运费信息" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000503","操作成功") /* "操作成功" */,
  fullPackageMail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000504","全场包邮") /* "全场包邮" */,
  unifiedPricing: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000505","统一计价") /* "统一计价" */,
  calculateQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000506","按件数计算") /* "按件数计算" */,
  calculateAmount: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000507","按金额计算") /* "按金额计算" */,
  rmbunit: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000508","元") /* "元" */,
  defaultBtn: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000509","默认") /* "默认" */
};
