export default {
  effect: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005CD","整单生效") /* "整单生效" */,
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005CE","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005CF","是否要当前的") /* "是否要当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005D0","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005D1","操作成功") /* "操作成功" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005D2","删除") /* "删除" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005D3","条") /* "条" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005D4","请选择需要批量操作的条目") /* "请选择需要批量操作的条目" */,
  effectting: lang.templateByUuid("UID:P_UDHWN_17DD50DE050005D5","生效中") /* "生效中" */
};
