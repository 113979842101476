<template>
  <div class="demension-box-bg">
    <div :class="(demensionType === 1 || demensionType === 8 || isPromotion || isAgentGroup) ? 'demension-select-class demension-select-class-shout':'demension-select-class'">
      <Select
        v-if="!demensionMeta.isOnlyAgent"
        v-model="demensionType"
        :disabled="demensionMeta.disabled"
        @on-change="optionChange">
        <Option
          v-for="option in demensionList"
          :key="option.value"
          :label="option.cName || option.title"
          :value="option.value"></Option>
      </Select>
      <Button
        v-if="(demensionType === 1 || demensionType === 8 || isPromotion || isAgentGroup) && !demensionMeta.isOnlyAgent"
        icon="plus"
        @click="selectAgentDimensions(demensionType)">
        {{ mlang('selectDataBtn') }}
      </Button>
      <Button
        v-if="demensionMeta.isOnlyAgent"
        icon="plus"
        @click="selectAgentDimensions()">
        {{ mlang('addExtAgent') }}
      </Button>
    </div>
    <FormItem
      v-if="demensionType !== 0"
      :label="showLabel ? mlang('weidumingcheng') : ''"
      :class="{'archive-item': true, 'archive-demension-item': true, 'margin-left-auto': !showLabel}">
      <TreeSelect
        v-if="demensionType === 2"
        v-model="selectIds"
        :instance-id="getId('class')"
        :options="selectOptionsArea"
        :flat="true"
        value-format="object"
        :auto-load-root-options="false"
        :no-results-text="mlang('noResults')"
        :no-options-text="mlang('noResults')"
        :loading-text="mlang('loading')"
        :clearable="true"
        :multiple="true"
        class-name="ivu-select"
        style="width:400px;display:inline-block;height:auto"
        placeholder="" />
      <TreeSelect
        v-if="demensionType === 3"
        v-model="selectIds"
        :instance-id="getId('area')"
        :options="selectOptionsClass"
        :flat="true"
        value-format="object"
        :auto-load-root-options="false"
        :no-results-text="mlang('noResults')"
        :no-options-text="mlang('noResults')"
        :loading-text="mlang('loading')"
        :clearable="true"
        :multiple="true"
        :value-consists-of="valueConsistsOf"
        class-name="ivu-select"
        style="width:400px;"
        placeholder="" />
      <!-- <Select
        v-if="demensionType === 4"
        v-model="selectIds"
        multiple
        placeholder=" "
        style="width:400px">
        <Option
          v-for="le in selectOptionsLevel"
          :key="le[demensionMeta.idName]"
          :label="le[demensionMeta.labelName]"
          :value="le[demensionMeta.idName]"></Option>
      </Select> -->
      <TreeSelect
        v-if="demensionType === 4"
        v-model="selectIds"
        :instance-id="getId('level')"
        :options="selectOptionsLevel"
        :flat="true"
        value-format="object"
        :auto-load-root-options="false"
        :no-results-text="mlang('noResults')"
        :no-options-text="mlang('noResults')"
        :loading-text="mlang('loading')"
        :clearable="true"
        :multiple="true"
        class-name="ivu-select"
        style="width:400px;display:inline-block"
        placeholder="" />
      <!-- <Select
        v-if="demensionType === 8 && !isPromotion"
        v-model="selectIds"
        multiple
        placeholder=" "
        style="width:400px">
        <Option
          v-for="le in selectOptionsGroup"
          :key="le[demensionMeta.idName]"
          :label="le[demensionMeta.labelName]"
          :value="le[demensionMeta.idName]"></Option>
      </Select> -->
      <!-- <TreeSelect
        v-if="demensionType === 8 && !isPromotion"
        v-model="selectIds"
        :instance-id="getId('group')"
        :options="selectOptionsGroup"
        :auto-load-root-options="false"
        :flat="true"
        value-format="object"
        :no-results-text="mlang('noResults')"
        :no-options-text="mlang('noResults')"
        :loading-text="mlang('loading')"
        :clearable="true"
        :multiple="true"
        class-name="ivu-select"
        style="width:400px;display:inline-block"
        placeholder="" /> -->
      <!--Select
        v-if="demensionType === 1"
        v-model="selectIds"
        multiple
        placeholder=" "
        style="width:400px">
        <Option
          v-for="le in selectOptionsAgent"
          :value="le[demensionMeta.idName]"
          :key="le[demensionMeta.idName]">{{ le[demensionMeta.labelName] }}</Option>
      </Select-->
      <div
        v-if="demensionType === 1"
        class="demension-agent-box ivu-select-multiple">
        <Tag
          v-for="(tag, index) in selectOptionsAgent"
          :key="tag[demensionMeta.idName]"
          type="border"
          closable
          @on-close="handleCloseTag(tag,index, 'agent')">
          {{ tag[demensionMeta.labelName] }}
        </Tag>
      </div>
      <div
        v-if="demensionType === 8 && demensionList.length < 8"
        class="demension-agent-box ivu-select-multiple">
        <Tag
          v-for="(tag, index) in selectOptionsGroup"
          :key="tag[demensionMeta.idName]"
          type="border"
          closable
          @on-close="handleCloseTag(tag,index,'agentGroup')">
          {{ tag[demensionMeta.labelName] }}
        </Tag>
      </div>
      <div
        v-if="(isPromotion && !demensionList.length < 8) || isAgentGroup"
        class="demension-agent-box ivu-select-multiple">
        <Tag
          v-for="(tag, index) in promotion[demensionType]"
          :key="index"
          type="border"
          closable
          @on-close="handleClosePromotionTag(tag, index, promotion[demensionType])">
          {{ tag[demensionMeta.labelName] }}
        </Tag>
      </div>
    </FormItem>
    <SelectAgent
      ref="selectAgent"
      :multiple="multiple"
      :before-query="selectAgentBeforeQuery"
      :is-channel-agent="isChannelAgent"
      @on-selected="onSelectedAgent"></SelectAgent>
    <SelectProductPromotion
      ref="selectProductPromotion"
      style="display:none;"
      :multiple="isPromotionMultiple"
      @selected="onSelectedPromotion"></SelectProductPromotion>
    <SelectEntirePromotion
      ref="selectEntirePromotion"
      style="display:none;"
      :multiple="isPromotionMultiple"
      @selected="onSelectedPromotion"></SelectEntirePromotion>
    <SelectCombinationPromotion
      ref="selectCombinationPromotion"
      style="display:none;"
      :multiple="isPromotionMultiple"
      @selected="onSelectedPromotion"></SelectCombinationPromotion>
    <SelectAgentGroup
      ref="selectAgentGroup"
      style="display:none;"
      :multiple="true"
      @on-selected="onSelectedPromotion"></SelectAgentGroup>
  </div>
</template>

<script>
import ajax from '@/libs/ajax';
import CommonMixin from '@/mixin/common.js';
import Mixin from '@/mixin/archiveList.js';
import { deepCopy } from '@/u-components/utils/assist';
import { getAgentCategorysTree, getAgentAreasTree, getAgentLevel } from '@/api/refer-tree';
import SelectAgent from '@/components/select-agent/select-agent.vue';
import SelectProductPromotion from '_c/select/productPromotion';
import SelectEntirePromotion from '_c/select/entirePromotion';
import SelectCombinationPromotion from '_c/select/combinationPromotion';
import SelectAgentGroup from '@/components/select-agentGroup/select-agentGroup.vue';
export default {
  name: 'ReferTable',
  components: { SelectAgent, SelectProductPromotion, SelectEntirePromotion, SelectCombinationPromotion, SelectAgentGroup },
  mixins: [CommonMixin, Mixin],
  props: {
    demensionMeta: {
      required: true,
      type: Object
    },
    currentData: {
      required: true,
      type: Object
    },
    isChannelAgent: {
      type: Boolean
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    valueConsistsOf: {
      type: String,
      default: 'BRANCH_PRIORITY'
    },
    selectData: {
      type: Object
    },
    isPromotionMultiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      multiple: true,
      copyOptions: {},
      demensionList: [],
      isLoad: false,
      demensionType: 1,
      selectOptionsArea: [],
      selectOptionsClass: [],
      selectOptionsLevel: [],
      selectOptionsAgent: [],
      selectOptionsGroup: [],
      promotion: {
        7: [], // selectOptionsProductPromotion 商品促销
        8: [], // selectOptionsEntirePromotion 整单促销
        9: [], // selectOptionsCombinationPromotion 组合促销
        10: [] // selectAgentGroup  客户分组
      },
      selectIds: [],
      isOnce: true,
      computedDemensionMeta: this.demensionMeta,
      computedCurrentData: this.currentData,
      dimensionTypeRefMap: {
        1: 'selectAgent', // 客户
        7: 'selectProductPromotion', // 商品促销
        8: 'selectEntirePromotion', // 整单促销
        9: 'selectCombinationPromotion', // 组合促销
        10: 'selectAgentGroup' // 客户分组
      },
      promotionEnum: ['ProductPromotion', 'EntirePromotion', 'CombinationPromotion']
    };
  },
  computed: {
    address() {
      const { computedDemensionMeta, computedCurrentData } = this;
      return {
        computedDemensionMeta,
        computedCurrentData
      };
    },
    isPromotion() {
      let item = this.demensionList.find((v) => {
        return v.value === this.demensionType;
      });
      if (item !== undefined) {
        return this.promotionEnum.includes(item.name);
      } else {
        return false;
      }
    },
    isAgentGroup() {
      return this.demensionType === 10;
    }
  },
  watch: {
    // computed同时监听两个变量有问题，改为分别监听
    currentData: {
      handler(newVal, oldVal) {
        if (this.$router.currentRoute.query.titleType && this.$router.currentRoute.query.titleType === 'add') {
          if (this.isOnce && this.demensionMeta.enumArray && this.demensionMeta.enumArray.length > 0) {
            this.isOnce = false;
            this.setData();
          }
        } else {
          if (this.isOnce && this.currentData.version !== undefined && this.demensionMeta.enumArray && this.demensionMeta.enumArray.length > 0) {
            this.isOnce = false;
            this.setData();
          }
        }
      },
      deep: true
    },
    demensionMeta: {
      handler(newVal, oldVal) {
        if (this.$router.currentRoute.query.titleType && this.$router.currentRoute.query.titleType === 'add') {
          if (this.isOnce && this.computedDemensionMeta.enumArray && this.computedDemensionMeta.enumArray.length > 0) {
            this.isOnce = false;
            this.setData();
          }
        } else {
          if (this.isOnce && this.currentData.version !== undefined && this.computedDemensionMeta.enumArray && this.computedDemensionMeta.enumArray.length > 0) {
            this.isOnce = false;
            this.setData();
          }
        }
      },
      deep: true
    },
    selectIds: {
      handler(val, oldval) {
        let vm = this;
        let selectOption;
        if (this.isLoad) {
          selectOption = this.getSelectOption();
          this.$emit('demension-change-event', this.demensionMeta, this.demensionType, selectOption);
        } else {
          ajax.all([getAgentCategorysTree(), getAgentLevel(), getAgentAreasTree()]).then(
            ajax.spread(function(optionsClass, optionsLevel, optionsArea) {
              vm.isLoad = true;
              vm.formatTree(optionsArea);
              vm.copyOptions.selectOptionsArea = optionsArea;
              vm.selectOptionsArea = optionsArea;
              vm.formatTree(optionsClass);
              vm.copyOptions.selectOptionsClass = optionsClass;
              vm.selectOptionsClass = optionsClass;
              vm.formatTree(optionsLevel);
              vm.copyOptions.selectOptionsLevel = optionsLevel;
              vm.selectOptionsLevel = optionsLevel;
              selectOption = vm.getSelectOption();
              vm.$emit('demension-change-event', vm.demensionMeta, vm.demensionType, selectOption);
            })
          );
        }
        // } else {
        // let selectOption = this.getCurrentSelectOption();
        // this.$emit('demension-change-event', this.demensionMeta, this.demensionType, selectOption);
        // }
      }
    },
    address: {
      handler(val, oldval) {
        if (this.$router.currentRoute.query.titleType && this.$router.currentRoute.query.titleType === 'add') {
          if (this.isOnce && val.computedDemensionMeta.enumArray && val.computedDemensionMeta.enumArray.length > 0) {
            this.isOnce = false;
            this.setData();
          }
        } else {
          if (this.isOnce && val.computedCurrentData.version !== undefined && val.computedDemensionMeta.enumArray && val.computedDemensionMeta.enumArray.length > 0) {
            this.isOnce = false;
            this.setData();
          }
        }
      },
      immediate: true,
      deep: true
    },
    isChannelAgent: {
      handler(val, oldval) {
        // 第一次加载的时候，isChannelAgent为undefined，此时不执行清空数组的操作
        if (oldval !== undefined) {
          this.selectOptionsAgent.splice(0, this.selectOptionsAgent.length);
        }
      }
    }
  },
  created() {
    const vm = this;
    if (this.selectData) {
      vm.isLoad = true;
      vm.copyOptions.selectOptionsArea = this.selectData.selectOptionsArea;
      vm.selectOptionsArea = this.selectData.selectOptionsArea;
      vm.copyOptions.selectOptionsClass = this.selectData.selectOptionsClass;
      vm.selectOptionsClass = this.selectData.selectOptionsClass;
      vm.copyOptions.selectOptionsLevel = this.selectData.selectOptionsLevel;
      vm.selectOptionsLevel = this.selectData.selectOptionsLevel;
    }
    if (!this.isLoad) {
      ajax.all([getAgentCategorysTree(), getAgentLevel(), getAgentAreasTree()]).then(
        ajax.spread(function(optionsClass, optionsLevel, optionsArea, optionsGroup) {
          vm.isLoad = true;
          vm.formatTree(optionsArea);
          vm.copyOptions.selectOptionsArea = optionsArea;
          vm.selectOptionsArea = optionsArea;
          vm.formatTree(optionsClass);
          vm.copyOptions.selectOptionsClass = optionsClass;
          vm.selectOptionsClass = optionsClass;
          vm.formatTree(optionsLevel);
          vm.copyOptions.selectOptionsLevel = optionsLevel;
          vm.selectOptionsLevel = optionsLevel;
        })
      );
    }
  },
  mounted() {
  },
  methods: {
    test() {

    },
    getId(type) {
      let id = this.demensionMeta.fieldName + '_' + type + '_' + new Date().getTime();
      if (this.demensionMeta.keyId) {
        id = id + '_' + this.demensionMeta.keyId;
      }
      return id;
    },
    setDataAdd() {
      if (this.demensionMeta.mustSelect) {
        this.demensionList = this.demensionMeta.enumArray;
      } else {
        this.demensionList = [
          {
            cName: this.mlang('all'),
            value: 0
          }
        ];
        this.demensionList = this.demensionList.concat(this.demensionMeta.enumArray);
      }
      if (this.currentData[this.demensionMeta.demensionTypeName]) {
        this.demensionType = this.currentData[this.demensionMeta.demensionTypeName];
      } else {
        this.demensionType = this.demensionMeta.mustSelect ? 1 : 0;
      }
      var selectOption = this.getCurrentSelectOption();
      this.$emit('demension-change-event', this.demensionMeta, this.demensionType, selectOption);
    },
    setData() {
      if (this.demensionMeta.mustSelect) {
        this.demensionList = this.demensionMeta.enumArray;
      } else {
        this.demensionList = [
          {
            cName: this.mlang('all'),
            value: 0
          }
        ];
        this.demensionList = this.demensionList.concat(this.demensionMeta.enumArray);
      }
      let copyData = deepCopy(this.currentData);
      if (this.currentData[this.demensionMeta.demensionTypeName]) {
        this.demensionType = copyData[this.demensionMeta.demensionTypeName];
      } else {
        if (Array.isArray(copyData[this.demensionMeta.demensionsName]) && copyData[this.demensionMeta.demensionsName][0] && copyData[this.demensionMeta.demensionsName][0][this.demensionMeta.demensionTypeName]) {
          this.demensionType = copyData[this.demensionMeta.demensionsName][0][this.demensionMeta.demensionTypeName];
        } else {
          this.demensionType = this.demensionMeta.mustSelect ? 1 : 0;
        }
      }
      if (copyData[this.demensionMeta.demensionsName] && copyData[this.demensionMeta.demensionsName].length > 0) {
        this.selectIds = [];
        copyData[this.demensionMeta.demensionsName].forEach((value) => {
          if (this.demensionType === 1) {
            value['labelName'] = value.referName;
            value['label'] = value[this.demensionMeta.labelName];
            this.selectOptionsAgent.push(value);
          } else if (this.demensionType === 8 && this.demensionList.length < 8) {
            value['labelName'] = value.referName;
            value['label'] = value[this.demensionMeta.labelName];
            this.selectOptionsGroup.push(value);
          } else if (this.isPromotion || this.isAgentGroup) {
            value['labelName'] = value.referName;
            value['label'] = value[this.demensionMeta.labelName];
            this.promotion[this.demensionType].push(value);
          }
          this.selectIds.push({
            id: value[this.demensionMeta.idName],
            label: value[this.demensionMeta.labelName]
          });
        });
      } else {
        this.selectIds = [];
      }
      var selectOption = this.getCurrentSelectOption();
      copyData = null;
      this.$emit('demension-change-event', this.demensionMeta, this.demensionType, selectOption);
    },
    onSelectedAgent(arr) {
      arr.map((v) => {
        v[this.demensionMeta.idName] = v.id;
        v[this.demensionMeta.labelName] = v.name;
        v['label'] = v.name;
      });
      arr.forEach((value) => {
        let obj = this.selectIds.find((v) => {
          return v.id === value.id;
        });
        if (!obj) {
          this.selectIds.push({
            id: value.id,
            label: value.name
          });
          this.selectOptionsAgent.push(value);
        }
      });
    },
    handleCloseTag(tag, index, type) {
      for (let i = 0; i < this.selectIds.length; i++) {
        if (this.selectIds[i].id === tag[this.demensionMeta.idName]) {
          this.selectIds.splice(i, 1);
        }
      }
      if (type === 'agent') {
        this.selectOptionsAgent.splice(index, 1);
      } else {
        this.selectOptionsGroup.splice(index, 1);
      }
    },
    handleClosePromotionTag(tag, index, arr) {
      for (let i = 0; i < this.selectIds.length; i++) {
        if (this.selectIds[i].id === tag[this.demensionMeta.idName]) {
          this.selectIds.splice(i, 1);
        }
      }
      arr.splice(index, 1);
    },
    // 点击选择数据按钮
    selectAgentDimensions(demensionType = 1) {
      // 目前没法判断demensionType === 8到底是整单还是各户分组  用枚举数量判断 小于8是分组
      if (this.demensionList.length < 8 && demensionType === 8) {
        this.$refs['selectAgentGroup'].showRefModal();
      } else {
        this.$refs[this.dimensionTypeRefMap[demensionType]].showRefModal();
      }
    },
    onSelectedPromotion(arr) {
      if (!Array.isArray(arr)) {
        let index = this.selectIds.findIndex((v) => {
          return v.id === arr.id;
        });
        if (index > -1) {
          this.selectIds.splice(index, 1);
        }
        this.promotion[this.demensionType] = [];
        arr = [arr];
      }
      arr.map((v) => {
        v[this.demensionMeta.idName] = v.id;
        v[this.demensionMeta.labelName] = v.name;
        v['label'] = v.name;
        return arr;
      });
      arr.forEach((value) => {
        let obj = this.selectIds.find((v) => {
          return v.id === value.id;
        });
        if (!obj) {
          this.selectIds.push({
            id: value.id,
            label: value.name
          });
          if (this.demensionList.length < 8) {
            this.selectOptionsGroup.push(value);
          } else {
            this.promotion[this.demensionType].push(value);
          }
        }
      });
    },
    optionChange(item) {
      this.selectIds = [];
      this.selectOptionsArea = deepCopy(this.copyOptions.selectOptionsArea);
      this.selectOptionsClass = deepCopy(this.copyOptions.selectOptionsClass);
      this.selectOptionsLevel = deepCopy(this.copyOptions.selectOptionsLevel);
      let selectOption = this.getSelectOption();
      this.$emit('demension-change-event', this.demensionMeta, this.demensionType, selectOption);
    },
    formatTree(arr) {
      for (let i = 0; i < arr.length; i++) {
        arr[i][this.demensionMeta.idName] = arr[i].id;
        arr[i][this.demensionMeta.labelName] = arr[i].name;
        arr[i]['label'] = arr[i].name;
        if (arr[i].subNodes) {
          arr[i]['children'] = arr[i].subNodes;
          this.formatTree(arr[i]['children']);
        }
      }
    },
    // 打平树形结构
    getAllClass(all, arr) {
      for (let i = 0; i < arr.length; i++) {
        all.push(arr[i]);
        if (arr[i].subNodes) {
          this.getAllClass(all, arr[i].subNodes);
        }
      }
    },
    getCurrentSelectOption() {
      var resultOptions = [];
      let copyData = deepCopy(this.currentData);
      if (this.selectIds.length > 0 && copyData[this.demensionMeta.demensionsName] && copyData[this.demensionMeta.demensionsName].length > 0) {
        copyData[this.demensionMeta.demensionsName] = copyData[this.demensionMeta.demensionsName].filter((v) => {
          return v.id;
        });
        for (let n = 0; n < copyData[this.demensionMeta.demensionsName].length; n++) {
          copyData[this.demensionMeta.demensionsName][n].actionStatus = 'U';
          copyData[this.demensionMeta.demensionsName][n][this.demensionMeta.demensionTypeName] = this.demensionType;
          resultOptions.push(copyData[this.demensionMeta.demensionsName][n]);
        }
      }
      copyData = null;
      return resultOptions;
    },
    getSelectOption() {
      var options = [];
      var resultOptions = [];
      if (this.demensionType === 1) {
        for (let n = 0; n < this.selectIds.length; n++) {
          for (let m = 0; m < this.selectOptionsAgent.length; m++) {
            if (this.selectIds[n].id === this.selectOptionsAgent[m][this.demensionMeta.idName]) {
              options.push(this.selectOptionsAgent[m]);
            }
          }
        }
      } else if (this.demensionType === 4) {
        for (let n = 0; n < this.selectIds.length; n++) {
          for (let m = 0; m < this.selectOptionsLevel.length; m++) {
            if (this.selectIds[n].id === this.selectOptionsLevel[m][this.demensionMeta.idName]) {
              options.push(this.selectOptionsLevel[m]);
            }
          }
        }
      } else if (this.demensionType === 8 && (!this.isPromotion || this.demensionList.length < 8)) {
        for (let n = 0; n < this.selectIds.length; n++) {
          for (let m = 0; m < this.selectOptionsGroup.length; m++) {
            if (this.selectIds[n].id === this.selectOptionsGroup[m][this.demensionMeta.idName]) {
              options.push(this.selectOptionsGroup[m]);
            }
          }
        }
      } else if (this.demensionType === 2) {
        let allArea = [];
        this.getAllClass(allArea, this.selectOptionsArea);
        for (let n = 0; n < this.selectIds.length; n++) {
          for (let m = 0; m < allArea.length; m++) {
            if (this.selectIds[n].id === allArea[m][this.demensionMeta.idName]) {
              options.push(allArea[m]);
            }
          }
        }
      } else if (this.demensionType === 3) {
        let allClass = [];
        this.getAllClass(allClass, this.selectOptionsClass);
        for (let n = 0; n < this.selectIds.length; n++) {
          for (let m = 0; m < allClass.length; m++) {
            if (this.selectIds[n].id === allClass[m][this.demensionMeta.idName]) {
              options.push(allClass[m]);
            }
          }
        }
      } else if (this.isPromotion || this.isAgentGroup) {
        let promotionList = this.promotion[this.demensionType];
        for (let n = 0; n < this.selectIds.length; n++) {
          for (let m = 0; m < promotionList.length; m++) {
            if (this.selectIds[n].id === promotionList[m][this.demensionMeta.idName]) {
              options.push(promotionList[m]);
            }
          }
        }
      }
      options.map((v) => {
        v[this.demensionMeta.demensionTypeName] = this.demensionType;
        if (v.actionStatus) {
          delete v.actionStatus;
        }
        return options;
      });
      options = deepCopy(options);
      let copyData = deepCopy(this.currentData);
      if (this.selectIds.length > 0 && copyData[this.demensionMeta.demensionsName] && copyData[this.demensionMeta.demensionsName].length > 0) {
        copyData[this.demensionMeta.demensionsName] = copyData[this.demensionMeta.demensionsName].filter((v) => {
          return v.id;
        });
        for (let n = 0; n < copyData[this.demensionMeta.demensionsName].length; n++) {
          let isDelete = true;
          for (let m = 0; m < options.length; m++) {
            if (options[m][this.demensionMeta.idName] === copyData[this.demensionMeta.demensionsName][n][this.demensionMeta.idName]) {
              isDelete = false;
              options[m].actionStatus = 'D';
              break;
            }
          }
          if (isDelete) {
            copyData[this.demensionMeta.demensionsName][n].actionStatus = 'D';
            resultOptions.push(copyData[this.demensionMeta.demensionsName][n]);
          } else {
            let id = copyData[this.demensionMeta.demensionsName][n].referId;
            let item = options.find((v) => { return v.id === id; });
            if (item !== undefined) {
              copyData[this.demensionMeta.demensionsName][n].label = item.label;
              copyData[this.demensionMeta.demensionsName][n].name = item.name;
              copyData[this.demensionMeta.demensionsName][n].referName = item.referName;
            }
            copyData[this.demensionMeta.demensionsName][n].actionStatus = 'U';
            resultOptions.push(copyData[this.demensionMeta.demensionsName][n]);
          }
        }
        options.forEach((value) => {
          if (!value.actionStatus) {
            value.actionStatus = 'C';
            if (value.id) {
              delete value.id;
            }
            resultOptions.push(value);
          }
        });
      } else if (this.selectIds.length <= 0 && copyData[this.demensionMeta.demensionsName] && copyData[this.demensionMeta.demensionsName].length > 0) {
        copyData[this.demensionMeta.demensionsName] = copyData[this.demensionMeta.demensionsName].filter((v) => {
          return v.id;
        });
        copyData[this.demensionMeta.demensionsName].forEach((value) => {
          value.actionStatus = 'D';
          resultOptions.push(value);
        });
      } else if (this.selectIds && (!copyData[this.demensionMeta.demensionsName] || copyData[this.demensionMeta.demensionsName].length <= 0)) {
        options.forEach((value) => {
          value.actionStatus = 'C';
          if (value.id) {
            delete value.id;
          }
          resultOptions.push(value);
        });
      } else {
        resultOptions = [];
      }
      copyData = null;
      return resultOptions;
    },
    selectAgentBeforeQuery(query) {
      // 用于控制客户参照只参照出已认证的客户
      let isOnlyCertifiedAgent = this.demensionMeta.isOnlyCertifiedAgent;
      if (isOnlyCertifiedAgent && query && Array.isArray(query.conditions)) {
        query.conditions.push({
          name: 'agent.verified',
          v1: true,
          valueType: 'BOOLEAN',
          conditionType: 'eq'
        });
      }
      return query;
    }
  }
};
</script>

<style lang="less" scoped>
  .archive-demension-item{
    margin-left: -120px;
    display: flex;
    margin-top:16px;
  }
  .demension-select-class{
    width:400px;
  }
  .ivu-tag-border.ivu-tag-closable:after {
    display: none;
  }
  .demension-box-bg /deep/ .ivu-select-arrow{
    top:13px;
  }
</style>
