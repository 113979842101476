export default {
  update: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000D9E","修改") /* "修改" */,
  change: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000D9F","变更") /* "变更" */,
  backPay: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA0","弃审") /* "弃审" */,
  verification: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA1","核销") /* "核销" */,
  delContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA2","确定要删除该付款记录吗?") /* "确定要删除该付款记录吗?" */,
  backPayContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA3","确定要弃审该付款记录吗?") /* "确定要弃审该付款记录吗?" */,
  opposeContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA4","驳回原因") /* "驳回原因" */,
  opposeTip: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA5","请填写驳回原因") /* "请填写驳回原因" */,
  opposeTitle: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA4","驳回原因") /* "驳回原因" */,
  opposeError: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA5","请填写驳回原因") /* "请填写驳回原因" */,
  sureContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA6","请在收到款项后进行确认!") /* "请在收到款项后进行确认!" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA7","请选择付款记录") /* "请选择付款记录" */,
  batchSureWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA8","请选择至少一条待确认的付款记录") /* "请选择至少一条待确认的付款记录" */,
  batchBackPayWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DA9","请选择至少一条可弃审的付款记录") /* "请选择至少一条可弃审的付款记录" */,
  batchDelPayWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DAA","请选择至少一条可删除的付款记录") /* "请选择至少一条可删除的付款记录" */,
  batchSureContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DAB","是否要对选中的") /* "是否要对选中的" */,
  batchSureContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DAC","条付款记录执行确认吗？") /* "条付款记录执行确认吗？" */,
  batchBackPayContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DAB","是否要对选中的") /* "是否要对选中的" */,
  batchBackPayContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DAD","条付款记录执行弃审吗？") /* "条付款记录执行弃审吗？" */,
  batchBackPayContentD: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DAE","条付款记录执行删除吗？") /* "条付款记录执行删除吗？" */,
  batchSureSuccessA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DAF","批量确认成功") /* "批量确认成功" */,
  batchSureSuccessB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB0","条付款记录！") /* "条付款记录！" */,
  batchBackPaySuccessA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB1","批量弃审成功") /* "批量弃审成功" */,
  batchBackPaySuccessB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB0","条付款记录！") /* "条付款记录！" */,
  batchOperationFail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000DB2","批量操作失败！") /* "批量操作失败！" */
};
