export default {
  feeItemName: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500005E","费用项目") /* "费用项目" */,
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500005F","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000060","是要对当前的") /* "是要对当前的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000061","记录执行操作吗？") /* "记录执行操作吗？" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000062","操作成功") /* "操作成功" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000063","条") /* "条" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000064","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000065","删除") /* "删除" */,
  confirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000066","启用") /* "启用" */,
  disConfirmIt2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000067","停用") /* "停用" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000068","查看") /* "查看" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000069","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500006A","查看执行情况") /* "查看执行情况" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500006B","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  handleBatchDeleteWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500006C","没有权限操作该平台的促销") /* "没有权限操作该平台的促销" */,
  opposeReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500006D","驳回原因") /* "驳回原因" */
};
