import deliveryMethods from './deliveryMethodList';
import expenseOrderTypes from './expenseOrderTypeList';
import settlementMethod from './settlementMethod';
import logisticsCompanys from './logisticsList';
import freightTemplates from './freightTemplateList';
import freightTemplate from './freightTemplate';
export default {
  basicInfo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000046","基本信息") /* "基本信息" */,
  deliveryMethods,
  expenseOrderTypes,
  logisticsCompanys,
  freightTemplates,
  freightTemplate,
  settlementMethod
};
