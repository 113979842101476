import { deepCopy } from '@/u-components/utils/assist';
import { getFellItemData } from '@/api/refer-tree';

const fellItemRefer = (ctrl, vm) => {
  let simpleVOsData = [];
  function getfillId() {
    let fillUrl = window.location.href;
    if (fillUrl.indexOf('?') > -1) {
      let paramArrays = fillUrl.split('?')[1];
      let feeItemIndex = paramArrays.indexOf('feeItemSet');
      if (feeItemIndex !== -1) {
        let feeItemData = paramArrays.slice(feeItemIndex);
        let feelItemString = feeItemData.split('=')[1];
        if (feelItemString) {
          let feelItemStringIdArr = feelItemString.split(',');
          return feelItemStringIdArr;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  };
  var idArray = getfillId();
  if (idArray && idArray.length) {
    simpleVOsData.push({
      field: 'id',
      op: 'in',
      value1: idArray
    });
  };
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: true,
      bRefer: true,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'id',
      refName: 'name',

      queryschema: [{
        name: 'name',
        controlType: 'String',
        groupType: 2,
        valueType: 'STRING',
        isShowIt: true,
        placeholder: vm.mlang('proName'),
        conditionType: 'like'
      }],
      passedparams: [{ simpleVOsData }],
      columns: [
        {
          showCaption: vm.mlang('proName'), // 名称
          fieldName: 'name',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('expenseItemtypeName'), // 费用项目类型
          isShowIt: true,
          fieldName: 'expenseitemtype_name',
          width: 120
        },
        {
          showCaption: vm.mlang('accentityName'), // 组织名
          isShowIt: true,
          fieldName: 'accentity_name',
          width: 120
        },
        {
          showCaption: vm.mlang('proCode'), // 编码
          isShowIt: false,
          fieldName: 'code',
          width: 120
        },
        {
          showCaption: vm.mlang('creator'), // 创建人
          isShowIt: true,
          fieldName: 'creator',
          width: 120
        }
      ],
      actions: { queryList: getFellItemData }
    },
    ctrl
  );

  return deepCopy(control);
};
export default fellItemRefer;
