const orders = {
  path: 'aorder',
  name: 'aorder',
  meta: {
    menuCode: 'agentOrderShow'
  },
  component: () => import('@/views/agent/orders/components/order/order.vue')
};

export default orders;
