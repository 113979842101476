const delivery = {
  state: {
    pickStockModalShow: false,
    allStock: [],
    defaultStockId: '',
    temporaryDeliveryId: ''
  },
  getters: {
  },
  mutations: {
    setPickStockModalShow(state, res) {
      state.pickStockModalShow = res;
    },
    setAllStock(state, res) {
      state.allStock = res;
    },
    setDefaultStockId(state, res) {
      state.defaultStockId = res
    },
    setTemporaryDeliveryId(state, res) {
      state.temporaryDeliveryId  = res
    }
  },
  actions: {
  }
};

export default delivery;
