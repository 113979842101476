export default {
  activityName: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000005","活动名称") /* "活动名称" */,
  activityDate: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000006","活动时间") /* "活动时间" */,
  creator: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000007","制单人") /* "制单人" */,
  marketName: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000008","营销方案名称") /* "营销方案名称" */,
  themeName: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000009","营销活动名称") /* "营销活动名称" */,
  entireType: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500000A","促销类型") /* "促销类型" */,
  promotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500000B","活动分类") /* "活动分类" */,
  activityRange: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500000C","商品范围") /* "商品范围" */,
  calculateRule: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500000D","促销条件") /* "促销条件" */,
  productSettingRange: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500000E","商品设置范围") /* "商品设置范围" */,
  whiteList: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500000F","白名单") /* "白名单" */,
  blackList: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000010","黑名单") /* "黑名单" */,
  whiteListProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000011","白名单商品") /* "白名单商品" */,
  blackListProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000012","黑名单商品") /* "黑名单商品" */,
  moneyRange: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000013","金额范围") /* "金额范围" */,
  moneyRange1: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000014","整单金额不含运费") /* "整单金额不含运费" */,
  moneyRange2: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000015","整单金额包含运费") /* "整单金额包含运费" */,
  mutexActivities: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000016","互斥条件") /* "互斥条件" */,
  isGiveawayShow: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000017","赠品金额处理") /* "赠品金额处理" */,
  giveawayRule: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000018","赠品计算依据") /* "赠品计算依据" */,
  pleaseSetDimenssion: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000019","请正确设置维度") /* "请正确设置维度" */,
  isCalcWithDisCountShow: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500001A","赠品数量计算") /* "赠品数量计算" */,
  isCalcWithDisCountShow1: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500001B","满足条件后完全按比例赠送") /* "满足条件后完全按比例赠送" */,
  isEnableMaxCount: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500001C","最大优惠次数") /* "最大优惠次数" */,
  isEnableMaxCount1: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500001D","开启") /* "开启" */,
  isEnableMaxCount2: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500001E","每客户享受本促销最多") /* "每客户享受本促销最多" */,
  isEnableMaxCount3: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500001F","次") /* "次" */,
  dimension: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000020","促销维度") /* "促销维度" */,
  promotionItems: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000021","优惠方式") /* "优惠方式" */,
  entireTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000022","整单促销") /* "整单促销" */,
  entireTypes2: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000023","叠加优惠") /* "叠加优惠" */,
  promotionTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000024","打折活动") /* "打折活动" */,
  promotionTypes2: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000025","减价活动") /* "减价活动" */,
  promotionTypes3: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000026","赠送商品") /* "赠送商品" */,
  activityRanges0: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000027","全部商品") /* "全部商品" */,
  activityRanges1: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000028","部分商品") /* "部分商品" */,
  discountTypes0: lang.templateByUuid("UID:P_UDHWN_17DD50DC05000029","折前优惠") /* "折前优惠" */,
  discountTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500002A","折后优惠") /* "折后优惠" */,
  calculateRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500002B","满件") /* "满件" */,
  calculateRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500002C","满额") /* "满额" */,
  calculateRules3: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500002D","开票类型") /* "开票类型" */,
  mutexActivities1: lang.templateByUuid("UID:P_UDHWN_17DD50DC0500002E","整单促销(针对金额促销)") /* "整单促销(针对金额促销)" */,
  mutexActivities2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000004","整单促销(针对买赠促销)") /* "整单促销(针对买赠促销)" */,
  mutexActivities3: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000005","商品促销(针对金额促销)") /* "商品促销(针对金额促销)" */,
  mutexActivities4: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000006","商品促销(针对买赠促销)") /* "商品促销(针对买赠促销)" */,
  isGiveawayShow1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000007","赠品金额强制为0") /* "赠品金额强制为0" */,
  isGiveawayShow2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000008","赠品参与分摊") /* "赠品参与分摊" */,
  giveawayRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000009","赠一定金额的赠品") /* "赠一定金额的赠品" */,
  giveawayRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500000A","赠一定数量的赠品") /* "赠一定数量的赠品" */,
  productProperty: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500000B","商品属性") /* "商品属性" */,
  jiliangdanwei: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500000C","计量单位") /* "计量单位" */,
  salePrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500000D","销售价格") /* "销售价格" */,
  tiaojianmanjian: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500000E","条件（批发计价单位）") /* "条件（批发计价单位）" */,
  tiaojianmane: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500000F","条件（满额）") /* "条件（满额）" */,
  disrate: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000010","扣率%") /* "扣率%" */,
  zengpin: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000011","赠品") /* "赠品" */,
  giveawayMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000012","赠品金额") /* "赠品金额" */,
  xianzhi: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000013","限制") /* "限制" */,
  isCapping: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000014","上不封顶") /* "上不封顶" */,
  jianjia: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000015","减价") /* "减价" */,
  giveawayNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000016","赠品计价数量") /* "赠品计价数量" */,
  selectErrorMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000017","至少选择一条优惠!") /* "至少选择一条优惠!" */,
  pleaseWriteOk: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000018","请正确填写!") /* "请正确填写!" */,
  selectDefaultProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000019","选择默认商品") /* "选择默认商品" */,
  pleaseSelectProductGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500001A","请选择商品分组") /* "请选择商品分组" */,
  productGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500001B","商品分组") /* "商品分组" */,
  selectDefaultProductClass: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500001C","选择商品分类") /* "选择商品分类" */,
  youhui: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500001D","优惠") /* "优惠" */,
  singleDiscountShowMessageError1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500001E","的优惠价格必须大于优惠") /* "的优惠价格必须大于优惠" */,
  singleDiscountShowMessageError2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500001F","的优惠价格") /* "的优惠价格" */,
  repeatProducthasNO: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000020","重复商品已被忽略") /* "重复商品已被忽略" */,
  isEnableMaxPreferential: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000021","最大优惠量") /* "最大优惠量" */,
  maxPreferentialRules3: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000022","赠品最大优惠计价数量") /* "赠品最大优惠计价数量" */,
  maxPreferentialRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000023","主商品最大优惠金额") /* "主商品最大优惠金额" */,
  maxPreferentialRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000024","赠品最大优惠量") /* "赠品最大优惠量" */,
  maxPreferentialRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000025","最大优惠金额") /* "最大优惠金额" */,
  zongshuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000026","总数量") /* "总数量" */,
  yishiyong: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000027","已使用") /* "已使用" */,
  meikehu: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000028","每客户") /* "每客户" */,
  bumen: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000029","部门") /* "部门" */,
  selectGroup: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500002A","选择商品分组") /* "选择商品分组" */,
  pleaseSelectOrgFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500002B","请先选择组织！") /* "请先选择组织！" */,
  pleaseSelectCurrencyFirst: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500002C","请先选择币种!") /* "请先选择币种!" */,
  zuidayouhuiliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000021","最大优惠量") /* "最大优惠量" */,
  shengyukeyongliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500002D","剩余可用量") /* "剩余可用量" */,
  shengyukeyongshuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500002E","剩余可用数量") /* "剩余可用数量" */,
  zuidayouhuishuliang: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500002F","最大优惠数量") /* "最大优惠数量" */,
  shengyukeyongjine: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000030","剩余可用金额") /* "剩余可用金额" */,
  jietiyouhui: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000031","阶梯优惠") /* "阶梯优惠" */,
  duotidudiejia: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000032","多梯度叠加") /* "多梯度叠加" */,
  jinzuidajieti: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000033","仅最大阶梯") /* "仅最大阶梯" */,
  opposeReason: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000034","驳回原因") /* "驳回原因" */
};
