<template>
  <div
    :class="classes"
    :name="name">
    <slot></slot>
  </div>
</template>
<script>
import { oneOf, findComponentsDownward } from '../../utils/assist';
import Emitter from '../../mixins/emitter';

const prefixCls = 'ivu-radio-group';

let seed = 0;
const now = Date.now();
const getUuid = () => `ivuRadioGroup_${now}_${seed++}`;

export default {
  name: 'RadioGroup',
  mixins: [Emitter],
  props: {
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    size: {
      type: String,
      validator(value) {
        return oneOf(value, ['small', 'large', 'default']);
      },
      default() {
        return this.$IVIEW.size === '' ? 'default' : this.$IVIEW.size;
      }
    },
    type: {
      type: String,
      validator(value) {
        return oneOf(value, ['button']);
      }
    },
    vertical: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: getUuid
    },
    isCongruent: { // 是否全等比较值
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentValue: this.value,
      childrens: []
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-${this.size}`]: !!this.size,
          [`ivu-radio-${this.size}`]: !!this.size,
          [`${prefixCls}-${this.type}`]: !!this.type,
          [`${prefixCls}-vertical`]: this.vertical
        }
      ];
    }
  },
  watch: {
    value() {
      if (this.isNonEqual(this.currentValue, this.value, this.isCongruent)) {
        this.currentValue = this.value;
        this.updateValue();
      }
    }
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    updateValue() {
      this.childrens = findComponentsDownward(this, 'Radio');
      if (this.childrens) {
        this.childrens.forEach((child) => {
          child.currentValue = this.isEqual(this.currentValue, child.label, this.isCongruent);
          child.group = true;
        });
      }
    },
    change(data) {
      this.currentValue = data.value;
      this.updateValue();
      this.$emit('input', data.value);
      this.$emit('on-change', data.value);
      this.dispatch('FormItem', 'on-form-change', data.value);
    },
    isEqual(val1, val2, isCongruent) {
      return isCongruent ? val1 === val2 : val1 == val2;// eslint-disable-line
    },
    isNonEqual(val1, val2, isCongruent) {
       return isCongruent ? val1 !== val2 : val1 != val2;// eslint-disable-line
    }
  }
};
</script>
