<template>
  <div class="agent-footer-content">
    <div
      v-if="isShowContent"
      :class="['footer-top', {'no-article': helps.length}]">
      <!-- 帮助内容 -->
      <ul
        v-if="helps.length"
        :class="['articles', {'five-article': helps.length === 5}]">
        <li
          v-for="help in helps"
          :key="help.id"
          class="groups">
          <p class="title">
            {{ help.name }}
          </p>
          <p
            v-for="sub in help.subArticle"
            :key="sub.id">
            <a
              class="sub-text"
              @click="agentLink({type: 'helpCenter', query: {menuid:sub.parentId,articleid:sub.id}})">
              {{ sub.name }}
            </a>
          </p>
        </li>
      </ul>
      <!-- 竖线 -->
      <span
        v-if="helps.length"
        class="line"></span>
      <!-- 工作时间、联系方式 -->
      <div class="tel-workTime">
        <div
          v-if="tenant.contact"
          class="tel">
          <i class="iconfont dinghuo-dianhuakefu"></i>
          <span>{{ tenant.contact }}</span>
        </div>
        <div
          v-if="tenant.workTime"
          class="work-time">
          <i class="iconfont dinghuodibu-gongzuoshijian"></i>
          <div class="content">
            <p class="tip">
              <span>{{ mlang('workTime') }}</span>
            </p>
            <p class="time">
              {{ tenant.workTime }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="tenant.weixinImg"
        class="weixin">
        <img
          :src="tenant.weixinImg"
          class="QRcode" />
        <p class="description">
          {{ tenant.weixinDesc }}
        </p>
      </div>
    </div>
    <div class="copyright" v-html="copyRight">
    </div>
  </div>
</template>

<script>
import { getHelpInfo } from '@/api/agent-home/agent-home.js';
import { getCorpInfo } from '@/api/setting/corprations.js';
import { getCopyRight } from '@/api/login/login.js';
import { mapGetters } from 'vuex';
export default {
  name: 'AgentFooter',
  data: function() {
    return {
      helps: [],
      tenant: {},
      copyRight: ''
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    isShowContent() {
      return this.helps.length || this.tenant.isShowWeiXin || this.tenant.workTime || this.tenant.contact;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getHelpInfo().then((res = []) => {
        this.helps = res;
      });
      getCorpInfo().then((res = {}) => {
        // 匹配默认图片，硬匹配 没看懂什么意思注掉
        // if (!res.weixinDesc && res.weixinImg && res.weixinImg.indexOf('defaultADcode')) {
        //   res.isShowWeiXin = false;
        // } else {
        //   res.isShowWeiXin = true;
        // }
        this.tenant = res;
      });
      getCopyRight().then(res => {
        this.copyRight = res;
      });
    }
  }
};
</script>

<style scoped>

</style>
