import corpParentView from '@/views/corp/home/home.vue';

const notices = {
  path: 'notices',
  title: { i18n: 'notices' },
  name: 'notices',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'notices',
      title: { i18n: 'title.settlementMethodList' },
      name: 'notices',
      component: () => import('@/views/agent/notices/components/notices.vue')
    },
    {
      path: 'notice',
      title: { i18n: 'title.settlementMethodList' },
      name: 'notice',
      component: () => import('@/views/agent/notices/components/notice.vue')
    }
  ]
};

export default notices;
