<template>
  <Table
    ref="refScTable"
    :data="datas"
    :row-height="rowHeight"
    v-bind="{...$attrs,...$props}"
    :height="tableHeight"
    :max-height="maxHeight"
    v-on="$listeners"
    @columns-ready="init">
    <template slot="footer">
      <slot name="footer"></slot>
    </template>
  </Table>
</template>

<script>
import ScrollHandle from './editable/src/scroll';
// import PropsStatic from './editable/src/props';

export default {
  name: 'ScTable',
  props: {
    data: Array,
    height: [String, Number],
    refheight: [String, Number],
    config: Object,
    columns: Array,
    rowHeight: {
      type: Number,
      default: 40
    }
  },
  provide() {
    return {
      $table: this
    };
  },
  data() {
    return {
      datas: [],
      tableHeight: null,
      visibleIndex: 0,
      visibleStart: 0,
      currentRow: null,
      lastActive: null,
      lastOperation: null
    };
  },
  computed: {
    maxHeight() {
      return this.rowHeight === 60 ? 651 : 451;
    },
    configs() {
      return Object.assign({
        // 渲染方式，可以设置为 scroll 启用滚动渲染，支持海量数据
        render: 'default',
        // 只对 render=scroll 有效，自定义滚动实时渲染条数，不应该低于可视区域数量的两倍（高级参数）
        renderSize: 0,
        // 只对 render=scroll 有效，自定义滚动实时渲染阈值，不应该低于可视区域的数量（高级参数）
        offsetSize: 0
      }, this.config);
    },
    scrollLoad() {
      return this.configs.render === 'scroll';
    }
  },
  watch: {
    data: {
      handler(value, oldVal) {
        if (!this.isUpdateData) {
          this.reload(value || []);
        } else {
          this.isUpdateData = false;
        }
      },
      immediate: true,
      deep: true
    },
    datas: {
      handler(value, oldVal) {
        this.$emit('on-datas-change', value);
      },
      deep: true
    }

  },
  created() {
    this.setData(this.data);
  },
  destroyed() {
    if (this.scrollLoad) {
      this._unbindScrollEvent();
    }
  },
  methods: {
    setData(data) {
      this._fullData = data || [];
      let index = 0;
      this._visibleFullData = this._fullData.filter((v, realIndex) => {
        if (v.actionStatus !== 'D') {
          v.__tableIndex = index++;
          v.__scIndex = realIndex;
          return true;
        };
      });
    },
    _toActiveRow(record, prop) {
      let { row, column, cell } = this._getColumnByRowIndex(record, prop);
      if (row && column) {
        this.callEvent = this._callTriggerEvent('activate');
        this.datas.forEach(row => {
          if (row.data !== record) {
            this._clearValidError(row);
          }
        });
        if (prop) {
          this._validCellRules('all', row, column)
            .then(valid => this._triggerActive(row, column, cell, { type: 'edit', trigger: 'call' }))
            .catch(rule => this._toValidError(rule, row, column, cell));
        } else {
          this._validRowRules('all', row)
            .then(valid => this._triggerActive(row, column, cell, { type: 'edit', trigger: 'call' }))
            .catch(({ rule, row, column, cell }) => this._toValidError(rule, row, column, cell));
        }
        this.currentRow = row.data;
        return true;
      }
      return false;
    },
    setActiveRow(record) {
      if (this.configs.mode === 'row') {
        return this._toActiveRow(record, null);
      }
      return false;
    },
    _getData(datas) {
      return datas;
    },
    _updateData() {
      let data = this._getData();
      this.isUpdateData = true;
      this.$emit('update:data', data);
    },
    setTableHeight() {
      const dataLength = this._visibleFullData.length;
      const rowHeight = this.rowHeight;
      const maxHeight = this.maxHeight;
      let tableHeight;
      let extHeight = this.rowHeight === 60 ? 50 : 52;
      if (dataLength) {
        const totalHeight = dataLength * rowHeight + extHeight;//
        if (totalHeight >= maxHeight) {
          tableHeight = maxHeight;
        } else {
          tableHeight = totalHeight;
        }
      }
      if (this.refheight) {
        this.tableHeight = this.refheight;
      } else {
        this.tableHeight = tableHeight;
      }
    },
    init() {
      if (this.scrollLoad) {
        this._bindScrollEvent().then(() => this._reloadScrollData());
        this.$emit('bind-ready');
      }
    },
    /****************************/
    /* Original methods statrt  */
    /****************************/
    clearSelection() {
      return this.$refs.refScTable.clearSelection();
    },
    toggleRowSelection(record, selected) {
      return this.$refs.refScTable.toggleRowSelection(record, selected);
    },
    toggleAllSelection() {
      return this.$refs.refScTable.toggleAllSelection();
    },
    toggleRowExpansion(record, expanded) {
      return this.$refs.refScTable.toggleRowExpansion(record, expanded);
    },
    setCurrentRow(record) {
      return this.$refs.refScTable.setCurrentRow(record);
    },
    clearSort() {
      return this.$refs.refScTable.clearSort();
    },
    clearFilter() {
      return this.$refs.refScTable.clearFilter();
    },
    doLayout() {
      return this.$refs.refScTable.doLayout();
    },
    sort(prop, order) {
      return this.$refs.refScTable.sort(prop, order);
    },
    /****************************/
    /* Original methods end     */
    /****************************/

    /****************************/
    /* Original methods start   */
    /****************************/
    _select(selection, row) {
      this.$emit('select', selection, row);
    },
    _selectAll(selection) {
      this.$emit('select-all', selection);
    },
    _selectionChange(selection) {
      this.$emit('selection-change', selection);
    },
    _cellMouseEnter(row, column, cell, event) {
      this.$emit('cell-mouse-enter', row, column, cell, event);
    },
    _cellMouseLeave(row, column, cell, event) {
      this.$emit('cell-mouse-leave', row, column, cell, event);
    },
    _cellClick(row, column, cell, event) {
      this.$emit('cell-click', row, column, cell, event);
    },
    _cellDBLclick(row, column, cell, event) {
      this.$emit('cell-dblclick', row, column, cell, event);
    },
    _rowClick(row, column, event) {
      this.$emit('row-click', row, column, event);
    },
    _rowContextmenu(row, column, event) {
      this.$emit('row-contextmenu', row, column, event);
    },
    _rowDBLclick(row, column, event) {
      this.$emit('row-dblclick', row, column, event);
    },
    _headerClick(column, event) {
      this.$emit('header-click', column, event);
    },
    _headerContextmenu(column, event) {
      this.$emit('header-contextmenu', column, event);
    },
    _sortChange({ column, prop, order }) {
      this.$emit('sort-change', { column, prop, order });
    },
    _filterChange(filters) {
      this.$emit('filter-change', filters);
    },
    _currentChange(currentRow, oldCurrentRow) {
      this.$emit('current-change', currentRow, oldCurrentRow);
    },
    _headerDragend(newWidth, oldWidth, column, event) {
      this.$emit('header-dragend', newWidth, oldWidth, column, event);
    },
    _expandChange(row, expandedRows) {
      this.$emit('expand-change', row, expandedRows);
    },
    /******************************/
    /* Original Attribute statrt  */
    /******************************/

    /******************************/
    /******************************/

    /****************************/
    /* Scroll methods end       */
    /****************************/
    _reloadScrollData: ScrollHandle.reload(),
    _bindScrollEvent: ScrollHandle.bind(),
    _unbindScrollEvent: ScrollHandle.unbind(),
    _scrollEvent: ScrollHandle.scroll(),
    _setScrollSpace: ScrollHandle.space(),
    _computeScroll: ScrollHandle.compute(8),
    /****************************/
    /* Scroll methods end       */
    /****************************/

    /******************************/
    /* Public methods start       */
    /******************************/
    reload(data) {
      this.setData(data);
      this.setTableHeight();
      if (this.scrollLoad) {
        this._reloadScrollData(true);
      } else {
        this.datas = data;
      }
      return this.$nextTick();
    }

    /******************************/
    /* Public methods end         */
    /******************************/
  }
};
</script>
