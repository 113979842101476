// import selfInfoParentView from '@/views/agent/selfInfo/components/selfInfoListTab.vue';

// const selfInfo = {
//   path: 'selfInfo',
//   name: 'selfInfo',
//   component: selfInfoParentView,
//   meta: { module: 'selfInfo' },
//   children: [
    // {
    //   path: 'usersafe',
    //   name: 'usersafe',
    //   meta: { module: 'selfInfo' },
    //   component: () => import('@/views/agent/selfInfo/components/usersafe/usersafe.vue')
    // },
//     {
//       path: 'base',
//       name: 'base',
//       meta: { module: 'selfInfo' },
//       component: () => import('@/views/agent/selfInfo/components/userBase/aUserBase.vue')
//     }
//   ]
// };

// export default selfInfo;
//因为要显示老的暂时注释新的（上面是新的）
import corpParentView from '@/views/corp/home/home.vue';

const selfInfo = {
  path: 'selfInfo',
  title: { i18n: 'selfInfo' },
  name: 'selfInfo',
  component: corpParentView,
  children: [
    {
      path: 'usersafe',
      name: 'usersafe',
      component: () => import('@/views/agent/selfInfo/components/usersafe/usersafe.vue')
    },
    {
      path: 'userbase',
      name: 'userbase',
      component: () => import('@/views/agent/selfInfo/components/userBase/aUserBase.vue')
    }
  ]
};

export default selfInfo;
