export default {
  orderChangePrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000619","订单改价") /* "订单改价" */,
  orderSure: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500061A","订单确认") /* "订单确认" */,
  orderConfirm: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500061B","订单审核") /* "订单审核" */,
  orderSubmit: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500061C","订单提交") /* "订单提交" */,
  orderSave: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500061D","订单保存") /* "订单保存" */,
  constraint: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500061E","严格控制") /* "严格控制" */,
  ignore: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500061F","不控制") /* "不控制" */,
  hint: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000620","仅提示") /* "仅提示" */
};
