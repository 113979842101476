import Modal from './confirm';
import { deepCopy } from '../../utils/assist';
import TextArea from '../textarea';
let modalInstance;

function getModalInstance(render = undefined) {
  modalInstance =
    modalInstance ||
    Modal.newInstance({
      closable: false,
      maskClosable: false,
      footerHide: true,
      render: render
    });

  return modalInstance;
}

function confirm(options) {
  options.title || (options.title = '');
  const render = 'render' in options ? options.render : undefined;
  let instance = getModalInstance(render);

  options.onRemove = function() {
    modalInstance = null;
  };

  instance.show(options);
}

Modal.info = function(props = {}) {
  props.icon = 'info';
  props.showCancel = false;
  return confirm(props);
};

Modal.success = function(props = {}) {
  props.icon = 'success';
  props.showCancel = false;
  return confirm(props);
};

Modal.warning = function(props = {}) {
  props.icon = 'warning';
  props.showCancel = false;
  return confirm(props);
};

Modal.error = function(props = {}) {
  props.icon = 'warning'; //'error';
  props.showCancel = false;
  return confirm(props);
};

Modal.confirm = function(props = {}) {
  props.icon = 'confirm';
  props.showCancel = true;
  return confirm(props);
};

Modal.remove = function() {
  if (!modalInstance) {
    // at loading status, remove after Cancel
    return false;
  }

  const instance = getModalInstance();

  instance.remove();
};

Modal.prompt = function(props = {}) {
  props.showCancel = true;
  props.className = 'modal-prompt';
  props.width = 520;
  let tmpValue = '';
  props.loading = true;
  const propOk = deepCopy(props).onOk;
  props.onOk = () => {
    if (propOk(tmpValue, modalInstance) === false) {
      const instance = getModalInstance();
      instance.loading(false);
    }
  };
  props.render = (h, parmas) => {
    const tipInner = h('span', props.tip);
    const tipIcon = h('Icon', { props: { custom: 'icon icon-tongzh', 'class-name': 'modal-tip-icon' } });
    const content = h('p', { class: 'modal-tip' }, [tipIcon, tipInner]);
    const textarea = h(TextArea, {
      props: { value: tmpValue, type: 'textarea', autofocus: true, autosize: { minRows: 2, maxRows: 5 } },
      on: {
        'on-change': (val) => {
          tmpValue = val;
        }
      }
    });
    const children = [];
    if (props.tip) {
      children.push(content);
    }
    children.push(textarea);
    return h('div', { class: 'modal-prompt-content' }, children);
  };
  return confirm(props);
};

export default Modal;
