<template>
  <div class="archive archive-user">
    <Tabs
      :animated="false"
      :value="tabValue"
      class="router-view-tab"
      @on-click="tabClick">
      <template v-for="tab in tabs">
        <TabPane
          :label="tab.name"
          :name="tab.key"></TabPane>
      </template>
    </Tabs>
    <div class="child-view">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomerDetail',
  data() {
    const tabs = [
      { name: this.mlang('addMainBankConfig'), key: 'mainBankConfig' },
      { name: this.mlang('addSubBankConfig'), key: 'subBankConfig' }
    ];
    return {
      tabs,
      tabValue: tabs[0].key,
      currentUser: this.$store.getters.currentUser
    };
  },
  created() {
    if (this.$route.name === 'bankConfig') {
      this.tabValue = this.tabs[0].key;
    } else {
      this.tabValue = this.$route.name;
    }
    this.tabClick(this.tabValue, true);
  },
  methods: {
    tabClick(name, add) {
      this.tabValue = name;
      this.$router.replace({
        name
      });
    }
  }
};
</script>
