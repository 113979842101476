const settings = [
  // path: 'settings',
  // title: { i18n: 'setting' },
  // name: 'settings',
  // redirect: '/corp/home/index',
  // component: corpParentView,
  // children: [
  {
    path: 'menus',
    title: { i18n: 'title.menus' },
    name: 'menus',
    component: () => import('@/views/corp/settings/components/menus.vue')
  },
  {
    path: 'users',
    title: { i18n: 'title.user' },
    name: 'users',
    meta: { module: 'users' },
    component: () => import('@/views/corp/settings/components/user/users.vue')
  },
  {
    path: 'user',
    title: { i18n: 'title.user' },
    name: 'user',
    meta: { module: 'users', notCache: true },
    component: () => import('@/views/corp/settings/components/user/user.vue')
  },
  {
    path: 'roles',
    title: { i18n: 'title.user' },
    name: 'roles',
    meta: { module: 'roles' },
    component: () => import('@/views/corp/settings/components/role/roles.vue')
  },
  {
    path: 'role',
    title: { i18n: 'title.user' },
    name: 'role',
    meta: { module: 'roles', notCache: true },
    component: () => import('@/views/corp/settings/components/role/role.vue')
  },
  {
    path: 'orgArea',
    title: { i18n: 'title.orgArea' },
    name: 'orgArea',
    component: () => import('@/views/corp/organization/components/org-area.vue')
  },
  {
    path: 'contacter',
    title: { i18n: 'title.contacter' },
    name: 'contacter',
    meta: { module: 'contacters', notCache: true },
    component: () => import('@/views/corp/settings/components/user/contacter.vue')
  },
  {
    path: 'contacters',
    title: { i18n: 'title.contacters' },
    name: 'contacters',
    meta: { module: 'contacters' },
    component: () => import('@/views/corp/settings/components/user/contacters.vue')
  },
  {
    path: 'customerArea',
    title: { i18n: 'title.customerArea' },
    name: 'customerArea',
    component: () => import('@/views/corp/organization/components/customerArea.vue')
  },
  {
    path: 'departmentArea',
    title: { i18n: 'title.departmentArea' },
    name: 'departmentArea',
    component: () => import('@/views/corp/organization/components/department-area.vue')
  },
  {
    path: 'transactionTypes',
    title: { i18n: 'title.transactionTypes' },
    name: 'transactionTypes',
    meta: { module: 'transactionType' },
    component: () => import('@/views/corp/order/components/transaction-types.vue')
  },
  {
    path: 'transactionType',
    title: { i18n: 'title.transactionType' },
    name: 'transactionType',
    meta: { module: 'transactionType', notCache: true },
    component: () => import('@/views/corp/order/components/transaction-type.vue')
  },
  {
    path: 'commission',
    title: { i18n: 'title.commission' },
    name: 'commission',
    component: () => import('@/components/payments/commission.vue')
  },
  {
    path: 'printSetting',
    title: { i18n: 'title.printSetting' },
    name: 'printSetting',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/print-setting.vue')
  },
  {
    path: 'noticeClasses',
    title: { i18n: 'title.noticeClasses' },
    name: 'noticeClasses',
    meta: { module: 'noticeclass', notCache: true },
    component: () => import('@/views/corp/settings/components/notice-classes.vue')
  },
  {
    path: 'noticeClass',
    title: { i18n: 'title.noticeClass' },
    name: 'noticeClass',
    meta: { module: 'noticeclass', notCache: true },
    component: () => import('@/views/corp/settings/components/notice-class.vue')
  },
  {
    path: 'adPictureSettings',
    title: { i18n: 'title.adPictureSettings' },
    name: 'adpicturesettings',
    meta: { module: 'adpicturesettings' },
    component: () => import('@/views/corp/settings/components/adPicture/adPictureSettings.vue')
  },
  {
    path: 'adPictureSetting',
    title: { i18n: 'title.adPictureSetting' },
    name: 'adpicturesetting',
    meta: { module: 'adpicturesettings', notCache: true },
    component: () => import('@/views/corp/settings/components/adPicture/adPictureSetting.vue')
  },
  {
    path: 'promotionAds',
    title: { i18n: 'title.promotionAds' },
    name: 'promotionAds',
    meta: { module: 'promotionAds' },
    component: () => import('@/views/corp/settings/components/promotionAd/promotionAds.vue')
  },
  {
    path: 'promotionAd',
    title: { i18n: 'title.promotionAds' },
    name: 'promotionAd',
    meta: { module: 'promotionAds', notCache: true },
    component: () => import('@/views/corp/settings/components/promotionAd/promotionAd.vue')
  },
  {
    path: 'noticeSettings',
    title: { i18n: 'title.noticeSettings' },
    name: 'noticeSettings',
    meta: { module: 'noticeSettings' },
    component: () => import('@/views/corp/settings/components/noticeSetting/notice-settings.vue')
  },
  {
    path: 'noticeSetting',
    title: { i18n: 'title.noticeSetting' },
    name: 'noticeSetting',
    meta: { module: 'noticeSettings', notCache: true },
    component: () => import('@/views/corp/settings/components/noticeSetting/notice-setting.vue')
  },
  {
    path: 'bo',
    title: { i18n: 'title.bo' },
    name: 'bo',
    component: () => import('@/views/corp/settings/components/bo.vue'),
    children: [
      {
        path: 'wxMoneyRule',
        name: 'wxMoneyRule',
        meta: { module: 'bo' },
        component: () => import('@/views/corp/settings/components/bo/wxMoneyRule.vue')
      },
      {
        path: 'orderAuditRule',
        name: 'orderAuditRule',
        meta: { module: 'bo' },
        component: () => import('@/views/corp/settings/components/bo/orderAuditRule.vue')
      }
    ]
  },
  {
    path: 'menuSetting',
    name: 'menuSetting',
    meta: {
      notCache: true,
      module: 'menuSetting'
    },
    component: () => import('@/views/corp/settings/components/menuSetting/menuSetting.vue')
  },
  {
    path: 'invoiceSetting',
    name: 'invoiceSetting',
    meta: {
      notCache: true,
      module: 'invoiceSetting'
    },
    component: () => import('@/views/corp/settings/components/invoiceSetting/invoiceSetting.vue')
  },
  {
    path: 'menuSetting',
    name: 'menuSetting',
    meta: {
      notCache: true,
      module: 'menuSetting'
    },
    component: () => import('@/views/corp/settings/components/menuSetting/menuSetting.vue')
  },
  {
    path: 'mobileFlashConfig',
    name: 'mobileFlashConfig',
    meta: {
      notCache: true,
      module: 'mobileFlashConfig'
    },
    component: () => import('@/views/corp/settings/components/mobileFlashConfig/mobileFlashConfig.vue')
  },
  {
    path: 'corpERPIntegrationOrg',
    name: 'corpERPIntegrationOrg',
    meta: { module: 'corpErpIntegration', notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/corpERPIntegration/corpERPIntegrationOrg.vue')
  },
  {
    path: 'functionOptions',
    name: 'functionOptions',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/functionOptions.vue')
  },
  {
    path: 'rebateSourceList',
    name: 'rebateSourceList',
    meta: {
      notCache: true,
      module: 'functionOptions'
    },
    component: () => import('@/views/corp/settings/components/rebateSource/rebateSourceList.vue')
  },
  {
    path: 'rebateSourceCard',
    name: 'rebateSourceCard',
    meta: {
      notCache: true,
      module: 'functionOptions'
    },
    component: () => import('@/views/corp/settings/components/rebateSource/rebateSourceCard.vue')
  },
  {
    path: 'exceptAgentEditArchive',
    name: 'exceptAgentEditArchive',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/exceptAgentEditArchive.vue')
  },
  {
    path: 'inventorylimit',
    name: 'inventorylimit',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/inventorylimit.vue')
  },
  {
    path: 'inventoryCheckSetting',
    name: 'inventoryCheckSetting',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/inventoryCheckSetting.vue')
  },
  {
    path: 'orderConfirmationStrategy',
    name: 'orderConfirmationStrategy',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/orderConfirmationStrategy.vue')
  },
  {
    path: 'orderConfirmationStrategy',
    name: 'orderConfirmationStrategy',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/orderConfirmationStrategy.vue')
  },
  {
    path: 'autoSeparateBillRule',
    name: 'autoSeparateBillRule',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/autoSeparateBillRule.vue')
  },
  {
    path: 'agreement',
    name: 'agreement',
    meta: { module: 'functionOptions', notCache: true },
    component: () => import('@/views/corp/settings/components/functionOptions/agreement.vue')
  },
  {
    path: 'onlinePaySetting',
    name: 'onlinePaySetting',
    meta: {
      notCache: true,
      module: 'onlinePaySetting'
    },
    component: () => import('@/views/corp/settings/components/onlinePaySetting/onlinePaySetting.vue')
  },
  {
    path: 'offlinePaySettings',
    name: 'offlinePaySettings',
    meta: {
      notCache: true,
      module: 'offlinePaySettings'
    },
    component: () => import('@/views/corp/settings/components/offlinePaySetting/offlinePaySettings.vue')
  },
  {
    path: 'offlinePaySetting',
    name: 'offlinePaySetting',
    meta: {
      module: 'offlinePaySettings'
    },
    component: () => import('@/views/corp/settings/components/offlinePaySetting/offlinePaySetting.vue')
  },
  {
    path: 'helpCenterSetting',
    title: { i18n: 'title.helpCenterSetting' },
    name: 'helpCenterSetting',
    meta: {
      module: 'helpCenterSetting',
      notCache: true
    },
    component: () => import('@/views/corp/settings/components/helpCenter/helpCenter.vue')
  },
  // 企业信息设置
  {
    path: 'enterpriseInfoSetting',
    name: 'enterpriseInfoSetting',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/enterpriseInfoSetting/enterpriseInfoSetting.vue')
  },
  // 客服信息
  {
    path: 'serviceInformation',
    name: 'serviceInformation',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/serviceInformation/serviceInformation.vue')
  },
  // 接口密钥
  {
    path: 'interfaceKey',
    name: 'interfaceKey',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/interfaceKey/interfaceKey.vue')
  },
  // ERP集成
  {
    path: 'corpERPIntegration',
    name: 'corpERPIntegration',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/corpERPIntegration/corpERPIntegration.vue')
  },
  // 主题风格
  {
    path: 'themeSetting',
    name: 'themeSetting',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/themeSetting/themeSetting.vue')
  },
  // 订购信息
  {
    path: 'orderInfo',
    name: 'orderInfo',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/orderInfo/orderInfo.vue')
  },
  // 注册邀请
  {
    path: 'registeredInvite',
    name: 'registeredInvite',
    meta: { notCache: true },
    component: () => import('@/views/corp/settings/components/corprations/registeredInvite/registeredInvite.vue')
  }
];

export default settings;
