import payments from './payments';
import refunds from './refunds';
import shareRules from './shareRules';
import shareRule from './shareRule';
import refundRules from './refundRules';
import refundRule from './refundRule';
export default {
  orderManager: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500071D","订单管理") /* "订单管理" */,
  CONFIRMORDER: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500071E","待确认") /* "待确认" */,
  DELIVERGOODS: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500071F","待发货") /* "待发货" */,
  TAKEDELIVERY: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000720","待收货") /* "待收货" */,
  ENDORDER: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000721","已完成") /* "已完成" */,
  OPPOSE: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000722","已取消") /* "已取消" */,
  NOTPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000723","未付款") /* "未付款" */,
  PARTPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000724","部分付款") /* "部分付款" */,
  CONFIRMPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000719","部分付款待确认") /* "部分付款待确认" */,
  CONFIRMPAYMENT_ALL: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500071A","付款待确认") /* "付款待确认" */,
  FINISHPAYMENT: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500071B","已付款") /* "已付款" */,
  noLogistic: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500071C","暂时查询不到物流信息哦~") /* "暂时查询不到物流信息哦~" */,
  payments,
  refunds,
  shareRules,
  refundRules,
  shareRule,
  refundRule
};
