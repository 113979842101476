<template>
  <Row v-if="filters && filters.length">
    <div
      class="queryschema">
      <Col class-name="queryschema-left">
      <QuerySchemaItem
        ref="control.name"
        :b-refer="bRefer"
        :filters="visibleFilters"
        :biz-meta="cloneMeta"
        :view-data="viewData"
        :obj-data="objData"
        :show-more="showMore"
        @on-filter-change="dynamicChange"
        @on-set-view-data-value="setViewValue"
        @onPropsChange="propChange"
        @on-tag-click="search"
        @on-enter-press="search">
      </QuerySchemaItem>
      <Checkbox v-if="bizMeta.key === 'productRef'" v-model="isKeyWordExactSearch" class="checkbox">
        {{ mlang('isKeyWordExactSearch') }}
      </Checkbox>
      </Col>
      <Col class-name="queryschema-right">
      <div
        v-if="!cloneMeta.isHideSearchBtn"
        :class="`search-block ${bRefer && 'm-l-0'}`">
        <Button
          type="text"
          icon="search"
          class="btn-black"
          fieldid="'search_btn"
          @click="search">
          {{ mlang('search') }}
        </Button>
        <span
          v-if="!bRefer && currentUser && currentUser.userType === 1 && cloneMeta.enableMetaSetting !== false"
          class="queryschema-setting">
          <MetaSetting
            :icon="false"
            :bill-id="cloneMeta.billId"
            :columns="filters"
            type="queryschema"></MetaSetting>
        </span>
        <span
          v-if="showMoreBtn"
          class="queryschema-more"
          fieldid="queryschema_more"
          @click="moreClick">
          <Icon
            :custom="moreIcon"></Icon>
        </span>
      </div>
      <slot name="addBtn"></slot>
      </Col>
    </div>
  </Row>
</template>

<script>
import { deepCopy } from '@/u-components/utils/assist';
import dayjs from 'dayjs';
import Refer from '_c/common/refer/refer';
import util from '@/libs/util';
import PropsyncMixin from '@/mixin/propsync.js';
import CommonMixin from '@/mixin/common.js';
import QuerySchemaItem from './queryschema-item';
import _ from 'lodash';
export default {
  name: 'Queryschema',
  components: {
    Refer,
    QuerySchemaItem
  },
  mixins: [CommonMixin, PropsyncMixin],
  props: {
    bRefer: {
      type: Boolean,
      default: false
    },
    bizMeta: {
      type: Object,
      default: {},
      propsync: false // 不会被propsync实现双向绑定
    },
    filters: {
      type: Array,
      required: true,
      propsync: false // 不会被propsync实现双向绑定
    },
    isImmediatelyCallInterface: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const { visibleFilters, viewData } = this.parseMetaToData(this.filters);
    return {
      span: 3,
      showMore: this.bizMeta.showMore || false,
      showMoreBtn: false,
      cloneMeta: deepCopy(this.bizMeta),
      copyFilter: deepCopy(this.filters),
      visibleFilters,
      viewData,
      objData: {},
      TENANTDEFINEMETA: this.$getBO('TENANTDEFINEMETA') > 0,
      isKeyWordExactSearch: false // 是否开启精确匹配
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    moreIcon() {
      return this.showMore ? 'iconfont icon-shouqi' : 'iconfont icon-zhankai';
    }
  },
  watch: {
    bizMeta: {
      handler(val) {
        if (!val) return;
        this.cloneMeta = val;
        // const { queryschema, shcemaFilters, view, all, flatAll } = this.parseMetaToData();
        // this.i_queryschema = queryschema;
        // this.shcemaFilters = shcemaFilters;
        // this.view = view;
        // this.all = all;
        // this.flatAll = flatAll;
        // this.emitLoaded();
      },
      deep: true
    },
    filters: {
      handler(v, v2) {
        if (v === v2) {
          return;
        }
        this.init();
      },
      deep: true
    },
    isKeyWordExactSearch(value) {
      // 记录用户精确匹配配置
      localStorage.setItem('popProductsExactSearch', value);
    }
  },
  created() {
    this.isKeyWordExactSearch = !!JSON.parse(localStorage.getItem('popProductsExactSearch'));
    if (this.bRefer) {
      if (this.isImmediatelyCallInterface) {
        this.init();
      }
    }
  },
  mounted() {
    // !this.cloneMeta.async && this.emitLoaded();
  },
  methods: {
    init() {
      this.copyFilter = deepCopy(this.filters);
      this.makeObjData();
      this.makeQueryschema();
    },
    makeObjData() {
      let queryschemaObj = {};
      for (const key in this.filters) {
        if (this.filters.hasOwnProperty(key)) {
          const item = this.filters[key];
          if (item['isShowIt'] !== false) {
            const newFilter = deepCopy(item); // todo 直接替换
            queryschemaObj[item.name] = newFilter;
          }
        }
      }
      this.objData = queryschemaObj;
    },
    // 根据action设置item 动态条件改变fieldName都相同不能用fieldName过滤 add byfzx
    setFilterItemByAction(action, fieldName, key, value) {
      let item = this.visibleFilters.find((v) => {
        return v.action === action;
      });
      if (item) {
        this.$set(item, key, value);
      }
      if (key === 'isCustomerShow') {
        this.viewData[fieldName] = null;
      }
      // const { visibleFilters } = this.parseMetaToData(this.copyFilter);
      // this.visibleFilters = visibleFilters;
      // this.viewData = viewData;
    },
    setFilterItem(fieldName, key, value) {
      let item = this.visibleFilters.find((v) => {
        return v.name === fieldName;
      });
      if (item) {
        this.$set(item, key, value);
      }
      if (key === 'isCustomerShow') {
        this.viewData[fieldName] = null;
      }
      if (key === 'defaultValue') {
        this.viewData[fieldName] = value;
      }
      // const { visibleFilters } = this.parseMetaToData(this.copyFilter);
      // this.visibleFilters = visibleFilters;
      // this.viewData = viewData;
    },
    makeQueryschema() {
      const { visibleFilters, viewData } = this.parseMetaToData(this.filters);
      this.visibleFilters = visibleFilters;
      this.viewData = viewData;
      this.setMoreBtn();
      this.emitLoaded();
    },
    setMoreBtn() {
      if (this.bRefer) {
        return false;
      }
      const rowFilters = this.visibleFilters.filter((v) => {
        return v.groupType === 1;
      });
      const cellFilters = this.visibleFilters.filter((v) => {
        return v.groupType === 2;
      });
      let show = false;
      if (rowFilters && rowFilters.length > 2) {
        show = true;
      } else if (rowFilters && rowFilters.length > 0 && rowFilters.length <= 2) {
        if (cellFilters && cellFilters.length > 0) {
          show = true;
        }
      } else {
        if (cellFilters && cellFilters.length > this.span) {
          show = true;
        }
      }
      this.showMoreBtn = show;
    },
    emitLoaded() {
      this.$nextTick(function() {
        this.$emit('on-queryschema-loaded');
      });
    },
    queryschemaLoaded() {
      this.$emit('');
    },
    moreClick() {
      this.showMore = !this.showMore;
      this.$nextTick(() => {
        this.$emit('on-show-more');
      });
    },
    parseMetaToData(filters) {
      let hash = {};
      filters = filters.reduce(function(item, next) {
        if (!hash[next.name]) {
          hash[next.name] = true;
          item.push(next);
        }
        return item;
      }, []);
      // 解决$set问题
      const all = {
        value: '',
        name: '',
        title: this.mlang('ALL')
      };
      let flatAll = true;
      const viewData = {};
      let visibleFilters = filters.filter((v) => {
        return v['isShowIt'] !== false;
      });
      visibleFilters = visibleFilters || [];
      visibleFilters.map((control, k) => {
        let { controlType, enumString, name, defaultValue, isEnum, enumName, action, customerDataSource, groupType } = control;
        control.visible = true;
        if (control.controlType === 'String' && control.isClearable === undefined) { control.isClearable = true; }
        // refer有自己特殊处理
        if (controlType !== 'Refer' && controlType !== 'Select' && this.bizMeta && this.bizMeta.actions && this.bizMeta.actions[action]) {
          control = this.bizMeta.actions[action](control);
        }
        if (control.enumString && control.customFlag) {
          enumString = control.enumString;
        }
        if ((isEnum || controlType === 'Select') && enumString !== '' && typeof enumString === 'string') {
          try {
            control.enumArray = JSON.parse(enumString);
            if (control.valueType === 'BOOLEAN') { // iview 3.4以后 select valueType不支持boolean
              control.enumArray.map(v => {
                v.value = v.value.toString();
              });
            }
          } catch (error) {
            console.error('enumString error-->' + enumString); //eslint-disable-line
          }
        }
        // 自定义数据源
        if (customerDataSource) {
          if (customerDataSource !== 'null') {
            control.enumArray = JSON.parse(customerDataSource);
          } else {
            control.enumArray = [];
          }
          control.enumArray.map((v) => {
            v.value = v.id;
            v.title = v.name;
          });
          if (control.enumArray.length > 4 || groupType === 2) {
            control.controlType = 'Select';
          }
          if (control.enumArray.length <= 1) {
            control.visible = false;
          }
          // nonSetSelectDefaultVal 用于控制是否设置默认值(设置为false, 不设置为true)
          if (customerDataSource && customerDataSource !== 'null' && control.enumArray && control.enumArray.length && !this.bizMeta.nonSetSelectDefaultVal) {
            viewData[name] = control.enumArray[0].value;
          }
        }
        if (isEnum && _.isUndefined(control.enumArray)) {
          // 补漏
          console.error('no enumArray !!' + control.name); // eslint-disable-line no-console
          control.enumArray = {};
        }
        const bizDefaultValue = (this.bizMeta.defaultValue && !_.isUndefined(this.bizMeta.defaultValue[name]) && this.bizMeta.defaultValue[name]) || control.defaultValue;
        const enumNotAll = this.bizMeta.filterEnumNotAll && !_.isUndefined(this.bizMeta.filterEnumNotAll[name]) && this.bizMeta.filterEnumNotAll[name];
        /* eslint no-case-declarations: "off" */
        switch (control.controlType) {
          case 'Refer':
            if (bizDefaultValue) {
              viewData[name] = bizDefaultValue;
              let referTextShow = this.bizMeta.defaultValue[name + 'Show'];
              this.$set(control, 'referTextShow', referTextShow);
            }
            break;
          case 'OuterRefer':
            control.controlType = 'String';
            control.conditionType = 'like';
            break;
          case 'Enum':
            if (enumName) {
              control.enumArray = [all, ...control.enumArray];
              if (enumNotAll) control.enumArray.shift();
              viewData[name] = bizDefaultValue || (_.isUndefined(defaultValue) ? all.value : defaultValue);
            }
            break;
          case 'Select':
            if (!control.enumArray) {
              if (this.bizMeta && !this.bizMeta.actions[action]) {
                console.error('no action ->' + control.name); //eslint-disable-line
              } else {
                const act = this.bizMeta &&
                  this.bizMeta.actions[action](control);
                if (this.$util.isPromise(act)) {
                  act.then((res) => {
                    control.enumArray = res;
                    this.bizMeta.actions[`after${action}`] && this.bizMeta.actions[`after${action}`](res, control);
                  });
                } else if (_.isObject(act)) {
                  control = act;
                }
              }
            } else {
              const enumStoreType = control.enumStoreType;
              if (enumStoreType === 'NAME') {
                control.enumArray.map((v) => {
                  v.value = v.name;
                });
              }
              if (enumStoreType === 'SPECIAL') {
                control.enumArray.map((v) => {
                  v.value = v.specialValue;
                });
              }
            }
            if (customerDataSource && groupType === 1) {
              control.clearable = false;
            } else {
              if (control.clearable !== false) {
                control.clearable = true;
              }
            }
            if (!_.isUndefined(bizDefaultValue)) {
              viewData[name] = bizDefaultValue;
            } else if (!_.isUndefined(control.defaultValue)) {
              viewData[name] = control.defaultValue;
            }
            break;
          case 'PredicateDateTime':
            let tagValue = control.enumArray[0].value;
            if (bizDefaultValue) {
              tagValue = bizDefaultValue;
            }
            viewData[name] = {
              tag: tagValue,
              v: ['', '']
            };
            break;
          case 'DateRange':
          case 'DateTimeRange':
            viewData[name] = ['', ''];
            break;
          case 'NumberRange':
            viewData[name] = { v1: -100000000, v2: 100000000 };
            break;
          default:
            viewData[name] = bizDefaultValue || null;
            break;
        }
        if (typeof this.bizMeta.setQueryAttr === 'function') this.bizMeta.setQueryAttr(control);
        return visibleFilters;
      });
      return {
        all,
        flatAll,
        viewData,
        visibleFilters
      };
    },
    getQueryschemaRealData() {
      let schema = {
        conditions: []
      };
      for (const ctrlName in this.viewData) {
        if (this.viewData.hasOwnProperty(ctrlName)) {
          const value = this.viewData[ctrlName];
          // console.log(ctrlName);
          if (util.isNoValue(value)) {
            continue;
          }
          const ctrl = this.visibleFilters.find((v) => {
            return v.name === ctrlName && v.isShowIt;
          });
          if (ctrl) {
            const { conditionType, name, valueType, group, isDefine, isBody, isOuterField, outerEntity, itemName } = ctrl;
            const query = {
              name,
              conditionType,
              isDefine,
              isOuterField,
              outerEntity,
              isDetail: isBody,
              valueType
            };
            if (itemName) {
              query.itemName = itemName;
            }
            group && (query.group = group);
            if (ctrl.controlType === 'DateTimeRange' || ctrl.controlType === 'DateRange') {
              let start = value[0];
              let end = value[1];
              if (ctrl.controlType === 'DateTimeRange') {
                start = start && dayjs(start).format('YYYY-MM-DD HH:mm:ss');
                end = end && dayjs(end).format('YYYY-MM-DD HH:mm:ss');
              }
              const v1 = this.formatQueryValue(ctrl, start);
              const v2 = this.formatQueryValue(ctrl, end);
              if (util.isNoValue(v1) && util.isNoValue(v2)) {
                continue;
              }
              query.v1 = v1;
              query.v2 = v2;
            } else if (ctrl.controlType === 'PredicateDateTime') {
              const v1 = this.formatQueryValue(ctrl, value.v[0]);
              const v2 = this.formatQueryValue(ctrl, value.v[1]);
              if (util.isNoValue(v1) && util.isNoValue(v2)) {
                continue;
              }
              query.v1 = v1;
              query.v2 = v2;
            } else if (ctrl.controlType === 'NumberRange') {
              if (util.isNoValue(value.v1) && util.isNoValue(value.v2)) {
                continue;
              } else if ((!util.isNoValue(value.v1) && util.isNoValue(value.v2)) || (util.isNoValue(value.v1) && !util.isNoValue(value.v2))) {
                this.$Message.error(this.mlang('leftAndRightNeedComplete'));
                return false;
              }
              query.v1 = value.v1;
              query.v2 = value.v2;
            } else {
              query.v1 = isDefine ? this.formatQueryValue(ctrl, value) : value;
              if (ctrlName === 'iPrdouctClassId') {
                query.v1 = Object.prototype.toString.call(query.v1) === '[object Array]' ? query.v1.join() : query.v1.toString();
              }
            }
            if (query && _.isString(query.v1) && query.v1.length) {
              query.v1 = _.trim(query.v1);
            }
            schema.conditions.push(query);
          }
        }
      }
      return schema;
    },
    formatQueryValue(ctrl, value, extText = '') {
      let queryValue = '';
      if (util.isNoValue(value)) {
        return '';
      } else {
        queryValue = `${value}${extText}`;
      }
      return queryValue;
    },
    search(control) {
      // 针对元数据筛选项，给enumstring里面的一项加children，上送要求和conditions的数组项并列 切换tagbutton情况添加遍历
      if (control && control.name && control.sourceFilterItemId) {
        this.visibleFilters.forEach((v) => {
          if (v.sourceFilterItemId === control.sourceFilterItemId) {
            v.name = control.name;
          }
        });
      }
      const schema = this.getQueryschemaRealData();
      if (schema) {
        this.$emit('on-queryschema-search', schema);
      }
    },
    setViewValue(control, value, key, viewEntity) {
      if (_.isUndefined(key)) {
        this.viewData[control.name] = value;
      } else {
        this.viewData[control.name][key] = value;
      }
    },
    dynamicChange(control, enumKey) {
      var valueObj = control.enumArray.find((v) => {
        return v.value === enumKey;
      });
      this.$emit('on-filter-change', control, valueObj);
    },
    resetViewData(){
      this.viewData = {};
    }
  }
};
</script>
<style lang="less" scoped>
.checkbox {
  margin-top: 5px;
}
.ivu-modal  {
  .ivu-checkbox-wrapper{
    margin-left: 20px;
  }
}
</style>
