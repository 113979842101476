import ajax from '@/libs/ajax';

// 品牌中心获取商品分类
export const getAllProductsClasses = (data) => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/goods/infrastructure/productclasses/list/up/modelVo?isArchive=1' : '/pc/ProductClass/getUpClasses',
    // url:'/agentpage/products/list',
    method: 'get',
    data: {
      node: 1,
      id: ''
      // isArchive: 1
    }
  });
};

// 品牌中心列表
export const getBrandList = (classId = undefined) => {
  return ajax.request({
    url: '/goods/infrastructure/productclass/brand/grouplist',
    method: 'get',
    data: {
      classId,
      isArchive: 1
    }
  });
};

// 品牌中心列表;
// export const getBrandList = (brandId) => {
//   return ajax.request({
//     url: '/orginazation/brand/grouplist',
//     method: 'get',
//     data: {
//       brandId,
//       isArchive: 1
//     }
//   });
// };

// 获取品牌列表（新）
export const getBrandListNew = (brandId) => {
  return ajax.request({
    url: '/orginazation/brand/list/page',
    method: 'get',
    data: {
      brandId,
      isArchive: 1
    }
  });
};

// 获取产品线列表
export const getProductLineList = (productLineId) => {
  return ajax.request({
    url: '/orginazation/productline/list',
    method: 'get',
    data: {
      productLineId,
      isArchive: 1
    }
  });
};
