<template>
  <div>
    <Col
      v-if="title"
      :title="title"
      :style="styleSet"
      span="8"
      class="label-control queryschema-label two-line-overflow-hide">
    {{ title }}
    </Col>
    <Col v-else style="width:20px"></Col>
  </div>
</template>

<script>
export default {
  name: 'QuerySchemaLabel',
  props: {
    title: {
      type: String,
      default: ''
    },
    styleSet: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data: function() {
    return {};
  },
  methods: {}
};
</script>
