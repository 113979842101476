import { render, staticRenderFns } from "./ZoneHome.vue?vue&type=template&id=2976bd52&scoped=true"
import script from "./ZoneHome.vue?vue&type=script&lang=js"
export * from "./ZoneHome.vue?vue&type=script&lang=js"
import style0 from "./ZoneHome.vue?vue&type=style&index=0&id=2976bd52&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2976bd52",
  null
  
)

export default component.exports