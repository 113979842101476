<template>
  <Modal
    v-model="isShowModal"
    :mask-closable="false"
    :title="title"
    class="select-product-sku">
    <div class="product-sku-content">
      <no-data
        v-if="!specList || !specList.length"
        class="product-sku-no-data"></no-data>
      <template
        v-else>
        <div
          v-for="(specItem,index) in specList"
          :key="index"
          class="product-sku-row">
          <div class="product-sku-label">
            {{ specItem.cName || specItem.name }}
          </div>
          <div class="product-sku-items">
            <span
              v-for="spec in specItem.lsSpecItem"
              :key="spec.id"
              :class="[ { 'active' : spec.id == selectedSkuId[index], 'disabled' : spec.disabled} ,'product-sku-item']"
              @click="handleCheck(spec, index)">{{ spec.cSpecItemName || spec.specItemName }}</span>
          </div>
        </div>
      </template>
    </div>
    <div slot="footer">
      <Button
        type="text"
        class="cancel-btn"
        @click="cancelHandler">
        {{ mlang('cancel') }}
      </Button>
      <Button
        type="primary"
        @click="sureHandler">
        {{ mlang('sure') }}
      </Button>
    </div>
  </Modal>
</template>

<script>
import { getProductSpecs, getProductSpeciSkuInfoUrl, getProductSku, getUpProductSpecsUrl, getUpProductSku } from '@/api/product';
export default {
  name: 'SelectProductSku',
  props: {
    productId: {
      type: Number
    },
    bizProductId: {
      type: Number
    },
    isReferSaleProduct: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      title: this.mlang('select') + this.mlang('attributes'),
      isShowModal: false,
      specList: [],
      selectedSkuId: [],
      theProductId: null, // 组件内使用
      theBizProductId: null,
      specItemObj: {},
      lsProductSkus:[],
      skuTemp:[],
      skuResults:[],
      lsSpecResults:[],
      lsSpecResultsF:[],
      specItemList:[],
    };
  },
  computed: {
    getSku() {
      //return this.isReferSaleProduct ? getUpProductSku : getProductSku;
      return getUpProductSku;
    },
    getSkusOfProd() {
      return this.isReferSaleProduct ? getProductSpeciSkuInfoUrl : getProductSpecs;
    }
  },
  watch: {
    productId: {
      handler(val) {
        if (!val) return;
        this.theProductId = val;
        this.getProductSkus(this.theProductId);
      },
      immediate: true
    },
    bizProductId: {
      handler(val) {
        if (!val) return;
        this.theBizProductId = val;
      },
      immediate: true
    }
  },
  methods: {
    setProductId(productId) {
      this.theProductId = productId;
    },
    setBizProductId(bizProductId) {
      this.theBizProductId = bizProductId;
    },
    showRefModal(skuSpecDesc = '', selectedProductId = this.theProductId, bizProductId = this.theBizProductId) {
      if (!selectedProductId) {
        return;
      }
      if (this.isReferSaleProduct && !bizProductId) {
        return;
      }
      if (selectedProductId !== undefined) this.theProductId = selectedProductId;
      if (bizProductId !== undefined) this.theBizProductId = bizProductId;
      this.getProductSkus(this.theProductId, skuSpecDesc, this.theBizProductId).then(() => { this.isShowModal = true; });
    },
    cancelHandler() {
      this.isShowModal = false;
    },
    sureHandler() {
      let specItemFilterArr = [];
      for (let k in this.specItemObj) {
        specItemFilterArr.push(`${k}:${this.specItemObj[k]}`);
      }
      let postData = {
        id: this.theProductId,
        specItemIds: this.selectedSkuId.toString(),
        specItemFilter: specItemFilterArr.join(';')
      };
      if (this.isReferSaleProduct) postData['bizProductId'] = this.theBizProductId;
      this.getSku(postData).then((data) => {
        if (data && data.length > 0) {
          let sku = data[0];
          this.$emit('on-selected', sku);
          this.isShowModal = false;
        } else {
          this.$Message.warning(this.mlang('theProductNotExistOrBeenRemoved') + '!');
          this.isShowModal = false;
        }
      });
    },
    handleCheck(item = {}, index) {
      this.$set(this.selectedSkuId, index, item.id);
      this.$set(this.specItemObj, item.iSpecCode, item.id);
      if(this.isReferSaleProduct){
        this.selectedSku(item, index)
      }
    },
    specDescripArr(specDescription) {
      let specDescripArr = [];
      if (specDescription) {
        if (specDescription.indexOf(';')) {
          specDescripArr = specDescription.split(';');
        } else {
          specDescripArr.push(specDescription);
        }
      }
      let arr = [];

      specDescripArr.forEach((v) => {
        let arr1 = [];
        if (v.indexOf(':') !== -1) {
          arr1 = v.split(':');
          arr.push(arr1[1]);
        } else {
          arr.push(v);
        }
      });
      return arr;
    },
    getProductSkus(theProductId, skuSpecDesc = '', bizProductId) {
      let skuArr = this.specDescripArr(skuSpecDesc);
      return new Promise((resolve, reject) => {
        this.selectedSkuId = [];
        this.getSkusOfProd(theProductId, bizProductId).then((resData) => { // 获取规格列表
          resData.lsProductSkus = resData.lsProductSkus || []
          let res = null;
          if(this.isReferSaleProduct){
            res = resData.lsSpecs || [];
            this.lsProductSkus = resData.lsProductSkus
          }else{
            res = resData || []
          }
          this.specItemObj = {};
          if(this.isReferSaleProduct){
            this.setSkuResults(res)
          }
          this.specList = res || [];
          res && res.forEach((arr, index) => {
            if (arr.lsSpecItem && arr.lsSpecItem.length) {
              let idx = arr.lsSpecItem.findIndex((v) => {
                return skuArr.includes(v.cSpecItemName || v.specItemName);
              }); // 找到选中项
              arr.lsSpecItem.map((v) => {
                v.iSpecCode = arr.code;
              });
              idx = idx !== -1 ? idx : 0;
              this.selectedSkuId.push(arr.lsSpecItem[idx].id); // 设置选中
              this.specItemObj[arr.lsSpecItem[idx].iSpecCode] = arr.lsSpecItem[idx].id;
              if(this.isReferSaleProduct){
                this.selectedSku(arr.lsSpecItem[idx], index, 1)
              }
            }
          });
          resolve();
        });
      });
    },
    setSkuResults(res){
      this.specItemList = _.cloneDeep(res)
      let source = [];
      res.forEach(item1 => {
        let arr = []
        item1.lsSpecItem.forEach(item2 => {
          arr.push(item2.id)
        });
        source.push(arr)
      });
      this.combine(source)

      this.skuResults.map((v)=>{
        this.lsSpecResults.push(v.join(','))
        this.lsSpecResultsF.push(v.join(';'))
      })
    },
    combine(arr, index = 0) {
      arr[index].forEach(item => {
        this.skuTemp[index] = item

        index + 1 < arr.length
          ? this.combine(arr, index + 1)
          : this.skuResults.push(this.skuTemp.slice())
      })
    },
    compare(a, b){
        const a1 = a.substr(0, 16);
        const a2 = a.substr(16, a.length-16);
        const b1 = b.substr(0, 16);
        const b2 = b.substr(16, b.length-16);
        if(a.length > b.length){
          return 1
        }else if(a.length < b.length){
          return -1
        }else{
          if (Number(a1) > Number(b1) || (Number(a1) === Number(b1) && Number(a2) > Number(b2))) {
              return a1-b1 || a2-b2
          } else if (Number(a1) < Number(b1) || (Number(a1) === Number(b1) && Number(a2) < Number(b2))) {
              return a1-b1 || a2-b2
          }
        }
    },
    selectedSku(skuVal, index, first) {
      const self = this;
      let specItemIds = []
      this.specList.forEach((specItem, i) => {
        specItem.lsSpecItem.forEach(item => {
          if (specItem.id === skuVal.iSpecId) {
            item.isSelected = item.id === skuVal.id;
          }
          if(item.isSelected && i <= index){
            specItemIds.push(item.id)
          }
        });
      });
      if(this.specList[index+1] && index < this.specList.length){
        this.specList.map((v, i)=>{
          if(i > index){
            v.lsSpecItem.map((item)=>{
              this.$set(item, 'disabled', false)
              this.$set(item, 'isSelected', false)
            })
          }
        })
        this.specList[index+1].lsSpecItem.forEach((item)=>{
          let speciIdsStr = specItemIds.concat(item.id).join(';');
          let speciIdsArr = this.lsSpecResultsF.filter((str)=>{
            return str.includes(speciIdsStr)
          })

          //只要存在一项也不用置灰
          let speciIdsExist =speciIdsArr.some((speciIds)=>{
              let specItemIds = speciIds.split(';')
              specItemIds.sort(function (val1, val2) {
                  return self.compare(val1, val2);
              });
              let v = specItemIds.join(';');
              return this.lsProductSkus.find((j) => {
                let a = j.speciIds.split(';');
                a.sort(function(val1, val2) {
                  return self.compare(val1, val2);
                });
                let b = a.join(';');
                return b.includes(v);
              })
          })

          if(!speciIdsExist){
            item.disabled = true;

          }
        })
        if(!first){
          let fIndex = this.specList[index+1].lsSpecItem.findIndex((j) => {
            return !j.disabled
          })
          this.handleCheck(this.specList[index+1].lsSpecItem[fIndex], index+1)
        }

      }
    }
  }
};
</script>
<style lang="less" scoped>
.select-product-sku {
    .product-sku-content{
      padding: 16px;
      .product-sku-no-data {
        margin: 20px;
      }
      .product-sku-row {
        line-height: 30px;
        overflow: hidden;
        &:last-child {
          margin-bottom: 0;
        }
        .product-sku-label {
          padding-right: 15px;
          width: 65px;
          float: left;
          line-height: 22px;
          cursor: default;
        }
        .product-sku-items {
          width: 488px;
          float: left;
          .product-sku-item {
            border: 1px solid #e4e4e4;
            border-radius: 4px;
            line-height: 20px;
            min-height: 22px;
            cursor: pointer;
            margin-right: 8px;
            margin-bottom: 8px;
            padding: 0 12px;
            text-align: left;
            display: inline-block;
            vertical-align: middle;
            &:hover{
              border: 1px solid #505766
            }
          }
          .active {
            border: 1px solid #505766;
          }
          .disabled{
            color: #e4e4e4;
            pointer-events: none;
          }
        }
      }
    }
    /deep/.ivu-modal{
      width: 600px !important;
      .ivu-modal-body{
        min-height: 359px;
      }
    }
}
</style>
