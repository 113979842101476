<template>
  <div class="trading-data-wrapper">
    <ul class="agent-num">
      <li
        v-for="(data,index) in tradingData"
        :key="index"
        class="left">
        <a
          class="link"
          href="javascript:void(0)"
          :fieldid="`fieldid_tradingData_tradingMsg${index}`"
          @click="toOrder(data)">
          <span class="text">{{ data.name }}</span>
          <span class="num">{{ data.value }}</span>
        </a>
      </li>
      <!-- <li
        class="right">
        <a
          class="link"
          href="javascript:void(0)"
          @click="$util.linkTo(data[index].link)">
          <span class="num">{{ data[index].value }}</span>
          <span class="text">{{ data[index].name }}</span>
        </a>
      </li> -->
    </ul>
  </div>
</template>
<script>
import { getTradingData } from '@/api/order.js';
export default {
  name: 'TradingData',
  data() {
    return {
      tradingData: [],
      isTourist: localStorage.tourist === 'true'
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    toOrder(data) {
      if (!this.currentUser.__isRepresent) {
        this.agentLink(data.link);
      }
    },
    init() {
      getTradingData().then((res) => {
        this.tradingData = [
          {
            name: this.mlang('toBeConfirm'),
            value: res.notCONFIRMORDER,
            link: this.isTourist || !this.$hasAuth('agentOrderShow') ? 'javascript:void(0);' : { type: 'agentOrderShow', query: { menuid: 'agentOrderShow', cOrderStatus: 'CONFIRMORDER', hash: 'salesOrder' } }
          },
          {
            name: this.mlang('toBeDelivery'),
            value: res.notDELIVERGOODS,
            link: this.isTourist || !this.$hasAuth('agentOrderShow') ? 'javascript:void(0);' : { type: 'agentOrderShow', query: { menuid: 'agentOrderShow', cOrderStatus: 'DELIVERGOODS', hash: 'salesOrder' } }
          },
          {
            name: this.mlang('toBePay'),
            value: res.notPAYMENT,
            link: this.isTourist || !this.$hasAuth('agentOrderShow') ? 'javascript:void(0);' : { type: 'agentOrderShow', query: { menuid: 'agentOrderShow', cOrderStatus: 'PAYMENT', cOrderPayStatus: 'PAYMENT', hash: 'salesOrder' } }
          },
          {
            name: this.mlang('toBeGoods'),
            value: res.notTAKEDELIVERY,
            link: this.isTourist || !this.$hasAuth('agentOrderShow') ? 'javascript:void(0);' : { type: 'agentOrderShow', query: { menuid: 'agentOrderShow', cOrderStatus: 'TAKEDELIVERY', hash: 'salesOrder' } }
          }
        ];
      });
    }
  }
};
</script>
<style lang="less" scoped>
.trading-data-wrapper {
  .agent-num {
    display: flex;
    flex-wrap: wrap;

    a {
      height: 100%;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
