import { productOrg } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const productOrgRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      refReturnValue: 'name',
      refName: 'name',
      // 传递过去的参数
      passedparams: [
      ],
      columns: [
        {
          showCaption: vm.mlang('name'),
          isShowIt: true,
          fieldName: 'fullName'
        }
      ],
      actions: { queryList: productOrg },
      type: 'string',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default productOrgRefer;
