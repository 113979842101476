import ajax from '@/libs/ajax';
// 公告设置列表
export const getNoticeSettings = (data) => {
  return ajax.request({
    url: '/archives/notice/queryschema',
    method: 'post',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 公告设置列表删除
export const delNoticeSettings = (id) => {
  return ajax.request({
    url: `/archives/notice/${id}`,
    method: 'post',
    data: { isArchive: 1 }
  });
};
// 公告列表批量删除
export const batchDeleteNoticeSettings = (ids) => {
  let idsStr = ids.join(',');
  return ajax.request({
    url: `/archives/notice/${idsStr}`,
    method: 'post',
    data: { isArchive: 1 }
  });
};
// 发布
export const publishNoticeSettings = (id) => {
  return ajax.request({
    url: `archives/notice/${id}/publish`,
    method: 'post',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 失效
export const invalidNoticeSettings = (id) => {
  return ajax.request({
    url: `/archives/notice/${id}/expired`,
    method: 'post',
    data: {
      isArchive: 1
    }
  });
};
// 获取公告分类
export const getNoticeClasses = (data) => {
  return ajax
    .request({
      url: '/archives/notice/category/list',
      method: 'get',
      data: { isArchive: 1 }
    })
    .then((res) => {
      return res;
    });
};
// 公告设置详情
export const getNoticeDetail = (id) => {
  return ajax
    .request({
      url: '/bill/detail',
      method: 'get',
      data: {
        isMarket: 1,
        billnum: 'noticesetting',
        rowAuthControl: 0,
        id
      }
    })
    .then((data) => {
      if (data) {
        data.dateRange = [data.startDate, data.endDate];
      }

      return data;
    });
};
// 公告设置详情
export const editNoticeSetting = (id) => {
  return ajax
    .request({
      url: `/archives/notice/${id}`,
      method: 'get',
      data: {
        isArchive: 1
      }
    })
    .then((data) => {
      data.dateRange = [data.startDate, data.endDate];
      return data;
    });
};
// 公告设置详情保存
export const saveNoticeSetting = (data) => {
  return ajax.request({
    url: '/archives/notice/save',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
// 获取公告类别
export const noticeTypeAction = (data) => {
  return ajax.request({
    url: '/archives/notice/category/list',
    method: 'get',
    data: {
      orgId: data.orgId,
      isArchive: 1
    }
  });
};

// 获取公告类型
export const noticeTypesAction = (data) => {
  return ajax.request({
    url: '/pc/Enums/getNoticeTypes',
    method: 'get',
    data
  });
};
