import { getCorprationFinancialList } from '@/api/agentPayment/payment.js';
import { deepCopy } from '@/u-components/utils/assist';
const corpFinancialRefer = (ctrl, vm) => {
  let orgId = vm.payment.settlementOrgId;
  let bizId = vm.payment.bizId;
  const control = Object.assign(
    {},
    {
      title: vm.mlang('corpFinancial'),
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      page: true,
      queryschema: [
        {
          name: 'bankName',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('accountOpeningBranch'),
          conditionType: 'like'
        },
        {
          name: 'bankAccount',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('bankNo'),
          conditionType: 'like'
        }

      ],
      passedparams: [{
        conditionType: 'eq',
        isDefine: false,
        isOuterField: false,
        name: 'orgId',
        v1: orgId,
        valueType: 'LONG'
      },
      {
        conditionType: 'eq',
        isDefine: false,
        isOuterField: false,
        name: 'bizId',
        v1: bizId,
        valueType: 'LONG'
      },
      {
        conditionType: 'eq',
        isDefine: false,
        isOuterField: false,
        name: 'activate',
        v1: true,
        valueType: 'BOOLEAN'
      }],
      columns: [
        {
          showCaption: vm.mlang('bank'),
          isShowIt: true,
          fieldName: 'bank',
          width: 180,
          render: (h, params) => {
            return h('span', {
              class: 'two-line-overflow-hide'
            }, [
              (params.row[params.column.fieldName]['name']),
              (params.row.isDefault ? h('span', { class: 'default-bank' }, vm.mlang('default')) : '')
            ]);
          }
        },
        {
          showCaption: vm.mlang('accountOpeningBranch'),
          fieldName: 'bankName',
          width: 300,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('accountUser'),
          fieldName: 'accountUser',
          width: 170,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('bankAccount'),
          isShowIt: true,
          width: 170,
          fieldName: 'bankAccount'
        },
        {
          showCaption: vm.mlang('accountOpeningArea'),
          isShowIt: true,
          width: 170,
          fieldName: 'region'
        }
        // {
        //   showCaption: vm.mlang('operate'),
        //   fieldName: 'action',
        //   isShowIt: true,
        //   width: 200,
        //   align: 'center',
        //   fixed: 'suspend',
        //   render: (h, params) => {
        //     return params.row.isDefault ? '' : h('span',
        //       {
        //         class: 'action-btn',
        //         style: {
        //           cursor: 'pointer'
        //         },
        //         on: {
        //           click: () => {
        //             vm.setCorpDefault(params.row.id);
        //           }
        //         }
        //       },
        //       vm.mlang('setDefault')
        //     );
        //   }
        // }

      ],
      beforeQuery(query) {
        if (!orgId) {
          vm.$Message.warning({ content: vm.mlang('pleaseSelectOrg') });
          return false;
        }
        return query;
      },
      actions: { queryList: getCorprationFinancialList },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default corpFinancialRefer;
