// import util from '@/libs/util.js';
// import _ from 'lodash';
import { delSaleReturn, delYsSaleReturn, checkagent, saleReturnBack, getMerchantIdApi } from '@/api/salereturn';
import { getDafaultStock, getAllStock } from '@/api/delivery';
import { getStockByOrg } from '@/api/refer-select';

const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {},

  methods: {
    mixin_agentDelSaleReturn(params, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('deletecONFIRM'),
        onOk: () => {
          if (vm.currentUser.__isU8C) {
            params.row.saleReturnStatus = params.row.cSaleReturnStatus;
            if (params.row.cSource) params.row.source = params.row.cSource;
            delYsSaleReturn(params.row).then((data) => {
              vm.$Message.success(vm.mlang('delSuccess'));
              vm.dispatch('VoucherList', 'reload');
            });
          } else {
            delSaleReturn(params.row.cSaleReturnNo).then((data) => {
              vm.$Message.success(vm.mlang('delSuccess'));
              vm.dispatch('VoucherList', 'reload');
            });
          }
        }
      });
    },
    mixin_agentSaleReturnExeute(params, vm) {
      vm.$Modal.confirm({
        content: vm.mlang('changeRealQuantityTip'),
        onOk: () => {
          let agid = '';
          if (vm.currentUser.__isRepresent) {
            agid = vm.currentUser.__upAgentId;
          } else {
            agid = vm.currentUser.agentId;
          }
          checkagent(agid).then((data) => {
            if (data) {
              this.getMerchantId(params, vm);
            } else {
              this.returnConfirm(params, vm);
            }
          });
        }
      });
    },
    // ys情况下，订货端点击退货不提示退货计价数量可改
    mixin_ysAgentSaleReturnExeute(params, vm) {
      let agid = '';
      if (vm.currentUser.__isRepresent) {
        agid = vm.currentUser.__upAgentId;
      } else {
        agid = vm.currentUser.agentId;
      }
      checkagent(agid).then((data) => {
        if (data) {
          this.getMerchantId(params, vm);
        } else {
          this.returnConfirm(params, vm);
        }
      });
    },
    getMerchantId(params, vm) {
      getMerchantIdApi(params.row.agent).then((data) => {
        let isOpenStock = vm.$getBO('OPENSTOCK');
        if (isOpenStock) {
          params.row.merchantId = data.id;
          this.getStock(params, vm); // 获取库存类表
        } else {
          this.returnConfirm(params, vm);
        }
      });
    },
    async returnConfirm(params, vm) {
      const currentUser = vm.$store.getters.currentUser;
      const merchants_2Bor2C = currentUser.docType && (currentUser.docType.includes('3') || currentUser.docType.includes('5'));
      if (merchants_2Bor2C) {
        const DafaultStock = await getDafaultStock(params.row?.deliverierId, params.row?.returnStore);
        const allStock = await getAllStock(params.row?.agent);
        vm.$store.commit('setPickStockModalShow', true);
        vm.$store.commit('setAllStock', allStock);
        vm.$store.commit('setDefaultStockId', DafaultStock?.id);
        vm.$store.commit('setTemporaryDeliveryId', params.row.id);
      } else {
        vm.$Modal.confirm({
          content: vm.mlang('alreadySentReturn'),
          onOk: () => {
            let postData = {
              cSaleReturnNo: params.row.cSaleReturnNo
            };
            if (params.row.deliveryStockId) {
              postData['stockId'] = params.row.deliveryStockId;
            }
            saleReturnBack(postData).then((data) => {
              vm.$Message.success(vm.mlang('waitForSignIn'));
              vm.dispatch('VoucherList', 'reload');
            });
          }
        });
      }
    },
    getStock(params, vm) {
      let postData = {
        bizId: params.row.merchantId
        //  saleOrgId: saleReturn.salesOrgId
      };
      getStockByOrg(postData).then((res) => {
        if (res && res.length) {
          vm.stockList = res;
        }
        if (res.length === 0) {
          vm.$Modal.warning({
            content: vm.mlang('pleaseSetStockFiles')
          });
        } else if (res.length === 1) {
          params.row.deliveryStockId = res[0].id;
          this.returnConfirm(params, vm);
        } else {
          this.selectStock(params, res);
        }
      });
    },
    selectStock(params, res) {
      const _this = this;
      _this.control.editRows[0].enumArray = [];
      res.map((v, index) => {
        _this.control.editRows[0].enumArray.push({
          'key': v.id,
          'text': v.name
        });
      });
      _this.isShowModal = true;
      _this.rowData = params;
    }
  }
};

export default mixin;
