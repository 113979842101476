import ajax from '@/libs/ajax';

// 潜在客户
export const getPotentialAgents = (data) => {
  return ajax
    .request({
      url: '/agent/infrastructure/potentialagent/query',
      // url: '/pc/agent/PotentialAgentSrv/getPotentialAgentSchema',
      method: 'post',
      data: {
        datatype: 'json',
        isArchive: 1,
        data: data.querySchema
      }
      // data: { querySchema: data.querySchema, datatype: 'json' }
    })
    .then((res) => {
      return res;
    });
};
export const saveCustomerGroup = (data) => {
  if (data.type === 'Other') {
    data.agentDimension = false;
  } else {
    data.agentDimension = true;
  }
  return ajax.request({
    url: '/auth/infrastructure/agentgroup',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const customerGroupDetail = (customerGroupId) => {
  return ajax.request({
    url: `/auth/infrastructure/agentgroup/${customerGroupId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// data.querySchema
export const getCustomerGroups = (data) => {
  return ajax
    .request({
      url: '/auth/infrastructure/agentgroup/queryschema',
      method: 'post',
      data: {
        data: data.querySchema,
        datatype: 'json',
        isArchive: 1
      }
    })
    .then((res) => {
      return {
        content: res
      };
    });
};
export const yytest = () => {
  return ajax
    .request({
      url: '/pc/agent/AgentDataAuthGroupSrv/getAgentAuthGroupList',
      method: 'get'
    })
    .then((res) => {
      return {
        content: res
      };
    });
};

export const delCustomerGroup = (id) => {
  return ajax.request({
    url: '/pc/agent/AgentDataAuthGroupSrv/delAgentDataAuthGroup',
    method: 'post',
    data: {
      id: id
    }
  });
};
export const delCustomer = (agentGroupId, agentId) => {
  return ajax.request({
    url: `/auth/infrastructure/agentgroup/${agentGroupId}/agent/${agentId}`,
    method: 'delete',
    data: {
      agentId,
      agentGroupId,
      isArchive: 1
    }
  });
};

export const approveAgent = (data) => {
  data.datatype = 'json';
  return ajax.request({
    url: '/pc/agent/PotentialAgentSrv/approveAgent',
    method: 'post',
    data
  });
};
export const delPotentialAgent = (id) => {
  return ajax.request({
    url: `/agent/infrastructure/${id}/potentialagent`,
    method: 'delete',
    data: {
      id: id,
      isArchive: 1
    }
  });
};

export const saveCustomerLevel = (data) => {
  delete data.erpCode;
  return ajax.request({
    url: '/agent/infrastructure/agentlevel',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const customerLevelDetail = (CustomerLevelId) => {
  return ajax.request({
    url: `/agent/infrastructure/agentlevel/${CustomerLevelId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const getCustomerLevels = () => {
  return ajax
    .request({
      url: '/agent/infrastructure/agentlevel/list?isArchive=1',
      method: 'get'
    })
    .then((res) => {
      return {
        content: res
      };
    });
};

export const delCustomerLevel = (id) => {
  return ajax.request({
    url: '/agent/infrastructure/agentlevel/' + id,
    method: 'delete',
    data: {
      id: id,
      isArchive: 1
    }
  });
};

export const saveIndustrySetting = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/industry',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};
export const industrySettingDetail = (industrySettingId) => {
  return ajax.request({
    url: `/agent/infrastructure/industry/${industrySettingId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const getIndustrySettings = () => {
  return ajax
    .request({
      url: '/agent/infrastructure/industrys?isArchive=1',
      method: 'get'
    })
    .then((res) => {
      return {
        content: res
      };
    });
};

export const delIndustrySetting = (id) => {
  return ajax.request({
    url: '/agent/infrastructure/industry/' + id,
    method: 'delete',
    data: {
      id: id,
      isArchive: 1
    }
  });
};
// 客户资金账户列表
export const getCustomerFundAccount = (data) => {
  return ajax
    .request({
      url: '/voucher/payment/account/getFundAccountSchema',
      method: 'post',
      data: {
        datatype: 'json',
        data: data.querySchema,
        isArchive: 1
      }
    });
};
// 客户资金账户导出
export const exportFundAccount = (data) => {
  return ajax.request({
    url: '/pc/PaymentSrv/exportFundAccounts',
    method: 'post',
    data: {
      datatype: 'json',
      querySchema: data.querySchema
    }
  });
};

// 客户资金账户费用列表
export const getCustomerFundAccountRebate = (data) => {
  return ajax
    .request({
      url: '/voucher/rebate/getRebateBalanceSchema',
      method: 'post',
      data: {
        datatype: 'json',
        data: data.querySchema,
        isArchive: 1
      }
    });
};
// 客户资金账户预付款列表
export const getCustomerFundAccountPayMoney = (data) => {
  return ajax
    .request({
      url: '/voucher/payment/paymentvoucher/getNotUsedPaymentsSchema',
      method: 'post',
      data: {
        datatype: 'json',
        data: data.querySchema,
        isArchive: 1
      }
    });
};
// 获取企业类型、经营模式、年营业额和经营范围
export const getTypes = () => {
  return ajax.request({
    url: '/agent/infrastructure/optiontype/enum',
    method: 'post',
    data: {
      data: ['CorpType', 'TurnoverType', 'BusinessType'],
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const savePotentialAgent = (data) => {
  return ajax.request({
    url: '/agent/infrastructure/potentialagent',
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data
    }
  });
};

// 组织关系
export const getOrgRelationList = (v1) => {
  let data = {
    conditions: [
      { name: 'agentId', v1: v1, valueType: 'LONG', conditionType: 'eq' },
      {
        conditionType: 'eq',
        isOuterField: false,
        isSkip: false,
        name: 'isContainBiz',
        v1: true,
        valueType: 'BOOLEAN'
      },
      {
        conditionType: 'eq',
        isOuterField: false,
        isSkip: false,
        name: 'isContainOrg',
        v1: true,
        valueType: 'BOOLEAN'
      },
      { name: 'activate', v1: true, valueType: 'BOOLEAN', conditionType: 'eq' }
    ],
    pager: {
      pageIndex: 1,
      pageSize: 300
    }
  };
  return ajax.request({
    url: '/agent/infrastructure/agents/current/0',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const getPotentialAgent = (data) => {
  return ajax.request({
    url: `/agent/infrastructure/${data.agentId}/potentialagent?isArchive=1`,
    method: 'get'
  });
};

export const getNewProtentialAgent = () => {
  return ajax.request({
    url: '/agent/infrastructure/potentialagent/new?isArchive=1',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 客户分组 客户自定义项维度参照服务   获取客户自定义项1-5 启用
export const getCustomerdefines = () => {
  return ajax.request({
    url: '/meta/bill/define/customerdefines/agentgroup',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 潜在客户核准
export const agreeCheckRepeat = (potentialAgentId) => {
  return ajax.request({
    url: `/agent/infrastructure/${potentialAgentId}/pretreatment`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const getPoints = (order) => {
  let url = '/uhy/calculate/points/apportion';
  return ajax.request({
    url,
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data: order
    }
  });
};
// /uhy/clean/points   post 请求
export const cleanPoints = (order) => {
  let url = '/uhy/clean/points';
  return ajax.request({
    url,
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data: order
    }
  });
};
// 客户维度的客户分组分页显示
export const getAgentDetail = (data) => {
  return ajax.request({
    url: '/auth/infrastructure/agentgroup/agentgroupruleline/queryschema',
    method: 'post',
    data: {
      isArchive: 1,
      datatype: 'json',
      data
    }
  });
};

// 客户协议
export const getIsConfirmedServiceTerms = () => {
  return ajax.request({
    url: '/archives/terms/isConfirmedServiceTerms?isArchive=1',
    method: 'get'
  });
};

// 客户点击同意后处理
export const confirmServiceTerms = () => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/archives/terms/serviceterms/confirm?isArchive=1' : '/pc/basic/ServiceTermsSrv/confirmServiceTerms',
    method: 'post'
  });
};
