<template>
  <div
    v-if="enable"
    class="pull-left importAndOutPut-btn-box btn-box">
    <Dropdown
      placement="bottom-start"
      class="btn-item"
      @on-click="exeImport">
      <Button
        :type="btnType"
        class="batchBtn">
        {{ mlang('import') }}
        <Icon type="arrow-down-b"></Icon>
      </Button>
      <DropdownMenu slot="list">
        <DropdownItem name="importFileDown">
          {{ mlang('importFileDown') }}
        </DropdownItem>
        <DropdownItem name="import">
          <Upload
            :action="action"
            :data="importData"
            :before-upload="beforeUpload"
            :on-success="handleImportSuccess"
            :on-error="handleImportError"
            :on-progress="handleProcess"
            :show-upload-list="false"
            accept=".xls, .xlsx">
            <div style="width:80px">
              {{ mlang('import') }}
            </div>
          </Upload>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import { getImportStatus, getImportExportStatus, getImportTmpl, getImportTmplYS, getImportExportStatusYS, getSystemToken } from '@/api/import';
export default {
  name: 'ImportExcel',
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    btnType: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      uploadingFileName: '',
      importData: {
        type: null
      },
      ys: {
        token: undefined,
        asyncKey: ('asyncImport_' + this.$util.uuid(32).replace('pc-', ''))
      }
    };
  },
  computed: {
    enable() {
      let enable = false;
      if (this.bizMeta.import) {
        if (this.bizMeta.import.auth) {
          const auth = this.$hasAuth(this.bizMeta.import.auth);
          if (auth) {
            enable = true;
          }
        } else {
          return true;
        }
      }
      return enable;
    },
    isNewImport() {
      const { enumName } = this.bizMeta.import;
      return !!enumName;
    },
    isYsImport() {
      return this.bizMeta.import && !!(this.bizMeta.import.ysParam);
    },
    action() {
      let action = '';
      const { url, enumName, params, ysParam, yhtToken } = this.bizMeta.import;
      if (this.isYsImport) {
        let token = yhtToken || this.ys.token;
        action = '/upload/mdf-node/bill/billImport?isU8C=1&yhttoken=' + token; // ys导入
      } else if (this.isNewImport) {
        action = `/upload/udinghuo/batch/import/${enumName}?importExcel=2`; // 新导入;
      } else {
        // 旧导入
        if (url.indexOf('isMarket') === -1) {
          action = url.indexOf('?') > -1 ? `${url}&importExcel=1` : `${url}?importExcel=1`;
        } else {
          action = url;
        }
      }
      if (ysParam) {
        let fileName = ysParam['fileName'];
        delete ysParam['fileName'];
        ysParam['asyncKey'] = this.ys.asyncKey;
        const query = '&' + this.$util.stringifyQuery(ysParam);
        action += query;
        ysParam['fileName'] = fileName;
      }
      if (params) {
        const query = '&' + this.$util.stringifyQuery(params);
        action += query;
      }
      return action;
    }
  },
  created() {
    if (this.isYsImport) {
      getSystemToken().then((token) => {
        this.ys.token = token;
        if (this.bizMeta.import.yhtToken) {
          this.ys.token = this.bizMeta.import.yhtToken;
        }
      });
    }
  },
  methods: {
    exeImport(name) {
      if (name === 'importFileDown') {
        const { enumName } = this.bizMeta.import;
        if (this.bizMeta.import.tempUrl) {
          this.$open(this.bizMeta.import.tempUrl);
          return;
        }
        if (this.isYsImport) {
          this.cdrDownFile(this.bizMeta.import.ysParam);
          return;
        }
        getImportTmpl(enumName).then((data) => {
          this.$open(data.filePath);
        });
      }
      if (name === 'import') {
        this.updateAsyncKey();
      }
    },
    updateAsyncKey() {
      this.ys.asyncKey = ('asyncImport_' + this.$util.uuid(32).replace('pc-', ''));
    },
    // 用于测试
    downLoadFile(params) {
      getImportTmplYS(params).then((data) => {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' }); // 传入一个合适的 MIME 类型
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.download = 'data.xlsx';
        a.href = url;
        a.click();
        // this.$open(data.filePath);
      });
    },
    cdrDownFile(params) {
      const eleForm = document.createElement('form');
      let ysDownFileUrl = '';// window.__app.context && window.__app.context.ysDownFileUrl;
      eleForm.method = 'post';
      eleForm.action = (ysDownFileUrl + '/api/mdf-node/files/billtemp/export?domainKey=udinghuo&isU8C=1&isStream=true&token=' + this.ys.token);
      eleForm.style.display = 'none';
      const eleInput = document.createElement('input');
      eleInput.name = 'json';
      eleInput.value = JSON.stringify(params);
      const csrf = document.createElement('input');
      csrf.type = 'hidden';
      csrf.name = '_csrf';
      csrf.value = window.__app.context.csrf;
      eleForm.appendChild(csrf);
      document.body.appendChild(eleForm);
      eleForm.appendChild(eleInput);
      eleForm.submit();
      document.body.removeChild(eleForm);
    },
    handleProcess(event, file, fileList) {
      this.$emit('on-uploading', 0, file);
    },
    handleOldResult(response, file, fileList) {
      if (response.code === 200) {
        if (this.bizMeta.import.marketImport) {
          this.resultCale(response.data, file, true);
        } else {
          this.resultCale(response.data, file);
        }
      } else {
        this.$Modal.error({
          content: response.message
        });
        // this.$emit(
        //   'on-upload-success',
        //   {
        //     errorCount: 1
        //   },
        //   file
        // );
      }
    },
    handleNewResult(response, file, fileList) {
      if (response.code !== 200) {
        this.$Modal.error({
          content: response.message
        });
        // this.$emit(
        //   'on-upload-success-new',
        //   {
        //     errorCount: 1
        //   },
        //   file
        // );
      } else {
        this.getImportStatusNew(response.data, file);
      }
    },
    handleNewResultYS(response, file, fileList) {
      if (response.code !== 200) {
        this.$Modal.error({
          content: response.message
        });
      } else {
        this.getImportStatusNewYS(response.data, file);
      }
    },
    handleResult() {
      if (this.isYsImport) {
        this.handleNewResultYS(...arguments);
      } else if (this.isNewImport) {
        this.handleNewResult(...arguments);
      } else {
        this.handleOldResult(...arguments);
      }
    },
    handleImportSuccess() {
      this.handleResult(...arguments);
    },
    handleImportError(response, file, fileList) {
      this.$Modal.error({
        content: this.mlang('uploadErrorMsg')
      });
    },
    complete(result, file) {
      this.$emit('on-upload-success', result, file);
    },
    getImportStatusNew(data, file) {
      setTimeout(() => {
        getImportExportStatus(data.jobName).then((res) => {
          if (res.status === 'COMPLETED') {
            // 导入完成
            this.$emit('on-upload-success-new', res, file);
          } else if (res.status === 'FAILED' || res.status === 'STOPPED') {
            // 导入失败 弹窗相关的部分
            this.$Modal.warning({
              content: (res.message || this.mlang('importWarning'))
            });
            //
          } else {
            this.$emit('on-uploading', res.writeCount, file);
            this.getImportStatusNew(data, file);
          }
        });
      }, 1000);
    },
    getImportStatusNewYS(data, file) {
      setTimeout(() => {
        getImportExportStatusYS(this.ys.asyncKey, this.ys.token).then((result) => {
          let res;
          let status = 'CONTINUE';
          let errStr = '';
          try {
            res = JSON.parse(result);
          } catch (e) {
            errStr = `${this.mlang('falidToImportTemplateContent')},${this.mlang('returnedMessages')}:${result}`;
            status = 'FAILED';
            this.finallyhandleAsyncImport(status, errStr, res);
            return;
          }
          const percentage = parseFloat(res.percentage || '0');
          switch (true) {
            case (res.flag === 0):
              errStr = res.count === 0 ? this.mlang('noValidDataFoundInTheTemplate') : this.mlang('falidToImportTemplateContent');
              if (res.data) { errStr = `${errStr},${this.mlang('returnedMessages')}:${res.data}`; }
              status = 'FAILED';
              break;
            case (res.flag === 1 && percentage >= 100):
              if (res.message && Array.isArray(res.message)) res.errorRecords = res.message;
              status = 'COMPLETED';
              break;
          }
          if (status === 'CONTINUE') {
            this.getImportStatusNewYS(data, file);
            return;
          }
          this.finallyhandleAsyncImport(status, errStr, res);
        });
      }, 1000);
    },
    finallyhandleAsyncImport(status, errStr, res) {
      switch (status) {
        case 'COMPLETED':
          this.$emit('on-upload-success-ys', res);
          break;
        case 'FAILED':
          this.$Modal.warning({
            content: (errStr || this.mlang('importWarning'))
          });
          break;
      }
    },
    getImportStatusOld(file) {
      let vm = this;
      setTimeout(() => {
        if (vm.bizMeta.import.getImportStatusFn && this.bizMeta.import.marketImport) {
          vm.bizMeta.import.getImportStatusFn().then((res) => {
            vm.resultCale(res, file, true);
          });
        } else {
          getImportStatus(this.bizMeta.import.statusAction).then((res) => {
            vm.resultCale(res, file);
          });
        }
      }, 1000);
    },
    // 处理状态
    resultCale(resultList, file, marketImport) {
      if (marketImport) {
        if (resultList && resultList.flag === 1 && resultList.data && resultList.data.length) {
          this.complete(resultList.data[0], file);
        } else if (resultList && resultList.flag === 0) {
          this.$Modal.warning({
            content: resultList.message
          });
        } else {
          this.$emit('on-uploading', 0, file);
          this.getImportStatusOld(file);
        }
      } else {
        if (resultList.length) {
          if (resultList[1]) {
            // 导入完成
            resultList[0].errorFile = resultList[3];
            this.complete(resultList[0], file);
          } else {
            const edCount = resultList[2];
            if (edCount) {
              this.$emit('on-uploading', edCount.executeCount, file);
            }
            this.getImportStatusOld(file);
          }
        }
      }
    },
    setData(v) {
      this.importData = v;
    },
    beforeUpload() {
      if (this.bizMeta.import.beforeImport) { // 兼容旧的 只设置 type
        this.importData.type = this.bizMeta.import.beforeImport ? this.bizMeta.import.beforeImport() : null;
      } else if (this.bizMeta.import.beforeUpload) {
        const fn = () => {
          return this.bizMeta.import.beforeUpload(this.setData);
        };
        return fn();
      }
    }
  }
};
</script>
