import { selectBizs } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const agentsRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      title: vm.mlang('agentName'),
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      fieldName: 'iAgentId',
      controlType: 'Refer',
      refReturnValue: 'agentId',
      reffieldName: 'name',
      refName: 'name',
      queryschema: [
        {
          name: 'name',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('name'),
          conditionType: 'like'
        },
        {
          name: 'code',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('code'),
          conditionType: 'rlike'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('code'),
          isShowIt: true,
          fieldName: 'code',
          width: 120
        },
        {
          showCaption: vm.mlang('name'),
          fieldName: 'name',
          width: 180,
          isShowIt: true
        }
      ],
      actions: { queryList: selectBizs },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default agentsRefer;
