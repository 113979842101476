<template>
  <div
    class="handle-record">
    <Poptip
      :options="popTipProps"
      :trigger="popTrigger"
      placement="bottom"
      popper-class="poptip"
      @on-popper-show="onPopperShow">
      <div
        class="handle-record">
        <Icon
          custom="iconfont icon-genzongxinxi"></Icon>
        {{ mlang('following') }}
        <Icon type="arrow-down-b"></Icon>
      </div>
      <div
        slot="content"
        class="payment-status-poptip-content logistics">
        <ul>
          <li
            v-for="(statusRecord,index) in records"
            :key="index"
            :class="{'active':index==0}">
            <dl>
              <!-- <dt>
              </dt> -->

              <dd>{{ statusRecord.oCreater && statusRecord.oCreater.cFullName }} <span>{{ $util.formatToCtxDateTime(statusRecord.dCreated) }}</span></dd>
              <dd class="status">
                {{ statusRecord.cName }}
              </dd>
            </dl>
            <i class="round"><b></b></i>
          </li>
        </ul>
      </div>
    </poptip>
  </div>
</template>

<script>
import PopTipMixin from '@/mixin/poptip.js';
import CellMixin from '@/mixin/cellControl.js';
import { fetchOrderStatusRecordByOrderId, fetchHistoryOrderStatusRecordById } from '@/api/order';
export default {
  name: 'HandleRecord',
  mixins: [PopTipMixin, CellMixin],
  data: function() {
    return {
      records: [],
      hasFetch: false
    };
  },
  methods: {
    onPopperShow() {
      if (this.$route.query.type === 'history') {
        fetchHistoryOrderStatusRecordById(this.params.row.id).then((res) => {
          this.records = res;
        });
      } else {
        if (!this.hasFetch) {
          fetchOrderStatusRecordByOrderId(this.params.row.id || this.params.row.id).then((res) => {
            this.records = res;
            // this.hasFetch = true;
          });
        }
      }
    }
  }
};
</script>
