import { selectChanelAgents } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
import { getAgentSourceTree } from '@/api/refer-tree';
const agentsRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      title: vm.mlang('agentName'),
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      fieldName: 'iAgentId',
      controlType: 'Refer',
      refReturnValue: 'agentId',
      reffieldName: 'name',
      refName: 'name',
      queryschema: [
        {
          name: 'name',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('name'),
          conditionType: 'like'
        },
        {
          name: 'code',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('code'),
          conditionType: 'rlike'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('agentCode'),
          isShowIt: true,
          fieldName: 'code',
          width: 120
        },
        {
          showCaption: vm.mlang('agentName'),
          fieldName: 'name',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('agentArea'),
          isShowIt: true,
          width: 180,
          fieldName: 'agentAreaName'
        },
        {
          showCaption: vm.mlang('agentCategory'),
          isShowIt: true,
          width: 180,
          fieldName: 'agentCategoryName'
        },
        {
          showCaption: vm.mlang('agentLevel'),
          isShowIt: true,
          width: 180,
          fieldName: 'agentLevelName'
        }
      ],
      actions: { queryList: selectChanelAgents, getAgentSourceTree },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  if (ctrl.isShowCustomerSource) {
    control.queryschema.push({
      name: 'customerResourceType',
      controlType: 'TreeSelect',
      valueType: 'STRING',
      groupType: 2,
      placeholder: vm.mlang('agentSource'),
      conditionType: 'eq',
      isShowIt: true,
      action: 'getAgentSourceTree',
      valueKey: 'value',
      nameKey: 'title'
    });
  }
  return deepCopy(control);
};
export default agentsRefer;
