import { oneOf } from '@/u-components/utils/assist';
import dayjs from 'dayjs';

const mixin = {
  data() {
    return {
      c_fixIndexColumn: {
        type: 'index',
        width: 30,
        fixed: 'left',
        align: 'center'
      },
      c_fixSelectionColumn: {
        type: 'selection',
        width: 40,
        fixed: 'left',
        align: 'center'
      },
      c_indexColumn: {
        type: 'index',
        width: 30,
        align: 'center'
      },
      c_selectionColumn: {
        type: 'selection',
        width: 40,
        align: 'center'
      },
      c_radioColumn: {
        type: 'radio',
        width: 30,
        align: 'center'
      }
    };
  },
  mounted() {},
  computed: {
  },

  methods: {
    c_pricePrecision(control) {
      const setting = this.$getPriceExact();
      control.numPoint = () => {
        return setting.unitPricePrecision;
      };
      control.roundType = setting.unitPriceRoundType;
      return control;
    },
    c_isNormalCtrl(controlType) {
      return oneOf(controlType, ['String', 'Date', 'Select', 'TreeSelect', 'TreeSelects', 'Refer', 'DateTimeRange', 'DateRange', 'Number', 'NumberRange', 'TIME', 'DateTime', 'DSelect']);
    },
    c_isRowCtrl(controlType) {
      return oneOf(controlType, ['Enum', 'PredicateDateTime']);
    },
    c_setColumns(columns = []) {
      // columns.map((column, index) => {
      //   if (column.fieldName !== 'action') {
      //     const spiltArr = split(column.name, '.');
      //     if (spiltArr && spiltArr.length > 1) {
      //       column.render = (h, params) => {
      //         const keys = split(column.name, '.');
      //         let formatVal = params.row;
      //         keys.forEach((key) => {
      //           formatVal = formatVal || {};
      //           formatVal = formatVal[key] || '';
      //         });
      //         return h('span', { attrs: { title: formatVal, class: 'two-line-overflow-hide' } }, formatVal);
      //       };
      //     }
      //   }
      // });
      return columns;
    },
    // 切换验证码
    getDeviceUUid() {
      if (localStorage) {
        return localStorage.getItem('udh_device_uuid');
      }
    },
    // 格式化成Date对象
    c_formatArrToDate(v) {
      let value = ['', ''];
      if (v && v[0] && v[1]) {
        const s = dayjs(v[0]).toDate();
        const e = dayjs(v[1]).toDate();
        value = [s, e];
      }
      return value;
    },
    c_renderSpan(h, v) {
      if (v === undefined) {
        return;
      }
      return <span title={ v }>{ v }</span>;
    }

  }
};

export default mixin;
