import _ from 'lodash';
import util from '@/libs/util.js';
import * as filters from '@/filters'; // global filters
import { findComponentsDownward } from '@/u-components/utils/assist';
import { getReadOnlyText } from './commonMethods';
const mixin = {
  data() {
    return {};
  },
  mounted() {},
  computed: {},

  methods: {
    setLoading(v) {
      const voucher = this.$refs.voucher;
      if (voucher) {
        if (v) {
          voucher.setLoading(true);
        } else {
          this.$nextTick(() => {
            voucher.setLoading(false);
          });
        }
      }
    },
    mixin_totalSum(
      arr = [],
      key,
      precision = () => {
        return 2;
      }
    ) {
      let tmpTotal = arr.reduce((prev, cur) => {
        let val = _.round(cur[key], precision(cur));
        return util.numAdd(val, prev);
      }, 0);
      return tmpTotal; // _.round(tmpTotal, precision());
    },
    inputNumberFormatter(item) {
      const fn = (value) => {
        if (item.isPrice) {
          value = filters.formatInputMoney(value);
          if (this.bizMeta && this.bizMeta.inputNumberFormatter) {
            value = this.bizMeta.inputNumberFormatter(item, value);
          }
        }
        return value;
      };
      return fn;
      // this.bizMeta.inputNumberFormatter && this.bizMeta.inputNumberFormatter(item, value);
    },
    inputNumberParser(item) {
      const fn = (value) => {
        if (item.isPrice) {
          value = value.replace(/$s?|(,*)/g, '');
        }
        return value;
      };
      return fn;
    },
    emitChange(option) {
      this.$emit('change', option);
    },
    emitEnter(option) {
      this.$emit('enter', option);
    },
    emitGroupPageChange(pager, group) {
      this.$emit('group-page-change', pager, group);
    },
    setTablesLoading(loading, vm) {
      const tables = findComponentsDownward(vm, 'VoucherTable');
      if (tables) {
        tables.map((table) => {
          table.reloadColumns();
          table.loading = loading;
        });
      }
    },
    isRefRetCtrl(control) {
      return ['Select', 'Refer', 'TreeSelect', 'Radio', 'SelectTab'].includes(control.controlType) && !control.isDefine;
    },
    getKey(path) {
      let key = path;
      if (_.isObject(path)) {
        if (path.isDefine) {
          key = path.fieldName;
        } else {
          if (this.isRefRetCtrl(path)) {
            key = path.refRetId;
          } else {
            key = path.fieldName;
          }
        }
      }
      return key;
    },
    getValue(formData, path) {
      let key = this.getKey(path);
      if (key === undefined) {
        return undefined;
      }
      const value = this.$util.getPropByPath(formData, key).v;
      return value;
    },
    /**
     *
     * @param {*} control billitem
     * @param {*} data 整个对象或者某一行数据
     * @param {*} getAllDataFn data可能为某一行数据，所以此方法为获取整个对象方法
     * @returns
     */
    formatVoucherValue(control, data, getAllDataFn) {
      let isRefRet = this.isRefRetCtrl(control);
      let value = '';
      if (control.formatValue && typeof control.formatValue === 'function') {
        // 只处理cell类型的文本
        return control.formatValue(control, data);
      }
      let valueKey = isRefRet ? control.refRetId : control.fieldName;
      value = this.getValue(data, valueKey);
      // 适配自定义类型基础档案特征
      if (control.fieldType === 20 || control.fieldType === 21) {
        if (data?.archiveDefineMap && typeof data?.archiveDefineMap === 'object') {
          const defineCombName = control.fieldName;
          const defineCombNameArr = defineCombName.split('.');
          const defineName = defineCombNameArr[defineCombNameArr.length - 1];
          let targetValueObject = {};
          if (Object.keys(data.archiveDefineMap).length > 0) {
            targetValueObject = data.archiveDefineMap[defineName];
            return targetValueObject?.name ? String(targetValueObject?.name) : '';
          }
          return '';
        } else {
          return '';
        }
      }
      if (isRefRet && control.controlType !== 'Select' && control.controlType !== 'Radio') { //
        return this.getValue(data, control.fieldName);
      }
      return getReadOnlyText.call(this, value, control, data, getAllDataFn);
    },
    mixin__isRenderCtrl(control) {
      const READONLY_CONTROLS = ['Product', 'Sku', 'Link', 'Enum'];
      return READONLY_CONTROLS.includes(control.controlType);
    },
    actviateGroupItemEditing() {
      const voucher = this.$refs.voucher;
      if (voucher) {
        voucher.actviateGroupItemEditing(...arguments);
      }
    }
  }
};

export default mixin;
