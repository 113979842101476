<template>
  <div v-if="product.id" :class="`product-table-cell ${isShowImg ? 'image-show' : 'image-none'}`">
    <div v-if="isShowImg" class="product-table-cell-left">
      <img :src="product.imgUrl" :onerror="imgError" class="product-img" />
    </div>
    <div class="product-table-cell-body">
      <!-- <div class="productCode">
        <div :class="['one-line-overflow-hide','product-code',{'gift-label': product.cOrderProductType === 'GIFT' }]">
          <span v-if="product.cOrderProductType === 'GIFT'" class="uniform-label"><i>{{ mlang('giveway') }}</i></span> {{ product.code }}
        </div>
      </div> -->
      <div :class="overflowHide">
        <a v-if="isAnchor" :title="product.name" :class="`${overflowHide} productName`" @click="goToProductDetail">{{ product.name }}</a>
        <div v-if="isShowIcon && product.productApportions && product.productApportions.length && params.row.cOrderProductType !== 'GIFT'">
          <PromotionPoptip :product-apportions="product.productApportions" :order-product-type="params.row.cOrderProductType"></PromotionPoptip>
        </div>
        <span v-if="!isAnchor">
          {{ product.name }}
          <div v-if="isShowIcon && product.productApportions && product.productApportions.length && params.row.cOrderProductType !== 'GIFT'">
            <PromotionPoptip :product-apportions="product.productApportions" :order-product-type="params.row.cOrderProductType"></PromotionPoptip>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import CellMixin from '@/mixin/cellControl.js';
import PromotionPoptip from '@/views/agent/orders/components/order/promotionPoptip.vue';

import _ from 'lodash';
export default {
  name: 'ProductTableCell',
  components: {
    PromotionPoptip
  },
  mixins: [CellMixin],
  props: {
    isList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgError: 'this.src="' + require('@/assets/images/ProductDefaultImg.png') + '"'
    };
  },
  computed: {
    overflowHide() {
      let str = `${this.$route.name}ProductOverflow`;
      let step = window.localStorage.getItem(str) && window.localStorage.getItem(str) !== 'undefined' ? window.localStorage.getItem(str) : 'one';
      return `${this.isShowImg ? 'tow' : step}-line-overflow-hide`;
    },
    href() {
      let href = '';
      const isAgent = sessionStorage.isAgentSide === 'true';
      if (!isAgent) {
        href = `/corppage/products/preview?id=${this.product.id}&bizProductId=${this.product.bizProductId || ''}`;
      } else {
        href = `/agentpage/products/index?menuid=agentProductDetail&id=${this.product.id}&bizProductId=${this.product.bizProductId}`;
      }
      return href;
    },
    product() {
      if (this.params.row) {
        if (this.params.row.product) {
          return this.params.row.product;
        }
        return {
          name: this.params.row.cProductName || this.params.row.productName,
          id: this.params.row.iProductId || this.params.row.productId,
          bizProductId: this.params.row.bizProductId,
          code: this.params.row.cProductCode || this.params.row.productCode,
          imgUrl: this.params.row.cProductImgUrl || this.params.row.imgUrl || this.params.row.productImgUrl,
          cOrderProductType: this.params.row.cOrderProductType,
          productApportions: this.params.row.oOrderProductApportions
        };
      }
      return {};
    },
    isAnchor() {
      if (_.isNil(this.params.row.isAnchor)) {
        return true;
      } else {
        return this.params.row.isAnchor;
      }
    },
    isShowImg() {
      return this.column.isShowImg;
    },
    isShowIcon() {
      return this.params.row.isMultiPre;
    }
  },
  methods: {
    goToProductDetail() {
      const isAgent = sessionStorage.isAgentSide === 'true';
      if (!isAgent) {
        let href = `/corppage/products/preview?id=${this.product.id}&bizProductId=${this.product.bizProductId || ''}`;
        this.$util.menuClickOldPage(href, this);
      } else {
        if (window.top === window.self) {
          if (this.product.explosiveProZoneId) {
            this.agentLink({
              type: 'productDetail',
              query: {
                menuid: 'agentProductDetail',
                id: this.product.skuVo && this.product.productVo && this.product.productVo.id,
                bizProductId: this.product.bizProductId,
                shoppingCard: 1,
                source: 'commoditySection',
                skuId: this.product.skuId,
                explosiveProZoneId: this.product.explosiveProZoneId,
                explosiveProZoneProductInfoId: this.product.explosiveProZoneProductInfoId
              }
            });
            return;
          }
          this.agentLink({ type: 'productDetail', query: { menuid: 'agentProductDetail', id: this.product.id, bizProductId: this.product.bizProductId }, newWindow: true });
        } else {
          this.$util.toFrameProductDetail(this.product.id, this.product.bizProductId);
        }
      }
    }
  }
};
</script>
