<template>
  <Row class="summary" type="flex" justify="start">
    <div v-if="!['PointTransaction', 'Coupon'].includes($route.name)" class="summary-search">
      <Row
        v-for="(control,index) in controls"
        v-if="control.fieldName === 'settlementOrgId' ? data.salesOrgId : true"
        :key="`label-${index}`"
        type="flex"
        justify="start">
        <label
          :key="`label-${index}`"
          class="search-label"
          :for="control.fieldName">{{ control.showCaption }}</label>
        <Select
          :key="index"
          v-model="data[control.fieldName]"
          style="width:300px"
          :disabled="control.disabled"
          :fieldid="`select_${control.fieldName}`"
          @on-change="handleChange(control)">
          <Option
            v-for="option in control.enumArray"
            :key="option.value"
            :title="option.title"
            :label="option.title"
            :value="option.value"
            :fieldid="`option_${control.fieldName}`"></Option>
        </Select>
      </Row>
    </div>
    <Row type="flex" style="padding-left: 70px;">
      <template v-for="(item,index) in items">
        <Col v-if="item.to && item.to.name === $route.name" :key="index">
        <summary-item :key="index" :title="item.title" :content="item.content" :to="item.to" />
        </Col>
      </template>
    </Row>
  </Row>
</template>
<script>
import { getAgentFund, getSurplusRebate, getBizs, getSaleOrgs, getSettlementOrgs, getAllSettlementOrgs } from '@/api/agentPayment/payment.js';
import { getCurrency } from '@/api/refer-select.js';
import { getAgentInfoVo } from '@/api/user.js';
import SummaryItem from './summary-item.vue';
import { mapGetters } from 'vuex';
import { upCoinDigit } from '@/filters';
import Bus from './bus.js';
export default {
  name: 'Summary',
  components: {
    SummaryItem
  },
  inject: ['broadcastToExtend'],
  data() {
    return {
      isShowPoint: false,
      isShowCredit: false,
      ORDERSHOWWAY: this.$getBO('ORDERSHOWWAY'),
      agentFund: {},
      items: [],
      controls: [],
      data: {},
      map: {},
      salesOrgs: []
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'agentMenuList'])
  },
  created() {
    this.init();
    Bus.$off('surplusParmas');
  },
  methods: {
    async init() {
      let map = {};
      if (this.currentUser.__isMutiBiz || this.currentUser.__isMutiOrg) {
        let ctrl = { showCaption: this.mlang('bizName'), fieldName: 'bizId', type: 'biz', enumArray: [] };
        ctrl.disabled = !this.ORDERSHOWWAY;
        this.controls.push(ctrl);
        map[ctrl.type] = ctrl;
      }
      if (this.ORDERSHOWWAY && this.currentUser.__isMutiOrg) {
        let ctrl = { showCaption: this.mlang('salesOrgs'), fieldName: 'salesOrgId', type: 'salesOrg', enumArray: [] };
        this.controls.push(ctrl);
        map[ctrl.type] = ctrl;
      }
      if (this.currentUser.__isMutiOrg) {
        let ctrl = { showCaption: this.mlang('financialOrgs'), fieldName: 'settlementOrgId', type: 'settlementOrg', enumArray: [] };
        this.controls.push(ctrl);
        map[ctrl.type] = ctrl;
      }
      if (this.currentUser.__isU8C) {
        let ctrl = { showCaption: this.mlang('currencyFieldName'), fieldName: 'currencyId', type: 'currency', enumArray: [] };
        this.controls.push(ctrl);
        map[ctrl.type] = ctrl;
        await getCurrency().then(async(currency) => {
          this.$set(map['currency'], 'enumArray', currency);
          if (!localStorage.upAgentRelationId) {
            this.data['currencyId'] = currency[0].id;
            return;
          }
          await getAgentInfoVo(localStorage.upAgentRelationId).then((data) => {
            this.data['currencyId'] = data.currencyId;
          });
        });
        this.$store.commit('SET_SUMMARYPARAMS', {
          originalPk: this.data['currencyId']
        });
      }
      this.map = map;
      await this.setOrg();
    },
    async setOrg() {
      let bizs = await getBizs();
      bizs.forEach(item => {
        item.title = item.name;
        item.value = item.id;
      });
      if (!this.data['bizId']) {
        this.data['bizId'] = localStorage.upBizId;
        if (this.map['biz']) this.$set(this.map['biz'], 'enumArray', bizs);
      }
      let biz = bizs.find((v) => v.id === this.data['bizId']);
      if (biz.bizType === 1) {
        let salesOrgs = await getSaleOrgs(biz.id);
        this.salesOrgs = salesOrgs;
        salesOrgs.forEach(item => {
          item.title = item.name;
          item.value = item.id;
        });
        if (this.map['salesOrg']) {
          this.data['salesOrgId'] = salesOrgs[0].id;
          this.$set(this.map['salesOrg'], 'enumArray', salesOrgs);
        } else {
          this.data['salesOrgId'] = localStorage.upOrgId;
        }
      } else {
        if (this.map['salesOrg']) {
          this.$set(this.map['salesOrg'], 'enumArray', []);
        }
      }
      if (this.data['salesOrgId']) await this.getFinancialOrgs();
      this.$store.commit('SET_SUMMARYPARAMS', {
        salesOrgId: this.data['salesOrgId'],
        settlementOrgId: this.data['settlementOrgId']
      });
      await this.getSummary();
    },
    async getSummary() {
      let fundParams = {};
      let vm = this;
      if (this.data['bizId']) fundParams['bizId'] = this.data['bizId'];
      if (this.$getBO('ORDERSHOWWAY') && this.data['salesOrgId']) fundParams['orgId'] = this.data['salesOrgId'];
      if (this.data['settlementOrgId']) fundParams['settlementOrgId'] = this.data['settlementOrgId'];
      if (this.currentUser.__isU8C && this.data['currencyId']) fundParams['currencyId'] = this.data['currencyId'];
      const { bizId, salesOrgId, currencyId } = this.data;
      const { name } = this.$route;
      const surplusParmas = {
        bizId,
        salesOrgId,
        agentId: this.currentUser && this.currentUser.agentId,
        useWayCode: name === 'numberProductCount' ? 'NUMBERPRODUCT' : 'AMOUNTPRODUCT'
      };
      if (this.data['settlementOrgId']) {
        surplusParmas['settlementOrgId'] = this.data['settlementOrgId'];
      }
      if (surplusParmas.useWayCode === 'AMOUNTPRODUCT') {
        surplusParmas['currencyId'] = currencyId;
      }
      Bus.$emit('surplusParmas', surplusParmas);
      Promise.all([getAgentFund(fundParams), getSurplusRebate(surplusParmas)]).then(([agentFund, surplusRebate]) => {
        vm.agentFund = agentFund;
        vm.surplusRebate = surplusRebate || {};
        vm.broadcastToExtend({ action: 'afterPaysAgentFund', params: { vm: this, data: agentFund }, namespace: 'common' });
        // 优惠券
        let items = [];
        if (vm.$hasAuth('agentReturnPayShow')) {
          items.push({
            title: vm.mlang('prepaidAccountBalance'),
            content: upCoinDigit(vm.agentFund.iPayNotUsedAmount || 0),
            to: {
              name: 'Pays',
              query: {
                formSumary: true,
                bizId: vm.data['bizId'],
                currencyId: vm.data['currencyId'],
                orgId: this.data['salesOrgId']
              }
            }
          });
        }
        if (this.$hasAuth('agentReturnAccountShow')) {
          const mlangArr = ['amountCountAccountBalance', 'amountProductCountAccountBalance', 'numberProductCountAccountBalance'];
          const countArr = ['amountCount', 'amountProductCount', 'numberProductCount'];
          mlangArr.map((item, idx) => {
            items.push({
              title: this.mlang(item),
              content: upCoinDigit(this.surplusRebate[countArr[idx]] || 0),
              to: {
                name: countArr[idx]
              }
            });
            return mlangArr;
          });
        }
        this.items = items;
      });
    },
    format_double(value) {
      var num = parseFloat(value);
      num = num.toFixed(2) + '';
      var str = num.split('.')[0];
      var lis = num.split('.')[1];
      var siz = 0;
      var result = '';
      for (var i = str.length - 1; i >= 0; i--) {
        siz++;
        if (siz % 3 === 0) {
          siz = 0;
          result = ',' + str.substr(i, 3) + result;
        }
      }

      if (str.length % 3 !== 0) {
        result = str.substr(0, str.length % 3) + result;
      } else {
        result = result.substring(1, result.length);
      }
      return result + '.' + lis;
    },
    async getFinancialOrgs() {
      let settlementOrgs = [];
      if (!this.data['salesOrgId'] || this.data['salesOrgId'] === 'undefined') {
        settlementOrgs = await getAllSettlementOrgs();
      } else {
        settlementOrgs = await getSettlementOrgs(this.data['salesOrgId']);
      }
      settlementOrgs.forEach(item => {
        item.title = item.name;
        item.value = item.id;
      });
      if (this.map['settlementOrg']) this.$set(this.map['settlementOrg'], 'enumArray', settlementOrgs);
    },
    handleChange({ type }) {
      switch (type) {
        case 'biz':
          this.data['salesOrgId'] = null;
          this.data['settlementOrgId'] = null;
          this.$set(this.map['settlementOrg'], 'enumArray', []);
          this.setOrg();
          break;
        case 'salesOrg':
          this.getFinancialOrgs().then(() => {
            this.getSummary();
          });
          break;
        case 'settlementOrg':
        case 'currency':
          this.getSummary();
          break;
      }
      this.$store.commit('SET_SUMMARYPARAMS', {
        salesOrgId: this.data['salesOrgId'],
        settlementOrgId: this.data['settlementOrgId'],
        originalPk: this.data['currencyId']
      });
    }
  }
};
</script>
<style lang="less" scoped>
.summary{
  .summary-search{
    display: flex;
    flex-wrap: wrap;
    width: 850px;
    padding: 10px;
  }
  .search-label{
    width: 100px;
    height: 32px;
    line-height: 28px;
    padding: 0px 10px;
    text-align: right;
  }
}
</style>
