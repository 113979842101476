import ajax from '@/libs/ajax';

// 上架的商品
export const getUpPopProducts = (data) => {
  return ajax.request({
    url: '/archives/product/getPopProducts?isArchive=1',
    data,
    method: 'post'
  });
};

// 所有的商品包括未上架
export const getPopProducts = (data) => {
  return ajax.request({
    url: '/archives/product/getProducts?isArchive=1',
    data,
    method: 'post'
  });
};

// 上架的商品规格
export const getUpProductSpecsUrl = (id, bizProductId) => {
  return ajax.request({
    url: '/archives/product/getUpProductSpecs?isArchive=1',
    data: { id, bizProductId },
    method: 'get'
  });
};
export const getProductSpeciSkuInfoUrl = (id, bizProductId) => {
  return ajax.request({
    url: '/archives/product/getProductSpeciSkuInfo?isArchive=1&withpriceitem=true',
    data: { id, bizProductId },
    method: 'get'
  });
};


// 所有商品规格包括未上架
export const getProductSpecs = (id) => {
  return ajax.request({
    url: '/archives/product/getProductSpecs?isArchive=1',
    data: { id },
    method: 'get'
  });
};
// 获取商品属性
export const getUpProductSpecs = (data) => {
  return ajax.request({
    url: '/archives/product/getUpProductSpecs',
    method: 'get',
    data: {
      isArchive: 1,
      id: data.productId,
      bizProductId: data.bizProductId
    }
  });
};
// 商品参数
export const getProductParams = (id) => {
  return ajax.request({
    url: '/archives/product/getProductParams?isArchive=1',
    data: { id },
    method: 'get'
  });
};
// 获取商品sku
export const getUpProductSku = (data) => {
  return ajax.request({
    url: '/archives/product/getUpSkus?isArchive=1&isPriceList=true',
    method: 'get',
    data
  });
};
export const getProductSpeciSkuInfo = (data) => {
  return ajax.request({
    url: '/archives/product/getProductSpeciSkuInfo?isArchive=1&withpriceitem=true',
    method: 'get',
    data
  });
};

export const getSkuAndParametersById = ({ id, orgId, currencyId, status, isAgent }) => {
  let data = {
    id,
    orgId,
    status,
    isAgent
  };
  if (currencyId) {
    data.currencyId = currencyId;
  }
  return ajax.request({
    url: '/archives/product/getPopProduct?isArchive=1',
    data: data,
    method: 'get'
  });
};

export const getRebateSkuAndParametersById = (data) => {
  return ajax.request({
    url: '/archives/product/getRebatePopProduct',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};

export const getbUseAuxUnitOrder = () => {
  return ajax.request({
    url: '/archives/product/getbUseAuxUnitOrder?isArchive=1',
    method: 'get'
  });
};

export const getProductOrgSettings = (productId) => {
  const data = {
    querySchema: {
      conditions: [
        {
          name: 'productId',
          conditionType: 'eq',
          isDefine: false,
          valueType: 'LONG',
          v1: productId
        }
      ],
      pager: { pageIndex: 1, pageSize: 1000 }
    }
  };
  return ajax.request({
    url: '/goods/scope/orgproduct/list',
    data: {
      data: data.querySchema,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
// 添加购物车数据
export const addComGroupCarts = (data) => {
  let isYs = window.__app.context.productLine === 'u8c';
  return ajax.request({
    url: isYs ? '/goods/shoppingcart/add/vo' : '/pc/goods/ShoppingCartSrv/add',
    method: 'post',
    data: isYs ? {
      data: data,
      datatype: 'json',
      isArchive: 1
    } : {
      items: data,
      datatype: 'json'
    },
    loading: true
  });
};
export const saveProductOrgSetting = (data) => {
  return ajax.request({
    url: '/goods/scope/orgproduct',
    method: 'post',
    data: {
      data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

/**
 * 获取所有产品线信息
 * @return
 */
export const getProductLines = () => {
  return ajax
    .request({
      url: '/orginazation/productline/list',
      data: {
        isArchive: 1
      },
      method: 'get'
    })
    .then((res) => {
      return {
        content: res
      };
    });
};

export const saveProductLines = (data) => {
  return ajax.request({
    url: '/orginazation/productline',
    method: 'POST',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const delProductLines = (id) => {
  return ajax.request({
    url: `/orginazation//productline/${id}`,
    method: 'DELETE',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const editProductLine = (productLineid) => {
  return ajax.request({
    url: `/orginazation/productline/${productLineid}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

/**
 * 获取所有品牌信息
 * @return
 */
export const getBrands = () => {
  return ajax
    .request({
      url: '/orginazation/brand/list',
      data: {
        isArchive: 1
      },
      method: 'get'
    })
    .then((res) => {
      return {
        content: res
      };
    });
};

export const saveBrand = (data) => {
  return ajax.request({
    url: '/orginazation/brand',
    method: 'POST',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const delBrand = (id) => {
  return ajax.request({
    url: `/orginazation/brand/${id}`,
    method: 'DELETE',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
/**
 * 根据id获取品牌信息
 * @param brandId
 * @return
 */
export const editBrand = (brandid) => {
  return ajax.request({
    url: `/orginazation/brand/${brandid}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
/**
 * 获取所有计量单位列表
 * @param 可上送筛选条件
 * @return
 */
export const getUnits = (data) => {
  return ajax
    .request({
      url: '/goods/infrastructure/productunits/list',
      data: {
        data,
        datatype: 'json',
        isArchive: 1
      },
      method: 'POST'
    })
    .then((res) => {
      return {
        content: res
      };
    });
};
/**
 * 删除计量单位
 * @param unitid
 * @return
 */
export const delUnit = (unitid) => {
  return ajax.request({
    url: `/goods/infrastructure/productunits/${unitid}`,
    method: 'DELETE',
    data: {
      datatype: 'json',
      isArchive: 1
    }
  });
};
/**
 * 保存新增、编辑计量单位
 * @return
 */
export const saveUnits = (data) => {
  return ajax.request({
    url: '/goods/infrastructure/productunits',
    method: 'POST',
    data: {
      data,
      datatype: 'json',
      loading: true,
      isArchive: 1
    }
  });
};
/**
 *查询条计量单位详情根据id
 * @return
 */
export const editUnit = (unitid) => {
  return ajax.request({
    url: `/goods/infrastructure/productunits/${unitid}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
// 获取全部商品
export const getNewestProducts = () => {
  return ajax.request({
    url: '/archives/product/getNewestProducts',
    method: 'get',
    data: {
      isArchive: 1,
      currentPage: 1,
      pageSize: 10
    }
  });
};
// 获取推荐商品
export const getRecommendedProducts = () => {
  return ajax.request({
    url: '/archives/product/getRecommendedProducts',
    method: 'get',
    data: {
      isArchive: 1,
      currentPage: 1,
      pageSize: 10
    }
  });
};

// 获取上架商品的sku
export const getProductSku = (data) => {
  return ajax.request({
    url: '/archives/product/getSkus?isArchive=1',
    method: 'post',
    data
  });
};
// 导出计量单位
export const exportUnit = (data) => {
  return ajax.request({
    url: '/goods/UnitSrv/ExportUnits?exportFormat=excel&random=' + Math.random(),
    method: 'post',
    data
  });
};

// 根据分类id获取属性信息
export const getPropertyByClass = (classifyId) => {
  let url = '/marketingbill/productclass/getSpecFilters?isMarket=1&id=' + classifyId;
  return ajax.request({
    url,
    method: 'get'
  });
};

// 根据分类id获取参数信息
export const getParamsByClass = (classifyId) => {
  let url = '/marketingbill/productclass/getParamsFilters?isMarket=1&id=' + classifyId;
  return ajax.request({
    url,
    method: 'get'
  });
};

// 根据分类id获取品牌信息
export const getBrandByClass = (classifyId) => {
  return ajax.request({
    url: '/goods/infrastructure/productclass/brand',
    method: 'get',
    data: {
      isArchive: 1,
      classId: classifyId
    }
  });
};

/**
 * @method getBizs 获取商家列表
 */
// export const getBizs = () => {
//   return ajax.request({ url: '/orginazation/merchant/user/list?isArchive=1', method: 'get' }).then((data) => {
//     data &&
//       data.map((v) => {
//         v.bizId = v.id;
//         v.title = v.name;
//       });
//     return data;
//   });
// };
export const getBizs = (v1) => {
  let data = {
    conditions: [
      { name: 'agentId', v1: v1, valueType: 'LONG', conditionType: 'eq' },
      {
        conditionType: 'eq',
        isOuterField: false,
        isSkip: false,
        name: 'isContainBiz',
        v1: true,
        valueType: 'BOOLEAN'
      },
      {
        conditionType: 'eq',
        isOuterField: false,
        isSkip: false,
        name: 'isContainOrg',
        v1: true,
        valueType: 'BOOLEAN'
      },
      { name: 'activate', v1: true, valueType: 'BOOLEAN', conditionType: 'eq' }
    ],
    pager: {
      pageIndex: 1,
      pageSize: 300
    }
  };
  return ajax.request({
    url: '/agent/infrastructure/agents/current/0',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    }
  }).then((data) => {
    let bizDatas = [];
    data && data.content.map((v) => {
      if (v.bizType === 1) {
        bizDatas.push({
          bizId: v.bizId,
          orgId: v.orgId,
          fullName: v.orgName
        });
      } else {
        bizDatas.push({
          bizId: v.bizId,
          fullName: v.bizName
        });
      }
    });
    return bizDatas;
  });
};

// 获取当前商家下上架的商品
export const getUpProductsClassesByBiz = () => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/goods/infrastructure/productclasses/list/up/modelVo?isArchive=1' : '/pc/ProductClass/getUpClasses',
    method: 'get'
  });
};

// 获取商品详情
export const getProduct = (data) => {
  return ajax.request({
    url: '/archives/product/getProduct?isArchive=1',
    method: 'get',
    data: {
      id: data.productId,
      bizProductId: data.bizProductId,
      bizId: data.bizId,
      saleOrgId: data.saleOrgId
    }
  }).then((res) => {
    let product = {};
    if (res && res.product) {
      product = res.product;
      product.leftOffset = 0;
      product.productImg = product.imgUrl;
    };
    return product;
  });
};
// 获取商品详情
export const getProductDetails = (data) => {
  return ajax.request({
    url: '/archives/product/detail?isArchive=1',
    method: 'get',
    data: {
      id: data.productId,
      bizProductId: data.bizProductId,
      bizId: data.bizId,
      saleOrgId: data.saleOrgId,
      explosiveProZoneId: data.explosiveProZoneId,
      explosiveProZoneProductInfoId: data.explosiveProZoneProductInfoId
    }
  }).then((res) => {
    let product = {};
    if (res && res.product) {
      product = res.product;
      product.leftOffset = 0;
      product.productImg = product.imgUrl;
    };
    return product;
  });
};
// 获取商品销售数量
export const getVirtualProductAttach = (data) => {
  return ajax.request({
    url: 'archives/product/detail/attach',
    method: 'post',
    data: {
      ...data,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

// 获取商品套件
export const getVirtualProductAttribute = (id, bizId, orgId) => {
  return ajax.request({
    url: `/archives/product/getSuiteProducts?bizId=${bizId}&orgId=${orgId}`,
    method: 'post',
    data: {
      data: [id],
      isArchive: 1,
      datatype: 'json'
    }
  });
};
// 获取PTO子件
export const getPtoProducts = (id, bizId, orgId) => {
  return ajax.request({
    url: `/archives/product/getPtoProducts?bizId=${bizId}&orgId=${orgId}&productId=${id}&isArchive=1`,
    method: 'get'
  });
};

// 获取订单详情的组合促销信息
export const getOrderDetailComGroupInfo = (orderdetails) => {
  var isYs = window.__app.context.productLine === 'u8c';
  return ajax.request({
    url: isYs ? '/goods/promotion/getOrderDetailComGroupInfo' : '/pc/combinationpromotion/getOrderDetailComGroupInfo',
    method: 'post',
    loading: true,
    datatype: isYs ? undefined : 'json',
    data: isYs ? {
      data: orderdetails,
      isArchive: 1,
      datatype: 'json'
    } : {
      orderdetails
    }
  });
};

// 获取商品详情的组合促销信息
export const getComGroupDetail = (data) => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/goods/promotion/getComGroupDetail' : '/pc/combinationpromotion/getComGroupDetail',
    method: 'get',
    data
  });
};

// 获取购物车详情的组合促销信息
export const getCartComGroupInfo = (data) => {
  return ajax.request({
    url: '/goods/promotion/getCartComGroupInfo',
    method: 'get',
    data
  });
};

// 获取购物车详情的组合促销信息
export const getComGroupDetailByCart = (items) => {
  return ajax.request({
    url: '/goods/promotion/getComGroupDetailByCart',
    method: 'post',
    data: {
      datatype: 'json',
      data: items,
      isArchive: 1
    }
  });
};

// 获取调价价格
export const getDiscountPrice = (data) => {
  return ajax.request({
    url: '/archives/product/getDiscountPrice',
    method: 'get',
    data
  });
};

// 更新购物车详情的组合促销信息
export const updateComGroupCarts = (data) => {
  let isYs = window.__app.context.productLine === 'u8c';
  return ajax.request({
    url: isYs ? '/goods/shoppingcart/updateComGroupCarts' : '/pc/goods/ShoppingCartSrv/updateComGroupCarts',
    method: 'post',
    data: isYs ? {
      data: data,
      loading: true,
      datatype: 'json',
      isArchive: 1
    } : {
      carts: data,
      loading: true,
      datatype: 'json'
    }
  });
};

// 生成订单
export const GenerateOrder = (items) => {
  items.forEach(v => {
    if (v.iGroupQuantity) {
      v.groupQuantity = v.iGroupQuantity;
    }
  });
  return ajax.request({
    url: '/voucher/order/GenerateOrder',
    method: 'post',
    data: {
      datatype: 'json',
      data: items,
      isArchive: 1
    }
  });
};
// 竞拍单生成订单
export const GenerateOrderByBidding = (data) => {
  return ajax.request({
    url: '/voucher/order/GenerateOrderByBidding',
    method: 'post',
    data: {
      datatype: 'json',
      data,
      isArchive: 1
    }
  });
};
// 校验传入的互斥组和购物车的互斥组是否一致，还有一个就是加入套餐的选配分组和购物车的选配分组不一致的情况
export const verifyMutexShoppingCartGroup = (items) => {
  return ajax.request({
    url: '/goods/promotion/selectfree/verifyMutexShoppingCartGroup',
    method: 'post',
    data: {
      datatype: 'json',
      data: items,
      isArchive: 1
    }
  });
};

// 获取组合促销
export const getComPreGroups = (data) => {
  return ajax.request({
    url: window.__app.context.productLine === 'u8c' ? '/goods/promotion/getComPreGroups?isArchive=1' : '/pc/combinationpromotion/getComPreGroups',
    method: 'get',
    data
  });
};
// 获取推荐商品
/**
 * @method getAccessoriesSettingForAgent 获取配件商品
 * @params id bizId saleOrgId
 * @description yb目前无商品推荐，暂先修改接口
 */
export const getAccessoriesSettingForAgent = (params) => {
  return ajax.request({
    url: `/archives/product/getAccessoriesSettingForAgent?id=${params.id}&bizId=${params.bizId}&saleOrgId=${params.saleOrgId}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 获取商品目录里符合查询条件的商品
export const getUpProducts = (data, ifProductionOptimize = false) => {
  let url = '';
  for (let key in data) {
    if (data[key] || data[key] === 0 || data[key] === false) {
      url += '&' + key + '=' + data[key];
    }
  }

  return ajax.request({
    url: `/archives/product/${ifProductionOptimize ? 'getUpProductsByAuthPager' : 'getUpProducts'}?isArchive=1${url}`,
    method: 'get'
  }).then((res) => {
    res && res.data &&
    res.data.forEach(item => {
      item.leftOffset = 0;
      item.productImg = item.imgUrl;
      if (!item.lsAlbums) {
        item.lsAlbums = [];
      }
    });
    return res;
  });
};
export const getPromotionDetail = (data) => {
  return ajax.request({
    url: `/goods/promotion/promotion/${data.preId}/detail/product?isArchive=1`,
    method: 'get',
    data
  });
};
export const getEntirePromotionProducts = (data) => {
  return ajax.request({
    url: `/goods/promotion/getEntirePromotionProducts?isArchive=1&promotionId=${data.preId}&pageIndex=${data.pageindex}&pageSize=${data.pagesize}`,
    method: 'get',
    data
  });
};
export const getComPromotionProducts = (data) => {
  return ajax.request({
    url: `/goods/promotion/combination/${data.preId}/product?isArchive=1&pageIndex=${data.pageindex}&pageSize=${data.pagesize}`,
    method: 'get',
    data
  });
};

// 获取商品目录里符合查询条件的商品
export const getPromotions = (data) => {
  return ajax.request({
    url: '/archives/product/getPromotions?isArchive=1',
    method: 'get',
    data
  });
};
// 快速下单 促销信息
export const getPromotionsBySku = (data) => {
  return ajax.request({
    url: '/archives/product/getPromotionsBySku?isArchive=1',
    method: 'get',
    data
  });
};
// 获取热销商品
export const getTop3HotProducts = (pageSize) => {
  return ajax.request({
    url: '/archives/product/getTop3HotProducts?isArchive=1&pageSize=' + pageSize,
    method: 'get'
  });
};
// 获取商品页数
export const getProductPageCount = () => {
  return ajax.request({
    url: '/archives/product/getProductPageCount',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const getPromotionPackageData = (data) => {
  return ajax.request({
    url: '/goods/promotion/selectfree/getPromotionPackageData',
    method: 'post',
    data: {
      ...data,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 获取购物车详情的套餐商品信息
export const getSelectFreeByShoppingCart = (items) => {
  return ajax.request({
    url: '/goods/promotion/selectfree/getSelectFreeByShoppingCart',
    method: 'post',
    data: {
      datatype: 'json',
      data: items,
      isArchive: 1
    }
  });
};
export const updateSelectFreeCarts = (data) => {
  return ajax.request({
    url: '/goods/shoppingcart/updateSelectFreeCarts',
    method: 'post',
    data: {
      data: data,
      loading: true,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 收藏商品
export const addFavorite = (data) => {
  return ajax.request({
    url: '/goods/product/favorites',
    method: 'post',
    data: {
      data: data,
      loading: true,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 删除收藏
export const delFavorite = (data) => {
  return ajax.request({
    url: '/goods/product/favorites/del',
    method: 'post',
    data: {
      data: data,
      loading: true,
      datatype: 'json',
      isArchive: 1
    }
  });
};

// 获取从商品专区进入的商品目录列表
export const getCommoditySectionProductList = (params) => {
  return ajax.request({
    url: `/archives/product/getUpZoneProductsList`,
    method: 'get',
    data: {
      ...params,
      isArchive: 1
    }
  }).then((res) => {
    res && res.data &&
    res.data.forEach(item => {
      item.leftOffset = 0;
      item.productImg = item.imgUrl;
      if (!item.lsAlbums) {
        item.lsAlbums = [];
      }
    });
    return res;
  });
}

// 获取商品专区的商品详情
export const getCommoditySectionProductSku = (params) => {
  return ajax.request({
    url: '/archives/product/getUpZoneSkus',
    method: 'get',
    data: {
      ...params,
      isArchive: 1
    }
  });
}
