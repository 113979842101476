export default {
  org: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C7","组织") /* "组织" */,
  activityName: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C8","活动名称") /* "活动名称" */,
  activityType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001C9","活动类型") /* "活动类型" */,
  activityMemo: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001CA","活动说明") /* "活动说明" */,
  activityDate: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001CB","活动时间") /* "活动时间" */,
  activityStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001CC","活动状态") /* "活动状态" */,
  preDeliveryDays: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001CD","预计发货日期") /* "预计发货日期" */,
  afterActivity: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001CE","活动结束") /* "活动结束" */,
  afterActivity1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001CF","天后开始发货，即") /* "天后开始发货，即" */,
  afterActivity2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D0","后开始发货") /* "后开始发货" */,
  preDeliveryEndDays: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D1","发货截止日期") /* "发货截止日期" */,
  afterDelivery: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D2","预计发货日") /* "预计发货日" */,
  afterDelivery1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D3","天后结束发货，即") /* "天后结束发货，即" */,
  afterDelivery2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D4","后结束发货") /* "后结束发货" */,
  stockPeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D5","备货周期") /* "备货周期" */,
  isValid: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D6","启用状态") /* "启用状态" */,
  confirmIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D7","启用") /* "启用" */,
  disConfirmIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D8","停用") /* "停用" */,
  depositRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001D9","订金规则") /* "订金规则" */,
  depositConfirmRange: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001DA","订金金额") /* "订金金额" */,
  depositConfirmRange1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001DB","统一收取定金") /* "统一收取定金" */,
  depositConfirmRange2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001DC","按照订单金额比例收取") /* "按照订单金额比例收取" */,
  dingjin: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001DD","%订金") /* "%订金" */,
  depositDealPeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001DE","订金周期") /* "订金周期" */,
  depositDealPeriod1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001DF","订单提交后") /* "订单提交后" */,
  depositDealPeriod2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E0","未支付，则取消订单") /* "未支付，则取消订单" */,
  depositDealRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E1","订金处理") /* "订金处理" */,
  balanceRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E2","尾款控制") /* "尾款控制" */,
  balancePercentRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E3","尾款金额") /* "尾款金额" */,
  balancePercentRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E4","订单支付金额需要达到订单总金额") /* "订单支付金额需要达到订单总金额" */,
  balancePercentRule2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E5","（包含定金）") /* "（包含定金）" */,
  balancePeriod: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E6","尾款周期") /* "尾款周期" */,
  balancePeriod1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E7","至活动结束时间后") /* "至活动结束时间后" */,
  balancePeriod2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E8","后结束") /* "后结束" */,
  day: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001E9","天") /* "天" */,
  xiaoshi: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001EA","小时") /* "小时" */,
  fenzhong: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001EB","分") /* "分" */,
  second: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001EC","秒") /* "秒" */,
  notPayBalanceRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001ED","未满足尾款支付要求") /* "未满足尾款支付要求" */,
  promotionRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001EE","优惠策略") /* "优惠策略" */,
  priceRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001EF","取价方式") /* "取价方式" */,
  promotionType: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F0","优惠方式") /* "优惠方式" */,
  isEnablePromotionStep: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F1","预售梯优惠") /* "预售梯优惠" */,
  promotionStepCalcRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F2","阶梯条件") /* "阶梯条件" */,
  addLsPresaleSteps: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F3","增加阶梯") /* "增加阶梯" */,
  demension: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F4","促销维度") /* "促销维度" */,
  addProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F5","新增商品") /* "新增商品" */,
  activityProduct: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F6","活动商品") /* "活动商品" */,
  product: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F7","商品") /* "商品" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F8","操作成功") /* "操作成功" */,
  maxPreferentialNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001F9","商品最大计价数量：") /* "商品最大计价数量：" */,
  usedPreferentialNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001FA","已占用计价数量：") /* "已占用计价数量：" */,
  notUsedPreferentialNum: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001FB","剩余计价数量：") /* "剩余计价数量：" */,
  kehu: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001FC","客户") /* "客户" */,
  isSettingSku: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001FD","按sku设置") /* "按sku设置" */,
  presaleTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001FE","预售") /* "预售" */,
  presaleTypes2: lang.templateByUuid("UID:P_UDHWN_17DD50DE050001FF","预订") /* "预订" */,
  depositRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000200","不收订金") /* "不收订金" */,
  depositRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000201","收取订金") /* "收取订金" */,
  promotionPriceRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000202","客户价格表") /* "客户价格表" */,
  promotionPriceRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000203","指定商品价格") /* "指定商品价格" */,
  promotionRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000204","单独设定客户价格以及优惠") /* "单独设定客户价格以及优惠" */,
  promotionRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000205","无优惠") /* "无优惠" */,
  promotionStepCalcRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000206","满件") /* "满件" */,
  promotionStepCalcRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000207","满额") /* "满额" */,
  promotionTypes1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000208","打折") /* "打折" */,
  depositDealRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000209","订单取消后订金回退") /* "订单取消后订金回退" */,
  notPayBalanceRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500020A","订单自动取消") /* "订单自动取消" */,
  balanceRules1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500020B","统一收取尾款") /* "统一收取尾款" */,
  balanceRules2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500020C","不控制尾款") /* "不控制尾款" */,
  orderQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500020D","商品计价数量") /* "商品计价数量" */,
  orderMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500020E","金额小计") /* "金额小计" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500020F","编辑") /* "编辑" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000210","删除") /* "删除" */,
  viewIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000211","查看") /* "查看" */,
  skuSpeciInfo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000212","商品Sku属性") /* "商品Sku属性" */,
  speciInfo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000213","商品属性") /* "商品属性" */,
  skuCode: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000214","商品Sku编码") /* "商品Sku编码" */,
  maxOrderQuantityTable: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000215","成团条件(计价单位)") /* "成团条件(计价单位)" */,
  minOrderQuantityTable: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000216","客户起订量(计价单位)") /* "客户起订量(计价单位)" */,
  permaxOrderQuantityTable: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000217","每客户最大订购量(计价单位)") /* "每客户最大订购量(计价单位)" */,
  tiaojianmanjian: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000218","条件（批发计价单位）") /* "条件（批发计价单位）" */,
  tiaojianmane: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000219","条件（满额）") /* "条件（满额）" */,
  jieti: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500021A","阶梯") /* "阶梯" */,
  copyIt: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500021B","复制") /* "复制" */,
  seeExeStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500021C","查看执行情况") /* "查看执行情况" */,
  handleBatchOperationWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500021D","请选择至少一条需要操作的信息") /* "请选择至少一条需要操作的信息" */,
  zhishaotianjiayigetuangoushangpin: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500021E","至少添加一个团购商品!") /* "至少添加一个团购商品!" */,
  shouldCorrectMessage: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500021F","请正确填写!") /* "请正确填写!" */,
  doFailSure: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000220","确定执行吗？") /* "确定执行吗？" */,
  promotionProgressName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000221","处理状态") /* "处理状态" */,
  pleaseSelectCreateOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000222","请先选择组织！") /* "请先选择组织！" */,
  orderNo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000223","订单号") /* "订单号" */,
  all: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000224","全部") /* "全部" */,
  groupBuy: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000225","团购") /* "团购" */,
  createActivity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000226","活动创建") /* "活动创建" */,
  pending: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000227","进行中") /* "进行中" */,
  activityFail: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000228","活动失败") /* "活动失败" */,
  activityEnd: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000229","活动截止") /* "活动截止" */,
  activityFinish: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500022A","已完成") /* "已完成" */,
  differenceRule: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500022B","团购尾差处理") /* "团购尾差处理" */,
  differenceRule1: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500022C","尾差忽略") /* "尾差忽略" */,
  requiredOrderStatus: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500022D","成团订单状态") /* "成团订单状态" */,
  confirmed: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500022E","已确认") /* "已确认" */,
  waitConfirm: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500022F","待确认") /* "待确认" */,
  mutexActivities: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000230","互斥规则") /* "互斥规则" */,
  mutexActivities1: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000231","商品限购") /* "商品限购" */,
  mutexActivities2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000232","团购(相同商品)") /* "团购(相同商品)" */,
  groupBuyPrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000233","团购价格(计价单位)") /* "团购价格(计价单位)" */,
  productName: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000234","商品名称") /* "商品名称" */,
  setGroupCorrectly: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800037","请正确设置商品") /* "请正确设置商品" */,
  groupBuyInfo: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000235","团购信息") /* "团购信息" */,
  pleaseSet: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000236","请设置") /* "请设置" */,
  maxOrderQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000237","成团条件") /* "成团条件" */,
  groupMoney: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000238","团购价格") /* "团购价格" */,
  permaxOrderQuantity: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000239","每客户最大订购量") /* "每客户最大订购量" */,
  setGroupBuyStepPrice: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800038","设置团购阶梯价") /* "设置团购阶梯价" */,
  fixPrice: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500023A","价格") /* "价格" */,
  consumerSpending: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500023B","阶梯数量") /* "阶梯数量" */,
  setBasicPriceTip: lang.templateByUuid("UID:P_UDHWN_17E3A78E05800039","请设置数量为0团购价格") /* "请设置数量为0团购价格" */
};
