<template>
  <div class="rebate">
    <div>
      <template v-for="(tab,index) in tabs">
        <router-link v-if="!$route.query.isRebate" :key="index" style="padding: 0 10px;" :to="tab.name" :class="[{ 'unactive': (tabname !== tab.name) }]">
          {{ tab.title }}
        </router-link>
      </template>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      tabname: 'DiscountRebates',
      tabs: [
        { title: this.mlang('cashAndDiscountRebate'), name: 'DiscountRebates' },
        { title: this.mlang('productRebate'), name: 'GoodRebates' }
      ]
    };
  },
  created() {
    this.tabname = this.$route.name || 'DiscountRebates';
  },
  methods: {
    toList(name) {
      if (name === this.tabname) {
        return false;
      }
      this.tabname = name;
      this.$router.push({
        name
      });
    }
  }
};
</script>

<style lang="less" scoped>
.unactive {
  color: gray;
}
</style>
