<template>
  <div id="order-list" ref="orderList">
    <VoucherList
      ref="voucherList"
      :data="data"
      :query="query"
      :biz-meta="meta"
      :before-query="beforeQuerySchemaSearch"
      :is-need-place-holder-col="true"
      :place-holder-width="20"
      @on-batch-click="handleBatchClick"
      @on-filter-change="dynamicChange"
      @table-selection-change="handleTableChange"
      @on-header-body-change="handleRadioChange"
      @activated-force-reload-all="setMeta">
    </VoucherList>
    <ElectronicInvoiceModal
      :show-modal.sync="showElectronicInvoiceModal"
      :order-id="invoiceOrderId">
    </ElectronicInvoiceModal>
  </div>
</template>

<script>
import VoucherList from '_c/common/voucher-list';
import ElectronicInvoiceModal from '@/views/agent/orders/components/order/order-invoices/electronic-invoice-modal.vue';
import Mixin from '@/mixin/archiveList.js';
import OrderMixin from '@/mixin/agentOrder.js';
import ResetPredicateDateMixin from '@/mixin/resetPredicateDate.js';
import Emitter from '@/u-components/mixins/emitter';
import { getAllOrders, getOrderListBtn, exportOrder, exportSaleOrder, getYHTtoken } from '@/api/order';
import { productRefer, srcBillRefer } from '@/meta/refer-common';
import { getProductClassTree, getShippingChoiceTree } from '@/api/refer-tree';
import { getBizsSelect } from '@/api/refer-select';
import dayjs from 'dayjs';
import _ from 'lodash';
import { deepCopy } from '@/u-components/utils/assist';

export default {
  name: 'Orders',
  components: { VoucherList, ElectronicInvoiceModal },
  mixins: [Mixin, OrderMixin, Emitter, ResetPredicateDateMixin],
  data() {
    const meta = this.getDefaultMeta();
    return {
      data: {},
      query: this.queryList,
      meta,
      bizs: [],
      shippingTreeData: [],
      orderToOwner: ['DELIVERY_TAKE_PART', 'TAKEDELIVERY'],
      unpaystatus: ['CONFIRMORDER', 'DELIVERGOODS', 'DELIVERY_TAKE_PART', 'DELIVERY_PART', 'TAKEDELIVERY', 'ENDORDER'],
      unpay: ['NOTPAYMENT', 'PARTPAYMENT', 'CONFIRMPAYMENT'],
      showElectronicInvoiceModal: false,
      invoiceOrderId: '',
      isSum: true
    };
  },
  computed: {
    BO() {
      return this.$store.getters.BO;
    },
    noPayFilter() {
      return this.$route.query.cOrderStatus === 'PAYMENT' && this.$route.query.cOrderPayStatus === 'PAYMENT';
    },
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  watch: {
    BO: {
      handler() {
        // this.setMeta();
      },
      deep: true
    }
  },
  async mounted() {
    var yhtToken = '';
    if (this.currentUser.__isU8C && this.$getBO('AGENTORDERIMPORT')) {
      let data = await getYHTtoken();
      yhtToken = data.token;
    }
    const agentOrderPay = this.$hasAuth('agentOrderPay');// 批量支付权限
    const agentOrderOutput = this.$hasAuth('agentOrderOutput');// 导出权限
    const agentOrderImport = this.$hasAuth('agentOrderImport'); // 导入权限
    // 多选 有agentOrderPay权限还不是代下单
    this.meta = this.getDefaultMeta();
    if (agentOrderPay && !this.currentUser.__isRepresent) {
      this.meta.multiple = true;// 控制多选框时候显示
      // 由业务选项“允许订货门户付款”控制批量付款按钮，但是多选框仍存在
      if (this.$getBO('ALLOWORDERPAYMENT')) {
        this.meta.batchBtn.push({
          name: 'payBatch',
          disabled: true,
          title: this.mlang('payBatch')
        });
      }
    } else {
      this.meta.multiple = false;
    }
    if (this.currentUser.__isU8C && this.$getBO('AGENTORDERIMPORT') && agentOrderImport) {
      this.meta.import = {
      // url: '/upload/pc/OrderSrv/importNewOrder',
      // tempUrl: '/api/udinghuo/batch/template/ys_importExcel',
      // tempUrl: '/public/template/orderimport.xlsx',
        ysParam: { 'billnum': 'voucher_order', 'serviceCode': 'orderList', 'fileName': '订单信息列表模板' },
        yhtToken
      };
    }
    // 导出 如果有权限还不是代下单
    if (agentOrderOutput && !this.currentUser.__isRepresent) {
      if (!this.currentUser.__isU8C) {
        this.meta.export = {
          action: exportOrder,
          beforeExport: this.beforeQuerySchemaSearch
        };
      } else {
        this.meta.export = {
          saleService: exportSaleOrder,
          isSaleExport: true,
          beforeExport: this.beforeYsExport,
          fileName: this.mlang('saleOrderTitle')
        };
      }
    }
    this.$bus.on('invoiceModaStatus', ({ orderId, modaStatus }) => {
      this.showElectronicInvoiceModal = modaStatus;
      this.invoiceOrderId = orderId;
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  created() {
    getBizsSelect().then((res) => {
      this.bizs = res;
    });
  },
  beforeDestroy() {
    this.$bus.off('invoiceModaStatus');
  },
  methods: {
    handleRadioChange(val) {
      this.isSum = val !== 'all';
    },
    dynamicChange(control, selectedItem) {
      let value = selectedItem && selectedItem.specialValue;
      if (control.name === 'srcbillType') {
        if(value === undefined){
          this.$refs['voucherList'].setFilterItem('srcBillNO', 'isCustomerShow', true);
          this.$refs['voucherList'].setFilterItem('srcBillId', 'isCustomerShow', true);
        }else{
          if (value === 0) {
            this.$refs['voucherList'].setFilterItem('srcBillNO', 'isCustomerShow', false);
            this.$refs['voucherList'].setFilterItem('srcBillId', 'isCustomerShow', true);
          } else if (value === 1) {
            this.$refs['voucherList'].setFilterItem('srcBillId', 'isCustomerShow', false);
            this.$refs['voucherList'].setFilterItem('srcBillNO', 'isCustomerShow', true);
          }
        }

      }
    },
    beforeYsExport(query) {
      if (query.querySchema) {
        let commonVOs = [];
        let simpleVOs = [{
          field: 'agentId',
          op: 'eq',
          value1: this.currentUser.agentId
        }];
        let curOrg = {};
        if (!this.$getBO('ORDERSHOWWAY')) {
          curOrg = this.currentUser.relations.find((v) => {
            return v.id === localStorage.upAgentRelationId;
          });
          if (curOrg && curOrg.orgId) {
            simpleVOs.push({
              field: 'salesOrgId',
              op: 'eq',
              value1: curOrg.orgId
            });
          }
          if (curOrg && curOrg.bizId) {
            simpleVOs.push({
              field: 'bizId',
              op: 'eq',
              value1: curOrg.bizId
            });
          }
        }
        commonVOs.push({
          itemName: 'agentId_name',
          value1: [this.currentUser.agentId]
        });
        query.querySchema.conditions.map((v) => {
          let obj = {
            itemName: v.name,
            value1: v.v1
          };
          if (v.name === 'statusCode') {
            obj.itemName = 'nextStatusName';
          } else if (v.name === 'payStatus') {
            obj.itemName = 'payStatusCode';
          } else if (v.name === 'iSKUId') {
            obj.field = 'orderDetails.skuId';
          } else if (v.name === 'searchOrderNo') {
            obj.itemName = 'code';
          } else if (v.name === 'iPrdouctClassId') {
            obj.field = 'orderDetails.productId.productClass';
          } else if (v.name === 'shippingChoiceCode') {
            obj.field = 'shippingChoiceId_code';
          } else if (v.name === 'bizId') {
            obj.field = 'bizId';
          } else if (v.name === 'srcbillType' || v.name === 'srcBillNO') {
            obj.field = v.name;
            if (v.name === 'srcbillType') {
              obj.field = 'orderDetails.source';
              obj.value1 = v.v1 === 1 ? 'yonbip-hy-smk-ausa.ausa_auction_bill_card' : 'sact.sact_salescontract';
            }
          } else if (v.name === 'srcBillId') {
            obj.field = 'orderDetails.firstsourceid';
          }

          if (v.v2) {
            obj.value2 = v.v2;
          }

          // if (v.name === 'iPrdouctClassId' || v.name === 'shippingChoiceCode' || v.name === 'bizId' || v.name === 'iSKUId' || v.name === 'srcbillType' || v.name === 'srcBillNO' || v.name === 'srcBillId') {
          let vName = ['iPrdouctClassId', 'shippingChoiceCode', 'bizId', 'iSKUId', 'srcbillType', 'srcBillNO', 'srcBillId'];
          if (vName.includes(v.name)) {
            obj.op = 'eq';
            delete obj.itemName;
            simpleVOs.push(obj);
          } else if (v.name === 'auditDate') {
            obj.field = 'auditDate';
            obj.value1 = `${obj.value1} 00:00:00`;
            obj.value2 = `${obj.value2} 23:59:59`;
            obj.op = 'between';
            delete obj.itemName;
            simpleVOs.push(obj);
          } else {
            commonVOs.push(obj);
          }
        });
        let postData = {
          condition: {
            commonVOs,
            simpleVOs
          }
        };
        if (this.isSum) {
          postData.isSum = this.isSum;
        }
        return postData;
      } else {
        if (this.isSum) {
          query.isSum = this.isSum;
        }
        return query;
      }
    },
    beforeQuerySchemaSearch(queryschema) {
      queryschema.querySchema.conditions.map((v) => {
        if (v.name === 'isPresell') {
          if (v.v1 === '0') {
            v.v1 = false;
          } else {
            v.v1 = true;
          }
        };
        if (v.name === 'statusCode') {
          if (_.isEqual(v.v1, this.unpaystatus)) {
            v.v1 = 'PAYMENT';
          } else if (_.isEqual(v.v1, this.orderToOwner)) {
            v.v1 = 'ORDERTOOWNER';
          }
        }
        if (v.name === 'payStatus') {
          if (_.isEqual(v.v1, this.unpay)) {
            v.v1 = 'PAYMENT';
          }
        }
      });
      return queryschema;
    },
    // 控制批量支付按钮是否可点
    handleTableChange(checkedArr) {
      if (checkedArr.length > 0) {
        this.meta.batchBtn[0].disabled = false;
        // 按照原angular逻辑有预售订单批量支付置灰  现在过滤掉
        // checkedArr.map((v) => {
        //   if (v.presaleId) {
        //     this.meta.batchBtn[0].disabled = true;
        //   }
        // });
      } else {
        this.meta.batchBtn[0].disabled = true;
      }
    },
    getUserType() {
      let userType = null;
      let currentUser = this.$store.getters.currentUser;
      if (currentUser.userType === 1 || currentUser.userType === 3) {
        userType = 3;
      } else if (currentUser.userType === 9 || currentUser.userType === 10) {
        userType = 10;
      }
      return userType;
    },
    productReferAction(filterMeta) {
      let meta = {
        isAllowSelectSku: true,
        isSingleSku: true,
        referType: 'TreeTable',
        isMustSelectSku: true
      };
      Object.assign(filterMeta, meta);
      const conf = productRefer(filterMeta, this);
      return conf;
    },
    srcBillIdReferAction(filterMeta) {
      let _currentUser = this.$store.getters.currentUser;
      filterMeta.beforeQuery = (query) => {
        query.conditions.push(
          {
            name: 'agentId',
            controlType: 'String',
            valueType: 'STRING',
            groupType: 2,
            conditionType: 'eq',
            v1: _currentUser.agentId
          },
          {
            name: 'contractStatus',
            controlType: 'String',
            valueType: 'STRING',
            groupType: 2,
            conditionType: 'in',
            v1: '5,6,7'
          }
        );
        if (!this.$getBO('ORDERSHOWWAY')) {
          const curOrg = _currentUser.relations.find((v) => {
            return v.id === localStorage.upAgentRelationId;
          });
          query.conditions.push(
            {
              name: 'bizId',
              controlType: 'Long',
              valueType: 'LONG',
              groupType: 2,
              conditionType: 'eq',
              v1: curOrg.bizId
            },
            {
              name: 'saleOrgId',
              controlType: 'String',
              groupType: 2,
              valueType: 'STRING',
              conditionType: 'eq',
              v1: curOrg.orgId
            });
        }
        return query;
      };
      return srcBillRefer(filterMeta, this);
    },
    getDefaultMeta() {
      const agentBuyProduct = this.$hasAuth('agentBuyProduct');
      const noPayFilter = this.$route.query.cOrderStatus === 'PAYMENT' && this.$route.query.cOrderPayStatus === 'PAYMENT';
      const defaultStatus = this.$route.query.cOrderStatus;
      const meta = {
        billId: 207,
        isAgent: false,
        agentPage: true,
        height: 437,
        tableColumnsSetting: true,
        // isHeight60: true,
        // enableMetaSetting: false,
        // tableMetaSetting: false,
        batchBtn: [],
        stateAdjBtn: [],
        rowClassName: this.rowClassName,
        setVoucherListQuerySchema: this.setVoucherListQuerySchema,
        setListColumns: this.setListColumns,
        actions: {
          statusCodeAction: this.statusCodeAction,
          productReferAction: this.productReferAction, // 特定商品
          srcBillIdReferAction: this.srcBillIdReferAction, // 销售合同
          productClassTreeAction: getProductClassTree, // 商品分类
          shippingChoiceTreeAction: getShippingChoiceTree, // 发运方式
          BizTreeAction: this.BizTreeAction,
          orderDateAction: this.orderDateAction
        },
        async: true
      };
      if (!meta.defaultValue) {
        meta.defaultValue = {};
      }
      meta.defaultValue.orderDate = '-3';
      if (noPayFilter) {
        meta.defaultValue.statusCode = ['CONFIRMORDER', 'DELIVERGOODS', 'DELIVERY_TAKE_PART', 'DELIVERY_PART', 'TAKEDELIVERY', 'ENDORDER'];
        meta.defaultValue.payStatus = ['NOTPAYMENT', 'PARTPAYMENT', 'CONFIRMPAYMENT'];
      } else if (defaultStatus === 'TAKEDELIVERY' && !noPayFilter) {
        meta.defaultValue.statusCode = ['DELIVERY_TAKE_PART', 'TAKEDELIVERY'];
      } else if (defaultStatus !== 'TAKEDELIVERY' && !noPayFilter) {
        meta.defaultValue.statusCode = defaultStatus;
      }

      if (agentBuyProduct && this.$util.isNonfreezeAccount()) {
        meta.addBtn = {
          agentLink: {
            type: 'orderDetail', query: { source: 'newOrder' }, add: true
          }
        };
      }
      return meta;
    },
    setMeta() {
      this.meta = this.getDefaultMeta();
    },
    // 设置queryschema
    setVoucherListQuerySchema(queryschema = []) {
      // 输入框的筛选条件
      const normalGroup = queryschema.find((v) => {
        return v.groupType === 2;
      });
      normalGroup && normalGroup.currentFilterItems.map((filter) => {
        if (filter.name === 'srcBillNO') {
          if (this.$route.query.srcBillNO) {
            filter.defaultValue = this.$route.query.srcBillNO;
          }
          filter.conditionType = 'eq';
        }
        if (filter.name === 'sourceType') {
          if (this.$route.query.srcbillType && this.$route.query.source === 'jingpai') {
            filter.defaultValue = 1;
          }
          filter.name = 'srcbillType';
        }

        if (filter.name === 'srcBillId') {
          if (this.$getBO('ORDERSHOWWAY')) {
            let isShowsrc = this.currentUser.relations.filter((v) => {
              return v.bizType === 1;
            });
            filter.isShowIt = isShowsrc.length > 0;
            filter.isHidden = !isShowsrc.length > 0;
          } else {
            if (this.currentUser.singleBizRelation && this.currentUser.singleBizRelation.currentTradingBiz) {
              let bizType = this.currentUser.singleBizRelation.currentTradingBiz.bizType;
              filter.isShowIt = bizType === 1;
              filter.isHidden = bizType !== 1;
            }
          }
          filter.isShowIt = filter.isShowIt && this.$route.query.source !== 'jingpai';
        }
        if (filter.name === 'shippingChoiceCode') {
          filter.valueKey = 'cCode';
        }
        // 订单类型显示判断
        if (filter.name === 'isPresell' || filter.name === 'sendDate') {
          if (this.$getBO('OPENPRESALE')) {
            filter.isShowIt = true;
            filter.isHidden = false;
          } else {
            filter.isHidden = true;
          }
        }
        // 商家显示判断
        if (filter.name === 'bizId') {
          if (this.currentUser.__showBiz || this.currentUser.__isMutiBiz) {
            filter.isShowIt = true;
            filter.isHidden = false;
          } else {
            filter.isShowIt = false;
          }
        }
        // 我方采购订单号
        if (filter.name === 'purchaseNo') {
          if (this.currentUser.__isU8C) {
            filter.isShowIt = true;
            filter.isHidden = false;
          } else {
            filter.isHidden = true;
          }
        }
        if (filter.name === 'promotionType' && this.currentUser.__isU8C) {
          filter.isShowIt = false;
        }
      });
      return queryschema;
    },
    // 设置table某列展示
    setListColumns(columns) {
      columns.map((v) => {
        // 应付金额显示设置
        if (v.fieldName === 'fRealMoney') {
          if (!v.isHidden) {
            if (!this.$getBO('SETTLEVOUCHERTYPE') === 'SHIPPING') {
              v.isHidden = true;
            }
          }
        }
      });
      return columns;
    },
    queryList(query, isContainBody) {
      if (query.querySchema && !query.querySchema.conditions.length) {
        this.meta.defaultValue.orderDate = '-1';
      }

      // console.log('orders-querylist'); // eslint-disable-line
      // console.log(query); // eslint-disable-line
      query.datatype = 'json';
      delete query.querySchema.fields;
      delete query.querySchema.orders;
      // const menuid = 'orderList';
      let cloneQuery = deepCopy(query);
      query.querySchema.conditions.forEach((v) => {
        if (v.name === 'orderDate') {
          if (v.v1 && !v.v2) {
            let es = dayjs(v.v1).add(6, 'month').format('YYYY-MM-DD');
            v.v2 = `${es} 23:59:59`;
          } else if (v.v2 && !v.v1) {
            let es = dayjs(v.v2).subtract(6, 'month').format('YYYY-MM-DD');
            v.v1 = `${es} 00:00:00`;
          }
        }
      });
      getAllOrders(query, null, isContainBody === 'all').then((res) => {
        this.afterListSearch(cloneQuery, 'orderDate', true);
        const orderIds = [];
        res.total = res.count;
        if (res.count === 0) {
          this.$set(this.meta, 'isShowFooter', false);
        } else {
          this.$set(this.meta, 'isShowFooter', true);
        }
        res.orders.map((v) => {
          v.cOrderNoTo = true;
          v.cOrderNoAgentLink = {
            type: 'orderDetail',
            query: {
              menuid: 'orderDetail',
              cOrderNo: v.cOrderNo,
              iOrderId: v.id,
              id: v.id,
              salesOrgId: v.salesOrgId
            },
            hash: 'detailOrder'
          };
          v.isShowPayBtn = false;
          orderIds.push(v.id);
        });
        res.data = res.content = res.orders;
        let cloneOrders = _.cloneDeep(res.orders);
        getOrderListBtn(orderIds).then((btnArr) => {
          cloneOrders.map((v, k) => {
            let btnRes = btnArr && btnArr.find((j) => {
              return j.voucherNo === v.cOrderNo;
            });
            if (btnRes) {
              v.isShowReceiveBtn = btnRes.isShowReceiveBtn; // 签收
              v.isShowOpenDetail = btnRes.isShowOpenDetail; // 详情
              v.isShowModifyBtn = v.fPointsMoney > 0 ? false : btnRes.isShowModifyBtn; // 积分大于0禁止编辑
              // v.isShowModifyBtn = btnRes.isShowModifyBtn; // 修改
              v.isShowDelBtn = btnRes.isShowDeletedBtn; // 删除
              v.isShowSaleReturnBtn = btnRes.isShowSaleReturnBtn; // 退货
              v.showSaleReturnPartBtn = btnRes.showSaleReturnPartBtn; // 部分退货
              v.isShowTakeDeliveryBtn = btnRes.isShowTakeDeliveryBtn; // 收货确认
              v.isCopyOrder = btnRes.isShowCopyBtn && v.srcbillType !== 0 && v.sourceOrderType !== 'SALEQUOTE' &&  v.srcbillType !== 1;// 再次购买
              v.isShowCommentBtn = btnRes.isShowCommentBtn; // 评价
              v.isShowPayBtn = btnRes.isShowPayBtn; // 付款
              v.isShowAgentUserSubmitBtn = btnRes.isShowAgentUserSubmitBtn; // 提交
              v.isShowConfirmBtn = btnRes.isShowConfirmBtn;
              v.isShowOpposeBtn = btnRes.isShowOpposeBtn;
              v.downloadEInvoiceBtn = btnRes.downloadEInvoiceBtn; // 电子发票
              v.allowToDelivery = btnRes.allowToDelivery; // 电子发票
              // v.isShowAgentUserSubmitBtn = true;
            }
            res.orders.splice(k, 1, v);
          });
        });
        res.totalCount = res.count;
        this.data = res;
      });
    },
    orderDateAction(filteritem) {
      filteritem.options = {
        disabledDate(date) {
          return date && date.valueOf() < dayjs('2018-01-01 00:00:00').valueOf();
        }
      };
      return filteritem;
    },
    batchPay(checkedArr) {
      const batchPayArr = checkedArr.filter((v) => {
        return (v.isShowPayBtn && (v.cNextStatus !== 'SUBMITORDER' || v.cNextStatus !== 'OPPOSE') && (v.cPayStatusCode !== 'FINISHPAYMENT' || v.cPayStatusCode !== 'CONFIRMPAYMENT_ALL'));
      });
      if (!batchPayArr || !batchPayArr.length) {
        this.$Message.warning(this.mlang('batchPayWarningA'));
        return;
      }
      if (batchPayArr.length > 50) {
        this.$Message.warning(this.mlang('batchPayWarningB'));
      }
      this.mixin_batchPay(batchPayArr, this);
    },

    handleBatchClick(name, checkedArr) {
      if (name === 'payBatch') {
        if (!checkedArr || !checkedArr.length) {
          this.$Message.warning(this.mlang('handleBatchClickWarning'));
          return;
        }
        this.batchPay(checkedArr);
      }
    },
    statusCodeAction(item) {
      let arr = JSON.parse(item.enumString);
      let haveSubmitFlag = false;
      haveSubmitFlag = arr.some((v) => {
        return v.name === 'SUBMITORDER';
      });
      if (!haveSubmitFlag) {
        arr.unshift({
          'name': 'SUBMITORDER',
          'title': this.mlang('waitForSubmit'),
          'value': 5,
          'isHidden': false
        });
      }
      item.enumString = JSON.stringify(arr);
      item.customFlag = true;
      return item;
    },
    BizTreeAction(item) {
      item.enumArray = this.bizs;
      return item;
    }
  }
};
</script>
<style lang="less" scoped>
#order-list{
  margin-top: 6px;
  /deep/.main-body{
    border-top: 1px solid #f0f0f0;
  }
  /deep/.orderno-svg{
    float: left;
    margin-left: 10px;
  }
  /deep/.two-line-overflow-hide{
    float: left;
  }
  /deep/.text-gray{
    clear: both;
    color: #b5b5b5;
    min-height: 18px;
  }
  /deep/.icon-genzongxinxi{
    font-size: 16px;
  }
  .icon-presale{
    width: 30px;
  }
  /deep/.btn-item{
    button{
      border-radius:4px;
    }
    .addOrder{
      background:red;
      color: #fff;
      margin-right: 10px;
      border-radius:4px;
      border:1px solid red;
    }
  }

  /deep/.ivu-table .mh54{
    max-height: 36px;
    /deep/.order-no{
      line-height: 18px;
      /deep/a.two-line-overflow-hide{
        min-height: 18px;
      }
    }
  }
}
</style>
