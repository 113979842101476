<template>
  <div class="agent-content-banner">
    <Carousel
      v-if="imgInfo.length"
      v-model="value"
      :autoplay="setting.autoplay"
      :autoplay-speed="autoPlayLoopTime"
      :dots="setting.dots"
      :radius-dot="setting.radiusDot"
      :trigger="setting.trigger"
      :arrow="setting.arrow">
      <CarouselItem
        v-for="img in imgInfo"
        :key="img.id">
        <div class="demo-carousel" :class="{'cursor-pointer':img.carouselType === 1 || img.carouselType === 3 || img.carouselType === 4}">
          <img :src="img.imageUrl" alt="" :fieldid="`fieldid_carousel_img_${img.id}`" @click="imgClick(img)">
        </div>
      </CarouselItem>
    </Carousel>
    <img v-else :src="defaultImg" class="default-img" alt="" fieldid="fieldid_carousel_defaultImg">
  </div>
</template>
<script>
import { getAdPictureSettings } from '@/api/setting/adPictureSetting.js';
import { getPromotionIdWithPictureId } from '@/api/setting/promotionAd.js';
import DefaultImg from '@/assets/images/buaner.jpg';
export default {
  name: 'Carouse',
  data() {
    return {
      value: 0,
      setting: {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: 'inside',
        radiusDot: true,
        trigger: 'click',
        arrow: 'hover'
      },
      imgInfo: [],
      defaultImg: DefaultImg
    };
  },
  computed: {
    autoPlayLoopTime() {
      // 通过业务选项配置轮播切换的时间
      if (this.$getBO('ROTATIONPERIOD')) {
        return this.$getBO('ROTATIONPERIOD') * 1000;
      } else {
        return 5000; // 没有配置默认轮播5秒切换
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    imgClick(img) {
      if (img.carouselType === 2 && img?.id) {
        getPromotionIdWithPictureId(img.id).then((res) => {
          if (Array.isArray(res) && res.length) {
            this.$router.push({
              path: `/agent/promotionGroup`,
              query: {
                promotionIds: res.join(',')
              }
            });
          }
        });
      }
      else {
        switch (img.redirectUrl && img.carouselType) {
          case 1:
            this.$open(img.redirectUrl);
            break;
          case 3:
            this.$open(window.location.origin + '/v#' + img.redirectUrl);
            break;
          case 4:
            this.$router.push({ path: `${img.redirectUrl}` });
            break;
        }
      }
    },
    init() {
      getAdPictureSettings().then((res = []) => {
        this.imgInfo = res;
      });
    }
  }
};
</script>
