import ajax from '@/libs/ajax';

// 获取广告图片列表
export const getAdPictureSettings = (data) => {
  return ajax
    .request({
      url: '/archives/image/carouselimage/list',
      method: 'get',
      data: { isArchive: 1 }
    });
};
// 删除广告图片列表
export const deleteAdPictureSettings = (id) => {
  return ajax
    .request({
      url: `/archives/image/carouselimage/${id}`,
      method: 'DELETE',
      data: {
        isArchive: 1
      }
    });
};

// 广告图片设置详情
export const editAdPictureSetting = (id) => {
  return ajax
    .request({
      url: `/archives/image/carouselimage/${id}`,
      method: 'get',
      data: {
        isArchive: 1
      }
    });
};

// 广告图片设置详情保存
export const saveAdPictureSetting = (data) => {
  return ajax
    .request({
      url: '/archives/image/carouselimage',
      method: 'post',
      data: {
        data,
        isArchive: 1,
        datatype: 'json'
      }
    });
};
