<template>
  <div class="agent-btm" :class="{ zoneAgentBtm: zoneHomePage }">
    <ul class="agent-bottom" :class="{ zoneAgentBottom: zoneHomePage }">
      <li
        v-if="!zoneHomePage"
        :class="['listGroup', 'categories', {'categories-hover': isShowFirstMenu}]"
        fieldid="fieldid_menuBar_showFirstMenu"
        @mouseenter="showFirstMenu">
        <span class="text">{{ mlang('productClass') }}</span>
        <span class="icon">
          <i class="iconfont fenlei"></i>
        </span>
        <Categorys
          @hideFirstMenu="hideFirstMenu" />
      </li>
      <div v-else>
        <img
          v-if="currentUser.corpLogo"
          class="logoImg"
          :alt="currentUser.corpration"
          :src="currentUser.corpLogo"
          :class="{'img-width':imgWidth,'img-height':!imgWidth }" />
      </div>
      <div class="menu-wrapper">
        <li
          v-for="menu in wrapperMenuList"
          :key="menu.id"
          :class="menu.isActive ? 'normal-active listGroup normal' : 'listGroup normal'">
          <span class="text">
            <template v-if="!currentUser.__isU8C">
              <a
                v-if="!menu.disabled"
                href="javascript:void(0)"
                :fieldid="`fieldid_menuBar_isU8CMenu_${menu.sysCode}`"
                @click="checkAuth(menu)">
                {{ menu.name }}
              </a>
            </template>
            <template v-else>
              <Dropdown trigger="click">
                <a
                  v-if="!menu.disabled"
                  href="javascript:void(0)"
                  :fieldid="`fieldid_menuBar_menu_${menu.sysCode}`"
                  @click="checkAuth(menu)">
                  {{ menu.name }}
                  <Icon
                    v-if="menu.children&&menu.children.length > 0"
                    type="ios-arrow-down">
                  </Icon>
                </a>
                <template v-if="menu.children && menu.children.length > 0">
                  <DropdownMenu slot="list">
                    <li
                      v-for="dotMenis in menu.children"
                      :key="dotMenis.id">
                      <template v-if="dotMenis.children && dotMenis.children.length >0">
                        <Dropdown placement="right-start">
                          <DropdownItem style="width: 100px">
                            <div @click.stop="pop()">
                              <a
                                style="color: inherit"
                                href="javascript:void(0)"
                                :fieldid="`fieldid_menuBar_twoLevelMenu_${dotMenis.sysCode}`">
                                {{ dotMenis.name }}
                                <Icon type="ios-arrow-forward"></Icon>
                              </a>
                            </div>
                          </DropdownItem>
                          <DropdownMenu slot="list">
                            <li
                              v-for="dotMeni in dotMenis.children"
                              :key="dotMeni.id">
                              <DropdownItem style="width: 100px">
                                <div style="width:100%" @click="checkAuth(dotMeni)">
                                  <a
                                    style="color: inherit"
                                    href="javascript:void(0)"
                                    :fieldid="`fieldid_menuBar_threeLevelMenu_${dotMeni.sysCode}`">
                                    {{ dotMeni.name }}
                                  </a>
                                </div>
                              </DropdownItem>
                            </li>
                          </DropdownMenu>
                        </Dropdown>
                      </template>
                      <template v-else>
                        <DropdownItem :selected="dotMenis.selected">
                          <div style="width:100%" :fieldid="`fieldid_menuBar_twoLevelMenu_${dotMenis.sysCode}`" @click="checkAuth(dotMenis)">
                            <a
                              style="color: inherit"
                              href="javascript:void(0)">{{ dotMenis.name }}
                            </a>
                          </div>
                        </DropdownItem>
                      </template>
                    </li>
                  </DropdownMenu>
                </template>
              </Dropdown>
            </template>
            <a v-if="menu.disabled" href="javascript:void(0)" class="menu-disabled">{{ menu.name }}</a>
            <i class="iconfont daohangqiehuan"></i>
          </span>
        </li>
        <li class="more">
          <Poptip
            v-if="innerMenuList.length"
            trigger="click"
            placement="right"
            popper-class="more-content"
            :transfer="false"
            content="content">
            <li class="listGroup" style="margin: 0">
              <span class="text">{{ mlang('more') }}</span>
              <i class="iconfont jiantou"></i>
            </li>
            <ul slot="content" class="more-content">
              <li
                v-for="menu in innerMenuList"
                :key="menu.id"
                :class="menu.isActive ? 'normal-active listGroup normal' : 'listGroup normal'"
                style="margin: 0">
                <span class="text">
                  <template v-if="!currentUser.__isU8C">
                    <a
                      v-if="!menu.disabled"
                      href="javascript:void(0)"
                      @click="checkAuth(menu)">
                      {{ menu.name }}
                    </a>
                  </template>
                  <template v-else>
                    <Dropdown trigger="click">
                      <a
                        v-if="!menu.disabled"
                        href="javascript:void(0)"
                        @click="checkAuth(menu)">{{ menu.name }}
                        <Icon
                          v-if="menu.children&&menu.children.length > 0"
                          type="ios-arrow-down">
                        </Icon>
                      </a>
                      <template v-if="menu.children && menu.children.length > 0">
                        <DropdownMenu slot="list">
                          <li
                            v-for="dotMenis in menu.children"
                            :key="dotMenis.id">
                            <template v-if="dotMenis.children && dotMenis.children.length >0">
                              <Dropdown placement="right-start">
                                <DropdownItem style="width: 100px">
                                  <div @click.stop="pop()">
                                    <a
                                      style="color: inherit"
                                      href="javascript:void(0)">
                                      {{ dotMenis.name }}
                                      <Icon type="ios-arrow-forward"></Icon>
                                    </a>
                                  </div>
                                </DropdownItem>
                                <DropdownMenu slot="list">
                                  <li
                                    v-for="dotMeni in dotMenis.children"
                                    :key="dotMeni.id">
                                    <DropdownItem style="width: 100px">
                                      <div style="width:100%" @click="checkAuth(dotMeni)">
                                        <a
                                          style="color: inherit"
                                          href="javascript:void(0)">{{ dotMeni.name }}
                                        </a>
                                      </div>
                                    </DropdownItem>
                                  </li>
                                </DropdownMenu>
                              </Dropdown>
                            </template>
                            <template v-else>
                              <DropdownItem>
                                <div style="width:100%" @click="checkAuth(dotMenis)">
                                  <a
                                    style="color: inherit"
                                    href="javascript:void(0)">{{ dotMenis.name }}
                                  </a>
                                </div>
                              </DropdownItem>
                            </template>
                          </li>
                        </DropdownMenu>
                      </template>
                    </Dropdown>
                  </template>
                  <a v-if="menu.disabled" href="javascript:void(0)" class="menu-disabled">{{ menu.name }}</a>
                  <i class="iconfont daohangqiehuan"></i>
                </span>
              </li>
            </ul>
          </Poptip>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import Categorys from '../categorys.vue';
import { getAgentMenus } from '@/api/agent-home/agent-home.js';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'MenuBar',
  components: {
    Categorys
  },
  props: {
    zoneHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      wrapperMenuList: [],
      innerMenuList: [],
      systemMenus: [
        // 系统预置菜单
        // 首页
        {
          sysCode: 'agentIndex',
          path: '/agent/home'
        },
        // 订单管理
        {
          sysCode: 'agentOrderShow',
          path: '/agent/aorders/orders',
          children: ['Orders', 'Delivery', 'Expenses', 'ReturnBill']
        },
        {
          sysCode: 'agentOrderList',
          path: '/agent/aorders/orders'
        },
        {
          sysCode: 'agentDeliveryList',
          path: '/agent/aorders/deliverys'
        },
        {
          sysCode: 'agentReturnList',
          path: '/agent/aorders/returnBills'
        },
        {
          sysCode: 'agentOrderSignList',
          path: '/agent/aorders/orderSign'
        },
        // 资金账户
        {
          sysCode: 'agentCapital',
          path: '/agent/agentCaptial/pays',
          children: ['Pays', 'Rebates']
        },
        // 分摊/抵现账户
        {
          sysCode: 'amountCountAccount',
          path: '/agent/agentCaptial/amountCountAccount'
        },
        // 金额返货账户
        {
          sysCode: 'amountProductCount',
          path: '/agent/agentCaptial/amountProductCount'
        },
        {
          sysCode: 'memberPoint',
          path: '/agent/agentCaptial/pointTransaction'
        },
        {
          sysCode: 'coupon',
          path: '/agent/agentCaptial/coupon'
        },
        {
          sysCode: 'nccCredit',
          path: '/agent/agentCaptial/credit'
        },
        // 数量返货账户
        {
          sysCode: 'numberProductCount',
          path: '/agent/agentCaptial/numberProductCount'
        },
        // 商品目录
        {
          sysCode: 'agentProductMenu',
          path: '/agent/products',
          children: ['products']
        },
        {
          sysCode: 'agentBrands',
          path: '/agent/abrands'
        },
        {
          sysCode: 'agentPromotionGroup',
          path: '/agent/promotionGroup'
        },
        {
          sysCode: 'agentCommoditySection',
          path: '/agent/commoditySection'
        }
      ],
      isShowFirstMenu: false // 是否显示一级菜单
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'ueConfig']),
    currentUser() {
      let _this = this;
      var img = new Image();
      img.src = this.$store.getters.currentUser.corpLogo;
      img.onload = function() {
        if (img.width / img.height < 144 / 54) {
          _this.imgWidth = false;
        } else {
          _this.imgWidth = true;
        }
      };
      return this.$store.getters.currentUser;
    }
  },
  watch: {
    '$route': {
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.menuSelected();
          this.getCurrentPage(this.$route.query);
        }
      }
    }
  },
  created() {
    window.__setMenuActive = this.setMenuActive;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      getAgentMenus().then((res = []) => {
        const route = this.$route;
        if (route.name === 'frame') {
          // 自定义页面
          res.forEach((item) => {
            if (item.id === route.query.menuId) {
              item.isActive = true;
            } else {
              item.isActive = false;
            }
          });
        } else {
          const currenPage = this.systemMenus.find((item) => {
            if (item.children) {
              return item.children.includes(route.name);
            } else {
              return item.path === route.path;
            }
          });
          currenPage &&
            res.forEach((item) => {
              if (item.sysCode === currenPage.sysCode) {
                item.isActive = true;
              } else {
                item.isActive = false;
              }
            });
        }
        let menuList = res;
        const sum = 976; // 总长度
        let count = 0; // 累加长度
        const maxLen = 140; // 最大宽度
        const marginLen = 24; // 间距
        const moreLen = 78; // 更多宽度
        const fontSize = 16; // 文字像素
        const menuIcon = 20; //下拉图标长度
        for (let i = 0; i < menuList.length; i++) {
          let menu = menuList[i];
          if (!menu.isActive) {
            menu.isActive = false; // 判断当前所在页面
          }
          const isTourist = localStorage.tourist === 'true';

          if(this.currentUser.__isRepresent && menu.url && menu.url.includes('ausa_auction_hall_list')){
            menu.isCorpDisabled = true;
          }
          if (menu.isCorpDisabled) {
            menu.disabled = this.currentUser.__isRepresent || isTourist;
          }

          if (menu.sysCode === 'agentOrderShow' && isTourist) {
            menu.disabled = true;
          }
          if (isTourist) {
            if (menu.code === 'agentCapital' || menu.code === 'agentReportsShow') {
              menu.disabled = true;
            }
          }
          const realLen = this.$util.getBLen(menu.name);
          let menuLen = realLen * (fontSize / 2);

          if(menu.children && menu.children.length){
            menuLen += menuIcon;
          }
          let currentLen = 0; // 当前需要增加的宽度
          // 累加计算当前宽度
          if (menuLen > maxLen) {
            currentLen = maxLen + marginLen;
          } else {
            currentLen = menuLen + marginLen;
          }
          count += currentLen;
          // 比较当前宽度和总宽度，处理数组
          if (count < sum) {
            this.wrapperMenuList.push(menu);
          } else if (count > sum) {
            this.innerMenuList.push(menu);
            count -= currentLen;
          }
        }
        const innerListLen = this.innerMenuList.length;
        const wrapperListLen = this.wrapperMenuList.length;
        const resetLen = sum - count; // 剩余的宽度
        let lastMenuLen = this.$util.getBLen(this.wrapperMenuList[wrapperListLen - 1].name) * (fontSize / 2) + marginLen; // 最后一个元素占的宽度
        if(this.wrapperMenuList[wrapperListLen - 1].children && this.wrapperMenuList[wrapperListLen - 1].children.length){
          lastMenuLen += menuIcon;
        }
        let menu = [];
        if (innerListLen) {
          // 剩余的宽度不能装下【更多】
          if (resetLen < moreLen) {
            // 剩余的宽度+最后一个元素占的宽度
            // 装得下【更多】，则把最后一个元素放到【更多】里
            // 装不下【更多】，则把最后两个元素放到【更多】里
            if (resetLen + lastMenuLen < moreLen) {
              menu = this.wrapperMenuList.splice(wrapperListLen - 2, 2);
            } else {
              menu = this.wrapperMenuList.splice(wrapperListLen - 1, 1);
            }
            this.innerMenuList = [...menu, ...this.innerMenuList];
          }
        }
        this.menuSelected();
        this.$store.commit('setAgentMenuList', this.wrapperMenuList);
        this.getCurrentPage(this.$route.query);
      });
    },
    framePage(menu, uri) {
      // 特殊处理金额报表sysCode === 'amountRebateReportList'时向iframe的url添加salesOrgId参数
      this.$router.push({
        path: '/agent/frame',
        query: {
          menuId: menu.id,
          uri,
          sysCode: menu.sysCode === 'amountRebateReportList' ? menu.sysCode : undefined
        }
      });
    },
    // 检查权限跳路由
    checkAuth(menu) {
      if (!menu.url) {
        return false;
      }
      if (menu.level == 1) {
        this.wrapperMenuList.forEach((item) => {
          if (item.id === menu.parentId) {
            if (item.children && item.children.length > 0) {
              item.children.forEach(citem => {
                if (citem.id === menu.id) {
                  citem.selected = true;
                } else {
                  citem.selected = false;
                }
              });
            }
          } else {
            if (item.children && item.children.length > 0) {
              item.children.forEach(citem => {
                citem.selected = false;
              });
            }
          }
        });
      }
      if (!menu.isSystem) {
        // 非系统预置菜单
        if (menu.isNewWindow) {
          // 跳转外部链接
          let redirectUrl = `/redirect?menuId=${menu.id}&terminal=0`;
          if (this.currentUser.__isRepresent && sessionStorage.isAgentSide === 'true') {
            var upAgentId = localStorage.upAgentId;
            var upAgentRelationId = localStorage.upAgentRelationId !== 'undefined' ? localStorage.upAgentRelationId : '';
            redirectUrl += `&iAgentId=${upAgentId}&upAgentRelationId=${upAgentRelationId}`;
          }
          this.$open(redirectUrl);
        } else {
          // 自定义页面
          this.framePage(menu);
        }
      } else {
        // 系统预置菜单
        if (menu && menu.isNewWindow) {
          // 跳转外部链接
          let currentPage = this.systemMenus.find(item => item.sysCode === menu.sysCode);
          // 判断跳转的路由是否启用了新页面（即config.js里对应页面是否true）
          let currentConfig = false;
          if (this.ueConfig.enable) {
            currentConfig = this.ueConfig.pages[menu.sysCode];
          } else {
            currentConfig = false;
          }
          // 因为品牌中心是v#/...外部页面，排除掉品牌中心，直接跳新页面
          if (currentPage && currentPage.sysCode === 'agentBrands') {
            this.$open('/v#/agent/abrands');
          } else if (currentPage && currentConfig) {
            this.agentLink({
              type: currentPage.sysCode,
              add: true
            });
          } else {
            this.$open(menu.url);
          }
          return false;
        } else {
          // 系统菜单
          // this.getCurrentPage(menu);
          // 只控制了新页面跳转
          // 判断跳转的路由是否是新的（即systemMenus数组中是否注册了）
          let currentPage = this.systemMenus.find(item => item.sysCode === menu.sysCode);
          // 判断跳转的路由是否启用了新页面（即config.js里对应页面是否true）
          let currentConfig = false;
          if (this.ueConfig.enable) {
            currentConfig = this.ueConfig.pages[menu.sysCode];
          } else {
            currentConfig = false;
          }
          // 因为品牌中心是v#/...外部页面，排除掉品牌中心，直接跳新页面
          if (currentPage && currentPage.sysCode === 'agentBrands') {
            this.$router.push({
              path: currentPage.path,
              query: {
                menuId: menu.id
              }
            });
          } else if (currentPage && currentConfig) {
            this.$router.push({
              path: currentPage.path,
              query: {
                menuId: menu.id
              }
            });
          } else {
            // 跳到老页面
            if (_.startsWith(menu.url, '/v#/singlepage')) {
              return false;
            } else if (menu.openType === 1) {
              const menuCode = ['corpCertification', 'personCertification', 'corpSeals', 'personalSeals', 'authorizedSeals', 'signatureFlowSetting']
              // if ((menu.name === '企业认证管理') || (menu.name === '个人认证管理') || (menu.name === '企业印章管理') || (menu.name === '授权给我的印章') || (menu.name === '个人印章管理') || (menu.name === '签署流程设置')) {
              if(menuCode.includes(menu.code)){
                this.$router.push({
                  path: menu.url.replace(/\/v#/, ''),
                  query: {
                    menuId: menu.id
                  }
                });
              } else {
                this.framePage(menu);
              }
            } else {
              let url = this.$util.agentPageUrl(menu.url);
              this.framePage(menu, url);
            }
          }
        }
      }
    },
    // 新页面用，查当前所在页面,并选中菜单
    getCurrentPage() {
      const currentMenu = this.$route.query;
      const currentMeta = this.$route.meta;
      if (currentMeta.menuCode) {
        Object.assign(currentMenu, { menuCode: currentMeta.menuCode });
      }
      this.wrapperMenuList.forEach((item) => {
        if (item.id === currentMenu.menuId || currentMenu.menuCode === item.sysCode) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
      this.innerMenuList.forEach((item) => {
        if (item.id === currentMenu.menuId || currentMenu.menuCode === item.sysCode) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
    },
    menuSelected() {
      this.wrapperMenuList.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach(citem => {
            if (citem.id === this.$route.query?.menuId) {
              citem.selected = true;
            } else {
              citem.selected = false;
            }
          });
        }
      });
    },
    // 老页面用，查当前所在页面，并选中菜单
    setMenuActive(menuCode) {
      this.wrapperMenuList.forEach((item) => {
        if (menuCode === item.sysCode) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
      this.innerMenuList.forEach((item) => {
        if (menuCode === item.sysCode) {
          item.isActive = true;
        } else {
          item.isActive = false;
        }
      });
    },
    showFirstMenu() {
      this.isShowFirstMenu = true;
    },
    hideFirstMenu() {
      this.isShowFirstMenu = false;
    }
  }
};
</script>

<style lang="less" scoped>
.zoneAgentBtm {
  height: 60px !important;
}
.zoneAgentBottom {
  display: flex !important;
  height: 60px !important;
  align-items: center !important;
}
.logoImg{
  width: 229.16px;
  height: 43.19px;
}
.menu-bar {
  height: 62px;
  min-width: 1220px;
  background-color: bisque;
  .menu-bar-content {
    width: 1220px;
    margin: 0 auto;
    div {
      float: left;
      padding: 2px;
    }
  }
}
.more-content {
  li {
    height: 38px;
    padding: 0 15px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 38px;
    cursor: pointer;
    &:hover {
      background: #f3f5f9;
    }
    a:hover {
      color: #333;
    }
    .menu-disabled {
      color: #999;
      cursor: no-drop;
    }
  }
  .more-active {
    background: #f3f5f9;
    a {
      color: #333;
    }
  }
}
</style>
