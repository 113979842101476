
const rebateCenter = [
  // 商品费用
  {
    path: 'goodsRebates',
    name: 'goodsRebates',
    meta: {
      module: 'goodsRebates'
    },
    component: () => import('@/views/corp/rebateCenter/components/goodsRebate/goodsRebates.vue')
  },
  // 金额费用
  {
    path: 'discountRebates',
    name: 'discountRebates',
    meta: {
      module: 'discountRebates'
    },
    component: () => import('@/views/corp/rebateCenter/components/discountRebate/discountRebates.vue')
  },
  // 商品费用详情
  {
    path: 'goodsRebate',
    name: 'goodsRebate',
    meta: {
      module: 'goodsRebates',
      notCache: true
    },
    component: () => import('@/views/corp/rebateCenter/components/goodsRebate/goodsRebate.vue')
  },
  // 金额费用详情
  {
    path: 'discountRebate',
    name: 'discountRebate',
    meta: {
      module: 'discountRebates',
      notCache: true
    },
    component: () => import('@/views/corp/rebateCenter/components/discountRebate/discountRebate.vue')
  },
  // 费用使用规则
  {
    path: 'refundRules',
    title: { i18n: 'title.refundRules' },
    name: 'refundRules',
    meta: {
      title: '费用使用规则',
      module: 'refundRules'
    },
    component: () => import('@/views/corp/rebateCenter/customerRebate/refundRules/refundRules.vue')
  },
  // 费用使用规则详情
  {
    path: 'refundRule',
    title: { i18n: 'title.refundRules' },
    name: 'refundRule',
    meta: {
      title: '费用使用规则',
      module: 'refundRules',
      notCache: true
    },
    component: () => import('@/views/corp/rebateCenter/customerRebate/refundRules/refundRule.vue')
  }
  // FLH End
];
export default rebateCenter;
