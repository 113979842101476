import stocks from './stock';
import stockInventory from './stockInventory';
import stockInventorys from './stockInventorys';
import showStockTipSetting from './showStockTipSetting';
import showStockTipSettingDetail from './showStockTipSettingDetail';

export default {
  stocks,
  stockInventory,
  stockInventorys,
  showStockTipSetting,
  showStockTipSettingDetail
};
