import corpParentView from '@/views/corp/home/home.vue';

const basebill = {
  path: 'basebill',
  title: { i18n: 'basebill' },
  name: 'basebill',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'expenseOrderType',
      title: { i18n: 'title.expenseOrderType' },
      name: 'expenseOrderType',
      meta: { module: 'expenseOrderTypes', notCache: true },
      component: () => import('@/views/corp/basebill/components/expenseOrderType/expenseOrderType.vue')
    },
    {
      path: 'expenseOrderTypes',
      title: { i18n: 'title.expenseOrderType' },
      name: 'expenseOrderTypes',
      meta: { module: 'expenseOrderTypes' },
      component: () => import('@/views/corp/basebill/components/expenseOrderType/expenseOrderTypes.vue')
    },
    {
      path: 'freightTemplate',
      name: 'freightTemplate',
      meta: { module: 'freightTemplates', notCache: true },
      component: () => import('@/views/corp/basebill/components/freightTemplate/freightTemplate.vue')
    },
    {
      path: 'freightTemplates',
      name: 'freightTemplates',
      meta: { module: 'freightTemplates' },
      component: () => import('@/views/corp/basebill/components/freightTemplate/freightTemplates.vue')
    },
    {
      path: 'shippingChoice',
      title: { i18n: 'title.shippingChoice' },
      name: 'shippingChoice',
      meta: { module: 'shippingChoices', notCache: true },
      component: () => import('@/views/corp/basebill/components/shippingChoice/shippingChoice.vue')
    },
    {
      path: 'shippingChoices',
      title: { i18n: 'title.shippingChoice' },
      name: 'shippingChoices',
      meta: { module: 'shippingChoices' },
      component: () => import('@/views/corp/basebill/components/shippingChoice/shippingChoices.vue')
    },
    {
      path: 'logisticsCompany', // 物流公司
      title: { i18n: 'title.logisticsCompanys' },
      name: 'logisticsCompany',
      meta: { module: 'logisticsCompanys', notCache: true },
      component: () => import('@/views/corp/basebill/components/logisticsCompany/logisticsCompany.vue')
    },
    {
      path: 'logisticsCompanys', // 物流公司
      title: { i18n: 'title.logisticsCompanys' },
      name: 'logisticsCompanys',
      meta: { module: 'logisticsCompanys' },
      component: () => import('@/views/corp/basebill/components/logisticsCompany/logisticsCompanys.vue')
    },
    {
      path: 'settlementMethod',
      name: 'settlementMethod',
      meta: { notCache: true },
      component: () => import('@/views/corp/basebill/components/settlementMethod/settlementMethod.vue')
    },
  ]
};

export default basebill;
