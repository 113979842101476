<template>
  <keep-alive
    :include="cacheList"
    :exclude="notCacheName">
    <router-view ref="child" />
  </keep-alive>
</template>
<script>
export default {
  name: 'ParentView',
  computed: {
    tagNavList() {
      return this.$store.state.app.tagNavList;
    },
    notCacheName() {
      const list = [(this.$route.meta && this.$route.meta.notCache) ? this.$route.name : ''];
      return list;
    },
    cacheList() {
      const list = ['ParentView', ...this.tagNavList.length ? this.tagNavList.filter(item => !(item.meta && item.meta.notCache)).map(item => item.name) : []];
      return list;
    }
  }
};
</script>
