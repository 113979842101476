// 客户交易关系
import { selectAgentRelations } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const agentsRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      title: vm.mlang('agentName'),
      page: true,
      bRefer: true,
      bread: false,
      multiple: false,
      fieldName: 'iAgentId',
      controlType: 'Refer',
      refReturnValue: 'agentRelationId',
      reffieldName: 'name',
      refName: 'name',
      queryschema: [
        {
          name: 'agent.name',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('name'),
          conditionType: 'like'
        },
        {
          name: 'agent.code',
          controlType: 'String',
          groupType: 2,
          valueType: 'STRING',
          isShowIt: true,
          placeholder: vm.mlang('code'),
          conditionType: 'rlike'
        }
      ],
      columns: [
        {
          showCaption: vm.mlang('agentCode'),
          isShowIt: true,
          fieldName: 'code',
          width: 120
        },
        {
          showCaption: vm.mlang('agentName'),
          fieldName: 'name',
          width: 180,
          isShowIt: true
        },
        {
          showCaption: vm.mlang('agentArea'),
          isShowIt: true,
          width: 170,
          fieldName: 'agentAreaName'
        },
        {
          showCaption: vm.mlang('agentCategory'),
          isShowIt: true,
          width: 170,
          fieldName: 'agentCategoryName'
        },
        {
          showCaption: vm.mlang('agentLevel'),
          isShowIt: true,
          width: 170,
          fieldName: 'agentLevelName'
        }
      ],
      actions: { queryList: selectAgentRelations },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  const orgColumn = control.columns.find((v) => {
    return v.fieldName === 'orgName';
  });
  if (vm.$store.getters.currentUser.__showOrg && !orgColumn) {
    control.columns.push({
      showCaption: vm.mlang('org'),
      isShowIt: true,
      width: 180,
      fieldName: 'orgName'
    });
  }
  return deepCopy(control);
};
export default agentsRefer;
