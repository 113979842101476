import Vue from 'vue';
import zhLocale from 'iview/src/locale/lang/zh-CN';
import customZhCn from './lang/zh-cn';

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// 自动设置语言
// const navLang = navigator.language;
// const localLang = navLang === 'zh-cn' || navLang === 'en-us' ? navLang : false;
let lang = 'zh-cn';

Vue.config.lang = lang;

// vue-i18n 6.x+写法
Vue.locale = () => {};
const messages = {
  'zh-cn': Object.assign(zhLocale, customZhCn)
};
const i18n = new VueI18n({
  locale: lang,
  messages
});
export default i18n;
