export default {
  name: 'ArchiveRender',
  functional: true,
  props: {
    control: Object,
    data: Object,
    render: Function
  },
  render: (h, ctx) => {
    const params = ctx.props;
    return ctx.props.render(h, params);
  }
};
