import corpParentView from '@/views/corp/home/home.vue';

const history = {
  path: 'history',
  title: { i18n: 'order' },
  name: 'history',
  redirect: '/corp/home/index',
  component: corpParentView,
  children: [
    {
      path: 'orders',
      title: { i18n: 'title.orders' },
      name: 'ordersHistory',
      meta: { module: 'ordersHistory' },
      component: () => import('@/views/corp/order/components/orders.vue')
    },
    {
      path: 'orderHistory',
      title: { i18n: 'title.orders' },
      name: 'orderHistory',
      meta: { module: 'ordersHistory',notCache: true },
      component: () => import('@/views/corp/history/order/orderHistory.vue')
    },
    {
      path: 'salereturns',
      title: { i18n: 'title.salereturns' },
      name: 'salereturnsHistory',
      meta: { module: 'salereturnsHistory' },
      component: () => import('@/views/corp/order/components/salereturns.vue')
    },
    {
      path: 'saleReturnHistory',
      title: { i18n: 'title.salereturns' },
      name: 'saleReturnHistory',
      meta: { module: 'saleReturnHistory' },
      component: () => import('@/views/corp/history/order/saleReturnHistory.vue')
    },
    {
      path: 'deliverys',
      title: { i18n: 'title.deliverys' },
      name: 'deliverysHistory',
      meta: { module: 'deliverysHistory' },
      component: () => import('@/views/corp/order/components/deliverys.vue')
    },
    {
      path: 'deliveryHistory',
      title: { i18n: 'title.deliverys' },
      name: 'deliveryHistory',
      meta: { module: 'deliverysHistory' },
      component: () => import('@/views/corp/order/components/deliveryHistory.vue')
    },
    {
      path: 'payments',
      title: { i18n: 'title.payments' },
      name: 'paymentsHistory',
      meta: { module: 'paymentsHistory' },
      component: () => import('@/views/corp/settle/components/payments.vue')
    },
    {
      path: 'paymentHistory',
      title: { i18n: 'title.payments' },
      name: 'paymentHistory',
      meta: { module: 'paymentHistory' },
      component: () => import('@/views/corp/history/order/paymentHistory.vue')
    },
    {
      path: 'refunds',
      title: { i18n: 'title.refunds' },
      name: 'refundsHistory',
      component: () => import('@/views/corp/settle/components/refunds.vue')
    },
    {
      path: 'discountRebates',
      name: 'discountRebatesHistory',
      meta: {
        module: 'discountRebatesHistory'
      },
      component: () => import('@/views/corp/rebateCenter/components/discountRebate/discountRebates.vue')
    },
    {
      path: 'goodsRebates',
      name: 'goodsRebatesHistory',
      meta: {
        module: 'goodsRebatesHistory'
      },
      component: () => import('@/views/corp/rebateCenter/components/goodsRebate/goodsRebates.vue')
    }
  ]
};
export default history;
