import ajax from '@/libs/ajax';
import util from '@/libs/util.js';

export const getCorpConfig = (url) => {
  return ajax.request({
    url: '/passport/checkhastourist',
    method: 'get',
    data: {
      domain: url,
      nodeal: 1,
      isArchive: 1
    }
  });
};

export const getLogindefine = (data) => {
  return ajax.request({
    url: '/marketingbill/portal/logindefine',
    method: 'post',
    data: {
      data,
      datatype: 'json',
      isMarket: 1
    }
  });
};

export const getCopyRight = () => {
  return ajax.request({
    url: '/copyright',
    method: 'get',
    data: {
    }
  });
};

export const fetchLoginSwiper = () => {
  return ajax.request({
    url: '/loginSwiper',
    method: 'get',
    data: {
    }
  });
};

export const getPrivateCloud = () => {
  return ajax.request({
    url: '/privatecloud',
    method: 'get',
    data: {
    }
  });
};

export const getDiyJoin = () => {
  return ajax.request({
    url: '/diyjoin',
    method: 'get',
    data: {
    }
  });
};

export const doLogin = (data) => {
  // 密码加密
  data.password = util.encrypt(data.password);
  let url = '/gscm/user/newLogin';

  if (data.type) {
    switch (data.type) {
      case 'yxy':
        url = '/passport/yxy/' + data.yxyToken + '/login';
        break;
      case 'tplogin':
        url = '/passport/tpLogin/' + data.appkey + '/' + data.sign + '/' + data.userKey + (data.format ? '?format=' + data.format : '') + (data.userId ? '&userId=' + data.userId : '');
        break;
      case 'esn':
        url = '/pc/passport/esnLogin';
        break;
      case 'tourist':
        url = '/passport/tourist/login';
        break;
      default:
        url = '/gscm/user/newLogin';
        break;
    }
    if (url === '/gscm/user/newLogin') {
      if (Object.prototype.toString.call(data) === '[object Object]') {
        data.originalLoginUrl = location.host;
        data.loginDeviceType = 0;
      }
    }
    return ajax.request({
      url: url,
      method: 'post',
      data: {
        data: data.data ? data.data : data,
        datatype: 'json',
        isArchive: 1,
        nodeal: true
      }
    });
  } else {
    if (Object.prototype.toString.call(data) === '[object Object]') {
      data.originalLoginUrl = location.host;
      data.loginDeviceType = 0;
    }
    return ajax.request({
      url: url,
      method: 'post',
      data: {
        data: data.data ? data.data : data,
        datatype: 'json',
        isMarket: 1,
        nodeal: true
      }
    });
  }
};

export const getCaptch = (data) => {
  return ajax.request({
    url: `/captcha/verify/send/${data.mobile}/changepassword?countryCode=${data.countryCode}`,
    method: 'post',
    data: {
      data: data.captcha,
      isArchive: 1,
      datatype: 'json'
    }
  });
};
export const passwordEncode = (data) => {
  return ajax.request({
    url: '/encodePassword',
    method: 'post',
    data: {
      data,
      datatype: 'json'
    }
  });
};

export const checkMsgCaptch = (data) => {
  return ajax.request({
    url: `/captcha/verify/check/${data.mobile}/changepassword?countryCode=${data.countryCode}`,
    method: 'post',
    data: {
      data: data.captcha,
      isArchive: 1,
      datatype: 'json'
    }
  });
};

export const changePwd = (data) => {
  let url = '';
  if (data.countryCode) {
    url = `/passport/user/${data.mobile}/${data.tempToken}/password/change?countryCode=${data.countryCode}`;
  } else {
    url = `/passport/user/${data.mobile}/${data.tempToken}/password/change`;
  }
  return ajax.request({
    url: url,
    method: 'post',
    data: {
      data: {
        userId: data.userId,
        newPassword: data.password
      },
      datatype: 'json',
      isArchive: 1
    }
  });
};

export const showJustLook = () => {
  return ajax.request({
    url: '/showJustLook',
    method: 'get',
    data: {
    }
  });
};
// 登录获取验证码
export const getVerifyCode = (data) => {
  return ajax.request({
    url: '/captcha/valid/send/verify/' + data.mobile + '/login',
    method: 'post',
    data: {
      imgKey: data.imgKey,
      imgCode: data.imgCode,
      countryCode: data.countryCode,
      isArchive: 1,
      datatype: 'json'
    }
  });
};
export const getYhtAccountUrl = () => {
  return ajax.request({
    url: '/passport/yht/usercenter?isArchive=1',
    method: 'get'
  });
};
export const checkSharecode = (orderNo, validCode) => {
  return ajax.request({
    url: '/voucher/order/share/valid',
    method: 'post',
    data: {
      data: {
        orderNo: orderNo,
        validCode: validCode
      },
      isArchive: 1,
      datatype: 'json'
    }
  });
};
export const getInvitationAbout = (bizId) => {
  return ajax.request({
    url: `/passport/invitation/${bizId}/org?isArchive=1`,
    method: 'get'
  });
};
