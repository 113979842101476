import { otherRouter, appRouter } from '@/router/router';
import beforeClose from '@/router/before-close';
import util from '@/libs/util';
import Cookies from 'js-cookie';
import router from '@/router';
import Vue from 'vue';
import { getI18n } from '@/api/bo';
import { ueConfig } from '@/api/agent-home/agent-home.js';
import { ueOrderTipConfig } from '@/api/config.js';
import { getExtendJsConfig } from '@/api/meta';
const homeName = 'corp_home_index';
const closePage = (state, route) => {
  const nextRoute = util.getNextRoute(state.tagNavList, route);
  state.tagNavList = state.tagNavList.filter((item) => {
    return !util.routeEqual(item, route);
  });
  router.push(nextRoute);
};
const app = {
  state: {
    isMultiLang: util.getIsMultiLang(),
    langs: [],
    tagNavList: [],
    homeRoute: {},
    cachePage: [],
    i18nResource: null,
    ueConfig: { enable: false },
    lang: util.getLang(),
    orderTipConfig: { enable: false },
    isFullScreen: false,
    extendJsConfig: null, // 拓展js配置
    openedSubmenuArr: [], // 要展开的菜单数组
    menuTheme: 'light', // 主题
    themeColor: '',
    yyvuextest: [],
    pageOpenedList: [
      {
        title: '首页',
        path: '',
        name: 'home_index'
      }
    ],
    currentPageName: '',
    currentPath: [
      {
        title: '首页',
        path: '',
        name: 'home_index'
      }
    ], // 面包屑数组
    menuList: [],
    routers: [otherRouter, ...appRouter],
    tagsList: [...otherRouter.children],
    messageCount: 0,
    dontCache: ['text-editor', 'artical-publish'], // 在这里定义你不想要缓存的页面的name属性值(参见路由配置router.js)
    agentMenuList: [], // 添加agent的全局menu列表
  },
  getters: {
    tagNavList: (state, getters, rootState) => state.tagNavList,
    isMultiLang: (state, getters, rootState) => state.isMultiLang,
    ueConfig: (state, getters, rootState) => state.ueConfig,
    orderTipConfig: (state, getters, rootState) => state.orderTipConfig,
    extendJsConfig: (state, getters, rootState) => state.extendJsConfig,
    langs: (state, getters, rootState) => state.langs,
    agentMenuList: (state, getters, rootState) => state.agentMenuList
  },
  mutations: {
    setHomeRoute(state, routes) {
      const homeRoute = util.getHomeRoute(routes, homeName);
      homeRoute.fullPath = '/corp/home';
      state.homeRoute = homeRoute;
    },
    hideSameModuleTag(state, { newRoute, oldRoute }) {
      const moduleNext = newRoute.meta && newRoute.meta.module;
      const moduleLast = oldRoute.meta && oldRoute.meta.module;
      if (moduleNext !== undefined && moduleNext === moduleLast) {
        const preTag = state.tagNavList.find((v) => {
          // 上一个
          return v.name === oldRoute.name;
        });
        const nextTag = state.tagNavList.find((v) => {
          // 下一个
          return v.name === newRoute.name;
        });
        if (preTag) {
          preTag.meta = preTag.meta || {};
          if (preTag.meta.notCache !== true) {
            // 隐藏列表逻辑
            const newMeta = Object.assign({}, preTag.meta, { isHidden: true });
            preTag.meta = newMeta;
          } else if (preTag.meta.notCache === true) {
            // 删除详情
            const preIndex = state.tagNavList.findIndex((v) => v.name === preTag.name);
            state.tagNavList.splice(preIndex, 1);
          }
        }
        if (nextTag) {
          nextTag.meta = nextTag.meta || {};
          if (nextTag.meta.isHidden) {
            const newMeta = Object.assign({}, nextTag.meta, { isHidden: false });
            nextTag.meta = newMeta;
          }
        }
      }
      // util.setTagNavListInLocalstorage([...state.tagNavList]);
    },
    setTagNavList(state, list) {
      let tagList = [];
      if (list) {
        tagList = [...list];
      } else {
        tagList = [];
      }
      if (tagList[0] && tagList[0].name !== homeName) {
        tagList.shift();
      }
      let homeTagIndex = tagList.findIndex((item) => item.name === homeName);
      if (homeTagIndex > 0) {
        let homeTag = tagList.splice(homeTagIndex, 1)[0];
        tagList.unshift(homeTag);
      }
      state.tagNavList = tagList;
      // util.setTagNavListInLocalstorage([...tagList]);
    },
    setTagsList(state, list) {
      state.tagsList.push(...list);
    },
    updateMenulist(state) {
      let accessCode = parseInt(Cookies.get('access'));
      let menuList = [];
      appRouter.forEach((item, index) => {
        if (item.children.length === 1) {
          menuList.push(item);
        } else {
          let len = menuList.push(item);
          let childrenArr = [];
          childrenArr = item.children.filter((child) => {
            if (child.access !== undefined) {
              if (util.showThisRoute(child.access, accessCode)) {
                return child;
              }
            } else {
              return child;
            }
          });
          if (childrenArr === undefined || childrenArr.length === 0) {
            menuList.splice(len - 1, 1);
          } else {
            let handledItem = JSON.parse(JSON.stringify(menuList[len - 1]));
            handledItem.children = childrenArr;
            menuList.splice(len - 1, 1, handledItem);
          }
        }
      });
      state.menuList = menuList;
    },
    changeMenuTheme(state, theme) {
      state.menuTheme = theme;
    },
    changeMainTheme(state, mainTheme) {
      state.themeColor = mainTheme;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    addOpenSubmenu(state, name) {
      let hasThisName = false;
      let isEmpty = false;
      if (name.length === 0) {
        isEmpty = true;
      }
      if (state.openedSubmenuArr.indexOf(name) > -1) {
        hasThisName = true;
      }
      if (!hasThisName && !isEmpty) {
        // state.openedSubmenuArr.push(name);
        state.openedSubmenuArr = [name];
      }
    },
    closeTag(state, route) {
      let tag = state.tagNavList.filter((item) => util.routeEqual(item, route));
      route = tag[0] ? tag[0] : null;
      if (!route) return;
      if (route.meta && route.meta.beforeCloseName && route.meta.beforeCloseName in beforeClose) {
        new Promise(beforeClose[route.meta.beforeCloseName]).then((close) => {
          if (close) {
            closePage(state, route);
          }
        });
      } else {
        closePage(state, route);
      }
    },
    addTag(state, { route, type = 'unshift', vm }) {
      let MANYTAB = vm.$getBO('MANYTAB');
      let router = util.getRouteTitleHandled(route);
      let tagNavList = state.tagNavList;
      if (type === 'push') {
        const existModuleRoute = util.routeExistModule(tagNavList, router, MANYTAB);
        const routeHasExist = util.routeHasExist(tagNavList, router, MANYTAB);
        if (routeHasExist !== false) {
          tagNavList[routeHasExist] = router;
        } else if (existModuleRoute) {
          const oldIndex = tagNavList.findIndex((item) => {
            return item.name === existModuleRoute.name;
          });
          tagNavList.splice(oldIndex + 1, 0, router);
        } else if (routeHasExist === false) {
          tagNavList.push(router);
        }
      } else {
        if (router.name === homeName) {
          tagNavList.unshift(router);
        } else {
          tagNavList.splice(1, 0, router);
        }
      }
      // tagNavList.forEach((v) => {
      //   console.log(v.meta.uuid);
      //   console.log(v.meta.hash);
      // });
      // util.setTagNavListInLocalstorage([...tagNavList]);
    },
    setLocal(state, lang) {
      util.setLocal(lang);
      state.lang = lang;
    },
    initCachepage(state) {
      if (localStorage.cachePage) {
        state.cachePage = JSON.parse(localStorage.cachePage);
      }
    },
    removeTag(state, name) {
      state.pageOpenedList.map((item, index) => {
        if (item.name === name) {
          state.pageOpenedList.splice(index, 1);
        }
      });
    },
    pageOpenedList(state, get) {
      let openedPage = state.pageOpenedList[get.index];
      if (get.argu) {
        openedPage.argu = get.argu;
      }
      if (get.query) {
        openedPage.query = get.query;
      }
      state.pageOpenedList.splice(get.index, 1, openedPage);
      localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
    },
    clearAllTags(state) {
      state.pageOpenedList.splice(1);
      state.cachePage.length = 0;
      localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
    },
    clearOtherTags(state, vm) {
      let currentName = vm.$route.name;
      let currentIndex = 0;
      state.pageOpenedList.forEach((item, index) => {
        if (item.name === currentName) {
          currentIndex = index;
        }
      });
      if (currentIndex === 0) {
        state.pageOpenedList.splice(1);
      } else {
        state.pageOpenedList.splice(currentIndex + 1);
        state.pageOpenedList.splice(1, currentIndex - 1);
      }
      let newCachepage = state.cachePage.filter((item) => {
        return item === currentName;
      });
      state.cachePage = newCachepage;
      localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
    },
    setOpenedList(state) {
      // state.pageOpenedList = localStorage.pageOpenedList ? JSON.parse(localStorage.pageOpenedList) : [otherRouter.children[0]];
    },
    setCurrentPath(state, pathArr) {
      state.currentPath = pathArr;
    },
    setCurrentPageName(state, name) {
      state.currentPageName = name;
    },
    setAvator(state, path) {
      localStorage.avatorImgPath = path;
    },
    switchLang(state, lang) {
      state.lang = lang;
      Vue.config.lang = lang;
    },
    clearOpenedSubmenu(state) {
      state.openedSubmenuArr.length = 0;
    },
    setMessageCount(state, count) {
      state.messageCount = count;
    },
    increateTag(state, tagObj) {
      // if (!util.oneOf(tagObj.name, state.dontCache)) {
      //   state.cachePage.push(tagObj.name);
      //   localStorage.cachePage = JSON.stringify(state.cachePage);
      // }
      // state.pageOpenedList.push(tagObj);
      // localStorage.pageOpenedList = JSON.stringify(state.pageOpenedList);
    },
    SET_UE_CONFIG: (state, res) => {
      state.ueConfig = res;
    },
    SET_ORDERTIP_CONFIG: (state, res) => {
      state.orderTipConfig = res;
    },
    SET_EXTENDJS_CONFIG: (state, res) => {
      state.extendJsConfig = res;
    },
    SET_I18NS: (state, res) => {
      state.langs = res.langs;
    },
    setAgentMenuList(state, list) {
      state.agentMenuList = list;
    }
  },
  actions: {
    GetExtendJsConfig: async({ state, commit }) => {
      // 这里是为了防止重复获取
      if (state.extendJsConfig !== null) return;
      const list = await getExtendJsConfig();
      let routerList = list.filter((item) => { return item.rotues !== 'common'; });
      let commonList = list.filter((item) => { return item.rotues === 'common'; });
      let extendList = routerList.concat(commonList);
      commit('SET_EXTENDJS_CONFIG', extendList);
    },
    getI18n({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        getI18n().then((res = {}) => {
          if (res) {
            commit('SET_I18NS', res);
            // if (!state.lang) {
            //   commit('setLocal', res.defaultLang);
            // }
            // commit('SET_I18N_RESOURCE');
            resolve(res);
          }
        })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUeConfig({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        ueConfig().then((res) => {
          commit('SET_UE_CONFIG', res);
          resolve(res);
        });
      });
    },
    getOrderTipConfig({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        ueOrderTipConfig().then((res) => {
          commit('SET_ORDERTIP_CONFIG', res);
          // if (!state.lang) {
          //   commit('setLocal', res.defaultLang);
          // }
          // commit('SET_I18N_RESOURCE');
          resolve(res);
        });
      });
    }
  }
};

export default app;
