<template>
  <div class="stairs" :style="myStyle">
    <SvgIcon class="jiqiren" icon-class="loucengjiqiren"></SvgIcon>
    <template v-if="showProduct">
      <li
        v-for="anchor in anchors"
        :key="anchor.id"
        :class="[{ 'tuijian': anchor.id === 'recommond-product' },
                 { 'quanbu': anchor.id === 'all-product' },
                 { 'tip-active': anchor.active }]"
        :fieldid="`fieldid_backToTop_${anchor.id}`"
        @click="scrollInto(anchor)">
        <p>{{ anchor.name }}</p>
      </li>
    </template>
    <li
      class="top"
      fieldid="fieldid_backToTop_goTop"
      @click="backToTop">
      <i class="iconfont huidaodingbu"></i>
      <p>{{ mlang('top') }}</p>
    </li>
  </div>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    allProducts: {
      type: Array,
      default: ()=>{
        return [1,2]
      }
    },
    recommendProducts: {
      type: Array,
      default: ()=>{
        return [1,2]
      }
    },
    showProduct: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: String,
      default: '64px'
    }
  },
  data() {
    const anchors = this.makeAnchors();
    return {
      // 阶梯数组
      anchors
    };
  },
  computed: {
    myStyle() {
      return {
        bottom: this.bottom
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.resize, true);
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.resize, true);
  },
  methods: {
    makeAnchors() {
      let anchors = [];
      const reco = {
        id: 'recommond-product',
        name: this.mlang('recommendPro'),
        top: 0,
        bottom: 0,
        active: false,
        childId: 'recommond-child'
      };
      const all = {
        id: 'all-product',
        name: this.mlang('allPro'),
        top: 0,
        bottom: 0,
        active: false,
        childId: 'all-child'
      };
      if (this.recommendProducts.length) {
        anchors.push(reco);
      }
      if (this.allProducts.length) {
        anchors.push(all);
      }
      return anchors;
    },
    resize() {
      this.anchors.forEach((item, index) => {
        let anchorItem = document.getElementById(item.id);
        if (anchorItem) {
          let anchorRect = anchorItem.getBoundingClientRect();
          item.top = anchorRect.top;
          item.bottom = anchorRect.bottom;
          item.active = item.top < 0 && item.bottom > 0;
        }
      });
    },
    // 为了能够在点击的时候对应梯层hover，加了childId，调整了布局
    scrollInto(anchor) {
      this.$nextTick(() => {
        document.getElementById(anchor.childId) && document.getElementById(anchor.childId).scrollIntoView();
      });
    },
    backToTop() {
      this.$nextTick(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    }
  }
};
</script>
<style>
.stairs:hover svg{
    animation: shakeHead 1.6s ease-in infinite;
}
@keyframes shakeHead {
    0%,50%{
        transform: rotate(0deg);
        transform-origin: 13px 26px
    }

    5%{
        transform: rotate(5deg)
    }
    45%{
        transform: rotate(-5deg)
    }

    10%,20%{
        transform: rotate(8deg)
    }

    30%,40%{
        transform: rotate(-8deg)
    }

    15%{
        transform: rotate(12deg)
    }

    35%{
        transform: rotate(-12deg)
    }
}
</style>
