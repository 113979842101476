import { selectTransactionType } from '@/api/refer-modal';
import { deepCopy } from '@/u-components/utils/assist';
const transactionRefer = (ctrl, vm) => {
  const control = Object.assign(
    {},
    {
      pagerSizeOpts: [10, 20],
      page: false,
      bRefer: true,
      bread: false,
      multiple: false,
      controlType: 'Refer',
      // refReturnValue: 'agentId',
      reffieldName: 'name',
      // refName: 'name',
      queryschema: [

      ],
      columns: [
        {
          showCaption: vm.mlang('jiaoyileixingName'),
          isShowIt: true,
          fieldName: 'cName',
          width: 120
        },
        {
          showCaption: vm.mlang('jiaoyileixingCode'),
          isShowIt: true,
          fieldName: 'cCode',
          width: 120
        },
        {
          showCaption: vm.mlang('jiaoyileixingErpCode'),
          isShowIt: true,
          fieldName: 'cErpCode',
          width: 120
        }
      ],
      actions: { queryList: selectTransactionType },
      type: 'long',
      op: 'eq'
    },
    ctrl
  );
  return deepCopy(control);
};
export default transactionRefer;
