<template>
  <div class="order-status">
    <Status
      :params="params"
      :column="column"
      :id-key="params.row['cNextStatus']"
      name-key="cNextStatusName"></Status>
    <div
      v-if="params.row.cNextStatus=='ENDORDER'"
      class="">
      {{ params.row.isHaveDelivery ? mlang('receiveComplete') : mlang('allOrderClose') }}
    </div>
    <Logistic
      v-if="params.row.isShowReceiveBtn || params.row.isShowTakeDeliveryBtn"
      :datas="datas"
      :title="mlang('deliveryNo')"
      :has-fetch="hasFetch"
      :is-loading="isLoading"
      @on-popper-show="onPopShow">
    </Logistic>
  </div>
</template>

<script>
import Logistic from './order-logistic.vue';
import CellMixin from '@/mixin/cellControl.js';
import { getDeliveryList, getLogisticInfo, getLogisticsByDeliveryNo } from '@/api/delivery';
export default {
  name: 'OrderStatus',
  components: {
    Logistic
  },
  mixins: [CellMixin],
  data() {
    return {
      datas: [],
      hasFetch: false,
      isLoading: false
    };
  },
  methods: {
    onPopShow() {
      if (!this.hasFetch) {
        this.isLoading = true;

        let getDeliveryListParam = {
          conditions: [
            { name: 'orderNo', conditionType: 'like', isDefine: false, isOuterField: false, valueType: 'STRING', v1: this.params.row.cOrderNo }
          ],
          pager: { pageIndex: 1, pageSize: 10, pageSizeOpts: [10, 20, 30, 50], currentPage: 1, totalCount: 1 },
          _csrf: ''
        };
        let postData = {
          datatype: 'json',
          isArchive: 1,
          data: getDeliveryListParam
        };
        getDeliveryList(postData).then((res) => {
          (async() => {
            if (res.content.length > 0) {
              const promises = [];
              res.content.map(async(item) => {
                let promise = new Promise((resolve, reject) => {
                  getLogisticsByDeliveryNo(item.cDeliveryNo).then((res) => {
                    item.oCorpLogistics = res.oCorpLogistics;
                    if (res.iLogisticWayId && res.cLogisticsBillNo) {
                      getLogisticInfo(res.iLogisticWayId, res.cLogisticsBillNo, res.cReceiveMobile)
                        .then((logData) => {
                          if (logData.code === 200 && logData.data) {
                            item.logisticsMsg = logData.data.data;
                          }
                          resolve();
                        });
                    } else {
                      resolve();
                    }
                  });
                });
                promises.push(promise);
              });
              await Promise.all(promises);
              this.datas = res.content;
            } else {
              this.datas = res.content;
            }
          })();
        });
      }
    }
  }
};
</script>
