<template>
  <Modal
    v-model="showModal"
    class="exImport-info-modal"
    width="560">
    <div
      slot="header">
      <div class="exImport-info-title">
        {{ mlang('importWarning') }}
      </div>
    </div>
    <div
      class="exImport-info-content">
      <div class="exImport-info-introduction">
        <div class="exImport-info-sum">
          <span class="total">{{ mlang('total') }}{{ mlang('operate') }} <span class="total-sum">{{ executeCount }}</span> {{ mlang('bar') }}</span>，
          <span>{{ mlang('success') }} <span class="success-sum">{{ successCount }}</span> {{ mlang('bar') }}</span>，
          <span>{{ mlang('fail') }} <span class="fail-sum">{{ errorCount }}</span> {{ mlang('bar') }}</span>
        </div>
        <div
          :title="downErrorFileTip"
          class="exImport-info-result"
          @click="downErrorFile()">
          {{ mlang('importWarning') }}
        </div>
      </div>
      <div class="exImport-info-details">
        <template v-for="error in errorRecords">
          <div
            :key="error"
            class="exImport-info-item">
            <div
              class="exImport-info-icon">
              <Icon
                custom="iconfont icon-fail-tip"></Icon>
            </div>
            <div
              class="exImport-info">
              {{ error }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div slot="footer">
      <Button
        type="error"
        size="large"
        @click="handleSure">
        {{ mlang('ok') }}
      </Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'ExOrImportResultModal',
  props: {
    bizMeta: {
      type: Object,
      required: true
    },
    importResult: {
      type: Object,
      default: () => ({
        errorFile: '',
        errorRecords: [],
        executeCount: 0,
        successCount: 0,
        errorCount: 0
      })
    }
  },
  data() {
    return {
      showModal: false,
      downErrorFileTip: this.mlang('download') + this.mlang('importWarning') + this.mlang('view')
    };
  },
  computed: {
    errorRecords() {
      if (!this.importResult) {
        return [];
      }
      let { allMsgs, errorRecords } = this.importResult;
      if (errorRecords && errorRecords.length) {
        return errorRecords;
      }
      if (allMsgs && allMsgs.length) {
        errorRecords = [];
        allMsgs.forEach((v) => { if (v.isError) errorRecords.push(v.msg); });
        return errorRecords;
      }
      return [];
    },
    executeCount() {
      // totalCount 用于支持YS的导入
      return (this.importResult && (this.importResult.executeCount || this.importResult.totalCount)) || 0;
    },
    successCount() {
      return (this.importResult && this.importResult.successCount) || 0;
    },
    errorCount() {
      // failCount 用于支持YS的导入
      return (this.importResult && (this.importResult.errorCount || this.importResult.failCount)) || 0;
    }
  },
  methods: {
    isShowModal(boolVal = true) {
      this.showModal = boolVal;
    },
    handleSure() {
      this.showModal = false;
    },
    downErrorFile() {
      let item = this.importResult;
      // if (item && item.errorFile) {
      // this.$download(item.errorFile);
      // }
      if (!item) return;
      let errorInfo = this.errorRecords.join('\r\n');
      let fileName = item.fileName || 'error.txt';
      this.$util.downloadFile(errorInfo, fileName);
    }
  }
};
</script>

<style lang="less" scoped>
.exImport-info-modal {
  .exImport-info-title {
    font-size: 14px;
    color: #666666;
    font-weight: bold;
  }
  .exImport-info-content {
    padding: 0 20px 15px;
    height: 316px;
    overflow-y: scroll;
    .exImport-info-introduction {
      padding: 15px 0;
      overflow: hidden;
      position: sticky;
      top: 0;
      background: #fff;
      .exImport-info-sum {
        float: left;
        .total {
          color:#999999;
          .total-sum {
            font-weight: bold;
            color: #666;
          }
        }
        .success-sum {
          font-weight: bold;
          color: #4CC377;
        }
        .fail-sum {
          font-weight: bold;
          color: #EE2223;
        }
      }
      .exImport-info-result {
        cursor: pointer;
        float: right;
        color: #f85951;
        background: url(../../../assets/images/download_fail.png) no-repeat;
        background-position: 57px 2px;
        padding-right: 25px;
        background-size: 14px;
      }
     }
    .exImport-info-details {
      .exImport-info-item {
        margin-bottom: 10px;
        .exImport-info-icon {
            display: table-cell;
            vertical-align: top;
            width: 20px;
            color: #ff5735;
        }
        .exImport-info {
            display: table-cell;
            vertical-align: top;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
