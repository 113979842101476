export default {
  update: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500023C","修改") /* "修改" */,
  disable: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500023D","停用") /* "停用" */,
  enable: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500023E","启用") /* "启用" */,
  openServiceOfYou: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500023F","开通友企联服务") /* "开通友企联服务" */,
  dredge: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000240","开通") /* "开通" */,
  serviceDockSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000241","业务对接设置") /* "业务对接设置" */,
  unifiedCorrespondingToSingleSalesOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000242","统一对应单一销售组织") /* "统一对应单一销售组织" */,
  correspondingToMultipleSalesOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000243","分别对应多个销售组织") /* "分别对应多个销售组织" */,
  definationOfSalesOrg: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000244","销售组织定义") /* "销售组织定义" */,
  startInit: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000245","开始初始化") /* "开始初始化" */,
  formulaCheckSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000246","公式校验成功！") /* "公式校验成功！" */,
  warningOfInterfaceKey: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000247","请到接口秘钥设置进行操作！") /* "请到接口秘钥设置进行操作！" */,
  fillInCorrectly: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000248","请正确填写!") /* "请正确填写!" */,
  operationSuccess: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000249","操作成功！") /* "操作成功！" */,
  hasBeenSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500024A","已设置") /* "已设置" */,
  hasBeenNotSetting: lang.templateByUuid("UID:P_UDHWN_17DD50DE0500024B","未设置") /* "未设置" */
};
