<template>
  <span>
    <i-switch
      v-model="params.row[column.fieldName]"
      :true-value="trueValue"
      :false-value="falseValue"
      size="large"
      @on-change="change">
      <span slot="open">{{ open }}</span>
      <span slot="close">{{ close }}</span>
    </i-switch>
  </span>
</template>
<script>
import CellMixin from '@/mixin/cellControl.js';
export default {
  name: 'Toggle',
  mixins: [CellMixin],
  props: {},
  data() {
    return {
      enumArray: this.column.enumArray
    };
  },
  computed: {
    trueValue() {
      const trueItem = this.enumArray.find((v) => {
        return v.value;
      });
      return trueItem['value'];
    },

    open() {
      const trueItem = this.enumArray.find((v) => {
        return v.value;
      });
      return trueItem['title'];
    },
    close() {
      const falseItem = this.enumArray.find((v) => {
        return !v.value;
      });
      return falseItem['title'];
    },
    falseValue() {
      const falseItem = this.enumArray.find((v) => {
        return !v.value;
      });
      return falseItem['value'];
    }
  },
  methods: {
    change(status) {
      this.column.change && this.column.change(status, this.params.row);
    }
  }
};
</script>
