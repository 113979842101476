export default {
  enableContent: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000621","确定执行整单生效?") /* "确定执行整单生效?" */,
  batchContentA: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000622","是否要对选中的") /* "是否要对选中的" */,
  batchContentB: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000623","记录执行操作吗？") /* "记录执行操作吗？" */,
  item: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000624","条") /* "条" */,
  delContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000625","删除") /* "删除" */,
  editContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000626","编辑") /* "编辑" */,
  effectContent2: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000627","生效") /* "生效" */,
  handleBatchClickWarning: lang.templateByUuid("UID:P_UDHWN_17DD50DE05000628","请选择需要批量操作的条目") /* "请选择需要批量操作的条目" */
};
