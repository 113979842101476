import ajax from '@/libs/ajax';
import util from '@/libs/util';

export const getDeliveryList = (data, isHistory, isContainBody) => {
  if (isHistory) {
    delete data.isArchive;
    data.isReport = 1;
    return ajax.request({
      url: '/report/history/deliveryvoucher/list/queryschema',
      method: 'post',
      data
    });
  } else {
    isContainBody && data.data.conditions.push(
      {
        'name': 'withDeliveryDetails',
        'conditionType': 'eq',
        'isDefine': false,
        'isOuterField': false,
        'valueType': 'Boolean',
        'v1': true
      }
    );
    return ajax.request({
      url: `/voucher/delivery/deliveryvoucher/list?isContainBody=${isContainBody}`,
      method: 'post',
      data
    });
  }
};
export const exportDelivery = (data) => {
  return ajax.request({
    url: '/pc/DeliverySrv/exportDeliveryVouchers',
    method: 'post',
    data
  });
};

export const checkInventory = (cDeliveryNo) => {
  return ajax.request({ url: '/pc/DeliverySrv/getExceedInventoryDeliveryConfirm?cDeliveryNo', method: 'get', data: { cDeliveryNo } });
};

export const confirmDelivery = (data) => {
  return ajax.request({ url: '/pc/DeliverySrv/confirmDelivery', method: 'post', data });
};

export const backConfirmDvery = (data) => {
  return ajax.request({ url: '/pc/DeliverySrv/backUnConfirmDelivery', method: 'post', data });
};

export const delDelivery = (data) => {
  return ajax.request({ url: '/pc/DeliverySrv/delDelivery', method: 'post', data });
};

// 已处理
export const getDeliveryListByOrder = (data) => {
  // return ajax.request({ url: '/pc/DeliverySrv/getDeliveryListByOrder', method: 'get', data });
  return ajax.request({
    url: `/voucher/delivery/deliveryvoucher/${data.cOrderNo}/${data.pageIndex}/${data.pageSize}/page`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

// 已关闭，待处理
export const getNotDeliveryListByOrder = (cOrderNo) => {
  return ajax.request({ url: '/pc/DeliverySrv/getNotDeliveryListByOrder', method: 'get', data: { cOrderNo } }).then((res) => {
    res.closed.map((v) => {
      v.oSKU = {
        cSpecDescription: v.cSpecDescription,
        oProduct: {
          cName: v.cProductName,
          imgUrl: v.cProductImgUrl,
          id: v.iProductId
        }
      };
    });
    return res;
  });
};
export const getDeliveryListBtn = (data) => {
  return ajax.request({
    url: '/voucher/delivery/deliveryvoucher/btn',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
export const getInvoice = (cOrderNo) => {
  return ajax.request({
    url: '/pc/OrderSrv/getOrderInfo',
    method: 'get',
    data: {
      cOrderNo
    }
  });
};

export const getInvoiceType = (id) => {
  return ajax.request({
    url: '/enum/orginazation/agent/InvoiceType/info',
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};

export const saveInvoice = (data) => {
  return ajax.request({
    url: '/voucher/delivery/deliveryvoucher/invoice',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
export const takeDelivery = (deliveryNo, receiveWarehouse4Ustock, receiveWarehouseName4Ustock) => {
  return ajax.request({
    url: `/voucher/delivery/deliveryvoucher/${deliveryNo}/take`,
    data: {
      isArchive: 1,
      receiveWarehouse4Ustock,
      receiveWarehouseName4Ustock
    },
    method: 'put'
  });
};
export const watchInvoiceApi = (data) => {
  let str = '' + data;
  let arr = [str];
  return ajax.request({
    url: '/electronic/invoicenotices',
    data: {
      data: arr,
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};

export const isOpenInvoiceBtnApi = () => {
  return ajax
    .request({
      url: '/electronic/invoice',
      method: 'get',
      data: {
        isArchive: 1
      }
    });
};
export const getLogisticsByDeliveryNo = (data) => {
  return ajax.request({
    url: `/voucher/delivery/deliveryvoucher/corplogistics/${data}`,
    method: 'get',
    data: {
      isArchive: 1
    }
  });
};
export const getLogisticInfo = (iCorprationLogisticsId, cLogisticsBillNo, phone) => {
  return ajax.request({
    url: `/archives/logistics/corprationlogistics/${iCorprationLogisticsId}/${cLogisticsBillNo}?phone=${phone}`,
    data: {
      isArchive: 1,
      nodeal: true
    },
    method: 'get'
  });
};

// 发货单导出
export const exportSaleDelivery = (postData) => {
  let url = '/mdf-node/uniform/bill/export?action=output&serviceCode=st_salesout&domainKey=ustock';
  url = postData.ids ? `${url}&cmdname=cmdExport2` : `${url}&cmdname=cmdExport1`;
  return ajax.request({
    url,
    method: 'post',
    data: {
      data: Object.assign({}, {
        billnum: 'st_salesoutlist',
        fileName: lang.templateByUuid('UID:P_UDHWN_17E3A78E05800049', '销售出库') /* "销售出库" */,
        asyncKey: 'asyncOutput_' + util.uuid(32).replace('pc-', ''),
        externalData: { isAsync: true }
      }, postData),
      isArchive: 1,
      isU8C: 1,
      datatype: 'json'
    }
  });
};

// 获取默认收货仓库
export const getDafaultStock = (receiveId, deliveryShop) => {
  return ajax.request({
    url: '/voucher/delivery/deliveryvoucher/stock',
    method: 'get',
    data: {
      isArchive: 1,
      receiveId,
      deliveryShop
    }
  })
};

// 获取所有收获仓库
export const getAllStock = (bizId) => {
  return ajax.request({
    url: '/goods/inventory/stock/list/query',
    data: {
      data: {
        bizId
      },
      datatype: 'json',
      isArchive: 1
    },
    method: 'post'
  });
};
// /voucher/delivery/deliveryvoucher/take 批量收货确认
export const batchDeliveryVoucher = (data) => {
  return ajax.request({
    url: '/voucher/delivery/deliveryvoucher/take',
    data: {
      data,
      datatype: 'json',
      isArchive: 1
    },
    method: 'put'
  });
};
