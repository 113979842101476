import ajax from '@/libs/ajax';

export const getAddressByParent = (data) => {
  let url = `/archives/address/getAddressByParent/${data.iParentId || 0}/${data.levelType || 0}?isArchive=1`;
  if (window.__app.mainV.$route.query.alias) {
    url = `${url}&yhtTenantId=${window.__app.mainV.$route.query.alias}`;
  }
  return ajax.request({
    url,
    method: 'get'
  });
};

export const getAddress = (data) => {
  let url = `/archives/address/getAddress?isArchive=1&addresssString=${encodeURIComponent(data.defVal)}`;
  if (window.__app.mainV.$route.query.alias) {
    url = `${url}&yhtTenantId=${window.__app.mainV.$route.query.alias}`;
  }
  return ajax.request({
    url,
    method: 'get'
  });
};
