import agreement from './agreement';
import autoSeparateBillRule from './autoSeparateBillRule';
import corpERPIntegration from './corpERPIntegration';
import corpERPIntegrationOrg from './corpERPIntegrationOrg';
import defines from './defines';
import enterpriseInfoSetting from './enterpriseInfoSetting';
import functionOptions from './functionOptions';
import helpCenterSetting from './helpCenterSetting';
import interfaceKey from './interfaceKey';
import inventoryCheckSetting from './inventoryCheckSetting';
import inventorylimit from './inventorylimit';
import menuSetting from './menuSetting';
import noticeSettings from './noticeSettings';
import offlinePaySettings from './offlinePaySettings';
import onlinePaySetting from './onlinePaySetting';
import orderAuditRule from './orderAuditRule';
import orderConfirmationStrategy from './orderConfirmationStrategy';
import orderInfo from './orderInfo';
import printSetting from './printSetting';
import rebateSourceList from './rebateSourceList';
import registeredInvite from './registeredInvite';
import serviceInformation from './serviceInformation';
import systemLog from './systemLog';
import themeSetting from './themeSetting';
import user from './user';
import wxMoneyRule from './wxMoneyRule';
export default {
  defines,
  systemLog,
  user,
  printSetting,
  noticeSettings,
  menuSetting,
  autoSeparateBillRule,
  inventorylimit,
  inventoryCheckSetting,
  orderConfirmationStrategy,
  orderAuditRule,
  wxMoneyRule,
  functionOptions,
  agreement,
  onlinePaySetting,
  rebateSourceList,
  serviceInformation,
  interfaceKey,
  corpERPIntegration,
  themeSetting,
  orderInfo,
  offlinePaySettings,
  helpCenterSetting,
  registeredInvite,
  enterpriseInfoSetting,
  corpERPIntegrationOrg
};
