<template>
  <div class="input-meter">
    <Tooltip v-if="$route.path === '/agent/aorder'" placement="top">
      <div slot="content">
        <span>
          {{ mlang('minimumQuatity') }}:{{ renderParams.iMinOrderQuantity }}{{ renderParams.cProductUnitName }}
        </span>
        <span v-if="renderParams.enquiryQuintuple|| renderParams.oSKU.enquiryQuintuple">{{ mlang('multiplier') }}:{{ renderParams.enquiryQuintuple || renderParams.oSKU.enquiryQuintuple }}{{ renderParams.cProductUnitName }}</span>
      </div>
      <div class="input-meter__content">
        <InputNumber
          ref="input"
          :step="step"
          :can-null="false"
          :show-up-down="true"
          :max="max"
          :min="min"
          :precision="precision"
          :round-type="roundType"
          :formatter="formatter"
          :parser="parser"
          :value="value"
          class="ivu-input-wrapper input-quantity input-amount"
          type="text"
          @on-blur="inputNumberBlur"
          @on-enter="inputNumberEnter"
          @input="onInput"></InputNumber>
      </div>
      <div class="input-meter__unit">
        <span v-if="unit">{{ unit }}</span>
      </div>
    </Tooltip>
    <template v-else>
      <div class="input-meter__content">
        <InputNumber
          ref="input"
          :step="step"
          :can-null="false"
          :show-up-down="true"
          :max="max"
          :min="min"
          :precision="precision"
          :round-type="roundType"
          :formatter="formatter"
          :parser="parser"
          :value="value"
          class="ivu-input-wrapper input-quantity input-amount"
          type="text"
          @on-blur="inputNumberBlur"
          @on-enter="inputNumberEnter"
          @input="onInput"></InputNumber>
      </div>
      <div class="input-meter__unit">
        <span v-if="unit">{{ unit }}</span>
      </div>
    </template>
  </div>
</template>
<!--
主辅计量数量换算
-->
<script>
import VoucherMixin from '@/mixin/voucher.js';
import FormMixin from '@/mixin/form.js';
import util from '@/libs/util';
export default {
  name: 'InputMeter',
  components: {},
  mixins: [VoucherMixin, FormMixin],
  props: {
    meterType: {
      type: String,
      validator: function(val) {
        if (!val) return;
        return ['master', 'auxilary'].includes(val.toLocaleLowerCase()); // 判断是主计量还是辅计量
      },
      default: 'master'
    },
    column: {
      required: true,
      type: Object,
      default: {}
    },
    value: {
      type: [Number, String],
      default: ''
    },
    hasMeterConversion: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: 0
    },
    precision: {
      type: Number
    },
    formatter: {
      type: Function
    },
    conversionRate: {
      type: Number,
      default: 0
    },
    invExchRate: Number,
    invPriceExchRate: Number,
    parser: {
      type: Function
    },
    unit: {
      type: String
    },
    roundType: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    renderParams: {
      type: Object,
      default:function(){
        return {}
      }
    }
  },
  methods: {
    inputNumberEnter(v) {
      this.$emit('on-enter', v, this.getMeter(v));
    },
    getMeter(v) {
      let v1 = 0;
      let meter = {};
      meter[this.meterType] = v;
      if (this.hasMeterConversion && this.conversionRate !== 0) {
        switch (this.meterType) {
          case 'master':
            v1 = util.numDiv(v, this.conversionRate);
            meter['auxilary'] = v1;
            meter['__conversValue'] = v1;
            break;
          case 'auxilary':
            v1 = util.numMulti(v, this.conversionRate);
            meter['master'] = v1;
            meter['__conversValue'] = v1;
            break;
        }
      } else if (this.invExchRate && this.invPriceExchRate) {
        switch (this.meterType) {
          case 'master':
            v1 = util.numDiv(util.numMulti(v, this.invPriceExchRate), this.invExchRate);
            meter['auxilary'] = v1;
            meter['__conversValue'] = v1;
            break;
          case 'auxilary':
            v1 = util.numDiv(util.numMulti(v, this.invExchRate), this.invPriceExchRate);
            meter['master'] = v1;
            meter['__conversValue'] = v1;
            break;
        }
      }
      return meter;
    },
    onInput(v) {
      this.$emit('input', v, this.getMeter(v));
    },
    inputNumberBlur(v) {
      this.$emit('input', v, this.getMeter(v));
      this.$emit('on-blur', v, this.getMeter(v));
    },
    inputNumberSelect() {
      const ref = this.$refs.input;
      ref && ref.$refs.input && ref.$refs.input.select();
    },
    inputNumberMouseenter(e) {
      e.target.querySelector('input').select();
    }
  }
};
</script>
<style lang="less" scoped>
.input-meter {
  display: flex;
  align-items: center;
  height: 100%;
  /deep/ .ivu-input-number-input {
    padding-right: 24px;
  }
  .input-meter__content {
    width: 102px;
    display: table-cell;
  }
  .input-meter__unit {
    display: table-cell;
    padding: 0;
    padding-left: 5px;
    word-break: keep-all;
  }
}
</style>
