<template>
  <iframe
    ref="iframe"
    :src="url"
    frameborder="0"
    class="noBorder"
    :class="agentClass"
    style="height:600px;"
    :scrolling="scrolling"
    @load="loaded"></iframe>
</template>

<script>
// import { getMenuUrlById } from '@/api/system/menu';
import _ from 'lodash';
export default {
  name: 'UFrame',
  // inject: ['updateOpenName'],
  props: {
    url: String,
    heightControl: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      timer: null,
      height: '400px',
      noCheckLoad: false
    };
  },
  computed: {
    scrolling() {
      return this.isAgentSide ? 'no' : 'auto';
    },
    isAgentSide() {
      return sessionStorage.isAgentSide === 'true';
    },
    agentClass() {
      return this.isAgentSide ? 'agent-frame' : 'corp-frame';
    }
  },
  watch: {
    $route: {
      handler(val) {
        // this.setUrl();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setHeight();
      this.timer = setInterval(_.debounce(this.setHeight, 500), 1000);
    });
  },
  update() {},
  created() {
    // this.setUrl();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    test() {
    },
    loaded() {
      this.$emit('frame-loaded');
      // const href = this.$refs.iframe.contentWindow.location.href;

      // if (href.indexOf(this.url) === -1) {
      //   util.menuClickOldPage(href, this, {}, true);
      // }
    },
    debounce() {
      return _.debounce(this.setHeight(), 10);
    },
    setHeight() {
      const oIframe = this.$refs.iframe;
      if (!oIframe) {
        return;
      }
      // const deviceWidth = document.documentElement.clientWidth;
      const deviceHeight = localStorage.getItem('dataCenterUrl') ? document.documentElement.clientHeight : document.documentElement.clientHeight - 80;
      const oldHeight = oIframe.style.height;
      let nwHeight = Number(deviceHeight) + 'px';
      if (this.isAgentSide && ((this.$route.query && this.$route.query.uri) || this.heightControl)) {
        // 跨域的获取这会报错
        if (oIframe.contentWindow.document.body) {
          nwHeight = oIframe.contentWindow.document.body.scrollHeight + 35 + 'px';
        }
      } else if (this.isAgentSide) {
        oIframe.scrolling = 'auto';
        oIframe.style.width = Number(document.documentElement.clientWidth) + 'px';
        oIframe.style.position = 'absolute';
        oIframe.style.left = 0;
      }

      if (oldHeight === nwHeight) {
        return;
      }
      if (this.isAgentSide && document.getElementById('iframeWrapper')) {
        document.getElementById('iframeWrapper').style.height = nwHeight;
        document.getElementById('iframeWrapper').style.minHeight = '550px';
      }
      oIframe.style.height = nwHeight;
      oIframe.style.minHeight = '550px';
      // oIframe.style.width = '100%'; // 数字是页面布局宽度差值
    }
  }
};
</script>
<style lang="less" scoped>
.agent-frame {
  width: calc(~'100% + 3px');
}
.corp-frame{
  width: 100%;
}
</style>
