import corpParentView from '@/views/corp/home/home.vue';

const home = [
  // path: '/corp/home',
  // title: { i18n: 'home' },
  // name: 'corp_home',
  // redirect: '/corp/home/index',
  // component: corpParentView,
  {
    path: '/corp/home',
    meta: {
      notCache: true,
    },
    name: 'corp_home_index',
    component: () => import('@/views/corp/index/index.vue')
  },
  {
    path: '/corp/test',
    title: 'test',
    name: 'test',
    component: () => import('@/views/corp/order/components/test.vue')
  }
];

export default home;
