import creditRules from './creditRules';
import objectCredit from './objectCredit';
import creditCalculation from './creditCalculation';
import temporaryCredit from './temporaryCredit';
export default {
  creditRules,
  objectCredit,
  creditCalculation,
  temporaryCredit
};
