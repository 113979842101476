/* eslint-disable */
import Axios from 'axios';
import errorHandler from './errorHandler';
import { LoadingBar } from 'iview';
import dayjs from 'dayjs';
import { oneOf } from '@/u-components/utils/assist';
import deparam from './param';
// import Cookies from 'js-cookie';
import util from '@/libs/util';
import Modal from '../u-components/components/modal';
const reqInterceptors = require.context('./interceptors/request', true, /\.js$/);
const interceptors = reqInterceptors.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = reqInterceptors(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
class httpRequest {
  constructor() {
    this.options = {
      method: '',
      url: ''
    };
    // 存储请求队列
    this.queue = {};
    this.setXscfToken = {};
  }
  // 销毁请求实例
  destroy(url) {
    delete this.queue[url];
    const queue = Object.keys(this.queue);
    return queue.length;
  }
  // 请求拦截
  interceptors(instance, url, options) {
    // 添加请求拦截器
    //自定义请求，写前面的后进
    Object.values(interceptors).forEach((interceptor) => {
      instance.interceptors.request.use(
        (config) => {
          return interceptor(config);
        },
        (error) => {}
      );
    });
    instance.interceptors.request.use(
      (config) => {
        if (!config.params) {
          config.params = {};
        }
        if (config.url && config.url.indexOf('+') > -1) {
          config.url = config.url.replace('+', '%2B');
        }
        config.params.deviceId = util.getDeviceId();
        config.params.terminal = sessionStorage.isAgentSide === 'true' ? 0 : 1;
        const app = window.__app.mainV;
        if (sessionStorage.isAgentSide === 'true' ) {
          if(JSON.parse(localStorage.getItem('defaultUserInfo'))){
            const currentUser = app.$store.getters.currentUser;
            // 刚进入订货端的时候，取不到currentUser的内容，该方法用于在此时判断是否为代客下单
            const isPresent = () => {
              let user = JSON.parse(localStorage.getItem('defaultUserInfo'));
              return [1, 3, 9, 10].includes(user.userType);
            };
            if (currentUser.__isRepresent || isPresent() || util.getUrlParam('isRepresent')) {
              // 最后一个"||"后面的数据用于代客下单刚进订货端页面的时候
              config.params.iAgentId = config.params.iAgentId || currentUser.__upAgentId || localStorage.upAgentId;
              config.params.upAgentRelationId = config.params.upAgentRelationId || currentUser.__upAgentRelationId || localStorage.upAgentRelationId;
            }
            const ORDERSHOWWAY = app.$getBO('ORDERSHOWWAY'); //false :单商家模式
            if (ORDERSHOWWAY === false && localStorage.upAgentRelationId && localStorage.upAgentRelationId !== 'undefined') {
              config.params.upAgentRelationId = localStorage.upAgentRelationId;
            }
          }else{
            if(localStorage.isRepresentToOrder){
              config.params.iAgentId = localStorage.upAgentId;
              config.params.upAgentRelationId = localStorage.upAgentRelationId;
            }
          }
        }
        config.params.lang = localStorage.lang || 'zh-cn';
        if (url.indexOf('/passport/heartbeat/ping?isArchive=1') === -1) {
          LoadingBar.start();
        }
        if (oneOf(config.method, ['delete', 'post', 'put', 'patch'])) {
          config.data = config.data || {};
          if (config.data.exportExcel) {
            config.params.exportExcel = 1;
          }
          config.data._csrf = window.__app && window.__app.context.csrf;
        }
        if (oneOf(config.method, ['delete', 'patch'])) {
          config.data.isArchive = 1;
        }
        if (config.method === 'post' || config.method === 'patch') {
          config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
          if (config.data.datatype === 'json' || config.data.isArchive === 1) {
            config.headers.post['Content-Type'] = 'application/json; charset=UTF-8';
            config.headers['Content-Type'] = 'application/json; charset=UTF-8';
          } else {
            config.data = deparam(config.data);
          }
          // else {
          //   config.data = stringify(config.data);
          // }
        }
        if((config.data && config.data.isArchive && config.data.isArchive === 1) || url.indexOf('isArchive=1') > -1){
          config.headers['x-from-mdf'] = 'public'
        }

        window.__app.broadcastToExtend({
          namespace: 'common',
          action:'beforeRequest',
          params:{
            data: config.data,
            url: config.url,
            config
          }
        });
        return config;
      },
      (error) => {
        // Do something with request error
        console.log(error); // for debug
        if (url.indexOf('/passport/heartbeat/ping?isArchive=1') === -1) {
          LoadingBar.finish();
        }
        Promise.reject(error);
      }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
      (response) => {
        if (url.indexOf('/passport/heartbeat/ping?isArchive=1') === -1) {
          LoadingBar.finish();
        }
        let res = response.data;
        let whiteList = ['/marketingbill/order/list/getListSchema','/voucher/delivery/deliveryvoucher/list', '/marketingbill/salereturn/list/getListSchema'];
        if(whiteList.includes(url.split('?')[0]) && res.isEncrypt){
          res.data = util.decrypt(res.data);
        }
        window.__app.broadcastToExtend({
          namespace: 'common',
          action: 'afterRequest',
          params: {
            data: response.data,
            url: response.config && response.config.url,
            Modal: Modal
          }
        });
        return errorHandler(res, options);
        // return res.data;
      },
      (error) => {
        console.log('err' + error); // for debug
        if (url.indexOf('/passport/heartbeat/ping?isArchive=1') === -1) {
          LoadingBar.finish();
        }
        return Promise.reject(error);
      }
    );
  }
  // 创建实例
  create() {
    this.setXscfToken = window.tnsSdk && window.tnsSdk.writeXscfToken('udh');
    if (this.setXscfToken && this.setXscfToken.lastTime + 1000 > new Date().getTime()) {
      this.setXscfToken = window.tnsSdk && window.tnsSdk.writeXscfToken('udh');
    }
    let conf = {
      baseURL: '/api',
      withCredentials: true,
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-URL-PATH': location.pathname,
        'X-XSRF-TOKEN': window.tnsSdk && window.tnsSdk.readXscfToken(),
      }
    };
    if(location.pathname.indexOf('yonbip-mkt-udhwebnode') != -1 ) conf.baseURL = `/yonbip-mkt-udhwebnode${conf.baseURL}`;
    return Axios.create(conf);
  }
  // 合并请求实例
  all(instances = []) {
    return Axios.all(instances);
  }
  spread(instances = []) {
    return Axios.spread(instances);
  }
  // 请求实例
  request(options) {
    if (options.method === 'get') {
      options.params = options.data || {};
      options.params._r = dayjs().format('YYYYMMDD-HHmm');
    }
    const instance = this.create();
    this.interceptors(instance, options.url, options);
    options = Object.assign({}, options);
    this.queue[options.url] = instance;
    return instance(options);
  }
}
export default httpRequest;
